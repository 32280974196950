<template>
    <div>
        <draggable v-model="list">
            <el-row v-for='appTab in list' :key="appTab.id" style="margin-bottom:5px;">
                <el-col>
                    <el-card class="boxCard">
                        {{appTab.label}}
                    </el-card>
                </el-col>
            </el-row>
        </draggable>
        <el-row class="footerButtons" type="flex" justify="end">
            <el-header>
                <el-col>
                    <el-button type="default" @click="() => this.$router.push('/organizations/app_tab')">
                        戻る
                    </el-button>
                    <el-button type="success" @click="updateOrder">
                        並び替えを保存する
                    </el-button>
                </el-col>
            </el-header>
        </el-row>
    </div>
</template>

<script>

import * as types from '../../../store/mutation-types'
import draggable  from 'vuedraggable'

export default {
    components: {
        draggable
    },
    data() {
        return {
            list     : [],
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'APPタイムラインタブ - 並び替え')
    },
    mounted() {
        this.fetch()
    },
    methods   : {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)
            const {data, error} = await axios.get('/api/organization/app_tabs')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.list = data.map((appTab) => {
                return {id: appTab.id, label: appTab.tab_name}
            })
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async updateOrder() {
            const orderList = this.list.map((appTab, index) => {
                return {id: appTab.id, order_num: this.list.length - index}
            })
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.post('/api/organization/app_tabs/order', {
                order_list : orderList
            })
            if (error) {
                if (error.response.status === 403) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'この操作は許可されていません。')
                } else {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, '並び替えデータ不整合です、他の方が編集している可能性がありますのでもう一度お試しください。')
                }
            } else {
                await this.fetch()
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'APPタイムラインタブを並び替えました。')
            }
            this.$store.commit(types.LOADING, false)
        },
    }
}
</script>
<style scoped>
    .footerButtons {
        background: rgba(255, 255, 255, 0.8);
        position: fixed;
        bottom: 0;
        right: 0;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-right: 40px;
        width: 320px;
    }

    .boxCard {
        cursor: move;
    }
</style>
