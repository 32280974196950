<template>
    <div>
        <draggable v-model="appRecipeMenuHints">
            <el-row v-for='hint in appRecipeMenuHints' :key="hint.id" style="margin-bottom:5px;">
                <el-col>
                    <el-card class="boxCard">
                        {{hint.title}}
                    </el-card>
                </el-col>
            </el-row>
        </draggable>
        <el-row class="footerButtons" type="flex" justify="end">
            <el-header>
                <el-col>
                    <el-button type="default" @click="() => this.$router.push('/organizations/app_recipe_menu/hints')">
                        戻る
                    </el-button>
                    <el-button type="success" @click="updateOrder">
                        並び替えを保存する
                    </el-button>
                </el-col>
            </el-header>
        </el-row>
    </div>
</template>

<script>

import * as types from '../../../../store/mutation-types'
import draggable  from 'vuedraggable'

export default {
    components: {
        draggable
    },
    data() {
        return {
            appRecipeMenuHints: [],
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '並び替え')
    },
    mounted() {
        this.fetch()
    },
    methods   : {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)
            const {data, error} = await axios.get('/api/organization/app_recipe_menu/hints/all')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'データの取得に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            this.$store.commit(types.PAGE_TITLE, ' 献立ヒント - 並び替え')
            if (data.length !== 0) {
                this.appRecipeMenuHints = data
            }
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async updateOrder() {
            const orderList = this.appRecipeMenuHints.map((hint, index) => {
                return {id: hint.id, order_num: index + 1}
            })
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.post('/api/organization/app_recipe_menu/hints/order', {
                order_list: orderList
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error + '並び替えデータ不整合です、他の方が編集している可能性がありますのでもう一度お試しください。')
            } else {
                await this.fetch()
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'APP献立ヒントを並び替えました。')
            }
            this.$store.commit(types.LOADING, false)
        },
    }
}
</script>
<style scoped>
    .footerButtons {
        background: rgba(255, 255, 255, 0.8);
        position: fixed;
        bottom: 0;
        right: 0;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-right: 40px;
        width: 320px;
    }

    .boxCard {
        cursor: move;
    }
</style>
