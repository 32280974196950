<template>
    <div class="affiliatePlaceSearch">
        <ul class="mediaList">
            <li
                class="mediaItem"
                :class="{active: selectedMedia === MEDIA_LIST.gnavi}"
                @click="selectedMedia = MEDIA_LIST.gnavi"
            >
                <img src="https://cdn.macaro-ni.jp/assets/img/place_gnavi_icon.svg" alt="ぐるなび" />
            </li>
            <li
                class="mediaItem"
                :class="{active: selectedMedia === MEDIA_LIST.ikyu}"
                @click="selectedMedia = MEDIA_LIST.ikyu"
            >
                <img src="https://cdn.macaro-ni.jp/assets/img/place_ikyu_icon.svg" alt="一休.com" />
            </li>
        </ul>
        <div class="content">
            <media-gnavi
                v-if="selectedMedia === MEDIA_LIST.gnavi"
                v-model="searchForm"
                :page="gnavi.page"
                :has-next="gnavi.hasNext"
                :search-result="gnavi.results"
                @search="searchGnavi"
                @add="$emit('add', MEDIA_LIST.gnavi, $event)"
            />
            <media-ikyu
                v-if="selectedMedia === MEDIA_LIST.ikyu"
                v-model="searchForm"
                :page="ikyu.page"
                :has-next="ikyu.hasNext"
                :search-result="ikyu.results"
                @search="searchIkyu"
                @add="$emit('add', MEDIA_LIST.ikyu, $event)"
            />
        </div>
    </div>
</template>

<script>
import MediaGnavi from './Media/MediaGnavi'
import MediaIkyu from './Media/MediaIkyu'

export const MEDIA_LIST = () => ({gnavi: 'gnavi', ikyu: 'ikyu'})

export default {
    props: {
        // 一休アフィリンクの生成に記事IDが必要
        articleId: {
            type: Number,
            required: true,
        },
    },
    components: {
        MediaGnavi,
        MediaIkyu,
    },
    data () {
        return {
            selectedMedia: MEDIA_LIST().gnavi,
            searchForm: {
                keyword: '',
                area: '',
            },
            gnavi: {
                page: 1,
                hasNext: false,
                results: [],
            },
            ikyu: {
                page: 1,
                hasNext: false,
                results: [],
            },
        }
    },
    computed: {
        MEDIA_LIST() {
            return MEDIA_LIST()
        }
    },
    methods: {
        async searchGnavi(page) {
            if (this.searchForm.keyword.trim().length === 0 && this.searchForm.area.trim().length === 0) {
                this.$message.error('検索条件を入力してください')
                return
            }

            const loading = this.$loading({fullscreen: true})
            const { data, error } = await axios.get('/api/cooperation/gurunavi/places/search', {
                params: {
                    keyword1: this.searchForm.keyword,
                    keyword2: this.searchForm.area,
                    page: page,
                }
            })
            loading.close()
            if (error) {
                this.$message.error(`サーバーエラーが発生しました。${error.response.data.message ?? ''}`)
                return
            }
            if (data.shops.shops.length === 0) {
                this.$message.info('条件に該当する店舗が見つかりませんでした。')
            }

            this.gnavi.page = page
            this.gnavi.hasNext = data.shops.hasNext
            const places = data.shops.shops.map((shop) => ({
                name: shop.shopName,
                address: shop.shopArea,
                url: shop.shopUrl,
                affiliateUrl: shop.affiliateShopUrl,
                memo: shop.shopCategory,
            }))
            this.gnavi.results = page > 1
                ? this.gnavi.results.concat(places)
                : places
        },
        async searchIkyu(page) {
            if (this.searchForm.keyword.trim().length === 0 && this.searchForm.area.trim().length === 0) {
                this.$message.error('検索条件を入力してください')
                return
            }

            const loading = this.$loading({fullscreen: true})
            const { data, error } = await axios.get('/api/cooperation/ikkyu/places/search', {
                params: {
                    article_id: this.articleId,
                    keyword: this.searchForm.keyword,
                    area: this.searchForm.area,
                    page: page,
                }
            })
            loading.close()
            if (error) {
                this.$message.error(`サーバーエラーが発生しました。${error.response.data.message ?? ''}`)
                return
            }
            if (data.restaurants.length === 0) {
                this.$message.info('条件に該当する店舗が見つかりませんでした。')
            }

            this.ikyu.page = page
            this.ikyu.hasNext = data.pagination.hasNext
            const places = data.restaurants.map((restaurant) => ({
                name: restaurant.name,
                address: restaurant.address,
                url: restaurant.url,
                affiliateUrl: restaurant.affiliateUrl,
                memo: [restaurant.nearByStation1, restaurant.nearByStation2, restaurant.nearByStation3]
                    .filter((station) => (station ?? '').length > 0)
                    .join(' / '),
            }))
            this.ikyu.results = page > 1
                ? this.ikyu.results.concat(places)
                : places
        },
    }
}
</script>

<style scoped>
.affiliatePlaceSearch {
    display: flex;
    gap: 10px;
}

.mediaList {
    display: flex;
    flex-direction: column;
    width: 170px;
}
.mediaItem {
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 10px 0;
    border: 1px solid #ccc;
    border-bottom: 0;
    &:last-child {
        border-bottom: 1px solid #ccc;
    }
    &.active {
        background-color: #f7f7f7;
    }
}

.content {
    width: 100%;
}
</style>
