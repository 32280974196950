<template>
    <div>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <el-table class="list" :data="tableData" style="font-size: 12px" max-height="800">
            <el-table-column :width="60" label="ID" >
                <template slot-scope="scope" v-if="scope.row.id">
                    {{ scope.row.id }}
                </template>
            </el-table-column>
            <el-table-column label="テキスト" >
                <template slot-scope="scope" >
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 1, maxRows: 10}"
                        :cols="40"
                        v-model="scope.row.sale_text"
                  >
                    </el-input>
                </template>
            </el-table-column>
            <el-table-column size="small" label="amazonフラグ">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_amazon" />
                </template>
            </el-table-column>
            <el-table-column size="small" label="楽天フラグ">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_rakuten" />
                </template>
            </el-table-column>
            <el-table-column label="開始日" >
                <template slot-scope="scope" >
                    <el-date-picker
                        v-model="scope.row.sale_begin_at"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width:100%"
                        size="mini"
                        placeholder="開始日">
                    </el-date-picker>
                </template>
            </el-table-column>
            <el-table-column label="終了日" >
                <template slot-scope="scope" >
                    <el-date-picker
                        v-model="scope.row.sale_end_at"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width:100%"
                        size="mini"
                        placeholder="終了日">
                    </el-date-picker>
                </template>
            </el-table-column>
            <el-table-column :width="130" label="操作">
                <template slot-scope="scope" >
                    <div v-if="scope.row.id">
                        <el-button
                            class="btnEdit unitBtn"
                            size="mini"
                            type="warning"
                            @click="updateRow(scope.row.id, scope.row)"
                        >更新</el-button>
                        <el-button
                            class="btnDelete unitBtn"
                            size="mini"
                            type="danger"
                            @click="deleteRow(scope.row.id)"
                        >削除</el-button>
                    </div>
                    <div v-else>
                        <el-button
                            class="unitBtn"
                            size="mini"
                            type="primary"
                            @click="createRow(scope.row)">
                            新規作成
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
    </div>
</template>
<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
export default {
    components: {
        Pagination,
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data() {
        return {
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            pagination  : {},
            tableData   : [],

            status: null,
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'アフィリエイトセール設定')
        this.queryInit()
        this.fetch()
    },
    methods: {
        queryInit() {
            this.page = this.$route.query.page ? this.$route.query.page : 1
        },
        search() {
            const query = Object.assign({}, this.$route.query,{
                page: 1
            })
            this.$router.push({ query })
        },
        init() {
            this.fetch()
        },
        // 検索
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)
            const { data } = await axios.get('/api/affiliate_sale_info', {
                params: { ...this.$route.query }
            })

            this.pagination = Object.assign({}, data.affiliate_sale_info)
            this.affiliate_sale_infos = Object.assign({}, data.affiliate_sale_info.data)

            this.tableData = _.map(this.affiliate_sale_infos, (affiliate_sale_info) => {
                return {
                    id: affiliate_sale_info.id,
                    sale_text:affiliate_sale_info.sale_text,
                    is_amazon: affiliate_sale_info.is_amazon,
                    is_rakuten: affiliate_sale_info.is_rakuten,
                    sale_begin_at:affiliate_sale_info.sale_begin_at,
                    sale_end_at:affiliate_sale_info.sale_end_at,
                }
            })
            if (this.pagination.current_page === 1) {
                this.tableData.unshift({
                    id: null,
                    keywords: [],
                    parent_tags: [],
                    child_tags: [],
                    grandchild_tags: [],
                    admin_id: null,
                    category_id: null,
                    article_type_id: null,
                    base_char_count: null,
                    plot: null,
                    admin_name: null,

                })
            }

            this.$store.commit(types.PAGE_LOADING, false)
        },
        handleCurrentChange (page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
        async createRow(form) {
            this.$store.commit(types.PAGE_LOADING, true)

            const { error } = await axios.post('/api/affiliate_sale_info/', form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '作成しました')
            this.fetch()
        },
        async updateRow(id, form) {
            this.$store.commit(types.PAGE_LOADING, true)

            const { error } = await axios.patch('/api/affiliate_sale_info/' + id, form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '更新しました')
            this.fetch()
        },
        async deleteRow(id) {
            if (! confirm('本当に削除しますか？')) {
                return
            }

            this.$store.commit(types.PAGE_LOADING, true)

            const { error } = await axios.delete('/api/affiliate_sale_info/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '削除しました')
            this.fetch()
        },
    }
}
</script>

<style scoped>
.search {
    margin: 10px 5px 0;
}
.btnEdit {
    padding: 9px 15px;

    border-radius: 3px;
    margin-right: 10px;
    margin-top: 10px;
}
.el-button {
    margin: 5px 0 5px 10px;
}
</style>
