import { tableNodes } from 'prosemirror-tables'

export default tableNodes({
    tableGroup: 'block',
    cellContent: 'block+',
    cellAttributes: {
        background: {
            default: null,
            getFromDOM (dom) {
                return dom.style.backgroundColor || null
            },
            setDOMAttr (value, attrs) {
                if (value) {
                    const style = { style: `${(attrs.style || '')}background-color: ${value};` }
                    Object.assign(attrs, style)
                }
            }
        },
        width: {
            default: null,
            getFromDOM (dom) {
                return dom.width || null
            },
            setDOMAttr (value, attrs) {
                if (attrs['data-colwidth']) {
                    attrs.width = attrs['data-colwidth'].split(',').map(s => Number(s)).reduce((prev, current) => prev + current)
                }
            }
        }
    }
})
