<template>
    <el-form :model="form" :inline="true">
        <div class="top-content">
            <el-form-item>
                <el-input v-model="form.label" placeholder="項目名"></el-input>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="form.required">必須</el-checkbox>
            </el-form-item>
        </div>
        <div class="submit-content">
            <el-form-item>
                <el-button type="primary" @click="add">追加</el-button>
            </el-form-item>
        </div>
    </el-form>
</template>

<script>
import * as types from '../../../../store/mutation-types'

export default {
    props: {
        htmlClasses: Object,
    },
    data() {
        return {
            form: {
                label: '電話番号',
                required: false,
            },
        }
    },
    methods: {
        add() {
            if (!this.form.label.match(/\S/g)) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '項目名は必須です')
                return
            }
            const html = this.getTemplate()

            this.$emit('addFormHtml', html)
            this.form = {
                label: '',
                required: false,
            }
        },
        getTemplate() {
            return `
                <div class="${this.htmlClasses.COMMON}" data-required="${this.form.required ? 'true' : 'false'}">
                    <div class="${this.htmlClasses.LEFT}">
                        <b>${this.form.label}</b>
                        ${this.form.required ? '<span class="required">必須</span>' : ''}
                    </div>
                    <div class="${this.htmlClasses.RIGHT}">
                        <input type="tel" class="zip" maxlength="20" name="${this.form.label}">
                    </div>
                </div>
            `
        }
    }
}
</script>

<style lang="scss" scoped>
    .top-content {
        margin-bottom: 10px;
    }
</style>
