<template>
    <div>
        <el-row type="flex" justify="end" class="bottomControllerWrapper" >
            <el-header class="bottomController">
                <el-button
                    v-if="canEditIncentiveFormat"
                    size="small"
                    class="bottonButton"
                    @click="openIncentiveModal"
                >インセンティブ</el-button>

                <div class="bottomController__characterCountWrapper">
                    <p class="originalCount">
                        オリジナル文字数: {{characterInfo.originalCount}} ({{characterInfo.ratio}}%)
                    </p>
                    <p class="totalCount">
                        全体文字数: {{characterInfo.totalCount}}
                    </p>
                </div>
                <el-button
                    size="small"
                    type="danger"
                    class="bottonButton"
                    :disabled="!enableUpdateAndDelete"
                    @click="() => this.deleteItemsConfirm = true"
                    >選択アイテムをまとめて削除</el-button>

                <el-button
                    size="small"
                    class="bottonButton"
                    type="success"
                    @click="updateOrderCheckedItems()"
                 >並び替えモード</el-button>

                <el-button
                    size="small"
                    class="bottonButton"
                    @click="() => this.openEditPoint = true"
                 >ライティングのポイント</el-button>
                <el-button
                    size="small"
                    class="bottonButton"
                    :disabled="!plot && !rewritePlot"
                    @click="openPlotModal"
                 >プロット確認</el-button>
                <el-button
                    size="small"
                    class="bottonButton"
                    :disabled="itemsCount === 0"
                    @click="$emit('moveToPreviewPage')"
                 >プレビュー</el-button>
                <el-button
                    v-if="ccdLink !== null"
                    size="small"
                    class="bottonButton"
                    @click="() => this.confirmCcd = true"
                >CCD</el-button>
                <el-button
                    v-if="this.$store.state.role === 'tr'"
                    size="small"
                    class="bottonButton"
                    @click="doCopy"
                >テキスト一括コピー</el-button>
           </el-header >
        </el-row>
        <el-row v-if="updateOrdersMode" type="flex" justify="end" class="bottomControllerWrapper updateOrdersMode" >
            <el-header class="bottomController" >
                <span style="padding-right:40px;">
                    <el-input-number
                        :disabled="!enableUpdateAndDelete"
                        size="small"
                        v-model="orderPosition"
                        :min="1"
                        :max="itemsCount + 1">
                    </el-input-number>
                    番目に
                    <el-button
                    size="small"
                    class="bottonButton"
                    :disabled="!enableUpdateAndDelete"
                    @click="$emit('movePositionCheckedOrder',orderPosition)"
                    >移動</el-button>
                </span>
               <el-button
                    size="small"
                    class="bottonButton"
                    :disabled="!enableUpdateAndDelete"
                    @click="$emit('moveTopCheckedOrder')"
                    ><i class="fas fa-angle-double-up"></i>&nbsp;&nbsp;一番上に移動</el-button>
                <el-button
                    size="small"
                    class="bottonButton"
                    :disabled="!enableUpdateAndDelete"
                    @click="$emit('moveUpCheckedOrder')"
                    ><i class="fas fa-angle-up"></i>&nbsp;&nbsp;上に移動</el-button>
                <el-button
                    size="small"
                    class="bottonButton"
                    :disabled="!enableUpdateAndDelete"
                    @click="$emit('moveDownCheckedOrder')"
                    ><i class="fas fa-angle-down"></i>&nbsp;&nbsp;下に移動</el-button>
                <el-button
                    size="small"
                    class="bottonButton"
                    :disabled="!enableUpdateAndDelete"
                    @click="$emit('moveBottomCheckedOrder')"
                    ><i class="fas fa-angle-double-down"></i>&nbsp;&nbsp;一番下に移動</el-button>
                <el-button
                    size="small"
                    class="bottonButton"
                    @click="finishUpdateOrderCheckedItems"
                 >終了</el-button>

                <el-button
                    size="small"
                    class="bottonButton"
                    type="success"
                    @click="saveUpdateOrder"
                 >並び替えを保存する</el-button>
           </el-header >
        </el-row>
        <el-dialog
            title="選択アイテムをまとめて削除"
            :visible.sync="deleteItemsConfirm"
            width="30%"
            @close="() => this.deleteItemsConfirm = false"
            >
            <span>アイテム番号<span class="dialogItemNumber">{{ deleteCheckItems }}</span>をまとめて削除してよろしいですか？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="() => this.deleteItemsConfirm = false">キャンセル</el-button>
                <el-button type="danger" @click="deleteItems">削除する</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="ライティングのポイント"
            :visible.sync="openEditPoint"
            width="60%"
            @close="() => this.openEditPoint = false"
            >
            <edit-point></edit-point>
            <span slot="footer" class="dialog-footer">
                <el-button @click="() => this.openEditPoint = false">閉じる</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="プロット確認"
            :visible.sync="confirmPlot"
            width="60%"
            @close="() => this.confirmPlot = false"
            >
            <confirm-plot :plot="dialogPlot" :baseCharCountList="baseCharCountList"></confirm-plot>
            <span slot="footer" class="dialog-footer">
                <el-button @click="() => this.confirmPlot = false">閉じる</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="CCD"
            :visible.sync="confirmCcd"
            width="60%"
            @close="() => this.confirmCcd = false"
        >
            <confirm-ccd :ccdLink="ccdLink"></confirm-ccd>
            <span slot="footer" class="dialog-footer">
                <el-button @click="() => this.confirmCcd = false">閉じる</el-button>
            </span>
        </el-dialog>
        <incentive-modal
            :is-active="isActiveIncentiveModal"
            :incentive-prices="articleIncentivePrices"
            :incentives="articleIncentives"
            :is-wr="isWr"
            :can-edit-status="canEditIncentive"
            :incentive-histories="incentiveHistories"
            :is-published="isPublished"
            :other-incentive-params="otherIncentiveParams"
            @close="closeIncentiveModal"
            @deleteArticleIncentivePrice="deleteArticleIncentivePrice"
            @create="createArticleIncentivePrice"
            @updateOtherIncentive="updateOtherIncentive"
        ></incentive-modal>
    </div>
</template>
<script>
import ConfirmCcd from './BottomController/ConfirmCcdText'
import ConfirmPlot from './BottomController/ConfirmPlotText'
import EditPoint from './BottomController/EditPointText'
import IncentiveModal from './BottomController/IncentiveModal'
import sanitizeHTML from 'sanitize-html'
import * as types from '../../../store/mutation-types'

export default {
    components: {
        EditPoint,
        ConfirmPlot,
        ConfirmCcd,
        IncentiveModal,
    },
    props: {
        response: Object,
        enableUpdateAndDelete: Boolean,
        fetch: Function,
        isWr: Boolean,
        canEditIncentiveFormat: Boolean,
        checkedItems: Array,
    },
    computed: {
        plot() {
            if (!this.response) {
                return null
            }
            if (!this.response.article.direction) {
                return null
            }
            if (!this.response.article.direction.plot) {
                return null
            }
            return this.response.article.direction.plot
        },
        rewritePlot() {
            if (!this.response) {
                return null
            }
            if (this.response.originRewriteData && this.response.originRewriteData.rewrite_plot !== undefined) {
                return this.response.originRewriteData.rewrite_plot
            }
            if (!this.response.rewriteData || this.response.rewriteData.length <= 0 ) {
                return null
            }
            if (!this.response.rewriteData[0].rewrite_plot) {
                return null
            }
            return this.response.rewriteData[0].rewrite_plot
        },
        characterInfo() {
            const defaultCharacterInfo = {
                originalCount: 0,
                otherCount: 0,
                totalCount: 0,
                ratio: 0
            }
            if (!this.response) {
                return defaultCharacterInfo
            }
            if (this.response.article.characterInfo) {
                return this.response.article.characterInfo
            }
            return defaultCharacterInfo
        },
        article() {
            if (!this.response) {
                return null
            }
            return this.response.article
        },
        itemsCount() {
            if (!this.article) {
                return 0
            }
            if (!this.article.items) {
                return 0
            }
            return this.article.items.length
        },

        baseCharCountList()  {
            if (!this.response) {
                return []
            }
            return this.response.baseCharCountList
        },
        ccdLink()  {
            if (!this.article) {
                return null
            }
            if (!this.article.ccd) {
                return null
            }
            return this.article.ccd
        },
        dialogPlot() {
            return  this.rewritePlot ? this.rewritePlot : this.plot ? this.plot : {}
        },
        articleIncentives() {
            return this.response ? this.response.articleIncentives : []
        },
        articleIncentivePrices() {
            return this.response ? this.response.article.article_incentive_prices : []
        },
        canEditIncentive() {
            return this.response ? this.response.canEditIncentive : false
        },
        incentiveHistories() {
            return this.response ? this.response.incentiveHistories : []
        },
        isPublished() {
            return this.response ? this.response.isPublished : false
        },
        otherIncentiveParams() {
            if (! this.response) {
                return {
                    is_photo_incentive_target: false
                }
            } else if (this.response.rewriteData.length !== 0) {
                return {
                    is_photo_incentive_target: this.response.rewriteData[0].is_photo_incentive_target
                }
            } else {
                return {
                    is_photo_incentive_target: this.response.article.is_photo_incentive_target
                }
            }
        },
        isRewrite() {
            if (!this.response) {
                return false
            } else if (this.response.rewriteData.length === 0) {
                return false
            }

            return true
        },
        deleteCheckItems() {
            return this.checkedItems
                .map(item => { return item.number })
                .join(',')
        }
    },
    data () {
        return {
            orderPosition: false,
            updateOrdersMode: false,
            deleteItemsConfirm: false,
            openEditPoint: false,
            confirmPlot: false,
            confirmCcd: false,
            isActiveIncentiveModal: false,
        }
    },
    methods: {
        openPlotModal() {
            if ((this.plot && this.plot.plot)
                || (this.rewritePlot && this.rewritePlot.plot)) {
                this.confirmPlot = true
            } else {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'プロットが設定されていません。')
            }
        },
        deleteItems() {
            this.deleteItemsConfirm = false
            this.$emit('deleteItems')
        },
        updateOrderCheckedItems() {
            this.updateOrdersMode = true
            this.$emit('updateOrderCheckedItems',true)
        },
        finishUpdateOrderCheckedItems() {
            this.updateOrdersMode = false
            this.$emit('updateOrderCheckedItems',false)
        },
        saveUpdateOrder() {
            this.$emit('saveUpdateOrder')
        },
        doCopy() {
            this.$copyText(this.fetchCopyText(this.response.article)).then(() => {
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'クリップボードにコピーしました。')
            }, () => {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'コピーに失敗しました。')
            })
        },
        fetchCopyText(article) {
            const copyText = []
            if (article.format === 'normal' || article.format === 'rich') {
                // 記事タイトル
                if (article.title !== null) {
                    copyText.push(article.title)
                }
                // 記事ディスクリプション
                if (article.description !== null) {
                    copyText.push(article.description)
                }
                // 各アイテムに応じて取得する要素を変更する
                article.items.forEach(item => {
                    switch (item.item_type) {
                    case 'HeadingType':
                        if (item.content.heading_text !== null){
                            copyText.push(item.content.heading_text)
                        }
                        break
                    case 'TextType':
                        if (item.content.text_body !== null) {
                            copyText.push(this.convertHtmlToText(item.content.text_body))
                        }
                        break
                    case 'ImageType':
                        if (item.content.image_title !== null) {
                            copyText.push(item.content.image_title)
                        }
                        if (item.content.image_comment !== null) {
                            copyText.push(item.content.image_comment)
                        }
                        if (item.content.alt_text !== null) {
                            copyText.push(item.content.alt_text)
                        }
                        break
                    }
                })
            } else if (article.format === 'video') {
                // 記事タイトル
                if (article.title !== null) {
                    copyText.push(article.title)
                }
                // 記事ディスクリプション
                if (article.description !== null) {
                    copyText.push(article.description)
                }
                if (article.video_article.cook_name !== null) {
                    copyText.push(article.video_article.cook_name)
                }
                if (article.video_article.servings_min !== null) {
                    copyText.push(article.video_article.servings_min)
                }
                if (article.video_article.servings_max !== null) {
                    copyText.push(article.video_article.servings_max)
                }
                if (article.video_article.servings_free_text !== null) {
                    copyText.push(article.video_article.servings_free_text)
                }
                if (article.video_article.total_cooking_time !== null) {
                    copyText.push(article.video_article.total_cooking_time)
                }
                if (article.video_article.cooking_time !== null) {
                    copyText.push(article.video_article.cooking_time)
                }
                if (article.video_article.cooking_time_comment !== null) {
                    copyText.push(article.video_article.cooking_time_comment)
                }
                if (article.video_article.calorie !== null) {
                    copyText.push(article.video_article.calorie)
                }
                // 各アイテムに応じて取得する要素を変更する
                article.items.forEach(item => {
                    switch (item.item_type) {
                    case 'HeadingType':
                        if (item.content.heading_text !== null) {
                            copyText.push(item.content.heading_text)
                        }
                        break
                    case 'TextType':
                        copyText.push(this.convertHtmlToText(item.content.text_body))
                        break
                    case 'ImageType':
                        if (item.content.image_title !== null) {
                            copyText.push(item.content.image_title)
                        }
                        if (item.content.image_comment !== null) {
                            copyText.push(item.content.image_comment)
                        }
                        if (item.content.alt_text !== null) {
                            copyText.push(item.content.alt_text)
                        }
                        break
                    case 'MaterialType':
                        // eslint-disable-next-line no-case-declarations
                        let materialGroupName = null
                        for (const materialElement of item.content.elements) {
                            if (materialGroupName !== materialElement.material_group_name) {
                                materialGroupName = materialElement.material_group_name
                                if (materialGroupName !== null) {
                                    copyText.push(materialGroupName)
                                }
                            }
                            if (materialElement.material.material_name !== null) {
                                copyText.push(materialElement.material.material_name)
                            }
                            if (materialElement.quantity_text !== null) {
                                copyText.push(materialElement.quantity_text)
                            }
                        }
                        break
                    case 'RecipeType':
                        item.content.elements.forEach(ele => {
                            if (ele.recipe_text !== null) {
                                copyText.push(this.convertHtmlToText(ele.recipe_text))
                            }
                        })
                        break
                    }
                })
            }
            return copyText.reduce((p, c) => p + '\n' + c)
        },
        convertHtmlToText(str) {
            str = str.replace(/<p>(.*?)<\/p>/g, '$1\n').trim()
            const div = document.createElement('div')
            div.innerHTML = sanitizeHTML(str)
            return div.innerText
        },
        openIncentiveModal() {
            this.isActiveIncentiveModal = true
        },
        closeIncentiveModal() {
            this.isActiveIncentiveModal = false
        },
        async createArticleIncentivePrice(params) {
            // 通常記事以外は編集不可だが、将来編集可になる可能性があるため、viewのみで制御する
            if (! this.canEditIncentiveFormat) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '通常記事以外は登録できません。')
                return
            }

            this.$store.commit(types.PAGE_LOADING, true)

            const { data, error } = await axios.post('/api/articles/incentives/prices/'+this.article.id, params)
            if (error) {
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                    this.$store.commit(types.LOADING, false)
                    return
                }
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '登録が完了しました。')
            await this.fetch()
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async deleteArticleIncentivePrice(articleIncentivePriceId) {
            // 通常記事以外は編集不可だが、将来編集可になる可能性があるため、viewのみで制御する
            if (! this.canEditIncentiveFormat) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '通常記事以外は削除できません。')
                return
            }

            this.$store.commit(types.PAGE_LOADING, true)

            const { data, error } = await axios.delete('/api/articles/incentives/prices/'+this.article.id+'/'+articleIncentivePriceId)
            if (error) {
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                    this.$store.commit(types.LOADING, false)
                    return
                }
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '削除が完了しました。')
            await this.fetch()
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async updateOtherIncentive(params) {
            this.$store.commit(types.PAGE_LOADING, true)

            // リライトの場合エンドポイントを変更
            const url = this.isRewrite ? '/api/rewrites/incentives/other/'+this.response.rewriteData[0].id : '/api/articles/incentives/other/'+this.article.id

            const { data, error } = await axios.post(url, params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '更新が完了しました。')
            await this.fetch()
            this.$store.commit(types.PAGE_LOADING, false)
        }
    }
}
</script>
<style scoped>
.bottomControllerWrapper {
    margin-top:100px;
}

.bottomController {
    color: #606266;
    background-color: rgba(178,207,210,0.7);
    position:fixed !important;
    bottom:0;
    right:0;
    height:60px;
    width:100%;
    border-radius: 2px 2px 0 0;
    text-align:right;
    z-index:1000;
    display: flex;
    justify-content: flex-end;
}
.bottomController__characterCountWrapper {
    margin-top:0;
    font-size: 12px;
    background-color: #fff;
    margin-right:20px;
    padding:0 10px;
    background-color: rgba(255,255,255,0.9);
}

.updateOrdersMode > .bottomController {
    background-color: rgba(178,207,210,0.9);
    z-index:2000;
}
.bottonButton {
    margin: 15px 15px 15px 0 !important;
}
.dialogItemNumber {
    font-size:18px;
    font-weight:bold;
    word-break: break-all;
}
</style>
