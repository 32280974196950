<template>
    <div id="add_data">
        <el-input
                type="textarea"
                v-model="add_data.tag"
                style="width: 590px;"
                >
        </el-input>
        <el-select
                v-model="add_data.id"
                placeholder="追加する枠"
                style="width: 360px;">
            <el-option
                    v-for="unit in units"
                    :key="unit.id"
                    :label="unit.name"
                    :value="unit.id">
            </el-option>
        </el-select>
        <el-button
                type="primary"
                @click="addAdTag"
                >
            追加
        </el-button>
        <el-row v-for="(ad_tag, key) in adTags"
                :key="key"
        >
            <h3 class="type_heading">{{ unitIdOrder[key].name }}</h3>
            <el-row v-if="ad_tag">
                <el-table
                        :data="ad_tag"
                        class="ad_tag_table">
                    <el-table-column
                            label="広告タグ">
                        <template slot-scope="scope">
                            <el-input
                                    type="textarea"
                                    v-model="scope.row.tag">
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="今日のimp / imp上限"
                            width="200">
                        <template slot-scope="scope">
                            <div :class="impClassName(scope.row)">{{ scope.row.imp_count }}</div>
                            <el-input
                                    type="text"
                                    v-model="scope.row.imp_limit">
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="開始 / 終了日時"
                            width="420">
                        <template slot-scope="scope">
                            <el-date-picker
                                    v-model="scope.row.date_range"
                                    type="datetimerange"
                                    range-separator="〜"
                                    start-placeholder="開始日時"
                                    end-placeholder="終了日時">
                            </el-date-picker>
                        </template>
                    </el-table-column>
                    <el-table-column
                            width="80">
                        <template slot-scope="props">
                            <el-button
                                    type="warning"
                                    class="update-button"
                                    @click="updateAdTag(props.row)"
                                    size="mini">
                                更新
                            </el-button><br>
                            <el-button
                                    type="danger"
                                    size="mini"
                                    @click="deleteRow(props.row.id)">
                                削除
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="比率"
                            width="100">
                        <template slot-scope="scope">
                            <el-input-number
                                    v-model="scope.row.ratio"
                                    :controls="false"
                                    style="width: 60px; padding-right: 5px;"
                                    :min="0">
                            </el-input-number>%
                        </template>
                    </el-table-column>
                </el-table>

                <el-row class="ratio-update-button">
                        <div class="total_ratio">
                            <span :class="ratioClassName(totalRatio[key])">{{ totalRatio[key] }}%</span>
                        </div>
                        <el-button
                                type="warning"
                                @click="updateRatio(ad_tag)"
                                size="mini">
                            比率更新
                        </el-button>
                    </el-row>
            </el-row>
            <el-row v-else class="not_found">データが設定されていません</el-row>
    </el-row>
    <el-dialog
            title="広告タグ削除"
            :visible.sync="deleteConfirmDialog"
            width="30%"
            @close="closeDeleteDialog"
    >
        <span >広告タグを削除してよろしいですか？</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDeleteDialog">キャンセル</el-button>
            <el-button type="danger" @click="deleteAdTag">削除する</el-button>
        </span>
    </el-dialog>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
export default {
    components: {
    },
    data() {
        return {
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            add_data: {
                'id': null,
                'tag':'',
            },
            form: {},
            createDialog: false,
            dialog: false,
            units: {},
            tableData: [],
            adTags:[],
            totalRatio:[],
            unitIdOrder:[],
            deleteConfirmId: null,
            deleteConfirmDialog: false,
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '広告　>　広告タグ設定')
        this.fetch()
    },
    methods: {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const {data} = await axios.get('/api/ad_tag')
            this.adTags = data.adTags
            this.units = data.unit
            this.unitIdOrder = this.idKeyList(this.units)
            this.totalRatio = data.totalRatio
            this.tableData = _.map(this.adTags, (adTag) => {
                return [adTag]
            })

            this.$store.commit(types.PAGE_LOADING, false)
        },
        // 広告タグデータ追加
        async addAdTag() {
            this.$store.commit(types.LOADING, true)
            const params = {
                'tag': this.add_data.tag,
                'ad_tag_unit_id' : Number(this.add_data.id),
                'imp_count' : 0,
                'ratio'     : 0,
            }
            const { error } = await axios.post('/api/ad_tag', params)

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, '広告タグを追加しました')
            this.$store.commit(types.LOADING, false)

        },
        async deleteAdTag() {
            this.$store.commit(types.PAGE_LOADING, true)
            const { error } = await axios.delete('/api/ad_tag/' + this.deleteConfirmId)
            if (error) {
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.PAGE_LOADING, false)
            this.deleteConfirmId = null
            this.deleteConfirmDialog = false
            this.$store.commit(types.SHOW_INFO_MESSAGE, '広告タグを削除しました')
        },
        // 広告タグデータ更新
        async updateAdTag(row) {
            this.$store.commit(types.PAGE_LOADING, true)
            const params = {
                'tag' : row.tag,
                'ratio' : row.ratio,
                'imp_limit': row.imp_limit,
                'start_at': row.date_range ? this.getFormattedDateTime(row.date_range[0]) : null,
                'end_at': row.date_range ? this.getFormattedDateTime(row.date_range[1]) : null,
            }

            const { error } = await axios.patch('/api/ad_tag/' + row.id, params)
            this.$store.commit(types.PAGE_LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, '広告タグデータを更新しました')
        },
        async updateRatio(row) {
            this.$store.commit(types.PAGE_LOADING, true)
            let tagData = []
            let totalRatio = 0
            row.forEach(function(value){
                let rowData = {
                    'id': value.id,
                    'ratio': value.ratio,
                }
                tagData.push(rowData)
                totalRatio += parseInt(value.ratio)
            })
            const { error } = await axios.post('/api/ad_tag/ratio', tagData)
            this.$store.commit(types.PAGE_LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, '広告タグデータを更新しました')

        },

        idKeyList(list) {
            return _.keyBy(list, (data) => data.id)
        },
        // impクラス名
        impClassName(row) {
            let class_name = ''

            if (!row.imp_limit) return class_name

            if (parseInt(row.imp_limit) < parseInt(row.imp_count))
            {
                class_name = 'invalid_value'
            }
            return class_name
        },
        // 比率クラス名
        ratioClassName(ratio) {
            return parseInt(ratio) === 100 ? '': 'invalid_value'
        },

        deleteRow(id) {
            this.deleteConfirmId = id
            this.deleteConfirmDialog = true
        },
        closeDeleteDialog() {
            this.deleteConfirmId = null
            this.deleteConfirmDialog = false
        },
        // 日時フォーマッター
        getFormattedDateTime (date) {
            let target_date = new Date(date)
            let formatted_date =  target_date.getFullYear() + '-' + ('0'+(target_date.getMonth() + 1)).slice(-2) + '-' + ('0'+(target_date.getDate())).slice(-2)
            let formatted_time =  ('0'+(target_date.getHours())).slice(-2) + ':' + ('0'+(target_date.getMinutes())).slice(-2) + ':' + ('0'+(target_date.getSeconds())).slice(-2)
            return formatted_date + ' ' + formatted_time
        },
    }
}
</script>

<style scoped>
    #add_data {
        padding: 0 0 20px 10px;
    }
    #add_data div,
    #add_data button {
        vertical-align: top;
        margin-right: 5px;
    }
    #adtag {
        padding-bottom: 20px;
    }
    .el-table td {
        vertical-align: top;
    }
    h3 {
        margin-top: 20px;
    }
    .update-button {
        margin-bottom: 10px;
    }
    .total_ratio {
        display: inline-block;
        padding-right: 20px;
    }
    .invalid_value {
        color: #ff0000;
    }
    .ratio-update-button {
        text-align: right;
        padding: 10px 10px 0 0;
    }
    .el-textarea.is-disabled .el-textarea__inner {
        color: #5a5e66;
    }

</style>
