<template>
    <el-row class="cooperateImage">
        <el-col :span="4">
            <el-radio-group v-model="cooperate">
                <el-radio label="shutterstock" class="serviceRadio">
                        <span class="serviceLabel">
                            <img src="/images/cooperate/shutterstock.png" alt="">
                        </span>
                </el-radio>
                <el-radio label="recipeblog" class="serviceRadio">
                        <span class="serviceLabel">
                            <img src="/images/cooperate/recipeblog.gif" alt="">
                        </span>
                </el-radio>
                <el-radio label="snapdish" class="serviceRadio">
                    <span class="serviceLabel">
                        <img src="/images/cooperate/snapdish.png" alt="">
                    </span>
                </el-radio>
                <el-radio label="nadia" class="serviceRadio">
                        <span class="serviceLabel">
                            <img src="/images/cooperate/nadia.png" alt="">
                        </span>
                </el-radio>
                <el-radio label="rakuten-recipe" class="serviceRadio">
                    <span class="serviceLabel">
                        <img src="/images/cooperate/rakuten-recipe.gif" alt="">
                    </span>
                </el-radio>
                <el-radio label="ikkyu" class="serviceRadio">
                    <span class="serviceLabel">
                        <img src="/images/cooperate/ikkyu.png" alt="">
                    </span>
                </el-radio>
                <el-radio label="gurunavi" class="serviceRadio">
                        <span class="serviceLabel">
                            <img src="/images/cooperate/gurunavi.png" alt="">
                        </span>
                </el-radio>
                <el-radio label="macaroniVideo" class="serviceRadio">
                        <div class="serviceLabel" style="padding: 7px 0; font-weight: bold;">
                            macaroni動画
                        </div>
                </el-radio>
            </el-radio-group>
        </el-col>
        <el-col :span="20">
            <shutterstock
                :class="selectedMedia('shutterstock')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                @saveIcon="saveIconFromUrl"
                :imageType="imageType"
                :arrengeList="arrengedList"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
            <recipeblog
                :class="selectedMedia('recipeblog')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                @saveIcon="saveIconFromUrl"
                :imageType="imageType"
                :arrengeList="arrengedList"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
            <snapdish
                :class="selectedMedia('snapdish')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                @saveIcon="saveIconFromUrl"
                :imageType="imageType"
                :arrengeList="arrengedList"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
            <nadia
                :class="selectedMedia('nadia')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                @saveIcon="saveIconFromUrl"
                :imageType="imageType"
                :arrengeList="arrengedList"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
            <rakuten-recipe
                :class="selectedMedia('rakuten-recipe')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                @saveIcon="saveIconFromUrl"
                :imageType="imageType"
                :arrengeList="arrengedList"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
            <rakuten
                :class="selectedMedia('rakuten')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                @saveIcon="saveIconFromUrl"
                :imageType="imageType"
                :arrengeList="arrengedList"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
            <amazon
                :class="selectedMedia('amazon')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                @saveIcon="saveIconFromUrl"
                :imageType="imageType"
                :arrengeList="arrengedList"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
            <ikkyu
                :class="selectedMedia('ikkyu')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                @saveIcon="saveIconFromUrl"
                :imageType="imageType"
                :arrengeList="arrengedList"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
            <gurunavi
                :class="selectedMedia('gurunavi')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                @saveIcon="saveIconFromUrl"
                :imageType="imageType"
                :arrengeList="arrengedList"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
            <macaroni-video
                :class="selectedMedia('macaroniVideo')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                @saveIcon="saveIconFromUrl"
                :imageType="imageType"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
        </el-col>
    </el-row>
</template>
<script>
import Amazon from './Cooperate/Amazon'
import Gurunavi from './Cooperate/Gurunavi'
import Ikkyu from './Cooperate/Ikkyu'
import Nadia from './Cooperate/Nadia'
import Rakuten from './Cooperate/Rakuten'
import RakutenRecipe from './Cooperate/RakutenRecipe'
import Recipeblog from './Cooperate/Recipeblog'
import Shutterstock from './Cooperate/Shutterstock'
import Snapdish from './Cooperate/Snapdish'
import MacaroniVideo from './MacaroniVideo'

export default {
    data () {
        return {
            searchKeyword:'',
            cooperate: 'shutterstock',
            page: 1,
            nextPage: 0,
            articles: [],
            tags: [],
        }
    },
    props: {
        imageType: String,
    },
    components: {
        Amazon,
        Gurunavi,
        Ikkyu,
        Nadia,
        Rakuten,
        RakutenRecipe,
        Recipeblog,
        Shutterstock,
        Snapdish,
        MacaroniVideo,
    },
    methods:{
        saveIconFromUrl(url) {
            this.$emit('saveIcon', url, true)
        },
        saveItemFromMedia(media, form) {
            if ('image_title' in form) {
                form.image_title = form.image_title.substring(0, 60)
            }
            this.$emit('save', media, form)
        },
        arrengedList(list) {
            let dist = [[]]
            list.forEach((el) => {
                let tail = dist.length - 1
                dist[tail].push(el)

                if (dist[tail].length > 4 - 1){
                    dist.push([])
                }
            })
            return dist
        },
        selectedMedia(mediaName) {
            if (this.cooperate === mediaName) {
                return 'selected'
            }
            return 'unselected'
        },
    },
}
</script>
<style scoped>
.selected {
    display: block;
}
.unselected {
    display: none;
}
.serviceRadio {
    border: 1px solid #ccc;
    border-top: 0;
    width: 150px;
    text-align: center;
    padding: 5px 10px;
}
.serviceRadio:first-child {
    border-top: 1px solid #ccc;
}
.serviceLabel > img{
    height:19px;
    padding-top: 6px;
}
</style>
<style>
.serviceRadio .el-radio__input {
    display: none;
}
.serviceRadio .el-radio__label {
    padding: 0;
}
.serviceRadio.is-checked {
    background-color: #f7f7f7;
}
.cooperateImage .itemForm {
    margin-left: 10px;
    margin-top: 0;
}
.cooperateImage .loading {
    padding: 40px 0;
}
.cooperateImage .thumbnails {
    margin:10px;
    position: relative;
}
.cooperateImage .thumbnails img {
    width:210px;
}
.cooperateImage .thumbnails .addButton {
    position: absolute;
    top: 53px;
    left: 65px;
    display: none;
}
.cooperateImage .thumbnails:hover img {
    filter: brightness(50%);
}
.cooperateImage .thumbnails:hover .addButton {
    display: block;
}
.cooperateImage .thumbnailText {
    margin:0px;
    padding-bottom: 20px;
    font-size: 12px;
    line-height: 1.5;
}
</style>
