<template>
    <el-dialog
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
    >
        <p>本登録が完了しました。ログイン画面からログインしてご利用ください。</p>
    </el-dialog>
</template>

<script>
import { PAGE_TITLE , LOADING } from '../store/mutation-types'
import * as types from '../store/mutation-types'

export default {
    created() {
        this.$store.commit(types.LOADING, true)
        this.$store.commit(PAGE_TITLE, '管理者登録確認')
        this.verify()
    },
    data() {
        return {
            isActive: false,
        }
    },
    methods: {
        // token確認
        async verify() {
            this.$store.commit(LOADING, true)
            const token = this.$route.params.token

            if (! token) {
                this.$router.push('/login')
            }

            const { error } = await axios.post('/api/admins/email/verify', { token })

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$router.push('/login')
            }

            this.$store.commit(LOADING, false)
            this.isActive = true
        },
        close() {
            this.isActive = false
            this.$router.push('/login')
        }
    }
}
</script>
