<template>
    <div>
        <el-row>
            <item-left-contents
                :check-state="checkState"
                :number="number"
                :page-number="item.page_number"
                @update:checkState="checkState = $event"
            />

            <el-col class="contents" :span="21">
                <recipe-link-type
                    v-if="isEditing"
                    ref="dialogModule"
                    :id="content.id"
                    :content="content"
                    :save="update"
                    :article-format="articleFormat"
                    :heading-type-options="response.headingTypeList"
                    @close="closeMenu"
                ></recipe-link-type>
                <div v-else class="recipeLink">
                    <div class="recipeLink__imgWrapper">
                        <img :src="content.quote_article.thumbnail_cdn_url" class="recipeLink__img" />
                        <div class="recipeLink__materialWrapper">
                            <div class="recipeLink__cookingTime">調理時間：{{ content.quote_article.video_article.video_article_cooking_time }}</div>
                            <div class="recipeLink__materialListWrapper">
                                <span v-for="(materialItem, materialItemKey) in content.quote_article.material_items" :key="materialItemKey">
                                    <span
                                        v-for="(element, elementKey) in materialItem.elements"
                                        :key="elementKey"
                                        class="recipeLink__materialName"
                                    >{{ element.material.material_name }}&nbsp;&nbsp;</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="recipeLink__nutrientWrapper">
                        <div class="recipeLink__nutrientContent">エネルギー：{{ content.quote_article.video_article.calorie }}kcal</div>
                        <div class="recipeLink__nutrientContent">タンパク質：{{ content.quote_article.video_article.protein }}g</div>
                        <div class="recipeLink__nutrientContent">脂質：{{ content.quote_article.video_article.lipid }}g</div>
                        <div class="recipeLink__nutrientContent">炭水化物：{{ content.quote_article.video_article.carbohydrate }}g</div>
                        <div class="recipeLink__nutrientContent">塩分量：{{ content.quote_article.video_article.salt }}g</div>
                        <div class="recipeLink__nutrientContent">糖質：{{ content.quote_article.video_article.saccharinity }}g</div>
                    </div>
                    <div class="recipeLink__description">{{ content.description }}</div>
                    <div class="recipeLink__linkWrapper">
                        <a :href="`https://macaro-ni.jp/${content.quote_article_id}`" target="_blank">{{ content.quote_article.title }}</a>
                    </div>
                </div>
            </el-col>
            <el-col class="rightBox" :span="2" justify="end" v-if="!updateOrdersMode">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList">下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', resourceName, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>

    </div>
</template>
<script>

import FeedbackForm from './Components/FeedbackForm'
import RecipeLinkType from '../Form/RecipeLinkType'
import ItemLeftContents from './Components/ItemLeftContents'

export default {
    components: {
        ItemLeftContents,
        RecipeLinkType,
        FeedbackForm,
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
        articleFormat: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            isEditing: false,
        }
    },
    computed: {
        content() {
            return this.item.content
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        resourceName() {
            return 'recipe_links'
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check, item) {
            this.parentCheckItem(check, item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>
<style scoped lang="scss">
.recipeLink {
    &__img {
        width: 150px;
        margin-right: 15px;
    }
    &__imgWrapper {
        display: flex;
        margin-bottom: 15px;
    }
    &__materialWrapper {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
    }
    &__nutrientWrapper {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
    }
    &__nutrientContent {
        &:nth-child(odd) {
            width: 30%;
        }
        &:nth-child(even) {
            width: 70%;
        }
    }
    &__description {
        margin-bottom: 15px;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}


.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}
</style>
