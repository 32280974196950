<template>
    <el-row class="wysiwygEditor">
        <editor-menu-bubble :editor="editor" :keep-in-bounds="true" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
            <div
                class="menububble"
                :class="{ 'is-active': menu.isActive }"
                :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
            >
                <button
                    class="menububble__button"
                    :class="{ 'is-active': isActive.textSmall() }"
                    @click="commands.textSmall"
                >小</button>
                <button
                    class="menububble__button"
                    :class="{ 'is-active': isActive.textLarge() }"
                    @click="commands.textLarge"
                >大</button>
                <button
                    class="menububble__button"
                    :class="{ 'is-active': isActive.textBold() }"
                    @click="commands.textBold"
                >太字</button>
                <button
                    class="menububble__button"
                    :class="{ 'is-active': isActive.textLargeBold() }"
                    @click="commands.textLargeBold"
                >太字&大</button>
                <button
                    class="menububble__button"
                    :class="{ 'is-active': isActive.colorRed() }"
                    @click="commands.colorRed"
                >赤</button>
                <button
                    class="menububble__button"
                    :class="{ 'is-active': isActive.textItalic() }"
                    @click="commands.textItalic"
                >斜体</button>
                <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                    <input class="menububble__input" type="text" v-model="linkUrl" placeholder="記事IDかURL" @keydown.esc="hideLinkMenu"/>
                    <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
                        <i class="fas fa-trash-alt"></i>
                    </button>
                </form>

                <template v-else>
                    <button
                        class="menububble__button"
                        @click="showLinkMenu(getMarkAttrs('link'))"
                        :class="{ 'is-active': isActive.link() }"
                    >
                        <span><i class="fas fa-link"></i>{{ isActive.link() ? '更新' : '追加'}}</span>
                    </button>
                </template>
                <button
                    class="menububble__button"
                    @click="commands.undo"
                ><i class="fas fa-undo"></i></button>
                <button
                    class="menububble__button"
                    @click="commands.redo"
                ><i class="fas fa-redo"></i></button>
            </div>
        </editor-menu-bubble>
        <editor-content :editor="editor" />
    </el-row>
</template>

<script>
import {Editor, EditorContent, EditorMenuBubble, Extension} from 'tiptap'
import {HardBreak, History} from 'tiptap-extensions'
import ColorRed from '../Components/Tiptap/ColorRed'
import TextLargeBold from '../Components/Tiptap/TextLargeBold'
import TextBold from '../Components/Tiptap/TextBold'
import TextLarge from '../Components/Tiptap/TextLarge'
import TextSmall from '../Components/Tiptap/TextSmall'
import CustomLink from '../Components/Tiptap/CustomLink'
import TextItalic from '../Components/Tiptap/TextItalic'

import * as types from '../../../../../../store/mutation-types'

export default {
    components: {
        Editor,
        EditorContent,
        EditorMenuBubble,
        Extension,
    },
    props: {
        value: {
            type: String|null,
            required: true,
        },
        // editorを配列で保持する場合
        isArray: {
            type: Boolean,
            required: false,
            default: false,
        },
        // editorを配列で保持する場合、lengthを渡す
        editorTotalCount: {
            type: Number,
            required: false,
            default: 0,
        },
        editorViewStyle: {
            type: String,
            required: false,
            default: '',
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
    },
    data () {
        return {
            editor: null,
            linkUrl: null,
            linkMenuIsActive: false,
        }
    },
    created() {
        this.initEditor()
    },
    methods: {
        initEditor() {
            this.editor = new Editor({
                onUpdate: () => {
                    this.$emit('input', this.contentHtml)
                },
                content: this.value,
                editorProps: {
                    attributes: {
                        style: this.editorViewStyle,
                        class: 'editorView',
                        'data-placeholder': this.value ? '' : this.placeholder,
                    },
                },
                extensions: [
                    new HardBreak(),
                    new class extends Extension {
                        keys() {
                            return {
                                Enter(state, dispatch, view) {
                                    const {schema, doc, tr} = view.state
                                    const hard_break = schema.nodes.hard_break
                                    const transaction = tr.replaceSelectionWith(hard_break.create()).scrollIntoView()
                                    view.dispatch(transaction)
                                    return true
                                }
                            }
                        }
                    }(),
                    new ColorRed(),
                    new TextLargeBold(),
                    new TextBold(),
                    new TextLarge(),
                    new TextSmall(),
                    new CustomLink(),
                    new TextItalic(),
                    new History(),
                ],
            })
        },
        hideLinkMenu() {
            this.linkUrl = null
            this.linkMenuIsActive = false
        },
        setLinkUrl(command, url) {
            if (typeof url !== 'string') {
                command({ href: url })
                this.hideLinkMenu()
                return
            }
            if (url.match(/^[0-9]+$/) !== null) {
                command({ href: 'https://macaro-ni.jp/'+url })
                this.hideLinkMenu()
                return
            }
            if (url.match(/^https?(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)$/) === null) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'URLではありません。')
                return
            }
            if (url.indexOf('macaro-ni.jp') === -1) {
                this.$store.commit(types.SHOW_INFO_MESSAGE, '注意!macaroniのリンクではありません。')
            }
            command({ href: url })
            this.hideLinkMenu()
        },
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href
            this.linkMenuIsActive = true
            this.$nextTick(() => {
                this.$refs.linkInput.focus()
            })
        },
    },
    computed:{
        contentHtml () {
            return this.editor.getHTML().replace(/^<p>/, '').replace(/<\/p>$/, '')
        },
    },
    watch: {
        value() {
            const isSame = this.contentHtml === this.value
            // 親コンポーネントの更新時はeditor内も更新
            if (!isSame) {
                this.editor.setContent(this.value)
            }
            // プレースホルダーの表示切り替え
            if (this.placeholder) {
                this.editor.view.dom.setAttribute('data-placeholder', this.value ? '' : this.placeholder)
            }
        },
        editorTotalCount() {
            // 配列でeditorを保持する場合、要素削除時にvalueが更新されないため、配列のlengthが変更されたら初期化する
            if (this.isArray) {
                this.editor.destroy()
                this.initEditor()
            }
        }
    },
    beforeDestroy() {
        this.editor.destroy()
    },
}
</script>

<style scoped lang="scss">
$color-black: #000000;
$color-white: #ffffff;
.wysiwygEditor {
    position: static;
}
.editor {
    position: relative;
    max-width: 30rem;
    margin: 0 auto 5rem auto;

    &__content {
        pre {
            padding: 0.7rem 1rem;
            border-radius: 5px;
            background: $color-black;
            color: $color-white;
            font-size: 0.8rem;
            overflow-x: auto;

            code {
                display: block;
            }
        }

        p code {
            display: inline-block;
            padding: 0 0.4rem;
            border-radius: 5px;
            font-size: 0.8rem;
            font-weight: bold;
            background: rgba($color-black, 0.1);
            color: rgba($color-black, 0.8);
        }

        ul,
        ol {
            padding-left: 1rem;
        }

        li > p,
        li > ol,
        li > ul {
            margin: 0;
        }

        a {
            color: inherit;
        }

        blockquote {
            border-left: 3px solid rgba($color-black, 0.1);
            color: rgba($color-black, 0.8);
            padding-left: 0.8rem;
            font-style: italic;

            p {
                margin: 0;
            }
        }

        img {
            max-width: 100%;
            border-radius: 3px;
        }

    }
}
::v-deep .editorView::before {
    content: attr(data-placeholder);
    position: absolute;
    opacity: 0.5;
}
.menububble {
    position: absolute;
    display: flex;
    z-index: 20;
    background: $color-black;
    border-radius: 5px;
    padding: 0.3rem;
    margin-bottom: 0.5rem;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }

    &__button {
        display: inline-flex;
        background: transparent;
        border: 0;
        color: $color-white;
        padding: 0.2rem 0.5rem;
        margin-right: 0.2rem;
        border-radius: 3px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background-color: rgba($color-white, 0.1);
        }

        &.is-active {
            background-color: rgba($color-white, 0.5);
        }
    }

    &__form {
        display: flex;
        align-items: center;
    }

    &__input {
        font: inherit;
        border: none;
        background: transparent;
        color: $color-white;
        width: 200px;
    }
}

.menubar {
    margin-bottom: 1rem;
    transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

    &.is-hidden {
        visibility: hidden;
        opacity: 0;
    }

    &.is-focused {
        visibility: visible;
        opacity: 1;
        transition: visibility 0.2s, opacity 0.2s;
    }

    &__button {
        font-weight: bold;
        display: inline-flex;
        background: transparent;
        border: 0;
        color: $color-black;
        padding: 0.2rem 0.5rem;
        margin-right: 0.2rem;
        border-radius: 3px;
        cursor: pointer;

        &:hover {
            background-color: rgba($color-black, 0.05);
        }

        &.is-active {
            background-color: rgba($color-black, 0.1);
        }
    }
}

</style>
