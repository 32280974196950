<template>
    <el-col class="leftBox" :span="1">
        <el-row>
            <el-checkbox
                class="itemCheckBox"
                :value="checkState"
                @change="$emit('update:checkState', $event)"
            >{{ number }}</el-checkbox>
        </el-row>
        <el-row>
            <div class="pageNumber">{{ pageNumber }}P</div>
        </el-row>
    </el-col>
</template>

<script>
export default {
    props: {
        checkState: {
            required: true,
            type: Boolean,
        },
        number: {
            required: true,
            type: Number,
        },
        pageNumber: {
            required: true,
            type: Number,
        }
    },
}
</script>

<style scoped>
.leftBox {
    width: 45px;
}
.pageNumber {
    display: inline;
    font-size: 12px;
    margin-top: 5px;
    padding: 2px 3px;
    color: #909399;
    background: #f4f4f5;
    border-radius: 4px;
    border: 1px solid #e9e9eb;
}
</style>
