<template>
    <el-dialog
            title="APPタイムラインタブ追加"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>タブ名</dt>
                <dd>
                    <el-input class="tab-name-input" placeholder="タブ名" v-model="tabName"></el-input>
                </dd>
                <dt>タブ種別</dt>
                <dd>
                    <el-select class="type-select" v-model="type" @change="changeType" placeholder="タブ種別">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </dd>
                <dt>詳細</dt>
                <dd v-if="type === 'tag'">
                    <div>
                        <el-select
                            class="type-tag-select"
                            v-model="selectParentTagIds"
                            multiple
                            filterable
                            remote
                            reserve-keyword
                            :remote-method="searchRemote"
                            :loading="loading"
                            :multiple-limit="limit"
                            @focus="focusSubOptions"
                            placeholder="親タグ"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="parentTag in parentTags"
                                :key="parentTag.id"
                                :label="parentTag.tag_name"
                                :value="parentTag.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-select
                            class="type-tag-select"
                            v-model="selectChildTagIds"
                            multiple
                            filterable
                            remote
                            reserve-keyword
                            :remote-method="searchRemote"
                            :loading="loading"
                            :multiple-limit="limit"
                            @focus="focusSubOptions"
                            placeholder="子タグ"
                            style="width: 100%"
                        >
                            <el-option-group
                                v-for="(childTag, key) in childTags"
                                :key="key"
                                :label="key"
                            >
                                <el-option
                                    v-for="tag in childTag.tags"
                                    :key="tag.id"
                                    :label="tag.tag_name"
                                    :value="tag.id">
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </div>
                    <div>
                        <el-select
                            class="type-tag-select"
                            v-model="selectGrandchildTagIds"
                            multiple
                            filterable
                            remote
                            reserve-keyword
                            :remote-method="searchRemote"
                            :loading="loading"
                            :multiple-limit="limit"
                            @focus="focusSubOptions"
                            placeholder="孫タグ"
                            style="width: 100%"
                        >
                            <el-option-group
                                v-for="(grandchildTag, key) in grandchildTags"
                                :key="key"
                                :label="key"
                            >
                                <el-option
                                    v-for="tag in grandchildTag.tags"
                                    :key="tag.id"
                                    :label="tag.tag_name"
                                    :value="tag.id">
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </div>
                </dd>
                <dd v-else>
                    <el-select
                        class="type-detail-select"
                           v-model="typeDetail"
                           multiple
                           filterable
                           remote
                           reserve-keyword
                           :remote-method="searchRemote"
                           :loading="loading"
                           :multiple-limit="limit"
                           @focus="focusSubOptions"
                           placeholder="詳細">
                        <el-option
                            v-for="item in subOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </dd>
                <dt>表示</dt>
                <dd>
                    <el-checkbox v-model="tabIsActive"></el-checkbox>
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="add">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../store/mutation-types'

export default {
    props  : {
        isActive: Boolean,
        options : Object,
    },
    data() {
        return {
            tabName: '',
            subOptions: [],
            oldSubOptions: [],
            selectParentTagIds: [],
            selectChildTagIds: [],
            selectGrandchildTagIds: [],
            parentTags: [],
            childTags: [],
            grandchildTags: [],
            type: '',
            typeDetail: [],
            tabIsActive: false,
            loading: false,
            limit: 1,
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        // 追加
        async add() {
            this.$store.commit(types.LOADING, true)
            const params = {
                tab_name: this.tabName,
                type: this.type,
                ids: this.typeDetail,
                is_active: this.tabIsActive,
                parent_tag_ids: this.selectParentTagIds,
                child_tag_ids: this.selectChildTagIds,
                grandchild_tag_ids: this.selectGrandchildTagIds,
            }
            const {data, error} = await axios.post('/api/organization/app_tabs', params)
            if (error) {
                this.$store.commit(types.LOADING, false)
                return
            }
            this.tabName = ''
            this.type = ''
            this.typeDetail = []
            this.tabIsActive = false
            this.selectParentTagIds = []
            this.selectChildTagIds = []
            this.selectGrandchildTagIds = []
            this.$emit('close')
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの追加に成功しました。')
            this.$emit('reload')
        },
        // 詳細の検索
        async searchRemote(query) {
            if (query === '') {
                this.subOptions = this.oldSubOptions
                return
            }

            this.loading = true
            // 固定値ベタ書きしているのでなんとかしたい
            if (this.type === 'tag') {
                const {data, error} = await axios.get('/api/organization/app_tabs/search_tag_options', {
                    params: {
                        tag_name: query
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'データの取得に失敗しました。')
                    return
                }
                this.parentTags = data.parent_tags
                this.childTags = data.child_tags
                this.grandchildTags = data.grandchild_tags
                this.loading = false
            } else {
                this.subOptions = this.subOptions.filter(item => {
                    return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
                })
                this.loading = false
            }
        },
        // 検索文字列がなくなった時に元に戻す
        focusSubOptions() {
            if (this.typeDetail === []) {
                this.subOptions = this.oldSubOptions
            }
        },
        // タイプが変更された時
        changeType(value) {
            this.typeDetail = []
            this.limit = value === 'tag' ? 0 : 1
            this.subOptions = this.options[value].sub
            this.oldSubOptions = this.options[value].sub
        },
    },
    watch  : {
        options() {
        },
    }
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            width: 30%;
            padding-top: 2%;
        }

        dd {
            margin-bottom:3%;
            width: 70%;
        }

        .type-detail-select,
        .type-select {
            width: 400px;
        }
        .type-tag-select {
            margin-bottom: 10px;
        }
    }
</style>
