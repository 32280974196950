<template>
    <div>
        <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
                <el-input
                    v-model="formData.app_topic_id"
                    type="number"
                    placeholder="見つける特集ID"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button
                    @click="createAppRecipeSmallTopic"
                    type="primary"
                >
                    新規作成
                </el-button>
            </el-form-item>
            <p style="color: red;">※適用されるのは追加順の1件です</p>
        </el-form>

        <el-table
            :data="tableData"
            :row-class-name="tableRowClassName"
            style="width: 100%"
            max-height="800"
        >
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="見つける特集ID" prop="app_topic_id"></el-table-column>
            <el-table-column label="見つける特集タイトル">
                <template slot-scope="scope">{{ scope.row.app_topic.title }}</template>
            </el-table-column>
            <el-table-column label="作成日時" prop="created_at"></el-table-column>
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                        class="btn-danger"
                        size="mini"
                        type="danger"
                        @click="deleteAppRecipeSmallTopic(scope.row.id)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'

export default {
    data() {
        return {
            tableData: [],
            formData: {
                app_topic_id: null,
            },
            previewIcon: ''
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'アプリ　>　レシピタブ　>　小特集')
        this.fetch()
    },
    methods: {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data, error } = await axios.get('/api/organization/app_recipe_small_topics')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            this.tableData = data
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async deleteAppRecipeSmallTopic(id) {
            if (! confirm('本当に削除しますか？')) {
                return
            }

            this.$store.commit(types.PAGE_LOADING, true)

            const { error } = await axios.delete('/api/organization/app_recipe_small_topics/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '削除しました')
            this.fetch()
        },
        async createAppRecipeSmallTopic() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { error } = await axios.post('/api/organization/app_recipe_small_topics', this.formData)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            this.formData = {
                app_topic_id: null,
            }
            this.fetch()
        },
        tableRowClassName({row, rowIndex}) {
            if (rowIndex === 0) {
                return 'active-topic'
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .active-topic {
        background-color: #e3f6ce;
    }
</style>
