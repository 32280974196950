<template>
    <div class="target-table">
        <el-table
            :data="target"
            style="width: 100%"
        >
            <el-table-column label="配信中バナー">
                <el-table-column label="キャンペーン ID" width="70">
                <template slot-scope="scope">
                    <div class="target-column">
                        {{ scope.row.id }}
                    </div>
                </template>
                </el-table-column>
                <el-table-column :render-header="renderTableHeader(['記事ID','特集ID','連載ID', '外部URL'])">
                    <template slot-scope="scope">
                        <div class="target-column-id" v-if="scope.row.article_id">
                            記事 ID
                            <br>
                            {{ scope.row.article_id }}
                        </div>
                        <div class="target-column" v-else-if="scope.row.topic_id">
                            特集 ID
                            <br>
                            {{ scope.row.topic_id }}
                        </div>
                        <div class="target-column" v-else-if="scope.row.serialization_id">
                            連載 ID
                            <br>
                            {{ scope.row.serialization_id }}
                        </div>
                        <div class="target-column" v-else>
                            外部URL
                            <br>
                            {{ scope.row.link_url }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="バナー" width="350">
                    <template slot-scope="scope">
                        <div class="image">
                            <label :for="'image_' + scope.row.id">
                                <img v-if="scope.row.image" :src="scope.row.image_preview" width="300" alt="サムネイル">
                            </label>
                        </div>
                        <div class="image">
                            <label :for="'image_' + scope.row.id">
                                <img v-if="scope.row.image2" :src="scope.row.image_preview2" width="300" alt="サムネイル">
                            </label>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="タイトル" width="250">
                    <template slot-scope="scope">
                        <div class="target-column">
                            {{ scope.row.title }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="160" :render-header="renderTableHeader(['公開開始日時','公開終了日時'])">
                    <template slot-scope="scope">
                        {{ scope.row.published_start_at }}
                        <br>
                        <div class="date">
                            〜
                        </div>
                        <br>
                        {{ scope.row.published_end_at }}
                    </template>
                </el-table-column>
                <el-table-column label="優先度">
                    <template slot-scope="scope">
                        <div class="target-column">
                            {{ scope.row.order_num }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="配信チャネル">
                    <template slot-scope="scope">
                        <div class="target-column">
                            {{ scope.row.chosenChanel }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :render-header="renderTableHeader(['広告フラグ','(内部)'])">
                    <template slot-scope="scope">
                        <div class="target-column">
                        {{scope.row.is_tieup}}
                        </div>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
        <el-row type="flex" justify="end">
            <el-header >
                <el-col>
                    <el-button class="add-button" type="primary" @click="openRegistrationModal()">
                        新規追加
                    </el-button>
                </el-col>
            </el-header >
        </el-row>
        <div class="all-table">
            <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
            <el-table :data="tableData" style="font-size: 12px" max-height="800">
                <el-table-column label="キャンペーン ID" width="70">
                    <template slot-scope="scope">
                        {{ scope.row.id }}
                    </template>
                </el-table-column>
                <el-table-column :render-header="renderTableHeader(['記事ID','特集ID','連載ID', '外部URL'])" width="150">
                    <template slot-scope="scope">
                        <div class="text" v-if="scope.row.article_id">
                            記事ID<el-input v-model="scope.row.article_id" placeholder="記事ID" size="mini"></el-input>
                        </div>
                        <div class="text" v-else-if="scope.row.topic_id">
                           特集ID<el-input v-model="scope.row.topic_id" placeholder="特集ID" size="mini"></el-input>
                        </div>
                        <div class="text" v-else-if="scope.row.serialization_id">
                            連載ID<el-input v-model="scope.row.serialization_id" placeholder="連載ID" size="mini"></el-input>
                        </div>
                        <div class="text" v-else>
                            外部URL<el-input
                                type="textarea"
                                :autosize="{ minRows: 1, maxRows: 10}"
                                :cols="40"
                                v-model="scope.row.link_url"/>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :render-header="renderTableHeader(['バナー','比率'])" width="270">
                    <template slot-scope="scope">
                        <label :for="'image_' + scope.row.id">
                            <img v-if="scope.row.image" :src="scope.row.image_preview" width="240" alt="バナー">
                            <el-button v-else size="small" type="primary">画像選択</el-button>
                            <input type="file" accept="image/png,image/jpeg,image/gif" style="display: none;" :id="'image_' + scope.row.id" @change="selectedFile"/>
                        </label>
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 1, maxRows: 10}"
                            :cols="40"
                            v-model="scope.row.ratio"/>
                    </template>
                </el-table-column>
                <el-table-column :render-header="renderTableHeader(['バナー2','比率'])" width="270">
                    <template slot-scope="scope">
                        <label :for="'image2_' + scope.row.id">
                            <img v-if="scope.row.image2" :src="scope.row.image_preview2" width="240" alt="バナー">
                            <el-upload
                                v-else
                                class="upload-file"
                                action=""
                                :auto-upload="false"
                                :on-remove="handleRemove"
                                :on-change="handleAdd"
                                list-type="picture"
                                :multiple="false"
                                :limit="1">
                                <el-button size="small" type="primary">画像を選択して下さい</el-button>
                            </el-upload>
                            <input type="file" accept="image/png,image/jpeg,image/gif" style="display: none;" :id="'image2_' + scope.row.id" @change="selectedFile2"/>
                        </label>
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 1, maxRows: 10}"
                            :cols="40"
                            v-model="scope.row.ratio2"/>

                    </template>
                </el-table-column>
                <el-table-column label="タイトル" width="180">
                    <template slot-scope="scope">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 1, maxRows: 10}"
                            :cols="40"
                            v-model="scope.row.title"/>
                    </template>
                </el-table-column>
                <el-table-column :render-header="renderTableHeader(['公開開始日時','公開終了日時'])" width="200">
                    <template slot-scope="scope">
                        <el-date-picker
                            v-model="scope.row.published_start_at"
                            type="datetime"
                            style="width:100%; margin-bottom:15px;"
                            size="mini"
                            placeholder="公開開始日時">
                        </el-date-picker>
                        <br>
                        <el-date-picker
                            v-model="scope.row.published_end_at"
                            type="datetime"
                            style="width:100%"
                            size="mini"
                            placeholder="公開終了日時">
                        </el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column label="優先度" width="80">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.order_num">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="配信チャネル" width="100">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.is_distribute_ios">iOS</el-checkbox>
                        <el-checkbox v-model="scope.row.is_distribute_android">android</el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column :render-header="renderTableHeader(['広告フラグ','(内部)'])">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.is_tieup"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column :render-header="renderTableHeader(['【デイリー】','クリック数','上限クリック数'])">
                    <template slot-scope="scope">
                        <div class="text" v-if="scope.row.daily_click_count">
                            {{ scope.row.daily_click_count }}
                        </div>
                        <div class="text" v-else>
                            0
                        </div>
                        <el-input v-model="scope.row.daily_limit_click_count" placeholder="【デイリー】上限数" size="mini"></el-input>
                    </template>
                </el-table-column>
                <el-table-column :render-header="renderTableHeader(['【合計】','クリック数','上限クリック数'])">
                    <template slot-scope="scope">
                        <div class="text" v-if="scope.row.click_count">
                            {{ scope.row.click_count }}
                        </div>
                        <div class="text" v-else>
                            0
                        </div>
                        <el-input v-model="scope.row.limit_click_count" placeholder="【合計】上限数" size="mini"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    width="80">
                    <template slot-scope="scope">
                        <el-button
                            class="update-button"
                            size="mini"
                            @click="update(scope.row)"
                        >
                            更新
                        </el-button>
                        <br>
                        <el-button
                            type="danger"
                            size="mini"
                            @click="destroy(scope.row.id)"
                        >
                            削除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        </div>
        <registration-modal
            :is-active="registration.isActive"
            @save="store"
            @close="closeRegistrationModal"
        ></registration-modal>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import RegistrationModal from './Campaign/RegistrationModal'

export default {
    components: {
        Pagination,
        RegistrationModal,
    },
    data() {
        return {
            tableData: [],
            target:[],
            pagination: {},
            registration: {
                isActive: false,
            },
            options: [{
                value: 1,
                label: 1
            }, {
                value: 2,
                label: 2
            },],
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'アプリ　>　キャンペーン ')
        this.init()
    },
    methods   : {
        init() {
            this.fetch()
        },
        openRegistrationModal() {
            this.registration.isActive = true
        },
        closeRegistrationModal() {
            this.registration.isActive = false
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
        async fetch() {
            const page = this.$route.query.page
            const params = {page}
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/app/campaign', {params})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.pagination = Object.assign({}, this.pagination, data.campaigns)

            const datalist = Object.entries(data.campaigns.data).map(([key, data]) => {
                return {
                    id: data.id,
                    article_id: data.article_id ? data.article_id : null,
                    topic_id: data.topic_id ? data.topic_id : null,
                    serialization_id: data.serialization_id ? data.serialization_id : null,
                    link_url: data.link_url ? data.link_url : null,
                    title: data.title,
                    image: data.image,
                    image2: data.image2,
                    order_num: data.order_num,
                    image_preview: data.image_cdn_url,
                    image_preview2: data.image_cdn_url2,
                    ratio: data.ratio,
                    ratio2: data.ratio2,
                    limit_click_count: data.limit_click_count,
                    daily_limit_click_count: data.daily_limit_click_count,
                    published_start_at: data.published_start_at,
                    published_end_at: data.published_end_at,
                    is_tieup: data.is_tieup,
                    is_distribute_ios: data.is_distribute_ios,
                    is_distribute_android: data.is_distribute_android,
                }
            })
            const targetDatalist = Object.entries(data.target).map(([key, data]) => {
                const chosenChanel = []
                if (data.is_distribute_ios) {
                    chosenChanel.push('iOS')
                }
                if (data.is_distribute_android) {
                    chosenChanel.push('Android')
                }
                return {
                    id: data.id,
                    article_id: data.article_id ? data.article_id : null,
                    topic_id: data.topic_id ? data.topic_id : null,
                    serialization_id: data.serialization_id ? data.serialization_id : null,
                    link_url: data.link_url ? data.link_url : null,
                    title: data.title,
                    image: data.image,
                    image2: data.image2,
                    order_num: data.order_num,
                    image_preview: data.image_cdn_url,
                    image_preview2: data.image_cdn_url2,
                    ratio: data.ratio,
                    ratio2: data.ratio2,
                    chosenChanel: chosenChanel.join(','),
                    published_start_at: data.published_start_at,
                    published_end_at: data.published_end_at,
                    is_tieup: data.is_tieup,
                }
            })
            this.tableData = Object.assign([], datalist)

            this.target = Object.assign([], targetDatalist)

            this.$store.commit(types.LOADING, false)
        },
        async store(fd) {
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.post('/api/app/campaign', fd)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, '登録しました。')
            this.closeRegistrationModal()
            this.fetch()
        },
        async update(campaign) {
            const fd = this.getFormData(campaign)

            this.$store.commit(types.LOADING, true)
            // formがpatchでrequest投げると空になるので、headerを書き換える
            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PATCH'
                }
            }
            const { error } = await axios.post('/api/app/campaign/'+campaign.id, fd, config)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '更新しました。')
            this.fetch()
        },
        async destroy(id) {
            if (!confirm('本当に削除しますか？')) {
                return
            }
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.delete('/api/app/campaign/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            this.$store.commit(types.LOADING, true)

            this.fetch()
        },
        // フォームデータ作成
        getFormData(campaign) {
            const fd = new FormData()
            if (campaign.limit_click_count == null) {
                campaign.limit_click_count = 0
            }
            if (campaign.daily_limit_click_count == null) {
                campaign.daily_limit_click_count = 0
            }
            if (campaign.article_id) {
                fd.append('article_id', campaign.article_id)
            } else if (campaign.topic_id) {
                fd.append('topic_id', campaign.topic_id)
            } else if (campaign.serialization_id) {
                fd.append('serialization_id', campaign.serialization_id)
            } else if (campaign.link_url) {
                fd.append('link_url', campaign.link_url)
            }
            fd.append('is_distribute_ios', Number(campaign.is_distribute_ios))
            fd.append('is_distribute_android', Number(campaign.is_distribute_android))
            fd.append('is_tieup', Number(campaign.is_tieup))
            if (campaign.published_start_at) {
                fd.append('published_start_at', this.getFormattedDateTime(campaign.published_start_at))
            }
            if (campaign.published_end_at) {
                fd.append('published_end_at', this.getFormattedDateTime(campaign.published_end_at))
            }
            fd.append('ratio', campaign.ratio ? campaign.ratio:'')
            fd.append('ratio2', campaign.ratio2 ? campaign.ratio2:'')

            fd.append('title', campaign.title)
            fd.append('limit_click_count', Number(campaign.limit_click_count))
            fd.append('daily_limit_click_count', Number(campaign.daily_limit_click_count))
            if (!campaign.order_num) {
                fd.append('order_num', '')
            } else {
                fd.append('order_num', Number(campaign.order_num))
            }
            fd.append('image', campaign.image && typeof campaign.image !== 'string' ? campaign.image : '')
            if (this.image2) {
                fd.append('image2', this.image2)
            } else {
                fd.append('image2', campaign.image2 && typeof campaign.image2 !== 'string' ? campaign.image2 : '')
            }

            return fd
        },
        selectedFile: function(e) {
            // 選択された File の情報を保存しておく
            e.preventDefault()
            let files = e.target.files
            let campaignId = Number(e.target.id.replace('image_', ''))
            this.campaign = this.tableData.map(function(x) {
                if (x.id === campaignId) {
                    x.image = files[0]
                    const reader = new FileReader()
                    reader.onload = e => {
                        x.image_preview = e.target.result
                    }
                    reader.readAsDataURL(files[0])
                }

                return x
            })
        },
        selectedFile2: function(e) {
            // 選択された File の情報を保存しておく
            e.preventDefault()
            let files = e.target.files
            let campaignId = Number(e.target.id.replace('image2_', ''))
            this.campaign = this.tableData.map(function(x) {
                if (x.id === campaignId) {
                    x.image = files[0]
                    const reader = new FileReader()
                    reader.onload = e => {
                        x.image_preview = e.target.result
                    }
                    reader.readAsDataURL(files[0])
                }

                return x
            })
        },
        handleRemove: function () {
            this.imagePreview2 = ''
            this.image2 = ''
        },
        // ファイルを追加した時
        handleAdd: function (file) {
            this.imagePreview2 = file.url
            this.image2 = file.raw
        },
        // 日時フォーマッター
        getFormattedDateTime (date) {
            let target_date = new Date(date)
            let formatted_date =  target_date.getFullYear() + '-' + ('0'+(target_date.getMonth() + 1)).slice(-2) + '-' + ('0'+(target_date.getDate())).slice(-2)
            let formatted_time =  ('0'+(target_date.getHours())).slice(-2) + ':' + ('0'+(target_date.getMinutes())).slice(-2) + ':' + ('0'+(target_date.getSeconds())).slice(-2)
            return formatted_date + ' ' + formatted_time
        },
    }
}
</script>
<style scoped>
    .span-2column-first-input {
        margin-bottom: 10px;
    }
    .update-button {
        margin-bottom: 10px;
    }
    .target-column {
        text-align: center;
        padding-top: 40px;
    }
    .image {
        text-align: center;
        padding-top: 30px;
    }
    .target-column-id {
        text-align: center;
        padding-top: 20px;
    }
    .date {
        text-align: center;
        margin-top: 10px;
    }
    .add-button {
        margin-top: 180px;
    }
    .all-table {
        margin-top: 210px;
    }
</style>
