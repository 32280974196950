<template>
    <div>
        <el-row type="flex" justify="end">
            <div class="search-form" :inline="true">
                <el-checkbox @change="clearSearchText()" v-model="searchTextNullOnly">タグが付いてないものを検索</el-checkbox>
                <el-input class="search-text-input" v-bind:disabled="searchTextNullOnly" placeholder="タグ" v-model="searchText" @keyup.enter.native="fetch(searchTextNullOnly, searchText)">
                    <el-button
                            slot="append"
                            @click="fetch(searchTextNullOnly, searchText)"
                            icon="el-icon-search">
                    </el-button>
                </el-input>
            </div>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <el-table
            :data="shutterstocks"
            style="width: 100%"
            max-height="800"
        >
            <el-table-column label="画像">
                <template slot-scope="scope">
                    <img :src='scope.row.image_cdn_url' alt="画像" class="shutterstock-thumbnail" />
                </template>
            </el-table-column>
            <el-table-column label="タグ">
                    <template slot-scope="scope">
                            <span v-html="replaceCommaToLine(scope.row.search_text)" ></span>
                    </template>
            </el-table-column>
            <el-table-column label="Photo by" prop="photo_by"></el-table-column>
            <el-table-column label="有効/無効" align="right">
                <template slot-scope="scope">
                    <el-switch
                        v-model="scope.row.is_active"
                        :active-value="1"
                        :inactive-value="0"
                        @change="updateIsActive(scope.row.id)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
    </div>
</template>

<script>
import * as types from '../../../store/mutation-types'
import Pagination from '../../../components/Pagination'

export default {
    components: {
        Pagination
    },
    data() {
        return {
            shutterstocks     : [],
            pagination        : {},
            searchText        : '',
            searchTextNullOnly: false,
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '記事作成データ管理　>　Shutterstock')
        this.init()
    },
    methods   : {
        init() {
            this.fetch(this.searchTextNullOnly)
        },
        clearSearchText() {
            this.searchText = ''
        },
        // 検索
        async fetch(searchTextNullOnly, searchText = '') {
            const page = this.$route.query.page
            const searchTextNullOnlyParam = searchTextNullOnly ? 1: 0
            const params = searchText === '' ? {
                page,
                search_text_null_only: searchTextNullOnlyParam
            } : {
                page,
                search_text          : searchText,
                search_text_null_only: searchTextNullOnlyParam
            }
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/quote/shutterstocks', {
                params: params
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.shutterstocks = data.data
            this.pagination = Object.assign({}, this.pagination, data)
            this.$store.commit(types.LOADING, false)
            return Promise.resolve(1)
        },
        // カンマ区切りを改行に変換
        replaceCommaToLine(text) {
            return text? text.split(',').join('<br>'): ''
        },
        // is_active を有効・無効にする
        async updateIsActive(id) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.put('/api/quote/shutterstocks/' + id, {})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            await this.fetch(this.searchTextNullOnly, this.searchText)
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの更新に成功しました。')
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
    }
}
</script>

<style lang="scss" scoped>
    .search-text-input{
        width: 250px;
    }
    .shutterstock-thumbnail{
        width: 100%;
    }
</style>
