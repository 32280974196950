<template>
    <el-dialog title="リライト単価種別" @close="close" :visible="dialog" height="70%">
        <el-form label-width="150px">
            <el-form-item label="単価種別">
                <el-col :span="18" style="display: flex; align-items: center;">
                    <el-select v-model="form.payment_type" @change="changeRewritePaymentType($event)">
                        <el-option
                            v-for="type in rewritePaymentTypes"
                            :key="type.value"
                            :label="type.label"
                            :value="type.value"
                        ></el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="金額">
                <el-col :span="18" style="display: flex; align-items: center;">
                    <el-input
                        v-model="form.article_unit_price"
                        placeholder="金額"
                        type="number"
                        :disabled="form.payment_type !== 'ARTICLE'"
                        style="width: 192px"
                    ></el-input>
                </el-col>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="submitForm">更新</el-button>
        </span>
    </el-dialog>
</template>
<script>

export default {
    props: {
        dialog: {
            type: Boolean,
            require: true,
            default: false,
        },
        rewrite: {
            type: Object,
            required: true,
            default: {}
        },
        rewritePaymentTypes: {
            type: Array,
            required: true,
            default: [],
        },
    },
    data () {
        return {
            form: {
                payment_type: null,
                article_unit_price: null,
            }
        }
    },
    watch: {
        rewrite() {
            this.form = {
                ...this.form,
                payment_type: this.rewrite.payment_type,
                article_unit_price: this.rewrite.article_unit_price,
            }
        },
    },
    methods: {
        submitForm () {
            this.$emit('save', this.rewrite.id, this.form)
        },
        close () {
            this.$emit('close')
        },
        changeRewritePaymentType(e) {
            if (e !== 'ARTICLE') {
                this.form.article_unit_price = null
            }
        }
    }
}
</script>
