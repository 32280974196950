<template>
    <div>
        <el-row type="flex" justify="end">
            <el-button type="primary" @click="openRegistrationModal()">新規作成</el-button>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <el-table
                :data="blacklists"
                style="width: 100%"
                max-height="800"
        >
            <el-table-column label="引用不可URL" prop="path"></el-table-column>
            <el-table-column label="作成日時" prop="created_at"></el-table-column>
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                            class="btn-danger"
                            size="mini"
                            type="danger"
                            @click="destroyBlacklist(scope.row.id)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <registration-modal
                :is-active="registration.isActive"
                @close="registration.isActive = false"
                @reload="init()"
        ></registration-modal>
    </div>
</template>

<script>
import * as types from '../../../store/mutation-types'
import Pagination from '../../../components/Pagination'
import RegistrationModal from './Blacklist/RegistrationModal'

export default {
    components: {
        Pagination,
        RegistrationModal
    },
    data() {
        return {
            blacklists: [],
            pagination: {},
            path: '',
            registration : {
                isActive: false,
            },
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '外部連携管理　>　URLブラックリスト')
        this.init()
    },
    methods: {
        init() {
            this.fetch()
        },
        // 検索
        async fetch() {
            const page = this.$route.query.page
            this.$store.commit(types.LOADING, true)
            const { data, error } = await axios.get('/api/quote/url/blacklists', {
                params: { page }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.blacklists = data.data
            this.pagination = Object.assign({},this.pagination, data)
            this.$store.commit(types.LOADING, false)
            return Promise.resolve(1)
        },
        // URLブラックリストを削除
        async destroyBlacklist(blacklistId) {
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.delete('/api/quote/url/blacklists/' + blacklistId)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.LOADING, false)
        },
        openRegistrationModal() {
            this.registration.isActive = true
        },
        handleCurrentChange (page) {
            this.$router.push({query: {page}})
            this.fetch()
        },
    }
}
</script>

<style lang="scss" scoped>
    .path-input {
        width: 500px;
    }
</style>
