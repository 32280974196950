<template>
    <div>
        <el-row>
            <el-col class="leftBox" :span="1">
                <el-col>
                    <el-checkbox class="itemCheckBox" v-model="checkState">{{ number }}</el-checkbox>
                </el-col>
            </el-col>

            <el-col class="contents" :span="21">
                <emphasis-type
                    v-if="isEditing"
                    ref="dialogModule"
                    :save="update"
                    :id="content.id"
                    :input="content"
                    :emphasis-item-type-list="response.emphasisItemTypeList"
                    :emphasis-item-heading-tag-type-list="response.emphasisItemHeadingTagTypeList"
                    :emphasis-item-color-list="response.emphasisItemColorList"
                    @close="closeMenu"
                ></emphasis-type>
                <div v-else>
                    <div class="emphasisTitle" :style="[{'background': emphasisColor}]">{{ content.title }}（{{ content.heading_tag_type }}）</div>
                    <ul class="emphasisElementWrapper">
                        <li v-for="(element, idx) in content.elements" :key="idx" class="emphasisElementList">
                            <span v-if="content.type === 'CHECK'"><i class="fas fa-check-square" :style="[{'font-size': '16px'}, {'color': emphasisColor}]"></i></span>
                            <span v-else-if="content.type === 'NUMBER'" class="emphasisElementNumberIcon" :style="[{'background': emphasisColor}]">{{ idx + 1 }}</span>
                            <span v-else-if="content.type === 'DOT'" class="emphasisElementDotIcon" :style="[{'color': emphasisColor}]">・</span>
                            <span v-html="element.text"></span>
                        </li>
                    </ul>
                </div>
            </el-col>


            <el-col class="rightBox" :span="2" justify="end" v-if="!updateOrdersMode">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList">下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', resourceName, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>

    </div>
</template>
<script>

import FeedbackForm from './Components/FeedbackForm'
import EmphasisType from '../Form/EmphasisType'
import * as types from '../../../../../store/mutation-types'

export default {
    components: {
        EmphasisType,
        FeedbackForm,
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    data() {
        return {
            isEditing: false,
        }
    },
    computed: {
        content() {
            return this.item.content
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        emphasisColor() {
            let color
            switch (this.content.color) {
            case 'RED':
                color = '#CE7A79'
                break
            case 'YELLOW':
                color = '#E0D09F'
                break
            case 'GREEN':
                color = '#C9CFA7'
                break
            case 'BLUE':
                color = '#B6CED1'
                break
            case 'BROWN':
                color = '#897C7A'
                break
            case 'GRAY':
                color = '#888888'
                break
            }

            return color
        },
        resourceName() {
            return 'emphasises'
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check, item) {
            this.parentCheckItem(check, item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>
<style scoped>
.emphasisTitle {
    font-weight: bold;
    font-size: 16px;
    padding: 5px;
    color: #ffffff;
    margin-bottom: 5px;
}
.emphasisElementList {
    margin-bottom: 5px;
}
.emphasisElementNumberIcon {
    border-radius: 50%;
    color: white;
    width: 17px;
    height: 17px;
    display: inline-block;
    text-align: center;
}

.leftBox {
    width: 45px;
}
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}

</style>
