<template>
    <div>
        <el-row>
            <el-form v-if="!embedTagPc" class="itemForm" @submit.native.prevent label-width="120px" >
                <el-form-item label="pc埋め込みタグ">
                    <el-input
                        type="textarea"
                        size="mini"
                        :rows="3"
                        v-model="form.embedTagPc">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form v-if="!embedTagSp" class="itemForm" @submit.native.prevent label-width="120px" >
                <el-form-item label="sp埋め込みタグ">
                    <el-input
                        type="textarea"
                        size="mini"
                        :rows="3"
                        v-model="form.embedTagSp">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form v-if="!embedTagApp" class="itemForm" @submit.native.prevent label-width="120px" >
                <el-form-item label="app埋め込みタグ">
                    <el-input
                        type="textarea"
                        size="mini"
                        :rows="3"
                        v-model="form.embedTagApp">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-row>
                <div class="itemFormFooter">
                    <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
                    <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
                </div>
            </el-row>
        </el-row>
    </div>
</template>

<script>
const defaultFormValues =  {
    embedTagPc:null,
    embedTagSp:null,
    embedTagApp:null,
}
export default {
    data () {
        return {
            form: {...defaultFormValues},
            embedTagPc:null,
            embedTagSp:null,
            embedTagApp:null,
        }
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
    },
    methods: {
        submitForm(){
            this.save(this.resourceName, this.form, this.id)
        },
        fetchEmbedTag() {
            this.form = Object.assign({}, this.form)
            this.embedTagPc = this.form.embedTagPc
            this.embedTagSp = this.form.embedTagSp
            this.embedTagApp = this.form.embedTagApp
        },
        openDialog(input) {
            let values = {
                'embedTagPc': input.elements[0].embedded_tag,
                'embedTagSp': input.elements[1].embedded_tag,
                'embedTagApp': input.elements[2].embedded_tag
            }
            this.form = Object.assign({}, defaultFormValues, _.pick(values, Object.keys(this.form)))
        }
    },
    computed: {
        resourceName() {
            return 'embedded'
        },
    }
}
</script>

<style scoped>

</style>
