<template>
    <div>
        <el-dialog
            :title="title"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
        >
            <el-form :model="editForm" label-width="120px">
                <el-form-item v-if="!isEdit" label="メールアドレス">
                    <el-input v-model="editForm.email" placeholder="example@example.com"></el-input>
                </el-form-item>
                <el-form-item label="編集者名">
                    <el-input v-model="editForm.editor_name" placeholder="user"></el-input>
                </el-form-item>
                <el-form-item label="権限">
                    <el-select v-model="editForm.authority">
                        <el-option
                            v-for="authority in authorities"
                            :key="authority.value"
                            :label="authority.label"
                            :value="authority.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">キャンセル</el-button>
                <el-button type="primary" @click="save">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

export default {
    props: {
        authorities: Object,
        isActive: Boolean,
        admin: Object,
        title: String,
    },
    data() {
        return {
            editForm: {
                email: '',
                editor_name: '',
                authority: '',
            },
            isEdit: false,
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        // 編集
        async save() {
            this.$emit('save', this.editForm)
        },
    },
    watch: {
        admin() {
            this.isEdit = this.admin.hasOwnProperty('id')
            this.editForm = Object.assign({}, this.admin, { email: '' })
        }
    },
}
</script>
