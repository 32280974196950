<template>
    <el-dialog
        title="SNSアカウント制限追加"
        :visible="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>SNS</dt>
                <dd>
                    <el-select v-model="service" placeholder="SNSを選択してください">
                        <el-option
                            v-for="service in services"
                            :key="service"
                            :label="service"
                            :value="service"
                        >
                        </el-option>
                    </el-select>
                </dd>
                <dt>アカウント名</dt>
                <dd>
                    <el-input class="account-input" placeholder="アカウント名" v-model="accountName"></el-input>
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="add()">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../../../store/mutation-types'

export default {
    props: {
        isActive: Boolean,
        services: [Object, Array],
    },
    data() {
        return {
            service: '',
            accountName: '',
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        async add() {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.post('/api/quote/snsaccount/blacklists', {
                service: this.service,
                account_name: this.accountName
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.service = ''
            this.accountName = ''
            this.$emit('close')
            this.$store.commit(types.LOADING, false)
            this.$emit('reload')
        }
    }
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            width: 20%;
            padding-top: 2%;
        }

        dd {
            margin-bottom:3%;
            width: 70%;
        }
    }
</style>
