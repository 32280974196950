<template>
    <el-badge is-dot :type="feedbackType" class="feedbackForm">
        <el-popover
            placement="bottom"
            width="200"
            trigger="manual"
            v-model="visible">
            <el-form>
                <el-form-item style="margin-bottom: 0;">
                    <el-input
                        type="textarea"
                        @change="submitForm"
                        v-model="form.feedback"
                        :disabled="this.$store.state.role === 'wr'">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-button
                size="mini"
                class="btnEdit"
                slot="reference"
                @click="visible = !visible"
            >フィードバック</el-button>
        </el-popover>
    </el-badge >
</template>
<script>

const formDefaultData = {
    feedback: null,
}
export default {
    props: {
        input: Object,
    },
    data () {
        return {
            form: {...formDefaultData},
            visible: false,
        }
    },
    computed: {
        feedbackType() {
            return this.form.feedback ? 'danger': 'primary'
        },
    },
    mounted() {
        this.form = Object.assign({}, formDefaultData, {'feedback': this.input.feedback})
    },
    methods: {
        submitForm () {
            this.$emit('save', this.input.id, this.form)
        },
    }
}
</script>
<style>
.el-popover.el-popper {
    background-color: #f2f2f2;
    padding: 5px;
}
.el-textarea__inner {
    height: 100px;
}
</style>