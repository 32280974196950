var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeRangeList"},[_c('div',{staticClass:"checkRow"},[_c('el-checkbox',{attrs:{"value":_vm.value.is_twenty_four,"label":_vm.twentyFourLabel},on:{"input":function($event){return _vm.$emit('input', {
                is_twenty_four: !_vm.value.is_twenty_four,
                times: _vm.value.times,
            })}}})],1),_vm._v(" "),(!_vm.value.is_twenty_four)?_c('div',{staticClass:"listRow"},_vm._l((_vm.value.times),function(timeRange,idx){return _c('div',{key:idx,staticClass:"item"},[_c('time-range-picker',{staticClass:"picker",attrs:{"value":timeRange,"size":"mini","start-placeholder":_vm.startPlaceholder,"end-placeholder":_vm.endPlaceholder},on:{"input":newRange => _vm.$emit('input', {
                    is_twenty_four: _vm.value.is_twenty_four,
                    times: _vm.value.times.map((v, i) => i === idx ? newRange : v),
                })}}),_vm._v(" "),_c('div',{staticClass:"control"},[(_vm.value.times.length > 1)?_c('el-button',{staticClass:"deleteButton",attrs:{"plain":"","type":"warning","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){_vm.$emit('input', {
                        is_twenty_four: _vm.value.is_twenty_four,
                        times: _vm.value.times.filter((_, i) => i !== idx),
                    })}}}):_vm._e(),_vm._v(" "),(idx === _vm.value.times.length - 1)?_c('el-button',{staticClass:"addButton",attrs:{"type":"info","icon":"el-icon-plus","size":"mini"},on:{"click":function($event){return _vm.$emit('input', {
                        is_twenty_four: _vm.value.is_twenty_four,
                        times: [..._vm.value.times, ['', '']],
                    })}}}):_vm._e()],1)],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }