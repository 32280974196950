<template>
    <el-dialog
            title="APPタイムラインタブ編集"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>タブ名</dt>
                <dd>
                    <el-input class="tab-name-input" placeholder="タブ名" v-model="tabName"></el-input>
                </dd>
                <dt>タブ種別</dt>
                <dd>
                    <el-input :disabled=true v-model="typeLabel"></el-input>
                </dd>
                <dt>詳細</dt>
                <dd>
                    <el-input :disabled=true v-model="typeDetail"></el-input>
                </dd>
                <dt>表示</dt>
                <dd>
                    <el-checkbox v-model="tabIsActive"></el-checkbox>
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="edit(id, tabName, tabIsActive)">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../store/mutation-types'

export default {
    props  : {
        isActive: Boolean,
        row     : Object,
    },
    data() {
        return {
            tabName    : '',
            typeLabel  : '',
            typeDetail : '',
            tabIsActive: '',
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        async edit(id, tabName, tabIsActive) {
            const params = {
                'id'       : id,
                'tab_name' : tabName,
                'is_active': tabIsActive,
            }
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.put('/api/organization/app_tabs/' + id, params)
            if (error) {
                this.$store.commit(types.LOADING, false)
                return
            }
            this.$emit('close')
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの更新に成功しました。')
            this.$emit('reload')
        }
    },
    watch  : {
        row() {
            this.id = this.row.id
            this.tabName = this.row.tab_name
            this.typeLabel = this.row.type_label
            this.typeDetail = this.row.type_detail
            this.tabIsActive = this.row.is_active
        }
    }
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            width: 30%;
            padding-top: 2%;
        }

        dd {
            margin-bottom:3%;
            width: 70%;
        }
    }
</style>
