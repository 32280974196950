<template>
    <el-row class="cooperateImage">
        <el-col :span="4">
            <el-radio-group v-model="cooperate">
                <el-radio label="Rakuten" class="serviceRadio">
                    <span class="serviceLabel">
                        <img src="/images/cooperate/rakuten.gif" alt="">
                    </span>
                </el-radio>
                <el-radio label="amazon" class="serviceRadio">
                    <span class="serviceLabel">
                        <img src="/images/cooperate/amazon.png" alt="">
                    </span>
                </el-radio>
                <el-radio label="Yahoo" class="serviceRadio">
                    <span class="serviceLabel">
                    <!--ダミー画像後で変える-->
                        <img src="/images/cooperate/yahoo!.png" alt="">
                    </span>
                </el-radio>
            </el-radio-group>
        </el-col>
        <el-col :span="20">
            <rakuten
                :class="selectedMedia('Rakuten')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                :imageType="imageType"
                :arrengeList="arrengedList"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
            <amazon
                :class="selectedMedia('amazon')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                :imageType="imageType"
                :arrengeList="arrengedList"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
            <Yahoo
                :class="selectedMedia('Yahoo')"
                :parentKeyword="searchKeyword"
                @save="saveItemFromMedia"
                :imageType="imageType"
                :arrengeList="arrengedList"
                @updateKeyword="(keyword) => this.searchKeyword = keyword"/>
        </el-col>
    </el-row>
</template>
<script>
import Amazon from './Cooperate/Amazon'
import Rakuten from './Cooperate/Rakuten'
import Yahoo from './Cooperate/Yahoo'

export default {
    data () {
        return {
            searchKeyword:'',
            cooperate: 'Rakuten',
            page: 1,
            nextPage: 0,
            articles: [],
            tags: [],
        }
    },
    props: {
        imageType: String,
    },
    components: {
        Amazon,
        Rakuten,
        Yahoo,
    },
    methods:{
        saveIconFromUrl(url) {
            this.$emit('saveIcon', url, true)
        },
        saveItemFromMedia(media, form) {
                
            if ('image_title' in form) {
                form.image_title = form.image_title.substring(0, 60)
            }
            this.$emit('save', media, form)
        },
        arrengedList(list) {
            let dist = [[]]
            list.forEach((el) => {
                let tail = dist.length - 1
                dist[tail].push(el)

                if (dist[tail].length > 4 - 1){
                    dist.push([])
                }
            })
            return dist
        },
        selectedMedia(mediaName) {
            if (this.cooperate === mediaName) {
                return 'selected'
            }
            return 'unselected'
        },
    },
}
</script>
<style scoped>
    .selected {
        display: block;
    }
    .unselected {
        display: none;
    }
    .serviceRadio {
        border: 1px solid #ccc;
        border-top: 0;
        width: 150px;
        text-align: center;
        padding: 5px 10px;
    }
    .serviceRadio:first-child {
        border-top: 1px solid #ccc;
    }
    .serviceLabel > img{
        height:19px;
        padding-top: 6px;
    }
</style>
<style>
    .serviceRadio .el-radio__input {
        display: none;
    }
    .serviceRadio .el-radio__label {
        padding: 0;
    }
    .serviceRadio.is-checked {
        background-color: #f7f7f7;
    }
    .cooperateImage .itemForm {
        margin-left: 10px;
        margin-top: 0;
    }
    .cooperateImage .loading {
        padding: 40px 0;
    }
    .cooperateImage .thumbnails {
        margin:10px;
        position: relative;
    }
    .cooperateImage .thumbnails img {
        width:210px;
    }
    .cooperateImage .thumbnails .addButton {
        position: absolute;
        top: 53px;
        left: 65px;
        display: none;
    }
    .cooperateImage .thumbnails:hover img {
        filter: brightness(50%);
    }
    .cooperateImage .thumbnails:hover .addButton {
        display: block;
    }
    .cooperateImage .thumbnailText {
        margin:0px;
        padding-bottom: 20px;
        font-size: 12px;
        line-height: 1.5;
    }
</style>
