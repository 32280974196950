<template>
    <div>
        <el-row type="flex" justify="end">
            <el-button type="success" @click="() => this.$router.push('/organizations/app_search_word/order')">並び替え</el-button>
            <el-button type="primary" @click="openRegistrationModal()">新規追加</el-button>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <el-table
                :data="words"
                style="width: 100%"
                max-height="800"
        >
            <el-table-column label="並び順" prop="order_num"></el-table-column>
            <el-table-column label="タグ名" prop="name"></el-table-column>
            <el-table-column label="作成日時" prop="created_at"></el-table-column>
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            class="btn-danger"
                            type="danger"
                            @click="destroyAppRecommendSearchWord(scope.row.id)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <registration-modal
                :is-active="registration.isActive"
                :services="registration.services"
                @close="registration.isActive = false"
                @reload="fetch"
        ></registration-modal>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'

import RegistrationModal from './AppSearchWord/RegistrationModal'

export default {
    components: {
        Pagination,
        RegistrationModal,
    },
    data() {
        return {
            words     : [],
            pagination  : {},
            registration: {
                isActive: false,
            }
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'アプリ　>　見つける検索ワード')
        this.init()
    },
    methods   : {
        init() {
            this.fetch()
        },
        // 検索
        async fetch() {
            const page = this.$route.query.page
            const params = {page}
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/organization/app_search_words', {params})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.words = data.data
            this.pagination = Object.assign({}, this.pagination, data)
            this.$store.commit(types.LOADING, false)
        },
        // APP見つける検索ワードを削除
        async destroyAppRecommendSearchWord(wordId) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.delete('/api/organization/app_search_words/' + wordId)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの削除に成功しました。')
        },
        openRegistrationModal() {
            this.registration.isActive = true
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch(this.accountName)
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
