<template>
    <div>
        <el-form class="itemForm" @submit.native.prevent label-width="160px" >
            <el-form-item label="ピンタレストURL入力" style="width:80%;">
                <el-input
                    width="50%"
                    type="text"
                    size="small"
                    placeholder="https://jp.pinterest.com/pin/XXXXXXXXXXXXXXXXXXXXXX/"
                    v-model="pinterestUrl"
                    @keypress.enter.native="parsePinId"
                >
                    <el-button @click="parsePinId" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </el-form-item>
        </el-form>
        <pinterest-contents :pin-id="pinId" />
        <div  class="itemFormFooter" style="margin-left: 160px;">
            <el-button type="primary" size="mini" :disabled="! pinId" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </div>
    </div>
</template>

<script>

import * as types from '../../../../../store/mutation-types'
import PinterestContents from './Components/Pinterest'

const defaultFormValues =  {
    pin_id: null,
}
export default {
    components:{
        PinterestContents
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
    },
    data () {
        return {
            form: {...defaultFormValues},
            pinId: null,
            pinterestUrl: null,
        }
    },
    computed: {
        resourceName() {
            return 'pinterest'
        },
    },
    methods: {
        submitForm () {
            const data = {
                pin_id : this.pinId
            }
            this.form = Object.assign({}, defaultFormValues, data)
            this.save(this.resourceName, this.form, this.id)
        },
        async parsePinId() {
            this.pinId = null

            if (! this.pinterestUrl) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'ピンタレストURLを入力してください')
                return
            }
            if (! this.pinterestUrl.match(/^https:\/\/jp.pinterest.com\/pin\/\d/)) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'ピンタレストURLが正しくありません')
                return
            }

            this.pinId = (new URL(this.pinterestUrl)).pathname.split('/')[2]
        }
    },
}
</script>
