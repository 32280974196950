<template>
    <div class="placeSearch">
        <div class="googleMaps">
            <google-maps-form
                @searched="fetchPlaceById"
                @save="handleSave"
            />
        </div>
        <table v-if="searchResult !== null" class="googlePlaces">
            <tr><th class="tableHeader">名前</th><td>{{ searchResult.displayName }}</td></tr>
            <tr><th class="tableHeader">住所</th><td>{{ searchResult.address }}</td></tr>
            <tr>
                <th class="tableHeader">URL</th>
                <td>
                    <a v-if="searchResult.websiteUri !== null" :href="searchResult.websiteUri" target="_blank">
                        {{ searchResult.websiteUri }}
                    </a>
                </td>
            </tr>
            <tr>
                <th class="tableHeader">GoogleMapsURL</th>
                <td>
                    <a v-if="searchResult.googleMapsUri !== null" :href="searchResult.googleMapsUri" target="_blank">
                        {{ searchResult.googleMapsUri }}
                    </a>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import GoogleMapsForm from '../Map'

export default {
    components: {
        GoogleMapsForm,
    },
    data () {
        return {
            searchResult: null,
        }
    },
    methods: {
        async fetchPlaceById({ placeId }) {
            const loading = this.$loading({fullscreen: true})
            const {data, error} = await axios.get('/api/cooperation/google/places/search', {
                params: {
                    place_id: placeId,
                }
            })
            loading.close()
            if (error) {
                this.$message.error('サーバーエラーが発生しました。')
                return
            }

            this.searchResult = data
        },
        handleSave() {
            if (this.searchResult === null) {
                this.$message.error('住所情報が取得できませんでした。もう一度検索してみてください。')
                return
            }
            this.$emit('save', structuredClone(this.searchResult))
        }
    },
}
</script>

<style scoped>
.googlePlaces {
    margin: 20px 0 0 0;
}
.tableHeader {
    text-align: left;
}
</style>
