<template>
    <div class="setIconUrl">
        <el-form>
            <el-form-item>
                <el-input
                    type="text"
                    size="small"
                    placeholder="画像URL"
                    v-model="imageUrl">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-input
                    type="text"
                    size="small"
                    placeholder="参照元URL"
                    v-model="quoteUrl">
                </el-input>
            </el-form-item>
            <el-button type="primary" size="mini"  @click="urlCheckAndSave" :disabled="isDisabled">追加する</el-button>
        </el-form>
    </div>
</template>
<script>
import * as types from '../../../../../store/mutation-types'
export default {
    name:'SetIconUrlModal',
    data () {
        return {
            imageUrl: null,
            quoteUrl: null,
        }
    },
    props: {
        article: Object,
    },
    computed: {
        isDisabled() {
            return this.imageUrl === '' || this.imageUrl === null || this.quoteUrl === '' || this.quoteUrl === null
        },
    },
    methods: {
        async urlCheckAndSave() {
            this.$store.commit(types.EDITOR_LOADING,true)
            const formData = {
                'thumbnail_quote_url': this.quoteUrl,
                'thumbnail_quote_image_url': this.imageUrl,
            }
            this.$emit('save', formData, false)
        },
    }
}
</script>
<style>
</style>

