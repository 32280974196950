<template>
    <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item>
                <el-input
                    v-model="formData.app_topic_id"
                    type="number"
                    placeholder="見つける特集ID"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-upload
                    action=""
                    accept="image/png,image/jpeg"
                    list-type="picture"
                    class="form-icon"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="handleImageAdd"
                    :on-remove="handleImageRemove"
                >
                    <img v-if="previewIcon" :src="previewIcon" class="form-icon-img" width="100px">
                    <el-button v-else type="primary">画像選択</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item>
                <el-button
                    @click="createAppBasicRecipe"
                    type="primary"
                >
                    新規作成
                </el-button>
            </el-form-item>
            <el-form-item style="float: right;">
                <el-button
                    @click="$router.push('/organizations/app_basic_recipe/order')"
                    type="success"
                >
                    並び替え
                </el-button>
            </el-form-item>
        </el-form>

        <el-table
            :data="tableData"
            style="width: 100%"
            max-height="800"
        >
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="アイコン">
                <template slot-scope="scope">
                    <img :src="scope.row.image_cdn_url" width="100px">
                </template>
            </el-table-column>
            <el-table-column label="見つける特集ID" prop="app_topic_id"></el-table-column>
            <el-table-column label="見つける特集タイトル">
                <template slot-scope="scope">{{ scope.row.app_topic.title }}</template>
            </el-table-column>
            <el-table-column label="作成日時" prop="created_at"></el-table-column>
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                        class="btn-danger"
                        size="mini"
                        type="danger"
                        @click="deleteAppBasicRecipe(scope.row.id)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import FileDownload from 'js-file-download'

export default {
    data() {
        return {
            tableData: [],
            formData: {
                app_topic_id: null,
                image: null,
            },
            previewIcon: ''
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'アプリ　>　レシピタブ　>　料理の基本')
        this.fetch()
    },
    methods: {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data, error } = await axios.get('/api/organization/app_basic_recipes')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            this.tableData = data
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async deleteAppBasicRecipe(id) {
            if (! confirm('本当に削除しますか？')) {
                return
            }

            this.$store.commit(types.PAGE_LOADING, true)

            const { error } = await axios.delete('/api/organization/app_basic_recipes/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '削除しました')
            this.fetch()
        },
        async createAppBasicRecipe() {
            this.$store.commit(types.PAGE_LOADING, true)

            const fd = new FormData()
            fd.append('app_topic_id', this.formData.app_topic_id)
            fd.append('image', this.formData.image)

            const { error } = await axios.post('/api/organization/app_basic_recipes', fd)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            this.formData = {
                app_topic_id: null,
                image: null,
            }
            this.fetch()
        },
        handleImageAdd(file) {
            this.formData.image = file.raw
            this.previewIcon = file.url
        },
        handleImageRemove() {
            this.formData.image = null
            this.previewIcon = ''
        },
    }
}
</script>
