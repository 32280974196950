<template>
    <div class="materialForm">
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent>
            <el-form-item v-for="(element, idx) in elements" v-bind:key="idx">
                <el-row type="flex">
                    <p v-if="idx === 0" class="make-index-wrapper"><span class="make-index">下</span></p>
                    <p v-else class="make-index-wrapper"><span class="make-index">{{idx}}</span></p>
                    <wysiwyg-editor
                        v-model="elements[idx]['recipe_text']"
                        :placeholder="idx === 0 ? '下ごしらえ\n※下ごしらえがない場合は未記入で登録してください。' : '作り方'"
                        :editorViewStyle="'height: 10em;resize: vertical;overflow-y: scroll;'"
                        style="width: 80%;"
                    >
                    </wysiwyg-editor>
                    <el-row type="flex" class="actionIconWrapper">
                        <i @click="elementMoveUp(element, idx)" class="el-icon-arrow-up actionIcon"></i>
                        <i @click="elementMoveDown(element, idx)" class="el-icon-arrow-down actionIcon"></i>
                        <i @click="elementRemove(element, idx)" class="el-icon-close actionIcon"></i>
                    </el-row>
                </el-row>
                <el-row type="flex" style="flex-wrap: wrap; width: 1100px;">
                    <el-upload
                            v-if="!imageUrl1[idx]"
                            class="avatar-uploader"
                            :drag="true"
                            :auto-upload="false"
                            action=""
                            :on-change="(file) => handleImageAdd1(file, idx)"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif"
                    >
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div v-else class="el-upload-list el-upload-list--picture-card">
                        <div class="el-upload-list__item is-ready">
                            <img :src="imageUrl1[idx]" class="el-upload-list__item-thumbnail">
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-delete" @click="handleImageDel1(idx)">
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </div>
                    <el-upload
                            v-if="!imageUrl2[idx]"
                            class="avatar-uploader"
                            :drag="true"
                            :auto-upload="false"
                            action=""
                            :on-change="(file) => handleImageAdd2(file, idx)"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif"
                    >
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div v-else class="el-upload-list el-upload-list--picture-card">
                        <div class="el-upload-list__item is-ready">
                            <img :src="imageUrl2[idx]" class="el-upload-list__item-thumbnail">
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-delete" @click="handleImageDel2(idx)">
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </div>
                    <el-upload
                            v-if="!imageUrl3[idx]"
                            class="avatar-uploader"
                            :drag="true"
                            :auto-upload="false"
                            action=""
                            :on-change="(file) => handleImageAdd3(file, idx)"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif"
                    >
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div v-else class="el-upload-list el-upload-list--picture-card">
                        <div class="el-upload-list__item is-ready">
                            <img :src="imageUrl3[idx]" class="el-upload-list__item-thumbnail">
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-delete" @click="handleImageDel3(idx)">
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </div>
                    <el-upload
                            v-if="!imageUrl4[idx]"
                            class="avatar-uploader"
                            :drag="true"
                            :auto-upload="false"
                            action=""
                            :on-change="(file) => handleImageAdd4(file, idx)"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif"
                    >
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div v-else class="el-upload-list el-upload-list--picture-card">
                        <div class="el-upload-list__item is-ready">
                            <img :src="imageUrl4[idx]" class="el-upload-list__item-thumbnail">
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-delete" @click="handleImageDel4(idx)">
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </div>
                </el-row>
            </el-form-item>
            <el-form-item>
                <el-button @click="addElement()">行を追加する</el-button>
            </el-form-item>
        </el-form>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini" @click="submitRecipeForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
    </div>
</template>

<script>

const defaultFormValues = () => ({
    elements: []
})
const defaultElementFormValues = () => ({
    id: null,
    recipe_text: '',
    recipe_image1: null,
    recipe_image2: null,
    recipe_image3: null,
    recipe_image4: null,
})

import * as types from '../../../../../../store/mutation-types'
import WysiwygEditor from '../Components/WysiwygEditor'

export default {
    components: {
        WysiwygEditor,
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        resourceName: {
            required: true,
            type: String,
        },
    },
    data () {
        return {
            form: defaultFormValues(),
            elements: [],
            imageUrl1: [],
            imageUrl2: [],
            imageUrl3: [],
            imageUrl4: [],
            imageRaw1: [],
            imageRaw2: [],
            imageRaw3: [],
            imageRaw4: [],
        }
    },
    mounted() {
        this.form = defaultFormValues()
    },
    methods: {
        submitRecipeForm() {
            this.form.elements = this.elements.map((element, idx) => ({
                ...element,
                recipe_image1: this.imageRaw1[idx],
                recipe_image2: this.imageRaw2[idx],
                recipe_image3: this.imageRaw3[idx],
                recipe_image4: this.imageRaw4[idx],
            }))

            const fd = new FormData()
            _.forEach(this.form.elements, (value, key) => {
                _.forEach(value, (v, k) => {
                    if (v !== null) {
                        fd.append('elements['+key+']['+k+']', this.form.elements[key][k])
                    }
                })
            })
            this.form = fd
            this.save(this.resourceName, this.form, this.id)
        },
        openDialog(input) {
            this.elements = input.elements.map(element => {
                element.recipe_image1 = element.recipe_image1_cdn_url
                element.recipe_image2 = element.recipe_image2_cdn_url
                element.recipe_image3 = element.recipe_image3_cdn_url
                element.recipe_image4 = element.recipe_image4_cdn_url
                return {...defaultElementFormValues(), ..._.pick(element, Object.keys(defaultElementFormValues()))}
            })
            this.elements.forEach((element, key) => {
                this.imageUrl1.splice(key, 1, element.recipe_image1)
                this.imageUrl2.splice(key, 1, element.recipe_image2)
                this.imageUrl3.splice(key, 1, element.recipe_image3)
                this.imageUrl4.splice(key, 1, element.recipe_image4)
                this.imageRaw1.push(null)
                this.imageRaw2.push(null)
                this.imageRaw3.push(null)
                this.imageRaw4.push(null)
                this.$set(this.elements[key], 'recipe_image1', null)
                this.$set(this.elements[key], 'recipe_image2', null)
                this.$set(this.elements[key], 'recipe_image3', null)
                this.$set(this.elements[key], 'recipe_image4', null)
            })
        },
        addElement() {
            this.elements.push(defaultElementFormValues())
            this.imageUrl1.push(null)
            this.imageUrl2.push(null)
            this.imageUrl3.push(null)
            this.imageUrl4.push(null)
            this.imageRaw1.push(null)
            this.imageRaw2.push(null)
            this.imageRaw3.push(null)
            this.imageRaw4.push(null)
        },
        elementMoveDown(element, index) {
            const movedIndex = index + 1
            if (this.elements[movedIndex]) {
                this.elements.splice(index, 2, this.elements[movedIndex], this.elements[index])
                this.imageUrl1.splice(index, 2, this.imageUrl1[movedIndex], this.imageUrl1[index])
                this.imageUrl2.splice(index, 2, this.imageUrl2[movedIndex], this.imageUrl2[index])
                this.imageUrl3.splice(index, 2, this.imageUrl3[movedIndex], this.imageUrl3[index])
                this.imageUrl4.splice(index, 2, this.imageUrl4[movedIndex], this.imageUrl4[index])
                this.imageRaw1.splice(index, 2, this.imageRaw1[movedIndex], this.imageRaw1[index])
                this.imageRaw2.splice(index, 2, this.imageRaw2[movedIndex], this.imageRaw2[index])
                this.imageRaw3.splice(index, 2, this.imageRaw3[movedIndex], this.imageRaw3[index])
                this.imageRaw4.splice(index, 2, this.imageRaw4[movedIndex], this.imageRaw4[index])
            }
        },
        elementMoveUp(element, index) {
            if (index > 0) {
                const movedIndex = index - 1
                this.elements.splice(movedIndex, 2, this.elements[movedIndex+1], this.elements[movedIndex])
                this.imageUrl1.splice(movedIndex, 2, this.imageUrl1[movedIndex+1], this.imageUrl1[movedIndex])
                this.imageUrl2.splice(movedIndex, 2, this.imageUrl2[movedIndex+1], this.imageUrl2[movedIndex])
                this.imageUrl3.splice(movedIndex, 2, this.imageUrl3[movedIndex+1], this.imageUrl3[movedIndex])
                this.imageUrl4.splice(movedIndex, 2, this.imageUrl4[movedIndex+1], this.imageUrl4[movedIndex])
                this.imageRaw1.splice(movedIndex, 2, this.imageRaw1[movedIndex+1], this.imageRaw1[movedIndex])
                this.imageRaw2.splice(movedIndex, 2, this.imageRaw2[movedIndex+1], this.imageRaw2[movedIndex])
                this.imageRaw3.splice(movedIndex, 2, this.imageRaw3[movedIndex+1], this.imageRaw3[movedIndex])
                this.imageRaw4.splice(movedIndex, 2, this.imageRaw4[movedIndex+1], this.imageRaw4[movedIndex])
            }
        },
        elementRemove(element, index) {
            if (this.elements.length <= 1) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '最低1つ必要です。')
                return
            }
            this.elements.splice(index, 1)
            this.imageUrl1.splice(index, 1)
            this.imageUrl2.splice(index, 1)
            this.imageUrl3.splice(index, 1)
            this.imageUrl4.splice(index, 1)
            this.imageRaw1.splice(index, 1)
            this.imageRaw2.splice(index, 1)
            this.imageRaw3.splice(index, 1)
            this.imageRaw4.splice(index, 1)
        },
        handleImageAdd1(file, idx) {
            this.imageRaw1.splice(idx, 1, file.raw)
            this.imageUrl1.splice(idx, 1, URL.createObjectURL(file.raw))
            this.fetchedImage = true
        },
        handleImageAdd2(file, idx) {
            this.imageRaw2.splice(idx, 1, file.raw)
            this.imageUrl2.splice(idx, 1, URL.createObjectURL(file.raw))
            this.fetchedImage = true
        },
        handleImageAdd3(file, idx) {
            this.imageRaw3.splice(idx, 1, file.raw)
            this.imageUrl3.splice(idx, 1, URL.createObjectURL(file.raw))
            this.fetchedImage = true
        },
        handleImageAdd4(file, idx) {
            this.imageRaw4.splice(idx, 1, file.raw)
            this.imageUrl4.splice(idx, 1, URL.createObjectURL(file.raw))
        },
        handleImageDel1(idx) {
            this.imageRaw1.splice(idx, 1, '')
            this.imageUrl1.splice(idx, 1, '')
        },
        handleImageDel2(idx) {
            this.imageRaw2.splice(idx, 1, '')
            this.imageUrl2.splice(idx, 1, '')
        },
        handleImageDel3(idx) {
            this.imageRaw3.splice(idx, 1, '')
            this.imageUrl3.splice(idx, 1, '')
        },
        handleImageDel4(idx) {
            this.imageRaw4.splice(idx, 1, '')
            this.imageUrl4.splice(idx, 1, '')
        },
    }
}
</script>

<style scoped>
    .materialForm {
        width: 732px;
    }
    .el-upload-list__item-thumbnail {
        object-fit: cover;
    }
    .el-upload-list--picture-card .el-upload-list__item {
        width: 178px;
        height: 178px;
        margin: 20px 20px 0 0;
    }
    .avatar-uploader {
        margin: 20px 190px 0 0;
    }
    .actionIcon {
        width: 50px;
        background: #F5F7FA;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8F9398;
    }
    .actionIconWrapper {
        width: 150px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        margin: 0 20px;
    }
    .make-index {
        margin: 0px 15px 0px 0;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        background-color: #E3D099;
        display: inline-block;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        box-sizing: content-box;
        padding: 5px;
        border-radius: 50%;
    }
    .make-index-wrapper {
        margin-top: 0;
    }
</style>
