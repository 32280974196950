<template>
    <el-dialog
            title="APP見つける特集 - 編集"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>タイトル</dt>
                <dd>
                    <el-input placeholder="title" v-model="title"></el-input>
                </dd>
                <dt>表示</dt>
                <dd>
                    <el-checkbox v-model="tabIsActive"></el-checkbox>
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="edit(id, title, tabIsActive)">更新</el-button>
            <el-button @click="close">閉じる</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../store/mutation-types'

export default {
    props  : {
        isActive: Boolean,
        row     : Object,
    },
    data() {
        return {
            title      : '',
            tabIsActive: '',
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        async edit(id, titile, tabIsActive) {
            if (!titile.length) {
                return
            }
            const params = {
                'title'    : titile,
                'is_active': tabIsActive,
            }
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.put('/api/organization/app_topics/' + id, params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'データの更新に失敗しました。')
                return
            }
            this.$emit('close')
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの更新に成功しました。')
            this.$emit('reload')
        }
    },
    watch  : {
        row() {
            this.id = this.row.id
            this.title = this.row.title
            this.tabIsActive = this.row.is_active
        }
    }
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            width: 15%;
            margin-bottom: 10px;
        }

        dd {
            width: 85%;
            margin-bottom: 10px;
        }
    }
</style>