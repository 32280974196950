<template>
   <el-col :span="8">
       <el-input :placeholder="placeholder" v-model="input" >
           <el-button @click="search" slot="append" icon="el-icon-search"></el-button>
       </el-input>
   </el-col>
</template>
<script>
export default {
    name:'SearchBox',
    props:{
        placeholder: String
    },
    data() {
        return {
            input: '',
        }
    },
    mounted () {
        this.input = this.$route.query.keyword
    },
    methods: {
        search() {
            const query = Object.assign({},this.$route.query,{
                keyword: this.input,
                page: 1
            })
            this.$router.push({ query })
        }
    }
}
</script>
