<template>
    <!--
    TimeRangePickerのリスト表示コンポーネント
    allowMultipleRangeがtrueの場合、動的にリストを追加・削除できるようになります。
    -->
    <div class="timeRangeList">
        <div class="checkRow">
            <el-checkbox
                :value="value.is_twenty_four"
                :label="twentyFourLabel"
                @input="$emit('input', {
                    is_twenty_four: !value.is_twenty_four,
                    times: value.times,
                })"
            />
        </div>
        <div v-if="!value.is_twenty_four" class="listRow">
            <div v-for="(timeRange, idx) in value.times" :key="idx" class="item">
                <time-range-picker
                    :value="timeRange"
                    class="picker"
                    size="mini"
                    :start-placeholder="startPlaceholder"
                    :end-placeholder="endPlaceholder"
                    @input="newRange => $emit('input', {
                        is_twenty_four: value.is_twenty_four,
                        times: value.times.map((v, i) => i === idx ? newRange : v),
                    })"
                />
                <div class="control">
                    <!-- 削除ボタン -->
                    <el-button
                        v-if="value.times.length > 1"
                        class="deleteButton"
                        plain
                        type="warning"
                        icon="el-icon-delete"
                        size="mini"
                        @click="$emit('input', {
                            is_twenty_four: value.is_twenty_four,
                            times: value.times.filter((_, i) => i !== idx),
                        })"
                    />
                    <!-- 追加ボタン -->
                    <el-button
                        v-if="idx === value.times.length - 1"
                        class="addButton"
                        type="info"
                        icon="el-icon-plus"
                        size="mini"
                        @click="$emit('input', {
                            is_twenty_four: value.is_twenty_four,
                            times: [...value.times, ['', '']],
                        })"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TimeRangePicker from './TimeRangePicker'

export default {
    components: { TimeRangePicker },
    props: {
        value: {
            type: Object,
            default: () => ({is_twenty_four: false, times: [['', '']]}),
        },
        twentyFourLabel: {
            type: String,
            default: '24時間',
        },
        startPlaceholder: {
            type: String,
            default: '開始',
        },
        endPlaceholder: {
            type: String,
            default: '終了',
        },
    },
}
</script>

<style scoped>
.timeRangeList {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.checkRow {
    padding: 4px 0 0 0;
}

.listRow {
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.item {
    display: flex;
    gap: 7px;
}

.control {
    display: flex;
    gap: 3px;
}

.addButton.el-button {
    margin-left: 0;
}
</style>
