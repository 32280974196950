<template>
    <div class="tableItem">
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent>
            <div class="tableItem__formHeading">テーブル</div>
            <div>
                <el-form-item style="margin:0px; padding-top:3px;">
                    <div class="menubar">
                        <div class="menubar__buttonWrapper">
                            <el-button
                                v-if="!editor.isActive.table()"
                                @click="editor.commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: true })"
                                class="menubar__button"
                                size="mini"
                            >
                                <img src="/images/icon/table.svg" alt="">
                            </el-button>
                            <span v-if="editor.isActive.table()">
                                <el-button
                                    @click="editor.commands.deleteTable"
                                    class="menubar__button"
                                    size="mini"
                                >
                                    <img src="/images/icon/delete_table.svg" alt="">
                                </el-button>
                                <el-button
                                    @click="editor.commands.addColumnBefore"
                                    class="menubar__button"
                                    size="mini"
                                >
                                    <img src="/images/icon/add_col_before.svg" alt="">
                                </el-button>
                                <el-button
                                    @click="editor.commands.addColumnAfter"
                                    class="menubar__button"
                                    size="mini"
                                >
                                    <img src="/images/icon/add_col_after.svg" alt="">
                                </el-button>
                                <el-button
                                    @click="editor.commands.deleteColumn"
                                    class="menubar__button"
                                    size="mini"
                                >
                                    <img src="/images/icon/delete_col.svg" alt="">
                                </el-button>
                                <el-button
                                    @click="editor.commands.addRowBefore"
                                    class="menubar__button"
                                    size="mini"
                                >
                                    <img src="/images/icon/add_row_before.svg" alt="">
                                </el-button>
                                <el-button
                                    @click="editor.commands.addRowAfter"
                                    class="menubar__button"
                                    size="mini"
                                >
                                    <img src="/images/icon/add_row_after.svg" alt="">
                                </el-button>
                                <el-button
                                    @click="editor.commands.deleteRow"
                                    class="menubar__button"
                                    size="mini"
                                >
                                    <img src="/images/icon/delete_row.svg" alt="">
                                </el-button>
                                <el-button
                                    @click="editor.commands.toggleCellMerge"
                                    class="menubar__button"
                                    size="mini"
                                >
                                    <img src="/images/icon/combine_cells.svg" alt="">
                                </el-button>
                            </span>
                        </div>
                        <div class="menubar__buttonWrapper">
                            <span v-if="editor.isActive.table()">
                                <el-button
                                    v-for="(color, idx) in tableItemColorList"
                                    :key="idx"
                                    @click="editor.commands.setCellAttr({ name: 'background', value: color.value})"
                                    class="menubar__button"
                                    size="mini"
                                >
                                    <svg width="24" height="24">
                                        <rect width="24" height="24" :fill="color.value" />
                                    </svg>
                                </el-button>
                                <el-button
                                    @click="editor.commands.setCellAttr({ name: 'background', value: ''})"
                                    class="menubar__button"
                                    size="mini"
                                >
                                    <svg width="24" height="24">
                                        <rect width="24" height="24" stroke="#303030" stroke-width="1" fill="#FFFFFF" />
                                    </svg>
                                </el-button>
                            </span>
                        </div>
                    </div>
                    <editor-menu-bubble :editor="editor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, getMarkAttrs, menu }">
                        <div
                            :class="{ 'is-active': menu.isActive }"
                            :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                            class="menububble"
                        >
                            <button
                                :class="{ 'is-active': isActive.textSmall() }"
                                @click="commands.textSmall"
                                class="menububble__button"
                            >
                                小
                            </button>
                            <button
                                :class="{ 'is-active': isActive.textLarge() }"
                                @click="commands.textLarge"
                                class="menububble__button"
                            >
                                大
                            </button>
                            <button
                                :class="{ 'is-active': isActive.textBold() }"
                                @click="commands.textBold"
                                class="menububble__button"
                            >
                                太字
                            </button>
                            <button
                                :class="{ 'is-active': isActive.textLargeBold() }"
                                @click="commands.textLargeBold"
                                class="menububble__button"
                            >
                                太字&大
                            </button>
                            <button
                                :class="{ 'is-active': isActive.colorRed() }"
                                @click="commands.colorRed"
                                class="menububble__button"
                            >
                                赤
                            </button>
                        </div>
                    </editor-menu-bubble>

                </el-form-item>
                <editor-content :editor="editor" class="itemFormContent" style="font-size:18px;" />

                <span slot="footer">
                    <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
                    <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
                </span>
            </div>
        </el-form>
    </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble, Extension } from 'tiptap'
import { HardBreak, History } from 'tiptap-extensions'
import TextSmall from './Components/Tiptap/TextSmall'
import TextLarge from './Components/Tiptap/TextLarge'
import TextLargeBold from './Components/Tiptap/TextLargeBold'
import TextBold from './Components/Tiptap/TextBold'
import ColorRed from './Components/Tiptap/ColorRed'
import CustomLink from './Components/Tiptap/CustomLink'
import CustomTable from './Components/Tiptap/Table/CustomTable'
import CustomTableHeader from './Components/Tiptap/Table/CustomTableHeader'
import CustomTableRow from './Components/Tiptap/Table/CustomTableRow'
import CustomTableCell from './Components/Tiptap/Table/CustomTableCell'

const defaultFormValues = {
    id: null,
    text_body: null,
}
const defaultTextStyle = {
    height: 140,
    bottomAddPixel: 5
}

export default {
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        tableItemColorList: {
            required: true,
            type: Array,
        }
    },
    components: {
        Editor,
        EditorMenuBar,
        EditorMenuBubble,
        EditorContent,
    },
    data() {
        return {
            form: {},
            resourceName: 'tables',
            keepInBounds: true,
            editor: new Editor({
                autoFocus: true,
                onFocus: ({ view }) => {
                    // onInit時にoffsetHeight取得出来なかった為、autoFocus有効化し
                    // 表示時にフォーカス設定のタイミングで高さ調整実施
                    const editorHeight = view.dom.firstElementChild
                    if (Number(editorHeight.offsetHeight) > defaultTextStyle.height) {
                        view.dom.style.height = editorHeight.offsetHeight + defaultTextStyle.bottomAddPixel + 'px'
                    } else {
                        view.dom.style.height = defaultTextStyle.height + 'px'
                    }
                },
                onUpdate: ({ getHTML }) => {
                    // スタイル崩れ防止の為、Pタグを削除
                    this.form.text_body = getHTML().replace().replace(/<(|\/)p(?: .+?)?>.*?/g, '')
                },
                extensions: [
                    new HardBreak(),
                    new class extends Extension {
                        keys () {
                            return {
                                Enter (state, dispatch, view) {
                                    const { schema, tr } = view.state
                                    const hardbreak = schema.nodes.hard_break
                                    const transaction = tr.replaceSelectionWith(hardbreak.create()).scrollIntoView()
                                    view.dispatch(transaction)
                                    return true
                                }
                            }
                        }
                    }(),
                    new TextSmall(),
                    new TextLarge(),
                    new TextLargeBold(),
                    new TextBold(),
                    new ColorRed(),
                    new CustomLink(),
                    new CustomTable({
                        resizable: true
                    }),
                    new CustomTableHeader(),
                    new CustomTableRow(),
                    new CustomTableCell(),
                    new History()
                ]
            })
        }
    },
    created() {
        this.form = Object.assign({}, defaultFormValues)
    },
    beforeDestroy () {
        this.editor.destroy()
    },
    methods: {
        submitForm () {
            this.save(this.resourceName, this.form, this.id)
        },
        openDialog(input) {
            this.form = Object.assign({}, this.form, input)
            this.editor.setContent(this.form.text_body)
        },
    },
}

</script>

<style scoped lang="scss">
.tableItem {
}

$color-white: #ffffff;
.menububble {
    position: absolute;
    display: flex;
    z-index: 20;
    background: #000000;
    border-radius: 5px;
    padding: 0.3rem;
    margin-bottom: 0.5rem;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }

    &__button {
        display: inline-flex;
        background: transparent;
        border: 0;
        color: $color-white;
        padding: 0.2rem 0.5rem;
        margin-right: 0.2rem;
        border-radius: 3px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background-color: rgba($color-white, 0.1);
        }

        &.is-active {
            background-color: rgba($color-white, 0.5);
        }
    }

    &__form {
        display: flex;
        align-items: center;
    }

    &__input {
        font: inherit;
        border: none;
        background: transparent;
        color: $color-white;
    }
}
::v-deep .itemFormContent {
    margin-bottom: 30px;
    .ProseMirror {
        height: 140px;
        overflow-y: scroll;
    }
    table {
        border-collapse: collapse;
        table-layout: fixed;
        margin: 0.5em 0;
        background: #fff;

        td, th {
            min-width: 25px;
            height: 30px;
            border: 1px solid #303030;
            padding: 3px 5px;
            vertical-align: top;
            box-sizing: border-box;
            position: relative;
            font-weight: normal;
        }

        th {
            text-align: left;
        }

        .selectedCell:after {
            z-index: 2;
            position: absolute;
            content: "";
            left: 0; right: 0; top: 0; bottom: 0;
            background: rgba(200, 200, 255, 0.4);
            pointer-events: none;
        }

        .column-resize-handle {
            position: absolute;
            right: -2px; top: 0; bottom: 0;
            width: 4px;
            z-index: 20;
            background-color: #adf;
            pointer-events: none;
        }

        p {
            margin: 0;
        }
    }

    .resize-cursor {
        cursor: ew-resize;
        cursor: col-resize;
    }
}
.menubar {
    &__buttonWrapper {
        margin-bottom: 15px;
    }
}
</style>

