import { render, staticRenderFns } from "./CooperateImage.vue?vue&type=template&id=64422562&scoped=true"
import script from "./CooperateImage.vue?vue&type=script&lang=js"
export * from "./CooperateImage.vue?vue&type=script&lang=js"
import style0 from "./CooperateImage.vue?vue&type=style&index=0&id=64422562&prod&scoped=true&lang=css"
import style1 from "./CooperateImage.vue?vue&type=style&index=1&id=64422562&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64422562",
  null
  
)

export default component.exports