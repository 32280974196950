<template>
    <el-row class="headerTabWrapper">
        <div class="headerTabs">
            <div :class="tabClass('heading')" @click="selectTab('heading')"><i class="fas fa-list-ul fa-2x"></i><div class="itemSelectTab">見出し</div></div>
            <div :class="tabClass('image')" @click="selectTab('image')"><i class="fas fa-camera-retro fa-2x"></i><div class="itemSelectTab">画像</div></div>
            <div :class="tabClass('video')" @click="selectTab('video')"><i class="fab fa-youtube fa-2x"></i><div class="itemSelectTab">動画</div></div>
            <div :class="tabClass('text')" @click="selectTab('text')"><i class="fas fa-pencil-alt fa-2x"></i><div class="itemSelectTab">テキスト</div></div>
            <div :class="tabClass('emphasis')" @click="selectTab('emphasis')"><i class="fas fa-check-circle fa-2x"></i><div class="itemSelectTab">強調表示</div></div>
            <div :class="tabClass('information')" @click="selectTab('information')"><i class="fas fa-table fa-2x"></i><div class="itemSelectTab">アイテム情報</div></div>
            <div :class="tabClass('comparison')" @click="selectTab('comparison')"><i class="far fa-window-restore fa-2x"></i><div class="itemSelectTab">比較表</div></div>
            <div :class="tabClass('table')" @click="selectTab('table')"><i class="fas fa-align-justify fa-2x"></i><div class="itemSelectTab">テーブル</div></div>
            <div :class="tabClass('link')" @click="selectTab('link')"><i class="fas fa-link fa-2x"></i><div class="itemSelectTab">リンク</div></div>
            <div :class="tabClass('place')" @click="selectTab('place')"><i class="fas fa-store fa-2x"></i><div class="itemSelectTab">店舗情報</div></div>
            <div :class="tabClass('affiliate')" @click="selectTab('affiliate')"><i class="fab fa-amazon fa-2x"></i><div class="itemSelectTab">アフィリエイト</div></div>
            <div :class="tabClass('quote')" @click="selectTab('quote')"><i class="fas fa-quote-left fa-2x"></i><div class="itemSelectTab">引用</div></div>
            <div :class="tabClass('pinterest')" @click="selectTab('pinterest')"><i class="fab fa-pinterest fa-2x"></i><div class="itemSelectTab">Pinterest</div></div>
            <div :class="tabClass('tweet')" @click="selectTab('tweet')"><i class="fa-brands fa-x-twitter fa-2xl"></i><div class="itemSelectTab">X</div></div>
            <div :class="tabClass('product')" @click="selectTab('product')"><i class="fas fa-shopping-bag fa-2x"></i><div class="itemSelectTab">グッズ情報</div></div>
            <div :class="tabClass('embedded')" @click="selectTab('embedded')"><i class="fas fa-clipboard fa-2x"></i><div class="itemSelectTab">埋め込みタグ</div></div>
            <div v-if="response.isShowRecipeLinkItem" :class="tabClass('recipe_link')" @click="selectTab('recipe_link')"><i class="fas fa-utensils fa-2x"></i><div class="itemSelectTab">レシピリンク</div></div>
            <div v-if="response.isShowRecipeSummaryItem" :class="tabClass('recipe_summary')" @click="selectTab('recipe_summary')"><i class="fab fa-apple fa-2x"></i><div class="itemSelectTab">単一レシピ</div></div>
        </div>
        <div class="headerForm" v-if="selectedTab !== null">
            <heading-type
                v-if="selectedTab === 'heading'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel"
                :headingTypeList="response.headingTypeList"
            />
            <image-type
                v-if="selectedTab === 'image'"
                class="headerItemAddBox"
                :articleFormat="articleFormat"
                :isTieup="isTieup"
                :save="create"
                :saveAndContinue="createAndContinue"
                @close="closeMenuOfCancel"
            />
            <video-type
                v-if="selectedTab === 'video'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel"
            />
            <text-type
                v-if="selectedTab === 'text'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel" />
            <link-type
                v-if="selectedTab === 'link'"
                class="headerItemAddBox"
                :articleFormat="articleFormat"
                :save="create"
                :saveAndContinue="createAndContinue"
                @close="closeMenuOfCancel"
            />
            <recipe-link-type
                v-if="selectedTab === 'recipe_link'"
                class="headerItemAddBox"
                :save="create"
                :articleFormat="articleFormat"
                @close="closeMenuOfCancel"
                :heading-type-options="response.headingTypeList"
            />
            <place-type
                v-if="selectedTab === 'place'"
                class="headerItemAddBox"
                :article-id="response.article.id"
                :save="create"
                @close="closeMenuOfCancel"
            />
            <affiliate-type
                v-if="selectedTab === 'affiliate'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel"
            />
            <quote-type
                v-if="selectedTab === 'quote'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel"
            />
            <pinterest-type
                v-if="selectedTab === 'pinterest'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel"
            />
            <tweet-type
                v-if="selectedTab === 'tweet'"
                class="headerItemAddBox"
                :save="create"
                :saveAndContinue="createAndContinue"
                @close="closeMenuOfCancel"
            />
            <product-type
                v-if="selectedTab === 'product'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel"
            />
            <embedded-type
                v-if="selectedTab === 'embedded'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel"
            />
            <emphasis-type
                v-if="selectedTab === 'emphasis'"
                class="headerItemAddBox"
                :save="create"
                :emphasis-item-color-list="response.emphasisItemColorList"
                :emphasis-item-heading-tag-type-list="response.emphasisItemHeadingTagTypeList"
                :emphasis-item-type-list="response.emphasisItemTypeList"
                @close="closeMenuOfCancel"
            />
            <information-type
                v-if="selectedTab === 'information'"
                class="headerItemAddBox"
                :save="create"
                :information-item-type-list="response.informationItemTypeList"
                :information-item-other-label-list="response.informationItemOtherLabelList"
                :information-item-table-body-evaluation="response.informationItemTableBodyEvaluation"
                :information-items="response.informationItems"
                :items="response.article.items"
                @close="closeMenu"
            />
            <comparison-type
                v-if="selectedTab === 'comparison'"
                class="itemAddBox"
                :save="create"
                :article-id="response.article.id"
                :items="response.article.items"
                :heading-different-information-items="response.headingDifferentInformationItems"
                @close="closeMenu"
            />
            <table-type
                v-if="selectedTab === 'table'"
                class="headerItemAddBox"
                :save="create"
                :table-item-color-list="response.tableItemColorList"
                @close="closeMenu"
            />
            <recipe-summary-type
                v-if="selectedTab === 'recipe_summary'"
                class="headerItemAddBox"
                :save="create"
                :emphasis-item-color-list="response.emphasisItemColorList"
                :emphasis-item-heading-tag-type-list="response.emphasisItemHeadingTagTypeList"
                :emphasis-item-type-list="response.emphasisItemTypeList"
                :article-format="articleFormat"
                @close="closeMenu"
            />

        </div>
    </el-row>
</template>

<script>
import HeadingType from '../Form/HeadingType'
import TextType from '../Form/TextType'
import ProductType from '../Form/ProductType'
import QuoteType from '../Form/QuoteType'
import AffiliateType from '../Form/AffiliateType'
import TweetType from '../Form/TweetType'
import PinterestType from '../Form/PinterestType'
import ImageType from '../Form/ImageType'
import PlaceType from '../Form/PlaceType'
import LinkType from '../Form/LinkType'
import VideoType from '../Form/VideoType'
import EmbeddedType from '../Form/EmbeddedType'
import EmphasisType from '../Form/EmphasisType'
import InformationType from '../Form/InformationType'
import ComparisonType from '../Form/ComparisonType'
import RecipeLinkType from '../Form/RecipeLinkType'
import TableType from '../Form/TableType'
import RecipeSummaryType from '../Form/RecipeSummaryType'

export default {
    components: {
        HeadingType,
        TextType,
        ProductType,
        QuoteType,
        AffiliateType,
        TweetType,
        PinterestType,
        ImageType,
        PlaceType,
        LinkType,
        VideoType,
        EmbeddedType,
        EmphasisType,
        InformationType,
        ComparisonType,
        RecipeLinkType,
        TableType,
        RecipeSummaryType,
    },
    props: {
        articleFormat: String,
        response: null,
        isTieup: Boolean,
    },
    data () {
        return {
            selectedTab: null,
        }
    },
    methods: {
        tabClass(active) {
            let className = 'headerTab'
            if (active === this.selectedTab) {
                className += ' active'
            }
            return className
        },
        selectTab(tab) {
            this.selectedTab = tab
        },
        closeMenu () {
            this.selectedTab = null
        },
        closeMenuOfCancel () {
            if (!confirm('キャンセルしますか？')) {
                return
            }
            this.selectedTab = null
        },
        async create (type, form) {
            this.$emit('create', type, form)
        },
        async createAndContinue (type, form) {
            this.$emit('createAndContinue', type, form)
        },
    },
}
</script>
