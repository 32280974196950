import moment from 'moment'

export default {
    filters: {
        showDateFormat( datetime ) {
            return datetime ? moment(datetime).format('YYYY-MM-DD') : ''
        },
        showTimeFormat( datetime ) {
            return datetime ? moment(datetime).format('HH:mm:ss') : ''
        }
    },
    methods: {
        renderTableHeader: function(args) {
            return function () {
                let arg = null
                if (args.typeof === 'string') {
                    arg = [this.$createElement('span', args)]
                } else {
                    arg = []
                    for(let i in args) {
                        if (i > 0) arg.push(this.$createElement('br'))
                        arg.push(this.$createElement('span', args[i]))
                    }
                }

                return this.$createElement('div', {
                    style: {
                        lineHeight: 1.7
                    }
                }, arg)
            }
        },
        makeServiceUrl(path) {
            if (process.env.NODE_ENV === 'production') {
                return `https://macaro-ni.jp${path}`
            } else if (process.env.NODE_ENV === 'development') {
                return  `https://stg-renew.macaro-ni.jp${path}`
            }

            // TODO stgとlocalでenvが分かれていない
            return `https://localhost${path}`
        }
    }
}
