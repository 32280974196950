<template>
    <el-row>

        <normal-header
            v-if="articleFormat === 'normal'"
            :articleFormat="articleFormat"
            :response="response"
            :isTieup="isTieup"
            ref="normalHeader"
            @create="create"
            @createAndContinue="createAndContinue"
        ></normal-header>

        <rich-header
            v-if="articleFormat === 'rich'"
            :articleFormat="articleFormat"
            :response="response"
            :isTieup="isTieup"
            ref="richHeader"
            @create="create"
            @createAndContinue="createAndContinue"
        ></rich-header>

        <video-header
            v-if="articleFormat === 'video'"
            :articleFormat="articleFormat"
            :response="response"
            :isTieup="isTieup"
            ref="videoHeader"
            @create="create"
            @createAndContinue="createAndContinue"
        ></video-header>

    </el-row>
</template>

<script>

import * as types from '../../../../store/mutation-types'
import NormalHeader from './MenuBar/NormalHeader'
import RichHeader from './MenuBar/RichHeader'
import VideoHeader from './MenuBar/VideoHeader'

export default {
    components: {
        NormalHeader,
        RichHeader,
        VideoHeader,
    },
    props: {
        response: null,
        fetch: Function,
        articleFormat: String,
        isTieup:Boolean,
    },
    data () {
        return {
            order: 1,
        }
    },
    methods: {
        async create (type, form) {
            const ret = await this.performCreate(type, form)
            if (ret === true) {
                if (this.articleFormat === 'normal') {
                    this.$refs.normalHeader.closeMenu()
                } else if (this.articleFormat === 'rich') {
                    this.$refs.richHeader.closeMenu()
                } else if (this.articleFormat === 'video') {
                    this.$refs.videoHeader.closeMenu()
                }
            }
        },
        async performCreate(type, form)  {
            this.$store.commit(types.PAGE_LOADING,true)
            if (form instanceof FormData) {
                form.append('order_num', this.order)
            } else {
                form.order_num = this.order
            }
            const {error} = await axios.post('/api/articles/'+this.response.article.id+'/items/' + type, form)

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING,false)
                return false
            }

            await this.fetch()
            this.$store.commit(types.PAGE_LOADING,false)
            return true
        },
        async createAndContinue (type, form) {
            await this.performCreate(type, form)
        },
    },
}
</script>

<style>
.headerTabWrapper {
    margin: 15px 15px 20px;
}
.headerTabs {
    display: flex;
    justify-content: space-around;
}
.headerTab {
    width: 100%;
    padding: 13px 0;
    font-size: 12px;
    color: #606266;
    font-weight: bold;
    text-align: center;
    border: 1px solid #606266;
    cursor: pointer;
}
.headerTab.active {
    border-bottom: 0;
    cursor: default;
}
.headerForm {
    padding: 15px;
    border: 1px solid #606266;
    border-top: 0;
}
.itemSelectTab {
    margin-top: 5px;
}
</style>
