<template>
    <div>
        <div class="selectModel">
            <label for="model">モデル選択</label>
            <select v-model="bedrockModel.selected" id="model">
                <option v-for="option in bedrockModel.options" :key="option.value" :value="option.value">
                    {{ option.label }}
                </option>
            </select>
        </div>
        <section class="section">
            <div class="faq">
                <h1>キーワードからよくある質問の生成</h1>
                <input class="faq_input" type="text" placeholder="キーワード ex:豆腐" v-model="faq.keyword" @keydown.enter="e => e.isComposing || generateFaq(faq.keyword)">
                <button @click="generateFaq(faq.keyword)">生成（20〜40秒ぐらいかかる）</button>
                <div v-if="faq.result.length > 0" class="faq_result">
                    <div class="faq_resultTitle">生成結果</div>
                    <ul>
                        <li v-for="(item, idx) in faq.result" :key="idx" class="faq_resultItem">
                            <p>Q. {{ item.question }}</p>
                            <p>A. {{ item.answer }}</p>
                            <div class="faq_resultReference">
                                <a v-for="article in item.referenceArticles" :key="article.url" :href="article.url" target="_blank">
                                    {{ article.title }}
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="sideDish">
                <h1>主菜料理名から副菜の提案</h1>
                <input class="sideDish_input" type="text" placeholder="料理名 ex:ハンバーグ" v-model="sideDish.dishName" @keydown.enter="e => e.isComposing || generateSideDish(sideDish.dishName)">
                <button @click="generateSideDish(sideDish.dishName)">生成（20〜40秒ぐらいかかる）</button>
                <div v-if="sideDish.result.length > 0" class="sideDish_result">
                    <div class="sideDish_resultTitle">生成結果</div>
                    <ul>
                        <li v-for="(item, idx) in sideDish.result" :key="idx" class="sideDish_resultItem">
                            <p>{{ item.sideDishName }}</p>
                            <p>{{ item.description }}</p>
                            <div class="sideDish_resultReference">
                                <a v-for="article in item.referenceArticles" :key="article.url" :href="article.url" target="_blank">
                                    {{ article.title }}
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="relationArticles">
                <h1>記事IDから関連記事を検索</h1>
                <p>※ モデル選択は結果に影響しません</p>
                <input class="relationArticles_input" type="text" placeholder="記事ID" v-model="relationArticles.articleId" @keydown.enter="e => e.isComposing || fetchRelationArticles(relationArticles.articleId)">
                <button @click="fetchRelationArticles(relationArticles.articleId)">検索</button>
                <div v-if="relationArticles.result.length > 0" class="relationArticles_result">
                    <div class="relationArticles_resultTitle">検索結果</div>
                    <ul>
                        <li v-for="(item, idx) in relationArticles.result" :key="idx" class="relationArticles_resultItem">
                            <div>
                                <p>類似スコア：{{item.score}}</p>
                                <a :href="item.url" target="_blank">{{item.title}}</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'

export default {
    name: 'BedrockIndex',
    data() {
        return {
            bedrockModel: {
                selected: 'us.anthropic.claude-3-5-sonnet-20241022-v2:0',
                options: [
                    {label: 'Anthropic Claude 3.5', value: 'us.anthropic.claude-3-5-sonnet-20241022-v2:0'},
                    {label: 'Anthropic Claude 3.7 (2/25 New!)', value: 'us.anthropic.claude-3-7-sonnet-20250219-v1:0'},
                ]
            },
            faq: {
                keyword: '',
                result: [],
            },
            sideDish: {
                dishName: '',
                result: [],
            },
            relationArticles: {
                articleId: '',
                result: [],
            }
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'Bedrockサンプル')
    },
    methods: {
        async generateFaq(keyword) {
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/bedrock/faq', {
                params: {
                    modelId: this.bedrockModel.selected,
                    keyword,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.faq.result = structuredClone(data.faqs)
            this.$store.commit(types.LOADING, false)
        },
        async generateSideDish(dishName) {
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/bedrock/sideDish', {
                params: {
                    modelId: this.bedrockModel.selected,
                    dishName,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.sideDish.result = structuredClone(data.sideDishes)
            this.$store.commit(types.LOADING, false)
        },
        async fetchRelationArticles(articleId) {
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/bedrock/relationArticles', {
                params: {
                    articleId,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.relationArticles.result = structuredClone(data.relationArticles)
            this.$store.commit(types.LOADING, false)
        },
    }
}
</script>

<style scoped>
.selectModel {
    margin: 0 0 20px 0;
}

.section {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 0 0 30px 0;
}

.faq_input {
    width: 150px;
}
.faq_resultTitle {
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0 0 0;
}
.faq_resultItem {
    border-bottom: 1px solid #ddd;
    padding: 0 0 10px 0;
}
.faq_resultReference {
    display: flex;
    flex-direction: column;
}

.sideDish_input {
    width: 150px;
}
.sideDish_resultTitle {
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0 0 0;
}
.sideDish_resultItem {
    border-bottom: 1px solid #ddd;
    padding: 0 0 10px 0;
}
.sideDish_resultReference {
    display: flex;
    flex-direction: column;
}

.relationArticles_input {
    width: 150px;
}
.relationArticles_resultTitle {
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0 0 0;
}
.relationArticles_resultItem {
    border-bottom: 1px solid #ddd;
    padding: 0 0 10px 0;
}
</style>
