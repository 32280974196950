<template>
    <el-dialog title="インセンティブ詳細" @close="close" :visible="dialog" height="50%">
        <el-table :data="incentiveHistories" max-height="800">
            <el-table-column label="記事ID" width="150">
                <template slot-scope="scope">
                    {{ scope.row.article_id}}
                </template>
            </el-table-column>
            <el-table-column label="詳細" prop="description"></el-table-column>
            <el-table-column label="料金" width="150">
                <template slot-scope="scope">
                    {{ scope.row.price | number }}円
                </template>
            </el-table-column>
            <el-table-column label="削除" width="150">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.can_delete" type="danger" @click="deleteIncentiveHistory(scope.row.id)">削除</el-button>
                    <span v-else> - </span>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
</template>

<script>
export default {
    props: {
        incentiveHistories: Array,
        dialog: Boolean,
    },
    methods: {
        close() {
            this.$emit('close')
        },
        deleteIncentiveHistory(id) {
            if (! confirm('対象の金額がインセンティブから減算されます。\n本当に削除しますか？')) {
                return
            }
            this.$emit('deleteIncentiveHistory', id)
        }
    }
}
</script>

