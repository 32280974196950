<template>
    <div>
        <el-row align="middle" style="margin-top: 20px;">
            <el-radio-group v-model="tabs" size="mini">
                <el-radio-button label="upload">アップロード</el-radio-button>
                <el-radio-button label="url">URLから検索</el-radio-button>
                <el-radio-button label="embed_tag">埋め込みタグ</el-radio-button>
            </el-radio-group>
        </el-row>

        <el-row v-if="tabs ==='upload'">
            <el-row>
                <el-form class="itemForm" @submit.native.prevent label-width="120px" >
                    <el-col :span="24">
                        <el-form-item style="margin-bottom:10px;" class="uploadVideo" label="動画">
                            <el-upload
                                :headers="header"
                                class="avatar-uploader"
                                :auto-upload="false"
                                action=""
                                :on-change="handleVideoAdd"
                                :show-file-list="false"
                                accept="video/*"
                            >
                                <video v-if="videoUrl" :src="videoUrl" class="avatar"></video>
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <el-row>
                <div class="itemFormFooter">
                    <el-button type="primary" size="mini" :disabled="!fetchedVideo" @click="submitVideoForm">保存する</el-button>
                    <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
                </div>
            </el-row>
        </el-row>

        <el-row v-if="tabs ==='url'">
            <el-form class="itemForm"  @submit.native.prevent="searchFromUrl" label-width="150px" >
                <el-form-item label="動画URL入力" style="width:80%;">
                    <el-input
                        style="width: 450px;"
                        type="text"
                        size="mini"
                        placeholder="追加する動画のURLを入力 (youtubeとvimeo、Instagramに対応しています)"
                        v-model="videoUrl">
                        <el-button @click="searchFromUrl" slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-form-item>
            </el-form>
            <div style="padding-left:20px;padding-bottom:20px;">
                <iframe v-if="fetchedYoutube" width="560" height="315" :src="youtubeUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe v-if="fetchedVimeo" :src="vimeoUrl" width="560" height="310" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                <video v-if="fetchedInstagram" :poster="instagramPosterUrl" muted="muted" controls="controls" :src="instagramVideoUrl" width="560" height="310"></video>
            </div>
            <div class="itemFormFooter">
                <el-button type="primary" size="mini" :disabled="!fetchedVideo" @click="submitForm">保存する</el-button>
                <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
            </div>
        </el-row>

        <el-row v-if="tabs ==='embed_tag'">
            <el-form v-if="!embedTag" class="itemForm" @submit.native.prevent label-width="120px" >
               <el-form-item label="埋め込みタグ">
                   <el-input
                       type="textarea"
                       size="mini"
                       :rows="3"
                       v-model="form.embed_tag">
                   </el-input>
               </el-form-item>
            </el-form>
            <el-row type="flex" style="padding: 0 0 20px 120px;">
                <span v-if="embedTag" v-html="embedTag"></span>
                <el-button v-if="!embedTag" :disabled="!form.embed_tag" size="mini" @click="fetchEmbedTag">タグ読み込み</el-button>
            </el-row>
            <el-row>
                <div class="itemFormFooter">
                    <el-button type="primary" size="mini" :disabled="!fetchedVideo" @click="submitForm">保存する</el-button>
                    <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
                </div>
            </el-row>
        </el-row>
    </div>
</template>

<script>

import * as types from '../../../../../store/mutation-types'

const defaultFormValues =  {
    video_key: null,
    video_type: null,
    video_url: null,
    video_comment: null,
    embed_tag:null,
    thumbnail_url: null
}
export default {
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
    },
    computed: {
        youtubeUrl() {
            return 'https://www.youtube.com/embed/' + this.form.video_key
        },
        vimeoUrl() {
            return '//player.vimeo.com/video/' + this.form.video_key
        },
        instagramPosterUrl() {
            return this.form.thumbnail_url
        },
        instagramVideoUrl() {
            return this.form.video_url
        },
        resourceName() {
            return 'videos'
        },
    },
    data () {
        return {
            form: {...defaultFormValues},
            videoUrl: null,
            searchResultUrl: null,
            tabs:'upload',
            fetchedSearch: false,
            fetchedYoutube: false,
            fetchedVimeo: false,
            fetchedVideo: false,
            fetchedInstagram: false,
            embedTag: null,
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
        }
    },
    methods: {
        fetchEmbedTag() {
            this.fetchedVideo = true
            this.form = Object.assign({}, this.form, {
                video_type: 'embed_tag'
            })
            this.embedTag = this.form.embed_tag
        },
        submitVideoForm() {
            this.form.video_file = this.videoRaw
            const fd = new FormData()
            _.forEach(this.form, (value,key) => {
                if (value === null) {
                    this.form[key] = ''
                }
            })
            this.form = Object.assign({}, defaultFormValues, this.form)
            fd.append('video_file', this.form.video_file)
            fd.append('video_type', this.form.video_type)
            fd.append('video_url', this.form.video_url)
            fd.append('thumbnail_url', this.form.thumbnail_url)
            fd.append('video_key', this.form.video_key)
            fd.append('embed_tag', this.form.embed_tag)
            this.form = fd
            this.save(this.resourceName, this.form, this.id)
        },
        submitForm(){
            this.save(this.resourceName, this.form, this.id)
        },
        searchFromUrl() {
            if (/^https?:\/\/www\.instagram.com\/p\/(.*?)/.test(this.videoUrl)) {
                this.searchInstagramFromUrl()
            } else if (/^https?:\/\/www\.youtube.com\/watch\?v=(.*?)/.test(this.videoUrl)
                    || /^https?:\/\/youtu.be\/(.*?)/.test(this.videoUrl)) {
                this.searchYoutubeFromUrl()
            } else if (/^https?:\/\/vimeo.com\/(.*?)/.test(this.videoUrl)) {
                this.searchVimeoFromUrl()
            } else {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '動画のURLが正しくありません')
            }

        },
        async searchYoutubeFromUrl() {
            this.$store.commit(types.EDITOR_LOADING, true)
            const {data, error} = await axios.get('/api/youtube/video', {
                params: {
                    video_url: this.videoUrl
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error.response.data.message)
                this.$store.commit(types.EDITOR_LOADING, false)
                return
            }
            this.form = Object.assign({}, this.form, {
                video_key: data.youtube_id,
                video_type: 'youtube',
                video_url: this.videoUrl,
            })
            this.fetchedYoutube = true
            this.fetchedVideo = true
            this.$store.commit(types.EDITOR_LOADING, false)
        },
        async searchInstagramFromUrl () {
            this.$store.commit(types.EDITOR_LOADING, true)
            const {data, error} = await axios.get('/api/instagram/video', {
                params: {
                    video_url: this.videoUrl
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error.response.data.message)
                this.$store.commit(types.EDITOR_LOADING, false)
                return
            }
            this.form = Object.assign({}, this.form, {
                video_key: data.video_key,
                video_type: 'instagram',
                video_url: data.video_url,
                thumbnail_url: data.poster_url,
            })
            this.fetchedInstagram = true
            this.fetchedVideo = true
            this.$store.commit(types.EDITOR_LOADING, false)

        },
        async searchVimeoFromUrl () {
            this.$store.commit(types.EDITOR_LOADING, true)
            const {data, error} = await axios.get('/api/vimeo/video', {
                params: {
                    video_url: this.videoUrl
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error.response.data.message)
                this.$store.commit(types.EDITOR_LOADING, false)
                return
            }
            this.form = Object.assign({}, this.form, {
                video_key: data.vimeo_id,
                video_type: 'vimeo',
                video_url: this.videoUrl,
            })
            this.fetchedVimeo = true
            this.fetchedVideo = true
            this.$store.commit(types.EDITOR_LOADING, false)

        },
        handleVideoAdd(file) {
            this.videoRaw = file.raw
            this.videoUrl = URL.createObjectURL(file.raw)
            this.fetchedVideo = true
            this.form.video_type = 'upload'
        },
    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px !important;
    text-align: center;
}
.avatar {
    width: 178px;
    display: block;
}
.searchVideo img{
    margin: 0 auto;
}
.serviceRadio .el-radio__input {
    margin-bottom:20px;
}
.serviceLabel > img{
    height:25px;
}
</style>

