<template>
    <div>
        <el-row >
            <el-form class="itemForm" @submit.native.prevent="startSearchKeyword" >
                <el-form-item style="width:412px;">
                    <el-input
                        width="50%"
                        type="text"
                        size="mini"
                        placeholder="一休検索"
                        v-model="keyword">
                    <el-button
                        native-type="submit"
                        slot="append"
                        icon="el-icon-search"></el-button>
                    </el-input>
                </el-form-item>
            </el-form>
        </el-row >
        <el-row v-for="(rowData,rowIndex) in arrengedList" :key="rowIndex" >
            <el-col :span="6" v-for="(data,index) in rowData" :key="index">
                <div class="thumbnails">
                    <img :src="data.ImageFileName" >
                    <div class="addButton">
                        <el-button type="primary" :disabled="data.added" size="mini" @click="addItem(data, index)">
                            <span v-if="data.added">
                                追加済み
                            </span>
                            <span v-else>
                                追加する
                            </span>
                        </el-button>
                    </div>
                </div>
            </el-col>
        </el-row >
        <el-row v-if="list.length > 0" type="flex" justify="center">
            <el-button style="width:80%;margin-top:10px;" @click="searchMore">次の画像を読み込む</el-button>
        </el-row >
        <div v-if="loading" v-loading="loading" class="loading"></div>
    </div>
</template>
<script>
import * as types from '../../../../../../../store/mutation-types'
export default {
    name:'ItemImageIkkyu',
    data () {
        return {
            list: [],
            page: 1,
            loading:false,
        }
    },
    props:{
        parentKeyword:null,
        arrengeList: Function,
        imageType: String,
    },
    computed: {
        keyword: {
            get() {
                return this.parentKeyword
            },
            set(keyword) {
                this.$emit('updateKeyword',keyword)
            }
        },
        arrengedList() {
            return this.arrengeList(this.list)
        },
    },
    methods: {
        async searchKeyword(){
            this.loading = true
            const {data,error} = await axios.get('/api/cooperation/ikkyu/images/search', {
                params: {
                    keyword:this.keyword,
                    page:this.page,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.loading = false
                return
            }
            if ('resuponse' in data.images) {
                this.list = Object.assign([],this.list.concat(data.images.resuponse.Restaurants))
            } else {
                this.list = []
            }
            this.loading = false
        },
        searchMore() {
            this.page++
            this.searchKeyword()
        },
        startSearchKeyword() {
            this.page = 1
            this.list = []
            this.searchKeyword()
        },
        addItem(data, index) {
            data.added = true
            this.$set(this.list, index, Object.assign({}, data, {added: true}))
            if (this.imageType === 'item') {
                this.$emit('save','Ikkyu',{
                    quote_image_url: data.ImageFileName,
                    quote_url: data.RestaurantURL,
                })
            } else if (this.imageType === 'icon') {
                this.$emit('saveIcon', {
                    thumbnail_quote_image_url: data.ImageFileName,
                    quote_image_url: data.RestaurantURL,
                })
            }
        }
    }
}

</script>
