<template>
    <div class="articleMain">
        <el-form @submit.native.prevent :model="form">
            <el-row type="flex" style="margin-bottom: 30px;">
                <el-col style="margin-right: 30px;" :span="8">
                    <el-form-item>
                        サムネイル
                        <el-upload
                            :headers="header"
                            class="avatar-uploader"
                            :auto-upload="false"
                            action=""
                            :on-change="handleThumbnailImageAdd"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif">
                            <img v-if="thumbnailImageUrl" :src="thumbnailImageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            <icon-aspect-check :imageUrl="thumbnailImageUrl"></icon-aspect-check>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item>
                        バナー
                        <el-upload
                            :headers="header"
                            class="avatar-uploader"
                            :auto-upload="false"
                            action=""
                            :on-change="handleBannerImageAdd"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif">
                            <img v-if="bannerImageUrl" :src="bannerImageUrl" class="rich-avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row type="flex" style="margin-bottom: 30px;">
                <el-col style="margin-right: 30px;" :span="8">
                    <el-form-item>
                        Top用バナー1
                        <el-upload
                            :headers="header"
                            class="avatar-uploader"
                            :auto-upload="false"
                            action=""
                            :on-change="handleTopBannerImage1Add"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif">
                            <img v-if="topBannerImage1Url" :src="topBannerImage1Url" class="rich-avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item>
                        Top用バナー2
                        <el-upload
                            :headers="header"
                            class="avatar-uploader"
                            :auto-upload="false"
                            action=""
                            :on-change="handleTopBannerImage2Add"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif">
                            <img v-if="topBannerImage2Url" :src="topBannerImage2Url" class="rich-avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-form @submit.native.prevent :model="form"  label-width="140px" >
            <el-form-item label="タイトル" style="margin: 0;">
                <el-input
                    type="text"
                    style="font-size:25px;"
                    @change="update"
                    maxlength="60"
                    v-model="form.article.title">
                </el-input>
                <text-length-count :targetText="form.article.title" :maxTextLength="60" ></text-length-count>
            </el-form-item>
            <el-form-item label="ディスクリプション" style="margin: 0;">
                <el-input
                    type="textarea"
                    size="medium"
                    placeholder="100〜140文字、キーワードが記事に設定されている場合は初めの50字以内に含むようにしてください。"
                    @change="update"
                    :rows="3"
                    maxlength="140"
                    v-model="form.article.description">
                </el-input>
                <text-length-count :targetText="form.article.description" :maxTextLength="140"></text-length-count>
            </el-form-item>
            <el-form-item label="メモ" style="margin: 0;">
                <el-input
                    type="textarea"
                    size="mini"
                    @change="update"
                    :rows="3"
                    maxlength="2000"
                    v-model="form.article.memo">
                </el-input>
                <text-length-count :targetText="form.article.memo" :maxTextLength="2000"></text-length-count>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import * as types from '../../../../store/mutation-types'
import TextLengthCount from '../ItemSection/Form/Components/TextLengthCount'
import IconAspectCheck from './Components/IconAspectCheck'

const formDefaultData = {
    article: {
        thumbnail: null,
        title: null,
        description: null,
        memo: null,
    },
    rich_article: {
        banner_image: null,
        top_banner_image_1: null,
        top_banner_image_2: null,
    },
}
export default {
    name:'ArticleMainRich',
    components: {
        TextLengthCount,
        IconAspectCheck,
    },
    props: {
        response: Object,
        fetch: Function
    },
    data () {
        return {
            baseCharCountList: [],
            form: {...formDefaultData},
            fetchedImage: false,
            thumbnailImageRaw: null,
            thumbnailImageUrl: null,
            bannerImageRaw: null,
            bannerImageUrl: null,
            topBannerImage1Raw: null,
            topBannerImage1Url: null,
            topBannerImage2Raw: null,
            topBannerImage2Url: null,
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
        }
    },
    mounted() {
        this.form.article = Object.assign({}, _.pick(this.article, Object.keys(this.form.article)))
        this.form.rich_article = Object.assign({}, _.pick(this.article.rich_article, Object.keys(this.form.rich_article)))
        if (this.article.thumbnail_cdn_url) {
            this.thumbnailImageUrl = this.article.thumbnail_cdn_url
        }
        if (this.article.rich_article.banner_image_cdn_url) {
            this.bannerImageUrl = this.article.rich_article.banner_image_cdn_url
        }
        if (this.article.rich_article.top_banner_image_1_cdn_url) {
            this.topBannerImage1Url = this.article.rich_article.top_banner_image_1_cdn_url
        }
        if (this.article.rich_article.top_banner_image_2_cdn_url) {
            this.topBannerImage2Url = this.article.rich_article.top_banner_image_2_cdn_url
        }
    },
    computed:{
        article() {
            if (!this.response) {
                return {}
            }
            return this.response.article
        }
    },
    methods: {
        async update () {
            this.$store.commit(types.EDITOR_LOADING,true)
            this.form.article.thumbnail = this.thumbnailImageRaw
            this.form.rich_article.banner_image = this.bannerImageRaw
            this.form.rich_article.top_banner_image_1 = this.topBannerImage1Raw
            this.form.rich_article.top_banner_image_2 = this.topBannerImage2Raw
            const fd = new FormData()
            _.forEach(this.form.article, (value, key) => {
                if (value === null) {
                    this.form.article[key] = ''
                }
            })
            _.forEach(this.form.rich_article, (value, key) => {
                if (value === null) {
                    this.form.rich_article[key] = ''
                }
            })

            fd.append('article[thumbnail]', this.form.article.thumbnail)
            fd.append('article[title]', this.form.article.title)
            fd.append('article[description]', this.form.article.description)
            fd.append('article[memo]', this.form.article.memo)
            fd.append('rich_article[banner_image]', this.form.rich_article.banner_image)
            fd.append('rich_article[top_banner_image_1]', this.form.rich_article.top_banner_image_1)
            fd.append('rich_article[top_banner_image_2]', this.form.rich_article.top_banner_image_2)

            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PATCH'
                }
            }
            const {error} = await axios.post('/api/articles/' + this.article.id + '/rich', fd, config)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.EDITOR_LOADING,false)
                return
            }
            await this.fetch()
            this.$store.commit(types.EDITOR_LOADING,false)
        },
        handleThumbnailImageAdd(file) {
            this.thumbnailImageRaw = file.raw
            this.thumbnailImageUrl = URL.createObjectURL(file.raw)
            this.fetchedImage = true
            this.update()
        },
        handleBannerImageAdd(file) {
            this.bannerImageRaw = file.raw
            this.bannerImageUrl = URL.createObjectURL(file.raw)
            this.fetchedImage = true
            this.update()
        },
        handleTopBannerImage1Add(file) {
            this.topBannerImage1Raw = file.raw
            this.topBannerImage1Url = URL.createObjectURL(file.raw)
            this.fetchedImage = true
            this.update()
        },
        handleTopBannerImage2Add(file) {
            this.topBannerImage2Raw = file.raw
            this.topBannerImage2Url = URL.createObjectURL(file.raw)
            this.fetchedImage = true
            this.update()
        },
    }
}
</script>

<style scoped>
.articleMain {
    padding: 15px;
}
.articleMain .avatar-uploader:hover .imgAspectRatioPopup {
    display: block;
}
.articleMain .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: visible;
}
.avatar-uploader {
    width: 178px;
}
.rich {
    width: auto;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px !important;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}
.rich-avatar {
    width: auto;
    height: 178px;
    display: block;
    object-fit: cover;
}
</style>
