<template>
    <div>
        <el-table class="list" :data="tableData" style="font-size: 12px">
            <el-table-column size="mini" label="ID" width="65" >
                <template slot-scope="scope">
                    <el-popover
                        placement="right"
                        trigger="hover">
                        <img class="thumbnail" alt="thumbnail" :src="scope.row.thumbnail_cdn_url">
                        <span slot="reference">
                        <a :href="scope.row.article_url"
                           v-if="isArticlePublish(scope.row.status)"
                           target="_blank">{{ scope.row.id }}</a>
                        <span v-else>{{ scope.row.id }}</span>
                    </span>
                    </el-popover>
                    <br/>
                    <a class="el-icon-edit" :href="addArticleUrl(scope.row.id)" target="_blank" >編集</a>
                </template>
            </el-table-column>
            <el-table-column size="small" :render-header="renderTableHeader(['執筆ライター名','公開ライター名'])" width="90">
                <template slot-scope="scope">
                    {{ scope.row.writer.screen_name }}
                    <br>
                    {{ scope.row.publish_writer.screen_name }}
                </template>
            </el-table-column>
            <el-table-column size="small" :render-header="renderTableHeader(['タイトル','詳細'])" >
                <template slot-scope="scope">
                    {{ scope.row.title }}
                    <br>
                    <el-button
                        @click="openDetailModal(scope.row)"
                        size="mini"
                    >詳細</el-button>
                </template>
            </el-table-column>
            <el-table-column size="small" :render-header="renderTableHeader(['記事種別','カテゴリ'])" width="90">
                <template slot-scope="scope">
                    {{ articleType(scope.row.article_type_id) }}
                    <br>
                    {{ (scope.row.category) ? scope.row.category.category_name : '-' }}
                </template>
            </el-table-column>
            <el-table-column size="small" :render-header="renderTableHeader(['公開日付','リライト完了日'])" width="135">
                <template slot-scope="scope">
                    {{ (scope.row.published_at) ? scope.row.published_at : '-' }}
                    <br>
                    <template v-if="scope.row.rewrites[0] && scope.row.rewrites[0].completed_at">
                        {{ scope.row.rewrites[0].completed_at }}
                    </template>
                    <template v-else-if="scope.row.rewrite && scope.row.rewrite.updated_at">
                        {{ scope.row.rewrite.updated_at }}
                    </template>
                    <template v-else>
                        -
                    </template>
                </template>
            </el-table-column>
            <el-table-column size="small" label="フォーマット" width="95">
                <template slot-scope="scope">
                    {{ formatLabelList[scope.row.format] }}
                </template>
            </el-table-column>
            <el-table-column size="small" :render-header="renderTableHeader(['no','index'])" width="70">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_noindex" />
                </template>
            </el-table-column>
            <el-table-column size="small" label="ステルス" width="70">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_stealth" />
                </template>
            </el-table-column>
            <el-table-column size="small" label="記事広告" width="70">
                <template slot-scope="scope">
                    <el-checkbox :disabled="scope.row.is_tieup === true" v-model="scope.row.is_tieup" @change="is_select_tieup(scope.row)"/>
                </template>
            </el-table-column>
            <el-table-column size="small" :render-header="renderTableHeader(['no','pager'])" width="70">
                <template slot-scope="scope">
                    <el-checkbox :disabled="scope.row.is_tieup === true" v-model="scope.row.is_nopager" />
                </template>
            </el-table-column>
            <el-table-column size="small" :render-header="renderTableHeader(['広告','非表示'])" width="70">
                <template slot-scope="scope">
                    <el-checkbox :disabled="scope.row.is_tieup === true" v-model="scope.row.is_hide_ad" />
                </template>
            </el-table-column>
            <el-table-column size="small" :render-header="renderTableHeader(['mate','フォーム'])" width="70">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_display_mate_form" />
                </template>
            </el-table-column>
            <el-table-column size="small" :render-header="renderTableHeader(['トレンド','記事'])" width="70">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_trend" />
                </template>
            </el-table-column>
            <el-table-column size="small" :render-header="renderTableHeader(['mate','記事'])" width="70">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_mate" />
                </template>
            </el-table-column>
            <el-table-column size="small" :render-header="renderTableHeader(['ｵﾘｼﾞﾅﾙ','記事'])" width="70">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_original" />
                </template>
            </el-table-column>
            <el-table-column size="small" :render-header="renderTableHeader(['リダイレクト','対象外記事'])" width="90">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_no_redirect" />
                </template>
            </el-table-column>
            <el-table-column size="small" label="操作" width="80">
                <template slot-scope="scope">
                    <el-button
                        class="btnEdit unitBtn"
                        type="warning"
                        size="mini"
                        @click="doUpdate(scope.row)"
                    >更新</el-button>
                </template>
            </el-table-column>
        </el-table>
        <detail-modal
            :is-active="isModalActive"
            :lastUpdateAdminName="lastUpdateAdminName"
            :editorName="editorName"
            :keyWords="keyWords"
            title="記事詳細"
            @close="closeDetailModal"
        />
    </div>
</template>

<script>

import DetailModal from './DetailModal'
export default {
    name: 'EditArticleRow',
    components: {DetailModal},
    props: {
        tableData: Array,
        formatLabelList: Object,
        articleTypes: Object,
        update: Function
    },
    data () {
        return {
            isModalActive: false,
            lastUpdateAdminName: '',
            editorName: '',
            keyWords: [],
        }
    },
    methods: {
        articleType(id) {
            return (id) ? this.articleTypes[id].type_name : '-'
        },
        isArticleAdd(status) {
            return (status === 100) ? true : false
        },
        doUpdate(form) {
            this.update(form.id, form)
        },
        addArticleUrl(articleId) {
            return '/articles/' + articleId
        },
        is_select_tieup(form) {
            if (form.is_tieup === true) {
                if (confirm('記事広告・広告非表示・nopagerの3つがチェックされ管理画面からは戻せなくなりますがよろしいですか？')) {
                    form.is_nopager = true
                    form.is_hide_ad = true
                    this.update(form.id, form)
                } else {
                    form.is_tieup = false
                }
            }
        },
        isArticlePublish(status){
            return status === 500
        },
        openDetailModal(article) {
            if (article.last_modified_user !== undefined && article.last_modified_user) {
                this.lastUpdateAdminName = article.last_modified_user.name
            } else  {
                this.lastUpdateAdminName = '-'
            }
            if (article.direction.admin !== undefined && article.direction.admin) {
                this.editorName = article.direction.admin.name
            } else  {
                this.editorName = '-'
            }
            if (article.direction.plot !== undefined && article.direction.plot) {
                this.keyWords = article.direction.plot.keywords.map( (item) => {return item['keyword']})
            } else  {
                this.keyWords = []
            }
            this.isModalActive = true
        },
        closeDetailModal() {
            this.isModalActive = false
        },
    }
}
</script>

<style>
    .el-table td, th{
        vertical-align: top !important;
    }
</style>

<style scoped>
    .el-button {
        margin: 5px 0 5px 10px;
    }

    .unitBtn {
        width: 100%;
    }

    .list {
        vertical-align: top;
    }

    .el-select {
        margin: 5px 0 5px 10px;
    }

    .thumbnail {
        height: auto;
        width: 185px;
    }
</style>
