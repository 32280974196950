<template>
    <div>
        <el-row type="flex" justify="end">
            <div class="search-form">
                <el-checkbox v-model="searchForm.is_include_admin">管理者を含める</el-checkbox>
                <el-checkbox v-model="searchForm.is_include_writer">ライターを含める</el-checkbox>
                <el-input class="search-email" v-model="searchForm.email" placeholder="メールアドレス(完全一致)" @keyup.enter.native="search()">
                    <el-button
                            @click="search()"
                            slot="append"
                            icon="el-icon-search">
                    </el-button>
                </el-input>
            </div>
        </el-row>
        <el-table
                :data="users"
                style="width: 100%"
                max-height="800"
        >
            <el-table-column label="ユーザーID" prop="id" width="100px"></el-table-column>
            <el-table-column label="アカウント名" width="170px">
                <template slot-scope="scope">
                    <a
                        v-if="scope.row.writer"
                        :href="scope.row.writer.writer_url"
                        target="_blank"
                    >{{scope.row.writer.writer_name}}</a>
                </template>
            </el-table-column>
            <el-table-column label="ライター名" prop="writer.screen_name" width="170px"></el-table-column>
            <el-table-column label="メールアドレス" prop="email"></el-table-column>
            <el-table-column label="参加日時" width="170px" prop="created_at"></el-table-column>
            <el-table-column align="right" width="220px">
                <template slot-scope="scope">
                    <el-button
                            v-if="!scope.row.is_valid_writer"
                            size="mini"
                            @click="openWriterStoreModal(scope.row)"
                            style="margin-bottom: 5px;"
                    >ライター招待</el-button>
                    <el-button
                            v-else
                            size="mini"
                            disabled
                    >登録済み</el-button>
                    <el-button
                            type="danger"
                            size="mini"
                            @click="openWithdrawConfirmModal(scope.row.id)"
                    >強制退会</el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="search" :pagination="pagination" />

        <writer-store-modal
            :user="registerUser"
            :writer-teams="writerTeams"
            :editors="editors"
            :is-active="isActive.writerStoreModal"
            @createWriter="createWriter"
            @close="closeWriterStoreModal"
        ></writer-store-modal>

        <withdraw-confirm-modal
            :is-active="isActive.withdrawConfirmModal"
            @withdraw="withdraw"
            @close="closeWithdrawConfirmModal"
        ></withdraw-confirm-modal>
    </div>
</template>

<script>
import { PAGE_TITLE , LOADING } from '../../store/mutation-types'
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import WriterStoreModal from './User/WriterStoreModal'
import WithdrawConfirmModal from './User/WithdrawConfirmModal'

export default {
    components: { Pagination, WriterStoreModal, WithdrawConfirmModal },
    data() {
        return {
            users: [],
            writerTeams: {},
            editors: {},
            pagination: {},
            writerCreateForm: {
                'writer_name': '',
                'screen_name': '',
                'writer_team_id': null,
            },
            isActive: {
                writerStoreModal: false,
                withdrawConfirmModal: false,
            },
            registerUser: {},
            searchForm: {
                email: '',
                is_include_admin: false,
                is_include_writer: false,
            },
            withdrawUserId: '',
        }
    },
    beforeRouteUpdate(to, from, next) {
        next()
        this.fetch()
    },
    created() {
        this.$store.commit(PAGE_TITLE, 'アカウント　>　ユーザー管理')
        var pair = location.search.substring(1).split('&')
        for(var i=0;pair[i];i++) {
            var kv = pair[i].split('=')
            if (kv[0] == 'email') {
                this.searchForm.email = decodeURIComponent(kv[1])
            } else if (kv[0] == 'is_include_admin') {
                this.searchForm.is_include_admin = kv[1] == 1
            } else if (kv[0] == 'is_include_writer') {
                this.searchForm.is_include_writer = kv[1] == 1
            }
        }
        this.fetch()
    },
    methods: {
        // 取得
        async fetch() {
            this.$store.commit(LOADING, true)

            const { data, error } = await axios.get('/api/users', {
                params: { ...this.$route.query }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            this.users = data.users.data
            this.pagination = Object.assign({}, data.users)
            Object.keys(data.writer_teams).forEach((key) => {
                this.writerTeams[data.writer_teams[key].id] = data.writer_teams[key].name
            })
            Object.keys(data.editors).forEach((key) => {
                this.editors[data.editors[key].id] = data.editors[key].editor_name
            })
            this.$store.commit(LOADING, false)
        },
        // 退会
        async withdraw() {
            this.$store.commit(LOADING, true)
            const { error } = await axios.delete('/api/users/' + this.withdrawUserId)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '退会に失敗しました。')
                this.$store.commit(LOADING, false)
                return
            }
            this.closeWithdrawConfirmModal()
            this.$store.commit(LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'ユーザー退会が完了しました。')

            this.fetch()
        },
        // ライター作成
        async createWriter(params) {
            this.$store.commit(LOADING, true)
            const { error } = await axios.post('/api/writers', params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }
            this.$store.commit(LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'ライター作成が完了しました。')
            this.closeWriterStoreModal()

            this.fetch()
        },
        // 検索
        search (page = 1) {
            const query = Object.assign({}, this.$route.query, {
                page,
                email: this.searchForm.email,
                is_include_admin: this.searchForm.is_include_admin ? 1 : 0,
                is_include_writer: this.searchForm.is_include_writer ? 1 : 0,
            })
            this.$router.push({ query })
        },
        // ライター作成モーダルopen
        openWriterStoreModal(user) {
            this.registerUser = user
            this.isActive.writerStoreModal = true
        },
        // ライター作成モーダルclose
        closeWriterStoreModal() {
            this.isActive.writerStoreModal = false
        },
        // 退会確認モーダルopen
        openWithdrawConfirmModal(userId) {
            this.withdrawUserId = userId
            this.isActive.withdrawConfirmModal = true
        },
        // 退会確認モーダルclose
        closeWithdrawConfirmModal() {
            this.withdrawUserId = ''
            this.isActive.withdrawConfirmModal = false
        },
    }
}
</script>
<style scoped>
    .search-email{
        width: 300px;
    }
</style>

