<!-- プレイスアイテムの作成・編集フォーム -->
<template>
    <div class="placeType">
        <section>
            <h2 class="heading">基本情報</h2>
            <el-button type="info" size="mini" icon="el-icon-search" @click="placeSearchModal.isOpen = true">GoogleMapsから検索する</el-button>
            <el-dialog
                title="GoogleMapsから検索する"
                width="1000px"
                :visible.sync="placeSearchModal.isOpen"
            >
                <place-search
                    @save="(searchResult) => {
                        handlePlaceSearchSave(searchResult)
                        placeSearchModal.isOpen = false
                    }"
                />
            </el-dialog>
            <el-form label-width="125px">
                <el-form-item label="店舗名">
                    <el-input
                        v-model="form.place_name"
                        type="text"
                        size="mini"
                        placeholder="トラストリッジ 代官山店"
                    ></el-input>
                </el-form-item>
                <el-form-item label="GoogleMapsURL">
                    <el-input
                        v-model="form.google_map_url"
                        type="text"
                        size="mini"
                        placeholder="https://maps.app.goo.gl/osQKKAmcy3djvXRA9"
                    ></el-input>
                </el-form-item>
                <el-form-item label="電話番号">
                    <el-input
                        v-model="form.phone_number"
                        class="shortInput"
                        type="text"
                        size="mini"
                        placeholder="000-0000-0000"
                    ></el-input>
                </el-form-item>
                <el-form-item label="郵便番号">
                    <div class="zipCode">
                        <el-input
                            v-model="form.zip_code"
                            class="shortInput"
                            type="text"
                            size="mini"
                            placeholder="000-0000"
                        ></el-input>
                        <el-button type="info" size="mini" @click="zipCodeToAddress">住所検索</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="住所">
                    <el-input
                        v-model="form.address"
                        type="text"
                        size="mini"
                        placeholder="東京都目黒区中目黒1-1-71 KN代官山"
                    ></el-input>
                </el-form-item>
                <el-form-item label="営業時間">
                    <div class="placeItem_hasOpeningHour">
                        <el-switch v-model="hasOpeningHour" @change="changeHasOpeningHour" />
                    </div>
                </el-form-item>
                <el-form-item v-if="hasOpeningHour">
                    <!-- 営業時間の一括設定モーダル -->
                    <el-button type="info" size="mini" @click="openingHoursModal.isOpen = true">一括設定を開く</el-button>
                    <el-dialog
                        title="営業時間の一括設定"
                        :visible.sync="openingHoursModal.isOpen"
                        @close="() => openingHoursModal.hours = defaultTimeRange"
                    >
                        <span>全ての曜日に一括入力できます。既存の入力内容はリセットされます。</span>
                        <time-range-list
                            v-model="openingHoursModal.hours"
                            start-placeholder="営業開始"
                            end-placeholder="営業終了"
                            twenty-four-label="24時間営業"
                        ></time-range-list>
                        <span slot="footer">
                            <el-button
                                type="primary"
                                @click="
                                    bulkSetOpeningHours(openingHoursModal.hours)
                                    openingHoursModal.isOpen = false
                                "
                            >設定する</el-button>
                            <el-button @click="openingHoursModal.isOpen = false">キャンセル</el-button>
                        </span>
                    </el-dialog>
                    <!-- 営業時間の個別設定 -->
                    <el-form-item
                        v-for="(label, day) in {mon: '月曜', tue: '火曜', wed: '水曜', thu: '木曜', fri: '金曜', sat: '土曜', sun: '日曜'}"
                        :key="day"
                        :label="label"
                        label-width="50px"
                    >
                        <time-range-list
                            v-model="form.opening_hours[day]"
                            start-placeholder="営業開始"
                            end-placeholder="営業終了"
                            twenty-four-label="24時間営業"
                        ></time-range-list>
                    </el-form-item>
                </el-form-item>
                <el-form-item class="placeItem_flag" label="フラグ">
                    <div class="placeItem_flagList">
                        <el-checkbox v-model="form.flags.is_no_smoking" class="flagCheck" label="禁煙" border size="mini"></el-checkbox>
                        <el-checkbox v-model="form.flags.is_wifi" class="flagCheck" label="Wi-Fi" border size="mini"></el-checkbox>
                        <el-checkbox v-model="form.flags.is_power_supply" class="flagCheck" label="電源" border size="mini"></el-checkbox>
                    </div>
                    <div class="placeItem_flagList">
                        <el-checkbox v-model="form.flags.is_serves_breakfast" class="flagCheck" label="朝食" border size="mini"></el-checkbox>
                        <el-checkbox v-model="form.flags.is_serves_lunch" class="flagCheck" label="昼食" border size="mini"></el-checkbox>
                        <el-checkbox v-model="form.flags.is_serves_dinner" class="flagCheck" label="ディナー" border size="mini"></el-checkbox>
                    </div>
                </el-form-item>
            </el-form>
        </section>

        <section>
            <h2 class="heading">関連URL</h2>
            <el-form label-width="100px">
                <el-form-item label="公式HP">
                    <el-input
                        v-model="form.urls.place_url"
                        type="text"
                        size="mini"
                        placeholder="https://macaro-ni.jp"
                    ></el-input>
                </el-form-item>
            </el-form>
            <el-form label-width="100px">
                <url-list
                    v-model="form.urls.other"
                    :only-site-types="['FACEBOOK', 'INSTAGRAM', 'X', 'YOUTUBE', 'TIKTOK', 'OTHER']"
                    :is-from-shop-item="isFromShopItem"
                ></url-list>
            </el-form>
        </section>

        <section>
            <h2 class="heading">アフィリエイト情報</h2>
            <div class="affiliateSearch">
                <el-button type="info" size="mini" icon="el-icon-search" @click="affiliateSearchModal.isOpen = true">探して追加する</el-button>
                <el-dialog
                    title="アフィリエイトを探して追加する"
                    width="1000px"
                    :visible.sync="affiliateSearchModal.isOpen"
                >
                    <affiliate-place-search
                        :article-id="articleId"
                        @add="(media, url) => {
                            addAffiliateUrl(media, url)
                            affiliateSearchModal.isOpen = false
                        }"
                    />
                </el-dialog>
            </div>
            <el-form label-width="100px">
                <el-form-item label="ぐるなびURL">
                    <el-input
                        v-model="form.affiliate.gnavi_affiliate_url"
                        type="text"
                        size="mini"
                        placeholder="https://r.gnavi.co.jp/"
                    ></el-input>
                </el-form-item>
                <el-form-item label="食べログURL">
                    <el-input
                        v-model="form.affiliate.tabelog_affiliate_url"
                        type="text"
                        size="mini"
                        placeholder="https://tabelog.com/"
                    ></el-input>
                </el-form-item>
                <el-form-item label="一休URL">
                    <el-input
                        v-model="form.affiliate.ikyu_affiliate_url"
                        type="text"
                        size="mini"
                        placeholder="https://www.ikyu.com/"
                    ></el-input>
                </el-form-item>
            </el-form>
        </section>

        <section>
            <h2 class="heading">追加情報</h2>
            <el-form label-width="100px">
                <sortable-input-list v-model="form.elements"></sortable-input-list>
                <el-form-item label="追加情報">
                    <el-select v-model="elementCustom.selectedLabel" size="mini" placeholder="選択してください">
                        <el-option
                            v-for="label in ELEMENT_LABELS"
                            :key="label"
                            :label="label"
                            :value="label"
                        ></el-option>
                    </el-select>
                    <el-input
                        v-if="elementCustom.selectedLabel === CUSTOM_ELEMENT_LABEL"
                        v-model="elementCustom.customLabel"
                        class="customLabelInput"
                        clearable
                        type="text"
                        size="mini"
                        placeholder="新しいラベルを入力してください"
                    ></el-input>
                    <el-button
                        type="info"
                        size="mini"
                        icon="el-icon-plus"
                        :disabled="elementCustom.selectedLabel === CUSTOM_ELEMENT_LABEL
                            ? elementCustom.customLabel.length === 0
                            : elementCustom.selectedLabel.length === 0"
                        @click="form.elements.push({
                            label: (elementCustom.selectedLabel === CUSTOM_ELEMENT_LABEL
                                ? elementCustom.customLabel : elementCustom.selectedLabel),
                            value: ''
                        })"
                    />
                </el-form-item>
            </el-form>
        </section>

        <div class="footerButtons">
            <el-button type="primary" size="mini" @click="handleSave">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </div>
    </div>
</template>

<script>
import AffiliatePlaceSearch from './Components/AffiliatePlaceSearch/AffiliatePlaceSearch'
import PlaceSearch from './Components/PlaceSearch/PlaceSearch'
import SortableInputList from './Components/SortableInputList'
import TimeRangeList from './Components/TimeRangeList.vue'
import UrlList from './Components/UrlList'
import { MEDIA_LIST } from './Components/AffiliatePlaceSearch/AffiliatePlaceSearch'
import * as types from '../../../../../store/mutation-types'

const RESOURCE_NAME = 'places'

const CUSTOM_ELEMENT_LABEL = '自分で入力する'
const ELEMENT_LABELS = [
    '最寄駅',
    '支払方法',
    '平均予算',
    '駐車場',
    CUSTOM_ELEMENT_LABEL,
]

const defaultTimeRange = () => ({is_twenty_four: false, times: [['', '']]})
const defaultOpeningHours = () => ({
    mon: defaultTimeRange(),
    tue: defaultTimeRange(),
    wed: defaultTimeRange(),
    thu: defaultTimeRange(),
    fri: defaultTimeRange(),
    sat: defaultTimeRange(),
    sun: defaultTimeRange(),
})
const defaultFormValues = () => ({
    place_name: '',
    google_map_url: '',
    phone_number: '',
    zip_code: '',
    address: '',
    opening_hours: defaultOpeningHours(),
    flags: {
        is_no_smoking: false,
        is_wifi: false,
        is_power_supply: false,
        is_serves_breakfast: false,
        is_serves_lunch: false,
        is_serves_dinner: false,
    },
    urls: {
        place_url: '',
        other: [], // [{url: '', type: ''}]
    },
    affiliate: {
        gnavi_affiliate_url: '',
        tabelog_affiliate_url: '',
        ikyu_affiliate_url: '',
    },
    elements: [], // [{label: 'ラベル名', value: '入力値'}]
})

export default {
    name:'ItemFormPlaceType',
    components: {
        AffiliatePlaceSearch,
        PlaceSearch,
        SortableInputList,
        TimeRangeList,
        UrlList,
    },
    props: {
        save: {
            required: true,
            type: Function,
        },
        articleId: {
            required: true,
            type: Number,
        },
        content: {
            required: false,
            type: Object,
            default: null,
        },
        isFromShopItem: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            form: defaultFormValues(),
            elementCustom: {
                selectedLabel: '',
                customLabel: '',
            },
            openingHoursModal: {
                isOpen: false,
                hours: defaultTimeRange(),
            },
            placeSearchModal: {
                isOpen: false,
            },
            affiliateSearchModal: {
                isOpen: false,
            },
            hasOpeningHour: true,
        }
    },
    computed: {
        ELEMENT_LABELS() {
            return ELEMENT_LABELS
        },
        CUSTOM_ELEMENT_LABEL() {
            return CUSTOM_ELEMENT_LABEL
        },
        defaultTimeRange() {
            return defaultTimeRange()
        },
    },
    watch: {
        // 編集モード開始時の初期化 サーバレスポンスからthis.formへのセット
        content: {
            handler(content) {
                if (content === null) {
                    this.form = defaultFormValues()
                    return
                }
                this.form = {
                    place_name: content.place_name,
                    google_map_url: content.google_map_url,
                    phone_number: content.phone_number,
                    zip_code: content.zip_code,
                    address: content.address,
                    opening_hours: content.opening_hours.length ? Object.fromEntries(
                        content.opening_hours.map(day => [
                            ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][day.day_of_week],
                            {
                                is_twenty_four: day.is_open_twenty_four_hours,
                                times: day.opening_hours !== null
                                    ? day.opening_hours.map(hours => [hours.opening_hour, hours.closing_hour])
                                    : [['', '']],
                            }
                        ])
                    ) : null,
                    flags: {
                        is_no_smoking: content.is_no_smoking,
                        is_wifi: content.is_wifi,
                        is_power_supply: content.is_power_supply,
                        is_serves_breakfast: content.is_serves_breakfast,
                        is_serves_lunch: content.is_serves_lunch,
                        is_serves_dinner: content.is_serves_dinner,
                    },
                    urls: {
                        place_url: content.place_url,
                        other: content.urls.map(url => ({url: url.url, type: url.type}))
                    },
                    affiliate: {
                        gnavi_affiliate_url: content.gnavi_affiliate_url,
                        tabelog_affiliate_url: content.tabelog_affiliate_url,
                        ikyu_affiliate_url: content.ikyu_affiliate_url,
                    },
                    elements: content.elements.map(element => ({label: element.label, value: element.value})),
                }
                this.setHasOpeningHour()
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        /**
         * すべての曜日に指定した時間を設定する
         * @param {Object} hours
         * @param {Array<Array<string>>} hours.times
         * @param {boolean} hours.is_twenty_four
         */
        bulkSetOpeningHours(hours) {
            this.form.opening_hours = Object.fromEntries(
                Object.keys(this.form.opening_hours).map(day => [day, structuredClone(hours)])
            )
        },
        /**
         * 全ての営業時間が設定されていないかつ24時間営業フラグがoffの場合は、営業時間を無しにする
         */
        setNullIfOpeningHoursIsEmpty() {
            const isEmpty = Object.entries(this.form.opening_hours).every(([, day]) => {
                return day.is_twenty_four === false && (!day.times[0][0] || !day.times[0][1])
            })
            if (isEmpty) {
                this.form.opening_hours = null
            }
        },
        /**
         * 営業時間スイッチトグル変更時の処理
         * @param {boolean} value
         */
        changeHasOpeningHour(value) {
            this.form.opening_hours = value ? defaultOpeningHours() : null
        },
        /**
         * 営業時間の有無でスイッチトグルを変更する
         */
        setHasOpeningHour() {
            this.hasOpeningHour = this.form.opening_hours !== null
        },
        /**
         * 郵便番号から住所を自動入力する
         */
        async zipCodeToAddress() {
            if (this.form.zip_code.trim().length === 0) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '郵便番号を入力してください。')
                return
            }

            this.$store.commit(types.EDITOR_LOADING, true)
            const { data, error } = await axios.get(`/api/addresses/zip_search/${this.form.zip_code}`)
            this.$store.commit(types.EDITOR_LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.EDITOR_LOADING, false)
                return
            }
            if (data.pref === null && data.city === null && data.town === null) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '該当する住所が見つかりませんでした。')
                return
            }

            this.form.address = `${data.pref ?? ''}${data.city ?? ''}${data.town ?? ''}`
        },
        /**
         * 探して追加するモーダルからアフィURLを追加する
         */
        addAffiliateUrl(media, url) {
            if (media === MEDIA_LIST().gnavi) {
                this.form.affiliate.gnavi_affiliate_url = url
            } else if (media === MEDIA_LIST().ikyu) {
                this.form.affiliate.ikyu_affiliate_url = url
            }
        },
        /**
         * GoogleMaps/PlacesAPI検索モーダルの結果からフォームに自動入力する
         */
        handlePlaceSearchSave(placeApiResult) {
            this.form = {
                place_name: placeApiResult.displayName,
                google_map_url: placeApiResult.googleMapsUri ?? this.form.google_map_url,
                phone_number: placeApiResult.nationalPhoneNumber ?? this.form.phone_number,
                zip_code: placeApiResult.zipCode ?? this.form.zip_code,
                address: placeApiResult.address ?? this.form.address,
                opening_hours: structuredClone(placeApiResult.regularOpeningHours),
                flags: {
                    is_no_smoking: this.form.flags.is_no_smoking,
                    is_wifi: this.form.flags.is_wifi,
                    is_power_supply: this.form.flags.is_power_supply,
                    is_serves_breakfast: placeApiResult.isServesBreakfast ?? this.form.flags.is_serves_breakfast,
                    is_serves_lunch: placeApiResult.isServesLunch ?? this.form.flags.is_serves_lunch,
                    is_serves_dinner: placeApiResult.isServesDinner ?? this.form.flags.is_serves_dinner,
                },
                urls: {
                    place_url: placeApiResult.websiteUri ?? this.form.urls.place_url,
                    other: structuredClone(this.form.urls.other),
                },
                affiliate: {
                    gnavi_affiliate_url: this.form.affiliate.gnavi_affiliate_url,
                    tabelog_affiliate_url: this.form.affiliate.tabelog_affiliate_url,
                    ikyu_affiliate_url: this.form.affiliate.ikyu_affiliate_url,
                },
                elements: structuredClone(this.form.elements),
            }
            if (this.form.opening_hours !== null) {
                this.setNullIfOpeningHoursIsEmpty()
            }
            this.setHasOpeningHour()
        },
        /**
         * アイテムの作成
         */
        handleSave() {
            this.save(RESOURCE_NAME, this.form, this.content?.id)
        },
    },
}
</script>

<style scoped>
.placeType {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.heading {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 10px 0;
}

.shortInput {
    width: 150px;
}
.zipCode {
    display: flex;
    gap: 7px;
}
.placeItem_hasOpeningHour {
    margin-top: 3px;
}
.placeItem_flag >>> .el-form-item__content {
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.placeItem_flagList {
    display: flex;
    gap: 7px;
}
.flagCheck.el-checkbox {
    width: 100px;
}

.customLabelInput {
    width: 215px;
}

.affiliateSearch {
    display: flex;
    justify-content: flex-end;
}

.footerButtons {
    display: flex;
    gap: 10px;
    margin: 10px 0 0 95px;
}

.el-form-item >>> .el-form-item__content {
    line-height: 20px;
    margin: 6px 0 0 50px;
}


/* ElementUIのスタイルリセット */
.el-checkbox {
    margin-right: 0;
}
.el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 0;
}
.el-button+.el-button {
    margin-left: 0;
}
</style>
