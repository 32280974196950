<template>
    <div>
        <div class="gmapForm">
            <el-row>
                <div ref="googleMapElement" class="gmap"></div>
            </el-row>
            <el-row>
                <el-input
                    type="text"
                    size="mini"
                    placeholder="住所、キーワードを入力してください"
                    v-model="mapInfo.searchKeyword"
                    @keydown.native.enter="(keyboardEvent) => {
                        if (keyboardEvent.isComposing) { return }
                        searchByAddress()
                    }"
                />
                <el-button
                    class="addressBtn"
                    type="success"
                    size="mini"
                    @click="searchByAddress"
                >検索する</el-button>
                <el-button
                    class="addressBtn"
                    :disabled="disableSave"
                    type="primary"
                    size="mini"
                    @click="positionSave"
                >この場所を使用する</el-button>
            </el-row>
        </div>

        <div ref="mapInfoElement">
            <p>{{ mapInfo.formattedAddress }}</p>
        </div>
    </div>
</template>

<script>
import * as types from '../../../../../../store/mutation-types'

// https://developers.google.com/maps/documentation/javascript/reference/coordinates?hl=ja#LatLng
const defaultLatLng = new window.google.maps.LatLng(35.689734, 139.691725) // 都庁

export default {
    data () {
        return {
            gMapsApi: {
                // https://developers.google.com/maps/documentation/javascript/reference/map?hl=ja#Map
                map: null,
                // https://developers.google.com/maps/documentation/javascript/reference/geocoder?hl=ja#Geocoder
                geocoder: null,
                // 2024/2/21で非推奨になっているが、移行先の"AdvancedMarkerElement"がまだベータ版となっている
                // https://developers.google.com/maps/documentation/javascript/reference/marker?hl=ja#Marker
                maker: null,
            },
            mapInfo: {
                searchKeyword: '',
                formattedAddress: '',
                lat: 0,
                lng: 0,
                placeId: '',
            }
        }
    },
    computed:{
        disableSave() {
            return this.mapInfo.formattedAddress === '' || this.mapInfo.searchKeyword === ''
        }
    },
    mounted() {
        this.$nextTick(function () {
            // ビュー全体がレンダリングされた後にのみ実行されるコード（domを指定するため）
            this.initMap()
        })
    },
    methods: {
        // マップの初期化
        initMap() {
            this.gMapsApi.geocoder = new window.google.maps.Geocoder()
            this.gMapsApi.map = new window.google.maps.Map(this.$refs.googleMapElement, {
                zoom: 17,
                center: defaultLatLng,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            })
        },
        // 住所から検索
        searchByAddress() {
            const vue = this

            vue.gMapsApi.geocoder.geocode({'address': vue.mapInfo.searchKeyword}, function(results, status) {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    window.google.maps.event.trigger(vue.gMapsApi.map, 'resize')
                    vue.gMapsApi.map.setCenter(results[0].geometry.location)
                    // 前に検索した時のmarkerは削除
                    if (vue.gMapsApi.maker !== null) {
                        vue.gMapsApi.maker.setMap(null)
                    }

                    // 結果から情報取得
                    vue.mapInfo.formattedAddress = results[0].formatted_address
                    vue.mapInfo.lat = results[0].geometry.location.lat()
                    vue.mapInfo.lng = results[0].geometry.location.lng()
                    vue.mapInfo.placeId = results[0].place_id
                    const infoWindowDom = vue.$refs.mapInfoElement
                    const infoWindow = new window.google.maps.InfoWindow({
                        content: infoWindowDom
                    })

                    // 新しいマーカーを生成
                    vue.gMapsApi.maker = new window.google.maps.Marker({
                        map: vue.gMapsApi.map,
                        animation: window.google.maps.Animation.DROP,
                        position: results[0].geometry.location,
                        draggable: true
                    })

                    infoWindow.open(vue.gMapsApi.map, vue.gMapsApi.maker)

                    vue.$emit('searched', {...vue.mapInfo})
                } else {
                    vue.$store.commit(types.SHOW_ERROR_MESSAGE, '検索ワード「' + vue.mapInfo.searchKeyword + '」に一致する場所は見つかりませんでした。キーワードが正しく入力されているかを確認してください。')
                    window.google.maps.event.trigger(vue.gMapsApi.map, 'resize')
                    vue.gMapsApi.map.setCenter(defaultLatLng)
                    vue.gMapsApi.maker = new window.google.maps.Marker({
                        map: vue.gMapsApi.map,
                        position: defaultLatLng,
                        draggable: true
                    })
                    vue.mapInfo.formattedAddress = ''
                    vue.mapInfo.lat = 0
                    vue.mapInfo.lng = 0
                    vue.mapInfo.placeId = ''
                }

                // マーカーのドラッグ&ドロップイベント
                window.google.maps.event.addListener(vue.gMapsApi.maker, 'dragend', function(e) {
                    vue.searchByLatAndLng(e.latLng.lat(), e.latLng.lng())
                })
            })
        },
        // 緯度軽度から検索
        searchByLatAndLng(lat, lng) {
            const vue = this
            const latLng = new window.google.maps.LatLng(lat, lng)
            vue.gMapsApi.geocoder.geocode({'latLng': latLng}, function(results, status) {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    if (results[1]) {
                        window.google.maps.event.trigger(vue.gMapsApi.map, 'resize')
                        // 前に検索した時のmarkerは削除
                        if (vue.gMapsApi.maker !== null) {
                            vue.gMapsApi.maker.setMap(null)
                        }

                        // 結果から情報取得
                        vue.mapInfo.formattedAddress = results[0].formatted_address
                        vue.mapInfo.lat = results[0].geometry.location.lat()
                        vue.mapInfo.lng = results[0].geometry.location.lng()
                        vue.mapInfo.placeId = results[0].place_id
                        const infoWindowDom = vue.$refs.mapInfoElement
                        const infoWindow = new window.google.maps.InfoWindow({
                            content: infoWindowDom
                        })

                        // 新しいマーカーを生成
                        vue.gMapsApi.maker = new window.google.maps.Marker({
                            map: vue.gMapsApi.map,
                            animation: window.google.maps.Animation.DROP,
                            position: results[0].geometry.location,
                            draggable: true
                        })

                        infoWindow.open(vue.gMapsApi.map, vue.gMapsApi.maker)

                        vue.$emit('searched', {...vue.mapInfo})
                    }
                }

                // マーカーのドラッグ&ドロップイベント
                window.google.maps.event.addListener(vue.gMapsApi.maker, 'dragend', function(e) {
                    vue.searchByLatAndLng(e.latLng.lat(), e.latLng.lng())
                })
            })
        },
        positionSave () {
            this.$emit('save', {...this.mapInfo})
        },
    }
}
</script>

<style scoped>
    .gmapForm {
        text-align:center;
    }
    .gmap {
        height: 400px;
        width: 400px;
        margin: 0 auto 20px;
        padding-bottom: 30px;
    }
    .addressBtn{
        margin-top: 20px;
    }
</style>
