<template>
    <div>
        <el-upload class="uploadButton"
                   accept=".csv"
                   :headers="header"
                   :before-upload="importCsvStart"
                   :on-error="importCsvError"
                   :on-success="importCsvSuccess"
                   :show-file-list="false"
                   action="/api/app/affinity_article/csv"
                   name="upload_affinity_article"
        >
            <el-button type="warning" icon="el-icon-upload2">
                CSVアップロード
            </el-button>
        </el-upload>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <el-table
            :data="tableData"
            style="width: 100%"
            max-height="800"
        >
            <el-table-column label="対象記事ID">
                <template slot-scope="scope">
                    <div>{{ scope.row.source_article_id }}</div>
                </template>

            </el-table-column>
            <el-table-column label="相性度ペア記事ID">
                <template slot-scope="scope">
                    <div>{{ scope.row.target_article_id }}</div>
                </template>
            </el-table-column>
            <el-table-column label="相性度ランク">
                <template slot-scope="scope">
                    <div>{{ scope.row.affinity_rank }}</div>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
    </div>
</template>

<script>
import * as types from '../../../store/mutation-types'
import Pagination from '../../../components/Pagination'
export default {
    components: {
        Pagination,
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data() {
        return {
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            tableData  : [],
            pagination : {},
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'アプリ　>　関連記事データ作成')
        this.fetch()
    },
    methods: {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data, error } = await axios.get('/api/app/affinity_article')

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            this.pagination = Object.assign({}, data)
            this.tableData = data.data

            this.$store.commit(types.PAGE_LOADING, false)
        },

        importCsvStart() {
            this.$store.commit(types.LOADING, true)
        },
        importCsvError(e) {
            let errorMsg = 'CSVアップロードに失敗しました'
            if (e.status === 422){
                let errorJson = JSON.parse(e.message)
                errorMsg = typeof errorJson.error !== 'undefined' ? errorJson.error : errorMsg
            } else if (e.status === 403) {
                errorMsg = 'この操作は許可されていません。'
            }
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_ERROR_MESSAGE, errorMsg)
        },
        async importCsvSuccess(event) {
            if (event.status === 400) {
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_ERROR_MESSAGE, event.error)
            } else {
                await this.fetch()
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'CSVデータをアップロードしました')
            }
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
    }
}
</script>
<style scoped>
    .uploadButton{
        display:inline-block;
    }
</style>
