<template>
    <div>
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent label-width="120px" >
            <el-form-item label="テキスト本文" >
                <wysiwyg-editor v-model="form.text_body"></wysiwyg-editor>
                <text-length-count :targetText="form.text_body" :maxTextLength="2000"></text-length-count>
            </el-form-item>
            <el-row>
                <el-button
                    style="margin-left: 120px;"
                    type="text"
                    size="mini"
                    @click="() => this.mapDialog = true">位置情報を追加</el-button>
            </el-row>
        </el-form>
        <p v-if="showAddressLabel" class="addressLabel"><a style="color: #409eff;" :href="form.geo_url" target="_blank"><i class="el-icon-location"></i>{{addressLabel}}</a></p>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
        <el-dialog
            title="位置情報入力"
            @close="() => this.mapDialog = false"
            :visible="mapDialog"
            height="50%"
            append-to-body
            >
            <map-form
                @save="(mapInfo) => {
                    this.saveAddress(mapInfo)
                    this.mapDialog = false
                }"
            />
        </el-dialog>
    </div>
</template>

<script>
import WysiwygEditor from './Components/WysiwygEditor'
import MapForm from './Components/Map'
import TextLengthCount from './Components/TextLengthCount'

const defaultFormValues = {
    text_body:'',
    font_color:'black',
    font_type:'medium',
    geo_location:null,
    geo_title:null,
    geo_url:null,
}

export default {
    components: {
        WysiwygEditor,
        MapForm,
        TextLengthCount,
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
    },
    computed: {
        addressLabel() {
            return this.form.geo_title + ', ' + this.form.geo_location
        },
        showAddressLabel() {
            return Boolean(this.form.geo_title)
        },
        resourceName() {
            return 'text'
        },
    },
    data () {
        return {
            mapDialog : false,
            form : {...defaultFormValues},
        }
    },

    methods: {
        saveAddress({searchKeyword, formattedAddress}) {
            this.form.geo_title = searchKeyword
            this.form.geo_location = formattedAddress
        },
        submitForm () {
            // 全体にStyleを適用させるのがエディタ上では厳しそうなので
            // リプレースではテキスト全体にかけるStyleはテキストを全選択してStyleを適用させた状態と同じにする為(アクセサーでやってる)、更新時既存のデータはデフォルトに戻す
            // よっていつか全てのテキストアイテムのfont_type, font_colorがデフォルトになったらそれらのカラムは不要になる
            this.form.font_type  = 'medium'
            this.form.font_color = 'black'
            this.save(this.resourceName, this.form, this.id)
        },
        openDialog(input) {
            this.form = Object.assign({}, defaultFormValues, _.pick(input, Object.keys(this.form)))
        },
    },
}

</script>

<style>
    .el-form-item__content li {
        display: list-item !important;
        list-style: disc !important;
        margin-left:20px;
    }
    .gmap {
        height:500px;
        width:500px;
    }
    .ProseMirror {
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        line-height: 30px;
        padding: 5px 5px 5px 5px;
    }
    .ProseMirror > p > a:link {
        color: #444;
        text-decoration: underline;
    }
    .ProseMirror > p > a:visited {
        color: #444;
        text-decoration: underline;
    }
    .ProseMirror > p > a:hover {
        color: #888;
        text-decoration: underline;
    }
    .ProseMirror > p > a:active {
        color: #888;
        text-decoration: none;
    }
</style>
<style scoped lang="scss">
    .addressLabel {
        margin-left:120px;
    }
</style>
