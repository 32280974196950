<template>
    <div class="managementMaterial">
        <div class="searchRow">
            <el-input
                class="searchInput"
                placeholder="材料名"
                v-model="searchForm.name"
                @keydown.enter.native="$event.isComposing || search(1)"
            ></el-input>
            <el-input
                class="searchInput"
                placeholder="読み方"
                v-model="searchForm.hiraganaName"
                @keydown.enter.native="$event.isComposing || search(1)"
            ></el-input>
            <el-input
                class="searchInput"
                placeholder="NG"
                v-model="searchForm.ng"
                @keydown.enter.native="$event.isComposing || search(1)"
            ></el-input>
            <el-input
                class="searchInput"
                placeholder="タグ名"
                v-model="searchForm.tagName"
                @keydown.enter.native="$event.isComposing || search(1)"
            ></el-input>
            <single-tag-relation-form
                v-model="searchForm.endTagPathId"
                tag-class="MATERIAL"
                :tag-paths-key-by-end-tag-id="tagPathsByEndTagId"
                :default-parent-tag-paths="defaultParentTags"
            ></single-tag-relation-form>
            <el-select v-model="searchForm.isProductName" class="searchProductSelect">
                <el-option
                    v-for="item in [{label: '商品名フラグ:条件なし', value: null}, {label: '商品名フラグ:ON', value: true}, {label: '商品名フラグ:OFF', value: false}]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
            </el-select>
            <el-button @click="search(1)" icon="el-icon-search" class="searchSubmitButton">検索</el-button>
        </div>
        <pagination :handleCurrentChange="search" :pagination="pagination"/>
        <management-material-row
            :is-wr="false"
            :materials="materials"
            :material-units="materialUnits"
            :tag-paths-by-end-tag-id="tagPathsByEndTagId"
            :default-parent-tags="defaultParentTags"
            @fetch="fetch"
        ></management-material-row>
        <pagination :handleCurrentChange="search" :pagination="pagination"/>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import ManagementMaterialRow from '../../components/Management/ManagementMaterialRow'
import SingleTagRelationForm from '../../components/Tag/SingleTagRelationForm'

export default {
    components: {
        Pagination,
        ManagementMaterialRow,
        SingleTagRelationForm,
    },
    data() {
        return {
            searchForm: {
                endTagPathId: null,
                tagName: '',
                isProductName: false,
                name: '',
                hiraganaName: '',
                ng: '',
            },
            pagination: {},
            materials: [],
            materialUnits: [],
            tagPathsByEndTagId: {},
            defaultParentTags: [],
        }
    },
    async created() {
        this.$store.commit(types.PAGE_TITLE, '記事作成データ管理　>　材料')
        this.searchForm.endTagPathId = this.$route.query.end_tag_path_id ? Number(this.$route.query.end_tag_path_id) : null
        this.searchForm.tagName = this.$route.query.tag_name ? this.$route.query.tag_name : ''
        this.searchForm.isProductName = this.$route.query.is_product_name === '0' ? false : (this.$route.query.is_product_name === '1' ? true : null)
        this.searchForm.name = this.$route.query.name ? this.$route.query.name : ''
        this.searchForm.hiraganaName = this.$route.query.hiragana_name ? this.$route.query.hiragana_name : ''
        this.searchForm.ng = this.$route.query.ng ? this.$route.query.ng : ''

        await Promise.all([
            this.fetch(),
            this.fetchMaterialParentTags(),
        ])
    },
    methods: {
        // 検索
        async fetch() {
            this.$store.commit(types.LOADING, true)

            const page = this.$route.query.page ? this.$route.query.page : 1
            const endTagPathId = this.$route.query.end_tag_path_id ? this.$route.query.end_tag_path_id : null
            const tagName = this.$route.query.tag_name ? this.$route.query.tag_name : ''
            const isProductName = this.$route.query.is_product_name !== undefined ? this.$route.query.is_product_name : null
            const name = this.$route.query.name ? this.$route.query.name : ''
            const hiraganaName = this.$route.query.hiragana_name ? this.$route.query.hiragana_name : ''
            const ng = this.$route.query.ng ? this.$route.query.ng : ''

            const {data, error} = await axios.get('/api/suggests/materials', {
                params: {
                    page: page,
                    end_tag_path_id: endTagPathId,
                    tag_name: tagName,
                    is_product_name: isProductName,
                    name: name,
                    hiragana_name: hiraganaName,
                    ng: ng,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.pagination = Object.assign({}, data.pagination)
            this.materials = structuredClone(data.suggest_materials)
            this.tagPathsByEndTagId = structuredClone(data.tag_paths)
            this.materialUnits = Object.assign([], data.suggest_units)

            this.$store.commit(types.LOADING, false)
        },
        search(page) {
            this.$router.push({
                query: {
                    page: page,
                    end_tag_path_id: this.searchForm.endTagPathId,
                    tag_name: this.searchForm.tagName,
                    is_product_name: this.searchForm.isProductName === null ? '' : (this.searchForm.isProductName ? '1' : '0'),
                    name: this.searchForm.name,
                    hiragana_name: this.searchForm.hiraganaName,
                    ng: this.searchForm.ng
                }
            })
            this.fetch()
        },
        /**
         * 食材親タグリストの取得
         * 親タグは共通で必ず使用するため、初期表示時に取得できるようにしている
         *
         * @returns {Promise<void>}
         */
        async fetchMaterialParentTags() {
            const { data } = await axios.get('/api/tags/search', {
                params: {
                    tag_type: 'PARENT',
                    base_tag_path_ids: [],
                    tag_class: 'MATERIAL',
                }
            })
            this.defaultParentTags = data.tag_paths
        },
    }
}
</script>

<style lang="scss" scoped>
.managementMaterial {
    .searchRow {
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        .searchInput {
            width: 150px;
        }
        .searchProductSelect {
            width: 200px;
        }
        .searchSubmitButton {
            width: 90px;
            margin: 0 0 0 10px;
        }
    }
}
</style>
