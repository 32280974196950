<template>
    <div>
        <el-row >
            <item-left-contents
                :check-state="checkState"
                :number="number"
                :page-number="item.page_number"
                @update:checkState="checkState = $event"
            />

            <el-col class="contents" :span="21">
                <video-type
                    v-if="isEditing"
                    ref="dialogModule"
                    :save="update"
                    @close="closeMenu"
                    :id="content.id"
                    :input="content"
                />
                <div v-else>
                    <el-row class="videoItem" type="flex">
                        <iframe
                            v-if="content.video_type === 'youtube'"
                            width="560"
                            height="315"
                            :src="youtubeUrl"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                        <iframe
                            v-if="content.video_type === 'vimeo'"
                            :src="vimeoUrl"
                            width="560"
                            height="310"
                            frameborder="0"
                            webkitallowfullscreen mozallowfullscreen allowfullscreen
                        ></iframe>
                        <video
                            v-if="content.video_type === 'instagram'"
                            :poster="instagramPosterUrl"
                            muted="muted"
                            controls="controls"
                            :src="instagramVideoUrl"
                            width="560"
                            height="310"
                        ></video>
                        <div
                            v-if="content.video_type === 'embed_tag'"
                        >{{content.embed_tag}}</div>
                        <video
                            v-if="content.video_type === 'upload'"
                            controls="controls"
                            :src="uploadVideoUrl"
                            width="560"
                            height="310"
                        ></video>
                    </el-row>
                    <el-row class="videoComment" v-if="content.video_comment" >
                        <h5>コメント</h5>
                        <p style="white-space:pre-line;">
                            {{content.video_comment}}
                        </p>
                    </el-row>
                </div>
            </el-col>

            <el-col class="rightBox" :span="2">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>

    </div>
</template>
<script>
import VideoType from '../Form/VideoType/Comment'
import ItemLeftContents from './Components/ItemLeftContents'

export default {
    components: {
        ItemLeftContents,
        VideoType
    },
    data () {
        return {
            isEditing: false,
        }
    },
    computed: {
        youtubeUrl() {
            return 'https://www.youtube.com/embed/' + this.content.video_key
        },
        vimeoUrl() {
            return '//player.vimeo.com/video/' + this.content.video_key
        },
        instagramPosterUrl() {
            return this.content.thumbnail_url
        },
        instagramVideoUrl() {
            return this.content.video_url
        },
        uploadVideoUrl() {
            return this.content.origin_upload_video
        },
        content() {
            return this.item.content
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        resourceName() {
            return 'videos'
        },
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check,item) {
            this.parentCheckItem(check,item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        }
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>
<style scoped>
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}
.videoItem img {
    max-width:600px;
    margin-top:10px;
}
</style>
