<template>
    <div class="managementMaterialRow">
        <div v-if="canEdit" class="createForm">
            <div class="createFormRow">
                <el-input v-model="createForm.name" placeholder="材料名" class="createInput"></el-input>
                <el-input v-model="createForm.hiragana_name" placeholder="読み方" class="createInput"></el-input>
                <el-input v-model="createForm.ng" placeholder="NG" class="createInput"></el-input>
            </div>
            <div class="createFormRow">
                <el-checkbox
                    v-model="createForm.is_exclude_material_category"
                    class="createCheckbox"
                    @change="createForm.end_tag_path_id = createForm.is_exclude_material_category ? null : createForm.end_tag_path_id"
                >食材マスタ除外</el-checkbox>
                <single-tag-relation-form
                    v-model="createForm.end_tag_path_id"
                    tag-class="MATERIAL"
                    :disabled="createForm.is_exclude_material_category"
                    :tag-paths-key-by-end-tag-id="tagPathsByEndTagId"
                    :default-parent-tag-paths="defaultParentTags"
                ></single-tag-relation-form>
                <el-input v-model="createForm.comment" placeholder="コメント" class="createInput"></el-input>
                <el-button @click="openUnitModal(createForm, null)" class="createUnitButton">単位選択</el-button>
                <el-checkbox v-model="createForm.is_product_name" class="createCheckbox">商品名フラグ</el-checkbox>
            </div>
            <div class="createFormRow">
                <el-button class="createSubmitButton" type="primary" @click="create">新規作成</el-button>
            </div>
        </div>

        <el-table
            :data="materials"
            style="width: 100%"
        >
            <el-table-column label="材料名">
                <template v-slot="scope">
                    <el-input placeholder="材料名" v-model="scope.row.name" :disabled="!canEdit"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="読み方">
                <template v-slot="scope">
                    <el-input placeholder="読み方" v-model="scope.row.hiragana_name" :disabled="!canEdit"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="NG">
                <template v-slot="scope">
                    <el-input placeholder="NG" v-model="scope.row.ng" :disabled="!canEdit"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="食材マスタ除外フラグ">
                <template v-slot="scope">
                    <el-checkbox
                        v-model="scope.row.is_exclude_material_category"
                        :disabled="!canEdit"
                        @change="scope.row.end_tag_path_id = scope.row.is_exclude_material_category ? null : scope.row.end_tag_path_id"
                    >除外</el-checkbox>
                </template>
            </el-table-column>
            <el-table-column label="親/子/孫タグ">
                <template v-slot="scope">
                    <single-tag-relation-form
                        v-model="scope.row.end_tag_path_id"
                        tag-class="MATERIAL"
                        flex-direction-column
                        :disabled="scope.row.is_exclude_material_category || !canEdit"
                        :tag-paths-key-by-end-tag-id="tagPathsByEndTagId"
                        :default-parent-tag-paths="defaultParentTags"
                    ></single-tag-relation-form>
                </template>
            </el-table-column>
            <el-table-column label="コメント">
                <template v-slot="scope">
                    <el-input placeholder="コメント" v-model="scope.row.comment" :disabled="!canEdit"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="単位">
                <template v-slot="scope">
                    <el-button @click="openUnitModal(scope.row, scope.$index)">単位選択</el-button>
                </template>
            </el-table-column>
            <el-table-column label="商品名フラグ">
                <template v-slot="scope">
                    <el-checkbox v-model="scope.row.is_product_name" :disabled="!canEdit">商品名</el-checkbox>
                </template>
            </el-table-column>
            <el-table-column label="更新">
                <template v-slot="scope">
                    <el-button
                        class="btn-warning"
                        type="warning"
                        :disabled="!canEdit"
                        @click="update(scope.row)"
                    >更新
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column label="削除">
                <template v-slot="scope">
                    <el-button
                        class="btn-danger"
                        type="danger"
                        :disabled="!canEdit"
                        @click="deleteMaterial(scope.row)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <unit-modal
            :is-active="isModalActive"
            :material-units="materialUnits"
            :material="selectMaterial"
            :disabled="!canEdit"
            @close="closeUnitModal"
        ></unit-modal>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import UnitModal from './Material/UnitModal'
import SingleTagRelationForm from '../../components/Tag/SingleTagRelationForm'

export default {
    components: {
        UnitModal,
        SingleTagRelationForm,
    },
    props: {
        isWr: {
            type: Boolean,
            required: true
        },
        materials: {
            type: Array,
            required: true
        },
        materialUnits: {
            type: Array,
            required: true
        },
        tagPathsByEndTagId: {
            type: Object,
            required: true
        },
        defaultParentTags: {
            type: Array,
            required: true,
        },
        fetch: {
            type: Function,
            required: false,
        }
    },
    data() {
        return {
            createForm: {
                end_tag_path_id: null,
                is_exclude_material_category: false,
                is_product_name: false,
                name: '',
                hiragana_name: '',
                ng: '',
                comment: '',
                suggest_unit_ids: '',
                suggest_unit: [],
            },
            isModalActive: false,
            selectMaterial: {},
            selectMaterialIdx: 0,
        }
    },
    computed: {
        canEdit () {
            return !this.isWr
        },
    },
    methods: {
        openUnitModal(material, idx) {
            this.selectMaterial = material
            this.selectMaterialIdx = idx
            this.isModalActive = true
        },
        closeUnitModal(suggestUnits) {
            if (this.selectMaterialIdx === null) {
                this.createForm = {...this.createForm, suggest_unit: structuredClone(suggestUnits)}
            } else {
                this.$set(this.materials[this.selectMaterialIdx], 'suggest_unit', structuredClone(suggestUnits))
            }
            this.isModalActive = false
        },
        async update(material) {
            this.$store.commit(types.LOADING, true)

            const suggestUnitIds = this.getSuggestUnitIds(material.suggest_unit)

            const { error } = await axios.put('/api/suggests/materials/'+material.id, {
                id: material.id,
                end_tag_path_id: material.end_tag_path_id,
                is_exclude_material_category: material.is_exclude_material_category,
                is_product_name: material.is_product_name,
                name: material.name,
                hiragana_name: material.hiragana_name,
                ng: material.ng,
                comment: material.comment,
                suggest_unit_ids: suggestUnitIds,
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.$emit('fetch')
        },
        async create() {
            this.$store.commit(types.LOADING, true)

            this.createForm.suggest_unit_ids = this.getSuggestUnitIds(this.createForm.suggest_unit)

            const { error } = await axios.post('/api/suggests/materials', this.createForm)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.createForm = {
                end_tag_path_id: null,
                is_exclude_material_category: false,
                is_product_name: false,
                name: '',
                hiragana_name: '',
                ng: '',
                comment: '',
                suggest_unit_ids: '',
            }

            this.$emit('fetch')
        },
        async deleteMaterial(material) {
            if (! confirm('本当に削除しますか？')) {
                return
            }

            this.$store.commit(types.LOADING, true)

            const { error } = await axios.delete('/api/suggests/materials/'+material.id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.$emit('fetch')
        },
        getSuggestUnitIds(suggestUnits) {
            let suggestUnitIds = []
            suggestUnits.forEach(suggestUnit => {
                suggestUnitIds.push(suggestUnit.id)
            })

            return suggestUnitIds
        }
    }
}
</script>

<style lang="scss" scoped>
.managementMaterialRow {
    .createForm {
        width: 1100px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        border: 1px solid #EBEEF5;
        border-radius: 3px;
        padding: 15px;
        margin: 0 0 15px auto;
        .createFormRow {
            display: flex;
            justify-content: flex-end;
            gap: 5px;
        }
        .createInput {
            width: 150px;
        }
        .createCheckbox {
            display: flex;
            align-items: center;
            margin-right: 5px;
        }
        .createUnitButton {
            width: 100px;
        }
        .createSubmitButton {
            width: 90px;
            margin: 0 0 0 10px;
        }
    }
}
</style>
