<template>
    <el-dialog
        title="インセンティブ"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
    >
        <p v-if="isPublished" style="color:red; margin-top:0;">この記事は公開されているため、インセンティブの登録/変更はできません</p>
        <el-tabs type="card">
            <el-tab-pane label="固定インセンティブ">
                <div v-if="isPublished">
                    <el-table
                        :data="incentiveHistories"
                        style="width: 100%"
                    >
                        <el-table-column label="インセンティブ詳細" prop="description"></el-table-column>
                        <el-table-column label="料金">
                            <template slot-scope="scope">{{ scope.row.price | number }}円</template>
                        </el-table-column>
                    </el-table>
                </div>
                <div v-else>
                    <el-row v-if="canEdit" type="flex" style="margin: 30px 0;">
                        <el-select v-model="selectIncentiveId" class="incentive-create-form-input" @change="initArticleIncentivePriceId">
                            <el-option
                                v-for="incentive in incentives"
                                :key="incentive.id"
                                :label="incentive.name"
                                :value="incentive.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="createForm.article_incentive_price_id" class="incentive-create-form-input">
                            <el-option
                                v-for="incentivePrice in selectIncentivePrices"
                                :key="incentivePrice.id"
                                :label="incentivePrice.name"
                                :value="incentivePrice.id">
                            </el-option>
                        </el-select>
                        <span class="incentive-create-form-input" style="display: flex;align-items: center;">{{ selectIncentivePrice | number }}円</span>
                        <el-button class="add-button create-form-input" type="primary" @click="create">新規作成</el-button>
                    </el-row>

                    <el-table
                        :data="incentivePrices"
                        style="width: 100%"
                    >
                        <el-table-column label="インセンティブ名">
                            <template slot-scope="scope">{{ scope.row.article_incentive.name }}</template>
                        </el-table-column>
                        <el-table-column label="インセンティブ詳細名" prop="name"></el-table-column>
                        <el-table-column label="料金">
                            <template slot-scope="scope">{{ scope.row.price | number }}</template>
                        </el-table-column>
                        <el-table-column v-if="canEdit" label="削除">
                            <template slot-scope="scope">
                                <el-button type="danger" @click="deleteArticleIncentivePrice(scope.row.id)">削除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <el-row type="flex" style="margin-top: 30px;" justify="end">
                    <el-button @click="close">閉じる</el-button>
                </el-row>
            </el-tab-pane>

            <el-tab-pane label="本数変動型インセンティブ">
                <div v-if="canEdit">
                    <el-form ref="form" label-width="150px">
                        <el-form-item label="オリジナル撮影料">
                            <el-checkbox v-model="otherCreateForm.is_photo_incentive_target"></el-checkbox>
                        </el-form-item>
                    </el-form>
                    <el-row type="flex" style="margin-top: 30px;" justify="end">
                        <el-button @click="close">閉じる</el-button>
                        <el-button @click="updateOtherIncentive" type="primary">更新</el-button>
                    </el-row>
                </div>
                <div v-else>
                    <el-form ref="form" label-width="120px">
                        <el-form-item label="オリジナル撮影">
                            <el-checkbox v-model="otherCreateForm.is_photo_incentive_target" disabled></el-checkbox>
                        </el-form-item>
                    </el-form>
                    <el-row type="flex" style="margin-top: 30px;" justify="end">
                        <el-button @click="close">閉じる</el-button>
                    </el-row>
                </div>
            </el-tab-pane>
        </el-tabs>
    </el-dialog>
</template>

<script>
export default {
    name: 'IncentiveModal',
    props: {
        isActive: Boolean,
        incentivePrices: Array,
        incentives: Array,
        isWr: Boolean,
        isPublished: Boolean,
        canEditStatus: Boolean,
        incentiveHistories: Array,
        otherIncentiveParams: Object,
    },
    data() {
        return {
            createForm: {
                article_incentive_price_id: null,
            },
            otherCreateForm: {
                is_photo_incentive_target: false,
            },
            selectIncentiveId: null,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        deleteArticleIncentivePrice(articleIncentivePriceId) {
            if (! confirm('本当に削除しますか？')) {
                return
            }
            this.$emit('deleteArticleIncentivePrice', articleIncentivePriceId)
        },
        create() {
            this.$emit('create', this.createForm)
        },
        initArticleIncentivePriceId() {
            this.createForm.article_incentive_price_id = null
        },
        updateOtherIncentive() {
            this.$emit('updateOtherIncentive', this.otherCreateForm)
        }
    },
    computed: {
        selectIncentivePrices() {
            let incentivePrices = []
            const vue = this
            this.incentives.forEach(function (incentive) {
                if (vue.selectIncentiveId === incentive.id) {
                    incentivePrices = incentive.article_incentive_prices
                    return
                }
            })

            return incentivePrices
        },
        selectIncentivePrice() {
            let incentivePrice = 0
            const vue = this
            this.selectIncentivePrices.forEach(function (incentivePriceObj) {
                if (vue.createForm.article_incentive_price_id === incentivePriceObj.id) {
                    incentivePrice = incentivePriceObj.price
                    return
                }
            })

            return incentivePrice
        },
        canEdit() {
            return (! this.isWr && this.canEditStatus)
        },
    },
    watch: {
        otherIncentiveParams() {
            this.otherCreateForm = Object.assign({}, this.otherIncentiveParams)
        }
    }
}
</script>

<style scoped>
    .incentive-create-form-input {
        margin-right: 10px;
    }
</style>
