<template>
    <div>
        <p class="description">{{ topic.description }}</p>
        <el-tabs type="card">
            <el-tab-pane label="記事追加">
                <el-row type="flex" justify="end" style="margin-top:10px;">
                    <el-input
                        v-model="searchForm.article_id"
                        placeholder="ID"
                        type="number"
                        style="width: 200px;"
                        class="search-box"
                    ></el-input>
                    <el-input
                        v-model="searchForm.title"
                        placeholder="タイトル"
                        style="width: 400px;"
                        class="search-box"
                    ></el-input>
                    <el-input
                        v-model="searchForm.description"
                        placeholder="ディスクリプション"
                        style="width: 400px;"
                        class="search-box"
                    ></el-input>
                    <el-button
                            @click="searchArticle"
                            icon="el-icon-search">
                    </el-button>
                </el-row>
                <el-table
                        :data="searchArticles"
                        style="width: 100%"
                        max-height="800"
                >
                    <el-table-column label="ID" prop="id"></el-table-column>
                    <el-table-column label="タイトル" prop="title"></el-table-column>
                    <el-table-column label="サムネイル" width="120">
                        <template slot-scope="props">
                            <img :src="props.row.thumbnail_cdn_url" class="thumbnail-img">
                        </template>
                    </el-table-column>
                    <el-table-column label="公開日時" prop="published_at"></el-table-column>
                    <el-table-column label="noindex">
                        <template slot-scope="scope">
                            <i v-if="scope.row.is_noindex" class="fas fa-check-square"></i>
                            <i v-else class="far fa-square"></i>
                        </template>
                    </el-table-column>
                    <el-table-column label="追加">
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                @click="addArticleTopic(scope.row.id)"
                            >追加</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination :handleCurrentChange="searchArticle" :pagination="pagination" />
            </el-tab-pane>

            <el-tab-pane label="追加記事一覧">
                <el-table
                    :data="articles"
                    style="width: 100%"
                    max-height="800"
                >
                    <el-table-column label="ID" prop="id"></el-table-column>
                    <el-table-column label="タイトル" prop="title"></el-table-column>
                    <el-table-column label="サムネイル" width="120">
                        <template slot-scope="props">
                            <img :src="props.row.thumbnail" class="thumbnail-img">
                        </template>
                    </el-table-column>
                    <el-table-column label="公開日時" prop="published_at"></el-table-column>
                    <el-table-column label="更新日時" prop="latest_updated_at"></el-table-column>
                    <el-table-column label="noindex">
                        <template slot-scope="scope">
                            <i v-if="scope.row.is_noindex" class="fas fa-check-square"></i>
                            <i v-else class="far fa-square"></i>
                        </template>
                    </el-table-column>
                    <el-table-column label="削除">
                        <template slot-scope="scope">
                            <el-button
                                type="danger"
                                @click="deleteArticle(scope.row.id)"
                            >削除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import {PAGE_TITLE} from '../../store/mutation-types'

export default {
    components: { Pagination },
    data() {
        return {
            topicId: 0,
            topic: {},
            searchForm: {
                article_id: '',
                title: '',
                description: '',
            },
            articles: [],
            searchArticles: [],
            pagination: {},
        }
    },
    async created() {
        this.topicId = this.$route.params.topicId
        await this.init()
        this.$store.commit(PAGE_TITLE, '編成　>　特集　>　' + this.topic.title)
    },
    methods: {
        async init() {
            this.$store.commit(types.LOADING, true)
            await this.fetchTopic(false)
            await this.searchArticle(1, false)
            await this.fetchArticle(false)
            this.$store.commit(types.LOADING, false)
        },
        // フォーム初期化
        formInit() {
            this.searchForm = {
                topic_id: this.topicId,
                article_id: '',
                title: '',
                description: '',
            }
        },
        // 特集取得
        async fetchTopic(isLoading = true) {
            if (isLoading) {
                this.$store.commit(types.LOADING, true)
            }

            const { data, error } = await axios.get('/api/topics/'+this.topicId)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '特集の取得に失敗しました')
                this.$store.commit(types.LOADING, false)
            }
            this.topic = data

            if (isLoading) {
                this.$store.commit(types.LOADING, false)
            }
        },
        // 記事検索
        async searchArticle(page = 1, isLoading = true) {
            if (isLoading) {
                this.$store.commit(types.LOADING, true)
            }

            const { data, error } = await axios.get('/api/topics/'+this.topic.id+'/articles/unregister', {
                params: {
                    page,
                    article_id: this.searchForm.article_id,
                    title: this.searchForm.title,
                    description: this.searchForm.description,
                }
            })

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '記事の検索に失敗しました')
                this.$store.commit(types.LOADING, false)

                return
            }

            this.searchArticles = data.articles.data
            this.pagination = Object.assign({}, this.pagination, data.articles)
            if (isLoading) {
                this.$store.commit(types.LOADING, false)
            }
        },
        // 記事追加
        async addArticleTopic(articleId, isLoading = true) {
            this.$store.commit(types.LOADING, true)

            const params = {
                article_id: articleId,
            }
            const { error } = await axios.post('/api/topics/'+this.topic.id+'/articles', params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '記事の追加に失敗しました')
                return
            }

            this.init()
        },
        // 追加記事取得
        async fetchArticle(isLoading = true) {
            if (isLoading) {
                this.$store.commit(types.LOADING, true)
            }
            const { data, error } = await axios.get('/api/topics/'+this.topic.id+'/articles/registered')

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '追加済み記事の取得に失敗しました')
                this.$store.commit(types.LOADING, false)

                return
            }

            this.articles = data.articles
            if (isLoading) {
                this.$store.commit(types.LOADING, false)
            }
        },
        // 記事削除
        async deleteArticle(articleId) {
            this.$store.commit(types.LOADING, true)
            const { error } = axios.delete('/api/topics/'+this.topic.id+'/articles/'+articleId)

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '記事の削除に失敗しました')
                this.$store.commit(types.LOADING, false)
                return
            }

            this.init()
        }
    },
}
</script>

<style lang="scss" scoped>
    .search-box {
        margin-right: 15px;
    }
    .sort-button {
        text-align: right;
        margin-top: 10px;
    }
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }
        dt {
            width: 15%;
        }
        dd {
            width: 85%;
        }
    }
    .thumbnail-img {
        width: 100px;
    }
    .description {
        margin: 50px 0 20px;
    }
</style>
