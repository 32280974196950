<template>
    <div>
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent label-width="90px" >
            <el-form-item label="見出し種別" >
                <el-select v-model="form.heading_type" size="small">
                     <el-option
                         v-for="item in headingTypeList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                 </el-select>
            </el-form-item>
            <el-form-item label="見出し" >
                <el-input
                    type="text"
                    maxlength="100"
                    v-model="form.heading_text">
                </el-input>
                <text-length-count :targetText="form.heading_text" :maxTextLength="100"></text-length-count>
            </el-form-item>
        </el-form>
        <span slot="footer" class="itemFormFooter" style="margin-left: 90px;">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
    </div>
</template>

<script>

import TextLengthCount from './Components/TextLengthCount'

const defaultFormValues =  {
    heading_type:null,
    heading_text:null
}
export default {
    components: {
        TextLengthCount,
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        headingTypeList: {
            required: true,
            type: Array,
        },
        save: {
            required: true,
            type: Function,
        },
    },
    data () {
        return {
            form: {...defaultFormValues},
        }
    },
    mounted() {
        this.form.heading_type = 'large'
    },
    methods: {
        submitForm () {
            this.save(this.resourceName, this.form, this.id)
        },
        openDialog(input) {
            this.form = Object.assign({}, defaultFormValues, _.pick(input, Object.keys(this.form)))
        }
    },
    computed: {
        resourceName() {
            return 'headings'
        },
    }
}
</script>
