<template>
    <div class="comparisonItem">
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent label-width="140px" >
            <div class="comparisonItem__formHeading">比較表</div>
            <div v-if="notExistsInformationItem">
                <div class="comparisonItem__notMakeText">比較表を作成する、アイテム情報（テーブル表示）がありません。<br/>アイテム情報を１つ以上登録してください。</div>
                <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
            </div>
            <div v-else-if="!id && existsItem">
                <div class="comparisonItem__notMakeText">作成済みです</div>
                <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
            </div>
            <div v-else-if="!id && headingDifferentInformationItems.length !== 0">
                <div class="comparisonItem__notMakeText">アイテム情報（テーブル表示）の「項目」が揃っていません。<br/>下記を参考に、アイテム情報（テーブル表示）の内容を修正してください</div>
                <table class="comparisonItem__table" border="1">
                    <tbody>
                        <tr>
                            <td class="comparisonItem__baseTableItemNo">アイテムNo.{{ baseInformationItem.order_num }}（ベース）</td>
                            <td v-for="(element, idx) in baseInformationItem.content.elements" :key="idx">{{ element.label }}</td>
                        </tr>
                        <tr v-for="(item, idx) in headingDifferentInformationItems" :key="idx">
                            <td class="comparisonItem__baseTableItemNo--diff">アイテムNo.{{ item.order_num }}</td>
                            <td v-for="(element, elementIdx) in item.elements" :key="elementIdx">{{ element.label }}</td>
                        </tr>
                    </tbody>
                </table>
                <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
            </div>
            <div v-else>
                <div v-if="id && !isGetTableData && headingDifferentInformationItems.length !== 0">
                    <div class="comparisonItem__notMakeText">
                        アイテム情報（テーブル表示）の「項目」が揃っていないため、最新の状態に更新できません。
                        <br/>下記を参考に、アイテム情報（テーブル表示）の内容を修正してください
                    </div>
                    <table class="comparisonItem__table" border="1">
                        <tbody>
                        <tr>
                            <td class="comparisonItem__baseTableItemNo">アイテムNo.{{ baseInformationItem.order_num }}（ベース）</td>
                            <td v-for="(element, idx) in baseInformationItem.content.elements" :key="idx">{{ element.label }}</td>
                        </tr>
                        <tr v-for="(item, idx) in headingDifferentInformationItems" :key="idx">
                            <td class="comparisonItem__baseTableItemNo--diff">アイテムNo.{{ item.order_num }}</td>
                            <td v-for="(element, elementIdx) in item.elements" :key="elementIdx">{{ element.label }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <el-button v-else-if="id && !isGetTableData" type="primary" size="mini" @click="makeTemplate(true)" class="comparisonItem__replaceBtn">比較表を最新の状態に更新</el-button>
                <el-button v-else-if="!id && !isGetTableData" type="primary" size="mini" @click="makeTemplate">作成</el-button>
                <div v-if="id || isGetTableData">
                    <div>
                        <el-checkbox
                            :true-label="1"
                            :false-label="0"
                            v-model="tableData.is_ranking_mode"
                        >ランキング形式にする</el-checkbox>
                    </div>
                    <table class="comparisonItem__table" border="1">
                        <thead>
                            <tr>
                                <th></th>
                                <th>商品</th>
                                <th v-if="tableData.is_show_affiliate">
                                    <span @click="headPriceSort" class="comparisonItem__tableHead--sort"><i class="fas fa-caret-down"></i>&nbsp;最安値</span>
                                    <span @click="deleteAffiliateHead"><i class="fas fa-minus-circle comparisonItem__headDeleteIcon"></i></span>
                                </th>
                                <th v-if="tableData.is_show_other_link">
                                    その他リンク<span @click="deleteOtherLinkHead"><i class="fas fa-minus-circle comparisonItem__headDeleteIcon"></i></span>
                                </th>
                                <th v-for="(heading, idx) in tableData.headings" :key="idx">
                                    <span @click="headMoveLeft(idx)" class="comparisonItem__tableHead--left"><i class="fas fa-chevron-left"></i>︎</span>
                                    <span @click="headSort(idx)" class="comparisonItem__tableHead--sort"><i class="fas fa-caret-down"></i>&nbsp;{{ heading.heading }}</span>
                                    <span @click="deleteHead(idx, heading)"><i class="fas fa-minus-circle comparisonItem__headDeleteIcon"></i></span>
                                    <span @click="headMoveRight(idx)" class="comparisonItem__tableHead--right"><i class="fas fa-chevron-right"></i>︎︎</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, idx) in tableData.rows" :key="idx">
                                <td>
                                    <div @click="rowMoveTop(idx)" class="comparisonItem__tableRow--top"><i class="fas fa-chevron-up"></i></div>
                                    <div @click="deleteRow(idx, row)"><i class="fas fa-minus-circle"></i></div>
                                    <div @click="rowMoveBottom(idx)" class="comparisonItem__tableRow--bottom"><i class="fas fa-chevron-down"></i></div>
                                </td>
                                <td>
                                    <div class="comparisonItem__imageWrapper">
                                        <img :src="row.image_src" class="comparisonItem__image" />
                                        <span v-if="tableData.is_ranking_mode" class="comparisonItem__ranking">{{ idx + 1 }}</span>
                                    </div>
                                    <div class="comparisonItem__itemDetail">
                                        <div class="comparisonItem__brand">{{ row.brand }}</div>
                                        <div>{{ row.item_name }}</div>
                                    </div>
                                </td>
                                <td v-if="tableData.is_show_affiliate">
                                    <a v-if="row.affiliate_url" :href="row.affiliate_url" class="comparisonItem__link--affiliate">
                                        <div class="comparisonItem__linkText--small">{{ row.affiliate_label }}</div>
                                        <div class="comparisonItem__linkText">{{ row.affiliate_price }}円</div>
                                        <div class="comparisonItem__linkText--small">(税込み)</div>
                                    </a>
                                </td>
                                <td v-if="tableData.is_show_other_link">
                                    <a v-if="row.other_label" :href="row.other_url" class="comparisonItem__link--other">
                                        {{ row.other_label_text }}
                                    </a>
                                </td>
                                <td v-for="(heading, headingIdx) in tableData.headings" :key="headingIdx">
                                    {{ row.cells.find(cell => heading.heading === cell.heading.heading).body }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span slot="footer">
                        <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
                        <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
                    </span>
                </div>
            </div>
            <div v-else>アイテム情報の「項目」が揃っていないため作成できません。</div>
        </el-form>
    </div>
</template>

<script>
import * as types from '../../../../../store/mutation-types'

const defaultFormValues = {
    id: null,
    is_ranking_mode: false,
    is_show_affiliate: true,
    is_show_other_link: true,
    rows: [],
    headings: [],
    delete_row_ids: [],
    delete_heading_ids: [],
    is_replace: false,
}

export default {
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        articleId: {
            required: true,
            type: Number,
        },
        headingDifferentInformationItems: {
            required: true,
            type: Array,
            default: [],
        },
        items: {
            required: true,
            type: Array,
            default: []
        },
    },
    data() {
        return {
            tableData: {},
            isGetTableData: false,
            affiliatePriceSortCount: 1,
            otherSortCountList: {},
        }
    },
    created() {
        this.tableData = Object.assign({}, defaultFormValues, {
            rows: [],
            headings: [],
            delete_row_ids: [],
            delete_heading_ids: [],
        })
    },
    methods: {
        submitForm () {
            this.save(this.resourceName, this.tableData, this.id)
        },
        async makeTemplate(isReplace = false) {
            const { data, error } = await axios.get(`/api/articles/${this.articleId}/items/comparisons/make_template`)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.tableData = data
            this.isGetTableData = true
            if (isReplace) {
                this.tableData.is_replace = true
            }
        },
        deleteRow(idx, row) {
            this.tableData.rows.splice(idx, 1)
            if (this.id) {
                this.tableData.delete_row_ids.push(row.id)
            }
        },
        deleteAffiliateHead() {
            this.tableData.is_show_affiliate = false
        },
        deleteOtherLinkHead() {
            this.tableData.is_show_other_link = false
        },
        deleteHead(idx, heading) {
            this.tableData.headings.splice(idx, 1)
            this.tableData.rows = this.tableData.rows.map(row => {
                row.cells = row.cells.filter((cell, cellIdx) => idx !== cellIdx).slice()
                return row
            })
            if (this.id) {
                this.tableData.delete_heading_ids.push(heading.id)
            }
        },
        openDialog(input) {
            this.tableData = Object.assign({}, this.tableData, input)
            this.tableData.headings = input.headings.map(heading => Object.assign({}, heading))
            this.tableData.rows = input.rows.map(row => Object.assign({}, row))
        },
        headPriceSort() {
            if (this.affiliatePriceSortCount % 2 === 1) {
                // desc
                this.tableData.rows.sort((a, b) => b.affiliate_price - a.affiliate_price)
            } else {
                // asc
                this.tableData.rows.sort((a, b) => a.affiliate_price - b.affiliate_price)
            }
            this.affiliatePriceSortCount++
        },
        headSort(headIdx) {
            if (!this.otherSortCountList.hasOwnProperty(headIdx)) {
                this.otherSortCountList[headIdx] = 1
            }
            if (this.otherSortCountList[headIdx] % 2 === 1) {
                // desc
                this.tableData.rows.sort((a, b) => b.cells[headIdx].body - a.cells[headIdx].body)
            } else {
                // asc
                this.tableData.rows.sort((a, b) => a.cells[headIdx].body - b.cells[headIdx].body)
            }
            this.otherSortCountList[headIdx]++
        },
        headMoveLeft(headIdx) {
            if (headIdx === 0) {
                return
            }
            this.tableData.headings.splice(headIdx - 1, 2, this.tableData.headings[headIdx], this.tableData.headings[headIdx - 1])
            this.tableData.rows = this.tableData.rows.map(row => {
                const cells = row.cells.slice()
                cells.splice(headIdx - 1, 2, row.cells[headIdx], row.cells[headIdx - 1])
                row.cells = cells.slice()
                return row
            })
        },
        headMoveRight(headIdx) {
            if (this.tableData.headings.length === (headIdx + 1)) {
                return
            }
            this.tableData.headings.splice(headIdx, 2, this.tableData.headings[headIdx + 1], this.tableData.headings[headIdx])
            this.tableData.rows = this.tableData.rows.map(row => {
                const cells = row.cells.slice()
                cells.splice(headIdx, 2, row.cells[headIdx + 1], row.cells[headIdx])
                row.cells = cells.slice()
                return row
            })
        },
        rowMoveTop(rowIdx) {
            if (rowIdx === 0) {
                return
            }
            this.tableData.rows.splice(rowIdx - 1, 2, this.tableData.rows[rowIdx], this.tableData.rows[rowIdx - 1])
        },
        rowMoveBottom(rowIdx) {
            if (this.tableData.rows.length === (rowIdx + 1)) {
                return
            }
            this.tableData.rows.splice(rowIdx, 2, this.tableData.rows[rowIdx + 1], this.tableData.rows[rowIdx])
        }
    },
    computed: {
        // 比較表アイテム作成済みか
        existsItem() {
            return this.items.map(item => item.item_type).includes('ComparisonType')
        },
        // アイテム情報が存在するか
        notExistsInformationItem() {
            return ! this.items.map(item => item.item_type).includes('InformationType')
        },
        // 見出しのベースとなるアイテム情報
        baseInformationItem() {
            return this.items.find(item => {
                if (item.item_type === 'InformationType' && item.content.is_comparison_table_base_heading) {
                    return item
                }
            })
        },
        resourceName() {
            return 'comparisons'
        },
    }
}

</script>

<style scoped lang="scss">
.comparisonItem {
    &__formHeading {
        font-weight: bold;
        margin-bottom: 10px;
    }
    &__table {
        display: block;
        width: 100%;
        overflow-x: scroll;
        border: none;
        margin: 15px 0 20px;
        white-space: nowrap;
        border-collapse: collapse;
        & tbody,
        & thead {
            border-color: #cccccc;
        }
        & th {
            padding: 3px 10px;
        }
        & td {
            padding: 10px;
        }
    }
    &__itemDetail {
        display: inline-block;
    }
    &__brand {
        color: #999999;
    }
    %__link {
        display: block;
        border-radius: 10px;
        padding: 5px;
        text-decoration: none;
        color: white;
        text-align: center;
    }
    &__link {
        &--affiliate {
            @extend %__link;
            background: #FF7B7B;
        }
        &--other {
            @extend %__link;
            background: #FFAB7B;
        }
    }
    &__linkText {
        &--small {
            font-size: 10px;
        }
    }
    &__headDeleteIcon {
        margin-left: 5px;
    }
    &__imageWrapper {
        position: relative;
        display: inline-block;
    }
    &__image {
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
    &__ranking {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #696868;
        position: absolute;
        left: -5px;
        top: -5px;
        color: white;
        text-align: center;
    }
    %__tableHead {
        cursor: pointer;
    }
    &__tableHead {
        &--sort {
            @extend %__tableHead;
            margin-right: 3px;
        }
        &--right {
            @extend %__tableHead;
            margin-left: 5px;
            color: #7c7c7c;
        }
        &--left {
            @extend %__tableHead;
            margin-right: 5px;
            color: #7c7c7c;
        }
    }
    %__tableRow {
        color: #7c7c7c;
        cursor: pointer;
    }
    &__tableRow {
        &--top {
            @extend %__tableRow;
        }

        &--bottom {
            @extend %__tableRow;
        }
    }
    &__replaceBtn {
        margin-bottom: 10px;
    }
    &__notMakeText {
        margin-bottom: 15px;
    }
    &__baseTableItemNo {
        background: #cccccc;
        &--diff {
            background: #FF7B7B;
        }
    }
}
.fa-minus-circle {
    cursor: pointer;
    color: #FF7B7B;
}
</style>

