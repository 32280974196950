<template>
    <div>
        <el-row >
            <el-col class="leftBox" :span="1">
                <el-checkbox class="itemCheckBox" v-model="checkState" >{{number}}</el-checkbox>
            </el-col>

            <el-col class="contents" :span="21">
                <product-type v-if="isEditing" ref="dialogModule" :save="update" @close="closeMenu" :id="content.id" :input="content"  />
                <div v-else>
                    <el-row >
                        <span v-html="textBody(content.text_body)"></span>
                    </el-row>
                    <el-row v-if="showAddressLabel">
                        <p class="addressLabel"><a style="color: #409eff;" :href="content.geo_url" target="_blank"><i class="el-icon-location"></i>{{addressLabel}}</a></p>
                    </el-row>
                </div>
            </el-col>

            <el-col class="rightBox" :span="2" justify="end" v-if="!updateOrdersMode">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', resourceName, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>

    </div>
</template>
<script>

import FeedbackForm from './Components/FeedbackForm'
import ProductType from '../Form/ProductType'
import * as types from '../../../../../store/mutation-types'
import sanitizeHTML from 'sanitize-html'

export default {
    data () {
        return {
            isEditing: false,
        }
    },
    computed: {
        content() {
            return this.item.content
        },
        addressLabel() {
            return this.content.geo_title + ', ' + this.content.geo_location
        },
        showAddressLabel() {
            return Boolean(this.content.geo_title)
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        resourceName() {
            return 'products'
        },
    },
    components: {
        ProductType,
        FeedbackForm,
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check,item) {
            this.parentCheckItem(check,item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
        textBody(text_body) {
            return sanitizeHTML(text_body, {
                allowedTags: sanitizeHTML.defaults.allowedTags.concat(['span']),
                allowedAttributes: {
                    span: ['class'],
                },
            })
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>
<style scoped>
.leftBox {
    width: 45px;
}
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}
</style>
