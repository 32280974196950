<template>
    <div>
        <el-tag
            :disable-transitions="true"
            type="info"
            size="mini"
            closable
            @close="handleClose(index)"
            class="wordList"
            v-for='(keyword, index) in keywords'
            :key='index'
            >
            {{ keyword }}
        </el-tag>
        <el-autocomplete
            class="inputNewTag"
            v-if="inputVisible"
            v-model="inputTag"
            ref="saveInputTag"
            @select="handleSelect"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="true"
        />
        <el-button
            v-else
            width="100px"
            size="mini"
            class="wordList buttonNewTag"
            @click="showInput"
            >
            + 追加
        </el-button>
    </div>
</template>
<script>
export default {
    name: 'KeywordSelect',
    props: {
        keywords: Array
    },
    data () {
        return {
            inputTag:'',
            inputVisible: false,
        }
    },
    methods: {
        showInput () {
            this.inputVisible = true
            this.$nextTick(() => {
                this.$refs.saveInputTag.$refs.input.focus()
            })
        },
        handleInput() {
            if (this.inputTag
                && this.keywords.findIndex(item => item === this.inputTag) === -1
            ) {
                this.keywords.push(this.inputTag)
            }
            this.inputVisible = false
            this.inputTag = ''
        },
        handleClose(index) {
            this.keywords.splice(index, 1)
        },
        async querySearch(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/seo_keywords/suggest', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'SEOキーワード検索に失敗しました')
                    return
                }
                let suggestList = Object.assign([], data.seo_keywords)
                let findKeywords = suggestList.map(obj => obj.value)
                if (! findKeywords.includes(queryString)) {
                    suggestList.push({seo_keyword_id: null, value: queryString})
                }
                cb(suggestList)
            }
        },
        handleSelect(item) {
            this.inputTag = item.value
            this.handleInput()
        }

    }
}
</script>
<style scoped>
.inputNewTag {
    margin:5px 5px 0 0;
    vertical-align: bottom;
}
.wordList {
    margin:0 5px 5px 0;
    width: auto;
}
</style>
