<template>
    <div>
        <el-row>
            <el-col class="leftBox" :span="1">
                <el-col>
                <el-checkbox class="itemCheckBox" v-model="checkState" >{{number}}</el-checkbox>
                </el-col>
            </el-col>

            <el-col class="contents" :span="21">
                <heading-type
                    v-if="isEditing"
                    ref="dialogModule"
                    :save="update"
                    @close="closeMenu"
                    :id="content.id"
                    :content="content"
                    :headingTypeList="response.headingTypeList"
                />
                <div v-else>
                    <template v-if="content.decoration_type === 'recipe_link_item'">
                        <h2 v-if="content.heading_type === 'large'" class="headingItem__content--medium">
                            <i class="far fa-circle"></i>
                            {{content.heading_text}}
                        </h2>
                        <h3 v-else-if="content.heading_type === 'medium'" class="headingItem__content--medium">
                            <i class="far fa-circle"></i>
                            {{content.heading_text}}
                        </h3>
                        <h4 v-else-if="content.heading_type === 'small'" class="headingItem__content--medium">
                            <i class="far fa-circle"></i>
                            {{content.heading_text}}
                        </h4>
                    </template>
                    <template v-else>
                        <h2 v-if="content.heading_type === 'large'" class="headingItem__content--large">{{content.heading_text}}</h2>
                        <h3 v-if="content.heading_type === 'medium'" class="headingItem__content--medium">
                            <i class="far fa-circle"></i>
                            {{content.heading_text}}
                        </h3>
                        <h4 v-if="content.heading_type === 'small'" class="headingItem__content--small">{{content.heading_text}}</h4>
                    </template>
                </div>
            </el-col>


            <el-col class="rightBox" :span="2" justify="end" v-if="!updateOrdersMode">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', resourceName, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>

    </div>
</template>
<script>

import FeedbackForm from './Components/FeedbackForm'
import HeadingType from '../Form/HeadingType'
import * as types from '../../../../../store/mutation-types'

export default {
    data () {
        return {
            isEditing: false,
        }
    },
    computed: {
        content() {
            return this.item.content
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        resourceName() {
            return 'headings'
        },
    },
    components: {
        HeadingType,
        FeedbackForm,
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check,item) {
            this.parentCheckItem(check,item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>

<style lang="scss" scoped>
.leftBox {
    width: 45px;
}
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}

.headingItem {
    &__content {
        &--large {
            font-weight: 600;
            font-size: 16px;
            letter-spacing: .01em;
            padding-bottom: 6px;
            border-bottom: solid 2px #444;
            width: 100%;
            color: #444;
            margin:0;
        }
        &--medium {
            font-size: 16px;
            font-weight: 700;
            margin:0;
        }
        &--small {
            font-weight: 600;
            font-size: 15px;
            letter-spacing: .01em;
            color: #999;
            margin:0;
        }
    }
}

.fa-circle {
    font-size: 20px;
    vertical-align: middle;
    padding: 0 5px 0px 0;
    color: #999;
}
</style>
