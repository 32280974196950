<template>
    <el-card class="sectionCard" style="margin: 15px 0;">
        <el-tabs :stretch="true" >
            <el-tab-pane label="見出し" >
                <heading-type
                    class="itemAddBox"
                    :save="create"
                    @close="closeMenu"
                    :headingTypeList="response.headingTypeList" />
            </el-tab-pane>
            <el-tab-pane label="画像" >
                <image-type
                    class="itemAddBox"
                    :articleFormat="articleFormat"
                    :is-tieup="isTieup"
                    :save="create"
                    :saveAndContinue="createAndContinue"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane label="動画" >
                <video-type
                    class="itemAddBox"
                    :save="create"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane label="テキスト" >
                <text-type
                    class="itemAddBox"
                    :save="create"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane label="強調表示">
                <emphasis-type
                    class="itemAddBox"
                    :save="create"
                    :emphasis-item-color-list="response.emphasisItemColorList"
                    :emphasis-item-heading-tag-type-list="response.emphasisItemHeadingTagTypeList"
                    :emphasis-item-type-list="response.emphasisItemTypeList"
                    @close="closeMenu"
                />
            </el-tab-pane>
            <el-tab-pane label="アイテム情報">
                <information-type
                    class="itemAddBox"
                    :save="create"
                    :information-item-type-list="response.informationItemTypeList"
                    :information-item-other-label-list="response.informationItemOtherLabelList"
                    :information-item-table-body-evaluation="response.informationItemTableBodyEvaluation"
                    :information-items="response.informationItems"
                    :items="response.article.items"
                    @close="closeMenu"
                />
            </el-tab-pane>
            <el-tab-pane label="比較表">
                <comparison-type
                    class="itemAddBox"
                    :save="create"
                    :article-id="response.article.id"
                    :items="response.article.items"
                    :heading-different-information-items="response.headingDifferentInformationItems"
                    @close="closeMenu"
                />
            </el-tab-pane>
            <el-tab-pane label="テーブル">
                <table-type
                    class="itemAddBox"
                    :save="create"
                    :table-item-color-list="response.tableItemColorList"
                    @close="closeMenu"
                />
            </el-tab-pane>
            <el-tab-pane label="リンク" >
                <link-type
                    class="itemAddBox"
                    :articleFormat="articleFormat"
                    :save="create"
                    :saveAndContinue="createAndContinue"
                    @close="closeMenu"
                />
            </el-tab-pane>
            <el-tab-pane label="店舗情報" >
                <place-type
                    class="itemAddBox"
                    :save="create"
                    :article-id="response.article.id"
                    @close="closeMenu"
                />
            </el-tab-pane>
            <el-tab-pane label="アフィリエイト" >
                <affiliate-type
                    class="itemAddBox"
                    :save="create"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane label="引用" >
                <quote-type
                    class="itemAddBox"
                    :save="create"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane label="Pinterest" >
                <pinterest-type
                    class="itemAddBox"
                    :save="create"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane label="X" >
                <tweet-type
                    class="itemAddBox"
                    :save="create"
                    :saveAndContinue="createAndContinue"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane label="グッズ情報" >
                <product-type
                    class="itemAddBox"
                    :save="create"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane label="埋め込みタグ" >
                <embedded-type
                    class="itemAddBox"
                    :save="create"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane v-if="response.isShowRecipeLinkItem" label="レシピリンク">
                <recipe-link-type
                    class="itemAddBox"
                    :save="create"
                    :articleFormat="articleFormat"
                    @close="closeMenu"
                    :heading-type-options="response.headingTypeList"
                />
            </el-tab-pane>
            <el-tab-pane v-if="response.isShowRecipeSummaryItem" label="単一レシピ">
                <recipe-summary-type
                    class="itemAddBox"
                    :save="create"
                    :emphasis-item-type-list="response.emphasisItemTypeList"
                    :emphasis-item-heading-tag-type-list="response.emphasisItemHeadingTagTypeList"
                    :emphasis-item-color-list="response.emphasisItemColorList"
                    :article-format="articleFormat"
                    @close="closeMenu"
                />
            </el-tab-pane>
        </el-tabs>
    </el-card >
</template>

<script>
import HeadingType from '../Form/HeadingType'
import TextType from '../Form/TextType'
import ProductType from '../Form/ProductType'
import QuoteType from '../Form/QuoteType'
import AffiliateType from '../Form/AffiliateType'
import TweetType from '../Form/TweetType'
import PinterestType from '../Form/PinterestType'
import ImageType from '../Form/ImageType'
import PlaceType from '../Form/PlaceType'
import LinkType from '../Form/LinkType'
import VideoType from '../Form/VideoType'
import EmbeddedType from '../Form/EmbeddedType'
import EmphasisType from '../Form/EmphasisType'
import InformationType from '../Form/InformationType'
import ComparisonType from '../Form/ComparisonType'
import RecipeLinkType from '../Form/RecipeLinkType'
import TableType from '../Form/TableType'
import RecipeSummaryType from '../Form/RecipeSummaryType'

export default {
    components: {
        HeadingType,
        TextType,
        ProductType,
        QuoteType,
        AffiliateType,
        TweetType,
        PinterestType,
        ImageType,
        PlaceType,
        LinkType,
        VideoType,
        EmbeddedType,
        EmphasisType,
        InformationType,
        ComparisonType,
        RecipeLinkType,
        TableType,
        RecipeSummaryType,
    },
    props: {
        articleFormat: String,
        response: null,
        isTieup: Boolean,
    },
    data () {
        return {
            menu: false,
        }
    },
    methods: {
        showItemMenu() {
            this.$emit('show')
        },
        closeMenu () {
            if (!confirm('キャンセルしますか？')) {
                return
            }
            this.$emit('close')
        },
        async create (type, form) {
            this.$emit('create', type, form)
        },
        async createAndContinue (type, form) {
            this.$emit('createAndContinue', type, form)
        },
    },
}
</script>
