<template>
    <div>
        <el-row class="sumTable">
            <el-col>
                <el-table :data="sumTableData">
                    <el-table-column label="記事総文字数（プレス記事を除く）">
                        {{articleSumCharCount | number}}
                    </el-table-column>
                    <el-table-column label="リライト総文字数（プレス記事を除く）">
                        {{rewriteSumCharCount | number}}
                    </el-table-column>
                    <el-table-column label="記事数">
                        <template slot-scope="scope">
                            {{scope.row.articles_count | number}}
                        </template>
                    </el-table-column>
                    <el-table-column label="リライト数">
                        <template slot-scope="scope">
                            {{scope.row.rewrites_count | number}}
                        </template>
                    </el-table-column>
                    <el-table-column :render-header="renderTableHeader(['記事金額','(税込)'])">
                        <template slot-scope="scope">
                            {{scope.row.result.article | number}}円
                        </template>
                    </el-table-column>
                    <el-table-column :render-header="renderTableHeader(['リライト金額','(税込)'])">
                        <template slot-scope="scope">
                            {{scope.row.result.rewrite | number}}円
                        </template>
                    </el-table-column>
                    <el-table-column :render-header="renderTableHeader(['インセンティブ','(税込)'])">
                        <template slot-scope="scope">
                            {{scope.row.result.incentive | number}}円
                        </template>
                    </el-table-column>
                    <el-table-column :render-header="renderTableHeader(['店舗/商品/アイテム情報/比較表入力','(税込)'])">
                        <template slot-scope="scope">
                            {{scope.row.result.other | number}}円
                        </template>
                    </el-table-column>
                    <el-table-column :render-header="renderTableHeader(['合計金額','(税込)'])">
                        <template slot-scope="scope">
                            <b>{{scope.row.result.total | number}}円</b>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <h1 class="titleLabel">{{ articleTitle }}</h1>
        <el-row>
            <el-col >
                <el-table :data="tableData" max-height="800">
                    <el-table-column width="80" label="ID">
                        <template slot-scope="scope">
                             {{scope.row.id}}
                        </template>
                    </el-table-column>
                    <el-table-column width="500" label="タイトル">
                        <template slot-scope="scope">
                             <el-button class="titleLink" @click="showArticle(scope.row.id)" type="text">
                                 {{scope.row.title}}
                             </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="公開日">
                        <template slot-scope="scope">
                            {{showDate(scope.row.published_at)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="Org文字数(公開時、または支払いチェック時)">
                        <template slot-scope="scope">
                            {{scope.row.article_char_count.diff_char_count | number}}
                        </template>
                    </el-table-column>
                    <el-table-column label="単価種別">
                        <template slot-scope="scope">
                            <div>{{ scope.row.fee.show_unit_price_type }}</div>
                            <el-button type="primary" size="small" @click="openPaymentTypeModal(scope.row.fee)" style="padding: 5px 10px;">編集</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="記事単価">
                        <template slot-scope="scope">
                            {{ scope.row.fee.show_unit_price | number }}円
                        </template>
                    </el-table-column>
                    <el-table-column label="インセンティブ">
                        <template slot-scope="scope">
                            <div>{{ scope.row.fee.incentive | number }}円</div>
                            <el-button v-if="scope.row.fee.custom_incentive_histories.length !== 0" type="primary" size="small" style="padding: 5px 10px;" @click="openIncentiveModal(scope.row.fee.custom_incentive_histories)">詳細</el-button>
                            <el-button type="primary" size="small" @click="openArticleIncentiveModal(scope.row)" style="padding: 5px 10px;">編集</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="店舗/商品/アイテム情報/比較表入力">
                        <template slot-scope="scope">
                            <div>{{ scope.row.fee.other_price | number }}円</div>
                        </template>
                    </el-table-column>
<!--                    現状使用する用途がなさそうなので非表示-->
<!--                    以下詳細-->
<!--                    https://trustridge.slack.com/archives/CFXC2T9KP/p1579592092011100-->
<!--                    <el-table-column label="編集">-->
<!--                        <template slot-scope="scope">-->
<!--                            <el-button-->
<!--                                class="btn-edit"-->
<!--                                @click="editRow(scope.row)"-->
<!--                            >単価編集</el-button>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
               </el-table>
            </el-col>
        </el-row>
        <h1 class="titleLabel">{{ rewriteTitle }}</h1>
        <el-row>
            <el-col >
                <el-table :data="rewritesTableData" max-height="800">
                    <el-table-column width="80" label="ID">
                        <template slot-scope="scope">
                            {{scope.row.origin_article_id}}
                        </template>
                    </el-table-column>
                    <el-table-column width="500" label="タイトル">
                        <template slot-scope="scope">
                            <el-button class="titleLink" @click="showArticle(scope.row.origin_article.id)" type="text">
                                {{scope.row.origin_article.title}}
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="公開日">
                        <template slot-scope="scope">
                            {{showDate(scope.row.completed_at)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="リライト前Org文字数">
                        <template slot-scope="scope">
                            <span v-if="scope.row.payment_type === 'ARTICLE'"> - </span>
                            <span v-else>{{scope.row.article_char_count.base_char_count | number}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="リライト後Org文字数">
                        <template slot-scope="scope">
                            <span v-if="scope.row.payment_type === 'ARTICLE'"> - </span>
                            <span v-else>{{scope.row.article_char_count.payment_char_count | number}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="リライトした文字数">
                        <template slot-scope="scope">
                            <span v-if="scope.row.payment_type === 'ARTICLE'"> - </span>
                            <span v-else>{{scope.row.article_char_count.diff_char_count | number}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="単価種別">
                        <template slot-scope="scope">
                            <div v-if="scope.row.payment_type === 'ARTICLE'">記事固定の単価</div>
                            <div v-else>文字数による単価</div>
                            <el-button type="primary" size="small" @click="openRewritePaymentTypeModal(scope.row)" style="padding: 5px 10px;">編集</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="記事単価（税抜）">
                        <template slot-scope="scope">
                            {{showRewriteUnitPrice(scope.row) | number}}円
                        </template>
                    </el-table-column>
                    <el-table-column label="インセンティブ">
                        <template slot-scope="scope">
                            <div>{{ scope.row.rewrite_fee.incentive | number }}円</div>
                            <el-button v-if="scope.row.rewrite_fee.custom_incentive_histories.length !== 0" type="primary" size="small" style="padding: 5px 10px;" @click="openIncentiveModal(scope.row.rewrite_fee.custom_incentive_histories)">詳細</el-button>
                            <el-button type="primary" size="small" @click="openRewriteIncentiveModal(scope.row)" style="padding: 5px 10px;">編集</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="店舗/アイテム情報/比較表入力">
                        <template slot-scope="scope">
                            <div v-if="scope.row.payment_type === 'ARTICLE'">-</div>
                            <div v-else>{{ scope.row.rewrite_fee.other_price | number }}円</div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <edit-modal :save="save" :dialog="dialog" :closeDialog="closeDialog" :input="form" :grades="grades" />
        <incentive-modal
            :incentive-histories="incentiveHistories"
            :dialog="isIncentiveModal"
            @close="closeIncentiveModal"
            @deleteIncentiveHistory="deleteIncentiveHistory"
        ></incentive-modal>
        <incentive-edit-modal
            :dialog="isArticleIncentiveEditModal"
            :fee="editFee"
            :is-photo-incentive-target="editIsPhotoIncentiveTarget"
            @save="updateArticleFeeIncentive"
            @close="closeArticleIncentiveModal"
        ></incentive-edit-modal>
        <incentive-edit-modal
            :dialog="isRewriteIncentiveEditModal"
            :fee="editFee"
            :is-photo-incentive-target="editIsPhotoIncentiveTarget"
            @save="updateRewriteFeeIncentive"
            @close="closeRewriteIncentiveModal"
        ></incentive-edit-modal>
        <payment-type-edit-modal
            :dialog="isPaymentTypeModal"
            :article-fee="editArticleFee"
            :payment-types="paymentTypes"
            @close="closePaymentTypeModal"
            @save="updateArticleFeePaymentType"
        ></payment-type-edit-modal>
        <rewrite-payment-type-edit-modal
            :dialog="isRewritePaymentTypeEditModal"
            :rewrite-payment-types="rewritePaymentTypes"
            :rewrite="editRewrite"
            @close="closeRewritePaymentTypeModal"
            @save="updateRewritePaymentType"
        />
    </div>
</template>

<script>

import * as types from '../../../store/mutation-types'
import EditModal from './Article/EditModal.vue'
import IncentiveEditModal from './Article/IncentiveEditModal'
import PaymentTypeEditModal from './Article/PaymentTypeEditModal'
import RewritePaymentTypeEditModal from './Article/RewritePaymentTypeEditModal'
import moment from 'moment'
import IncentiveModal from './IncentiveModal'

export default {
    components: {
        EditModal,
        IncentiveModal,
        IncentiveEditModal,
        PaymentTypeEditModal,
        RewritePaymentTypeEditModal,
    },
    data() {
        return {
            form: {},
            targetId: null,
            dialog: false,
            grades: [],
            sumTableData: [],
            tableData: [],
            rewritesTableData: [],
            articleTitle: '',
            rewriteTitle: '',
            incentiveHistories: [],
            isIncentiveModal: false,
            isArticleIncentiveEditModal: false,
            isRewriteIncentiveEditModal: false,
            isRewritePaymentTypeEditModal: false,
            editFee: {},
            editIsPhotoIncentiveTarget: false,
            paymentTypes: [],
            isPaymentTypeModal: false,
            editArticleFee: null,
            articleCharCountExclusionPublishedWriterId: null,
            editRewrite: {},
            rewritePaymentTypes: [],
        }
    },
    created() {
        this.fetch()
    },
    computed: {
        articleSumCharCount() {
            const vue = this
            return this.tableData.reduce(function(prev, current) {
                // プレス記事は含まない
                if (current.publish_writer_id === vue.articleCharCountExclusionPublishedWriterId) {
                    return prev
                } else {
                    return prev + current.article_char_count.diff_char_count
                }
            }, 0)
        },
        rewriteSumCharCount() {
            const vue = this
            return this.rewritesTableData.reduce(function(prev, current) {
                // プレス記事、記事単価の記事は含まない
                if (current.origin_article.publish_writer_id === vue.articleCharCountExclusionPublishedWriterId || current.payment_type === 'ARTICLE') {
                    return prev
                } else {
                    return prev + current.article_char_count.diff_char_count
                }
            }, 0)
        },
    },
    methods: {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)
            const { data } = await axios.get('/api/writers/' + this.$route.params.writerId + '/fees/articles/' + this.$route.params.date)
            this.$store.commit(types.PAGE_LOADING, false)
            const writerLabel = data.writer.screen_name + '(' + data.writer.writer_name + ')'

            this.$store.commit(types.PAGE_TITLE, writerLabel + 'の' + this.$route.params.date + '月の料金情報')
            this.articleTitle = writerLabel + 'が' + this.$route.params.date + 'に作成した記事一覧'
            this.rewriteTitle = writerLabel + 'が' + this.$route.params.date + 'にリライトした記事一覧'

            this.sumTableData = []
            this.sumTableData.push({...data.fee})
            this.tableData = data.articles
            this.rewritesTableData = data.rewrites
            this.paymentTypes = data.payment_types
            this.articleCharCountExclusionPublishedWriterId = data.article_char_count_exclusion_published_writer_id
            this.rewritePaymentTypes = data.rewrite_payment_types
        },
        editRow (form) {
            this.form = Object.assign({}, form)
            this.dialog = true
        },
        showArticle (id) {
            this.$router.push('/articles/' + id)
        },
        showRewriteUnitPrice (form) {
            return form.rewrite_fee.rewrite_calc_unit_price
        },
        showDate (item) {
            return moment(item).format('YYYY-MM-DD')
        },
        async save (id, form) {
            this.$store.commit(types.LOADING, true)
            const { error} = await axios.patch('/api/writers/' + this.$route.params.writerId + '/fees/articles/' + id, form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            }
            else
            {
                await this.fetch()
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'データを変更しました')
            }
            this.$store.commit(types.LOADING, false)
        },
        handleCurrentChange (page) {
            this.$router.push({query: {page}})
            this.fetch()
        },
        closeDialog () {
            this.dialog = false
        },
        openIncentiveModal(incentiveHistories) {
            this.incentiveHistories = incentiveHistories
            this.isIncentiveModal = true
        },
        closeIncentiveModal() {
            this.isIncentiveModal = false
        },
        openArticleIncentiveModal(article) {
            this.editFee = article.fee
            this.editIsPhotoIncentiveTarget = article.is_photo_incentive_target
            this.isArticleIncentiveEditModal = true
        },
        closeArticleIncentiveModal() {
            this.isArticleIncentiveEditModal = false
        },
        openRewriteIncentiveModal(rewrite) {
            this.editFee = rewrite.rewrite_fee
            this.editIsPhotoIncentiveTarget = rewrite.is_photo_incentive_target
            this.isRewriteIncentiveEditModal = true
        },
        closeRewriteIncentiveModal() {
            this.isRewriteIncentiveEditModal = false
        },
        openRewritePaymentTypeModal(rewrite) {
            this.isRewritePaymentTypeEditModal = true
            this.editRewrite = {...rewrite}
        },
        closeRewritePaymentTypeModal() {
            this.isRewritePaymentTypeEditModal = false
        },
        async updateArticleFeeIncentive(articleFeeId, params) {
            this.$store.commit(types.LOADING, true)
            const { data, error } = await axios.put('/api/articles/fees/incentive/'+articleFeeId, params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'インセンティブの更新が完了しました')
            this.closeArticleIncentiveModal()
            await this.fetch()
            this.$store.commit(types.LOADING, false)
        },
        async updateRewriteFeeIncentive(rewriteFeeId, params) {
            this.$store.commit(types.LOADING, true)
            const { data, error } = await axios.put('/api/rewrites/fees/incentive/'+rewriteFeeId, params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'インセンティブの更新が完了しました')
            this.closeRewriteIncentiveModal()
            await this.fetch()
            this.$store.commit(types.LOADING, false)
        },
        async deleteIncentiveHistory(id) {
            this.$store.commit(types.LOADING, true)

            const { data, error } = await axios.delete('/api/incentives/histories/'+id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました')
            } else {
                this.closeIncentiveModal()
                await this.fetch()
                this.$store.commit(types.SHOW_INFO_MESSAGE, '削除しました')
            }

            this.$store.commit(types.LOADING, false)
        },
        openPaymentTypeModal(articleFee) {
            this.isPaymentTypeModal = true
            this.editArticleFee = articleFee
        },
        closePaymentTypeModal() {
            this.isPaymentTypeModal = false
        },
        async updateArticleFeePaymentType(articleFeeId, params) {
            this.$store.commit(types.LOADING, true)

            const { data, error } = await axios.put('/api/articles/fees/payment_type/'+articleFeeId, params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '支払い区分の更新が完了しました')
            this.closePaymentTypeModal()
            await this.fetch()
            this.$store.commit(types.LOADING, false)
        },
        async updateRewritePaymentType(rewriteId, params) {
            this.$store.commit(types.LOADING, true)

            const { data, error } = await axios.post(`/api/editing/rewrite/${rewriteId}/payment_type`, params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.closeRewritePaymentTypeModal()
            this.fetch()

            this.$store.commit(types.LOADING, false)
        }
    }
}
</script>

<style scoped>
    .sumTable {
        margin-bottom: 50px;
    }
    .titleLabel {
        font-weight: normal;
        font-size: 24px;
        margin: 60px 0 0.9em 0;
    }
    .titleLink {
        text-align: left;
        white-space: normal;
    }
</style>
