<template>
    <div>
        <el-row>
            <span>URL</span>
            <el-input class="url-input" placeholder="URL" v-model="url"></el-input>
            <span>期間</span>
            <el-date-picker
                v-model="dateRange"
                type="daterange"
                range-separator="〜"
                align="left"
                :default-value="defaultDate"
                start-placeholder="開始日"
                end-placeholder="終了日">
            </el-date-picker>
            <el-button class="search-button" type="primary" @click="fetch(url, dateRange)">検索</el-button>
        </el-row>
        <el-row>
            <el-button class="csv-button" type="primary" @click="downloadCsv(url, dateRange)">CSVエクスポート</el-button>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <el-table
            :data="urlList"
            style="width: 100%"
            max-height="800"
        >
            <el-table-column label="アイテム種別" prop="type"></el-table-column>
            <el-table-column label="記事内順序(目安)" prop="order_num"></el-table-column>
            <el-table-column label="引用URL" prop="quote_url"></el-table-column>
            <el-table-column label="記事ID" prop="id"></el-table-column>
            <el-table-column label="記事タイトル" prop="title"></el-table-column>
            <el-table-column label="記事カテゴリ" prop="category_name"></el-table-column>
            <el-table-column label="記事種別" prop="type_name"></el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
    </div>
</template>

<script>
import * as types from '../../../store/mutation-types'
import Pagination from '../../../components/Pagination'
import FileDownload from 'js-file-download'
import moment from 'moment'

export default {
    components: {
        Pagination,
    },
    data() {
        return {
            urlList: [],
            pagination: {},
            url: '',
            dateRange: '',
            defaultDate: new Date().setMonth(new Date().getMonth() - 1),
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '外部連携管理　>　引用済みURL一覧')
    },
    methods: {
        // 検索
        async fetch(url, dateRange) {
            const page = this.$route.query.page
            if (!url || !dateRange) {
                return
            }
            const from = moment(dateRange[0].getTime()).format('YYYY-MM-DD')
            const to = moment(dateRange[1].getTime()).format('YYYY-MM-DD')
            const params = { url, from, to, page }
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/quote/exports', {
                params: params
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.urlList = data.data
            this.pagination = Object.assign({}, this.pagination, data)
            this.$store.commit(types.LOADING, false)
        },
        // CSVダウンロード
        async downloadCsv(url, dateRange) {
            if (!url || !dateRange) {
                return
            }
            const from = moment(dateRange[0].getTime()).format('YYYY-MM-DD')
            const to = moment(dateRange[1].getTime()).format('YYYY-MM-DD')
            const params = {
                url: url,
                from: from,
                to: to,
            }

            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/quote/export/download', {
                params
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            FileDownload(data, url + '_' + from + '〜' + to + '.csv')
            this.$store.commit(types.LOADING, false)
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch(this.url, this.dateRange)
        },
    }
}
</script>

<style lang="scss" scoped>
    .csv-button {
        float: right;
        width: 150px;
    }
    .search-button {
        float: right;
        width: 150px;
    }
    .url-input {
        width: 500px;
    }
</style>
