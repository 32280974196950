<template>
    <div>
        <div class="actionMenu">
            <div class="actionMenu_item">
                <div class="actionMenu_head">ライターマスタ</div>
                <csv-upload
                    button-text="CSVアップロード"
                    name="writer"
                    url="/api/writers/import"
                    @afterCsvImportFetch="importCsvSuccess"
                />
                <csv-download
                    el-button-type="plain"
                    button-name="CSVダウンロード"
                    name="writers.csv"
                    url="/api/writers/export"
                />
            </div>
            <div class="actionMenu_item">
                <div class="actionMenu_head">資格マスタ</div>
                <csv-upload
                    button-text="CSVアップロード"
                    name="csv"
                    url="/api/licenses/import"
                    @afterCsvImportFetch="importCsvSuccess"
                />
                <csv-download
                    el-button-type="plain"
                    button-name="CSVダウンロード"
                    name="licenses.csv"
                    url="/api/licenses/export"
                />
            </div>
            <div class="actionMenu_item">
                <div class="actionMenu_head">資格/ライター紐付け</div>
                <csv-upload
                    button-text="CSVアップロード"
                    name="csv"
                    url="/api/writer_licenses/import"
                    @afterCsvImportFetch="importCsvSuccess"
                />
                <csv-download
                    el-button-type="plain"
                    button-name="CSVダウンロード"
                    name="writer_licenses.csv"
                    url="/api/writer_licenses/export"
                />
            </div>
        </div>

        <el-row type="flex" justify="end" style="margin-top:10px;width: 90%;">
            <el-input v-model="searchForm.writer_name" class="search" placeholder="アカウント名" @keyup.enter.native="search()">
            </el-input>
            <el-autocomplete
                class="search writer-window"
                placeholder="ライター窓口"
                v-model="searchForm.editor_name"
                :fetch-suggestions="querySearchAdmin"
                :trigger-on-focus="true"
                @keyup.enter.native="search()"
            >
            </el-autocomplete>
            <el-input v-model="searchForm.license" class="search" placeholder="資格" @keyup.enter.native="search()">
            </el-input>
        </el-row>
        <el-row type="flex" justify="end" style="margin-top:10px;width: 90%;">
            <el-select v-model="searchForm.category_ids" class="search" placeholder="執筆カテゴリ" multiple>
                <el-option
                    v-for="category in categories"
                    :key="category.id"
                    :label="category.category_name"
                    :value="category.id">
                </el-option>
            </el-select>
            <el-select placeholder="所属チーム" class="search" v-model="searchForm.writer_team_id">
                <el-option
                    v-for="writerTeam in selectWriterTeams"
                    :key="writerTeam.id"
                    :label="writerTeam.name"
                    :value="writerTeam.id">
                </el-option>
            </el-select>
            <el-select v-model="searchForm.photo_rank_ids" class="search" placeholder="撮影ランク" multiple>
                <el-option
                    v-for="photoRank in photoRanks"
                    :key="photoRank.id"
                    :label="photoRank.name"
                    :value="photoRank.id">
                </el-option>
            </el-select>
            <el-checkbox true-label="1" false-label="0" v-model="searchForm.can_rewrite" class="search-check">リライト可</el-checkbox>
            <el-checkbox true-label="1" false-label="0" v-model="searchForm.can_macaronimate" class="search-check">メイト</el-checkbox>
            <el-checkbox true-label="1" false-label="0" v-model="searchForm.can_supervisor" class="search-check">監修者</el-checkbox>
            <el-checkbox true-label="1" false-label="0" v-model="searchForm.can_cook_specialist" class="search-check">料理家</el-checkbox>
            <el-checkbox true-label="1" false-label="0" v-model="searchForm.can_payee" class="search-check">支払い対象者</el-checkbox>
            <el-button @click="search()" icon="el-icon-search" class="search-input"></el-button>
        </el-row>
        <el-row type="flex" justify="end" style="margin: 15px 5px 0 0; align-items: center;">

        </el-row>
        <el-tabs type="card" @tab-click="tabClick" v-model="currentTabName">
            <el-tab-pane label="有効ライター一覧" :name="tabName.valid">
                <valid
                    :propWriters="validWriters"
                    :writer-teams="selectWriterTeams"
                    :prefecture="selectPrefecture"
                    :categories="categories"
                    :adminSuggestList="adminSuggestList"
                    :photoRanks="photoRanks"
                    :licenses="licenses"
                    @updateWriter="updateWriter"
                    @close="close"
                    @destroy="destroy"
                ></valid>
            </el-tab-pane>
            <el-tab-pane label="無効ライター一覧" :name="tabName.invalid">
                <invalid
                    :writers="invalidWriters"
                    @enable="enable"
                ></invalid>
            </el-tab-pane>
        </el-tabs>
        <pagination :handleCurrentChange="changePagination" :pagination="usePagination" />
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import { PAGE_TITLE , LOADING } from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import Valid from './Writer/Valid'
import Invalid from './Writer/Invalid'
import FileDownload from 'js-file-download'
import CsvUpload from '../../components/CsvUpload'
import CsvDownload from '../../components/CsvDownload'

export default {
    components: { Pagination, Valid, Invalid, CsvUpload, CsvDownload },
    created() {
        this.$store.commit(PAGE_TITLE, 'アカウント　>　ライター管理')
        var pair = location.search.substring(1).split('&')
        for(var i=0;pair[i];i++) {
            var kv = pair[i].split('=')
            if (kv[0] == 'writer_name') {
                this.searchForm.writer_name = decodeURIComponent(kv[1])
            }
            break
        }

        this.currentTabName = this.tabName.valid
        this.fetchValid()
        this.fetchInvalid()
        this.queryInit()
    },
    data() {
        return {
            validWriters: [],
            invalidWriters: [],
            searchForm: {
                writer_name: '',
                editor_name:'',
                category_ids:[],
                photo_rank_ids:[],
                license: '',
                writer_team_id: '',
                can_rewrite: 0,
                can_macaronimate: 0,
                can_supervisor: 0,
                can_cook_specialist: 0,
                can_payee: 0
            },
            usePagination: {},
            pagination: {
                valid: {},
                invalid: {},
            },
            tabName: {
                valid: 'valid',
                invalid: 'invalid',
            },
            currentTabName: '',
            currentPage: {
                valid: 1,
                invalid: 1,
            },
            selectWriterTeams: [],
            selectPrefecture:  [],
            categories: [],
            adminSuggestList: [],
            admins: [],
            photoRanks: [],
            licenses: [],
        }
    },
    methods: {
        // 有効ユーザー取得
        async fetchValid() {
            this.$store.commit(LOADING, true)

            const { data, error } = await axios.get('/api/writers', {
                params: { ...this.$route.query }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            const datalist = Object.entries(data.writers.data).map(([key, data]) => {
                let writerFee = null
                let photoRankName = null

                if (data.fees !== undefined && data.fees.length > 0) {
                    writerFee = data.fees.slice(-1)[0]
                }
                if (writerFee) {
                    if (writerFee.writer_photo_rank_id && writerFee.writer_photo_rank.name !== undefined && writerFee.writer_photo_rank.name.length > 0) {
                        photoRankName = writerFee.writer_photo_rank.name
                    }
                }

                return {
                    id: data.id,
                    writer_name: data.writer_name,
                    screen_name: data.screen_name,
                    category: data.category,
                    strong_field: data.strong_field,
                    history: data.history,
                    memo: data.memo,
                    prefecture_id: data.prefecture_id,
                    fee: writerFee,
                    photo_rank: photoRankName,
                    created_at: data.created_at,
                    writer_team_id: data.writer_team_id,
                    editor_admin_id: data.editor_admin_id,
                    can_serialization: data.can_serialization,
                    can_published_edit: data.can_published_edit,
                    can_rewrite: data.can_rewrite,
                    can_macaronimate: data.can_macaronimate,
                    can_supervisor: data.can_supervisor,
                    can_cook_specialist: data.can_cook_specialist,
                    can_payee: data.can_payee,
                    can_repro: data.can_repro,
                    can_press: data.can_press,
                    editor_admin_name: data.management_admin ? data.management_admin.name : null,
                    editor_admin_editor_name: data.management_admin ? data.management_admin.editor_name : null,
                    writer_licenses: structuredClone(data.writer_licenses),
                }
            })
            this.validWriters = datalist
            this.pagination.valid = Object.assign({}, data.writers)
            this.usePagination = this.pagination.valid
            this.selectWriterTeams = data.writer_teams
            this.selectPrefecture = data.prefectures
            this.categories = data.categories
            this.photoRanks = data.photo_ranks

            this.admins = Object.entries(data.admins).map(([key, data]) => {
                return {
                    id: data.id,
                    name: data.editor_name ? data.editor_name : data.name
                }
            })
            this.adminSuggestList = Object.assign([],this.admins).map((admin) => {return {value:admin.name}}).filter(admin => admin)
            this.licenses = structuredClone(data.licenses)
            this.$store.commit(LOADING, false)
        },
        querySearchAdmin(queryString, cb) {
            const values = this.adminSuggestList
            const results = queryString ? values.filter(this.createFilter(queryString)) : values
            cb(results)
        },
        createFilter(queryString) {
            return (item) => {
                return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
            }
        },
        handleSelectWriter (item) {
            this.admin = item.value
        },
        // 無効ユーザー取得
        async fetchInvalid() {
            this.$store.commit(LOADING, true)

            const { data, error } = await axios.get('/api/writers/invalid', {
                params: { ...this.$route.query }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            this.invalidWriters = data.data
            this.pagination.invalid = Object.assign({}, data)
            this.usePagination = this.pagination.invalid
            this.$store.commit(LOADING, false)
        },
        // 検索
        async search() {
            const page = 1
            const query = Object.assign({}, this.$route.query,{
                writer_name: this.searchForm.writer_name,
                license: this.searchForm.license,
                category_ids: this.searchForm.category_ids,
                photo_rank_ids: this.searchForm.photo_rank_ids,
                writer_team_id: this.searchForm.writer_team_id,
                editor_name:this.searchForm.editor_name,
                can_rewrite: this.searchForm.can_rewrite,
                can_macaronimate: this.searchForm.can_macaronimate,
                can_cook_specialist: this.searchForm.can_cook_specialist,
                can_supervisor: this.searchForm.can_supervisor,
                can_payee: this.searchForm.can_payee,
                page,
            })
            this.$router.push({ query })
            await this.fetchInvalid()
            await this.fetchValid()
            this.currentPage.valid = page
            this.currentPage.invalid = page
        },
        queryInit() {
            this.searchForm.license = this.$route.query.license ? this.$route.query.license : null
            this.searchForm.writer_team_id = this.$route.query.writer_team_id ? Number(this.$route.query.writer_team_id) : null
            this.searchForm.editor_name = this.$route.query.editor_name ? this.$route.query.editor_name : null
            this.searchForm.can_rewrite = this.$route.query.can_rewrite ? this.$route.query.can_rewrite : null
            this.searchForm.can_macaronimate = this.$route.query.can_macaronimate ? this.$route.query.can_macaronimate : null
            this.searchForm.can_cook_specialist = this.$route.query.can_cook_specialist ? this.$route.query.can_cook_specialist : null
            this.searchForm.can_supervisor = this.$route.query.can_supervisor ? this.$route.query.can_supervisor : null
            this.searchForm.can_payee = this.$route.query.can_payee ? this.$route.query.can_payee : null
            if (this.$route.query.category_ids) {
                this.searchForm.category_ids = this.$route.query.category_ids.map(function (value) {
                    return Number(value)
                })
            }
            if (this.$route.query.photo_rank_ids) {
                this.searchForm.photo_rank_ids = this.$route.query.photo_rank_ids.map(function (value) {
                    return Number(value)
                })
            }
        },
        // ページャー遷移（ページャー番号保持するため）
        async changePagination(page = 1) {
            const query = Object.assign({}, this.$route.query,{
                writer_name: this.searchForm.writer_name,
                license: this.searchForm.license,
                category_ids: this.searchForm.category_ids,
                photo_rank_ids: this.searchForm.photo_rank_ids,
                writer_team_id: this.searchForm.writer_team_id,
                editor_name:this.searchForm.editor_name,
                can_rewrite: this.searchForm.can_rewrite,
                can_macaronimate: this.searchForm.can_macaronimate,
                can_cook_specialist: this.searchForm.can_cook_specialist,
                can_supervisor: this.searchForm.can_supervisor,
                can_payee: this.searchForm.can_payee,
                page,
            })
            this.$router.push({ query })

            if (this.currentTabName === this.tabName.valid) {
                await this.fetchValid()
                this.currentPage.valid = page
            } else if (this.currentTabName === this.tabName.invalid) {
                await this.fetchInvalid()
                this.currentPage.invalid = page
            }
        },
        // タブクリック時
        tabClick(tab, event) {
            if (tab.name === this.tabName.valid) {
                this.usePagination = this.pagination.valid
                this.currentTabName = this.tabName.valid

                const query = Object.assign({}, this.$route.query, {
                    writer_name: this.searchForm.writer_name,
                    license: this.searchForm.license,
                    category_ids: this.searchForm.category_ids,
                    photo_rank_ids: this.searchForm.photo_rank_ids,
                    writer_team_id: this.searchForm.writer_team_id,
                    editor_name:this.searchForm.editor_name,
                    can_rewrite: this.searchForm.can_rewrite,
                    can_macaronimate: this.searchForm.can_macaronimate,
                    can_cook_specialist: this.searchForm.can_cook_specialist,
                    can_supervisor: this.searchForm.can_supervisor,
                    can_payee: this.searchForm.can_payee,
                    page: this.currentPage.valid,
                })
                this.$router.push({ query })
            } else if (tab.name === this.tabName.invalid) {
                this.usePagination = this.pagination.invalid
                this.currentTabName = this.tabName.invalid

                const query = Object.assign({}, this.$route.query, {
                    writer_name: this.searchForm.writer_name,
                    license: this.searchForm.license,
                    category_ids: this.searchForm.category_ids,
                    photo_rank_ids: this.searchForm.photo_rank_ids,
                    writer_team_id: this.searchForm.writer_team_id,
                    editor_name:this.searchForm.editor_name,
                    can_rewrite: this.searchForm.can_rewrite,
                    can_macaronimate: this.searchForm.can_macaronimate,
                    can_cook_specialist: this.searchForm.can_cook_specialist,
                    can_supervisor: this.searchForm.can_supervisor,
                    can_payee: this.searchForm.can_payee,
                    page: this.currentPage.invalid,
                })
                this.$router.push({ query })
            }
        },
        // 編集者CSVインポート成功時
        async importCsvSuccess() {
            await this.fetchInvalid()
            await this.fetchValid()
            alert('更新が完了しました')
        },
        // 無効ライターに
        async destroy(id) {
            if (!confirm('無効ライターにしますか？')) {
                return
            }
            this.$store.commit(LOADING, true)
            const { error } = await axios.delete('/api/writers/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '失敗しました。')
                this.$store.commit(LOADING, false)
                return
            }
            this.$store.commit(LOADING, false)

            await this.fetchInvalid()
            await this.fetchValid()
        },
        // 無効ライターの再有効化
        async enable(id) {
            if (!confirm(`ライターID:${id}を再有効化しますか？`)) {
                return
            }
            this.$store.commit(LOADING, true)
            const { error } = await axios.post(`/api/writers/${id}/enable`)
            this.$store.commit(LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '更新に失敗しました。')
                return
            }

            await this.fetchInvalid()
            await this.fetchValid()
        },
        // ライター更新
        async updateWriter(params) {
            this.$store.commit(LOADING, true)
            const { error } = await axios.patch('/api/writers/'+params.id, params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }
            location.reload()

            this.$store.commit(LOADING, false)

        },
        close() {
            // el-checkbox-groupの描画がリロードかけないとうまくいかないので
            location.reload()
        }
    }
}
</script>

<style lang="scss" scoped>
    .actionMenu {
      display: flex;
      justify-content: flex-end;
      gap: 15px;
    }
    .actionMenu_head {
      text-align: center;
    }
    .actionMenu_item {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .search {
        margin: 10px 5px 0;
    }
    .search-check {
        margin: 20px 40px 0 0; align-items: center;
    }
    .writer-window {
        width: 500px;
    }
</style>
