<template>
</template>
<script>

export default {
    name:'CheckNgWords',
    data () {
        return {
            ngWord: '',
            ngWordMessage: '',
            ngWordList: [],
        }
    },
    methods: {
        perform(ngWords, article) {
            this.ngWordMessage = ''
            this.ngWordList = ngWords.map(function(ngWord) {
                return ngWord.ng_word
            })

            // 記事タイトル
            this.searchWord(article.title, '【タイトル】')
            // 記事ディスクリプション
            this.searchWord(article.description, '【ディスクリプション】')

            for (let [key, item] of Object.entries(article.items)) {
                key = Number(key) + 1

                switch (item.item_type) {
                case 'TextType':
                    // テキストアイテム
                    this.searchWord(item.content.text_body, key, '【テキスト】')
                    break
                case 'ImageType':
                    // 画像アイテム タイトル
                    this.searchWord(item.content.image_title, key, '【画像】タイトル')
                    // 画像アイテム コメント
                    this.searchWord(item.content.image_comment, key, '【画像】コメント')
                    // 画像アイテム alt属性
                    this.searchWord(item.content.alt_text, key, '【画像】alt属性')
                    break
                case 'QuoteType':
                    // 引用アイテム
                    this.searchWord(item.content.quote_text, key, '【引用】')
                    break
                case 'HeadingType':
                    // 見出しアイテム
                    this.searchWord(item.content.heading_text, key, '【見出し】')
                    break
                case 'LinkType':
                    switch (item.content.link_type) {
                    case 'text':
                        // リンクアイテム テキストリンク タイトル
                        this.searchWord(item.content.link_title, key, '【リンク】テキスト')
                        // リンクアイテム テキストリンク ディスクリプション
                        this.searchWord(item.content.link_description, key, '【リンク】ディスクリプション')
                        break
                    case 'button':
                        // リンクアイテム ボタンリンク タイトル
                        this.searchWord(item.content.link_title, key, '【リンク】ボタンタイトル')
                        // リンクアイテム ボタンリンク ディスクリプション
                        this.searchWord(item.content.link_description, key, '【リンク】ボタンディスクリプション')
                        break
                    case 'thumbnail':
                        // リンクアイテム サムネイルリンク 画像alt
                        this.searchWord(item.content.link_comment, key, '【リンク】画像alt')
                        // リンクアイテム サムネイルリンク タイトル
                        this.searchWord(item.content.link_title, key, '【リンク】サムネイルタイトル')
                        // リンクアイテム サムネイルリンク ディスクリプション
                        this.searchWord(item.content.link_description, key, '【リンク】サムネイルディスクリプション')
                        break
                    }
                    break
                case 'ShopType':
                    for (const element of item.content.elements) {
                        // 店舗情報アイテム 項目名
                        this.searchWord(element.name, key, '【店舗情報】項目名')
                        // 店舗情報アイテム 内容
                        this.searchWord(element.value, key, '【店舗情報】内容')
                    }
                    break
                case 'VideoType':
                    // 動画アイテム コメント
                    this.searchWord(item.content.video_comment, key, '【動画】')
                    break
                case 'AffiliateType':
                    // アフィアイテム 商品名
                    this.searchWord(item.content.affi_name, key, '【アフィ】商品名')
                    // アフィアイテム 商品説明
                    this.searchWord(item.content.affi_description, key, '【アフィ】商品説明')
                    break
                default:
                }
            }
            return this.ngWordMessage.replace(/　/g, '[全角スペース]').replace(/\n\n\n/g, '[改行3連続]')
        },
        searchWord(string, key, ngWordMessage = null) {
            for (let [index, ngWord] of Object.entries(this.ngWordList)) {
                if (string !== null && string.indexOf(ngWord) !== -1) {
                    if (ngWordMessage) {
                        this.ngWordMessage += key + ':' + ngWordMessage + '：' + ngWord + '\n'
                    } else {
                        this.ngWordMessage += key + ':' + ngWord + '\n'
                    }
                }
            }
        }
    }
}

</script>

