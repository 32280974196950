<template>
    <div class="recipeForm">
        <div v-if="id === null" class="recipeForm__caution">※同時に見出しアイテムも生成されます</div>
        <el-form class="recipeForm__contents" @submit.native.prevent>
            <el-form-item v-if="!id" label="大見出し" required>
                <el-input
                    v-model="form.heading"
                    type="text"
                    size="mini"
                    placeholder="大見出し"
                ></el-input>
                <text-length-count :targetText="form.heading" :maxTextLength="100" />
            </el-form-item>
            <el-form-item v-for="(element, idx) in form.elements" :key="idx">
                <el-row type="flex" class="recipeForm__recipeElementWrapper">
                    <span v-if="isPreparation" class="recipeForm__recipeIcon">下</span>
                    <span v-else class="recipeForm__recipeIcon">{{ idx + 1 }}</span>
                    <span v-if="!isPreparation" class="required"></span>
                    <el-input
                        v-model="element.recipe_text"
                        type="text"
                        size="mini"
                        :placeholder="`${resourceText}中見出し`"
                        class="recipeForm__recipeDescription"
                    >
                    </el-input>
                    <text-length-count :targetText="element.recipe_text" :maxTextLength="100" />
                    <el-row v-if="! isPreparation" type="flex" class="recipeForm__recipeElementActionWrapper">
                        <i @click="recipeElementMoveUp(element, idx)" class="el-icon-arrow-up recipeForm__recipeElementActionIcon"></i>
                        <i @click="recipeElementMoveDown(element, idx)" class="el-icon-arrow-down recipeForm__recipeElementActionIcon"></i>
                        <i @click="recipeElementRemove(element, idx)" class="el-icon-close recipeForm__recipeElementActionIcon"></i>
                    </el-row>
                </el-row>
                <el-row>
                    <div v-for="(elementContent, elementContentIdx) in element.contents" :key="elementContentIdx" class="recipeForm__recipeElementContentList">
                        <el-upload
                            class="avatar-uploader recipeForm__recipeElementContentImage"
                            :auto-upload="false"
                            action=""
                            :on-change="(file) => handleRecipeElementContentImageAdd(file, idx, elementContentIdx)"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif"
                        >
                            <img v-if="elementContent.image_cdn_url" :src="elementContent.image_cdn_url" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <el-row class="recipeForm__recipeElementContentsWrapper">
                            <el-col :span="24">
                                <wysiwyg-editor
                                    v-model="elementContent.text"
                                    editorViewStyle="height: 7em;resize: vertical;overflow-y: scroll;word-break: break-all;"
                                    class="recipeForm__recipeElementContents"
                                    placeholder="補足コメント"
                                />
                            </el-col>
                            <el-col :span="24">
                                <el-input
                                    type="text"
                                    size="mini"
                                    v-model="elementContent.alt_text"
                                    class="recipeForm__recipeElementContents"
                                    placeholder="alt属性"
                                />
                            </el-col>
                            <el-col :span="24">
                                <el-input
                                    type="text"
                                    size="mini"
                                    v-model="elementContent.photo_by"
                                    class="recipeForm__recipeElementContents"
                                    placeholder="photoBy"
                                />
                            </el-col>
                        </el-row>
                        <el-row v-if="! isPreparation" type="flex" class="recipeForm__recipeElementDeleteWrapper">
                            <i @click="deleteRecipeElementContent(idx, elementContentIdx)" class="el-icon-close recipeForm__recipeElementActionIcon"></i>
                        </el-row>
                    </div>
                    <el-button v-if="! isPreparation" @click="addRecipeElementContent(idx)" type="primary" size="mini">要素を追加する</el-button>
                </el-row>
            </el-form-item>
            <el-form-item v-if="! isPreparation">
                <el-button @click="addRecipeElement()" type="primary" size="mini">作り方を追加する</el-button>
            </el-form-item>
        </el-form>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini" @click="submitRecipeForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
    </div>
</template>

<script>

import * as types from "../../../../../../store/mutation-types";
import WysiwygEditor from '../Components/WysiwygEditor'
import TextLengthCount from '../Components/TextLengthCount'

const defaultFormValues = () => ({
    heading: '',
    is_preparation: false,
    elements: []
})
const defaultElementFormValues = () => ({
    id: null,
    recipe_text: null,
    contents: [],
})
const defaultElementContentFormValues = () => ({
    id: null,
    image_file: null,
    alt_text: null,
    photo_by: null,
    text: null,
})

export default {
    components: {
        WysiwygEditor,
        TextLengthCount
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        resourceName: {
            required: true,
            type: String,
        },
        isPreparation: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            form: {},
        }
    },
    created() {
        this.form = defaultFormValues()
        this.form.heading = this.resourceText
        this.$set(this.form, 'elements', [])
        this.form.elements.push(defaultElementFormValues())
        this.$set(this.form.elements[0], 'contents', [])
        this.form.elements[0].contents.push(defaultElementContentFormValues())
    },
    computed: {
        resourceText() {
            return this.isPreparation ? '下ごしらえ' : '作り方'
        }
    },
    methods: {
        submitRecipeForm() {
            const fd = new FormData()
            fd.append('heading', this.form.heading)
            fd.append('is_preparation', this.isPreparation ? 1 : 0)
            this.form.elements.map((element, idx) => {
                fd.append(`elements[${idx}][id]`, element.id ?? '')
                fd.append(`elements[${idx}][recipe_text]`, element.recipe_text ?? '')
                element.contents.map((elementContent, elementContentIdx) => {
                    fd.append(`elements[${idx}][contents][${elementContentIdx}][id]`, elementContent.id ?? '')
                    fd.append(`elements[${idx}][contents][${elementContentIdx}][image_file]`, elementContent.image_file ?? '')
                    fd.append(`elements[${idx}][contents][${elementContentIdx}][alt_text]`, elementContent.alt_text ?? '')
                    fd.append(`elements[${idx}][contents][${elementContentIdx}][photo_by]`, elementContent.photo_by ?? '')
                    fd.append(`elements[${idx}][contents][${elementContentIdx}][text]`, elementContent.text ?? '')
                })
            })

            this.save(this.resourceName, fd, this.id)
        },
        openDialog(input) {
            this.form = {...input}
        },
        recipeElementMoveDown(element, idx) {
            const movedIdx = idx + 1
            if (this.form.elements[movedIdx]) {
                this.form.elements.splice(idx, 2, this.form.elements[movedIdx], this.form.elements[idx])
            }
        },
        recipeElementMoveUp(element, idx) {
            if (idx > 0) {
                const movedIdx = idx - 1
                this.form.elements.splice(movedIdx, 2, this.form.elements[movedIdx+1], this.form.elements[movedIdx])
            }
        },
        recipeElementRemove(element, idx) {
            if (this.form.elements.length <= 1) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '最低1つ必要です。')
                return
            }
            this.form.elements.splice(idx, 1)
        },
        handleRecipeElementContentImageAdd(file, idx, contentIdx) {
            this.$set(this.form.elements[idx].contents[contentIdx], 'image_file', file.raw)
            this.$set(this.form.elements[idx].contents[contentIdx], 'image_cdn_url', URL.createObjectURL(file.raw))
            this.fetchedImage = true
        },
        addRecipeElementContent(idx) {
            this.form.elements[idx].contents.push(defaultElementContentFormValues())
        },
        deleteRecipeElementContent(idx, contentIdx) {
            if (this.form.elements[idx].contents.length <= 1) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '最低1つ必要です。')
                return
            }
            this.form.elements[idx].contents.splice(contentIdx, 1)
        },
        addRecipeElement() {
            const addValue = defaultElementFormValues()
            this.$set(addValue, 'contents', [])
            addValue.contents.push(defaultElementContentFormValues())
            this.form.elements.push(addValue)
        },
    }
}
</script>

<style scoped lang="scss">
.recipeForm {
    &__contents {
        margin-bottom: 15px;
    }
    &__recipeElementWrapper {
        margin-bottom: 10px;
    }
    &__recipeIcon {
        margin: 0 15px 0 0;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        background-color: #E3D099;
        display: inline-block;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        box-sizing: content-box;
        padding: 5px;
        border-radius: 50%;
    }
    &__recipeDescription {
        width: 300px;
    }
    &__recipeElementActionWrapper {
        width: 150px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        margin: 0 20px;
        cursor: pointer;
    }
    &__recipeElementActionIcon {
        width: 50px;
        height: 100%;
        background: #F5F7FA;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8F9398;
        cursor: pointer;
    }
    &__recipeElementContentList {
        display: flex;
        margin-bottom: 10px;
    }
    &__recipeElementContentImage {
        margin-right: 15px;
    }
    &__recipeElementContentsWrapper {
        margin-right: 5px;
        flex-grow: 1;
    }
    &__recipeElementContents {
        margin-bottom: 5px;
    }
    &__recipeElementDeleteWrapper {
        width: 50px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
    }
    &__caution {
        font-size: 12px;
        color: red;
    }
}
.required::before {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
}

</style>
