<template>
    <div>
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent label-width="120px" >
            <el-form-item label="グッズ情報" >
                <editor-content :editor="editor" />
            </el-form-item>
            <el-row style="margin-left: 120px;">
                <el-button
                    type="text"
                    size="mini"
                    @click="() => this.mapDialog = true">位置情報を追加</el-button>
            </el-row>
        </el-form>
        <p v-if="showAddressLabel" class="addressLabel"><a style="color: #409eff;" :href="form.geo_url" target="_blank"><i class="el-icon-location"></i>{{addressLabel}}</a></p>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini"  @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
        <el-dialog
            title="位置情報入力"
            @close="() => this.mapDialog = false"
            :visible="mapDialog"
            height="50%"
            append-to-body
            >
            <map-form
                @save="(mapInfo) => {
                    this.saveAddress(mapInfo)
                    this.mapDialog = false
                }"
            />
        </el-dialog>
    </div>
</template>

<script>
import MapForm from './Components/Map'
import {Editor, EditorContent, Extension} from 'tiptap'
import {HardBreak} from 'tiptap-extensions'
const defaultFormValues =  {
    text_body:'',
    geo_location:null,
    geo_title:null,
    geo_url:null,
}
export default {
    components: {
        MapForm,
        EditorContent,
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
    },
    computed: {
        addressLabel() {
            return this.form.geo_title + ', ' + this.form.geo_location
        },
        showAddressLabel() {
            return Boolean(this.form.geo_title)
        },
        resourceName() {
            return 'products'
        },
    },
    data () {
        return {
            mapDialog:false,
            form: {...defaultFormValues},
            editor: new Editor({
                onUpdate: ({getHTML}) => {
                    this.form.text_body = getHTML().replace(/^<p>/, '').replace(/<\/p>$/, '')
                },
                extensions: [
                    new HardBreak(),
                    new class extends Extension {
                        keys() {
                            return {
                                Enter(state, dispatch, view) {
                                    const {schema, doc, tr} = view.state
                                    const hard_break = schema.nodes.hard_break
                                    const transaction = tr.replaceSelectionWith(hard_break.create()).scrollIntoView()
                                    view.dispatch(transaction)
                                    return true
                                }
                            }
                        }
                    }(),
                ],
                content: ' 【仕様】<br>■サイズ：<br>■容量：<br>■重量：<br>■材質：<br>■カラー：<br>■備考：<br>■価格： ',
            })
        }
    },
    methods: {
        saveAddress({searchKeyword, formattedAddress}) {
            this.form.geo_title = searchKeyword
            this.form.geo_location = formattedAddress
        },
        submitForm () {
            this.save(this.resourceName, this.form, this.id)
        },
        openDialog(input) {
            this.form = Object.assign({}, defaultFormValues, _.pick(input, Object.keys(this.form)))
            this.editor.setContent(this.form.text_body)
        },
    },
}
</script>

<style>
.el-form-item__content li {
    display: list-item !important;
    list-style: disc !important;
    margin-left:20px;
}
.gmap {
    height:500px;
    width:500px;
}
</style>
<style scoped>
.addressLabel {
    margin-left:120px;
}
</style>
