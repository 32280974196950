<template>
    <div>
        <div class="csvActions">
            <div class="csvActions_group">
                <csv-upload name="upload_direction" url="/api/directions/csv" button-text="ディレクションUP" @afterCsvImportFetch="fetch" />
                <csv-download name="direction_format.csv" url="/api/directions/csv" button-name="フォーマットDL" />
            </div>
            <div class="csvActions_group">
                <csv-upload name="csv" url="/api/seo_keywords/import_relations" button-text="SEO-KW UP" @afterCsvImportFetch="fetch" />
                <csv-download name="seo_keyword_relationships.csv" url="/api/seo_keywords/export_relations" button-name="フォーマットDL" />
            </div>
        </div>
        <el-row type="flex" justify="end" style="margin-top:10px;">
            <el-select placeholder="ステータスでフィルタ" v-model="status" class="search">
                 <el-option
                     v-for="(item) in statusFilterList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                 </el-option>
            </el-select>
            <el-select placeholder="カテゴリでフィルタ" v-model="category" class="search">
                 <el-option
                     v-for="(item) in categoryFilterList"
                     :key="item.id"
                     :label="item.category_name"
                     :value="item.id">
                 </el-option>
            </el-select>
            <el-select placeholder="記事種別でフィルタ" v-model="articleType" class="search">
                <el-option
                    v-for="(item) in articleTypeFilterList"
                    :key="item.id"
                    :label="item.type_name"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select placeholder="フォーマットでフィルタ" v-model="format" class="search">
                <el-option
                    v-for="item in formatFilterList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </el-row>
        <el-row type="flex" justify="end" style="margin-top:10px;">
            <el-autocomplete
                class="search"
                placeholder="担当者"
                v-model="admin"
                :fetch-suggestions="querySearchAdmin"
                :trigger-on-focus="true"
            ></el-autocomplete>
            <el-autocomplete
                class="search"
                placeholder="ライター名"
                v-model="writer"
                :fetch-suggestions="querySearchWriter"
                :trigger-on-focus="true"
            ></el-autocomplete>
            <el-input
                placeholder="記事ID タイトル プロット SEOキーワード タグ"
                v-model="keyword"
                class="search keyword"
                @keyup.enter.native="search()"
            ></el-input>
            <el-button
                @click="search()"
                icon="el-icon-search"
                class="search-input"
                style="height: 40px; margin-top: 10px;"
            ></el-button>
        </el-row>
        <el-row>
            <el-col >
                <direction-row
                    :tableData=tableData
                    :categoryList="categoryList"
                    :articleTypeList="articleTypeList"
                    :baseCharCountList="baseCharCountList"
                    :formatFilterList="formatFilterList"
                    :update="update"
                    :deleteRow="deleteRow"
                    :create="create"
                    :backToPlotConfirm="backToPlotConfirm"
                    :tagList="tagList"
                >
                </direction-row>
            </el-col>
        </el-row>

        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />

        <el-dialog
            title="プロットに戻す"
            :visible.sync="backToPlotConfirmDialog"
            width="30%"
            @close="closeBackToPlotConfirmDialog"
            >
            <span>プロットに戻してよろしいですか？対象のディレクションと記事は削除されます</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeBackToPlotConfirmDialog">キャンセル</el-button>
                <el-button type="danger" @click="performBackToPlotDialog">プロットに戻す</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import SearchBox from '../../components/SearchBox'
import moment from 'moment'
import DirectionRow from '../../components/Editing/DirectionRow'
import FileDownload from 'js-file-download'
import CsvDownload from '../../components/CsvDownload'
import CsvUpload from '../../components/CsvUpload'

export default {

    components: {
        CsvDownload,
        CsvUpload,
        Pagination,
        SearchBox,
        DirectionRow
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data() {
        return {
            form: {},
            createDialog: false,
            dialog: false,

            categoryList: [],
            articleTypeList: [],
            baseCharCountList: [],

            tableData: [],

            categoryFilterList: {},
            articleTypeFilterList: {},
            formatFilterList: [],
            statusFilterList: [],
            admin: '',
            writer: '',
            keyword: '',
            tagList: [],

            pagination: {},

            backToPlotConfirmDialog: false,
            backToPlotConfirmId: null,
            deleteConfirmDialog: false,
            deleteConfirmId: null,
            confirmPlot: null,
            confirmPlotDialog: false,
            formatList: [],
            formatLabelList: {},

            status: null,
            category: null,
            articleType: null,
            format: null,
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '編集　>　ディレクション')
        this.queryInit()
        this.fetch()
    },
    methods: {
        queryInit() {
            this.status = this.$route.query.status ? Number(this.$route.query.status) : null
            this.category = this.$route.query.category ? Number(this.$route.query.category) : null
            this.articleType = this.$route.query.articleType ? Number(this.$route.query.articleType) : null
            this.format = this.$route.query.format ? this.$route.query.format : null
            this.admin = this.$route.query.admin ? this.$route.query.admin : null
            this.writer = this.$route.query.writer ? this.$route.query.writer : null
            this.keyword = this.$route.query.keyword ? this.$route.query.keyword : null
        },
        search() {
            const query = Object.assign({}, this.$route.query, {
                status: this.status,
                category: this.category,
                articleType: this.articleType,
                format: this.format,
                admin: this.admin,
                writer: this.writer,
                keyword: this.keyword,
                page: 1
            })
            this.$router.push({ query })
        },
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data } = await axios.get('/api/directions', {
                params: { ...this.$route.query }
            })

            axios.get('/api/directions/tags').then((response) => {
                this.tagList = response.data.tag_list
            })

            this.pagination = Object.assign({}, data.directions)

            /* フィルター用 */
            this.categoryFilterList = Object.assign([], data.categories)
            this.categoryFilterList.unshift({category_name: null, id: null})

            this.statusFilterList = Object.assign([], data.status_list)
            this.statusFilterList.unshift({label: null, value: null})


            this.articleTypeFilterList = Object.assign([], data.article_types)
            this.articleTypeFilterList.unshift({type_name: null, id: null})

            this.formatFilterList = Object.assign([], data.format_list)
            this.formatFilterList.unshift({label: null, value: null})
            /* フィルター用 */

            this.formatList = Object.assign([], data.format_list)
            this.formatList.forEach((format) => {
                this.formatLabelList[format.value] = format.label
            })

            this.$store.commit(types.PAGE_LOADING, false)

            const categories = this.idKeyList(data.categories)
            this.categoryList = Object.values(categories).map((category) => {
                return {id: category.id, label: category.category_name}
            })
            this.categoryList.unshift({id: null, label: 'カテゴリ未設定'})

            const articleTypes = this.idKeyList(data.article_types)
            this.articleTypeList = Object.values(articleTypes).map((articleType) => {
                return {id: articleType.id, label: articleType.type_name}
            })
            this.articleTypeList.unshift({id: null, label: '記事種別未設定'})

            const tmpBaseCharCountList = Object.assign({}, data.base_char_count_list)
            this.baseCharCountList = Object.values(tmpBaseCharCountList).map((charCount, index) => {
                return {id: index + 1, label: charCount.min + '〜' + charCount.max}
            })
            this.baseCharCountList.unshift({id: null, label: '文字数未設定' })

            const directions = Object.assign([], data.directions.data)
            this.tableData = _.map(directions, (direction) => {
                const current_status = data.status_list.filter(s => s.value === direction.article.status)
                return {
                    id: direction.id,
                    article_id: direction.article_id,
                    admin_id: direction.plot ? direction.plot.admin_id : null,
                    admin_name: direction.admin ? direction.admin.name : null,
                    editing_admin_id: direction.editing_admin_id,
                    editing_admin_name: direction.editing_admin ? direction.editing_admin.editor_name : null,
                    status: current_status[0].label,
                    enable_back_to_plot: direction.enable_back_to_plot,
                    due_at: direction.due_at,
                    edit_article_url: this.editArticleUrl(direction.article_id),
                    plot: {
                        plot: direction.plot ? direction.plot.plot : null,
                        article_type_id: direction.plot ? direction.plot.article_type_id : null,
                        base_char_count: direction.plot ? direction.plot.base_char_count : null,
                        category_id: direction.plot ? direction.plot.category_id : null,
                        keywords: direction.plot ? Object.entries(direction.plot.keywords).map(([key, keyword]) => {
                            return keyword.keyword
                        }) : null
                    },
                    article: {
                        category_id: direction.article.category_id,
                        writer_id: direction.article.writer_id,
                        writer_name: direction.article.writer ? direction.article.writer.writer_name : null,
                        format: direction.article.format,
                        format_disabled: true,
                        parent_tags: direction.article.parent_tags,
                        child_tags: direction.article.child_tags,
                        grandchild_tags: direction.article.grandchild_tags,
                        video_code: direction.article.video_article ? direction.article.video_article.video_code : null
                    }
                }
            })
            if (this.pagination.current_page == 1) {
                this.tableData.unshift({
                    article_id: null,
                    admin_id: null,
                    admin_name: this.$store.state.loginUser.name,
                    editing_admin_id: null,
                    editing_admin_name: null,
                    status: null,
                    enable_back_to_plot: false,
                    due_at: null,
                    plot: {
                        plot: null,
                        article_type_id: null,
                        base_char_count: null,
                        category_id: null,
                        keywords: []
                    },
                    article: {
                        writer_id: null,
                        writer_name: null,
                        format: null,
                        format_disabled: false,
                        parent_tags: [],
                        child_tags: [],
                        grandchild_tags: [],
                    }
                })
            }
        },
        idKeyList(list) {
            return _.keyBy(list, (data) => data.id)
        },
        editArticleUrl (id) {
            return '/articles/' + id
        },
        deleteRow(data) {
            this.deleteConfirmId = data.id
            this.deleteConfirmDialog = true
        },
        closeDeleteDialog() {
            this.deleteConfirmId = null
            this.deleteConfirmDialog = false
        },
        closeBackToPlotConfirmDialog() {
            this.backToPlotConfirmId = null
            this.backToPlotConfirmDialog = false
        },
        openConfirmPlot(data) {
            this.confirmPlotDialog = true
            this.confirmPlot = data.plot.plot
        },
        async downloadCSV() {
            this.$store.commit(types.LOADING, true)
            const { data, error } = await axios.get('/api/directions/csv')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            const bom  = new Uint8Array([0xEF, 0xBB, 0xBF])
            FileDownload(data, 'directions.csv', null, bom)
            this.$store.commit(types.LOADING, false)
        },
        async update (id, form) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.patch('/api/directions/' + id, form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.closeDialog()
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'ディレクションを変更しました')
            this.$store.commit(types.LOADING, false)
        },
        async create (form) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.post('/api/directions', form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.closeDialog()
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'ディレクションを作成しました')
            this.$store.commit(types.LOADING, false)
        },
        async performBackToPlotDialog () {
            this.$store.commit(types.LOADING, true)
            // ステータス411発生の可能性がある為、ヘッダーにContent-Length設定し空欄のパラメータ設置
            const config = {
                headers: {
                    'Content-Length': Buffer.byteLength('')
                }
            }
            const {error} = await axios.patch('/api/directions/' + this.backToPlotConfirmId + '/back/plot','' ,config)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'プロットに戻し、ディレクションを削除しました')
            this.$store.commit(types.LOADING, false)
            this.closeBackToPlotConfirmDialog()

        },
        async performDeleteDialog() {
            this.$store.commit(types.PAGE_LOADING, true)
            await axios.delete('/api/directions/' + this.deleteConfirmId )
            await this.fetch()
            this.$store.commit(types.PAGE_LOADING, false)
            this.deleteConfirmId = null
            this.deleteConfirmDialog = false
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'ディレクションを削除しました')
        },
        backToPlotConfirm (data) {
            this.backToPlotConfirmDialog = true
            this.backToPlotConfirmId = data.id
        },
        handleCurrentChange (page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
        },
        closeDialog () {
            this.dialog = false
            this.createDialog = false
        },
        showDate(date) {
            return date ? moment(date).format('YYYY-MM-DD') : ''
        },
        showTime(date) {
            return date ? moment(date).format('HH:mm:ss') : ''
        },
        importCsvStart() {
            this.$store.commit(types.LOADING, true)
        },
        importCsvError(e) {
            let errorMsg = 'CSVアップロードに失敗しました'
            if (e.status === 422){
                let errorJson = JSON.parse(e.message)
                errorMsg = typeof errorJson.error !== 'undefined' ? errorJson.error : errorMsg
            } else if (e.status === 403) {
                errorMsg = 'この操作は許可されていません。'
            }
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_ERROR_MESSAGE, errorMsg)
        },
        async importCsvSuccess(event) {
            if (event.status === 400) {
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_ERROR_MESSAGE, event.error)
            } else {
                await this.fetch()
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'CSVデータをアップロードしました')
            }
        },
        async querySearchAdmin(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/admins/suggest', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, '管理者検索に失敗しました')
                    return
                }
                cb(data.admins)
            }
        },
        async querySearchWriter(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/writers/suggest', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'ライター検索に失敗しました')
                    return
                }
                cb(data.writers)
            }
        },
    }
}
</script>

<style scoped>
.csvActions {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
}
.csvActions_group {
    display: flex;
    gap: 5px;
}

.search{
    margin: 10px 5px 0;
}
.search.keyword {
    width: 400px;
}
</style>
