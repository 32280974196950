<template>
    <div>
        <el-row>
            <item-left-contents
                :check-state="checkState"
                :number="number"
                :page-number="item.page_number"
                @update:checkState="checkState = $event"
            />

            <el-col class="contents" :span="21">
                <quote-type v-if="isEditing" ref="dialogModule" :save="update" @close="closeMenu" :id="content.id" :input="content" />
                <div v-else>
                    <el-row >
                        <blockquote style="white-space: pre-line;"  class="quote">
                            {{content.quote_text}}
                        </blockquote>
                    </el-row>
                    <el-row v-if="content.quote_host">
                        <span class="quoteHost">出展:{{content.quote_host}} </span>
                    </el-row>
                </div>
            </el-col>

            <el-col class="rightBox" :span="2">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-header height="30px">
                <el-button
                    type="text"
                    class="btnEdit"
                    @click="editItem(item)"
                >編集</el-button>
                <el-button
                    type="text"
                    class="btnEdit"
                    @click="$emit('delete', resourceName, item.id)"
                >削除</el-button>
            </el-header >
        </div>
    </div>
</template>
<script>

import QuoteType from '../Form/QuoteType'
import ItemLeftContents from './Components/ItemLeftContents'

export default {
    data () {
        return {
            isEditing: false,
        }
    },
    computed: {
        content() {
            return this.item.content
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        resourceName() {
            return 'quotes'
        },
    },
    components: {
        ItemLeftContents,
        QuoteType
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = false
        },
        checkItem(check,item) {
            this.parentCheckItem(check,item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        }
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>
<style scoped>
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}
blockquote {
    background-color:#eee;
    margin: 0;
    padding: 10px;
}
.quoteHost {
    margin-left:20px;
    color:#bbb;
    font-size:12px;
}
</style>
