<template>
    <el-dialog :title="dialogTitle" @close="closeDialog" :visible="dialog" height="50%">
        <el-form :model="form"  label-width="120px">
            <el-form-item label="タグ名" >
                <el-col :span="14">
                    <el-input v-model="form.tag_name" placeholder="マカロニタグ" />
                </el-col>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">キャンセル</el-button>
            <el-button type="primary" @click="submitForm">編集</el-button>
        </span>
    </el-dialog>
</template>

<script>
const formDefaultData = {
    id: null,
    parent_tag_id: null,
    child_tag_id: null,
    tag_name: '',
}
export default {
    name: 'TrWriterTagEditModal',
    props: {
        dialog: Boolean,
        input: Object,
        save: Function,
        closeDialog: Function,
        isParent: Boolean,
    },
    data () {
        return {
            dialogTitle: '',
            form: {...formDefaultData},
        }
    },
    watch: {
        input() {
            //formで定義されているプロパティのみ親コンポーネントから受け取り
            this.form = Object.assign({}, formDefaultData, _.pick(this.input, Object.keys(this.form)))
            this.dialogTitle = this.input.tag_name + ' 編集'
        },
    },
    methods: {
        submitForm () {
            this.save(this.form)
            this.closeDialog()
        },
    }
}
</script>
