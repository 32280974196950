<template>
    <el-row class="tweetArea">
        <div class="tweetLeft">
            <a class="tweetUrl" target="_blank" :href="twitter.tweet_url">
                <img class="tweetUserIcon" :src="twitter.icon_url">
            </a>
        </div>
        <div class="tweetRight">
            <el-row >
                <a class="tweetUrl" target="_blank" :href="twitter.tweet_url">
                    <p class="tweetUserName">{{twitter.user_name}} <span>{{twitter.screen_name}}</span></p>
                </a>
                <el-button size="mini" :disabled="twitterAdded" type="primary" class="addTweetBtn" @click="addTweetResult(twitter,addTweetResultIndex)" v-if="parentAddTweetResult">追加する</el-button>
            </el-row >
            <p class="tweetText">{{twitter.tweet_text}}</p>
            <p class="tweetDate">{{twitter.tweeted_at}}</p>
            <div class="tweetImage" v-if="twitter.tweet_image_url">
                <img v-if="showImage" :src="twitter.tweet_image_url" alt="">
            </div>
        </div>
    </el-row>
</template>

<script>

export default {
    props: {
        twitter: null,
        showImage:null,
        parentAddTweetResult:false,
        addTweetResultIndex:false
    },
    data () {
        return {
            twitterAdded:false
        }
    },
    methods: {
        addTweetResult(twitter, index) {
            this.parentAddTweetResult(twitter,index)
            this.twitterAdded = true
        }
    }
}
</script>
<style scoped>
a {
    text-decoration:none;
}
.tweetArea{
    background-color:#eee;
    margin: 0 auto 10px;
    padding:20px;
}
.tweetLeft{
    float:left;
}
.tweetRight{
    width:650px;
    float:left;
    padding:0 30px;
}
.tweetUserName {
    color:#666;
    margin-top:10px;
    float:left;
}
.tweetUserIcon {
    width:48px;
}
.addTweetBtn {
    float:right;
}

.tweetUserName span {
    padding-left:10px;
    font-size:14px;
}
.tweetDate{
    margin-top:10px;
    color:#666;
    font-size:14px;
}
.tweetImage img {
    width:550px;
}
</style>
