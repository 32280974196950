<template>
    <div>
        <el-row class="caution">現在InstagramAPIが使用できないため、Instagramの画像を埋め込む場合は「埋め込みタグ」アイテムを使用してください。</el-row>
        <el-row align="middle" style="margin-top: 20px;">
            <el-radio-group v-model="imageFrom" style="margin: -3px 20px 0;" size="mini">
                <el-radio-button label="url">URLから検索</el-radio-button>
                <el-radio-button label="upload">アップロード</el-radio-button>
            </el-radio-group>
            <el-button type="text" size="mini" @click="() => this.cooperateImageDialog = true">探して追加する</el-button>
        </el-row>

        <div v-if="imageFrom === 'url'" >
            <el-form v-if="!fetchedSearch" class="itemForm" @submit.native.prevent label-width="150px" >
                <el-form-item label="画像URL入力" style="width:80%;">
                    <el-input
                        width="50%"
                        type="text"
                        size="small"
                        placeholder="https://cdn.macaro-ni.jp/image/aaa.jpg"
                        v-model="searchUrl">
                        <el-button @click="searchImageFromUrl" slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form v-if="fetchedSearch" class="itemForm" @submit.native.prevent label-width="120px" >
                <el-row>
                    <el-col :span="6">
                        <div class="searchImage">
                            <img :src="searchResultUrl" class="avatar">
                        </div>
                    </el-col>
                    <el-col :span="18">
                        <el-form-item label="タイトル">
                            <el-input
                                type="text"
                                size="small"
                                maxlength="56"
                                v-model="form.image_title">
                            </el-input>
                            <text-length-count :targetText="form.image_title" :maxTextLength="56"></text-length-count>
                        </el-form-item>
                        <el-form-item label="コメント">
                            <el-input
                                type="textarea"
                                size="small"
                                :rows="3"
                                maxlength="500"
                                v-model="form.image_comment">
                            </el-input>
                            <text-length-count :targetText="form.image_comment" :maxTextLength="500"></text-length-count>
                        </el-form-item>
                        <el-form-item v-if="articleFormat === 'rich'" label="リンク先URL">
                            <el-input
                                type="text"
                                size="small"
                                v-model="form.link_url">
                            </el-input>
                        </el-form-item>
                        <el-form-item v-if="isTieup === true" label="リンク先URL">
                            <el-input
                                type="text"
                                size="small"
                                v-model="form.ad_url">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="参照元URL">
                            <el-input
                                placeholder="https://macaro-ni.jp/"
                                type="text"
                                size="small"
                                v-model="form.quote_url">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="alt属性">
                            <el-input
                                type="text"
                                size="small"
                                v-model="form.alt_text">
                            </el-input>
                        </el-form-item>
                        <el-button
                            type="text"
                            size="mini"
                            @click="() => this.mapDialog = true">位置情報を追加</el-button>
                        <p v-if="showAddressLabel" class="addressLabel"><a style="color: #409eff;" :href="form.geo_url" target="_blank"><i class="el-icon-location"></i>{{addressLabel}}</a></p>
                    </el-col>
                </el-row>
            </el-form>
            <div class="itemFormFooter">
                <el-button type="primary" size="mini" :disabled="!fetchedSearch" @click="submitForm">保存する</el-button>
                <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
            </div>
        </div>

        <div v-else-if="imageFrom === 'upload'" >
            <el-row>
                <el-form class="itemForm" @submit.native.prevent>
                    <el-col :span="6">
                        <el-form-item style="margin-bottom: 0;" class="uploadImage" label="画像">
                            <el-upload
                                :headers="header"
                                class="avatar-uploader"
                                :auto-upload="false"
                                action=""
                                :on-change="handleImageAdd"
                                :show-file-list="false"
                                >
                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="マーク">
                            <el-checkbox
                                :true-label="1"
                                :false-label="0"
                                :disabled="!fetchedImage || watermarkDisabled"
                                v-model="form.is_watermark"
                            >
                            </el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="18">
                        <el-form-item label="タイトル" label-width="90px">
                            <el-input
                                type="text"
                                size="small"
                                :disabled="!fetchedImage"
                                maxlength="56"
                                v-model="form.image_title">
                            </el-input>
                            <text-length-count :targetText="form.image_title" :maxTextLength="56"></text-length-count>
                        </el-form-item>
                        <el-form-item label="コメント" label-width="90px">
                            <el-input
                                type="textarea"
                                size="small"
                                :disabled="!fetchedImage"
                                :rows="3"
                                maxlength="500"
                                v-model="form.image_comment">
                            </el-input>
                            <text-length-count :targetText="form.image_comment" :maxTextLength="500"></text-length-count>
                        </el-form-item>
                        <el-form-item v-if="articleFormat === 'rich'" label="リンク先URL">
                            <el-input
                                type="text"
                                size="small"
                                v-model="form.link_url">
                            </el-input>
                        </el-form-item>
                        <el-form-item v-if="isTieup === true" label="リンク先URL">
                            <el-input
                                type="text"
                                size="small"
                                v-model="form.ad_url">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="photo by" label-width="90px">
                            <el-input
                                type="text"
                                size="small"
                                maxlength="60"
                                :disabled="!fetchedImage"
                                v-model="form.photo_by_text">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="alt属性" label-width="90px">
                            <el-input
                                type="text"
                                size="small"
                                maxlength="60"
                                :disabled="!fetchedImage"
                                v-model="form.alt_text">
                            </el-input>
                        </el-form-item>
                        <el-button
                                type="text"
                                size="mini"
                                @click="() => this.mapDialog = true">位置情報を追加</el-button>
                        <p v-if="showAddressLabel" class="addresslabel"><a style="color: #409eff;" :href="form.geo_url" target="_blank"><i class="el-icon-location"></i>{{addressLabel}}</a></p>
                    </el-col>
                </el-form>

            </el-row>
            <el-row>
                <div class="itemFormFooter">
                    <el-button type="primary" size="mini" :disabled="!fetchedImage" @click="submitImageForm">保存する</el-button>
                    <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
                </div>
            </el-row>
        </div>

        <el-dialog
            title="位置情報入力"
            @close="() => this.mapDialog = false"
            :visible="mapDialog"
            height="50%"
            append-to-body
            >
            <map-form
                @save="(mapInfo) => {
                    this.saveAddress(mapInfo)
                    this.mapDialog = false
                }"
            />
        </el-dialog>

        <el-dialog
            title="連携メディアから探す"
            @close="() => this.cooperateImageDialog = false"
            :visible="cooperateImageDialog"
            class="cooperateImageDialogWrapper"
            height="50%"
            width="1100px"
            append-to-body
        >
            <cooperate-image
                @save="saveMediaItem"
                @close="() => this.cooperateImageDialog = false"
                :imageType="'item'" />
        </el-dialog>
    </div>
</template>

<script>

import * as types from '../../../../../store/mutation-types'
import MapForm from './Components/Map'
import TextLengthCount from './Components/TextLengthCount'
import CooperateImage from './Components/CooperateImage'

const defaultFormValues =  {
    image_src: null,
    image_file: null,
    image_title: '',
    image_comment: '',
    alt_text: '',
    photo_by_text: '',
    is_watermark: 0,
    geo_title: '',
    geo_url: '',
    geo_location: '',
    quote_image_url:'',
    quote_url:'',
    quote_host:'',
    link_url: null,
    ad_url:null,
    is_shutterstock:0,
}
export default {
    components: {
        MapForm,
        CooperateImage,
        TextLengthCount,
    },
    props: {
        articleFormat: {
            required: true,
            type: String,
        },
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        saveAndContinue: {
            required: false,
            type: Function|null,
            default: null,
        },
        isTieup: {
            required: true,
            type: Boolean
        },
    },
    computed: {
        addressLabel() {
            return this.form.geo_title + ', ' + this.form.geo_location
        },
        showAddressLabel() {
            return Boolean(this.form.geo_title)
        },
        resourceName() {
            return 'images'
        },
    },
    data () {
        return {
            imageFrom: 'url',
            searchKeyword:null,
            mapDialog:false,
            cooperateImageDialog: false,
            form: {...defaultFormValues},
            searchUrl: null,
            searchResultUrl: null,
            imageUrl: null,
            tabs:null,
            imageRaw: null,
            fetchedSearch: false,
            formattedAddress:null,
            placeLabel:null,
            mapUrl:null,
            fetchedImage: false,
            watermarkDisabled: false,
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
        }
    },
    methods: {
        submitImageForm(){

            this.form.image_file = this.imageRaw
            const fd = new FormData()
            _.forEach(this.form, (value,key) => {
                if (value === null) {
                    this.form[key] = ''
                }
            })
            this.form = Object.assign({}, defaultFormValues, this.form)
            fd.append('image_src', this.form.image_src)
            fd.append('image_file', this.form.image_file)
            fd.append('image_title', this.form.image_title)
            fd.append('image_comment', this.form.image_comment)
            fd.append('alt_text', this.form.alt_text)
            fd.append('photo_by_text', this.form.photo_by_text)
            fd.append('is_watermark',this.form.is_watermark)
            fd.append('geo_title',this.form.geo_title)
            fd.append('geo_location',this.form.geo_location)
            fd.append('geo_url',this.form.geo_url)
            fd.append('link_url',this.form.link_url)
            fd.append('ad_url',this.form.ad_url)
            this.save(this.resourceName, fd, this.id)
        },
        submitForm(){
            this.form.quote_image_url = this.searchUrl
            const type = this.isInstagramUrl() ? 'images/instagram' : 'images/quote'
            this.save(type, this.form, this.id)
        },
        isInstagramUrl(){
            return /^https?:\/\/www\.instagram.com\/p\/(.*?)/.test(this.searchUrl)
        },
        async saveMediaItem(media, form){
            let fd = null
            switch(media) {
            case 'RakutenRecipe':
                fd = this.createLinkButtonForm('楽天レシピ', form.quote_url)
                this.saveAndContinue('links', fd, this.id).then(
                    this.saveAndContinue('images/quote', form)
                )
                break
            case 'Recipeblog':
                fd = this.createLinkButtonForm('レシピブログ', form.quote_url)
                this.saveAndContinue('links', fd, this.id).then(
                    this.saveAndContinue('images/quote', form)
                )
                break
            case 'Snapdish':
                fd = this.createLinkButtonForm('SnapDish', form.quote_url)
                this.saveAndContinue('links', fd, this.id).then(
                    this.saveAndContinue('images/quote', form)
                )
                break
            case 'Nadia':
                fd = this.createLinkButtonForm('Nadia', form.quote_url)
                this.saveAndContinue('links', fd, this.id).then(
                    this.saveAndContinue('images/quote', form)
                )
                break
            case 'MacaroniVideo':
                fd = this.createLinkButtonForm('macaroni動画', form.quote_url)
                this.saveAndContinue('links', fd, this.id).then(
                    this.saveAndContinue('images/quote', form)
                )
                break
            case 'Ikkyu':
            case 'Amazon':
            case 'Gurunavi':
            case 'Rakuten':
                this.saveAndContinue('images/quote', form)
                break
            case 'Shutterstock':
                this.saveAndContinue('images/shutterstock', form)
                break
            }
        },

        async searchImageFromUrl() {
            this.$store.commit(types.EDITOR_LOADING, true)
            const api = this.isInstagramUrl() ? '/api/instagram/check' : '/api/image/check'
            const {data, error} = await axios.get(api, {
                params: {
                    image_url: this.searchUrl
                }
            })
            if (error) {
                this.$store.commit(types.EDITOR_LOADING, false)
                return
            }
            this.fetchedSearch = true
            this.searchResultUrl = this.isInstagramUrl() ? data.cdn_url : data.url

            this.$store.commit(types.EDITOR_LOADING, false)
        },
        handleImageAdd(file) {
            this.imageRaw = file.raw
            this.imageUrl = URL.createObjectURL(file.raw)
            this.fetchedImage = true
        },
        openDialog(input) {
            this.form = Object.assign({}, defaultFormValues, _.pick(input, Object.keys(this.form)))
            this.searchUrl = this.form.quote_image_url
            this.fetchedSearch = true
            if (input.quote_image_url) {
                this.imageFrom = 'url'
                this.fetchedSearch = true
                this.fetchedImage = false
                this.imageUrl = null
                this.searchResultUrl = input.image_src_cdn_url
            } else {
                this.imageFrom = 'upload'
                this.fetchedSearch = false
                this.fetchedImage = true
                this.imageUrl = input.image_src_cdn_url
                this.searchResultUrl = null
                this.tabs = '1'
            }
            if (this.form.is_watermark){
                this.watermarkDisabled = true
            }
        },
        saveAddress({searchKeyword, formattedAddress}) {
            this.form.geo_title = searchKeyword
            this.form.geo_location = formattedAddress
        },
        createLinkButtonForm(mediaName, linkUrl){
            const fd = new FormData()
            fd.append('link_title', 'レシピはこちら｜' + mediaName)
            fd.append('link_type', 'button')
            fd.append('link_url', linkUrl)
            return fd
        },
    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px !important;
    text-align: center;
}
.avatar {
    width: 178px;
    display: block;
}
</style>
<style lang="scss" scoped>
.caution {
    color: red;
}
</style>
