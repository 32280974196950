import { render, staticRenderFns } from "./PlaceType.vue?vue&type=template&id=3686a834&scoped=true"
import script from "./PlaceType.vue?vue&type=script&lang=js"
export * from "./PlaceType.vue?vue&type=script&lang=js"
import style0 from "./PlaceType.vue?vue&type=style&index=0&id=3686a834&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3686a834",
  null
  
)

export default component.exports