import { render, staticRenderFns } from "./MediaIkyu.vue?vue&type=template&id=c727949c&scoped=true"
import script from "./MediaIkyu.vue?vue&type=script&lang=js"
export * from "./MediaIkyu.vue?vue&type=script&lang=js"
import style0 from "./MediaIkyu.vue?vue&type=style&index=0&id=c727949c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c727949c",
  null
  
)

export default component.exports