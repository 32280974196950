<template>
    <div class="tagOperationEdit">
        <el-form ref="form" label-width="170px" class="tagOperationEdit__form">
            <el-form-item label="タグ名">
                {{ tagPath.hasOwnProperty('tag') ? tagPath.tag.tag_name : '' }}
            </el-form-item>
            <el-form-item label="タグ種別">
                <span v-if="tagPath.parent_tag_id">親タグ</span>
                <span v-else-if="tagPath.child_tag_id">子タグ</span>
                <span v-else-if="tagPath.grandchild_tag_id">孫タグ</span>
            </el-form-item>
            <el-form-item label="カテゴリー">
                <el-select v-model="formParams.category_id">
                    <el-option value=""></el-option>
                    <el-option
                        v-for="(category, idx) in categories"
                        :key="idx"
                        :label="category.category_name"
                        :value="category.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="ディスクリプション">
                <el-input type="textarea" v-model="formParams.tag_description" :maxlength="descriptionMaxCharCount"></el-input>
                <div class="tagOperationEdit__descriptionCount">{{ formParams.tag_description ? formParams.tag_description.length : 0 }} / {{ descriptionMaxCharCount }}</div>
            </el-form-item>
            <el-form-item label="関連タグ">
                <span v-if="tagPath.grandchild_tag_id">孫タグのため関連タグの設定はできません</span>
                <tag-suggest
                    v-else
                    v-model="interestTagPaths"
                    :tag-path-id="tagPath.id"
                ></tag-suggest>
            </el-form-item>
            <el-form-item label="定番のレシピ">
                <el-input v-model="searchArticleId" type="number" class="tagOperationEdit__articleSearchInput" placeholder="記事ID" />
                <el-button type="primary" @click="addTagStandardArticle">追加</el-button>
                <div class="tagOperationEdit__caution">※最大10個</div>
                <div class="tagOperationEdit__caution">※動画記事/単一レシピを追加できます</div>
                <draggable v-model="articles" :options="draggableOptions">
                    <div v-for="(article, idx) in articles" :key="idx" class="tagOperationEdit__articleList">
                        <i class="el-icon-rank tagOperationEdit__draggableIcon"></i>
                        <i class="el-icon-error tagOperationEdit__deleteIcon" @click="deleteArticle(article.id)"></i>
                        <a :href="makeServiceUrl(`/${article.id}`)" target="_blank">{{ article.title }}</a>
                    </div>
                </draggable>
            </el-form-item>
            <el-form-item class="tagOperationEdit__buttonWrapper">
                <el-button type="default" @click="$router.back()" class="tagOperationEdit__button">キャンセル</el-button>
                <el-button type="primary" @click="submit" class="tagOperationEdit__button">更新</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { PAGE_TITLE , LOADING } from '../../store/mutation-types'
import * as types from '../../store/mutation-types'
import draggable from 'vuedraggable'
import TagSuggest from '../../components/TagSuggest'

export default {
    components: {
        draggable,
        TagSuggest,
    },
    data() {
        return {
            tagPath: {},
            interestTagPaths: [],
            articles: [],
            formParams: {
                tag_description: '',
                tag_path_ids: [],
                article_ids: [],
                category_id: null,
            },
            searchArticleId: null,
            descriptionMaxCharCount: 140,
            draggableOptions: {
                handle: '.tagOperationEdit__draggableIcon'
            },
            suggestTagPaths: [],
            categories: []
        }
    },
    async created() {
        this.$store.commit(PAGE_TITLE, '編成　>　タグページ運用　>　タグページ詳細')
        await this.fetch()
        await this.suggest()
    },
    methods: {
        async fetch() {
            this.$store.commit(LOADING, true)

            const tagPathId = this.$route.params.tag_path_id
            const { data, error } = await axios.get(`/api/tag_operations/${tagPathId}`)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }
            this.tagPath = {...data.tag_path}
            this.interestTagPaths = data.interest_tag_paths.slice()
            this.articles = data.articles.slice()
            this.categories = data.categories.slice()
            this.formParams = {...this.formParams, ...{
                tag_description: data.tag_path.tag.tag_description,
                category_id: data.tag_path.category_id,
            }}

            this.$store.commit(LOADING, false)
        },
        async addTagStandardArticle() {
            if (! this.searchArticleId) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '記事IDは必須です')
                return
            }
            if (this.articles.map(article => article.id).includes(parseInt(this.searchArticleId))) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '追加済みです')
                return
            }

            this.$store.commit(LOADING, true)

            const { data, error } = await axios.get('/api/articles/search_by_id', {
                params: {
                    id: this.searchArticleId,
                    is_video: 1,
                    recipe_type: 'SINGLE',
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }
            if (Object.keys(data).length === 0) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '指定した記事IDは動画/単一レシピではありません')
            } else {
                this.articles.unshift(data)
            }

            this.$store.commit(LOADING, false)
        },
        async submit() {
            this.$store.commit(LOADING, true)

            this.formParams.article_ids = this.articles.map(article => article.id)
            this.formParams.tag_path_ids = this.interestTagPaths.map(tagPath => tagPath.id)

            const { error } = await axios.put(`/api/tag_operations/${this.tagPath.id}`, this.formParams)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, '更新が完了しました')

            this.$store.commit(LOADING, false)
        },
        // サジェスト用データ取得
        async suggest() {
            const params = {
                is_parent: false,
                is_child: false,
                is_grandchild: false,
            }
            if (this.tagPath.parent_tag_id) {
                params.is_child = true
            } else if (this.tagPath.child_tag_id || this.tagPath.grandchild_tag_id) {
                params.is_grandchild = true
            }

            const {data, error} = await axios.post('/api/tag_paths/specific_type', params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.suggestTagPaths = data.tag_paths
        },
        deleteArticle(id) {
            this.articles = this.articles.filter(article => article.id !== parseInt(id))
        },
    },
}
</script>

<style lang="scss" scoped>
.tagOperationEdit {
    &__form {
        max-width: 1200px;
    }
    &__articleSearchInput {
        width: 150px;
    }
    &__articleList {
        margin-top: 10px;
    }
    &__button {
        width: 150px;
    }
    &__buttonWrapper {
        text-align: right;
    }
    &__draggableIcon {
        cursor: grab;
    }
    &__deleteIcon {
        cursor: pointer;
    }
    &__descriptionCount {
        text-align: right;
    }
    &__caution {
        color: red;
        margin-bottom: 5px;
    }
}
</style>
