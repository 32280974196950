<template>
    <div class="appContainer" v-loading.fullscreen.lock="isAppLoading">
        <el-container class="globalHeader">
            <el-header>
                <el-menu
                  class="el-menu-demo"
                  mode="horizontal"
                  background-color="#f8f8f8"
                  text-color="#383838"
                  :default-active="'1'"
                  >
                  <el-menu-item index="1">
                      <span @click="() => this.$router.push('/')" style="font-size:18px;"><img src="/images/logo.png" width="150">&nbsp;&nbsp;&nbsp;&nbsp;トラストリッジ用管理画面</span>
                  </el-menu-item>
                  <el-menu-item v-if="isLogin" style="float: right; margin-right: 10px;">
                      {{ loginUser.name }}
                  </el-menu-item>
                </el-menu>
            </el-header>
        </el-container>
        <el-container>
            <admin-menu v-if="isLogin"/>
            <el-main v-loading.fullscreen.lock="isPageLoading">
                <section>
                    <h1 v-if="pageTitle">{{ pageTitle }}</h1>
                    <router-view/>
                </section>
            </el-main>
        </el-container>
    </div>
</template>

<script>

import AdminMenu from '../components/AdminMenu.vue'
import { mapState } from 'vuex'
import * as types from '../store/mutation-types'

export default {
    name: 'TrApp',
    components: {
        AdminMenu
    },
    computed: {
        ...mapState(['pageTitle','isAppLoading','isPageLoading','isLogin','loginUser'])
    },
    created () {
        // ログイン状態の判定を行い、未ログイン時はログインページへ戻す
        if (!this.isLogin) {
            if (this.$route.meta.notRequireLogin !== true) {
                this.$router.push('/login/')
                return
            }
        } else if (Object.keys(this.loginUser).length === 0) {
            (async () => {
                const {data} = await axios.get('/api/login/info')
                this.$store.commit(types.FETCH_LOGIN_USER, data)
            })()
        }
    }
}
</script>

<style scoped>
    .appContainer{
        font-family: 游ゴシック, YuGothic, Arial, Roboto, "Droid Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
        color:#444;
        margin:0;
    }
    h1 {
        font-weight:normal;
        font-size:24px;
        margin: 0.9em 0;
    }
    .el-menu--horizontal>.el-menu-item.is-active {
        border-bottom:none ;
        color :#383838;
    }

    .el-menu.el-menu--horizontal {
        border-bottom: 2px solid #B2CFD2 !important;
    }

    .globalHeader {
        margin-bottom:20px;
    }
    .el-menu-item i {
        color:#383838 !important;
    }
</style>

<style>
    .el-header {
        padding:0 !important;
    }
    .el-menu-item:focus, .el-menu-item:hover ,
    .el-submenu__title:focus, .el-submenu__title:hover,
    .el-table--enable-row-hover .el-table__body tr:hover>td {
        background-color: RGBA(227, 126, 123, 0.1) !important;
    }
</style>

