<template>
    <div>
        <el-table
                :data="writers"
                style="width: 100%"
                max-height="800"
        >
            <el-table-column label="ライターID" prop="id"></el-table-column>
            <el-table-column :render-header="renderTableHeader(['アカウント名'])">
                <template v-slot="scope">
                    <a :href="'/writers/'+scope.row.id + '/fees'" target="_blank">{{ scope.row.writer_name }}</a>
                </template>
            </el-table-column>
            <el-table-column :render-header="renderTableHeader(['ライター名'])">
                <template v-slot="scope">
                    <a :href="'/managements/writer/'+scope.row.id" target="_blank">{{ scope.row.screen_name }}</a>
                </template>
            </el-table-column>
            <el-table-column label="参加日時" prop="created_at"></el-table-column>
            <el-table-column>
                <template v-slot="scope">
                    <el-button type="primary" size="mini" @click="handleEnableClick(scope.row.id)">再有効化</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { PAGE_TITLE } from '../../../store/mutation-types'

export default {
    props: {
        writers: Array,
    },
    created() {
        this.$store.commit(PAGE_TITLE, 'ライター一覧')
    },
    methods: {
        handleEnableClick(id) {
            this.$emit('enable', id)
        },
    }
}
</script>
