<template>
    <div>
        <el-row>
            <item-left-contents
                :check-state="checkState"
                :number="number"
                :page-number="item.page_number"
                @update:checkState="checkState = $event"
            />
            <el-col class="contents" :span="21">
                <material-type
                    v-if="isEditing"
                    ref="dialogModule"
                    :save="update"
                    :id="content.id"
                    :article-format="articleFormat"
                    @close="closeMenu"
                />
                <div v-else class="materialWrapper">
                    <!-- 動画記事 -->
                    <div v-if="response.article.is_video">
                        <el-row class="materialLabel" type="flex">
                            <div>{{response.article.video_article.cook_name}}の材料・調味料</div>
                        </el-row>
                        <el-row v-for="(element, idx) in item.content.elements" v-bind:key="idx" type="flex" class="materialContent">
                            <div>{{ element.material.material_name }}</div>
                            <div>{{ element.quantity_text }}</div>
                            <div>{{ element.material_group_name !== null ? element.material_group_name : '' }}</div>
                            <div>{{ element.is_main ? 'メイン食材' : '' }}</div>
                        </el-row>
                    </div>
                    <!-- 通常記事 -->
                    <div v-else>
                        <div>提供人数：{{ content.servings_text }}</div>
                        <el-row v-for="(element, idx) in item.content.elements" v-bind:key="idx" type="flex" class="materialContent">
                            <div>{{ element.material.material_name }}</div>
                            <div>{{ element.quantity_text }}</div>
                            <div>{{ element.material_group_name !== null ? element.material_group_name : '' }}</div>
                            <div>{{ element.is_main ? 'メイン食材' : '' }}</div>
                        </el-row>
                    </div>
                </div>
            </el-col>

            <el-col class="rightBox" :span="2">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', resourceName, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>

    </div>
</template>
<script>

import FeedbackForm from './Components/FeedbackForm'
import MaterialType from '../Form/MaterialType'
import ItemLeftContents from './Components/ItemLeftContents'

export default {
    data () {
        return {
            isEditing: false,
        }
    },
    computed: {
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        content() {
            return this.item.content
        },
        resourceName() {
            return 'materials'
        },
    },
    components: {
        ItemLeftContents,
        MaterialType,
        FeedbackForm,
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
        articleFormat: {
            required: true,
            type: String,
        }
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check,item) {
            this.parentCheckItem(check,item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    },
}
</script>
<style scoped>
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}
.materialLabel div {
    width: 100%;
    margin-right: 20px;
    color: #909399;
    border-bottom: 3px solid #E3D099;
    padding-bottom: 6px;
}
.materialContent div {
    width: 200px;
    margin-right: 20px;
    margin-top: 10px;
}
</style>
<style lang="scss">
.materialContent > div {
    a:link {
        color: #444;
        text-decoration: underline;
    }

    a:visited {
        color: #444;
        text-decoration: underline;
    }

    a:hover {
        color: #888;
        text-decoration: underline;
    }

    a:active {
        color: #888;
        text-decoration: none;
    }
}
</style>
