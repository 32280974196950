<template>
    <div>
        <div class="button-list">
            <el-button type="success" @click="() => this.$router.push('/organizations/app_recipe_menu/hints/order')">並び替え</el-button>
            <el-button @click="openRegistrationModal()">新規追加</el-button>
        </div>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>

        <el-row :gutter="20">
            <el-col :span="4" :offset="16">
                <el-button 
                    type="primary"
                    @click="comfirmToggleIsPublishedAll()"
                >
                    公開/非公開を更新
                </el-button>
            </el-col>
        </el-row>

        <el-table
                ref="appRecipeMenuHintTable"
                :data="expandData"
                :span-method="objectSpanMethod"
                style="width: 100%"
                max-height="800"
        >
            <el-table-column label="献立ヒントID" prop="id"></el-table-column>
            <el-table-column label="献立タイトル" prop="title"></el-table-column>
            <el-table-column label="レシピリストID" prop="app_recipe_list_id"></el-table-column>
            <el-table-column label="バナー画像" width="350">
                <template slot-scope="scope">
                    <div class="image">
                          <label :for="'image_' + scope.row.app_recipe_list_id">
                              <img v-if="scope.row.app_recipe_list_banner" :src="scope.row.app_recipe_list_banner" width="100" alt="サムネイル">
                          </label>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="(内部)タイトル" prop="app_recipe_list_title"></el-table-column>

            <el-table-column label="公開" prop="is_published">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_published" @change="toggleIsPublished(scope.row.id)"></el-checkbox>
                </template>
            </el-table-column>

            <el-table-column label="作成日" prop="created_at"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            @click="openEditModal(scope.row)"
                    >編集
                    </el-button>
                    <el-button
                            size="mini"
                            type="danger"
                            @click="destroyAppRecipeMenuHint(scope.row.id)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <registration-modal
                :is-active="registration.isActive"
                @close="registration.isActive = false"
                @reload="fetch()"
        ></registration-modal>
        <edit-modal
                ref="edit"
                :is-active="edit.isActive"
                :row="edit.row"
                @close="edit.isActive = false"
                @reload="fetch()"
        ></edit-modal>
    </div>
</template>

<script>
import * as types        from '../../../store/mutation-types'
import Pagination        from '../../../components/Pagination'
import RegistrationModal from './Hint/RegistrationModal'
import EditModal         from './Hint/EditModal'

export default {
    components: {
        RegistrationModal,
        EditModal,
        Pagination,
    },
    data() {
        return {
            pagination         : {},
            appRecipeMenuHints : [],
            registeredIds      : [],
            registration       : {
                isActive       : false,
            },
            edit : {
                isActive: false,
                row     : {},
            },
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'アプリ　>　献立ヒント')
        this.init()
    },
    computed: {
        expandData() {
            return this.appRecipeMenuHints.reduce((result, appRecipeHint) => {
                let arr = []
                if (appRecipeHint.app_recipe_menu_lists.length > 0) {
                    appRecipeHint.app_recipe_menu_lists.forEach((item, index, array) => {
                        let is_max_lists = false
                        if (appRecipeHint.app_recipe_menu_lists.length > 1) {
                            is_max_lists = true
                        }
                        let is_first = false
                        if (index == 0) {
                            is_first = true
                        }
                        arr.push({
                            id: appRecipeHint.id,
                            title: appRecipeHint.title,
                            is_published: Boolean(appRecipeHint.is_published),
                            app_recipe_list_id: item.id,
                            app_recipe_list_banner: item.banner_url,
                            app_recipe_list_title: item.title,
                            is_max_lists: is_max_lists, 
                            is_first: is_first, 
                            created_at: appRecipeHint.created_at,
                        })
                    })
                } else {
                    arr = {
                        id: appRecipeHint.id,
                        title: appRecipeHint.title,
                        is_published: Boolean(appRecipeHint.is_published),
                        app_recipe_list_id: null,
                        app_recipe_list_banner: null,
                        app_recipe_list_title: null,
                        is_max_lists: false, 
                        created_at: appRecipeHint.created_at,
                    }
                }
                result = result.concat(arr)
                return result
            }, [])
        }
    },
    methods   : {
        init() {
            this.fetch()
        },
        // 検索
        async fetch() {
            this.$store.commit(types.LOADING, true)
            const page = this.$route.query.page
            const params = {page}
            const {data, error} = await axios.get('/api/organization/app_recipe_menu/hints', {params})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.appRecipeMenuHints = data.data
            this.pagination = Object.assign({}, this.pagination, data)
            this.$store.commit(types.LOADING, false)
        },
        // APP献立ヒントを削除
        async destroyAppRecipeMenuHint(id) {
            if (!confirm('本当に削除しますか？')) {
                return
            }
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.delete('/api/organization/app_recipe_menu/hints/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの削除に成功しました。')
        },
        openRegistrationModal() {
            this.registration.isActive = true
        },
        openEditModal(row) {
            this.edit.isActive = true
            this.edit.row = this.appRecipeMenuHints.filter(hint => hint.id == row.id)[0]
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0 || columnIndex === 1 || columnIndex === 5 || columnIndex === 6) {
                if (row.is_max_lists) {
                    if (row.is_first) {
                        return {
                            rowspan: 2,
                            colspan: 1
                        }
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        }
                    }
                }
            }
        },
        toggleIsPublished(id) {
            let expandDataRow = this.expandData.find(hint => hint.id == id)
            let originalRow = this.appRecipeMenuHints.find(hint => hint.id == id)
            originalRow.is_published = expandDataRow.is_published
        },
        async comfirmToggleIsPublishedAll() {
            this.$store.commit(types.LOADING, true)

            const sendData = {
                app_recipe_menu_hints: this.appRecipeMenuHints
            }

            const {data, error} = await axios.post('/api/organization/app_recipe_menu/hints/toggle_is_published', sendData)

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                this.$store.commit(types.SHOW_INFO_MESSAGE, '公開フラグの更新に成功しました。')
            }

            this.fetch()
            this.$store.commit(types.LOADING, false)
        }
    }
}
</script>

<style lang="scss" scoped>
.appRecipeMenuListLi {
  list-style:none;
}
</style>
