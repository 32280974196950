<template>
    <div class="textLengthCount">
            {{ textCount }} / {{ maxTextLength }}文字
    </div>
</template>

<script>
export default {
    props: {
        targetText: String,
        maxTextLength: Number,
    },
    computed: {
        textCount() {
            return !this.targetText ? 0 : this.targetText.length

        }

    }
}
</script>

<style scoped>
.textLengthCount {
    font-size: 12px;
    text-align: right;
}
</style>
