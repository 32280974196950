<template>
    <div class="informationItem">
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent label-width="140px" >
            <!-- アイテム情報 -->
            <div class="informationItem__formHeading">アイテム情報</div>
            <el-form-item>
                <el-button type="text" size="mini" @click="() => this.cooperateAffiDialog = true">探して追加する</el-button>
            </el-form-item>
            <el-form-item label="複製元">
                <el-select v-model="copyItemId" size="small" :disabled="informationItems.length === 0">
                    <el-option
                        v-for="item in informationItems"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
                <el-button type="primary" size="mini" @click="copyItem">取得</el-button>
            </el-form-item>
            <el-form-item label="アイテムカテゴリ">
                <el-select v-model="form.type" size="small">
                    <el-option
                        v-for="item in informationItemTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="ブランド/メーカー">
                <el-input
                    type="text"
                    maxlength="255"
                    v-model="form.brand"
                ></el-input>
            </el-form-item>
            <el-form-item label="シリーズ">
                <el-input
                    type="text"
                    maxlength="255"
                    v-model="form.series"
                ></el-input>
            </el-form-item>
            <el-form-item label="アイテム名">
                <el-input
                    type="text"
                    maxlength="255"
                    v-model="form.name"
                ></el-input>
            </el-form-item>
            <el-form-item label="楽天">
                <el-col :span="7">
                    <el-input
                        type="number"
                        v-model="form.rakuten_affiliate_price"
                        placeholder="価格"
                    ></el-input>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="7">
                    <el-date-picker
                        v-model="form.rakuten_affiliate_checked_at"
                        type="date"
                        placeholder="価格チェック日付"
                        style="width: 100%"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="8">
                    <el-input
                        type="text"
                        v-model="form.rakuten_affiliate_url"
                        placeholder="URL"
                    ></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="Amazon">
                <el-col :span="7">
                    <el-input
                        type="number"
                        v-model="form.amazon_affiliate_price"
                        placeholder="価格"
                    ></el-input>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="7">
                    <el-date-picker
                        v-model="form.amazon_affiliate_checked_at"
                        type="date"
                        placeholder="価格チェック日付"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width: 100%"
                    ></el-date-picker>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="8">
                    <el-input
                        type="text"
                        v-model="form.amazon_affiliate_url"
                        placeholder="URL"
                        style="width: 100%"
                    ></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="Yahoo">
                <el-col :span="7">
                    <el-input
                        type="number"
                        v-model="form.yahoo_affiliate_price"
                        placeholder="価格"
                    ></el-input>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="7">
                    <el-date-picker
                        v-model="form.yahoo_affiliate_checked_at"
                        type="date"
                        placeholder="価格チェック日付"
                        style="width: 100%"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="8">
                    <el-input
                        type="text"
                        v-model="form.yahoo_affiliate_url"
                        placeholder="URL"
                    ></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="その他URL">
                <el-col :span="7">
                    <el-select v-model="form.other_label" size="large">
                        <el-option label="" value=""></el-option>
                        <el-option
                            v-for="item in informationItemOtherLabelList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="16">
                    <el-input
                        type="text"
                        v-model="form.other_url"
                        placeholder="URL"
                    ></el-input>
                </el-col>
            </el-form-item>
            <el-row align="middle" style="margin-top: 20px;">
                <el-radio-group v-model="imageFrom" class="informationItem__imageUploadActionWrapper" size="mini">
                    <el-radio-button label="url">URLから検索</el-radio-button>
                    <el-radio-button label="upload">アップロード</el-radio-button>
                    <span class="informationItem__formCaution">※最低1枚</span>
                </el-radio-group>
            </el-row>

            <el-row v-if="imageFrom === 'url'">
                <el-row v-if="!fetchedImageSearch" class="itemForm" @submit.native.prevent label-width="150px" >
                    <el-form-item label="画像URL入力" style="width:80%;">
                        <el-input
                            width="50%"
                            type="text"
                            size="small"
                            placeholder="https://cdn.macaro-ni.jp/image/aaa.jpg"
                            v-model="imageSearchUrl"
                        >
                            <el-button @click="searchImageFromUrl" slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-form-item>
                </el-row>
                <el-row v-if="fetchedImageSearch" class="itemForm" @submit.native.prevent label-width="120px">
                    <el-row>
                        <el-col :span="6">
                            <div class="searchImage">
                                <img :src="addImageForm.image_src" class="avatar">
                            </div>
                        </el-col>
                        <el-col :span="18">
                            <el-form-item label="Photo by">
                                <el-input
                                    type="text"
                                    size="small"
                                    maxlength="255"
                                    v-model="addImageForm.photo_by"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <div class="informationItem__imageActionWrapper">
                            <el-button type="primary" size="mini" :disabled="!fetchedImageSearch" @click="addImage">追加する</el-button>
                            <el-button size="mini" @click="cancelAddImage">キャンセル</el-button>
                        </div>
                    </el-row>
                </el-row>
            </el-row>

            <el-row v-else-if="imageFrom === 'upload'" class="itemForm" @submit.native.prevent>
                <el-row class="itemForm" @submit.native.prevent>
                    <el-col :span="1">&nbsp;</el-col>
                    <el-col :span="5">
                        <div class="informationItem__formLabel">画像</div>
                        <el-upload
                            :headers="imageRequestHeader"
                            class="avatar-uploader"
                            :auto-upload="false"
                            action=""
                            :on-change="handleImageAdd"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif"
                        >
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-col>
                    <el-col :span="18">
                        <el-form-item label="Photo by">
                            <el-input
                                type="text"
                                size="small"
                                maxlength="255"
                                v-model="addImageForm.photo_by"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="マーク">
                            <el-checkbox
                                :true-label="1"
                                :false-label="0"
                                v-model="addImageForm.is_watermark"
                            ></el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <div class="itemFormFooter">
                        <el-button type="primary" size="mini" :disabled="!fetchedImage" @click="addImage">追加する</el-button>
                        <el-button size="mini" @click="cancelAddImage">キャンセル</el-button>
                    </div>
                </el-row>
            </el-row>

            <el-form-item v-for="(image, idx) in form.images" :key="idx" label="商品画像">
                <div class="subBox">
                    <div class="inputSub">
                        <el-input
                            type="text"
                            size="mini"
                            v-model="image.image_src"
                            :disabled="image.image_type === imageType.internal"
                        ></el-input>
                    </div>
                    <div class="operation">
                        <el-button @click="imageMoveUp(image, idx)" icon="el-icon-arrow-up" size="mini" type="mini"></el-button>
                        <el-button @click="imageMoveDown(image, idx)" icon="el-icon-arrow-down" size="mini"></el-button>
                        <el-button @click="imageRemove(image, idx)" icon="el-icon-close" size="mini"></el-button>
                    </div>
                </div>

                <div class="informationItem__imageEditFormWrapper">
                    <img :src=image.image_src class="informationItem__image">
                    <div class="informationItem__imageEditForm">
                        <el-form-item label="Photo by">
                            <el-input
                                type="text"
                                size="small"
                                maxlength="255"
                                v-model="image.photo_by"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="マーク" v-if="image.image_type === imageType.internal">
                            <el-checkbox
                                :true-label="1"
                                :false-label="0"
                                :disabled="image.id !== null && watermarkDisabledList.includes(image.id)"
                                v-model="image.is_watermark"
                            ></el-checkbox>
                        </el-form-item>
                    </div>
                </div>
            </el-form-item>


            <!-- 紹介テキスト -->
            <div class="informationItem__formHeading">紹介テキスト</div>
            <el-form-item label="見出し">
                <el-input
                    type="text"
                    maxlength="255"
                    v-model="form.heading"
                ></el-input>
                <text-length-count :targetText="form.heading" :maxTextLength="100"></text-length-count>
            </el-form-item>
            <el-form-item label="本文">
                <el-input
                    type="textarea"
                    maxlength="2000"
                    v-model="form.body"
                ></el-input>
                <text-length-count :targetText="form.body" :maxTextLength="2000"></text-length-count>
            </el-form-item>

            <!-- テーブル -->
            <div class="informationItem__formHeading">テーブル表示<span class="informationItem__formCaution">※最低2件</span></div>
            <div>
                <span class="informationItem__formHeadingLabel">比較表のベースとなる見出しに設定</span>
                <el-checkbox
                    :true-label="1"
                    :false-label="0"
                    v-model="form.is_comparison_table_base_heading"
                    :disabled="disabledBaseHeading"
                ></el-checkbox>
            </div>
            <draggable v-model="form.elements" handle=".informationItem__formSortIconWrapper">
                <el-row v-for="(element, idx) in form.elements" :key="idx">
                    <el-form-item v-if="element.body_type === tableBodyTypeList.content" label="項目/内容">
                        <el-col :span="7">
                            <el-input
                                type="text"
                                maxlength="255"
                                v-model="element.label"
                                placeholder="項目"
                            ></el-input>
                        </el-col>
                        <el-col :span="1">&nbsp;</el-col>
                        <el-col :span="12">
                            <el-input
                                type="textarea"
                                maxlength="255"
                                v-model="element.body"
                                placeholder="内容"
                            ></el-input>
                        </el-col>
                        <el-col :span="2" class="informationItem__formSortIconWrapper">
                            <i class="fas fa-arrows-alt fa-2x"></i>
                        </el-col>
                        <el-col :span="2" class="informationItem__formDeleteIconWrapper">
                            <span class="elementDeleteBtn" @click="deleteElement(idx)">
                                <i class="fas fa-minus-circle fa-2x" style="color: red;"></i>
                            </span>
                        </el-col>
                    </el-form-item>
                    <el-form-item v-else-if="element.body_type === tableBodyTypeList.evaluation" label="項目/評価">
                        <el-col :span="7">
                            <el-input
                                type="text"
                                maxlength="255"
                                v-model="element.label"
                                placeholder="項目"
                            ></el-input>
                        </el-col>
                        <el-col :span="1">&nbsp;</el-col>
                        <el-col :span="12">
                            <el-select v-model="element.body" size="large">
                                <el-option
                                    v-for="item in informationItemTableBodyEvaluation"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="2" class="informationItem__formSortIconWrapper">
                            <i class="fas fa-arrows-alt fa-2x"></i>
                        </el-col>
                        <el-col :span="2" class="informationItem__formDeleteIconWrapper">
                            <span class="elementDeleteBtn" @click="deleteElement(idx)">
                                <i class="fas fa-minus-circle fa-2x" style="color: red;"></i>
                            </span>
                        </el-col>
                    </el-form-item>
                </el-row>
            </draggable>

            <el-form-item>
                <el-col :span="3">
                    <div class="informationItem__actionHeading">項目</div>
                    <span class="informationItem__actionIcon" @click="addElement(tableBodyTypeList.content)"><i class="fas fa-plus-circle fa-2x"></i></span>
                </el-col>
                <el-col :span="3">
                    <div class="informationItem__actionHeading">評価</div>
                    <span class="informationItem__actionIcon" @click="addElement(tableBodyTypeList.evaluation)"><i class="fas fa-plus-circle fa-2x"></i></span>
                </el-col>
            </el-form-item>
        </el-form>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>

        <el-dialog
            title="連携メディアから探す"
            @close="() => this.cooperateAffiDialog = false"
            :visible="cooperateAffiDialog"
            class="cooperateImageDialogWrapper"
            height="50%"
            width="1100px"
            append-to-body
        >
            <cooperate-affiliate
                @save="saveMediaItem"
                @close="() => this.cooperateAffiDialog = false"
                :imageType="'affiItem'"
            />
        </el-dialog>
    </div>
</template>

<script>
import * as types from '../../../../../store/mutation-types'
import CooperateAffiliate from './Components/CooperateAffiliate'
import moment from 'moment'
import draggable  from 'vuedraggable'
import TextLengthCount from './Components/TextLengthCount'

const defaultFormValues = {
    id: null,
    name: null,
    type: null,
    brand: null,
    series: null,
    heading: null,
    body: null,
    rakuten_affiliate_price: null,
    rakuten_affiliate_checked_at: null,
    rakuten_affiliate_url: null,
    amazon_affiliate_price: null,
    amazon_affiliate_checked_at: null,
    amazon_affiliate_url: null,
    yahoo_affiliate_price: null,
    yahoo_affiliate_checked_at: null,
    yahoo_affiliate_url: null,
    other_label: null,
    other_url: null,
    is_comparison_table_base_heading: false,
    elements: [],
    images: [],
}
const defaultElementForm = {
    id: null,
    label: null,
    body_type: null,
    body: null,
}
const defaultImageForm = {
    id: null,
    image_type: null,
    image_src: null,
    image_file: null,
    photo_by: null,
    is_watermark: false,
}

export default {
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        informationItemTypeList: {
            required: true,
            type: Array,
        },
        informationItemOtherLabelList: {
            required: true,
            type: Array,
        },
        informationItemTableBodyEvaluation: {
            required: true,
            type: Array,
        },
        informationItems: {
            required: true,
            type: Array,
        },
        items: {
            required: true,
            type: Array,
            default: []
        },
    },
    components: {
        CooperateAffiliate,
        draggable,
        TextLengthCount,
    },
    data() {
        return {
            form: {},
            elementMinCount: 2,
            tableBodyTypeList: {
                content: 'CONTENT',
                evaluation: 'EVALUATION',
            },
            imageType: {
                internal: 'INTERNAL',
                external: 'EXTERNAL',
            },
            cooperateAffiDialog: false,
            imageFrom: 'url',
            fetchedImageSearch: false,
            fetchedImage: false,
            imageSearchUrl: null,
            addImageForm: {...defaultImageForm},
            imageRequestHeader: {
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            imageUrl: null,
            copyItemId: null,
            watermarkDisabledIdList: [],
        }
    },
    created() {
        this.form = {...defaultFormValues}
        this.form.images = []
        this.form.elements = []
        // アイテム情報が租納しない場合、比較表ベース見出しにする
        if (this.notExistsInformationItem) {
            this.form.is_comparison_table_base_heading = true
        }
    },
    methods: {
        submitForm () {
            const fd = new FormData()
            fd.append('id', this.form.id ? this.form.id : '')
            fd.append('name', this.form.name ? this.form.name : '')
            fd.append('type', this.form.type ? this.form.type : '')
            fd.append('brand', this.form.brand ? this.form.brand : '')
            fd.append('series', this.form.series ? this.form.series : '')
            fd.append('heading', this.form.heading ? this.form.heading : '')
            fd.append('body', this.form.body ? this.form.body : '')
            fd.append('rakuten_affiliate_price', this.form.rakuten_affiliate_price ? this.form.rakuten_affiliate_price : '')
            fd.append('rakuten_affiliate_checked_at', this.form.rakuten_affiliate_checked_at ? this.form.rakuten_affiliate_checked_at : '')
            fd.append('rakuten_affiliate_url', this.form.rakuten_affiliate_url ? this.form.rakuten_affiliate_url : '')
            fd.append('amazon_affiliate_price', this.form.amazon_affiliate_price ? this.form.amazon_affiliate_price : '')
            fd.append('amazon_affiliate_checked_at', this.form.amazon_affiliate_checked_at ? this.form.amazon_affiliate_checked_at : '')
            fd.append('amazon_affiliate_url', this.form.amazon_affiliate_url ? this.form.amazon_affiliate_url : '')
            fd.append('yahoo_affiliate_price', this.form.yahoo_affiliate_price ? this.form.yahoo_affiliate_price : '')
            fd.append('yahoo_affiliate_checked_at', this.form.yahoo_affiliate_checked_at ? this.form.yahoo_affiliate_checked_at : '')
            fd.append('yahoo_affiliate_url', this.form.yahoo_affiliate_url ? this.form.yahoo_affiliate_url : '')
            fd.append('other_label', this.form.other_label ? this.form.other_label : '')
            fd.append('other_url', this.form.other_url ? this.form.other_url : '')
            fd.append('is_comparison_table_base_heading', this.form.is_comparison_table_base_heading ? 1 : 0)
            this.form.elements.map((element, idx) => {
                fd.append(`elements[${idx}][id]`, element.id ? element.id : '')
                fd.append(`elements[${idx}][label]`, element.label ? element.label : '')
                fd.append(`elements[${idx}][body_type]`, element.body_type ? element.body_type : '')
                fd.append(`elements[${idx}][body]`, element.body ? element.body : '')
            })
            this.form.images.map((image, idx) => {
                fd.append(`images[${idx}][id]`, image.id ? image.id : '')
                fd.append(`images[${idx}][image_type]`, image.image_type ? image.image_type : '')
                fd.append(`images[${idx}][image_src]`, image.image_src ? image.image_src : '')
                fd.append(`images[${idx}][image_file]`, image.image_file ? image.image_file : '')
                fd.append(`images[${idx}][photo_by]`, image.photo_by ? image.photo_by : '')
                fd.append(`images[${idx}][is_watermark]`, image.is_watermark ? 1 : 0)
            })
            this.save(this.resourceName, fd, this.id)
        },
        addElement(bodyType) {
            this.form.elements.push(Object.assign({}, defaultElementForm, {
                body_type: bodyType
            }))
        },
        deleteElement(idx) {
            if (this.form.elements.length <= this.elementMinCount) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '最低2件の登録が必要です')
                return
            }
            this.form.elements.splice(idx, 1)
        },
        openDialog(input) {
            this.form = Object.assign({}, defaultFormValues, _.pick(input, Object.keys(this.form)))
            this.form.elements = this.form.elements.map(element => Object.assign({}, element))
            this.watermarkDisabledList = this.form.images.map(image => {
                if (image.is_watermark) {
                    return image.id
                }
            })
        },
        saveMediaItem(media, form) {
            const now = moment().format('YYYY-MM-DD hh:mm:ss')
            if (media === 'amazon') {
                this.form.images.push(Object.assign({}, defaultImageForm, {
                    image_type: this.imageType.external,
                    image_src: form.affi_thumbnail_url,
                    photo_by: 'Amazon',
                }))
                if (form.amazon_price === '値段情報がありません') {
                    form.amazon_price = 0
                }
                if (form.sub_images) {
                    form.sub_images.map(subImage => {
                        this.form.images.push(Object.assign({}, defaultImageForm, {
                            image_type: this.imageType.external,
                            image_src: subImage,
                            photo_by: 'Amazon',
                        }))
                    })
                }
                this.form.amazon_affiliate_price = form.amazon_price
                this.form.amazon_affiliate_url = form.amazon_url
                this.form.amazon_affiliate_checked_at = now
            } else if (media === 'Rakuten') {
                this.form.images.push(Object.assign({}, defaultImageForm, {
                    image_type: this.imageType.external,
                    image_src: form.affi_thumbnail_url,
                    photo_by: '楽天',
                }))
                this.form.rakuten_affiliate_price = form.rakuten_price
                this.form.rakuten_affiliate_url = form.rakuten_url
                this.form.rakuten_affiliate_checked_at = now
            } else if (media === 'Yahoo') {
                this.form.images.push(Object.assign({}, defaultImageForm, {
                    image_type: this.imageType.external,
                    image_src: form.affi_thumbnail_url,
                    photo_by: 'Yahoo',
                }))
                this.form.yahoo_affiliate_price = form.yahoo_price
                this.form.yahoo_affiliate_url = form.yahoo_url
                this.form.yahoo_affiliate_checked_at = now
            }

            this.cooperateAffiDialog = true
        },
        imageMoveDown(sub, index) {
            const movedIndex = index + 1
            if (this.form.images[movedIndex]) {
                this.form.images.splice(index, 2, this.form.images[movedIndex], this.form.images[index])
            }
        },
        imageMoveUp(sub, index) {
            if (index > 0) {
                const movedIndex = index - 1
                this.form.images.splice(movedIndex, 2, this.form.images[movedIndex+1], this.form.images[movedIndex])
            }
        },
        imageRemove(sub, index) {
            this.form.images.splice(index, 1)
        },
        async searchImageFromUrl() {
            this.$store.commit(types.EDITOR_LOADING, true)
            const api = this.isInstagramUrl() ? '/api/instagram/check' : '/api/image/check'
            const { data, error } = await axios.get(api, {
                params: {
                    image_url: this.imageSearchUrl
                }
            })
            if (error) {
                this.$store.commit(types.EDITOR_LOADING, false)
                return
            }
            this.fetchedImageSearch = true
            this.addImageForm.image_src = this.isInstagramUrl() ? data.cdn_url : data.url
            this.addImageForm.image_type = this.imageType.external

            this.$store.commit(types.EDITOR_LOADING, false)
        },
        isInstagramUrl() {
            return /^https?:\/\/www\.instagram.com\/p\/(.*?)/.test(this.imageSearchUrl)
        },
        addImage() {
            this.form.images.push(Object.assign({}, this.addImageForm))
            this.addImageForm = Object.assign({}, defaultImageForm)
            this.fetchedImageSearch = false
            this.imageSearchUrl = null
            this.imageUrl = null
        },
        cancelAddImage() {
            this.addImageForm = Object.assign({}, defaultImageForm)
            this.fetchedImageSearch = false
            this.imageUrl = null
        },
        handleImageAdd(file) {
            this.addImageForm.image_file = file.raw
            this.addImageForm.image_type = this.imageType.internal
            this.addImageForm.image_src = URL.createObjectURL(file.raw)
            this.imageUrl = URL.createObjectURL(file.raw)
            this.fetchedImage = true
        },
        copyItem() {
            if (this.copyItemId !== null) {
                const copyItem = this.informationItems.find(item => item.id === this.copyItemId)
                this.form = Object.assign({}, copyItem, {
                    id: null,
                    images: [],
                    elements: [],
                    rakuten_affiliate_price: null,
                    rakuten_affiliate_checked_at: null,
                    rakuten_affiliate_url: null,
                    amazon_affiliate_price: null,
                    amazon_affiliate_checked_at: null,
                    amazon_affiliate_url: null,
                    yahoo_affiliate_price: null,
                    yahoo_affiliate_checked_at: null,
                    yahoo_affiliate_url: null,
                    other_label: null,
                    other_url: null,
                    is_comparison_table_base_heading: false,
                    heading: null,
                    body: null,
                })
                copyItem.elements.map(element => this.form.elements.push(Object.assign({}, element, {id: null})))
            }
        }
    },
    computed: {
        // アイテム情報が存在するか
        notExistsInformationItem() {
            return ! this.items.map(item => item.item_type).includes('InformationType')
        },
        // 比較表見出しベースチェックボックスをdisabledにするか
        disabledBaseHeading() {
            if (this.input) {
                return !! this.input.is_comparison_table_base_heading
            } else {
                return this.notExistsInformationItem
            }
        },
        resourceName() {
            return 'information'
        },
    }
}

</script>

<style scoped lang="scss">
.informationItem {
    &__formHeading {
        font-weight: bold;
        margin: 50px 0 10px;
        &:first-child {
            margin: 0 0 10px;
        }
    }
    &__actionIcon {
        cursor: pointer;
    }
    &__formDeleteIconWrapper {
        text-align: right;
    }
    &__imageActionWrapper {
        text-align: right;
    }
    &__formLabel {
        font-size: 14px;
    }
    &__imageEditFormWrapper {
        display: flex;
        margin-top: 10px;
    }
    &__imageEditForm {
        flex: 1;
    }
    &__formCaution {
        font-size: 12px;
        color: red;
        font-weight: bold;
        margin-left: 5px;
    }
    &__formSortIconWrapper {
        cursor: grab;
        text-align: right;
    }
    &__image {
        width: 150px;
        height: 170px;
        object-fit: contain;
    }
    &__imageUploadActionWrapper {
        display: flex;
        align-items: center;
        margin: -3px 20px 0;
    }
    &__formHeadingLabel {
        text-align: right;
        vertical-align: middle;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        padding: 0 12px 0 0;
        box-sizing: border-box;
        margin-left: 65px
    }
}
.elementDeleteBtn {
    cursor: pointer;
}
</style>

