<template>
    <el-dialog
            title="APPレシピリスト - 編集"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>タイトル</dt>
                <dd>
                    <el-input placeholder="title" v-model="title"></el-input>
                </dd>
                <dt>画像</dt>
                <dd>
                    <el-upload
                        action=""
                        accept="image/png,image/jpeg,image/gif"
                        list-type="picture"
                        :show-file-list="false"
                        :auto-upload="false"
                        :on-change="handleBannerAdd"
                        :on-remove="handleBannerRemove"
                    >
                        <img v-if="bannerImagePreview" :src="bannerImagePreview" class="thumbnail-img" width="130px">
                        <img v-else-if="registeredBannerUrl" :src="registeredBannerUrl" class="thumbnail-img" width="130px">
                        <el-button v-else size="small" type="primary">画像選択</el-button>
                    </el-upload>
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="edit()">更新</el-button>
            <el-button @click="close">閉じる</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../../store/mutation-types'

export default {
    props: {
        isActive: Boolean,
        row     : Object,
    },
    data() {
        return {
            id                  : '',
            title               : '',
            bannerImage         : '',
            bannerImagePreview  : '',
            registeredBannerUrl : '',
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        async edit() {
            this.$store.commit(types.LOADING, true)
            const fd = this.getFormData()
            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PATCH'
                }
            }
            const {data, error} = await axios.post('/api/organization/app_recipe_menu/lists/' + this.id, fd, config)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.title       = ''
            this.bannerImage = ''
            this.bannerImagePreview  = ''
            this.$emit('close')
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの更新に成功しました。')
            this.$emit('reload')
        },
        // フォームデータ作成
        getFormData(serialization) {
            // 画像UPで使用するため
            const fd = new FormData()
            fd.append('title', this.title)
            if (this.bannerImage)  fd.append('banner_image', this.bannerImage)
            return fd
        },
        // バナー画像選択時
        handleBannerAdd(file, fileList) {
            this.bannerImage        = file.raw
            this.bannerImagePreview = file.url
        },
        // バナー画像削除時
        handleBannerRemove(file, fileList) {
            this.bannerImage        = ''
            this.bannerImagePreview = ''
        },
    },
    watch: {
        row() {
            this.id                  = this.row.id
            this.title               = this.row.title
            this.registeredBannerUrl = this.row.banner_url
        }
    }
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            width: 15%;
            margin-bottom: 10px;
        }

        dd {
            width: 85%;
            margin-bottom: 10px;
        }
    }
</style>
