<template>
    <!--
    ElementUI TimePickerのis-rangeモードでは、pickerオープン時のデフォルト値を設定できなかったため
    通常のTimePickerを並べてrangeを表現するコンポーネントを作成する。
    is-rangeモードでは困るケースで代替として利用する。
    -->
    <div class="timeRangePicker">
        <el-time-picker
            :value="value[0]"
            class="timepicker"
            :format="format"
            :value-format="valueFormat"
            :default-value="`2024/01/01 ${defaultTime[0]}`"
            :size="size"
            :placeholder="startPlaceholder"
            @input="$emit('input', [$event, value[1]])"
        ></el-time-picker>
        <span>{{ rangeSeparator }}</span>
        <el-time-picker
            :value="value[1]"
            class="timepicker"
            :format="format"
            :value-format="valueFormat"
            :default-value="`2024/01/01 ${defaultTime[1]}`"
            :size="size"
            :placeholder="endPlaceholder"
            @input="$emit('input', [value[0], $event])"
        ></el-time-picker>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => ['', '']
        },
        format: {
            type: String,
            default: 'HH:mm'
        },
        valueFormat: {
            type: String,
            default: 'HH:mm'
        },
        defaultTime: {
            type: Array,
            default: () => ['00:00', '00:00']
        },
        size: {
            type: String,
            default: 'mini'
        },
        rangeSeparator: {
            type: String,
            default: '〜'
        },
        startPlaceholder: {
            type: String,
            default: '開始'
        },
        endPlaceholder: {
            type: String,
            default: '終了'
        },
    },
}
</script>

<style scoped>
.timepicker.el-input {
    width: 150px;
}
</style>
