<template>
    <el-dialog
        title="ネイティブアド追加"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>macaroni / 外部</dt>
                <dd>
                    <el-radio-group v-model="articleSourceType">
                        <el-radio
                            v-for="(type, index) in articleSourceTypes"
                            :key="index"
                            :label="index" @change="changeSourceType()"
                        >{{ type }}</el-radio>
                    </el-radio-group>
                </dd>
                <dt>{{articleSourceType === 'internal' ? '記事ID' : '外部URL'}}</dt>
                <dd>
                    <el-input v-show="articleSourceType === 'internal'" class="article-id" v-model="articleId"></el-input>
                    <el-input v-show="articleSourceType !== 'internal'" class="link-url" v-model="linkUrl" placeholder="例：https://trustridge.jp"></el-input>
                </dd>
                <dt>クリエイティブ名</dt>
                <dd>
                    <el-input class="name" v-model="name"></el-input>
                </dd>
                <dt>表示企業名</dt>
                <dd>
                    <el-input
                        class="screen-name"
                        placeholder="例：株式会社〇〇（20文字まで）"
                        v-model="screenName"
                        :disabled="articleSourceType === 'internal'"
                    >
                    </el-input>
                </dd>
                <dt>サムネイル</dt>
                <dd>
                    <el-upload
                        class="upload-file"
                        action=""
                        :auto-upload="false"
                        :on-remove="handleRemove"
                        :on-change="handleAdd"
                        list-type="picture"
                        :multiple="false"
                        :limit="1">
                        <el-button size="small" type="primary">画像を選択して下さい</el-button>
                    </el-upload>
                </dd>
                <dt>タイトル</dt>
                <dd>
                    <el-input class="title" v-model="title"></el-input>
                </dd>
                <dt>PRマーク非表示</dt>
                <dd>
                    <el-checkbox v-model="isHidePr">非表示</el-checkbox>
                </dd>
                <dt>配信期間</dt>
                <dd>
                    <el-date-picker
                        v-model="dateRange"
                        type="datetimerange"
                        range-separator="〜"
                        start-placeholder="開始日時"
                        end-placeholder="終了日時"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    />
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="add()">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        isActive: Boolean,
        frequencyIntervalTypes: {}
    },
    data() {
        return {
            articleId  : '',
            linkUrl  : '',
            name : '',
            title : '',
            screenName : '',
            image : '',
            isHidePr : false,
            checkList: [],
            imagePreview : '',
            articleSourceType: 'internal',
            articleSourceTypes: {
                internal: 'macaroni',
                outside: '外部'
            },
            dateRange: null,
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.image = ''
            this.imagePreview = ''
            this.$emit('close')
        },
        changeSourceType() {
            if (this.articleSourceType === 'internal') {
                this.linkUrl = ''
                this.screenName = ''
            } else {
                this.articleId = ''
            }
        },
        async add() {
            const fd = this.getFormData()
            this.$emit('save', fd)
        },
        handleRemove: function () {
            this.imagePreview = ''
            this.image = ''
        },
        // ファイルを追加した時
        handleAdd: function (file) {
            this.imagePreview = file.url
            this.image = file.raw
        },
        getFormData() {
            const fd = new FormData()
            if (this.articleSourceType === 'internal') {
                fd.append('article_id', this.articleId)
            } else {
                fd.append('link_url', this.linkUrl)
                fd.append('screen_name', this.screenName)
            }
            fd.append('name', this.name)
            fd.append('title', this.title)
            fd.append('image', this.image)
            fd.append('hide_pr_mark_flag', Number(this.isHidePr))
            if (this.dateRange !== null) {
                fd.append('distribute_start_at', this.dateRange[0])
                fd.append('distribute_end_at', this.dateRange[1])
            }
            return fd
        },
    },
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }
        dt {
            width: 30%;
        }

        dd {
            margin-bottom:3%;
            width: 70%;
        }
    }
    .preview-img {
        width: 250px;
    }
</style>
