<template>
    <el-dialog
            title="APPレシピリスト - 追加"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>タイトル</dt>
                <dd>
                    <el-input placeholder="タイトル" v-model="title"></el-input>
                </dd>
                <dt>画像</dt>
                <dd>
                    <el-upload
                        action=""
                        accept="image/png,image/jpeg,image/gif"
                        list-type="picture"
                        :show-file-list="false"
                        :auto-upload="false"
                        :on-change="handleBannerAdd"
                        :on-remove="handleBannerRemove"
                    >
                        <img v-if="bannerImagePreview" :src="bannerImagePreview" class="thumbnail-img" width="100px">
                        <el-button v-else size="small" type="primary">画像選択</el-button>
                    </el-upload>
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="add()">追加</el-button>
            <el-button @click="close">閉じる</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../../store/mutation-types'

export default {
    props  : {
        isActive: Boolean,
    },
    data() {
        return {
            title       : '',
            bannerImage : '',
            bannerImagePreview : '',
        }
    },
    methods: {
        initData() {
            this.title = ''
            this.bannerImage = ''
            this.bannerImagePreview = ''
        },
        // モーダル閉じ
        close() {
            this.$emit('close')
            this.initData()
        },
        // 追加
        async add() {
            this.$store.commit(types.LOADING, true)
            const fd = this.getFormData()
            const {data, error} = await axios.post('/api/organization/app_recipe_menu/lists', fd)
            if (error) {
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.close()
            this.$store.commit(types.LOADING, false)
            this.$emit('reload')
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの追加に成功しました。')
        },
        // バナー画像選択時
        handleBannerAdd(file, fileList) {
            this.bannerImage = file.raw
            this.bannerImagePreview = file.url
        },
        // バナー画像削除時
        handleBannerRemove(file, fileList) {
            this.bannerImage = ''
            this.bannerImagePreview = ''
        },
        // フォームデータ作成
        getFormData(serialization) {
            // 画像UPで使用するため
            const fd = new FormData()
            fd.append('title', this.title)
            fd.append('banner_image', this.bannerImage)
            return fd
        },
    },
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            width: 15%;
            margin-bottom: 10px;
        }

        dd {
            width: 85%;
            margin-bottom: 10px;
        }

        .type-detail-select,
        .type-select {
            width: 400px;
        }
    }
</style>
