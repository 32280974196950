<template>
	<el-dialog
			title="アプリ用タイトルの編集"
			:visible.sync="isActive"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:before-close="close"
	>
		<div class="content">
			<dl>
				<dt>アプリ用タイトル</dt>
				<dd>
					<el-input placeholder="アプリ用タイトル" v-model="altTitle"></el-input>
				</dd>
			</dl>
		</div>
		<span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
					<el-button type="primary" @click="update()">保存</el-button>
        </span>
	</el-dialog>
</template>

<script>

export default {
    props  : {
        isActive: Boolean,
        row: Object,
    },
    data() {
        return {
            altTitle: null,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        async update() {
            this.$emit('save', this.altTitle, this.row.id)
        },
    }
}
</script>

<style lang="scss" scoped>
	.content {
		dl {
			display: flex;
			flex-wrap: wrap;
		}

		dt {
			width: 30%;
			padding-top: 3%;
		}

		dd {
			margin-top:1.5%;
			width: 70%;
		}
	}
</style>
