<template>
    <el-form :model="form" :inline="true">
        <div class="label-edit">
            <el-form-item>
                <el-input v-model="form.label" placeholder="項目名"></el-input>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="form.required">必須</el-checkbox>
            </el-form-item>
        </div>
        <div class="content-edit">
            <el-form-item v-for="(value, key) in form.values" :key="key">
                <div class="entry-form-value-list">
                    <el-input v-model="form.values[key]" placeholder="選択肢"></el-input>
                    <span @click="deleteValue(key)" class="delete-icon"><i class="fas fa-times"></i></span>
                </div>
            </el-form-item>
        </div>
        <div class="submit">
            <el-form-item>
                <el-button type="primary" @click="addValue">選択肢を追加</el-button>
                <el-button type="primary" @click="add">追加</el-button>
            </el-form-item>
        </div>
    </el-form>
</template>

<script>
import * as types from '../../../../store/mutation-types'

export default {
    props: {
        htmlClasses: Object,
    },
    data() {
        return {
            form: {
                label: '',
                required: false,
                values: [''],
            },
        }
    },
    methods: {
        // フォームに追加
        add() {
            // validation
            if (!this.form.label.match(/\S/g)) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '項目名は必須です')
                return
            }
            let isValidationSuccess = true
            for (let value of this.form.values) {
                if (!value.match(/\S/g)) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, '空の選択肢が存在します')
                    isValidationSuccess = false
                    return
                }
            }
            if (!isValidationSuccess) {
                return
            }

            const html = this.getTemplate()
            this.$emit('addFormHtml', html)

            this.form = {
                label: '',
                required: false,
                values: [''],
            }
        },
        // 選択肢を増やす
        addValue() {
            this.form.values.push('')
        },
        // フォームのHTMLを作成
        getTemplate() {
            let radioForm = ''
            let i = 0
            for (let value of this.form.values) {
                const id = 'radio-'+this.form.label+i+value
                radioForm += `
                    <span class="radio_wrapper">
                        <input type="radio" name="${this.form.label}" value="${value}" id="${id}">
                        <label for="${id}">${value}</label>
                    </span>
                `
                i++
            }

            return `
                <div class="${this.htmlClasses.COMMON}" data-required="${this.form.required ? 'true' : 'false'}">
                    <div class="${this.htmlClasses.LEFT}">
                        <b>${this.form.label}</b>
                        ${this.form.required ? '<span class="required">必須</span>' : ''}
                    </div>
                    <div class="${this.htmlClasses.RIGHT}">
                        ${radioForm}
                    </div>
                </div>
            `
        },
        // 選択肢削除
        deleteValue(key) {
            if (this.form.values.length <= 1) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '選択肢は最低1つ必要です。')
                return
            }

            this.form.values.splice(key, 1)
        },
    }
}
</script>

<style lang="scss" scoped>
    .label-edit {
        margin-bottom: 10px;
    }
    .content-edit .el-form-item {
        margin-bottom: 10px;
    }
    .entry-form-value-list {
        display: flex;
    }
    .entry-form-value-list .delete-icon {
        display: flex;
        margin-left: 5px;
        cursor: pointer;
    }
</style>
