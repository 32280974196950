<template>
    <div>
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent label-width="120px" >
            <el-form-item label="タイプ">
                <el-select v-model="form.type" size="small">
                    <el-option
                        v-for="item in emphasisItemTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="カラー">
                <el-select v-model="form.color" size="small">
                    <el-option
                        v-for="item in emphasisItemColorList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="見出し">
                <el-input
                    type="text"
                    maxlength="255"
                    v-model="form.title"
                ></el-input>
            </el-form-item>
            <el-form-item label="クラス">
                <el-select v-model="form.heading_tag_type" size="small">
                    <el-option
                        v-for="item in emphasisItemHeadingTagTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="本文">
                <el-row v-for="(element, idx) in form.elements" :key="idx" class="elementList">
                    <wysiwyg-editor
                        v-model="element.text"
                        :is-array="true"
                        :editor-total-count="form.elements.length"
                    ></wysiwyg-editor>
                    <span class="elementDeleteBtn" @click="deleteElement(idx)">
                        <i class="fas fa-minus-circle fa-2x" style="color: red;"></i>
                    </span>
                </el-row>
                <div @click="addElement" class="elementAddBtn">
                    <i class="fas fa-plus-circle fa-2x"></i>
                </div>
            </el-form-item>
        </el-form>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
    </div>
</template>

<script>
import WysiwygEditor from './Components/WysiwygEditor'
import * as types from '../../../../../store/mutation-types'

const defaultFormValues = {
    type: null,
    color: null,
    heading_tag_type: null,
    title: null,
    elements: [],
}
const defaultElementForm = {
    id: null,
    text: null,
}

export default {
    components: {
        WysiwygEditor,
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        emphasisItemColorList: {
            required: true,
            type: Array,
        },
        emphasisItemHeadingTagTypeList: {
            required: true,
            type: Array,
        },
        emphasisItemTypeList: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            form: {},
            elementMinCount: 1,
            elementMaxCount: 10,
        }
    },
    created() {
        this.form = {...defaultFormValues}
        this.form.elements = []
        // 本文の最低件数を設定
        for (let i = 0; i < this.elementMinCount; i++) {
            this.form.elements.push(Object.assign({}, defaultElementForm))
        }
    },
    methods: {
        submitForm () {
            this.save(this.resourceName, this.form, this.id)
        },
        addElement() {
            if (this.form.elements.length >= this.elementMaxCount) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, `最大${this.elementMaxCount}件です`)
                return
            }
            this.form.elements.push(Object.assign({}, defaultElementForm))
        },
        deleteElement(idx) {
            if (this.form.elements.length <= this.elementMinCount) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, `最低${this.elementMinCount}件の登録が必要です`)
                return
            }
            this.form.elements.splice(idx, 1)
        },
        openDialog(input) {
            this.form = Object.assign({}, defaultFormValues, _.pick(input, Object.keys(this.form)))
            this.form.elements = this.form.elements.map(element => Object.assign({}, element))
        },
    },
    computed: {
        resourceName() {
            return 'emphasises'
        },
    }
}

</script>

<style scoped lang="scss">
.elementList {
    position: relative;
    margin-bottom: 15px;
}
.elementDeleteBtn {
    position: absolute;
    top: 50%;
    right: -33px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    cursor: pointer;
}
.elementAddBtn {
    text-align: center;
    cursor: pointer;
}
</style>
