<template>
    <el-dialog
        title="NGワード編集"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>NGワード</dt>
                <dd>
                    <el-input class="ng-word-input" placeholder="NGワード" v-model="ngWord"></el-input>
                </dd>
                <dt>通常記事</dt>
                <dd>
                    <el-checkbox v-model="isNormal">通常記事</el-checkbox>
                </dd>
                <dt>動画記事</dt>
                <dd>
                    <el-checkbox v-model="isVideo">動画記事</el-checkbox>
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">閉じる</el-button>
            <el-button type="primary" @click="edit(id, ngWord, isNormal, isVideo)">更新</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../store/mutation-types'

export default {
    props  : {
        isActive: Boolean,
        row: Object,
    },
    data() {
        return {
            ngWord  : '',
            isNormal: true,
            isVideo : true,
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        async edit(id, ngWord, isNormal, isVideo) {
            const params = {
                ng_word  : ngWord,
                is_normal: Number(isNormal),
                is_video : Number(isVideo),
            }
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.put('/api/editing/ngwords/' + id, params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.$emit('close')
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの更新に成功しました。')
            this.$emit('reload')
        }
    },
    watch: {
        row() {
            this.id = this.row.id
            this.ngWord = this.row.ng_word
            this.isNormal = this.row.is_normal
            this.isVideo = this.row.is_video
        }
    }
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            width: 30%;
            padding-top: 2%;
        }

        dd {
            margin-bottom:3%;
            width: 70%;
        }
    }
</style>
