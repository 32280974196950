<template>
    <div>
        <el-row type="flex" justify="end">
            <el-button type="success" @click="() => this.$router.push('/organizations/app_tab/order')">並び替え</el-button>
            <el-button type="primary" @click="openRegistrationModal()">新規作成</el-button>
        </el-row>
        <div class="app-tab-table">
            <el-table
                    ref="appTabTable"
                    :data="appTabs"
                    style="width: 100%"
                    max-height="800"
            >
                <el-table-column label="並び順" prop="order_num"></el-table-column>
                <el-table-column label="タブ名" prop="tab_name"></el-table-column>
                <el-table-column label="タブ種別" prop="type_label"></el-table-column>
                <el-table-column label="詳細">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type === 'tag'">
                            <div>親タグ：{{ scope.row.parent_tag_name }}</div>
                            <div>子タグ：{{ scope.row.child_tag_name }}</div>
                            <div>孫タグ：{{ scope.row.grandchild_tag_name }}</div>
                        </div>
                        <div v-else>
                            {{ scope.row.type_detail }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="表示" prop="is_active">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.is_active" disabled></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column align="right">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                @click="openEditModal(scope.row)"
                        >編集
                        </el-button>
                        <el-button
                                size="mini"
                                type="danger"
                                @click="destroyAppTab(scope.row.id)"
                        >削除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <registration-modal
                :is-active="registration.isActive"
                :options="registration.options"
                @close="registration.isActive = false"
                @reload="init()"
        ></registration-modal>
        <edit-modal
                ref="edit"
                :is-active="edit.isActive"
                :row="edit.row"
                @close="edit.isActive = false"
                @reload="init()"
        ></edit-modal>
    </div>
</template>

<script>
import * as types        from '../../store/mutation-types'
import RegistrationModal from './AppTab/RegistrationModal'
import EditModal         from './AppTab/EditModal'

export default {
    components: {
        RegistrationModal,
        EditModal,
    },
    data() {
        return {
            appTabs      : [],
            registeredIds: [],
            options      : {},
            registration : {
                isActive: false,
                options : {},
            },
            edit         : {
                isActive: false,
                row     : {},
            },
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'アプリ　>　タイムラインタブ')
        this.init()
    },
    methods   : {
        async init() {
            this.$store.commit(types.LOADING, true)
            await this.fetchSelectOptions()
            await this.fetch()
            this.$store.commit(types.LOADING, false)
        },
        // 検索
        async fetch() {
            const {data, error} = await axios.get('/api/organization/app_tabs')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            data.forEach((row, index) => {
                data[index].type_detail = ''
                if (row.category) {
                    data[index].type_detail = row.category.category_name
                }
                if (row.topic) {
                    data[index].type_detail = row.topic.title
                }
                if (row.serialization) {
                    data[index].type_detail = row.serialization.title
                }
                data[index].parent_tag_name = ''
                if (row.parent_tags.length !== 0) {
                    data[index].parent_tag_name = row.parent_tags.reduce((join, tag) => join + tag.tag_name + '／', '').slice(0, -1)
                }
                data[index].child_tag_name = ''
                if (row.child_tags.length !== 0) {
                    data[index].child_tag_name = row.child_tags.reduce((join, tag) => join + tag.tag_name + '／', '').slice(0, -1)
                }
                data[index].grandchild_tag_name = ''
                if (row.grandchild_tags.length !== 0) {
                    data[index].grandchild_tag_name = row.grandchild_tags.reduce((join, tag) => join + tag.tag_name + '／', '').slice(0, -1)
                }
            })
            this.appTabs = data
        },
        //  セレクトボックスのオプションを取得
        async fetchSelectOptions() {
            const {data, error} = await axios.get('/api/organization/app_tabs/select_options')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.options = data
        },
        // APPタイムラインタブを削除
        async destroyAppTab(id) {
            if (!confirm('本当に削除しますか？')) {
                return
            }
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.delete('/api/organization/app_tabs/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの削除に成功しました。')
        },
        openRegistrationModal() {
            this.registration.isActive = true
            this.registration.options = this.options
        },
        openEditModal(row) {
            this.edit.isActive = true
            this.edit.row = row
        },
    }
}
</script>

<style lang="scss" scoped>
    .app-tab-table {
        margin-top: 20px;
    }
</style>
