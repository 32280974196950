<template>
    <el-row class="headerTabWrapper">
        <div class="headerTabs">
            <div :class="tabClass('material')" @click="selectTab('material')"><i class="fab fa-apple fa-2x"></i><div class="itemSelectTab">材料</div></div>
            <div :class="tabClass('recipe')" @click="selectTab('recipe')"><i class="fas fa-utensils fa-2x"></i><div class="itemSelectTab">作り方</div></div>
            <div :class="tabClass('text')" @click="selectTab('text')"><i class="fas fa-pencil-alt fa-2x"></i><div class="itemSelectTab">テキスト</div></div>
            <div :class="tabClass('emphasis')" @click="selectTab('emphasis')"><i class="fas fa-check-circle fa-2x"></i><div class="itemSelectTab">強調表示</div></div>
            <div :class="tabClass('image')" @click="selectTab('image')"><i class="fas fa-camera-retro fa-2x"></i><div class="itemSelectTab">画像</div></div>
            <div :class="tabClass('heading')" @click="selectTab('heading')"><i class="fas fa-list-ul fa-2x"></i><div class="itemSelectTab">見出し</div></div>
            <div :class="tabClass('link')" @click="selectTab('link')"><i class="fas fa-link fa-2x"></i><div class="itemSelectTab">リンク</div></div>
            <div :class="tabClass('affiliate')" @click="selectTab('affiliate')"><i class="fab fa-amazon fa-2x"></i><div class="itemSelectTab">アフィリエイト</div></div>
            <div :class="tabClass('embedded')" @click="selectTab('embedded')"><i class="fas fa-clipboard fa-2x"></i><div class="itemSelectTab">埋め込みタグ</div></div>

        </div>
        <div class="headerForm" v-if="selectedTab !== null">
            <material-type
                v-if="selectedTab === 'material'"
                class="headerItemAddBox"
                :save="create"
                :article-format="articleFormat"
                @close="closeMenuOfCancel" />
            <recipe-type
                v-if="selectedTab === 'recipe'"
                class="headerItemAddBox"
                :save="create"
                :article-format="articleFormat"
                @close="closeMenuOfCancel" />
            <text-type
                v-if="selectedTab === 'text'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel" />
            <image-type
                v-if="selectedTab === 'image'"
                class="headerItemAddBox"
                :articleFormat="articleFormat"
                :save="create"
                :saveAndContinue="createAndContinue"
                :is-tieup="isTieup"
                @close="closeMenuOfCancel" />
            <heading-type
                v-if="selectedTab === 'heading'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel"
                :headingTypeList="response.headingTypeList" />
            <link-type
                v-if="selectedTab === 'link'"
                class="headerItemAddBox"
                :articleFormat="articleFormat"
                :saveAndContinue="createAndContinue"
                :save="create"
                @close="closeMenuOfCancel" />
            <affiliate-type
                v-if="selectedTab === 'affiliate'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel" />
            <embedded-type
                v-if="selectedTab === 'embedded'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel" />
            <emphasis-type
                v-if="selectedTab === 'emphasis'"
                class="headerItemAddBox"
                :save="create"
                :emphasis-item-color-list="response.emphasisItemColorList"
                :emphasis-item-heading-tag-type-list="response.emphasisItemHeadingTagTypeList"
                :emphasis-item-type-list="response.emphasisItemTypeList"
                @close="closeMenuOfCancel"
            />
        </div>
    </el-row>
</template>

<script>

import MaterialType from '../Form/MaterialType'
import RecipeType from '../Form/RecipeType'
import TextType from '../Form/TextType'
import ImageType from '../Form/ImageType'
import HeadingType from '../Form/HeadingType'
import LinkType from '../Form/LinkType'
import AffiliateType from '../Form/AffiliateType'
import EmbeddedType from '../Form/EmbeddedType'
import EmphasisType from '../Form/EmphasisType'

export default {
    components: {
        MaterialType,
        RecipeType,
        TextType,
        ImageType,
        HeadingType,
        LinkType,
        AffiliateType,
        EmbeddedType,
        EmphasisType,
    },
    props: {
        articleFormat: String,
        response: null,
        isTieup: Boolean,
    },
    data () {
        return {
            selectedTab: null,
        }
    },
    methods: {
        tabClass(active) {
            let className = 'headerTab'
            if (active === this.selectedTab) {
                className += ' active'
            }
            return className
        },
        selectTab(tab) {
            this.selectedTab = tab
        },
        closeMenu () {
            this.selectedTab = null
        },
        closeMenuOfCancel () {
            if (!confirm('キャンセルしますか？')) {
                return
            }
            this.selectedTab = null
        },
        async create (type, form) {
            this.$emit('create', type, form)
        },
        async createAndContinue (type, form) {
            this.$emit('createAndContinue', type, form)
        },
    },
}
</script>
