<template>
    <div class="shopItem">
        <div class="placeItem">
            <el-alert v-if="!isOpenPlaceItemForm" title="これは廃止された店舗情報アイテムです。新しい店舗情報アイテムとして作成し直しをお願いします🙏" type="error" />
            <el-button v-if="!isOpenPlaceItemForm" type="text" class="btnEdit" @click="isOpenPlaceItemForm = true">このアイテムをもとに新アイテムを作成する</el-button>
            <place-type
                v-if="isOpenPlaceItemForm"
                :save="createPlaceItem"
                :article-id="response.article.id"
                :content="content.convertable_place_item"
                :is-from-shop-item="true"
                @close="isOpenPlaceItemForm = false"
            />
        </div>
        <el-divider v-if="isOpenPlaceItemForm" />
        <el-row>
            <item-left-contents
                :check-state="checkState"
                :number="number"
                :page-number="item.page_number"
                @update:checkState="checkState = $event"
            />

            <el-col class="contents" :span="21">
                <shop-type
                    v-if="isEditing"
                    ref="dialogModule"
                    :save="update"
                    @close="closeMenu"
                    :id="content.id"
                    :input="content"
                    :shopTypeList="response.shopTypeList"
                    :smokingTypeList="response.smokingTypeList"
                    :wifiTypeList="response.wifiTypeList"
                    :powerSupplyTypeList="response.powerSupplyTypeList"
                    :isEdit="true" />
                <div v-else>
                    <el-table :data="tableData" border :show-header="false">
                        <el-table-column prop="label" width="300"></el-table-column>
                        <el-table-column>
                            <template slot-scope="scope">
                                <div v-html="scope.row.value"></div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-row>
                        <img v-if="content.wifi_type === 'usable'"
                             class="shopIcon"
                             src="https://cdn.macaro-ni.jp/assets/img/store/pc_wifi_1.png">
                        <img v-if="content.power_supply_type === 'usable'"
                             class="shopIcon"
                             src="https://cdn.macaro-ni.jp/assets/img/store/pc_powersupply_1.png">
                        <img v-if="content.smoking_type === 'smokable'"
                             class="shopIcon"
                             src="https://cdn.macaro-ni.jp/assets/img/store/pc_smoking_1.png">
                        <img v-if="content.smoking_type === 'separate'"
                             class="shopIcon"
                             src="https://cdn.macaro-ni.jp/assets/img/store/pc_smoking_2.png">
                        <img v-if="content.smoking_type === 'nonsmoking'"
                             class="shopIcon"
                             src="https://cdn.macaro-ni.jp/assets/img/store/pc_smoking_3.png">
                    </el-row>
                    <el-row v-if="content.geo_location" class="geoLocationUrl">
                        <i class="el-icon-location"></i><a :href="getGeoLinkUrl" target="_blank">{{ content.geo_location }}</a>
                    </el-row>
                </div>
            </el-col>

            <el-col class="rightBox" :span="2" justify="end" v-if="!updateOrdersMode">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', resourceName, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="handleDeleteLink({ itemId: item.id, isSkipConfirm: false })"
            >削除</el-button>
        </div>
    </div>
</template>

<script>
import FeedbackForm from './Components/FeedbackForm'
import ShopType from '../Form/ShopType'
import PlaceType from '../Form/PlaceType'
import ItemLeftContents from './Components/ItemLeftContents'

export default {
    data () {
        return {
            isEditing: false,
            isOpenPlaceItemForm: false,
        }
    },
    computed: {
        content() {
            return this.item.content
        },
        tableData() {
            let tableData = []
            tableData.push({label:'店舗名', value:this.content.shop_name })
            tableData.push({label:'公式HP', value: this.linkTableData(this.content.shop_url) })
            tableData = tableData.concat(this.content.elements.map((element) => {
                let value = element.value
                if (element.type === 'official_url' || element.type === 'reference_url') {
                    value = this.linkTableData(element.value)
                }
                value = value.replace(/\r?\n/g, '<br>')
                return {label: element.name, value: value}
            }))
            return tableData
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        getGeoLinkUrl() {
            return 'https://maps.google.co.jp/maps?center=' + this.content.geo_lat + ',' + this.content.geo_lng + '&q=' + this.content.geo_location + '&iwloc=A'
        },
        resourceName() {
            return 'shops'
        },
    },
    components: {
        ItemLeftContents,
        PlaceType,
        ShopType,
        FeedbackForm,
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentCreate: {
            required: true,
            type: Function,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check,item) {
            this.parentCheckItem(check,item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        handleDeleteLink({ itemId, isSkipConfirm }) {
            this.$emit('delete', this.resourceName, itemId, { isSkipConfirm })
        },
        async createPlaceItem(type, form) {
            // 新店舗情報アイテムの作成
            const result = await this.parentCreate(type, form)
            if (!result) {
                return
            }

            // 旧店舗情報アイテムの削除
            this.handleDeleteLink({ itemId: this.item.id, isSkipConfirm: true })
            this.isEditing = false
            this.isOpenPlaceItemForm = false
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
        linkTableData(value) {
            if (!value) {
                return ''
            }
            return '<a href="' + value + '" target="_blank" class="shopLink">' + value + '</a><span class="externalLinkIcon"><i class="fas fa-external-link-alt"></i></span>'
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>
<style scoped>
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}
h2 {
    font-size:26px;
    margin:0;
}
h3 {
    font-size:20px;
    font-weight:normal;
    margin:0;
}
h4 {
    font-size:14px;
    font-weight:normal;
    margin:0;
}
.shopIcon {
    height: 30px;
    width: 30px;
    margin: 10px 5px 0;
}
.geoLocationUrl {
    margin-top: 5px;
    font-size: 12px;
}

.placeItem {
    width: 732px;
    border-right: 1px solid #ddd;
    padding: 0 38px 0 6px;
}
</style>
<style>
.shopItem .shopLink {
    display: inline-block;
    padding-right: 5px;
    color: #444;
}
.shopItem a.shopLink:hover {
    color: #827772;
}
.shopItem .externalLinkIcon {
    display: inline-block;
    font-size: 10px;
    color: #B2CFD2;
}
</style>
