<template>
    <div>
        <el-upload
            class="uploadButton"
            accept=".csv"
            :headers="header"
            :before-upload="importCsvStart"
            :on-error="importCsvError"
            :on-success="importCsvSuccess"
            :show-file-list="false"
            action="/api/organization/app_search_genres/csv/import"
        >
            <el-button type="warning" icon="el-icon-upload2">
                CSVアップロード
            </el-button>
        </el-upload>
        <el-button type="warning" @click="downloadCSV" icon="el-icon-download">
            CSVダウンロード
        </el-button>
        <el-table
            :data="tableData"
            style="width: 100%"
            max-height="800"
        >
            <el-table-column label="タグURL ID">
                <template slot-scope="scope">
                    <div>{{ scope.row.tag_path.id }}</div>
                </template>
            </el-table-column>
            <el-table-column label="タグ種別">
                <template slot-scope="scope">
                    <div v-if="scope.row.tag_path.parent_tag_id !== null">親</div>
                    <div v-else-if="scope.row.tag_path.child_tag_id !== null">子</div>
                    <div v-else>孫</div>
                </template>
            </el-table-column>
            <el-table-column label="タグ名">
                <template slot-scope="scope">
                    <div v-if="scope.row.tag_path.parent_tag_id !== null">{{ scope.row.tag_path.parent_tag.tag_name }}</div>
                    <div v-else-if="scope.row.tag_path.child_tag_id !== null">{{ scope.row.tag_path.child_tag.tag_name }}</div>
                    <div v-else>{{ scope.row.tag_path.grandchild_tag.tag_name }}</div>
                </template>
            </el-table-column>
            <el-table-column label="サムネイル" width="350">
                <template slot-scope="scope">
                    <div class="image">
                        <label :for="'image_' + scope.row.id">
                            <img v-if="scope.row.thumbnail_url" :src="scope.row.thumbnail_url" width="300" alt="サムネイル">
                        </label>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import FileDownload from 'js-file-download'

export default {
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data() {
        return {
            header: {
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            tableData: [],
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'アプリ　>　ジャンルから見つける')
        this.fetch()
    },
    methods: {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data, error } = await axios.get('/api/organization/app_search_genres')

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }
            this.tableData = data
            this.$store.commit(types.PAGE_LOADING, false)
        },
        importCsvStart() {
            this.$store.commit(types.LOADING, true)
        },
        importCsvError(e) {
            let errorMsg = 'CSVアップロードに失敗しました'
            if (e.status === 422) {
                errorMsg = JSON.parse(e.message)
            } else if (e.status === 403) {
                errorMsg = 'この操作は許可されていません。'
            } else if (e.status === 400) {
                errorMsg += '\nヘッダーが「タグURLID」になっているか確認してください。\n確認してもエラーが出る場合エンジニアに連絡お願いします。'
            }
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_ERROR_MESSAGE, errorMsg)
        },
        async importCsvSuccess(event) {
            if (event.status === 400) {
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_ERROR_MESSAGE, event.error)
            } else {
                await this.fetch()
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'CSVデータをアップロードしました')
            }
        },
        async downloadCSV() {
            this.$store.commit(types.PAGE_LOADING, true)
            const { data, error } = await axios.get('/api/organization/app_search_genres/csv/export')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            const bom  = new Uint8Array([0xEF, 0xBB, 0xBF])
            FileDownload(data, 'app_search_genre.csv', null, bom)
            this.$store.commit(types.PAGE_LOADING, false)
        },
    }
}
</script>
<style scoped>
    .uploadButton{
        display:inline-block;
    }
</style>
