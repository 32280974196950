<template>
    <div>
        <el-row type="flex" justify="end">
            <el-input class="search-input" placeholder="タイトル・ID" v-model="titleOrId" @keyup.enter.native="search()"></el-input>
            <el-input class="search-input" placeholder="ライター名" v-model="writerName" @keyup.enter.native="search()"></el-input>
            <el-button @click="search()" icon="el-icon-search" class="search-input" style="width: 60px;"></el-button>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <el-table
                :data="articles"
                style="width: 100%"
                max-height="800"
        >
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="記事タイトル" prop="title"></el-table-column>
            <el-table-column label="ライター名" prop="writer.screen_name"></el-table-column>
            <el-table-column label="公開日時" prop="published_at"></el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'

export default {
    components: {
        Pagination
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data() {
        return {
            articles   : [],
            pagination : {},
            titleOrId: '',
            writerName : '',
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '記事管理　>　非公開記事')
        this.queryInit()
        this.fetch()
    },
    methods: {
        queryInit() {
            this.titleOrId = this.$route.query.titleOrId ? this.$route.query.titleOrId : ''
            this.writerName = this.$route.query.writerName ? this.$route.query.writerName : ''
        },
        // 検索
        async fetch() {
            const page = Number(this.$route.query.page)
            const titleOrId = this.$route.query.titleOrId ? this.$route.query.titleOrId : ''
            const writerName = this.$route.query.writerName ? this.$route.query.writerName : ''

            this.$store.commit(types.PAGE_LOADING, true)
            let params = {page}
            if (titleOrId !== '') {
                params = Object.assign(params, titleOrId.match(/^[0-9]+$/) ? {id: titleOrId} : {title: titleOrId})
            }
            if (writerName !== '') {
                params.writer_name = writerName
            }
            const {data, error} = await axios.get('/api/article/downs', {
                params
            })
            this.$store.commit(types.PAGE_LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.articles = data.data
            this.pagination = Object.assign({}, this.pagination, data)
            this.$store.commit(types.PAGE_LOADING, false)
        },
        search() {
            const query = Object.assign({}, {
                titleOrId: this.titleOrId,
                writerName: this.writerName,
                page: 1
            })
            this.$router.push({ query })
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
    }
}
</script>

<style lang="scss" scoped>
    .search-button {
        height: 5%;
        margin-top: 1%;
        margin-left: 0.5%;
    }
    .search-input {
        width: 250px;
        margin: 0.5%;
    }
</style>
