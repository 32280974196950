<template>
    <div>
        <el-form :inline="true" :model="formData" class="demo-form-inline">
            <el-form-item>
                <el-select v-model="formData.serialization_type" placeholder="種類を選択してください" @change="changeSerializationType">
                    <el-option
                        v-for="serializationType in serializationTypeList"
                        :key="serializationType.value"
                        :label="serializationType.label"
                        :value="serializationType.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="formData.serialization_type === topicTypeValue">
                <el-input
                    v-model="formData.topic_id"
                    type="number"
                    placeholder="特集ID"
                ></el-input>
            </el-form-item>
            <el-form-item v-else-if="formData.serialization_type === serializationTypeValue">
                <el-input
                    v-model="formData.serialization_id"
                    type="number"
                    placeholder="連載ID"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button
                    @click="createAppRecipeRecommendSerialization"
                    type="primary"
                >
                    新規作成
                </el-button>
            </el-form-item>
            <p style="color: red;">※適用されるのは追加順の3件です</p>
        </el-form>

        <el-table
            :data="tableData"
            :row-class-name="tableRowClassName"
            style="width: 100%"
            max-height="800"
        >
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="種類">
                <template slot-scope="scope">
                    <div v-if="scope.row.topic === null">連載</div>
                    <div v-else>特集</div>
                </template>
            </el-table-column>
            <el-table-column label="特集/連載ID">
                <template slot-scope="scope">
                    <div v-if="scope.row.topic === null">{{ scope.row.serialization.id }}</div>
                    <div v-else>{{ scope.row.topic.id }}</div>
                </template>
            </el-table-column>
            <el-table-column label="タイトル">
                <template slot-scope="scope">
                    <div v-if="scope.row.topic === null">{{ scope.row.serialization.title }}</div>
                    <div v-else>{{ scope.row.topic.title }}</div>
                </template>
            </el-table-column>
            <el-table-column label="作成日時" prop="created_at"></el-table-column>
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                        class="btn-danger"
                        size="mini"
                        type="danger"
                        @click="deleteAppRecipeRecommendSerialization(scope.row.id)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import FileDownload from 'js-file-download'

export default {
    data() {
        return {
            tableData: [],
            serializationTypeList: [],
            formData: {
                topic_id: null,
                serialization_id: null,
                serialization_type: null,
            },
            topicTypeValue: 'topics',
            serializationTypeValue: 'serialize',
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'アプリ　>　レシピタブ　>　おすすめ連載')
        this.fetch()
    },
    methods: {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data, error } = await axios.get('/api/organization/app_recipe_recommend_serializations')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            this.tableData = data.app_recipe_recommend_serializations
            this.serializationTypeList = data.serialization_type_list
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async deleteAppRecipeRecommendSerialization(id) {
            if (! confirm('本当に削除しますか？')) {
                return
            }

            this.$store.commit(types.PAGE_LOADING, true)

            const { error } = await axios.delete('/api/organization/app_recipe_recommend_serializations/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '削除しました')
            this.fetch()
        },
        async createAppRecipeRecommendSerialization() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { error } = await axios.post('/api/organization/app_recipe_recommend_serializations', this.formData)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            this.formData = {
                topic_id: null,
                serialization_id: null,
                serialization_type: null,
            }
            this.fetch()
        },
        tableRowClassName({row, rowIndex}) {
            // 3件まで表示対象
            if (rowIndex < 3) {
                return 'active-serialization'
            }
        },
        changeSerializationType(type) {
            if (type === this.topicTypeValue) {
                this.formData.serialization_id = null
            } else if (type === this.serializationTypeValue) {
                this.formData.topic_id = null
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    ::v-deep .active-serialization {
        background-color: #e3f6ce;
    }
</style>
