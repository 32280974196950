<template>
    <div class="itemSection">
        <header-menu-bar
            v-if="!updateOrdersMode"
            :fetch="fetchAndUpdateOrder"
            :response="response"
            :article-format="articleFormat"
            :is-tieup="isTieup"
         />
        <div v-if="!updateOrdersMode">
            <div v-if="items.length > 0" class="itemHeader">
                <el-checkbox v-model="selectAllCheckbox" @change="checkAllItem">全アイテム選択</el-checkbox>
            </div>
            <items
                v-for="(item,index) in items"
                :item="item"
                :key="index"
                :number="index + 1"
                :order="index + 2"
                :response="response"
                :fetch="fetchAndUpdateOrder"
                :checkItem="checkItem"
                :article-format="articleFormat"
                :is-tieup="isTieup"
            />
        </div>
        <div v-if="updateOrdersMode">
            <items
                v-for="(item,index) in itemsForSaveCheckState"
                :number="index + 1"
                ref="draggableItemsComponent"
                :item="item"
                :key="index"
                :order="index + 2"
                :response="response"
                :fetch="fetchAndUpdateOrder"
                :checkItem="checkItem"
                :updateOrdersMode="updateOrdersMode"
                :article-format="articleFormat"
                :is-tieup="isTieup"
            />
        </div>
    </div>
</template>
<script>
import HeaderMenuBar from './ItemSection/HeaderMenuBar'
import Items from './ItemSection/Items'
import * as types from '../../../store/mutation-types'

export default {
    data () {
        return {
            itemsForSaveCheckState: [],
            sortTargetIndex:[],
            items:[],
            selectAllCheckbox: false
        }
    },
    components: {
        HeaderMenuBar,
        Items
    },
    props: {
        response: null,
        updateOrdersMode:Boolean,
        fetch: Function,
        articleFormat: String,
        isTieup: Boolean,
    },
    methods:{
        async fetchAndUpdateOrder() {
            this.$store.commit(types.EDITOR_LOADING,true)
            await this.fetch(false)
            await this.updateOrder(this.items.map((item) => { return {id: item.id, item_type: item.item_type} }))
            this.$store.commit(types.EDITOR_LOADING,false)
        },
        async saveUpdateOrder() {
            this.$store.commit(types.EDITOR_LOADING,true)
            await this.updateOrder(this.itemsForSaveCheckState.map((item) => { return {id: item.id, item_type: item.item_type} }))
            await this.fetch(false)
            this.$store.commit(types.EDITOR_LOADING,false)
        },
        async deleteItems() {
            this.$store.commit(types.PAGE_LOADING, true)
            const checkedItems =  this.items
                .map((item, index) => { item.number = index + 1; return item })
                .filter(item => { return item.checked === true})
            await axios.delete('/api/articles/' + this.$route.params.id +'/items', {
                data: {
                    delete_list: checkedItems.map((item) => { return {id: item.id, item_type: item.item_type} })
                }
            })
            await this.fetch(false)
            this.$store.commit(types.PAGE_LOADING, false)
            this.itemsForSaveCheckState = []
            this.selectAllCheckbox = false
            this.$emit('enableCheckedItemControll', false, [])
        },
        async updateOrder(form) {
            const { error } = await axios.patch('/api/articles/' + this.$route.params.id +'/items/orders', {order_list:form})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
        },
        checkItem(check, checkedItem) {
            let enableCheckedItemControll = false
            const items = (this.updateOrdersMode) ? this.itemsForSaveCheckState : this.items
            _.forEach(items, (item) => {
                if (item.id === checkedItem.id && item.item_type === checkedItem.item_type) {
                    item.checked = check
                }
                if (item.checked === true) {
                    enableCheckedItemControll = true
                }
                return item
            })

            const checkedItems =  this.items
                .map((item, index) => { item.number = index + 1; return item })
                .filter(item => { return item.checked === true})
            this.setSortTargetIndex()
            this.$emit('enableCheckedItemControll', enableCheckedItemControll, checkedItems)
        },
        checkAllItem(check) {
            _.forEach(this.items, (item, index) => {
                item.checked = check
                item.number = index + 1
                return item
            })
            this.$emit('enableCheckedItemControll', check, this.items)
        },
        setSortTargetIndex () {
            this.sortTargetIndex = []
            this.itemsForSaveCheckState.forEach((item, key) => {
                if (item.checked === true) {
                    this.sortTargetIndex.push(key)
                }
            })
        },
        moveUpCheckedOrder() {
            this.sortTargetIndex.forEach((index) => {
                if (index > 0) {
                    const movedIndex = index - 1
                    if (this.itemsForSaveCheckState[movedIndex].checked !== true) {
                        this.itemsForSaveCheckState.splice(movedIndex, 2, this.itemsForSaveCheckState[movedIndex+1], this.itemsForSaveCheckState[movedIndex])
                    }
                }
            })
            this.setSortTargetIndex()
        },
        moveDownCheckedOrder() {
            this.sortTargetIndex.reverse().forEach((index) => {
                const movedIndex = index + 1
                if (this.itemsForSaveCheckState[movedIndex]) {
                    if (this.itemsForSaveCheckState[movedIndex].checked !== true) {
                        this.itemsForSaveCheckState.splice(index, 2, this.itemsForSaveCheckState[movedIndex], this.itemsForSaveCheckState[index])
                    }
                }
            })
            this.setSortTargetIndex()
        },
        moveTopCheckedOrder() {
            let count = 0
            this.sortTargetIndex.reverse().forEach((index) => {
                const target = this.itemsForSaveCheckState.splice(index + count, 1)
                this.itemsForSaveCheckState.unshift(target[0])
                count++
            })
            this.setSortTargetIndex()

        },
        moveBottomCheckedOrder() {
            let count = 0
            this.sortTargetIndex.forEach((index) => {
                const target = this.itemsForSaveCheckState.splice(index - count, 1)
                this.itemsForSaveCheckState.push(target[0])
                count++
            })
            this.setSortTargetIndex()

        },
        movePositionCheckedOrder(position) {
            let count = 0
            let sortTargetList = []
            this.sortTargetIndex.forEach((index) => {
                const target = this.itemsForSaveCheckState.splice(index - count, 1)
                sortTargetList.push(target[0])
                count++
            })
            const positionIndex = position - 1
            Array.prototype.splice.apply(this.itemsForSaveCheckState,[positionIndex,0].concat(sortTargetList))
            this.setSortTargetIndex()
        },
    },
    watch: {
        response() {
            this.items = this.response.article.items.map(item => {
                return Object.assign({},item,{checked:false})
            })
            this.selectAllCheckbox = false
        },
        updateOrdersMode () {
            this.itemsForSaveCheckState = this.response.article.items.map(item => {
                return Object.assign({},item,{checked:false})
            })
            this.selectAllCheckbox = false
            this.$emit('enableCheckedItemControll', false, [])
        }
    }
}
</script>
<style>
.itemSection {
    margin-bottom:150px;
}
.itemHeader {
    padding: 0px 15px;
    padding-bottom: 5px;
}
.headerItemAddBox {
    width: 694px;
    margin-left: 28px;
}
.itemAddBox {
    width: 694px;
    margin-left: 38px;
}
</style>
