<template>
    <div>
        <el-row type="flex" justify="end">
            <el-button type="primary" @click="openRegistrationModal()">新規作成</el-button>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <el-table
            :data="milMovies"
            style="width: 100%"
            max-height="800"
        >
            <el-table-column label="タイトル" prop="title"></el-table-column>
            <el-table-column label="動画URL" prop="hls_video_cdn_url"></el-table-column>
            <el-table-column label="作成日時" prop="created_at" width="200"></el-table-column>
            <el-table-column label="更新日時" prop="updated_at" width="200"></el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <registration-modal
            :is-active="registration.isActive"
            @close="registration.isActive = false"
            @reload="init()"
        ></registration-modal>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import RegistrationModal from './MilMovie/RegistrationModal'

export default {
    components: {
        Pagination,
        RegistrationModal
    },
    data() {
        return {
            milMovies: [],
            pagination: {},
            registration : {
                isActive: false,
            },
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '記事作成データ管理　>　MIL動画')
        this.init()
    },
    methods: {
        init() {
            this.fetch()
        },
        // 検索
        async fetch() {
            const page = this.$route.query.page
            this.$store.commit(types.LOADING, true)
            const { data, error } = await axios.get('/api/mil_movies', {
                params: { page }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.milMovies = data.mil_movies.data
            this.pagination = Object.assign({},this.pagination, data.mil_movies)
            this.$store.commit(types.LOADING, false)
        },
        openRegistrationModal() {
            this.registration.isActive = true
        },
        handleCurrentChange (page) {
            this.$router.push({query: {page}})
            this.fetch()
        },
    }
}
</script>
