<template>
    <div>
        <el-row type="flex" justify="end">
            <el-input class="fetch-input" placeholder="インセンティブ名" v-model="searchForm.name" @keyup.enter.native="search(1)"></el-input>
            <el-button @click="search(1)" icon="el-icon-search" class="search-input" style="height: 40px;"></el-button>
        </el-row>
        <pagination :handleCurrentChange="search" :pagination="pagination"/>

        <el-row type="flex" style="margin: 30px 0;">
            <el-input v-model="createForm.name" placeholder="インセンティブ名" class="create-form-input" style="width: 300px;"></el-input>
            <el-select v-model="createForm.category_id" class="create-form-input">
                <el-option
                    v-for="category in categories"
                    :key="category.id"
                    :label="category.category_name"
                    :value="category.id">
                </el-option>
            </el-select>
            <el-button @click="openPriceModal(createForm.incentive_prices, true, null)" class="create-form-input">詳細登録</el-button>
            <el-button class="add-button create-form-input" type="primary" @click="create">新規作成</el-button>
        </el-row>

        <el-table
            :data="incentives"
            style="width: 100%"
        >
            <el-table-column label="インセンティブ名">
                <template slot-scope="scope">
                    <el-input placeholder="材料名" v-model="scope.row.name"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="カテゴリ名">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.category_id">
                        <el-option
                            v-for="category in categories"
                            :key="category.id"
                            :label="category.category_name"
                            :value="category.id">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="インセンティブ詳細">
                <template slot-scope="scope">
                    <el-button @click="openPriceModal(scope.row.article_incentive_prices, false, scope.$index)">確認</el-button>
                </template>
            </el-table-column>
            <el-table-column label="更新">
                <template slot-scope="scope">
                    <el-button
                        class="btn-warning"
                        type="warning"
                        @click="update(scope.row)"
                    >更新
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column label="削除">
                <template slot-scope="scope">
                    <el-button
                        class="btn-danger"
                        type="danger"
                        @click="deleteIncentive(scope.row)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="search" :pagination="pagination"/>
        <price-modal
            :is-active="isActivePriceModal"
            :incentive-prices="editIncentivePrices"
            :is-edit="isPriceEdit"
            @close="closePriceModal"
        ></price-modal>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import PriceModal from './Article/PriceModal'

export default {
    components: {
        Pagination,
        PriceModal,
    },
    data() {
        return {
            searchForm: {
                name: '',
            },
            createForm: {
                name: '',
                category_id: null,
                incentive_prices: []
            },
            pagination: {},
            incentives: [],
            categories: [],
            isActivePriceModal: false,
            editIncentivePrices: [],
            editIncentiveIdx: null,
            isPriceEdit: false,
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'ライター報酬管理　>　記事別固定インセンティブ一覧')
        this.searchForm.name = this.$route.query.name ? this.$route.query.name : ''
        this.fetch()
    },
    methods: {
        // 検索
        async fetch() {
            this.$store.commit(types.LOADING, true)

            const page = this.$route.query.page ? this.$route.query.page : 1
            const name = this.$route.query.name ? this.$route.query.name : ''

            const {data, error} = await axios.get('/api/article/incentives', {
                params: {
                    page: page,
                    name: name,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.pagination = Object.assign({}, data.article_incentives)
            this.incentives = Object.assign([], data.article_incentives.data)
            this.categories = Object.assign([], data.categories)

            this.$store.commit(types.LOADING, false)
        },
        search(page) {
            this.$router.push({
                query: {
                    page: page,
                    name: this.searchForm.name,
                }
            })
            this.fetch()
        },
        async update(incentive) {
            this.$store.commit(types.LOADING, true)

            const incentivePriceDeleteIds = incentive.hasOwnProperty('incentive_price_delete_ids') ? incentive.incentive_price_delete_ids : []
            const { data, error } = await axios.put('/api/article/incentives/'+incentive.id, {
                id: incentive.id,
                name: incentive.name,
                category_id: incentive.category_id,
                incentive_prices: incentive.article_incentive_prices,
                incentive_price_delete_ids: incentivePriceDeleteIds,
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.fetch()
        },
        async create() {
            this.$store.commit(types.LOADING, true)

            const { data, error } = await axios.post('/api/article/incentives', this.createForm)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.createForm = {
                name: '',
                category_id: null,
                incentive_prices: [
                    {
                        name: '',
                        price: 0,
                    }
                ]
            }

            this.fetch()
        },
        async deleteIncentive(incentive) {
            if (! confirm('未公開の記事に紐づいている場合、金額が計上されません。\n本当に削除しますか？')) {
                return
            }

            this.$store.commit(types.LOADING, true)

            const { data, error } = await axios.delete('/api/article/incentives/'+incentive.id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.fetch()
        },
        openPriceModal(incentivePrices, isEdit, idx) {
            this.editIncentivePrices = incentivePrices
            this.editIncentiveIdx = idx
            this.isPriceEdit = isEdit
            this.isActivePriceModal = true
        },
        closePriceModal(incentivePrices, deleteIncentivePriceIds) {
            if (this.editIncentiveIdx === null) {
                this.$set(this.createForm, 'incentive_prices', incentivePrices)
            } else {
                this.$set(this.incentives[this.editIncentiveIdx], 'article_incentive_prices', incentivePrices)
                this.$set(this.incentives[this.editIncentiveIdx], 'incentive_price_delete_ids', deleteIncentivePriceIds)
            }

            this.isActivePriceModal = false
        },
    }
}
</script>

<style lang="scss" scoped>
    .fetch-input {
        width: 500px;
        margin-right: 20px;
    }
    .create-form-input {
        margin-right: 10px;
    }
</style>
