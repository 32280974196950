<template>
    <el-dialog title="記事コピー" @close="$emit('close')" :visible="dialog" height="50%">
        <h3>ライターを選択して記事コピーすると、コピー先へ遷移します</h3>
        <el-form @submit.native.prevent :model="form"  label-width="120px" >
            <el-form-item label="ライター名" >
                <el-col :span="16">
                    <el-autocomplete
                        placeholder="ライター名"
                        v-model="form.writer_name"
                        @select="handleSelectWriter"
                        :fetch-suggestions="querySearchWriter"
                        :trigger-on-focus="true"
                        >
                    </el-autocomplete>
                </el-col>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
            <el-button type="primary" size="mini" @click="submitForm">コピー実行</el-button>
        </span>
    </el-dialog>
</template>

<script>
const formDefaultData = {
    writer_name: null,
}
export default {
    name: 'TrCopyArticleModal',
    props: {
        dialog: Boolean,
        input: Object,
        writers: Array,
    },
    data () {
        return {
            writerSuggestList:[],
            form: {...formDefaultData},
        }
    },
    watch: {
        dialog() {
            this.form = Object.assign({}, formDefaultData)
            this.writerSuggestList = Object.assign([],this.writers).map((writer) => {return {value:writer.writer_name}}).filter(writer => writer)
        }
    },
    methods: {
        submitForm () {
            this.$emit('save', this.input.id, this.form)
            this.$emit('close')
        },
        handleSelectWriter (item) {
            this.form.writer_name = item.value
        },
        querySearchWriter(queryString, cb) {
            const values = this.writerSuggestList
            const results = queryString ? values.filter(this.createFilter(queryString)) : values
            cb(results)
        },
        createFilter(queryString) {
            return (item) => {
                return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
            }
        },
    }
}
</script>
