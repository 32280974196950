<template>
    <el-card class="sectionCard" style="margin: 15px 0;">
        <el-tabs :stretch="true" >
            <el-tab-pane label="テキスト" >
                <text-type
                    class="itemAddBox"
                    :save="create"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane label="画像" >
                <image-type
                    class="itemAddBox"
                    :articleFormat="articleFormat"
                    :save="create"
                    :saveAndContinue="createAndContinue"
                    :is-tieup="false"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane label="リンク" >
                <link-type
                    class="itemAddBox"
                    :articleFormat="articleFormat"
                    :saveAndContinue="createAndContinue"
                    :save="create"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane label="動画" >
                <video-type
                    class="itemAddBox"
                    :save="create"
                    @close="closeMenu" />
            </el-tab-pane>
            <el-tab-pane label="店舗情報" >
                <place-type
                    class="itemAddBox"
                    :save="create"
                    :article-id="response.article.id"
                    @close="closeMenu"
                />
            </el-tab-pane>
        </el-tabs>
    </el-card >
</template>

<script>
import TextType from '../Form/TextType'
import ImageType from '../Form/ImageType'
import LinkType from '../Form/LinkType'
import VideoType from '../Form/VideoType'
import PlaceType from '../Form/PlaceType'

export default {
    components: {
        TextType,
        ImageType,
        LinkType,
        VideoType,
        PlaceType,
    },
    props: {
        articleFormat: String,
        response: null,
        isTieup: Boolean,
    },
    data () {
        return {
            menu: false,
        }
    },
    methods: {
        showItemMenu() {
            this.$emit('show')
        },
        closeMenu () {
            if (!confirm('キャンセルしますか？')) {
                return
            }
            this.$emit('close')
        },
        async create (type, form) {
            this.$emit('create', type, form)
        },
        async createAndContinue (type, form) {
            this.$emit('createAndContinue', type, form)
        },
    },
}
</script>
