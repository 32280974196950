<template>
    <div>
        <el-tag
            :disable-transitions="true"
            size="small"
            type="info"
            closable
            @close="handleClose(index)"
            class="wordList"
            v-for='(tag, index) in tags'
            :key=index
            :data-tag-id='tag.id'
            >
            {{tag.label}}
        </el-tag>
        <el-autocomplete
            class="inputNewTag"
            v-if="inputVisible"
            v-model="inputTag"
            ref="saveInputTag"
            @select="handleSelect"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="true"
            size="mini"
        >
            <template v-slot="{ item }">
                <div class="sub-label" v-if="item.parentLabel || item.childLabel">
                    <span v-if="item.parentLabel">{{ item.parentLabel }}</span>
                    <span v-if="item.childLabel"> > {{ item.childLabel }}</span>
                </div>
                <div class="tag">{{ item.label }}</div>
            </template>
        </el-autocomplete>
        <el-button
            v-else
            size="mini"
            class="wordList buttonNewTag"
            @click="showInput"
            >
            + 追加
        </el-button>
    </div>
</template>
<script>
export default {
    name: 'TagList',
    props: {
        tags: Array,
        input: Function,
        close: Function,
        suggestList: Array,
        update: Function,
    },
    data () {
        return {
            inputTag: '',
            inputVisible: false,
        }
    },
    methods: {
        showInput () {
            this.inputVisible = true
            this.$nextTick(() => {
                this.$refs.saveInputTag.$refs.input.focus()
            })
        },
        handleInput() {
            this.input(this.inputTag)
            this.inputVisible = false
            this.inputTag = ''
            this.$emit('update')
        },
        handleClose(index) {
            this.close(index)
            this.$emit('update')
        },
        querySearch(queryString, cb) {
            const values = this.suggestList.map(item => ({value: item.id, label: item.label, parentLabel: item.parent, childLabel: item.child}))
            const results = queryString ? values.filter(this.createFilter(queryString)) : values
            cb(results)
        },
        createFilter(queryString) {
            return (item) => {
                return (item.label.toLowerCase().indexOf(queryString.toLowerCase())  > -1)
            }
        },
        handleSelect(item) {
            this.inputTag = item.value
            this.handleInput()
            this.$emit('update')
        },
    }
}
</script>
<style scoped>
.inputNewTag {
  width: 300px;
  margin:5px 5px 0 0;
  vertical-align: bottom;
}
.wordList {
    margin:0 5px 5px 0;
}
.sub-label {
    overflow-x: auto;
    overflow-y: hidden;
    font-size: 11px;
    color: rgb(132, 146, 166);
    height: 23px;
}
.tag {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
}
</style>
