import { render, staticRenderFns } from "./ComparisonType.vue?vue&type=template&id=52490bc0&scoped=true"
import script from "./ComparisonType.vue?vue&type=script&lang=js"
export * from "./ComparisonType.vue?vue&type=script&lang=js"
import style0 from "./ComparisonType.vue?vue&type=style&index=0&id=52490bc0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52490bc0",
  null
  
)

export default component.exports