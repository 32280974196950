<template>
    <div>
        <el-form @close="$emit('close')" @submit.native.prevent>
            <el-form-item v-for="(element, idx) in elements" v-bind:key="idx">
                <div class="materialList">
                    <div class="materialListMainItem">
                        <el-autocomplete
                            v-model="element.material.material_name"
                            type="text"
                            size="mini"
                            :fetch-suggestions="suggestMaterial"
                            :popper-append-to-body="false"
                            @focus="focusSuggestList(idx)"
                            @select="showSuggestUnit"
                            placeholder="材料・調味料"
                            class="materialListMainItem__formElement"
                            popper-class="materialListMainItem__formElementSuggestPopper"
                        ></el-autocomplete>
                        <el-input
                            v-model="element.quantity_text"
                            type="text"
                            size="mini"
                            placeholder="分量"
                            class="materialListMainItem__quantityElement"
                        >
                            <el-button slot="append" @click="elementMoveUp(element, idx)" icon="el-icon-arrow-up"></el-button>
                            <el-button slot="append" @click="elementMoveDown(element, idx)" icon="el-icon-arrow-down"></el-button>
                            <el-button slot="append" @click="elementRemove(element, idx)" icon="el-icon-close"></el-button>
                        </el-input>
                        <el-select
                            v-if="element.suggest_unit_list.length"
                            v-model="element.suggest_unit_name"
                            placeholder="単位"
                            size="mini"
                            class="materialListMainItem__unitElement"
                            @change="setUnitName(element)">
                            <el-option
                                v-for="(value, index) in element.suggest_unit_list"
                                :key="index"
                                :label="value"
                                :value="value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="materialListSubItem">
                        <el-input
                            v-model="element.material_group_name"
                            type="text"
                            size="mini"
                            placeholder="グループ名"
                            class="materialListSubItem__groupElement"
                        ></el-input>
                        <el-input
                            :value="element.material.material_category ? element.material.material_category.material_category_name : ''"
                            type="text"
                            size="mini"
                            disabled
                            placeholder="マスタなし"
                            class="materialListSubItem__categoryElement"
                        ></el-input>
                        <el-checkbox
                            v-model="element.is_main"
                            size="mini"
                            class="materialListSubItem__labelElement"
                        >メイン食材</el-checkbox>
                    </div>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button size="small" @click="addElement()" style="margin-bottom: 10px;">行を追加する</el-button>
            </el-form-item>
        </el-form>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
    </div>
</template>

<script>

import * as types from '../../../../../../store/mutation-types'

const defaultFormValues = () => ({
    elements: []
})
const defaultElementFormValues = () => ({
    id: null,
    material_group_name: null,
    quantity_text: null,
    is_main: false,
    material: {
        material_name: null,
        material_category: {
            material_category_name: null,
        }
    },
    suggest_unit_list: [],
})
export default {
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        resourceName: {
            required: true,
            type: String,
        },
    },
    data () {
        return {
            form: defaultFormValues(),
            elements: [defaultElementFormValues()],
            focusedLineIndex: null,
        }
    },
    methods: {
        submitForm () {
            this.form.elements = this.elements.map((element) => ({
                id: element.id,
                material_name: element.material.material_name,
                material_group_name: element.material_group_name,
                quantity_text: element.quantity_text,
                is_main: element.is_main,
            }))
            this.save(this.resourceName, this.form, this.id)
        },
        openDialog(input) {
            this.form = {...defaultFormValues, ..._.pick(input, Object.keys(this.form))}
            this.elements = input.elements.map((element) => ({...defaultElementFormValues(), ..._.pick(element, Object.keys(defaultElementFormValues()))}))
        },
        addElement() {
            this.elements.push(defaultElementFormValues())
        },
        elementMoveDown(element, index) {
            const movedIndex = index + 1
            if (this.elements[movedIndex]) {
                this.elements.splice(index, 2, this.elements[movedIndex], this.elements[index])
            }
        },
        elementMoveUp(element, index) {
            if (index > 0) {
                const movedIndex = index - 1
                this.elements.splice(movedIndex, 2, this.elements[movedIndex+1], this.elements[movedIndex])
            }
        },
        elementRemove(element, index) {
            if (this.elements.length <= 1) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '最低1つ必要です。')
                return
            }

            this.elements.splice(index, 1)
        },
        focusSuggestList(index) {
            this.elements[index].suggest_unit_list = []
            this.elements[index].suggest_unit_name = null
            this.focusedLineIndex = index
        },
        async suggestMaterial(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/articles/items/materials/suggest', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'URL検索に失敗しました')
                    return
                }
                cb(data.suggest_materials)
            }
        },
        showSuggestUnit(item) {
            this.elements[this.focusedLineIndex].suggest_unit_list = item.unit_name
            this.elements[this.focusedLineIndex].material.material_name = this.elements[this.focusedLineIndex].material.material_name.replace('【商品名】', '')
        },
        setUnitName(element) {
            element.quantity_text = element.suggest_unit_name
            element.suggest_unit_name = null
        },
    }
}
</script>

<style scoped lang="scss">
.materialList {
    display: flex;
    flex-direction: column;
}
::v-deep .materialListMainItem__formElementSuggestPopper {
    width: 300px !important;
}
.materialListMainItem {
    display: flex;
    &__formElement {
        width: 157px;
        margin: 0 5px 0 0;
    }
    &__quantityElement {
        width: 271px;
        margin: 0 5px 0 0;
    }
    &__unitElement {
        width: 120px;
        margin: 0 5px 0 0;
    }
}
.materialListSubItem {
    display: flex;
    margin: 5px 0 0 0;
    padding: 0 0 0 162px;
    &__groupElement {
        width: 271px;
        margin: 0 5px 0 0;
    }
    &__categoryElement {
        width: 120px;
        margin: 0 5px 0 0;
    }
    &__labelElement {
        width: 120px;
    }
}
</style>
