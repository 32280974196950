<template>
    <!--
    並び替え可能なinputフォームリストのコンポーネント
    -->
    <div class="sortableInputList">
        <el-form-item
            v-for="(item, idx) in value"
            :key="idx"
            :label="item.label"
        >
            <div class="inlineItem">
                <el-input
                    :value="item.value"
                    type="text"
                    size="mini"
                    @input="
                        newValue => $emit('input',
                            value.map((v, i) => i === idx ? { label: v.label, value: newValue } : v)
                        )
                    "
                ></el-input>
                <div class="controlBtnList">
                    <!-- 上に移動 -->
                    <el-button
                        type="info"
                        icon="el-icon-caret-top"
                        size="mini"
                        :disabled="idx === 0"
                        @click="
                            $emit('input', value.map((v, i) => {
                                switch (i) {
                                    case idx - 1: return value[idx]
                                    case idx: return value[idx - 1]
                                    default: return v
                                }
                            }))
                        "
                    ></el-button>
                    <!-- 下に移動 -->
                    <el-button
                        type="info"
                        icon="el-icon-caret-bottom"
                        size="mini"
                        :disabled="idx === value.length - 1"
                        @click="
                            $emit('input', value.map((v, i) => {
                                switch (i) {
                                    case idx: return value[idx + 1]
                                    case idx + 1: return value[idx]
                                    default: return v
                                }
                            }))
                        "
                    ></el-button>
                    <!-- 削除 -->
                    <el-button
                        plain
                        type="warning"
                        icon="el-icon-delete"
                        size="mini"
                        @click="$emit('input', value.filter((_, i) => i !== idx))"
                    ></el-button>
                </div>
            </div>
        </el-form-item>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array, // : Array<{label: '', value: ''}>
            default: () => []
        },
    },
}
</script>

<style scoped>
.inlineItem {
    display: flex;
    gap: 7px;
}
.controlBtnList {
    display: flex;
    gap: 3px;
}

/* ElementUIスタイルリセット */
.el-button+.el-button {
    margin-left: 0;
}
</style>
