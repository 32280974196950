<template>
    <div>
        <el-form  class="itemForm" @submit.native.prevent label-width="120px" >
           <el-form-item label="コメント">
               <el-input
                   type="textarea"
                   :autosize="{ minRows: 4}"
                   v-model="form.video_comment">
               </el-input>
           </el-form-item>
        </el-form>
        <el-row>
            <div class="itemFormFooter">
                <el-button @click="$emit('close')">キャンセル</el-button>
                <el-button type="primary" :disabled="!form.video_comment" @click="submitForm">保存する</el-button>
            </div>
        </el-row>
    </div>
</template>

<script>

import * as types from '../../../../../../store/mutation-types'

const defaultFormValues =  {
    video_comment: null,
}
export default {
    props: {
        id: null,
        input:null,
        save:Function,
    },
    data () {
        return {
            form: {...defaultFormValues},
        }
    },
    mounted() {
        this.form = {...defaultFormValues}
    },
    methods: {
        submitForm(){
            this.save('videos', this.form, this.id)
        },
        openDialog(input) {
            this.form = Object.assign({}, defaultFormValues, _.pick(input, Object.keys(this.form)))
        }
    }
}
</script>
