<template>
    <div>
        <el-row>
            <el-table :data="csvTableData" style="width: 100%">
                <el-table-column label="新規作成" width="210px">
                    <template v-slot="scope">
                        <div class="buttonList">
                            <csv-upload
                                :button-text="scope.row.create.upload.name"
                                :name="scope.row.create.upload.csvName"
                                :url="scope.row.create.upload.url"
                            />
                            <csv-download
                                el-button-type="danger"
                                :buttonName="scope.row.create.download.name"
                                :name="scope.row.create.download.csvName"
                                :url="scope.row.create.download.url"
                            />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="更新" width="210px">
                    <template v-slot="scope">
                        <div class="buttonList">
                            <csv-upload
                                :button-text="scope.row.update.upload.name"
                                :name="scope.row.update.upload.csvName"
                                :url="scope.row.update.upload.url"
                            />
                            <csv-download
                                el-button-type="danger"
                                :buttonName="scope.row.update.download.name"
                                :name="scope.row.update.download.csvName"
                                :url="scope.row.update.download.url"
                            />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="記事紐付け" width="210px">
                    <template v-slot="scope">
                        <div class="buttonList">
                            <csv-upload
                                :button-text="scope.row.relation.upload.name"
                                :name="scope.row.relation.upload.csvName"
                                :url="scope.row.relation.upload.url"
                            />
                            <csv-download
                                el-button-type="danger"
                                :buttonName="scope.row.relation.download.name"
                                :name="scope.row.relation.download.csvName"
                                :url="scope.row.relation.download.url"
                            />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="よくある質問" width="210px">
                    <template v-slot="scope">
                        <div class="buttonList">
                            <csv-upload
                                :button-text="scope.row.faq.upload.name"
                                :name="scope.row.faq.upload.csvName"
                                :url="scope.row.faq.upload.url"
                            />
                            <csv-download
                                el-button-type="danger"
                                :buttonName="scope.row.faq.download.name"
                                :name="scope.row.faq.download.csvName"
                                :url="scope.row.faq.download.url"
                            />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="組み合わせ" width="210px">
                    <template v-slot="scope">
                        <div class="buttonList">
                            <csv-upload
                                :button-text="scope.row.combination.upload.name"
                                :name="scope.row.combination.upload.csvName"
                                :url="scope.row.combination.upload.url"
                            />
                            <csv-download
                                el-button-type="danger"
                                :buttonName="scope.row.combination.download.name"
                                :name="scope.row.combination.download.csvName"
                                :url="scope.row.combination.download.url"
                            />
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>

        <el-row type="flex" justify="end" style="margin-top:10px;">
            <el-input
                placeholder="親タグ名 子タグ名 孫タグ名"
                v-model="keywords"
                class="search keyword"
                @keyup.enter.native="search()"
            ></el-input>
            <el-button
                @click="search()"
                icon="el-icon-search"
                class="search-input"
                style="height: 40px; margin-top: 10px;"
            ></el-button>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <el-row v-for='(parent, parentIndex) in tableData' :key="parentIndex" style="margin-bottom:30px;">
            <el-col >
                <el-card class="box-card" >
                    <el-table border :data="parent" :default-expand-all="true" class="parentTable">
                        <el-table-column fixed type="expand">
                            <template slot-scope="props">
                                <el-table border :data="props.row.children" :default-expand-all="true" header-row-class-name="tabTableHeader">
                                    <el-table-column fixed type="expand">
                                        <template slot-scope="props">
                                            <el-table border :data="props.row.grandchildren" header-row-class-name="tabTableHeader">
                                                <el-table-column width="100" prop="id" label="タグID" />
                                                <el-table-column prop="tag_name" label="孫タグ名" />
                                                <el-table-column width="100">
                                                    <template slot-scope="scope">
                                                        <el-button
                                                                class="btn-edit"
                                                                size="mini"
                                                                @click="editRow(scope.row)"
                                                        >編集</el-button>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="100" prop="id" label="タグID" />
                                    <el-table-column prop="tag_name" label="子タグ名" />
                                    <el-table-column width="100">
                                        <template slot-scope="scope">
                                            <el-button
                                                    class="btn-edit"
                                                    size="mini"
                                                    @click="editRow(scope.row)"
                                            >編集</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>

                            </template>
                        </el-table-column>
                        <el-table-column width="100" prop="id" label="タグID" />
                        <el-table-column prop="tag_name" label="親タグ名" />
                        <el-table-column width="100">
                            <template slot-scope="scope">
                                <el-button
                                    class="btn-edit"
                                    size="mini"
                                    @click="editRowParent(scope.row)"
                                >編集</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <edit-modal :save="update" :dialog="dialog" :closeDialog="closeDialog" :input="form" :isParent="isParent"/>
    </div>
</template>

<script>

import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import EditModal from './Tag/EditModal'
import CsvDownload from '../../components/CsvDownload.vue'
import CsvUpload from '../../components/CsvUpload.vue'

export default {
    components: {
        CsvUpload,
        CsvDownload,
        Pagination,
        EditModal
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
        this.queryInit()
    },
    data() {
        return {
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            form: {},
            dialog: false,
            parentTags: [],
            pagination: {},
            tableData: [],
            isParent: false,
            keywords: '',
            csvTableData: [
                {
                    create: {
                        upload: {
                            name: 'CSVアップロード',
                            url: '/api/tags/csv/import/create',
                            csvName: 'tag_create_csv',
                        },
                        download: {
                            name: 'CSVダウンロード',
                            url: '/api/tags/csv/export/create',
                            csvName: 'tag_create_template.csv',
                        }
                    },
                    update: {
                        upload: {
                            name: 'CSVアップロード',
                            url: '/api/tags/csv/import/update',
                            csvName: 'tag_update_csv',
                        },
                        download: {
                            name: 'CSVダウンロード',
                            url: '/api/tags/csv/export/update',
                            csvName: 'tag_update.csv',
                        }
                    },
                    relation: {
                        upload: {
                            name: 'CSVアップロード',
                            url: '/api/tags/csv/import/relation',
                            csvName: 'upload_tag',
                        },
                        download: {
                            name: 'CSVダウンロード',
                            url: '/api/tags/csv/export/relation',
                            csvName: 'tag_relations.csv',
                        }
                    },
                    faq: {
                        upload: {
                            name: 'CSVアップロード',
                            url: '/api/tag_faqs/import',
                            csvName: 'csv',
                        },
                        download: {
                            name: 'CSVダウンロード',
                            url: '/api/tag_faqs/export',
                            csvName: 'tag_faqs.csv',
                        }
                    },
                    combination: {
                        upload: {
                            name: 'CSVアップロード',
                            url: '/api/tag_combinations/import',
                            csvName: 'csv',
                        },
                        download: {
                            name: 'CSVダウンロード',
                            url: '/api/tag_combinations/export',
                            csvName: 'tag_combinations.csv',
                        }
                    },
                }
            ]
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '記事作成データ管理　>　タグマスタ管理')
        this.queryInit()
        this.fetch()
    },
    methods: {
        queryInit() {
            this.keywords = this.$route.query.keywords ? this.$route.query.keywords : null
        },
        search() {
            const query = Object.assign({}, this.$route.query, {
                keywords: this.keywords,
                page    : 1
            })
            this.$router.push({query})
        },
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data } = await axios.get('/api/tags', {
                params: { ...this.$route.query }
            })

            this.pagination = Object.assign({},this.pagination, data.tags)

            const tags = Object.assign([], data.tags.data)
            this.tableData = _.map(tags, (tag) => {
                return [tag]
            })

            this.$store.commit(types.PAGE_LOADING, false)
        },
        editRow (form) {
            this.form = Object.assign({}, form)
            this.dialog = true
            this.isParent = false
        },
        editRowParent (form) {
            this.form = Object.assign({}, form)
            this.dialog = true
            this.isParent = true
        },
        async update (form) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.post('/api/tags/update', form)
            if (error) {
                this.$store.commit(types.LOADING, false)
                return
            }

            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'タグを変更しました')
            this.$store.commit(types.LOADING, false)
        },
        handleCurrentChange (page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
        },
        closeDialog () {
            this.dialog = false
        },
        importCsvStart() {
            this.$store.commit(types.LOADING, true)
        },
        importCsvError(e) {
            console.log(e)
            let errorMsg = '紐付けに失敗しました。'
            if (e.status === 403) {
                errorMsg = 'この操作は許可されていません。'
            }
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_ERROR_MESSAGE, errorMsg)
        },
        async importCsvSuccess(event) {
            if (event.status === 400) {
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_ERROR_MESSAGE, event.error)
            } else {
                await this.fetch()
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'CSVデータをアップロードしました')
            }
        },
    }
}
</script>
<style>

.uploadButton{
    display:inline-block;
}
.new-btn{
    text-align: end;
    padding: 10px 10px 10px 10px;
}
</style>
<style scoped>
.search{
    margin: 10px 5px 0;
}
.search.keyword {
    width: 400px;
}
.buttonList {
    height: 95px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
</style>
