<template>
    <div class="mediaIkyu">
        <div class="searchForm">
            <el-tooltip content="店舗名" placement="top" effect="light" :disabled="value.keyword.length === 0">
                <el-input
                    class="keywordInput"
                    type="text"
                    size="mini"
                    placeholder="店舗名"
                    :value="value.keyword"
                    @input="$emit('input', { ...value, keyword: $event })"
                    @keydown.enter.native="handleInputEnter"
                />
            </el-tooltip>
            <el-tooltip content="駅名" placement="top" effect="light" :disabled="value.area.length === 0">
                <el-input
                    class="areaInput"
                    type="text"
                    size="mini"
                    placeholder="駅名"
                    :value="value.area"
                    @input="$emit('input', { ...value, area: $event })"
                    @keydown.enter.native="handleInputEnter"
                />
            </el-tooltip>
            <el-button
                type="info"
                size="mini"
                icon="el-icon-search"
                @click="$emit('search', 1)"
            />
        </div>
        <el-table class="table" :data="searchResult">
            <el-table-column label="店舗名" prop="name" />
            <el-table-column label="エリア" prop="address" />
            <el-table-column label="その他情報" prop="memo" />
            <el-table-column label="店舗リンク" v-slot="scope">
                <a :href="scope.row.url" target="_blank"><i class="el-icon-link" /></a>
            </el-table-column>
            <el-table-column v-slot="scope">
                <el-button type="info" size="mini" @click="$emit('add', scope.row.affiliateUrl)">追加する</el-button>
            </el-table-column>
        </el-table>
        <el-button
            v-if="hasNext"
            class="nextLoadButton"
            @click="$emit('search', page + 1)"
        >次を読み込む</el-button>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        page: {
            type: Number,
            required: true,
        },
        hasNext: {
            type: Boolean,
            required: true,
        },
        searchResult: {
            type: Array,
            required: true,
        },
    },
    methods: {
        handleInputEnter(keyboardEvent) {
            if (keyboardEvent.isComposing) { // IME入力中はスルー
                return
            }
            this.$emit('search', 1)
        },
    }
}
</script>

<style scoped>
.searchForm {
    display: flex;
    gap: 5px;
    margin: 0 0 10px 0;
}
.keywordInput {
    width: 350px;
}
.areaInput {
    width: 300px;
}

.table {
    width: 100%;
    max-height: 700px;
    overflow: scroll;
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.25rem;
    }
    &::-webkit-scrollbar-thumb {
        --bg-opacity: 1;
        background-color: #444;
    }
}

.nextLoadButton {
    width: 100%;
}
</style>
