<template>
    <div class="singleTagRelation" :class="{column: flexDirectionColumn}">
        <el-select
            @focus="handleFocusForm(TAG_TYPE.parent, null)"
            @change="handleChangeTag(TAG_TYPE.parent, $event)"
            filterable
            clearable
            placeholder="親タグ"
            :disabled="disabled"
            :value="tagPathRelation.parent_tag_path?.id"
            :loading="isSearchLoading"
        >
            <el-option
                v-for="tag in selectOptionsParent"
                :key="tag.id"
                :label="tag.tag_name"
                :value="tag.id"
            ></el-option>
        </el-select>
        <el-select
            @focus="handleFocusForm(TAG_TYPE.child, tagPathRelation.parent_tag_path.id)"
            @change="handleChangeTag(TAG_TYPE.child, $event)"
            filterable
            clearable
            placeholder="子タグ"
            :disabled="disabled || tagPathRelation.parent_tag_path === null"
            :value="tagPathRelation.child_tag_path?.id"
            :loading="isSearchLoading"
        >
            <el-option
                v-for="tag in selectOptionsChild"
                :key="tag.id"
                :label="tag.tag_name"
                :value="tag.id"
            ></el-option>
        </el-select>
        <el-select
            @focus="handleFocusForm(TAG_TYPE.grandchild, tagPathRelation.child_tag_path.id)"
            @change="handleChangeTag(TAG_TYPE.grandchild, $event)"
            filterable
            clearable
            placeholder="孫タグ"
            :disabled="disabled || tagPathRelation.child_tag_path === null"
            :value="tagPathRelation.grandchild_tag_path?.id"
            :loading="isSearchLoading"
        >
            <el-option
                v-for="tag in selectOptionsGrandchild"
                :key="tag.id"
                :label="tag.tag_name"
                :value="tag.id"
            ></el-option>
        </el-select>
    </div>
</template>

<script>
const TAG_TYPE = () => ({
    parent: 'PARENT',
    child: 'CHILD',
    grandchild: 'GRANDCHILD',
})

export default {
    props: {
        value: {
            type: Number,
            required: false,
            default: null,
        },
        tagClass: {
            type: String,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        tagPathsKeyByEndTagId: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        defaultParentTagPaths: {
            type: Array,
            required: false,
            default: () => [],
        },
        flexDirectionColumn: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data () {
        return {
            parentTagPaths: [],
            childTagPaths: [],
            grandchildTagPaths: [],
            isSearchLoading: false,
        }
    },
    computed: {
        TAG_TYPE,
        tagPathRelation() {
            return this.value !== null
                ? this.tagPathsKeyByEndTagId[this.value]
                : {
                    parent_tag_path: null,
                    child_tag_path: null,
                    grandchild_tag_path: null,
                }
        },
        selectOptionsParent() {
            if (this.value === null) {
                return this.parentTagPaths
            }

            // 選択されているタグはデフォルトでoptionsに含める
            return ! this.parentTagPaths.some(tag => tag.id === this.tagPathRelation.parent_tag_path.id)
                ? [this.tagPathRelation.parent_tag_path, ...this.parentTagPaths]
                : this.parentTagPaths
        },
        selectOptionsChild() {
            if (
                this.value === null
                || this.tagPathRelation.child_tag_path === null
            ) {
                return this.childTagPaths
            }

            // 選択されているタグはデフォルトでoptionsに含める
            return ! this.childTagPaths.some(tag => tag.id === this.tagPathRelation.child_tag_path.id)
                ? [this.tagPathRelation.child_tag_path, ...this.childTagPaths]
                : this.childTagPaths
        },
        selectOptionsGrandchild() {
            if (
                this.value === null
                || this.tagPathRelation.grandchild_tag_path === null
            ) {
                return this.grandchildTagPaths
            }

            // 選択されているタグはデフォルトでoptionsに含める
            return ! this.grandchildTagPaths.some(tag => tag.id === this.tagPathRelation.grandchild_tag_path.id)
                ? [this.tagPathRelation.grandchild_tag_path, ...this.grandchildTagPaths]
                : this.grandchildTagPaths
        },
    },
    watch: {
        defaultParentTagPaths: {
            handler (newVal) {
                if (this.defaultParentTagPaths.length > 0 && this.parentTagPaths.length === 0) {
                    this.parentTagPaths = structuredClone(newVal)
                }
            },
            immediate: true,
        },
    },
    methods: {
        /**
         * タグフォーム選択時（プルダウンオープン）ハンドラ
         * タグリストを取得してセットしますが、すでにロード済みの場合はスキップします
         *
         * @param {string} tagType
         * @param {number|null} baseTagPathId
         * @returns {Promise<void>}
         */
        async handleFocusForm (tagType, baseTagPathId) {
            switch (tagType) {
            case TAG_TYPE().parent:
                if (this.parentTagPaths.length === 0) {
                    await this.fetchTags(TAG_TYPE().parent, null)
                }
                break
            case TAG_TYPE().child:
                if (this.childTagPaths.length === 0) {
                    await this.fetchTags(TAG_TYPE().child, baseTagPathId)
                }
                break
            case TAG_TYPE().grandchild:
                if (this.grandchildTagPaths.length === 0) {
                    await this.fetchTags(TAG_TYPE().grandchild, baseTagPathId)
                }
                break
            }
        },
        /**
         * タグ変更時ハンドラ
         *
         * @param {string} tagType
         * @param {number|''} newTagId クリアボタン押下時に空文字が渡される
         */
        async handleChangeTag (tagType, newTagId) {
            switch (tagType) {
            case TAG_TYPE().parent:
                this.$emit('input', newTagId === '' ? null : newTagId)
                // 下位レイヤーを初期化・再取得
                this.childTagPaths = []
                await this.fetchTags(TAG_TYPE().child, newTagId)
                return
            case TAG_TYPE().child:
                this.$emit('input', newTagId === '' ? this.tagPathRelation.parent_tag_path.id : newTagId)
                // 下位レイヤーを初期化・再取得
                this.grandchildTagPaths = []
                await this.fetchTags(TAG_TYPE().grandchild, newTagId)
                return
            case TAG_TYPE().grandchild:
                this.$emit('input', newTagId === '' ? this.tagPathRelation.child_tag_path.id : newTagId)
                return
            }
        },
        /**
         * 上位タグをもとに下位タグリストを検索
         *
         * @param {string} tagType
         * @param {number|null} baseTagPathId
         * @returns {Promise<void>}
         */
        async fetchTags(tagType, baseTagPathId) {
            if (this.isSearchLoading) {
                return
            }

            this.isSearchLoading = true
            const { data } = await axios.get('/api/tags/search', {
                params: {
                    tag_type: tagType,
                    base_tag_path_ids: baseTagPathId !== null ? [baseTagPathId] : [],
                    tag_class: this.tagClass,
                }
            })

            switch (tagType) {
            case TAG_TYPE().parent:
                this.parentTagPaths = data.tag_paths
                break
            case TAG_TYPE().child:
                this.childTagPaths = data.tag_paths
                break
            case TAG_TYPE().grandchild:
                this.grandchildTagPaths = data.tag_paths
                break
            }

            this.isSearchLoading = false
        },
    }
}
</script>

<style scoped>
.column {
    flex-direction: column;
}
.singleTagRelation {
    display: flex;
    gap: 5px;
}
</style>
