<template>
    <div>
        <el-dialog
            :title="title"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
            width="70%"
        >
            <el-form ref="form" :model="editForm" label="タイトル" label-width="130px">
                <el-form-item label="タイトル">
                    <el-input v-model="editForm.title" placeholder="タイトル"></el-input>
                </el-form-item>
                <el-form-item label="アイコン">
                    <el-upload
                            action=""
                            accept="image/png,image/jpeg"
                            list-type="picture"
                            class="form-icon"
                            :show-file-list="false"
                            :auto-upload="false"
                            :on-change="handleIconAdd"
                            :on-remove="handleIconRemove"
                    >
                        <img v-if="previewIcon" :src="previewIcon" class="form-icon-img">
                        <el-button v-else size="small" type="primary">画像選択</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="背景画像">
                    <el-upload
                            action=""
                            accept="image/png,image/jpeg"
                            list-type="picture"
                            class="form-background"
                            :show-file-list="false"
                            :auto-upload="false"
                            :on-change="handleBackgroundAdd"
                            :on-remove="handleBackgroundRemove"
                    >
                        <img v-if="previewBackground" :src="previewBackground" class="form-background-img">
                        <el-button v-else size="small" type="primary">画像選択</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="上部詳細">
                    <medium-editor
                            :text="editForm.top_text"
                            :options="editorOptions"
                            v-on:edit="topTextEdit"
                    ></medium-editor>
                </el-form-item>
                <el-form-item label="下部詳細">
                    <medium-editor
                            :text="editForm.bottom_text"
                            :options="editorOptions"
                            v-on:edit="bottomTextEdit"
                    ></medium-editor>
                </el-form-item>
                <el-form-item label="公開">
                    <el-checkbox
                        v-model="editForm.is_active"
                    ></el-checkbox>
                </el-form-item>
                <el-form-item label="終了日時">
                    <el-date-picker
                        v-model="editForm.end_at"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <!-- 応募フォーム -->
                <div class="entry-form-title">応募フォーム</div>
                <el-form-item label="項目追加">
                    <el-select v-model="selectInputType" placeholder="項目">
                        <el-option
                                v-for="item in inputTypes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- テキスト追加フォーム -->
                <el-form-item v-if="selectInputType === 'text'" label="テキスト">
                    <input-text
                        :html-classes="htmlClasses"
                        @addFormHtml="addFormHtml"
                    ></input-text>
                </el-form-item>
                <!-- テキストエリア追加フォーム -->
                <el-form-item v-if="selectInputType === 'textarea'" label="テキストエリア">
                    <input-textarea
                            :html-classes="htmlClasses"
                            @addFormHtml="addFormHtml"
                    ></input-textarea>
                </el-form-item>
                <!-- チェックボックス追加フォーム -->
                <el-form-item v-if="selectInputType === 'checkbox'" label="チェックボックス">
                    <input-checkbox
                            :html-classes="htmlClasses"
                            @addFormHtml="addFormHtml"
                    ></input-checkbox>
                </el-form-item>
                <!-- ラジオ追加フォーム -->
                <el-form-item v-if="selectInputType === 'radio'" label="ラジオ">
                    <input-radio
                            :html-classes="htmlClasses"
                            @addFormHtml="addFormHtml"
                    ></input-radio>
                </el-form-item>
                <!-- セレクト追加フォーム -->
                <el-form-item v-if="selectInputType === 'select'" label="セレクト">
                    <input-select
                            :html-classes="htmlClasses"
                            @addFormHtml="addFormHtml"
                    ></input-select>
                </el-form-item>
                <!-- 日付追加フォーム -->
                <el-form-item v-if="selectInputType === 'date'" label="日付">
                    <input-date
                            :html-classes="htmlClasses"
                            @addFormHtml="addFormHtml"
                    ></input-date>
                </el-form-item>
                <!-- メールアドレスフォーム -->
                <el-form-item v-if="selectInputType === 'email'" label="メールアドレス">
                    <input-email
                            :html-classes="htmlClasses"
                            @addFormHtml="addFormHtml"
                    ></input-email>
                </el-form-item>
                <!-- 郵便番号フォーム -->
                <el-form-item v-if="selectInputType === 'zip'" label="郵便番号">
                    <input-zip
                            :html-classes="htmlClasses"
                            @addFormHtml="addFormHtml"
                    ></input-zip>
                </el-form-item>
                <!-- 電話番号フォーム -->
                <el-form-item v-if="selectInputType === 'tel'" label="電話番号">
                    <input-tel
                            :html-classes="htmlClasses"
                            @addFormHtml="addFormHtml"
                    ></input-tel>
                </el-form-item>
            </el-form>

            <!-- 応募フォームアイテム -->
            <draggable v-model="editFormHtmlList" class="entry-form">
                <el-row v-for="(html, key) in editFormHtmlList" :key="key" class="entry-form-content">
                    <span v-html="html"></span>
                    <div class="action">
                        <i class="action-icon fas fa-arrows-alt fa-2x"></i>
                        <span @click="deleteFormHtml(key)"><i class="action-icon fas fa-times fa-2x"></i></span>
                    </div>
                </el-row>
            </draggable>

            <span slot="footer" class="dialog-footer">
                <el-button @click="close">キャンセル</el-button>
                <el-button type="primary" @click="save">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import InputText from './Input/Text'
import InputTextarea from './Input/Textarea'
import InputCheckbox from './Input/Checkbox'
import InputRadio from './Input/Radio'
import InputSelect from './Input/Select'
import InputDate from './Input/Date'
import InputEmail from './Input/Email'
import InputZip from './Input/Zip'
import InputTel from './Input/Tel'
import draggable from 'vuedraggable'
import MediumEditor from 'vue2-medium-editor'

export default {
    components: {
        InputText,
        InputTextarea,
        InputCheckbox,
        InputRadio,
        InputSelect,
        InputDate,
        InputEmail,
        InputZip,
        InputTel,
        draggable,
        MediumEditor,
    },
    props: {
        isActive: Boolean,
        form: Object,
        title: String,
        inputTypes: Array,
        formHtmlList: Array,
        htmlClasses: Object,
    },
    data() {
        return {
            editForm: {
                title: '',
                icon: '',
                background_image: '',
                top_text: '',
                bottom_text: '',
                is_active: false,
                end_at: '',
                form_html: '',
            },
            isEdit: false,
            selectInputType: '',
            previewIcon: '',
            previewBackground: '',
            editFormHtmlList: [],
            editorOptions: {
                toolbar: {
                    buttons: [
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        'anchor',
                        'orderedlist',
                        'justifyLeft',
                        'justifyCenter',
                        'justifyRight',
                        'h2',
                        'h3',
                        'h4',
                        'h5',
                        'h6',
                        'removeFormat'
                    ],
                },
                placeholder: {
                    text: '詳細'
                }
            },
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        // 編集
        async save() {
            this.$emit('save', this.editForm, this.getSaveHtml())
        },
        // フォームHTML追加
        addFormHtml(html) {
            this.$emit('addFormHtml', this.editFormHtmlList, html)
        },
        // フォームHTML削除
        deleteFormHtml(key) {
            this.$emit('deleteFormHtml', this.editFormHtmlList, key)
        },
        // アイコン画像選択時
        handleIconAdd(file, fileList) {
            this.editForm.icon = file.raw
            this.previewIcon = file.url
        },
        // アイコン画像削除時
        handleIconRemove(file, fileList) {
            this.editForm.icon = ''
            this.previewIcon = ''
        },
        // 背景画像選択時
        handleBackgroundAdd(file, fileList) {
            this.editForm.background_image = file.raw
            this.previewBackground = file.url
        },
        // 背景画像削除時
        handleBackgroundRemove(file, fileList) {
            this.editForm.background_image = ''
            this.previewBackground = ''
        },
        // 保存用html作成
        getSaveHtml() {
            let html = ''
            for (let htmlItem of this.editFormHtmlList) {
                html += htmlItem
            }

            return html
        },
        // 上部詳細編集時
        topTextEdit(operation) {
            this.editForm.top_text = operation.api.origElements.innerHTML
        },
        // 下部詳細編集時
        bottomTextEdit(operation) {
            this.editForm.bottom_text = operation.api.origElements.innerHTML
        },
    },
    watch: {
        form() {
            this.isEdit = Object.keys(this.form).length > 0
            this.editForm = Object.assign({}, this.form, { icon: '', background_image: '' })
            this.previewIcon = this.form.icon_cdn_url
            this.previewBackground = this.form.background_image_cdn_url
        },
        formHtmlList() {
            this.editFormHtmlList = Object.assign([], this.formHtmlList)
        },
    },
}
</script>

<style lang="scss" scoped>
    .entry-form-title {
        font-size: 20px;
        margin-bottom: 22px;
    }
    .entry-form {
        margin-left: 130px;
        margin-bottom: 22px;
    }
    .entry-form .action .action-icon {
        margin-left: 20px;
        cursor: pointer;
    }
    .entry-form-content {
        position: relative;
    }
    .entry-form-content .action {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 2%;
        margin: auto;
        display: flex;
        align-items: center;
    }
    .medium-editor-element {
        padding: 5px 15px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    }
    .form-background-img,
    .form-icon-img {
       width: 250px;
    }
</style>

<style lang="scss">
    // 埋め込んだ応募フォームHTMLのスタイル
    .pf_item {
        display: flex;
        background: #efefef;
        border: 1px solid #ccc;
        cursor: pointer;
    }
    .pf_item .pf_left {
        width: 20%;
        padding: 15px;
        border-right: 1px solid #ccc;
    }
    .pf_item .pf_right {
        width: 80%;
        padding: 15px;
    }
    .pf_item .pf_left .required::after {
        content: '必須';
        color: red;
        margin-left: 15px;
    }

    // エディターのスタイル
    .el-form-item__content {
        line-height: 20px !important;
    }
    .el-form-item__content p,h2,h3,h4,h5,h6 {
        margin: 0;
    }
</style>

