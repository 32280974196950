<template>
    <div>
        <el-row type="flex" justify="end">
            <div class="search-form">
                <el-input v-model="searchForm.title" placeholder="特集のタイトルで検索">
                    <el-button
                        @click="search"
                        slot="append"
                        icon="el-icon-search">
                    </el-button>
                </el-input>
            </div>
        </el-row>
        <div class="topic">
            <el-table
                    :data="topics"
                    style="width: 100%; font-size: 12px;"
                    max-height="800"
            >
                <el-table-column label="ID" width="50px">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.id"></div>
                        <router-link v-else :to="'/organizations/topic/' + scope.row.id">{{ scope.row.id }}</router-link>
                    </template>
                </el-table-column>
                <el-table-column label="タイトル">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.title" size="mini"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="説明">
                    <template slot-scope="scope">
                        <el-input type="textarea" :rows="3" size="mini" v-model="scope.row.description"></el-input>
                        <p class="topic__description-length">{{ getDescriptionLength(scope.row) }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="会社名">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.company_name" size="mini"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="バナー" width="130px">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.id">
                            <el-upload
                                action=""
                                accept="image/png,image/jpeg,image/gif"
                                list-type="picture"
                                class="topic-banner"
                                :show-file-list="false"
                                :auto-upload="false"
                                :on-change="handleBannerAdd"
                                :on-remove="handleBannerRemove"
                            >
                                <img v-if="scope.row.thumbnail" :src="scope.row.thumbnail_preview" class="thumbnail-img">
                                <el-button v-else size="small" type="primary">画像選択</el-button>
                            </el-upload>
                        </div>
                        <div v-else>
                            <label :for="'image_' + scope.row.id">
                                <img v-if="scope.row.thumbnail" :src="scope.row.thumbnail_preview" class="thumbnail-img">
                                <el-button v-else size="small" type="primary">画像選択</el-button>
                                <input type="file" accept="image/png,image/jpeg,image/gif" style="display: none;" :id="'image_' + scope.row.id" @change="selectedFile"/>
                            </label>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :render-header="renderTableHeader(['開始日時', '公開終了日時'])" width="230px">
                    <template slot-scope="scope">
                        <el-date-picker
                            v-model="scope.row.publish_start_at"
                            type="datetime"
                            size="mini"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        ></el-date-picker>
                        <el-date-picker
                            v-model="scope.row.publish_end_at"
                            type="datetime"
                            size="mini"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            style="margin-top: 5px;"
                        ></el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column label="順番" width="150px">
                    <template slot-scope="scope">
                        <el-input-number
                            v-model="scope.row.order_num"
                            :min="0"
                            size="mini"
                        ></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="記事下固定" width="65px">
                    <template slot-scope="scope">
                        <el-checkbox
                            v-model="scope.row.is_pickup_topic"
                        ></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column label="レシピ" width="50px">
                    <template slot-scope="scope">
                        <el-checkbox
                            v-model="scope.row.is_video"
                        ></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column label="公開" width="50px">
                    <template slot-scope="scope">
                        <el-checkbox
                            v-model="scope.row.is_active"
                        ></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column label="作成日時" prop="created_at" width="135px"></el-table-column>
                <el-table-column align="right" width="80px">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.id" class="topic__button">
                            <el-button
                                    size="mini"
                                    @click="createTopic(scope.row)"
                            >登録</el-button>
                        </div>
                        <div v-else class="topic__button">
                            <el-button
                                    size="mini"
                                    @click="updateTopic(scope.row)"
                            >更新</el-button>
                            <br>
                            <el-button
                                    type="danger"
                                    size="mini"
                                    @click="deletePickup(scope.row.id)"
                            >削除</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :handleCurrentChange="search" :pagination="pagination" />
        </div>
    </div>
</template>

<script>
import { PAGE_TITLE , LOADING } from '../../store/mutation-types'
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'

export default {
    components: {
        Pagination
    },
    data() {
        return {
            topics: [],
            editTopic: {
                title: '',
                company_name: '',
                description: '',
                thumbnail: '',
                publish_start_at: null,
                publish_end_at: null,
                order_num: 0,
                is_pickup_topic: false,
                is_active: false,
                is_video: false,
            },
            thumbnailPreview: '',
            pagination: {},
            searchForm: {
                title: '',
            },
        }
    },
    created() {
        this.$store.commit(PAGE_TITLE, '編成　>　特集')
        this.init()
    },
    methods: {
        init() {
            // 初回検索
            this.search()
        },
        // 検索
        async search(page = 1) {
            this.$store.commit(LOADING, true)

            const { data, error } = await axios.get('/api/topics', {
                params: {
                    page,
                    title: this.searchForm.title
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            this.pagination = Object.assign({},this.pagination, data.topics)

            let topicsData = []

            if (this.pagination.current_page === 1) {
                topicsData.unshift({
                    title: '',
                    company_name: '',
                    description: '',
                    thumbnail: '',
                    thumbnail_preview: '',
                    publish_start_at: null,
                    publish_end_at: null,
                    order_num: 0,
                    is_pickup_topic: false,
                    is_active: false,
                    is_video: false,
                })
            }
            let datalist = Object.entries(data.topics.data).map(([key, data]) => {
                return {
                    id: data.id,
                    title: data.title,
                    company_name: data.company_name,
                    description: data.description,
                    thumbnail: data.thumbnail_cdn_url,
                    thumbnail_preview: data.thumbnail_cdn_url,
                    publish_start_at: data.publish_start_at,
                    publish_end_at: data.publish_end_at,
                    order_num: data.order_num,
                    is_pickup_topic: data.is_pickup_topic,
                    is_active: data.is_active,
                    is_video: data.is_video,
                }
            })
            topicsData = topicsData.concat(datalist)
            this.topics = topicsData
            this.$store.commit(LOADING, false)
        },
        // 登録
        async createTopic(topic) {
            this.$store.commit(types.LOADING, true)

            const fd = this.getFormData(topic)
            const { error } = await axios.post('/api/topics', fd )

            this.$store.commit(types.LOADING, false)

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '登録しました。')
            this.thumbnailPreview = ''

            this.search()

        },
        // 特集の新規作成/更新
        async updateTopic(topic) {
            this.$store.commit(types.LOADING, true)
            const fd = this.getFormData(topic)
            // PUTだとformDataが空になるため、一旦postにし、optionでPUT指定します（他に解決方法ある？）
            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PUT'
                }
            }
            const { error } = await axios.post('/api/topics/'+topic.id, fd, config)
            this.$store.commit(types.LOADING, false)

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, '更新しました。')
            this.thumbnailPreview = ''

            this.search()
        },
        // 削除
        async deletePickup(id) {
            if (!confirm('本当に削除しますか？')) {
                return
            }
            this.$store.commit(LOADING, true)
            const { error } = await axios.delete('/api/topics/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(LOADING, false)
                return
            }
            this.$store.commit(LOADING, false)

            this.init()
        },
        getDescriptionLength: function (topic) {
            return topic.description ? topic.description.length + '文字' : ''
        },
        selectedFile: function(e) {
            // 選択された File の情報を保存しておく
            e.preventDefault()
            let files = e.target.files
            let topicId = Number(e.target.id.replace('image_', ''))
            this.topics = this.topics.map(function(x) {
                if (x.id === topicId) {
                    x.thumbnail = files[0]
                    const reader = new FileReader()
                    reader.onload = e => {
                        x.thumbnail_preview = e.target.result
                    }
                    reader.readAsDataURL(files[0])
                }
                return x
            })
        },
        // バナー画像選択時
        handleBannerAdd(file) {
            this.topics[0].thumbnail = file.raw
            this.topics[0].thumbnail_preview = file.url
        },
        // バナー画像削除時
        handleBannerRemove(topics) {
            topics.thumbnail = ''
            topics.thumbnail_preview = ''
        },
        // フォームデータ作成
        getFormData(topic) {
            // 画像UPで使用するため
            const fd = new FormData()
            if (topic.id) {
                fd.append('id', topic.id)
            }
            fd.append('title', topic.title)
            fd.append('company_name', topic.company_name ? topic.company_name : '')
            fd.append('description', topic.description)
            fd.append('thumbnail', topic.thumbnail && typeof topic.thumbnail !== 'string' ? topic.thumbnail : '')
            fd.append('publish_start_at', topic.publish_start_at ? topic.publish_start_at : '')
            fd.append('publish_end_at', topic.publish_end_at ? topic.publish_end_at : '')
            fd.append('order_num', topic.order_num)
            fd.append('is_pickup_topic', Number(topic.is_pickup_topic))
            fd.append('is_active', Number(topic.is_active))
            fd.append('is_video', Number(topic.is_video))

            return fd
        }
    }
}
</script>

<style lang="scss" scoped>
    .create-button {
        margin-top: 10px;
        margin-right: 20px;
    }
    .search-form {
        margin-top: 10px;
        margin-right: 20px;
    }
    .thumbnail-img {
        width: 100px;
    }
    .topic {
        margin-top:20px;
        &__id {
            color: #428bca;
            cursor: pointer;
        }
        &__description-length {
            margin-top: 5px;
            color: #999;
            font-size: 12px;
            text-align: right;
        }
        &__date {
            line-height: 3.0em;
        }
        &__button {
            line-height: 3.0em;
        }
    }
    .el-icon-check{
        font-size:2.0em;
    }
</style>
