<template>
    <el-dialog title="アプリプッシュ通知編集" @close="closeDialog" :visible="dialog" height="50%">
        <el-form @submit.native.prevent :model="form"  label-width="140px" >
            <el-form-item label="タイトル">
                <el-col :span="14">
                    <el-input v-model="form.title" placeholder="プッシュ通知タイトル" />
                </el-col>
            </el-form-item>
            <el-form-item label="メッセージ">
                <el-col :span="14">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        v-model="form.description">
                    </el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="対象OS">
                 <el-select v-model="form.os">
                     <el-option
                         v-for="item in osList"
                         :key="item"
                         :label="item"
                         :value="item">
                     </el-option>
                 </el-select>
            </el-form-item>

            <el-form-item label="予約日時">
                <p>現在から29日先の範囲で予約できます</p>
                <el-date-picker
                    v-model="form.send_after"
                    type="datetime"
                    placeholder="日時を選択してください">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="種類">
                <el-select v-model="form.content_type">
                    <el-option
                        v-for="item in contentTypeList"
                        :key="item.label"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>

            <!-- コンテンツタイプによって表示するフォームを変える -->
            <div v-if="form.content_type === 'article'">
                <el-form-item label="記事を追加">
                    <el-col :span="14">
                        <el-input type="number" v-model="searchArticleId" placeholder="記事ID">
                            <el-button @click="searchArticle" slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="配信記事" v-if="targetArticle !== null">
                    <p>記事ID:{{ targetArticle.id }}</p>
                    <p>記事タイトル:{{ targetArticle.title }}</p>
                    <el-button size="small" @click="deleteArticle">削除</el-button>
                </el-form-item>
                <el-form-item label="" v-if="searchedArticle !== null">
                    <el-col :span="5">
                        <img :src="searchedArticle.thumbnail_cdn_url" width="100%">
                    </el-col>
                    <el-col :span="10" :offset="1">
                        {{searchedArticle.title}}
                    </el-col>
                    <el-col :span="4" :offset="1">
                        <el-button size="small" @click="selectArticle(searchedArticle)">追加</el-button>
                    </el-col>
                </el-form-item>
            </div>
            <div v-else-if="form.content_type === 'topic'">
                <el-form-item label="特集を追加">
                    <el-col :span="14">
                        <el-input type="number" v-model="searchTopicId" placeholder="特集ID">
                            <el-button @click="searchTopic" slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="配信特集" v-if="targetTopic !== null">
                    <p>特集ID:{{ targetTopic.id }}</p>
                    <p>特集タイトル:{{ targetTopic.title }}</p>
                    <el-button size="small" @click="deleteTopic">削除</el-button>
                </el-form-item>
                <el-form-item label="" v-if="searchedTopic !== null">
                    <el-col :span="5">
                        <img :src="searchedTopic.thumbnail_cdn_url" width="100%">
                    </el-col>
                    <el-col :span="10" :offset="1">
                        {{ searchedTopic.title }}
                    </el-col>
                    <el-col :span="4" :offset="1">
                        <el-button size="small" @click="selectTopic(searchedTopic)">追加</el-button>
                    </el-col>
                </el-form-item>
            </div>
            <div v-else-if="form.content_type === 'serialization'">
                <el-form-item label="連載を追加">
                    <el-col :span="14">
                        <el-input type="number" v-model="searchSerializationId" placeholder="連載ID">
                            <el-button @click="searchSerialization" slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="配信連載" v-if="targetSerialization !== null">
                    <p>連載ID:{{ targetSerialization.id }}</p>
                    <p>連載タイトル:{{ targetSerialization.title }}</p>
                    <el-button size="small" @click="deleteSerialization">削除</el-button>
                </el-form-item>
                <el-form-item label="" v-if="searchedSerialization !== null">
                    <el-col :span="5">
                        <img :src="searchedSerialization.thumbnail_cdn_url" width="100%">
                    </el-col>
                    <el-col :span="10" :offset="1">
                        {{ searchedSerialization.title }}
                    </el-col>
                    <el-col :span="4" :offset="1">
                        <el-button size="small" @click="selectSerialization(searchedSerialization)">追加</el-button>
                    </el-col>
                </el-form-item>
            </div>
            <div v-else-if="form.content_type === 'writer'">
                <el-form-item label="ライターを追加">
                    <el-col :span="14">
                        <el-input type="number" v-model="searchWriterId" placeholder="ライターID">
                            <el-button @click="searchWriter" slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="配信ライター" v-if="targetWriter !== null">
                    <p>ライターID:{{ targetWriter.id }}</p>
                    <p>ライター名:{{ targetWriter.writer_name }}</p>
                    <el-button size="small" @click="deleteWriter">削除</el-button>
                </el-form-item>
                <el-form-item label="" v-if="searchedWriter !== null">
                    <el-col :span="5">
                        <img :src="searchedWriter.profile_image_cdn_url" width="100%">
                    </el-col>
                    <el-col :span="10" :offset="1">
                        {{ searchedWriter.writer_name }}
                    </el-col>
                    <el-col :span="4" :offset="1">
                        <el-button size="small" @click="selectWriter(searchedWriter)">追加</el-button>
                    </el-col>
                </el-form-item>
            </div>
            <div v-else-if="form.content_type === 'store_item'">
                <el-form-item label="ストア商品を追加">
                    <el-col :span="14">
                        <el-input type="number" v-model="searchStoreItemId" placeholder="ストア商品ID">
                            <el-button @click="searchStoreItem" slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="配信ストア商品" v-if="targetStoreItem !== null">
                    <p>ストア商品ID:{{ targetStoreItem.item_id }}</p>
                    <p>ストア商品名:{{ targetStoreItem.item_name }}</p>
                    <el-button size="small" @click="deleteStoreItem">削除</el-button>
                </el-form-item>
                <el-form-item label="" v-if="searchedStoreItem !== null">
                    <el-col :span="5">
                        <img :src="searchedStoreItem.item_image.src_small" width="100%">
                    </el-col>
                    <el-col :span="10" :offset="1">
                        {{ searchedStoreItem.item_name }}
                    </el-col>
                    <el-col :span="4" :offset="1">
                        <el-button size="small" @click="selectStoreItem(searchedStoreItem)">追加</el-button>
                    </el-col>
                </el-form-item>
            </div>
            <div v-else-if="form.content_type === 'store_shop'">
                <el-form-item label="ストアショップを追加">
                    <el-col :span="14">
                        <el-input v-model="searchStoreShopCode" placeholder="ストアショップコード">
                            <el-button @click="searchStoreShop" slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="配信ストアショップ" v-if="targetStoreShop !== null">
                    <p>ストアショップコード:{{ targetStoreShop.shop_code }}</p>
                    <p>ストアショップ名:{{ targetStoreShop.shop_name }}</p>
                    <el-button size="small" @click="deleteStoreShop">削除</el-button>
                </el-form-item>
                <el-form-item label="" v-if="searchedStoreShop !== null">
                    <el-col :span="5">
                        <img :src="searchedStoreShop.logo_src" width="100%">
                    </el-col>
                    <el-col :span="10" :offset="1">
                        {{ searchedStoreShop.shop_name }}
                    </el-col>
                    <el-col :span="4" :offset="1">
                        <el-button size="small" @click="selectStoreShop(searchedStoreShop)">追加</el-button>
                    </el-col>
                </el-form-item>
            </div>

            <el-form-item label="画像付きPUSH" >
                <el-checkbox
                    v-model="form.add_image"
                ></el-checkbox>
            </el-form-item>

            <el-form-item label="テスト環境フラグ" >
                <el-checkbox
                    v-model="form.is_test"
                ></el-checkbox>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">キャンセル</el-button>
            <el-button type="primary" @click="submitForm">{{ buttonLabel }}</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../store/mutation-types'

const formDefaultData = {
    os: null,
    title: null,
    description: null,
    send_after: null,
    input_article_id: null,
    add_image: false,
    is_test: false,
    content_type: null,
    article_id: null,
    topic_id: null,
    serialization_id: null,
    writer_id: null,
    store_item_id: null,
    store_shop_code: null,
}
export default {
    name: 'TrSmartphoneNotificationEditModal',
    props: {
        dialog: Boolean,
        input: Object,
        osList: Array,
        save: Function,
        closeDialog: Function,
        buttonLabel: String,
        contentTypeList: Array
    },
    data () {
        return {
            form: {...formDefaultData},
            targetArticle: null,
            targetTopic: null,
            targetSerialization: null,
            targetWriter: null,
            targetStoreItem: null,
            targetStoreShop: null,
            searchedArticle: null,
            searchedTopic: null,
            searchedSerialization: null,
            searchedWriter: null,
            searchedStoreItem: null,
            searchedStoreShop: null,
            searchArticleId: null,
            searchTopicId: null,
            searchSerializationId: null,
            searchWriterId: null,
            searchStoreItemId: null,
            searchStoreShopCode: null,
        }
    },
    watch: {
        dialog() {
            // formで定義されているプロパティのみ親コンポーネントから受け取り
            this.form = Object.assign({}, formDefaultData, _.pick(this.input, Object.keys(this.form)))
        },
        targetArticle() {
            this.form.article_id = this.targetArticle !== null ? this.targetArticle.id : null
        },
        targetTopic() {
            this.form.topic_id = this.targetTopic !== null ? this.targetTopic.id : null
        },
        targetSerialization() {
            this.form.serialization_id = this.targetSerialization !== null ? this.targetSerialization.id : null
        },
        targetWriter() {
            this.form.writer_id = this.targetWriter !== null ? this.targetWriter.id : null
        },
        targetStoreItem() {
            this.form.store_item_id = this.targetStoreItem !== null ? this.targetStoreItem.item_id : null
        },
        targetStoreShop() {
            this.form.store_shop_code = this.targetStoreShop !== null ? this.targetStoreShop.shop_code : null
        },
    },
    methods: {
        async submitForm () {
            await this.save(this.form)
                .then(res => {
                // 全て初期化
                    targetArticle = null
                    targetTopic = null
                    targetSerialization = null
                    targetWriter = null
                    targetStoreItem = null
                    targetStoreShop = null
                    searchedArticle = null
                    searchedTopic = null
                    searchedSerialization = null
                    searchedWriter = null
                    searchedStoreItem = null
                    searchedStoreShop = null
                })
                .catch(error => {})
        },
        async searchArticle() {
            this.searchedArticle = null
            const { data, error } = await axios.get('/api/smartphone/notifications/article', {
                params: {
                    send_after: this.form.send_after,
                    article_id: this.searchArticleId
                }
            })
            if (error) {
                return
            }
            this.searchedArticle = data.article
        },
        async searchTopic() {
            this.searchedTopic = null
            const { data, error } = await axios.get('/api/topics/' + this.searchTopicId + '/published')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '存在しないか、非公開か、記事が紐づいていない特集IDです。')
                return
            }
            this.searchedTopic = data
        },
        async searchSerialization() {
            this.searchedSerialization = null
            const { data, error } = await axios.get('/api/serializations/' + this.searchSerializationId + '/published')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '存在しないか、非公開か、記事が紐づいていない連載IDです。')
                return
            }
            this.searchedSerialization = data
        },
        async searchWriter() {
            this.searchedWriter = null
            const { data, error } = await axios.get('/api/writers/valid/' + this.searchWriterId)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '存在しないか、無効なライターIDです。')
                return
            }
            this.searchedWriter = data
        },
        async searchStoreItem() {
            this.searchedStoreItem = null
            const { data, error } = await axios.get('/api/macaroni/store/items/' + this.searchStoreItemId)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '存在しないか、非公開のストア商品IDです。')
                return
            }
            this.searchedStoreItem = data
        },
        async searchStoreShop() {
            this.searchedStoreShop = null
            const { data, error } = await axios.get('/api/macaroni/store/shops/' + this.searchStoreShopCode)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '存在しないか、非公開のストアショップコードです。')
                return
            }
            this.searchedStoreShop = data
        },
        selectArticle(article) {
            this.targetArticle = article
            this.form.article_id = article.id
            this.searchedArticle = null
        },
        deleteArticle() {
            this.form.article_id = null
            this.targetArticle = null
        },
        selectTopic(topic) {
            this.targetTopic = topic
            this.form.topic_id = topic.id
            this.searchedTopic = null
        },
        deleteTopic() {
            this.form.topic_id = null
            this.targetTopic = null
        },
        selectSerialization(serialization) {
            this.targetSerialization = serialization
            this.form.serialization_id = serialization.id
            this.searchedSerialization = null
        },
        deleteSerialization() {
            this.form.serialization_id = null
            this.targetSerialization = null
        },
        selectWriter(writer) {
            this.targetWriter = writer
            this.form.writer_id = writer.id
            this.searchedWriter = null
        },
        deleteWriter() {
            this.form.writer_id = null
            this.targetWriter = null
        },
        selectStoreItem(storeItem) {
            this.targetStoreItem = storeItem
            this.form.store_item_id = storeItem.item_id
            this.searchedStoreItem = null
        },
        deleteStoreItem() {
            this.form.store_item_id = null
            this.targetStoreItem = null
        },
        selectStoreShop(storeShop) {
            this.targetStoreShop = storeShop
            this.form.store_shop_code = storeShop.shop_code
            this.searchedStoreShop = null
        },
        deleteStoreShop() {
            this.form.store_shop_code = null
            this.targetStoreShop = null
        },
    }
}
</script>
<style scoped>
    .el-form-item__content p {
        margin: 5px 0;
    }
</style>
