// global
export const LOADING = 'LOADING'
export const PAGE_LOADING = 'PAGE_LOADING'
export const LOGIN = 'LOGIN'
export const ALREADY_LOGIN = 'ALREADY_LOGIN'
export const CHANGE_ROUTE = 'CHANGE_ROUTE'
export const LOGOUT = 'LOGOUT'
export const PAGE_TITLE = 'PAGE_TITLE'
export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_END = 'API_REQUEST_END'
export const API_REQUEST_FAILED = 'API_REQUEST_FAILED'
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'
export const SHOW_INFO_MESSAGE = 'SHOW_INFO_MESSAGE'
export const FETCH_LOGIN_USER = 'FETCH_LOGIN_USER'
export const EDITOR_LOADING = 'EDITOR_LOADING'
