<template>
    <div class="articleMain">
        <el-form @submit.native.prevent :model="form">
            <el-row>
                <el-col :span="5">
                    <el-form-item class="avatarWrapper">
                        <el-upload
                            :headers="header"
                            class="avatar-uploader"
                            :auto-upload="false"
                            action=""
                            :on-change="handleImageAdd"
                            :show-file-list="false">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            <icon-aspect-check :imageUrl="imageUrl"></icon-aspect-check>
                        </el-upload>
                    </el-form-item>
                    <el-row class="referenceLink">
                        <span v-if="is_shutterstock_icon">shutterstock</span>
                            <a :href="article.thumbnail_quote_url" v-else-if="article.thumbnail_quote_url" target="_blank">出典</a>
                    </el-row>
                    <el-row style="margin-top: 20px;">
                        <el-row>
                            <el-button type="text" size="mini" @click="() => this.setIconUrlDialog = true">画像のURL入力</el-button>
                        </el-row>
                        <el-row>
                            <el-button type="text" size="mini" @click="() => this.cooperateImageDialog = true">探して追加する</el-button>
                        </el-row>
                    </el-row>
                </el-col>
                <el-col :span="19">
                    <el-form-item label="タイトル" style="margin: 0;">
                        <el-input
                            style="font-size:25px;"
                            type="text"
                            @change="update"
                            maxlength="60"
                            v-model="form.title">
                        </el-input>
                        <text-length-count :targetText="form.title" :maxTextLength="60"></text-length-count>
                    </el-form-item>
                    <el-form-item label="ディスクリプション" style="margin: 0;">
                        <el-input
                            type="textarea"
                            size="medium"
                            placeholder="100〜140文字、キーワードが記事に設定されている場合は初めの50字以内に含むようにしてください。"
                            @change="update"
                            :rows="3"
                            maxlength="140"
                            v-model="form.description">
                        </el-input>
                        <text-length-count :targetText="form.description" :maxTextLength="140"></text-length-count>
                    </el-form-item>
                    <el-form-item label="メモ" style="margin: 0;">
                        <el-input
                            type="textarea"
                            size="mini"
                            @change="update"
                            :rows="3"
                            maxlength="2000"
                            v-model="form.memo">
                        </el-input>
                        <text-length-count :targetText="form.memo" :maxTextLength="2000"></text-length-count>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-dialog
            title="連携メディアから探す"
            @close="() => this.cooperateImageDialog = false"
            :visible="cooperateImageDialog"
            class="cooperateImageDialogWrapper"
            height="50%"
            width="1100px"
            append-to-body>
            <cooperate-image @saveIcon="updateIconUrl" @close="() => this.cooperateImageDialog = false" :imageType="'icon'" />
        </el-dialog>

        <el-dialog
            title="画像のURL入力"
            @close="() => this.setIconUrlDialog = false"
            :visible="setIconUrlDialog"
            class="setIconUrlDialog"
            height="50%"
            width="50%"
            append-to-body>
            <set-icon-url-modal @save="updateIconUrl" @close="() => this.setIconUrlDialog = false" :article="article" />
        </el-dialog>
    </div>
</template>
<script>
import * as types from '../../../../store/mutation-types'
import CooperateImage from '../ItemSection/Form/Components/CooperateImage'
import TextLengthCount from '../ItemSection/Form/Components/TextLengthCount'
import IconAspectCheck from './Components/IconAspectCheck'
import SetIconUrlModal from './Components/SetIconUrlModal'

const formDefaultData = {
    image_file: null,
    image_url: null,
    title: null,
    description: null,
    memo: null,
}
export default {
    name:'ArticleMainNormal',
    components: {
        CooperateImage,
        TextLengthCount,
        IconAspectCheck,
        SetIconUrlModal,
    },
    props: {
        response: Object,
        fetch: Function,
    },
    data () {
        return {
            form: {...formDefaultData},
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            fetchedImage: false,
            imageRaw: null,
            imageUrl: null,
            cooperateImageDialog: false,
            setIconUrlDialog: false,
        }
    },
    mounted() {
        this.form = Object.assign({}, _.pick(this.article, Object.keys(this.form)))
        this.imageUrl = this.article.thumbnail_cdn_url
    },
    computed:{
        article() {
            if (!this.response) {
                return {}
            }
            return this.response.article
        },
        enableSubmit() {
            return Boolean(this.form.title) && Boolean(this.form.description)
        },
        is_shutterstock_icon() {
            return this.response.article.is_thumbnail_shutterstock
        }
    },
    watch: {
        response() {
            this.form = Object.assign({}, _.pick(this.article, Object.keys(this.form)))
            this.imageUrl = this.article.thumbnail_cdn_url
        },
    },
    methods: {
        async updateIconUrl (imageData, isMedia) {
            this.$store.commit(types.EDITOR_LOADING,true)
            const fd = new FormData()
            fd.append('title', this.form.title)
            fd.append('description', this.form.description)
            fd.append('thumbnail_quote_image_url', imageData.thumbnail_quote_image_url)
            if (isMedia && 'quote_image_url' in imageData) {
                fd.append('quote_image_url', imageData.quote_image_url)
            }
            if (!isMedia && 'thumbnail_quote_url' in imageData) {
                fd.append('thumbnail_quote_url', imageData.thumbnail_quote_url)
            }

            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PATCH'
                }
            }

            const apiPath = isMedia ? '/normal': '/iconUrl'
            const { data, error } = await axios.post('/api/articles/' + this.response.article.id + apiPath, fd, config)
            if (error) {
                this.$store.commit(types.EDITOR_LOADING,false)
                const message = error ? error: 'アイコンの更新に失敗しました'
                this.$store.commit(types.SHOW_ERROR_MESSAGE, message)
                return
            }
            this.imageUrl = isMedia ? imageData.thumbnail_quote_image_url : data
            await this.fetch()
            if (!isMedia) {
                this.setIconUrlDialog = false
            }
            this.$store.commit(types.EDITOR_LOADING,false)
        },
        async update () {
            this.$store.commit(types.EDITOR_LOADING,true)
            this.form.image_file = this.imageRaw
            const fd = new FormData()
            _.forEach(this.form, (value,key) => {
                if (value === null) {
                    this.form[key] = ''
                }
            })

            fd.append('image_file', this.form.image_file)
            fd.append('title', this.form.title)
            fd.append('description', this.form.description)
            fd.append('memo', this.form.memo)

            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PATCH'
                }
            }
            const {error} = await axios.post('/api/articles/' + this.response.article.id + '/normal', fd, config)
            if (error) {
                this.$store.commit(types.EDITOR_LOADING,false)
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            await this.fetch()
            this.$store.commit(types.EDITOR_LOADING,false)
        },
        handleImageAdd(file) {
            this.imageRaw = file.raw
            this.imageUrl = URL.createObjectURL(file.raw)
            this.fetchedImage = true
            this.update()
        },
    }
}
</script>
<style>
.articleMain {
    padding: 15px;
}
.articleMain .avatar-uploader:hover .imgAspectRatioPopup {
    display: block;
}
.articleMain .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: visible;
}
.avatarWrapper {
    height: 180px;
    margin-bottom: 0;
}
.referenceLink a,
.referenceLink span {
    text-decoration: none !important;
    font-size: 11px;
}
.referenceLink a {
    color: #999 !important;
}
.referenceLink span {
    color: #000 !important;
}
.referenceLink a:hover {
    text-decoration: underline !important;
}
.avatar-uploader {
    width: 178px;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px !important;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}
.avatar #img_aspect_ratio_popup {
    display: block;
}
</style>
