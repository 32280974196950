<template>
    <div>
        <!-- 通常記事 -->
        <recipe-normal
            v-if="articleFormat === 'normal'"
            ref="childModule"
            :id="id"
            :save="save"
            :resource-name="resourceName"
            :is-preparation="isPreparation"
            @close="close"
        />
        <!-- 動画記事 -->
        <recipe-video
            v-else-if="articleFormat === 'video'"
            ref="childModule"
            :id="id"
            :save="save"
            :resource-name="resourceName"
            @close="close"
        />
    </div>
</template>

<script>
import RecipeVideo from './Recipe/RecipeVideo'
import RecipeNormal from './Recipe/RecipeNormal'

export default {
    components: {
        RecipeVideo,
        RecipeNormal,
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        articleFormat: {
            required: true,
            type: String,
        },
        isPreparation: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    methods: {
        openDialog(input) {
            setTimeout(() => {
                this.$refs.childModule.openDialog(input)
            }, 1)
        },
        close() {
            this.$emit('close')
        }
    },
    computed: {
        resourceName() {
            return 'recipes'
        },
    }
}
</script>

<style scoped>
</style>
