<template>
    <div class="imgAspectRatioPopup" v-if="imageUrl" @click.stop="invalidEvents">
        <p class="imgAspectRatioHeading">アスペクト比確認（デバイス幅で変動するので目安として下さい）</p>
        <ul class="imgAspectList">
            <li class="imgAspectItem">
                <div class="aspectPcTopLarge">
                    <img :src="imageUrl" class="aspectRatioImg aspectPcLarge">
                    <p class="aspectRatioText">PC 大</p>
                </div>
            </li>
            <li class="imgAspectItem">
                <div class="aspectPcTopMiddle">
                    <img :src="imageUrl" class="aspectRatioImg aspectPcMiddle">
                    <p class="aspectRatioText">PC 中</p>
                </div>
            </li>
            <li class="imgAspectItem">
                <div class="aspectPcTopSmall">
                    <img :src="imageUrl" class="aspectRatioImg aspectPcSmall">
                    <p class="aspectRatioText">PC 小</p>
                </div>
            </li>
            <li class="imgAspectItem">
                <div class="aspectSpTopLarge">
                    <img :src="imageUrl" class="aspectRatioImg aspectSpLarge">
                    <p class="aspectRatioText">SP 大</p>
                </div>
            </li>
            <li class="imgAspectItem">
                <div class="aspectSpTopMiddle">
                    <img :src="imageUrl" class="aspectRatioImg aspectSpMiddle">
                    <p class="aspectRatioText">SP 中</p>
                </div>
            </li>
            <li class="imgAspectItem">
                <div class="aspectSpTopSmall">
                    <img :src="imageUrl" class="aspectRatioImg aspectSpSmall">
                    <p class="aspectRatioText">SP 小</p>
                </div>
            </li>
        </ul>
    </div></template>
<script>
export default {
    name:'IconAspectCheck',
    props: {
        imageUrl: String,
    },
    methods: {
        invalidEvents () {
            return false
        }
    }
}
</script>
<style>
.imgAspectRatioPopup {
    display: none;
    transition-duration:0.2s;
    position:absolute;
    top:0;
    left:181px;
    width:830px;
    height:auto;
    background-color:#fff;
    box-shadow:1px 1px 10px #ccc;
    z-index:10;
    padding: 10px 15px;
    color: #383838 !important;
}
.imgAspectRatioPopup:hover {
    display: block;
}
</style>
<style scoped>
.imgAspectList {
    display:flex;
    flex-wrap:wrap;
}
.imgAspectItem {
    width:33.3%;
    box-sizing:border-box;
    padding: 0px 5px 10px;
    margin: 0;
    display: flex !important;
    height:200px;
    justify-content: center;
    align-items: center;
    list-style: none !important;
}
.imgAspectItem:hover {
    box-shadow:0 0 10px #ccc;
}
.aspectRatioImg {
    width:256px;
    height: 100%;
    object-fit: cover;
}
.imgAspectRatioHeading {
    font-size:14px;
}
.aspectRatioText {
    font-size:13px;
    margin-top: -8px;
    height: 20px;
    line-height: 20px;
    text-align:center;
}
.aspectPcTopLarge {
    height:102px;
}
.aspectPcTopMiddle {
    height:135px;
}
.aspectPcTopSmall {
    height:138px;
}
.aspectSpTopLarge {
    height:143px;
}
.aspectSpTopMiddle {
    height:144px;
}
.aspectSpTopSmall {
    height:148px;
}
</style>

