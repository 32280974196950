<template>
    <el-dialog title="支払い区分更新" @close="close" :visible="dialog" height="70%">
        <el-form label-width="150px">
            <el-form-item label="支払い区分">
                <el-col :span="18" style="display: flex; align-items: center;">
                    <el-select v-model="form.payment_type">
                        <el-option
                            v-for="type in paymentTypes"
                            :key="type.value"
                            :label="type.label"
                            :value="type.value">
                        </el-option>
                    </el-select>
                </el-col>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="submitForm">更新</el-button>
        </span>
    </el-dialog>
</template>
<script>

export default {
    props: {
        dialog: Boolean,
        articleFee: Object,
        paymentTypes: Array,
    },
    data () {
        return {
            form: {
                payment_type: null,
            }
        }
    },
    watch: {
        articleFee() {
            this.form.payment_type = this.articleFee.payment_type
        },
    },
    methods: {
        submitForm () {
            this.$emit('save', this.articleFee.id, this.form)
        },
        close () {
            this.$emit('close')
        },
    }
}
</script>
