<template>
    <div v-if="form" class="writerEditor">
        <el-row type="flex" justify="left">
            <el-col :span="16">
                <el-form ref="form" :model="form" label-width="30%" label-position="left" size="small">
                    <el-form-item label="表示名">
                        <el-input type="text" v-model="form.screen_name"></el-input>
                    </el-form-item>
                    <el-form-item label="肩書き">
                        <el-input type="text" v-model="form.position"></el-input>
                    </el-form-item>
                    <el-form-item label="プロフィール文">
                        <el-input
                            type="textarea"
                            :rows="5"
                            v-model="form.profile_text"
                         ></el-input>
                    </el-form-item>
                    <el-form-item label="URLリスト">
                        <div v-for="(writerExternalUrl, idx) in form.writer_external_urls" :key="idx">
                            <el-row class="writerEditor__externalUrlList">
                                <el-col :span="16">
                                    <el-input type="text" v-model="writerExternalUrl.url" @change="handleExternalUrlChange($event, idx)" placeholder="URL"></el-input>
                                </el-col>
                                <el-col :span="5" :offset="1">
                                    <el-select v-model="form.writer_external_urls[idx].type">
                                        <el-option
                                            v-for="externalUrlType in externalUrlTypeList"
                                            :key="externalUrlType.value"
                                            :label="externalUrlType.label"
                                            :value="externalUrlType.value">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="1" :offset="1">
                                    <i class="el-icon-remove-outline writerEditor__externalUrlDeleteIcon" @click="handleExternalUrlDelete(idx)"></i>
                                </el-col>
                            </el-row>
                        </div>
                        <el-form-item v-if="! isReachUrlLimit">
                            <el-button @click="handelAddUrl">行を追加</el-button>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="プロフィール画像">
                        <el-upload
                            action=""
                            :auto-upload="false"
                            :show-file-list="false"
                            :on-change="handleImageAdd"
                            class="avatar-uploader">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="update-button" @click="update">更新</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import { PAGE_TITLE , LOADING } from '../../store/mutation-types'

const EXTERNAL_URL_LIMIT = 16;
const formDefaultData = {
    screen_name: null,
    position: null,
    profile_text: null,
    profile_image: null,
    writer_external_urls: [],
}
const defaultWriterExternalUrl = {
    id: null,
    url: null,
    type: null,
}
export default {
    created() {
        this.$store.commit(PAGE_TITLE, 'アカウント　>　ライター管理　>　編集')
        this.fetch()
    },
    data() {
        return {
            form : null,
            externalUrlTypeList: [],
            imageRaw: null,
            imageUrl: null,
        }
    },
    computed: {
        isReachUrlLimit() {
            return this.form.writer_external_urls.length >= EXTERNAL_URL_LIMIT;
        }
    },
    methods: {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)
            const { data } = await axios.get('/api/writers/' + this.$route.params.writerId)
            if (data) {
                this.form = structuredClone({...formDefaultData, ...data.writer})
                this.externalUrlTypeList = structuredClone(data.externalUrlTypeList)
                if (this.form.profile_image_cdn_url) {
                    this.imageUrl = this.form.profile_image_cdn_url
                }
            }
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async update() {
            this.$store.commit(types.PAGE_LOADING, true)
            const fd = new FormData()
            fd.append('screen_name', this.form.screen_name ?? '')
            fd.append('position', this.form.position ?? '')
            fd.append('profile_text', this.form.profile_text ?? '')
            fd.append('profile_image', this.imageRaw ?? '')
            this.form.writer_external_urls.map((value, idx) => {
                fd.append(`writer_external_urls[${idx}][id]`, value.id ?? '')
                fd.append(`writer_external_urls[${idx}][url]`, value.url ?? '')
                fd.append(`writer_external_urls[${idx}][type]`, value.type ?? '')
            })

            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PATCH'
                }
            }
            const { error } = await axios.post(`/api/writers/${this.$route.params.writerId}/display_info`, fd, config)
            if (!error) {
                this.$store.commit(types.SHOW_INFO_MESSAGE, '更新しました')
            }
            this.$store.commit(types.PAGE_LOADING, false)
        },
        handleImageAdd(file) {
            this.imageRaw = file.raw
            this.imageUrl = URL.createObjectURL(file.raw)
        },
        handelAddUrl() {
            this.form.writer_external_urls.push(structuredClone(defaultWriterExternalUrl))
        },
        handleExternalUrlDelete(idx) {
            this.form.writer_external_urls.splice(idx, 1)
        },
        handleExternalUrlChange(value, idx) {
            // ドメイン判定してタイプを設定する
            if (! URL.canParse(value)) {
                this.form.writer_external_urls[idx].type = null
                return
            }
            const hostname = new URL(value).hostname
            if (hostname === 'instagram.com' || hostname.endsWith('.instagram.com')) {
                this.form.writer_external_urls[idx].type = 'INSTAGRAM'
            } else if (hostname === 'youtube.com' || hostname.endsWith('.youtube.com')) {
                this.form.writer_external_urls[idx].type = 'YOUTUBE'
            } else if ((hostname === 'twitter.com' || hostname.endsWith('.twitter.com')) || (hostname === 'x.com' || hostname.endsWith('.x.com'))) {
                this.form.writer_external_urls[idx].type = 'X'
            } else if (hostname === 'facebook.com' || hostname.endsWith('.facebook.com')) {
                this.form.writer_external_urls[idx].type = 'FACEBOOK'
            } else if (hostname === 'tiktok.com' || hostname.endsWith('.tiktok.com')) {
                this.form.writer_external_urls[idx].type = 'TIKTOK'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.writerEditor {
    margin: 30px 60px;
    &__externalUrlList {
        margin-bottom: 10px;
    }
    ::v-deep .el-upload {
        border-radius: 50%;
    }
    .update-button {
        margin-left: 60px;
    }
    &__externalUrlDeleteIcon {
        cursor: pointer;
        font-size: 20px;
    }
}
</style>
