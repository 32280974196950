<template>
    <div>
        <el-row type="flex" justify="end">
            <el-header height="45px">
                <el-col >
                    <el-upload
                        class="uploadButton"
                        accept=".csv"
                        :headers="header"
                        :before-upload="importCsvStart"
                        :on-error="importCsvError"
                        :on-success="importCsvSuccess"
                        :show-file-list="false"
                        action="/api/editing/rewrite/csv"
                        name="upload_rewrite">
                        <el-button type="warning" icon="el-icon-upload2">
                            CSVアップロード
                        </el-button>
                    </el-upload>
                    <el-button type="warning" @click="downloadCSV" icon="el-icon-download">
                        CSVダウンロード
                    </el-button>
                </el-col>
            </el-header >
        </el-row>
        <el-row type="flex" justify="end" style="margin-top:10px;">
            <el-select placeholder="ステータスでフィルタ" v-model="status" class="search">
                <el-option
                    v-for="(item) in rewriteStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-select placeholder="カテゴリでフィルタ" v-model="category" class="search">
                 <el-option
                     v-for="(item) in categoryFilterList"
                     :key="item.id"
                     :label="item.category_name"
                     :value="item.id">
                 </el-option>
            </el-select>
            <el-select placeholder="記事種別でフィルタ" v-model="articleType" class="search">
                <el-option
                    v-for="(item) in articleTypeFilterList"
                    :key="item.id"
                    :label="item.type_name"
                    :value="item.id">
                </el-option>
            </el-select>
        </el-row>
        <el-row type="flex" justify="end" style="margin-top:10px;">
            <el-autocomplete
                class="search"
                placeholder="担当者名"
                v-model="admin"
                @select="handleSelectAdmin"
                :fetch-suggestions="querySearchAdmin"
                :trigger-on-focus="true"
            ></el-autocomplete>
            <el-autocomplete
                class="search"
                placeholder="ライター名"
                v-model="writer"
                @select="handleSelectWriter"
                :fetch-suggestions="querySearchWriter"
                :trigger-on-focus="true"
            ></el-autocomplete>
            <el-input
                class="search"
                type="number"
                min="1"
                placeholder="元記事ID"
                v-model="articleId"
                @keyup.enter.native="search()"
                style="width: 300px;"
            ></el-input>
            <el-input
                class="search"
                placeholder="タイトル"
                v-model="title"
                @keyup.enter.native="search()"
                style="width: 500px;"
            ></el-input>
            <el-button
                @click="search()"
                icon="el-icon-search"
                class="search-input"
                style="height: 40px; margin-top: 10px;"
            ></el-button>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <el-row style="margin-bottom:7px;" v-if="pagination.current_page == 1">
            <el-form :model="form"  label-width="120px" >
                <el-col :span="8">
                    <el-form-item label="元記事ID" >
                        <el-input
                            class="edit-id"
                            size="mini"
                            v-model="form.origin_article_id">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="編集" >
                        <el-autocomplete
                            v-model="form.admin_name"
                            type="text"
                            size="mini"
                            :fetch-suggestions="querySearchAdmin"
                            @select="setAdminId"
                            placeholder="編集"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col style="text-align:right;" :span="8">
                    <el-button class="btnEdit"
                               type="primary"
                               size="mini"
                               @click="create(form)">
                        追加する
                    </el-button>
                </el-col>
            </el-form>
        </el-row>

        <el-row>
            <el-col >
                <rewrite-direction-row
                    :tableData=tableData
                    :categoryList="categoryList"
                    :articleTypeList="articleTypeList"
                    :update="update"
                    :backToPlotConfirm="backToPlotConfirm"
                    :tagList="tagList"
                    :rewrite-payment-types="rewritePaymentTypes"
                    @initArticleUnitPrice="initArticleUnitPrice"
                >
                </rewrite-direction-row>
            </el-col>
        </el-row>

        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />

        <el-dialog
            title="プロットに戻す"
            :visible.sync="backToPlotConfirmDialog"
            width="30%"
            @close="closeBackToPlotConfirmDialog"
        >
            <span>プロットに戻してよろしいですか？対象のディレクションと記事は削除されます</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeBackToPlotConfirmDialog">キャンセル</el-button>
                <el-button type="danger" @click="performBackToPlotDialog">プロットに戻す</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import FileDownload from 'js-file-download'
import RewriteDirectionRow from '../../components/Editing/RewriteDirectionRow'

export default {
    components: {
        Pagination,
        RewriteDirectionRow
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data() {
        return {
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            pagination  : {},
            tableData   : [],

            rewriteStatusList: [],
            categoryFilterList: {},
            articleTypeFilterList: {},

            reflectAbleStatusList: [],
            editableStatusList: [],

            status: null,
            category: null,
            articleType: null,
            admin: '',
            writer: '',
            articleId: '',
            title: '',
            tagList: [],

            categoryList: [],
            articleTypeList: [],

            backToPlotConfirmDialog: false,
            backToPlotConfirmId: null,
            form: {},

            rewritePaymentTypes: [],
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'リライト　>　ディレクション')
        this.queryInit()
        this.fetch()
    },
    methods: {
        queryInit() {
            this.status = this.$route.query.status ? Number(this.$route.query.status) : null
            this.category = this.$route.query.category ? Number(this.$route.query.category) : null
            this.articleType = this.$route.query.articleType ? Number(this.$route.query.articleType) : null
            this.admin = this.$route.query.admin ? this.$route.query.admin : ''
            this.writer = this.$route.query.writer ? this.$route.query.writer : ''
            this.articleId = this.$route.query.article_id ? this.$route.query.article_id : ''
            this.title = this.$route.query.article_title ? this.$route.query.article_title : ''
            this.page = this.$route.query.page ? this.$route.query.page : 1
        },
        search() {
            const query = Object.assign({}, this.$route.query,{
                status: this.status,
                category: this.category,
                article_type: this.articleType,
                admin: this.admin,
                writer: this.writer,
                article_id: this.articleId,
                article_title: this.title,
                page: 1
            })
            this.$router.push({ query })
        },
        init() {
            this.fetch()
        },
        // 検索
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data } = await axios.get('/api/editing/rewrite', {
                params: { ...this.$route.query }
            })

            axios.get('/api/editing/rewrite/tags').then((response) => {
                this.tagList = response.data.tag_list
            })

            this.pagination = Object.assign({}, data.rewrites)

            const rewrites = Object.assign([], data.rewrites.data)

            /* フィルター用 */
            this.rewriteStatusList = Object.assign([], data.status_list)
            this.rewriteStatusList.unshift({label: null, value: null})

            this.categoryFilterList = Object.assign([], data.categories)
            this.categoryFilterList.unshift({category_name: null, id: null})

            this.articleTypeFilterList = Object.assign([], data.article_types)
            this.articleTypeFilterList.unshift({type_name: null, id: null})
            /* フィルター用 */

            this.editableStatusList = Object.assign([], data.editable_status_list)
            this.reflectAbleStatusList = Object.assign([], data.reflectable_status_list)

            this.admin = this.$route.query.admin ? String(this.$route.query.admin) : null
            this.writer = this.$route.query.writer ? String(this.$route.query.writer) : null

            const categories = this.idKeyList(data.categories)
            this.categoryList = Object.values(categories).map((category) => {
                return {id: category.id, label: category.category_name}
            })
            this.categoryList.unshift({id: null, label: 'カテゴリ未設定'})

            const articleTypes = this.idKeyList(data.article_types)
            this.articleTypeList = Object.values(articleTypes).map((articleType) => {
                return {id: articleType.id, label: articleType.type_name}
            })
            this.articleTypeList.unshift({id: null, label: '記事種別未設定'})

            this.rewritePaymentTypes = [...data.payment_types]

            this.tableData = _.map(rewrites, (rewrite) => {
                const current_status = data.status_list.filter(s => s.value === rewrite.status)
                if (rewrite.rewrite_plot && rewrite.rewrite_article) {
                    return {
                        id: rewrite.id,
                        article_id: rewrite.origin_article_id,
                        rewrite_article_id: rewrite.rewrite_article_id,
                        admin_id: rewrite.admin_id,
                        admin_name: rewrite.admin ? rewrite.admin.editor_name : null,
                        writer_id: rewrite.writer_id,
                        writer_name: rewrite.writer ? rewrite.writer.writer_name : null,
                        status: current_status[0].label,
                        enable_back_to_plot: rewrite.enable_back_to_plot,
                        enable_edit_rewrite_plot: true,
                        edit_article_url: (this.editableStatus(rewrite.status)) ? this.editArticleUrl(rewrite.rewrite_article_id) : this.editArticleUrl(rewrite.origin_article_id),
                        due_at: rewrite.due_at,
                        before_shop_item_count: rewrite.before_shop_item_count,
                        payment_type: rewrite.payment_type,
                        article_unit_price: rewrite.article_unit_price,
                        rewrite_plot: {
                            plot: rewrite.rewrite_plot.plot,
                            article_type_id: rewrite.rewrite_plot.article_type_id,
                            category_id: rewrite.rewrite_plot.category_id,
                            keywords: Object.entries(rewrite.rewrite_plot.keywords).map(([key, keyword]) => {
                                return keyword.keyword
                            }),
                        },
                        rewrite_article: {
                            category_id: rewrite.rewrite_article.category_id,
                            parent_tags: rewrite.rewrite_article.parent_tags,
                            child_tags: rewrite.rewrite_article.child_tags,
                            grandchild_tags: rewrite.rewrite_article.grandchild_tags,
                        },
                        article_char_count: rewrite.article_char_count,
                    }
                } else if (rewrite.rewrite_article) {
                    return {
                        id: rewrite.id,
                        article_id: rewrite.origin_article_id,
                        rewrite_article_id: rewrite.rewrite_article_id,
                        admin_id: rewrite.admin_id,
                        admin_name: rewrite.admin ? rewrite.admin.editor_name : null,
                        writer_id: rewrite.writer_id,
                        writer_name: rewrite.writer ? rewrite.writer.writer_name : null,
                        status: current_status[0].label,
                        enable_back_to_plot: rewrite.enable_back_to_plot,
                        enable_edit_rewrite_plot: false,
                        edit_article_url: (this.editableStatus(rewrite.status)) ? this.editArticleUrl(rewrite.rewrite_article_id) : this.editArticleUrl(rewrite.origin_article_id),
                        due_at: rewrite.due_at,
                        before_shop_item_count: rewrite.before_shop_item_count,
                        payment_type: rewrite.payment_type,
                        article_unit_price: rewrite.article_unit_price,
                        rewrite_article: {
                            category_id: rewrite.rewrite_article.category_id,
                            parent_tags: rewrite.rewrite_article.parent_tags,
                            child_tags: rewrite.rewrite_article.child_tags,
                            grandchild_tags: rewrite.rewrite_article.grandchild_tags,
                        },
                        article_char_count: rewrite.article_char_count,
                    }
                } else {
                    return {
                        id: rewrite.id,
                        article_id: rewrite.origin_article_id,
                        rewrite_article_id: rewrite.rewrite_article_id,
                        admin_id: rewrite.admin_id,
                        admin_name: rewrite.admin ? rewrite.admin.editor_name : null,
                        writer_id: rewrite.writer_id,
                        writer_name: rewrite.writer ? rewrite.writer.writer_name : null,
                        status: current_status[0].label,
                        enable_back_to_plot: rewrite.enable_back_to_plot,
                        enable_edit_rewrite_plot: false,
                        edit_article_url: (this.editableStatus(rewrite.status)) ? this.editArticleUrl(rewrite.rewrite_article_id) : this.editArticleUrl(rewrite.origin_article_id),
                        due_at: rewrite.due_at,
                        before_shop_item_count: rewrite.before_shop_item_count,
                        payment_type: rewrite.payment_type,
                        article_unit_price: rewrite.article_unit_price,
                        origin_article: {
                            parent_tags: rewrite.origin_article.parent_tags,
                            child_tags: rewrite.origin_article.child_tags,
                            grandchild_tags: rewrite.origin_article.grandchild_tags,
                        },
                        rewrite_plot: {
                            plot: rewrite.rewrite_plot.plot,
                            article_type_id: rewrite.rewrite_plot.article_type_id,
                            category_id: rewrite.rewrite_plot.category_id,
                            keywords: Object.entries(rewrite.rewrite_plot.keywords).map(([key, keyword]) => {
                                return keyword.keyword
                            }),
                        },
                        article_char_count: rewrite.article_char_count,
                    }
                }
            })

            this.$store.commit(types.PAGE_LOADING, false)
        },
        handleCurrentChange (page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
        handleSelectAdmin (item) {
            this.admin = item.value
        },
        async querySearchAdmin(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/admins/suggest/editor', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, '管理者検索に失敗しました')
                    return
                }
                cb(data.admins)
            }
        },
        async create (params) {
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.post('/api/editing/rewrite', params)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.fetch()
        },
        /*
        async rewriteReflect(rewriteData) {
            this.$store.commit(types.LOADING, true)
            const form = {'internal_flag': true, 'rewrite_id' : rewriteData.id}
            const {error} = await axios.post('/api/editing/rewrite/reflect/' + rewriteData.rewrite_article_id, form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'リライト反映に失敗しました。')
            } else {
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'リライト反映が完了しました。')
            }
            this.$store.commit(types.LOADING, false)
        },
        */
        async update (id, form) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.patch('/api/editing/rewrite/' + id, form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'リライトディレクションを変更しました')
            this.$store.commit(types.LOADING, false)
        },
        async performBackToPlotDialog () {
            this.$store.commit(types.LOADING, true)
            // ステータス411発生の可能性がある為、ヘッダーにContent-Length設定し空欄のパラメータ設置
            const config = {
                headers: {
                    'Content-Length': Buffer.byteLength('')
                }
            }
            const {error} = await axios.patch('/api/editing/rewrite/' + this.backToPlotConfirmId + '/back/plot','' ,config)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'プロットに戻しました')
            this.$store.commit(types.LOADING, false)
            this.closeBackToPlotConfirmDialog()

        },
        backToPlotConfirm (data) {
            this.backToPlotConfirmDialog = true
            this.backToPlotConfirmId = data.id
        },
        closeBackToPlotConfirmDialog() {
            this.backToPlotConfirmId = null
            this.backToPlotConfirmDialog = false
        },
        handleSelectWriter (item) {
            this.writer = item.value
        },
        async querySearchWriter(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/writers/suggest', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'ライター検索に失敗しました')
                    return
                }
                cb(data.writers)
            }
        },
        editableStatus(status) {
            const statusCheck = (statusList) => statusList.value === status
            return this.editableStatusList.some(statusCheck)
        },
        /*** TODO編集で使いそう
        reflectAbleStatus(status) {
            return this.reflectAbleStatusList.some((statusList) => statusList.value === status)
        },
         */
        idKeyList(list) {
            return _.keyBy(list, (data) => data.id)
        },
        editArticleUrl (id) {
            return '/articles/' + id
        },
        importCsvStart() {
            this.$store.commit(types.LOADING, true)
        },
        importCsvError(e) {
            let errorMsg = 'CSVアップロードに失敗しました'
            if (e.status === 422){
                let errorJson = JSON.parse(e.message)
                errorMsg = typeof errorJson.error !== 'undefined' ? errorJson.error : errorMsg
            } else if (e.status === 403) {
                errorMsg = 'この操作は許可されていません。'
            }
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_ERROR_MESSAGE, errorMsg)
        },
        async importCsvSuccess(event) {
            if (event.status === 400) {
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_ERROR_MESSAGE, event.error)
            } else {
                await this.fetch()
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'CSVデータをアップロードしました')
            }
        },
        async downloadCSV() {
            this.$store.commit(types.PAGE_LOADING, true)
            const { data, error } = await axios.get('/api/editing/rewrite/csv')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            const bom  = new Uint8Array([0xEF, 0xBB, 0xBF])
            FileDownload(data, 'rewrites.csv', null, bom)
            this.$store.commit(types.PAGE_LOADING, false)
        },
        setAdminId(item) {
            this.form.admin_id = item.admin_id
        },
        initArticleUnitPrice(plotId) {
            const idx = this.tableData.findIndex(value => value.id === plotId)
            const newPlot = {...this.tableData[idx], article_unit_price: null}
            this.$set(this.tableData, idx, newPlot)
        },
    }
}
</script>

<style scoped>
    .search {
        margin: 10px 5px 0;
    }
    .btnEdit {
        padding: 9px 15px;

        border-radius: 3px;
        margin-right: 10px;
        margin-top: 10px;
    }
    .el-button {
        margin: 5px 0 5px 10px;
    }
</style>
