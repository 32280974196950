<template>
    <el-dialog
        title="キャンペーン枠追加"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>macaroni / 外部</dt>
                <dd>
                    <el-radio-group v-model="articleSourceType">
                        <el-radio
                            v-for="(type, index) in articleSourceTypes"
                            :key="index"
                            :label="index" @change="changeSourceType(index)">{{ type }}</el-radio>
                    </el-radio-group>
                </dd>
                <dt>{{ choise }}</dt>
                <dd>
                    <el-input v-if="is_article" class="article-id" v-model="articleId"></el-input>
                    <el-input v-if="is_topic" class="topic_id" v-model="topicId"></el-input>
                    <el-input v-if="is_serialization" class="serialization_id" v-model="serializationId"></el-input>
                    <el-input v-if="is_link" class="link-url" v-model="linkUrl" placeholder="例：https://trustridge.jp"></el-input>
                </dd>
                <dt>サムネイル</dt>
                <dd>
                    <el-upload
                        class="upload-file"
                        action=""
                        :auto-upload="false"
                        :on-remove="handleRemove"
                        :on-change="handleAdd"
                        list-type="picture"
                        :multiple="false"
                        :limit="1">
                        <el-button size="small" type="primary">画像を選択して下さい</el-button>
                    </el-upload>
                </dd>
                <dt>サムネイル配信比率</dt>
                <dd>
                    <el-input class="title" v-model="ratio"></el-input>
                </dd>
                <dt>サムネイル2</dt>
                <dd>
                    <el-upload
                        class="upload-file"
                        action=""
                        :auto-upload="false"
                        :on-remove="handleRemove2"
                        :on-change="handleAdd2"
                        list-type="picture"
                        :multiple="false"
                        :limit="1">
                        <el-button size="small" type="primary">画像を選択して下さい</el-button>
                    </el-upload>
                </dd>
                <dt>サムネイル2配信比率</dt>
                <dd>
                    <el-input class="title" v-model="ratio2"></el-input>
                </dd>
                <dt>タイトル</dt>
                <dd>
                    <el-input class="title" v-model="title"></el-input>
                </dd>
                <dt>公開開始日時・公開終了日時</dt>
                <el-date-picker
                    v-model="date_range"
                    type="datetimerange"
                    range-separator="〜"
                    start-placeholder="開始日時"
                    end-placeholder="終了日時">
                </el-date-picker>
                <dt>優先度</dt>
                <dd>
                    <el-select v-model="order_num">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </dd>
                <dt>配信チャネル</dt>
                <dd>
                    <el-checkbox v-model="isDistributeIos">iOS</el-checkbox>
                    <el-checkbox v-model="isDistributeAndroid">Android</el-checkbox>
                </dd>
                <dt>広告フラグ</dt>
                <dd>
                    <el-checkbox v-model="isTieup"></el-checkbox>
                </dd>
                <dt>【デイリー】<br>上限クリック数</dt>
                <dd>
                    <el-input class="limit-click-count" v-model="dailyLimitClickCount"></el-input>
                </dd>
                <dt>【合計】<br>上限クリック数</dt>
                <dd>
                    <el-input class="limit-click-count" v-model="limitClickCount"></el-input>
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="add()">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>

export default {
    props: {
        isActive: Boolean,
    },
    data() {
        return {
            articleId  : '',
            topicId: '',
            serializationId:'',
            linkUrl  : '',
            title : '',
            order_num : 1,
            limitClickCount : '',
            dailyLimitClickCount : '',
            image : '',
            image2 : '',
            isDistributeIos : false,
            isDistributeAndroid : false,
            isTieup: false,
            date_range : null,
            imagePreview : '',
            imagePreview2 : '',
            choise:'記事ID',
            ratio:'',
            ratio2:'',
            articleSourceType: 'article',
            is_article : true,
            is_topic: false,
            is_serialization: false,
            is_link: false,
            articleSourceTypes: {
                article: 'macaroni 記事',
                topic: 'macaroni 特集',
                serialization:'macaroni 連載',
                outside: '外部'
            },
            options: [{
                value: 1,
                label: 1
            }, {
                value: 2,
                label: 2
            },],
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.image = ''
            this.imagePreview = ''
            this.$emit('close')
        },
        changeSourceType(type) {
            if (type === 'article') {
                this.is_topic = this.is_link = this.is_serialization = false
                this.is_article = true
                this.choise = '記事ID'
            } else if (type === 'topic') {
                this.is_article = this.is_link = this.is_serialization = false
                this.is_topic = true
                this.choise = '特集ID'
            } else if (type === 'serialization') {
                this.is_article = this.is_topic =  this.is_link = false
                this.is_serialization = true
                this.choise = '連載ID'
            } else {
                this.is_article = this.is_topic = this.is_serialization = false
                this.is_link = true

                this.choise = '外部URL'
            }
        },
        async add() {
            const fd = this.getFormData()
            this.$emit('save', fd)
        },
        handleRemove: function () {
            this.imagePreview = ''
            this.image = ''
        },
        // ファイルを追加した時
        handleAdd: function (file) {
            this.imagePreview = file.url
            this.image = file.raw
        },
        handleRemove2: function () {
            this.imagePreview2 = ''
            this.image2 = ''
        },
        // ファイルを追加した時
        handleAdd2: function (file) {
            this.imagePreview2 = file.url
            this.image2 = file.raw
        },
        getFormData() {
            const fd = new FormData()
            if (this.articleSourceType === 'article') {
                fd.append('article_id', this.articleId)
            } else if(this.articleSourceType === 'topic') {
                fd.append('topic_id', this.topicId)
            } else if(this.articleSourceType === 'serialization'){
                fd.append('serialization_id', this.serializationId)
            } else {
                fd.append('link_url', this.linkUrl)
            }
            if (this.date_range !== null) {
                fd.append('published_start_at', this.getFormattedDateTime(this.date_range[0]))
                fd.append('published_end_at', this.getFormattedDateTime(this.date_range[1]))
            }
            fd.append('title', this.title)
            fd.append('ratio', this.ratio ? this.ratio:'')
            fd.append('ratio2', this.ratio2 ? this.ratio2:'')
            fd.append('order_num', this.order_num)
            fd.append('limit_click_count', this.limitClickCount)
            fd.append('daily_limit_click_count', this.dailyLimitClickCount)
            fd.append('image', this.image ? this.image:'')
            fd.append('image2', this.image2 ? this.image2:'')
            fd.append('is_tieup', Number(this.isTieup))
            fd.append('is_distribute_ios', Number(this.isDistributeIos))
            fd.append('is_distribute_android', Number(this.isDistributeAndroid))
            return fd
        },
        // 日時フォーマッター
        getFormattedDateTime (date) {
            let target_date = new Date(date)
            let formatted_date =  target_date.getFullYear() + '-' + ('0'+(target_date.getMonth() + 1)).slice(-2) + '-' + ('0'+(target_date.getDate())).slice(-2)
            let formatted_time =  ('0'+(target_date.getHours())).slice(-2) + ':' + ('0'+(target_date.getMinutes())).slice(-2) + ':' + ('0'+(target_date.getSeconds())).slice(-2)
            return formatted_date + ' ' + formatted_time
        },
    },
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }
        dt {
            margin-top: 2%;
            width: 30%;
        }

        dd {
            margin-top: 3%;
            margin-bottom:3%;
            width: 70%;
        }
    }
    .preview-img {
        width: 250px;
    }
</style>
