<template>
    <div>
        <el-row type="flex" justify="end">
            <el-input v-model="searchForm.media_name" placeholder="メディア名" style="width: 300px;">
                <el-button
                    @click="fetch()"
                    slot="append"
                    icon="el-icon-search"
                ></el-button>
            </el-input>
        </el-row>
        <el-table
            :data="reprintMedias"
            style="width: 100%"
            max-height="800"
        >
            <el-table-column label="メディア名">
                <template slot-scope="scope">
                    <div @click="openContentModal(scope.row)" style="color: #428bca; cursor: pointer;">{{ scope.row.media_name }}</div>
                </template>
            </el-table-column>
            <el-table-column label="URL" prop="media_url"></el-table-column>
            <el-table-column label="登録日時" prop="created_at"></el-table-column>
            <el-table-column label="RSS巡回ステータス">
                <template slot-scope="scope">
                    <p v-if="scope.row.reprint_type === 'API'">API連携</p>
                    <el-button
                        v-else-if="scope.row.is_active_rss_crawl"
                        type="primary"
                        @click="toggleActive(scope.row)"
                    >有効中</el-button>
                    <el-button
                        v-else
                        @click="toggleActive(scope.row)"
                    >無効中</el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="fetch" :pagination="pagination" />

        <content-list-modal
            :reprint-media="modalReprintMedia"
            :reprint-contents="reprintContents"
            :pagination="pagination.content"
            :categories="categories"
            :is-active="isModalActive.content"
            @createArticle="createArticle"
            @closeModal="closeContentModal"
            @fetch="fetchReprintContent"
        ></content-list-modal>
    </div>
</template>

<script>
import { PAGE_TITLE , LOADING } from '../../store/mutation-types'
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import ContentListModal from './Content/ListModal'

export default {
    components: { Pagination, ContentListModal },
    data() {
        return {
            reprintMedias: [],
            reprintContents: [],
            categories: [],
            pagination: {
                media: {},
                content: {},
            },
            modalReprintMedia: {},
            searchForm: {
                media_name: '',
            },
            isModalActive: {
                content: false,
            },
        }
    },
    created() {
        this.$store.commit(PAGE_TITLE, '転載')
        this.init()
    },
    methods: {
        async init() {
            this.$store.commit(types.LOADING, true)
            await this.fetch()
            await this.fetchCategories()
            this.$store.commit(types.LOADING, false)
        },
        // 一覧取得
        async fetch(page = 1) {
            const { data, error } = await axios.get('/api/reprint/medias', {
                params: {
                    page,
                    media_name: this.searchForm.media_name
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.reprintMedias = data.data
            this.pagination = Object.assign({}, this.pagination, data)
        },
        // 記事のカテゴリを取得
        async fetchCategories() {
            const { data, error } = await axios.get('/api/categories')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.categories = data.categories.slice()
        },
        // 無効化
        async toggleActive(media) {
            this.$store.commit(LOADING, true)
            const { error } = await axios.put('/api/reprint/medias/' + media.id, {
                is_active_rss_crawl: !media.is_active_rss_crawl
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }
            this.$store.commit(LOADING, false)

            this.init()
        },
        closeContentModal() {
            this.isModalActive.content = false
        },
        openContentModal(media) {
            this.modalReprintMedia = media
            this.isModalActive.content = true
        },
        // 転載記事一覧取得
        async fetchReprintContent(page, reprintMediaId, params) {
            this.$store.commit(LOADING, true)

            const { data, error } = await axios.get('/api/reprint/contents/' + reprintMediaId, {
                params: {
                    page,
                    title: params.title,
                    creator: params.creator,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            this.reprintContents = data.reprint_contents.map(reprintContent => ({...reprintContent}))
            this.pagination.content = {...data.pagination}
            this.$store.commit(LOADING, false)
        },
        // 記事作成
        async createArticle(reprintContentId, categoryId, page, reprintMediaId, searchForm) {
            this.$store.commit(LOADING, true)
            const { data, error } = await axios.post('/api/articles/reprints/store', {
                reprint_media_id: reprintMediaId,
                resource_id: reprintContentId,
                category_id: categoryId
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            window.open(data.url)

            await this.fetchReprintContent(page, reprintMediaId, searchForm)
            this.$store.commit(types.SHOW_INFO_MESSAGE, '作成が完了しました。')
            this.$store.commit(LOADING, false)
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
