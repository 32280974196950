<template>
    <div class="recipeLinkItem">
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent label-width="140px" >
            <div class="recipeLinkItem__formHeading">レシピリンクアイテム</div>
            <el-button size="mini" type="primary" @click="() => isOpenInternalLink = true">記事を探す</el-button>
            <div v-if="quoteArticle || this.id">
                <el-form-item label="リンク">
                     <a :href="`https://macaro-ni.jp/${quoteArticle.id}`" target="_blank">{{ `https://macaro-ni.jp/${quoteArticle.id}` }}</a>
                </el-form-item>
                <el-form-item label="リンクタイトル">{{ quoteArticle.title }}</el-form-item>
                <el-form-item v-if="isNewCreate" label="中見出し">
                    <el-input type="text" size="mini" v-model="form.heading" />
                    <el-select
                        placeholder="中見出し種別"
                        v-model="form.heading_type"
                        size="mini"
                    >
                        <el-option
                            v-for="option in headingTypeOptions"
                            :key="option.value"
                            :label="option.label"
                            :value="option.value"
                        >
                        </el-option>
                    </el-select>
                    <el-tooltip content="見出しアイテムとして同時に作成されます。種別によってデザインは変わりません。" placement="bottom">
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="サムネイル画像">
                    <img :src="quoteArticle.thumbnail_cdn_url" width="150px" />
                </el-form-item>
                <el-form-item label="調理時間">{{ quoteArticle.video_article.video_article_cooking_time }}</el-form-item>
                <el-form-item label="材料">
                    <span v-for="(materialItem, materialItemKey) in quoteArticle.material_items" :key="materialItemKey">
                        <span v-for="(element, elementKey) in materialItem.elements" :key="elementKey">{{ element.material.material_name }}&nbsp;&nbsp;</span>
                    </span>
                </el-form-item>
                <el-form-item label="栄養情報">
                    <el-row>
                        <el-col :span="8">エネルギー：{{ quoteArticle.video_article.calorie }}kcal</el-col>
                        <el-col :span="8">タンパク質：{{ quoteArticle.video_article.protein }}g</el-col>
                        <el-col :span="8">脂質：{{ quoteArticle.video_article.lipid }}g</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">炭水化物：{{ quoteArticle.video_article.carbohydrate }}g</el-col>
                        <el-col :span="8">塩分量：{{ quoteArticle.video_article.salt }}g</el-col>
                        <el-col :span="8">糖質：{{ quoteArticle.video_article.saccharinity }}g</el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="説明文">
                    <el-input
                        type="textarea"
                        size="mini"
                        v-model="form.description"
                    />
                </el-form-item>
            </div>
        </el-form>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>

        <el-dialog
            title="macaroniを探す"
            @close="() => isOpenInternalLink = false"
            :visible="isOpenInternalLink"
            class="internalLinkDialogWrapper"
            height="50%"
            width="75%"
            append-to-body
        >
            <internal-link
                :articleFormat="articleFormat"
                :is-display-tag="false"
                :is-only-video="true"
                @save="insertQuoteArticle"
                @close="() => isOpenInternalLink = false"
            />
        </el-dialog>
    </div>
</template>

<script>
import InternalLink from './Components/InternalLink'

const defaultFormValues = () => ({
    id: null,
    quote_article_id: null,
    heading: null,
    heading_type: null,
    description: null,
})

export default {
    components: {
        InternalLink
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        articleFormat: {
            required: true,
            type: String,
        },
        headingTypeOptions: {
            required: true,
            type: Array,
        },
        content: {
            required: false,
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            form: {},
            isOpenInternalLink: false,
            findArticle: null,
        }
    },
    created() {
        this.form = defaultFormValues()
    },
    methods: {
        submitForm () {
            this.save(this.resourceName, this.form, this.id)
        },
        openDialog(input) {
            this.form = Object.assign({}, this.form, input)
        },
        insertQuoteArticle(article) {
            this.findArticle = Object.assign({}, article)
            this.form.quote_article_id = article.id
            this.form.heading = article.title
            this.form.description = null
            this.isOpenInternalLink = false
        }
    },
    computed: {
        quoteArticle() {
            if (this.findArticle) {
                return this.findArticle
            } else if (this.content) {
                return this.content.quote_article
            }
            return null
        },
        resourceName() {
            return 'recipe_links'
        },
        isNewCreate() {
            return this.id === null
        }
    }
}
</script>

<style scoped lang="scss">
.recipeLinkItem {
    &__formHeading {
        font-weight: bold;
        margin-bottom: 10px;
    }
}
</style>
