<template>
    <div>
        <draggable v-model="list">
            <el-row v-for='appBasicRecipe in list' :key="appBasicRecipe.id" style="margin-bottom:5px;">
                <el-col>
                    <el-card class="boxCard">{{ appBasicRecipe.title }}</el-card>
                </el-col>
            </el-row>
        </draggable>
        <el-row class="footerButtons" type="flex" justify="end">
            <el-header>
                <el-col>
                    <el-button type="default" @click="$router.push('/organizations/app_basic_recipe')">戻る</el-button>
                    <el-button type="success" @click="updateOrder">並び替えを保存する</el-button>
                </el-col>
            </el-header>
        </el-row>
    </div>
</template>

<script>

import * as types from '../../../store/mutation-types'
import draggable  from 'vuedraggable'

export default {
    components: {
        draggable
    },
    data() {
        return {
            list: [],
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'アプリ　>　レシピタブ　>　料理の基本　>　並び替え')
    },
    mounted() {
        this.fetch()
    },
    methods: {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data, error } = await axios.get('/api/organization/app_basic_recipes')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            this.list = data.map((appBasicRecipe) => {
                return {
                    id: appBasicRecipe.id,
                    title: appBasicRecipe.app_topic.title
                }
            })
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async updateOrder() {
            const ids = this.list.map((appBasicRecipe, index) => {
                return appBasicRecipe.id
            })
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.post('/api/organization/app_basic_recipes/order', {
                ids: ids
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, '並び順を保存しました。')
            this.$store.commit(types.LOADING, false)
        },
    }
}
</script>
<style scoped>
    .footerButtons {
        background: rgba(255, 255, 255, 0.8);
        position: fixed;
        bottom: 0;
        right: 0;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-right: 40px;
        width: 320px;
    }

    .boxCard {
        cursor: move;
    }
</style>
