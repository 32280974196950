<template>
    <div class="editorWrapper">
        <header-buttons
            v-if="!updateOrdersMode && response"
            v-bind="response"
            :writerList="writerList"
            :isWr="isWr"
            :article-format="articleFormat"
            :fetch="fetch"
        />
        <article-base-table
            v-if="!updateOrdersMode && response"
            v-bind="response"
            :tagList="tagList"
            :fetch="fetch"
            :isTr="isTr"
        />
        <article-main-normal
            v-if="!updateOrdersMode && articleFormat === 'normal'"
            :response="response"
            :fetch="fetch"
        />
        <article-main-rich
            v-if="!updateOrdersMode && articleFormat === 'rich'"
            :response="response"
            :fetch="fetch"
        />
        <article-main-video
            v-if="!updateOrdersMode && articleFormat === 'video'"
            :response="response"
            :fetch="fetch"
        ></article-main-video>
        <item-section
            ref="itemSection"
            :updateOrdersMode="updateOrdersMode"
            @enableCheckedItemControll="(enable, items) => { this.enableUpdateAndDelete = enable; this.checkedItems = items }"
            :fetch="fetch"
            :response="response"
            :article-format="articleFormat"
            :is-tieup="isTieup"
        />
        <div id="articleUpdating" v-if="isEditorLoading">
            <img src="//cdn.macaro-ni.jp/assets/img/loading.gif" width="30" height="30" alt="ローディング中">
            <p>Updating...</p>
        </div>
        <bottom-controller
            @deleteItems="deleteItems"
            @saveUpdateOrder="saveUpdateOrder"
            @movePositionCheckedOrder="movePositionCheckedOrder"
            @moveUpCheckedOrder="moveUpCheckedOrder"
            @moveDownCheckedOrder="moveDownCheckedOrder"
            @moveTopCheckedOrder="moveTopCheckedOrder"
            @moveBottomCheckedOrder="moveBottomCheckedOrder"
            @updateOrderCheckedItems="(isUpdateMode) => this.updateOrdersMode = isUpdateMode"
            @moveToPreviewPage="moveToPreviewPage"
            :response="response"
            :enableUpdateAndDelete="enableUpdateAndDelete"
            :fetch="fetch"
            :is-wr="isWr"
            :can-edit-incentive-format="canEditIncentiveFormat"
            :checked-items="checkedItems"
        />
        <check-ng-words v-if="articleFormat !== 'rich'"
                ref="checkNgWords"
        />
        <ng-word-popup v-if="articleFormat !== 'rich'"
                :ngWordMessage="ngWordMessage"
        />
    </div>
</template>
<script>
import * as types from '../../store/mutation-types'
import HeaderButtons from './Editor/HeaderButtons'
import ArticleBaseTable from './Editor/ArticleBaseTable'
import ArticleMainNormal from './Editor/ArticleMain/Normal'
import ArticleMainRich from './Editor/ArticleMain/Rich'
import ArticleMainVideo from './Editor/ArticleMain/Video'
import BottomController from './Editor/BottomController'
import ItemSection from './Editor/ItemSection'
import CheckNgWords from './Editor/ArticleMain/Components/CheckNgWords'
import NgWordPopup from './Editor/ArticleMain/Components/NgWordPopup'
import { Message } from 'element-ui'
import { mapState } from 'vuex'

export default {
    components: {
        HeaderButtons,
        ArticleBaseTable,
        ArticleMainNormal,
        ArticleMainRich,
        ArticleMainVideo,
        BottomController,
        ItemSection,
        CheckNgWords,
        NgWordPopup,
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data() {
        return {
            response: null,
            tagList: [],
            writerList: [],
            checkedItems: [],
            enableUpdateAndDelete: false,
            updateOrdersMode: false,
            articleFormat: '',
            ngWordMessage: '',
            isTieup: false,
            isWr:false,
            isTr:true,
        }
    },
    async created() {
        this.$store.commit(types.PAGE_LOADING, true)
        await this.fetch()
        this.$store.commit(types.PAGE_LOADING, false)
    },
    computed: {
        ...mapState(['isEditorLoading']),
        canEditIncentiveFormat() {
            return this.articleFormat === 'normal'
        },
    },
    methods: {
        async fetchWithLoading() {
            this.$store.commit(types.PAGE_LOADING, true)
            await this.fetch()
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async fetch() {
            const { data } = await axios.get('/api/articles/' + this.$route.params.id)
            // リライト記事で、オリジナルの記事IDをURLに指定していた場合は、リライト記事にリダイレクトさせる
            if (data.hasOwnProperty('redirect_article_id')) {
                this.$router.push('/articles/' + data.redirect_article_id)
                return
            }

            this.response = Object.assign({}, data)

            // API初回レスポンスチューニングのため、要素数の多いtags, writersは子コンポーネントで別通信で取得する
            axios.get('/api/articles/tags').then((response) => {
                this.tagList = response.data.tagList
            })
            axios.get('/api/articles/writers').then((response) => {
                this.writerList = response.data.writerList
            })

            let formatList = []
            this.response.formatList.forEach((format) => {
                formatList[format.value] = format.label
            })
            this.articleFormat = this.response.article.format
            this.$store.commit(types.PAGE_TITLE, formatList[this.articleFormat])
            // リッチコンテンツの場合はNGチェックを実施しない
            if (this.articleFormat !== 'rich') {
                this.ngWordMessage = this.$refs.checkNgWords.perform(this.response.ngWords, this.response.article)
            }
            this.isTieup = this.response.article.is_tieup
        },
        moveToPreviewPage() {
            window.open(this.response.previewUrl, '_blank')
        },
        moveUpCheckedOrder() {
            this.$refs.itemSection.moveUpCheckedOrder()
        },
        moveDownCheckedOrder() {
            this.$refs.itemSection.moveDownCheckedOrder()
        },
        moveTopCheckedOrder() {
            this.$refs.itemSection.moveTopCheckedOrder()
        },
        movePositionCheckedOrder(position) {
            this.$refs.itemSection.movePositionCheckedOrder(position)
        },
        moveBottomCheckedOrder() {
            this.$refs.itemSection.moveBottomCheckedOrder()
        },
        saveUpdateOrder() {
            this.$refs.itemSection.saveUpdateOrder()
        },
        deleteItems() {
            this.$refs.itemSection.deleteItems()
        },
    }
}
</script>
<style >
.editorWrapper {
    min-width: 977px;
}
.topCard {
    margin-top:20px;
}
.headingLabel {
    float:left;
    color: #909399;
    font-size:14px;
    margin:0 0 20px;
}
.descLabel {
    color: #909399;
    font-size:16px;
    margin: 0 0 10px;
}

.commentArea {
    margin:20px 20px 0;
    padding: 0 20px 10px;
    background-color: #f6f4f4;
}

.commentLabel {
    color: #909399;
    margin-bottom:0px;
    font-weight:bold;
}
.comment {
    margin-top:0px;
    white-space:pre-line;
}

.articleTitle{
    font-weight:normal;
    float:left;
    margin-top:5px;
    margin-left:7px;
    width:650px;

}
.el-button {
    margin: 5px 0 5px 10px;
}

.mainBody {
    width:80%;
    margin:20px;
}

.mainThumbnail{
    width:160px;
    height:160px;
    background-color:#d8d8d8;
}
.itemForm {
    margin: 15px 0 20px;
}
.itemFormFooter {
    margin: 20px 0 0 120px;
}
.editorWrapper div.el-form-item {
    margin-bottom: 6px;
}
div.el-form-item .el-textarea {
    margin-bottom: 6px;
}

#articleUpdating {
    position: fixed;
    z-index: 10000;
    bottom: 70px;
    right: 15px;
    width: 57px;
    height: 63px;
    opacity: 0.8;
    border-radius: 5px;
    background: #999;
    text-align: center;
}
#articleUpdating img {
    padding-top: 10px;
    width: 30px;
    height: 30px;
    box-sizing: content-box;
}
#articleUpdating p {
    margin: 0;
    color: #fff;
    font-size: 10px;
}
.color-red {
    color: #d02b50
}
.text-font-mini,.text-small {
    font-size: 12px
}
.text-font-mid {
    font-size: 14px
}
.text-font-big,.text-large {
    font-size: 16px
}
.text-font-bold {
    font-size: 14px;
    font-weight: 600
}
.text-font-big-bold,.text-large-bold {
    font-size: 16px;
    font-weight: 600
}
.text-bold {
    font-weight: 600
}
.text-italic {
    font-style: italic;
}
.el-form-item__content {
    margin-top: 6px !important;
}
</style>
