var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sortableInputList"},_vm._l((_vm.value),function(item,idx){return _c('el-form-item',{key:idx,attrs:{"label":item.label}},[_c('div',{staticClass:"inlineItem"},[_c('el-input',{attrs:{"value":item.value,"type":"text","size":"mini"},on:{"input":newValue => _vm.$emit('input',
                        _vm.value.map((v, i) => i === idx ? { label: v.label, value: newValue } : v)
                    )}}),_vm._v(" "),_c('div',{staticClass:"controlBtnList"},[_c('el-button',{attrs:{"type":"info","icon":"el-icon-caret-top","size":"mini","disabled":idx === 0},on:{"click":function($event){_vm.$emit('input', _vm.value.map((v, i) => {
                            switch (i) {
                                case idx - 1: return _vm.value[idx]
                                case idx: return _vm.value[idx - 1]
                                default: return v
                            }
                        }))}}}),_vm._v(" "),_c('el-button',{attrs:{"type":"info","icon":"el-icon-caret-bottom","size":"mini","disabled":idx === _vm.value.length - 1},on:{"click":function($event){_vm.$emit('input', _vm.value.map((v, i) => {
                            switch (i) {
                                case idx: return _vm.value[idx + 1]
                                case idx + 1: return _vm.value[idx]
                                default: return v
                            }
                        }))}}}),_vm._v(" "),_c('el-button',{attrs:{"plain":"","type":"warning","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){_vm.$emit('input', _vm.value.filter((_, i) => i !== idx))}}})],1)],1)])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }