<template>
    <div>
        <el-row>
            <item-left-contents
                :check-state="checkState"
                :number="number"
                :page-number="item.page_number"
                @update:checkState="checkState = $event"
            />

            <el-col class="contents" :span="21">
                <information-type
                    v-if="isEditing"
                    ref="dialogModule"
                    :save="update"
                    :id="content.id"
                    :input="content"
                    :information-item-type-list="response.informationItemTypeList"
                    :information-item-other-label-list="response.informationItemOtherLabelList"
                    :information-item-table-body-evaluation="response.informationItemTableBodyEvaluation"
                    :information-items="response.informationItems"
                    :items="response.article.items"
                    @close="closeMenu"
                ></information-type>
                <div v-else class="information">
                    <span v-if="content.is_comparison_table_base_heading" class="information__baseHeadingText">ベース</span>
                    <div class="information__brand">{{ content.brand }}</div>
                    <div class="information__series">{{ content.series }}</div>
                    <div class="information__name">{{ content.name }}</div>
                    <div class="information__affiliateWrapper">
                        <div v-if="content.rakuten_affiliate_url">
                            <a :href="content.rakuten_affiliate_url" target="_blank" class="information__affiliateList">
                                <div class="information__affiliateLabel">楽天</div>
                                <div class="information__affiliatePrice">{{ content.rakuten_affiliate_price }}円</div>
                                <div class="information__affiliateTax">（税込み）</div>
                            </a>
                            <div class="information__affiliateCheckedAt">※価格チェック日付</div>
                            <div class="information__affiliateCheckedAt">{{ changeCheckedDateFormat(content.rakuten_affiliate_checked_at) }}</div>
                        </div>
                        <div v-if="content.amazon_affiliate_url">
                            <a :href="content.amazon_affiliate_url" target="_blank" class="information__affiliateList">
                                <div class="information__affiliateLabel">Amazon</div>
                                <div class="information__affiliatePrice">{{ content.amazon_affiliate_price }}円</div>
                                <div class="information__affiliateTax">（税込み）</div>
                            </a>
                            <div class="information__affiliateCheckedAt">※価格チェック日付</div>
                            <div class="information__affiliateCheckedAt">{{ changeCheckedDateFormat(content.amazon_affiliate_checked_at) }}</div>
                        </div>
                        <div v-if="content.yahoo_affiliate_url">
                            <a :href="content.yahoo_affiliate_url" target="_blank" class="information__affiliateList">
                                <div class="information__affiliateLabel">Yahoo</div>
                                <div class="information__affiliatePrice">{{ content.yahoo_affiliate_price }}円</div>
                                <div class="information__affiliateTax">（税込み）</div>
                            </a>
                            <div class="information__affiliateCheckedAt">※価格チェック日付</div>
                            <div class="information__affiliateCheckedAt">{{ changeCheckedDateFormat(content.yahoo_affiliate_checked_at) }}</div>
                        </div>
                        <div v-if="content.other_url">
                            <a :href="content.other_url" target="_blank" class="information__affiliateList--other">
                                <div class="information__affiliateLabel">{{ content.other_label_text }}</div>
                            </a>
                        </div>
                    </div>
                    <div class="information__imageWrapper">
                        <div v-for="(image, idx) in content.images" :key="idx" class="information__imageList">
                            <img class="information__image" :src="image.image_src" />
                        </div>
                    </div>
                    <div class="information__heading">{{ content.heading }}</div>
                    <div class="information__body">{{ content.body }}</div>
                    <div class="information__tableWrapper">
                        <div v-for="(element, idx) in content.elements" :key="idx" class="information__tableList">
                            <div class="information__tableLabel">{{ element.label }}</div>
                            <div v-if="element.body_type === 'EVALUATION'" class="information__tableBody">
                                <span class="information__tableBodyEvaluation" :data-rate="element.body"></span>
                            </div>
                            <div v-else class="information__tableBody">{{ element.body }}</div>
                        </div>
                    </div>
                </div>
            </el-col>


            <el-col class="rightBox" :span="2" justify="end" v-if="!updateOrdersMode">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList">下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', resourceName, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>

    </div>
</template>
<script>

import FeedbackForm from './Components/FeedbackForm'
import InformationType from '../Form/InformationType'
import moment from 'moment'
import ItemLeftContents from './Components/ItemLeftContents'

export default {
    components: {
        ItemLeftContents,
        InformationType,
        FeedbackForm,
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    data() {
        return {
            isEditing: false,
        }
    },
    computed: {
        content() {
            return this.item.content
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        resourceName() {
            return 'information'
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check, item) {
            this.parentCheckItem(check, item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
        changeCheckedDateFormat(date) {
            return moment(date).format('YYYY-MM-DD')
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>
<style scoped lang="scss">
.information {
    position: relative;
    &__tableWrapper {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        white-space: pre-wrap;
    }
    &__tableList {
        width: 50%;
        height: 70px;
        display: flex;
        border-left: 1px solid;
        border-right: 1px solid;
        border-bottom: 1px solid;
        box-sizing: border-box;
        &:nth-child(even) {
            border-left: none;
        }
        &:nth-child(1),
        &:nth-child(2) {
            border-top: 1px solid;
        }
    }
    &__tableLabel {
        width: 30%;
        padding: 15px;
        border-right: 1px solid;
        background: #FFD3F3;
    }
    &__tableBody {
        width: 70%;
        padding: 15px;
    }
    &__tableBodyEvaluation {
        position: relative;
        z-index: 0;
        display: inline-block;
        white-space: nowrap;
        color: #ffcf32;
        &::before {
            content: '☆☆☆☆☆';
        }
        &::after {
            content: '★★★★★';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            overflow: hidden;
            white-space: nowrap;
            color: #ffcf32;
        }
        &[data-rate="5"]:after{ width: 100%; }
        &[data-rate="4.5"]:after{ width: 90%; }
        &[data-rate="4"]:after{ width: 80%; }
        &[data-rate="3.5"]:after{ width: 70%; }
        &[data-rate="3"]:after{ width: 60%; }
        &[data-rate="2.5"]:after{ width: 50%; }
        &[data-rate="2"]:after{ width: 40%; }
        &[data-rate="1.5"]:after{ width: 30%; }
        &[data-rate="1"]:after{ width: 20%; }
        &[data-rate="0.5"]:after{ width: 10%; }
        &[data-rate="0"]:after{ width: 0; }
    }
    &__brand {
        color: #828282;
    }
    &__series {
        color: #828282;
    }
    &__name {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
    }
    &__affiliateWrapper {
        margin-top: 10px;
        display: flex;
    }
    %__affiliateList {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 110px;
        border-radius: 10px;
        color: white;
        text-decoration: none;
        margin-bottom: 5px;
    }
    &__affiliateList {
        @extend %__affiliateList;
        background: #FF7B7B;
        margin-right: 20px;
        &--other {
            @extend %__affiliateList;
            background: #FFAB7B;
            margin-right: 0;
        }
    }
    &__affiliateLabel {
        width: 100%;
        text-align: center;
        font-size: 12px;
    }
    &__affiliatePrice {
        width: 100%;
        text-align: center;
    }
    &__affiliateTax {
        width: 100%;
        text-align: center;
        font-size: 12px;
    }
    &__affiliateCheckedAt {
        font-size: 12px;
        color: #878787;
    }
    &__heading {
        margin-top: 15px;
        font-size: 20px;
        font-weight: bold;
    }
    &__body {
        margin-top: 10px;
        white-space: pre-wrap;
    }
    &__imageWrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
    }
    &__imageList {
        width: 150px;
        height: 150px;
        margin-right: 15px;
        margin-bottom: 15px;
        border: 1px solid #cccccc;
        &:last-child {
            margin-right: 0;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    &__baseHeadingText {
        position: absolute;
        background: #cccccc;
        color: white;
        right: 0;
        top: 0;
        padding: 5px;
    }
}

.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}
</style>
