<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-container">
                <div v-if="ngWordMessage === ''">
                    <div class="modal-header">
                        NGワードは含まれていません
                    </div>
                </div>
                <div v-else>
                    <div class="modal-header">
                        ️NGワードが含まれています
                    </div>
                    <div class="modal-body">{{ngWordMessage}}</div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'NgWordPopup',
    props: {
        ngWordMessage: String,
    },
}
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        transition: opacity 0.3s ease;
        bottom: 60px;
        right: 3px;
        z-index: 1000;
    }
    .modal-container {
        width: 250px;
        margin: 0px auto;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        border-radius: 5px;
    }
    .modal-header {
        display: block;
        text-align: center;
        border: 0px;
        padding: 10px 0 10px 0;
        font-size: 0.9rem;
    }
    .modal-body {
        display: block;
        padding: 0 10px 10px 10px;
        border: 0px;
        font-size: 0.7rem;
        white-space: pre-wrap;
        overflow: scroll;
        max-height: 100px;
    }
</style>