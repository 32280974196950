<template>
    <div class="internalLinkDialog">
        <el-form @submit.native.prevent="startSearch">
            <el-form-item>
                <el-input
                    type="text"
                    size="mini"
                    v-model="keyword">
                    <el-button native-type="submit" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </el-form-item>
            <el-form-item v-if="!isOnlyVideo">
                <el-checkbox v-model="isVideo">動画記事のみ</el-checkbox>
            </el-form-item>
        </el-form>
        <el-row v-if="isDisplayTag && tags.length">
            <h4>タグの検索結果</h4>
            <div v-for="(tag, index) in tags" :key="index" class="tagRow">
                <a :href="tag.tag_url" target="_blank" v-if="tag.child">親タグ {{ tag.child.parent.tag_name }}<br>子タグ {{ tag.child.tag_name }}<br>孫タグ {{ tag.tag_name }}</a>
                <a :href="tag.tag_url" target="_blank" v-else-if="tag.parent">親タグ {{ tag.parent.tag_name }}<br>子タグ {{ tag.tag_name }}</a>
                <a :href="tag.tag_url" target="_blank" v-else>親タグ {{ tag.tag_name }}</a>
                <el-button type="primary" size="mini"  @click="saveByTag(tag)">追加する</el-button>
            </div>
        </el-row>
        <el-row v-if="articles.length" style="margin-top: 50px;">
            <h4>記事の検索結果</h4>
            <div v-for="(article, index) in articles" :key="index" class="articleRow">
                <div class="articleRowTop">
                    <img :src="article.thumbnail_cdn_url" class="articleThumbnail">
                    <div class="articleRowRight">
                        <a :href="article.article_url" class="articleRowTitle">{{ article.title }}</a>
                        <div class="articleRowDescription" style="-webkit-box-orient: vertical;">{{ article.description }}</div>
                    </div>
                </div>
                <div class="articleRowBottom">
                    <a :href="article.article_url" target="_blank">{{ article.article_url }}</a>
                    <el-button type="primary" size="mini" @click="saveByArticle(article)" class="addLinkButton">追加する</el-button>
                </div>
            </div>
            <el-button v-if="showSearchMore" @click="searchMore" class="nextPageButton">もっと見る</el-button>
        </el-row>
        <div v-if="loading" v-loading="loading" class="loading"></div>
    </div>
</template>
<script>
import * as types from '../../../../../../store/mutation-types'

export default {
    props: {
        articleFormat: String,
        isDisplayTag: {
            required: false,
            default: true,
            type: Boolean,
        },
        isOnlyVideo: {
            required: false,
            default: false,
            type: Boolean,
        }
    },
    data () {
        return {
            isVideo: this.articleFormat === 'video',
            keyword: '',
            page: 1,
            articles: [],
            tags: [],
            showSearchMore: true,
            loading: false,
        }
    },
    methods:{
        async search() {
            this.loading = true
            const {data,error} = await axios.get('/api/articles/items/links/internalLink', {
                params: {
                    is_video: this.isOnlyVideo ? 1 : (this.isVideo ? 1 : 0),
                    search_text: this.keyword,
                    page: this.page,
                }
            })

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '検索に失敗しました')
                this.loading = false
                return
            }

            if (data.article.data.length < 20) {
                this.showSearchMore = false
            }

            this.articles = Object.assign([], this.articles.concat(data.article.data))
            this.tags = Object.assign([], data.tag)
            this.loading = false
        },
        startSearch() {
            this.page = 1
            this.articles = []
            this.tags = []
            this.showSearchMore = true
            this.search()
        },
        searchMore() {
            this.page++
            this.search()
        },
        async saveByTag(tag) {
            let tagType = ''
            if (tag.child) {
                tagType = 'grandchild'
            } else if (tag.parent) {
                tagType = 'child'
            } else {
                tagType = 'parent'
            }
            this.$emit('save',false, tag.id, true, tagType)
        },
        async saveByArticle(article) {
            this.$emit('save', article, true, true)
        }
    },
}
</script>
<style scoped>
.articleThumbnail {
    width: 90px;
    height: 90px;
}
.addLinkButton {
    float: right;
}
.articleRow,
.tagRow {
    padding: 12px 0;
    border-bottom: 1px solid #dcdfe6;
}
.tagRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.articleRowTop {
    display: flex;
}
.articleRowRight {
    padding-left: 10px;
}
.articleRowTitle {
    font-size: 16px;
    display: block;
}
.articleRowDescription {
    font-size: 12px;
    height: 55px;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.articleRowBottom {
    display: flex;
    font-size: 11px;
    margin-top: 3px;
    justify-content: space-between;
}
.loading {
    padding: 20px 0;
}
.nextPageButton {
    margin: 20px 0;
    width: 100%;
}
</style>
