<template>
    <el-dialog title="グレード, 金額を編集" @close="closeDialog" :visible="dialog" height="50%">
        <el-form :model="form"  label-width="120px">
            <el-form-item label="グレード" >
                <el-select v-model="form.grade_id" placeholder="未設定 : 0円">
                    <el-option
                        v-for="item in gradeList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="撮影ランク">
                <el-select v-model="form.writer_photo_rank_id" placeholder="未設定">
                    <el-option
                        v-for="photoRank in writerPhotoRanks"
                        :key="photoRank.id"
                        :label="photoRank.name + ' (' + photoRank.low_price + '円/' + photoRank.high_price + '円)'"
                        :value="photoRank.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="単価" >
                <el-col :span="14">
                    <el-input v-model="form.unit_price" placeholder="0">
                        <template slot="append">円</template>
                    </el-input>
                </el-col >
            </el-form-item>
            <el-form-item label="インセンティブ" >
                <el-col :span="18">
                    <el-input v-model="incentiveFormat.description" placeholder="説明" style="width: 50%"></el-input>
                    <el-input v-model="incentiveFormat.price" placeholder="0" style="width: 30%">
                        <template slot="append">円</template>
                    </el-input>
                    <el-button @click="addIncentive">追加</el-button>
                </el-col>
                <el-col :span="14" style="margin-top: 10px;">
                    <span>現在：{{ currentIncentive | number }}円</span>
                </el-col>
                <el-col :span="14">
                    <el-table v-if="form.incentives.length !== 0" :data="form.incentives">
                        <el-table-column label="説明" prop="description"></el-table-column>
                        <el-table-column label="金額">
                            <template slot-scope="scope">{{ scope.row.price | number }}円</template>
                        </el-table-column>
                        <el-table-column label="削除">
                            <template slot-scope="scope">
                                <el-button @click="deleteIncentive(scope.$index)">削除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-form-item>
            <el-form-item label="固定費" >
                <el-col :span="14">
                    <el-tooltip class="item" effect="dark" content="この欄に入力がある場合、他の金額計算はされず、完全に固定費で計上されます" placement="bottom-start">
                        <el-input v-model="form.fixed_cost" placeholder="0">
                            <template slot="append">円</template>
                        </el-input>
                    </el-tooltip>
                </el-col >
            </el-form-item>
            <el-form-item label="ライター向けコメント" >
                <el-col :span="16">
                    <el-input type="textarea" v-model="form.comment" :rows=5></el-input>
                </el-col >
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">キャンセル</el-button>
            <el-button type="primary" @click="submitForm">変更する</el-button>
        </span>
    </el-dialog>
</template>
<script>


import * as types from '../../../store/mutation-types'

export default {
    name: 'TrWriterFeeEditModal',
    props: {
        dialog: Boolean,
        input: Object,
        grades: Array,
        save: Function,
        closeDialog: Function,
        writerPhotoRanks: Array,
    },
    data () {
        return {
            gradeList: [],
            form: {
                grade_id: 0,
                unit_price: 0,
                fixed_cost: 0,
                comment: '',
                writer_photo_rank_id: null,
                incentives: [],
            },
            incentiveFormat: {
                description: '',
                price: 0,
            },
            currentIncentive: 0,
        }
    },
    watch: {
        input() {
            // formで定義されているプロパティのみ親コンポーネントから受け取り
            this.form = Object.assign({}, this.form, _.pick(this.input, Object.keys(this.form)))
            this.form.incentives = []
            this.currentIncentive = this.input.result.incentive
        },
        grades() {
            this.gradeList = this.grades.map((grade) => {
                return {id: grade.id, label: grade.name + ' : ' + grade.weight}
            })
        }
    },
    methods: {
        async submitForm() {
            await this.save(this.input.id, this.form)
            this.initIncentiveFormat()
            this.closeDialog()
        },
        addIncentive() {
            if (this.incentiveFormat.description === '') {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '説明は必須です。')
                return
            }
            this.form.incentives.push(this.incentiveFormat)
            this.initIncentiveFormat()
        },
        deleteIncentive(idx) {
            this.form.incentives.splice(idx, 1)
        },
        initIncentiveFormat() {
            this.incentiveFormat = {
                description: '',
                price: 0,
            }
        }
    }
}
</script>

