<template>
    <div>
        <div class="errorText">
            <p>2023/6/30現在X社のAPIの利用が出来なくなっております。</p>
            <p>再開までお待ち下さい。</p>
        </div>
        <el-row align="middle" style="margin-top: 20px;">
            <el-radio-group v-model="tabs" size="mini">
                <el-radio-button label="url">URLから検索</el-radio-button>
                <el-radio-button label="keyword">キーワード入力</el-radio-button>
            </el-radio-group>
        </el-row>

        <el-row v-if="tabs === 'url'">
            <el-form class="itemForm" @submit.native.prevent label-width="150px" >
                <el-form-item label="ツイートURL入力" style="width:80%;">
                    <el-input
                        width="50%"
                        type="text"
                        size="small"
                        disabled
                        v-model="tweetUrl">
                        <el-button @click="searchTweetFromUrl" disabled slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-form-item>
            </el-form>
            <twitter-contents :twitter="twitterResult" v-if="fetchedTwitter" :showImage="!hiddenImage" />
            <el-form class="hiddenImageCheck" v-if="fetchedTwitter && twitterResult.tweet_image_url">
               <el-checkbox v-model="hiddenImage" >画像を非表示にする</el-checkbox>
            </el-form>
            <div  class="itemFormFooter" style="margin-left: 150px;">
                <el-button type="primary" size="mini" disabled @click="submitForm">保存する</el-button>
                <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
            </div>
        </el-row>

        <el-row v-if="tabs === 'keyword'">
            <el-form class="itemForm" @submit.native.prevent label-width="150px" >
                <el-form-item label="キーワード入力" style="width:80%;">
                    <el-input
                        width="50%"
                        type="text"
                        size="small"
                        disabled
                        v-model="tweetKeyword">
                        <el-button @click="searchTweetFromKeywrod" disabled slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-form-item>
                <div class="keywordOptions">
                    <el-checkbox v-model="excludeRt">RTを除く</el-checkbox>
                    <el-checkbox v-model="onlyImgTweet" >画像のみを表示する</el-checkbox>
                </div>
            </el-form>
            <div class="keywrodResults" v-if="keywrodResults">
                <div class="keywrodResult" v-for="(result, index) in keywrodResults" :key="index">
                    <twitter-contents :twitter="result" :showImage="!hiddenImageList[index]"  :addTweetResultIndex="index" :parentAddTweetResult="addTweetResult"/>
                    <el-form class="hiddenImageCheck" v-if="result.tweet_image_url">
                       <el-checkbox v-model="hiddenImageList[index]" >画像を非表示にする</el-checkbox>
                    </el-form>
                </div>
            </div>
        </el-row>
    </div>
</template>

<script>

import * as types from '../../../../../store/mutation-types'
import TwitterContents from './Components/Twitter'

const defaultFormValues =  {
    user_name:null,
    screen_name:null,
    icon_url:null,
    tweet_url:null,
    tweet_text:null,
    tweet_image_url:null,
    tweeted_at:null,
    is_show_image:false
}
export default {
    components:{
        TwitterContents
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        saveAndContinue: {
            required: true,
            type: Function,
        }
    },
    data () {
        return {
            form: {...defaultFormValues},
            fetchedTwitter: false,
            tweetUrl: null,
            tweetKeyword: null,
            twitterResult:null,
            hiddenImage:true,
            keywrodResults:[],
            hiddenImageList:[],
            excludeRt:true,
            onlyImgTweet:false,
            tabs:'url',
        }
    },
    methods: {
        submitForm () {
            this.form = Object.assign({}, defaultFormValues, {is_show_image:!this.hiddenImage}, this.twitterResult)
            this.save(this.resourceName, this.form, this.id)
        },
        addTweetResult (twitter, index) {
            this.form = Object.assign({}, defaultFormValues, {is_show_image:!this.hiddenImageList[index]}, twitter)
            this.saveAndContinue('tweet', this.form, this.id)
        },
        async searchTweetFromUrl() {
            this.$store.commit(types.EDITOR_LOADING, true)
            const {data,error} = await axios.get('/api/twitter/statuses/show', {
                params: {
                    twitter_url:this.tweetUrl
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'URL検索に失敗しました')
                this.$store.commit(types.EDITOR_LOADING, false)
                return
            }
            this.twitterResult = data
            this.fetchedTwitter = true
            if (this.twitterResult.tweet_image_url) {
                this.hiddenImage = false
            }
            this.$store.commit(types.EDITOR_LOADING, false)
        },
        async searchTweetFromKeywrod() {
            this.$store.commit(types.EDITOR_LOADING, true)
            const {data,error} = await axios.get('/api/twitter/search/tweets', {
                params: {
                    keyword:this.tweetKeyword,
                    excludeRt:this.excludeRt,
                    onlyImgTweet:this.onlyImgTweet
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'キーワード検索に失敗しました')
                this.$store.commit(types.EDITOR_LOADING, false)
                return
            }
            this.keywrodResults = Object.assign([], data)
            this.$store.commit(types.EDITOR_LOADING, false)
        }
    },
    computed: {
        resourceName() {
            return 'tweet'
        },
    }
}
</script>
<style scoped>
.keywrodResults {
    max-height: 600px;
    overflow: scroll;
    width: 800px;
    margin: 0 auto;
}
.keywrodResult {
    margin:20px 0;
}
.hiddenImageCheck{
    margin-top:-10px;
    margin-bottom:20px;
    text-align:center;
}
.keywordOptions{
    margin-left:150px;
}
.errorText {
    color: red;
}
</style>
