<template>
    <el-dialog
        :title="reprintMedia.media_name"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="closeModal"
        width="80%"
    >
        <el-row type="flex" justify="end">
            <el-input v-model="searchForm.title" placeholder="記事タイトル" style="width: 30%; margin-right: 20px;">
                <el-button
                    @click="fetch()"
                    slot="append"
                    icon="el-icon-search">
                </el-button>
            </el-input>
        </el-row>
        <el-table
            :data="reprintContents"
            style="width: 100%; margin-top: 20px;"
        >
            <el-table-column label="タイトル" prop="title"></el-table-column>
            <el-table-column label="URL">
                <template slot-scope="scope">
                    <a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
                </template>
            </el-table-column>
            <el-table-column label="公開日時" prop="published_at"></el-table-column>
            <el-table-column label="作成時記事カテゴリー">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.category_id" placeholder="カテゴリー">
                        <el-option
                            v-for="category in categories"
                            :key="category.id"
                            :label="category.category_name"
                            :value="category.id">
                        </el-option>
                      </el-select>
                </template>
            </el-table-column>
            <el-table-column label="作成">
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.is_created"
                        @click="createArticle(scope.row.id, scope.row.category_id)"
                    >作成済み</el-button>
                    <el-button
                        v-else
                        type="primary"
                        @click="createArticle(scope.row.id, scope.row.category_id)"
                    >作成</el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="fetch" :pagination="pagination" />
    </el-dialog>
</template>

<script>
import Pagination from '../../../components/Pagination'

export default {
    components: { Pagination },
    props: {
        reprintMedia: Object,
        reprintContents: Array,
        categories: Array,
        pagination: Object,
        isActive: Boolean,
    },
    data() {
        return {
            searchForm: {
                title: '',
                creator: '',
            },
        }
    },
    methods: {
        init() {
            this.searchForm = {
                title: '',
                creator: '',
            }
            this.fetch()
        },
        // 一覧取得
        async fetch(page = 1) {
            this.$emit('fetch', page, this.reprintMedia.id, this.searchForm)
        },
        // 記事作成
        async createArticle(reprintContentId, categoryId) {
            this.$emit('createArticle', reprintContentId, categoryId, 1, this.reprintMedia.id, this.searchForm)
        },
        closeModal() {
            this.$emit('closeModal')
        },
    },
    watch: {
        reprintMedia() {
            this.init()
        }
    },
}
</script>
