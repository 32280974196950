<template>
    <div>
        <h4>一致率判定</h4>
        <p>{{typeof ccdLink.match_percent !== 'undefined' && ccdLink.match_percent !== null ? ccdLink.match_percent + '%' : ''}}</p>
        <h4>類似度判定</h4>
        <p>{{ typeof ccdLink.like_percent !== 'undefined' && ccdLink.like_percent !== null ?
            ccdLink.like_percent + '%' : ''}}</p>
        <h4>対象文字一覧</h4>
        <p class="likeText">{{typeof ccdLink.like_text !== 'undefined' && ccdLink.like_text !==
            null ? ccdLink.like_text : ''}}</p>
    </div>
</template>
<script>
export default {
    name: 'ConfirmCcdText',
    props: {
        ccdLink: Object
    }
}
</script>
<style scoped>
    .likeText{
        white-space:pre-wrap;
    }
</style>