<template>
    <div>
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent label-width="190px" >
            <el-form-item label="調理時間">
                <el-input-number
                    size="mini"
                    v-model="form.cooking_time"
                    :min="0"
                ></el-input-number> 分
            </el-form-item>
            <el-form-item label="調理時間に関するコメント">
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.cooking_time_comment"
                />
                <text-length-count :targetText="form.cooking_time_comment" :maxTextLength="255" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
    </div>
</template>

<script>
import TextLengthCount from './Components/TextLengthCount'

const defaultFormValues = () => ({
    id: null,
    cooking_time: null,
    total_cooking_time: 0,
    cooking_time_comment: null,
})

export default {
    components: {
        TextLengthCount
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
    },
    data() {
        return {
            form: {},
        }
    },
    created() {
        this.form = defaultFormValues()
    },
    methods: {
        submitForm () {
            this.save(this.resourceName, this.form, this.id)
        },
        openDialog(input) {
            this.form = {...defaultFormValues(), ..._.pick(input, Object.keys(this.form))}
        },
    },
    computed: {
        resourceName() {
            return 'cooking_times'
        },
    }
}

</script>

<style scoped lang="scss">
</style>
