<template>
    <div>
        <el-row type="flex" justify="end">
            <el-header >
                <el-col >
                    <el-upload class="uploadButton" accept=".csv" :headers="header" :before-upload="importCsvStart" :on-error="importCsvError" :on-success="importCsvSuccess" :show-file-list="false" action="/api/rewrite_plots/csv" name="upload_rewrite_plot">
                        <el-button type="warning" icon="el-icon-upload2">
                            CSVアップロード
                        </el-button>
                    </el-upload>
                    <el-button type="warning" @click="downloadCSV" icon="el-icon-download">
                        CSVダウンロード
                    </el-button>
                </el-col>
            </el-header >
        </el-row>
        <el-row type="flex" justify="end" style="margin-top:10px;">
            <el-select placeholder="カテゴリでフィルタ" v-model="category" class="search">
                 <el-option
                     v-for="(item) in categoryFilterList"
                     :key="item.id"
                     :label="item.category_name"
                     :value="item.id">
                 </el-option>
            </el-select>
            <el-input
                placeholder="SEOキーワード"
                v-model="keyword"
                class="search keyword"
                @keyup.enter.native="search()"
            ></el-input>
            <el-button
                @click="search()"
                icon="el-icon-search"
                class="search-input"
                style="height: 40px; margin-top:10px;"
            ></el-button>
        </el-row>
        <el-row>
            <el-col>
                <el-row type="flex" justify="end">
                    <plot-row
                        :tableData=tableData
                        :categoryList="categoryList"
                        :articleTypeList="articleTypeList"
                        :isRewrite="true"
                        :update="update"
                        :assignWriter="assignWriter"
                        :deleteRow="deleteRow"
                        :create="create"
                        :tagList="tagList"
                        :rewrite-payment-types="rewritePaymentTypes"
                        @initArticleUnitPrice="initArticleUnitPrice"
                    >
                    </plot-row>
                </el-row>
            </el-col>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />

        <assign-writer-modal
            :is-active="assignActive"
            :input="assignWriterForm"
            @save="assignWriterSave"
            @close="closeAssignModal"
        />

        <el-dialog
            title="プロット削除"
            :visible.sync="deleteConfirmDialog"
            width="30%"
            @close="closeDeleteDialog"
        >
            <span >プロットを削除してよろしいですか？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDeleteDialog">キャンセル</el-button>
                <el-button type="danger" @click="performDeleteDialog">削除する</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>

import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import AssignWriterModal from './RewritePlot/AssignWriterModal'
import SearchBox from '../../components/SearchBox'
import FileDownload from 'js-file-download'
import PlotRow from '../../components/Editing/PlotRow'

export default {
    components: {
        Pagination,
        AssignWriterModal,
        SearchBox,
        PlotRow
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data() {
        return {
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            registrationActive: false,
            editActive: false,
            assignActive: false,
            pagination: {},
            tableData: [],
            categories: {},
            articleTypes: {},
            form: {},
            deleteConfirmDialog: false,
            deleteConfirmId: null,
            confirmPlot: null,
            confirmPlotDialog: false,
            updateId: '',
            assignWriterForm: {},
            categoryList: [],
            articleTypeList: [],
            categoryFilterList: {},
            tagList: [],
            keyword: '',
            category: null,
            rewritePaymentTypes: [],
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'リライト　>　プロット')
        this.queryInit()
        this.fetch()
    },
    methods: {
        queryInit() {
            this.category = this.$route.query.category ? Number(this.$route.query.category) : null
            this.keyword = this.$route.query.keyword ? this.$route.query.keyword : ''
        },
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data } = await axios.get('/api/rewrite_plots', {
                params: { ...this.$route.query }
            })

            axios.get('/api/rewrite_plots/tags').then((response) => {
                this.tagList = response.data.tag_list
            })

            this.pagination = Object.assign({}, data.plots)

            /* フィルター用 */
            this.categoryFilterList = Object.assign([], data.categories)
            this.categoryFilterList.unshift({category_name: null, id: null})
            /* フィルター用 */

            this.categories = this.idKeyList(data.categories)
            this.categoryList = Object.values(this.categories).map((category) => {
                return {id: category.id, label: category.category_name}
            })
            this.categoryList.unshift({id: null, label: 'カテゴリ未設定'})

            this.rewritePaymentTypes = [...data.rewrite_payment_types]

            this.articleTypes = this.idKeyList(data.article_types)
            this.articleTypeList = Object.values(this.articleTypes).map((articleType) => {
                return {id: articleType.id, label: articleType.type_name}
            })
            this.articleTypeList.unshift({id: null, label: '記事種別未設定'})
            const datalist = Object.entries(data.plots.data).map(([key, data]) => {
                return {
                    article_id: data.rewrite.rewrite_article_id,
                    admin_id: data.admin_id,
                    admin_name: data.admin ? data.admin.editor_name : null,
                    article_type_id: data.article_type_id,
                    category_id: data.category_id,
                    id: data.id,
                    plot: data.plot,
                    keywords: Object.entries(data.keywords).map(([key, keyword]) => {
                        return keyword.keyword
                    }),
                    parent_tags: data.parent_tags,
                    child_tags: data.child_tags,
                    grandchild_tags: data.grandchild_tags,
                    payment_type: data.rewrite.payment_type,
                    article_unit_price: data.rewrite.article_unit_price,
                }
            })

            this.tableData = Object.assign([], datalist)
            if (this.pagination.current_page === 1) {
                this.tableData.unshift({
                    article_id: null,
                    id: null,
                    keywords: [],
                    parent_tags: [],
                    child_tags: [],
                    grandchild_tags: [],
                    admin_id: null,
                    category_id: null,
                    article_type_id: null,
                    plot: null,
                    payment_type: 'CHARACTER',
                    article_unit_price: null,
                })
            }
            this.$store.commit(types.PAGE_LOADING, false)
        },
        search() {
            const query = Object.assign({}, this.$route.query, {
                category: this.category,
                keyword: this.keyword,
                page: 1
            })
            this.$router.push({ query })
        },
        async create(form) {
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.post('/api/rewrite_plots', form)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.registrationActive = false
            this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'プロットを作成しました')
            this.$store.commit(types.LOADING, false)
        },
        async update(param) {
            this.$store.commit(types.LOADING, true)
            const form = Object.assign({}, param)
            const { error } = await axios.patch('/api/rewrite_plots/' + form.id, form)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.editActive = false
            this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'プロットを変更しました')
            this.$store.commit(types.LOADING, false)
        },
        async assignWriter(form) {
            this.assignActive = true
            this.assignWriterForm = Object.assign({}, form)

        },
        deleteRow(data) {
            this.deleteConfirmId = data.id
            this.deleteConfirmDialog = true
        },
        closeDeleteDialog() {
            this.deleteConfirmId = null
            this.deleteConfirmDialog = false
        },
        async performDeleteDialog() {
            this.$store.commit(types.PAGE_LOADING, true)
            await axios.delete('/api/rewrite_plots/' + this.deleteConfirmId )
            await this.fetch()
            this.$store.commit(types.PAGE_LOADING, false)
            this.deleteConfirmId = null
            this.deleteConfirmDialog = false
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'プロットを削除しました')
        },
        async assignWriterSave(form, id) {
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.post('/api/rewrite_plots/' + id + '/assign/writer', form)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'ライターの割当に失敗しました。')
                return
            }
            this.assignActive = false
            this.fetch()
        },
        async importCsvSuccess(event) {
            if (event.status === 400) {
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_ERROR_MESSAGE, event.error)
            } else {
                await this.fetch()
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'CSVデータをアップロードしました')
            }
        },
        async downloadCSV() {
            this.$store.commit(types.PAGE_LOADING, true)
            const { data, error } = await axios.get('/api/rewrite_plots/csv')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            const bom  = new Uint8Array([0xEF, 0xBB, 0xBF])
            FileDownload(data, 'download_rewrite_plot.csv', null, bom)
            this.$store.commit(types.PAGE_LOADING, false)
        },
        closeAssignModal() {
            this.assignActive = false
        },
        idKeyList(list) {
            return _.keyBy(list, (data) => data.id)
        },
        handleCurrentChange(page) {
            const query = Object.assign({}, this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
        importCsvStart() {
            this.$store.commit(types.LOADING, true)
        },
        importCsvError(e) {
            let errorMsg = 'CSVアップロードに失敗しました'
            if (e.status === 400) {
                const errorJson = JSON.parse(e.message)
                errorMsg = errorJson.hasOwnProperty('message') ? errorJson.message : errorMsg
            } else if (e.status === 422) {
                let errorJson = JSON.parse(e.message)
                errorMsg = typeof errorJson.error !== 'undefined' ? errorJson.error : errorMsg
            } else if (e.status === 403) {
                errorMsg = 'この操作は許可されていません。'
            }
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_ERROR_MESSAGE, errorMsg)
        },
        initArticleUnitPrice(plotId) {
            const idx = this.tableData.findIndex(value => value.id === plotId)
            const newPlot = {...this.tableData[idx], article_unit_price: null}
            this.$set(this.tableData, idx, newPlot)
        }
    }
}
</script>
<style scoped>
    .wordList {
        margin:0 5px;
    }
    .confirmPlot {
        white-space:pre-line;
        text-align:left;
        max-height:150px;
        margin-top:-10px;
    }
    .plot-confirm {
        white-space:pre-wrap
    }
    .el-button {
        margin: 5px 0 5px 10px;
    }
    .search{
        margin: 10px 5px 0;
    }
    .search.keyword {
        width: 400px;
    }
</style>
