<template>
    <el-dialog
            title="URLブラックリスト追加"
            :visible="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>URL</dt>
                <dd>
                    <el-input class="path-input" placeholder="例:http://example.com" v-model="path" @keyup.enter.native="storeBlacklist(path)"></el-input>
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="storeBlacklist(path)">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../../store/mutation-types'

export default {
    props  : {
        isActive: Boolean,
    },
    data() {
        return {
            path: '',
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        // URLブラックリストを追加
        async storeBlacklist(path) {
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.post('/api/quote/url/blacklists', {
                path
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.path = ''
            this.$emit('close')
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの追加に成功しました。')
            this.$emit('reload')
        },
    },
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            width: 20%;
            padding-top: 2%;
        }

        dd {
            margin-bottom:3%;
            width: 70%;
        }
    }
</style>
