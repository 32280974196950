<template>
    <div>
        <el-row>
            <item-left-contents
                :check-state="checkState"
                :number="number"
                :page-number="item.page_number"
                @update:checkState="checkState = $event"
            />

            <el-col class="contents" :span="21">
                <table-type
                    v-if="isEditing"
                    ref="dialogModule"
                    :save="update"
                    @close="closeMenu"
                    :id="content.id"
                    :input="content"
                    :table-item-color-list="response.tableItemColorList"
                />
                <div v-else class="tableItem">
                    <el-row>
                        <div class="tableItem__body" v-html="content.text_body"></div>
                    </el-row>
                </div>
            </el-col>

            <el-col class="rightBox" :span="2" justify="end" v-if="!updateOrdersMode">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', resourceName, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>
    </div>
</template>
<script>

import FeedbackForm from './Components/FeedbackForm'
import TableType from '../Form/TableType'
import ItemLeftContents from './Components/ItemLeftContents'

export default {
    components: {
        ItemLeftContents,
        TableType,
        FeedbackForm,
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
        updateFeedback: {
            required: false,
            type: Function,
        },
    },
    data () {
        return {
            isEditing: false,
        }
    },
    computed: {
        content() {
            return this.item.content
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        resourceName() {
            return 'tables'
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check,item) {
            this.parentCheckItem(check,item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        },
    }
}
</script>
<style scoped lang="scss">
    .contents {
        width: 732px;
        line-height: 1.7em;
        font-size: 14px;
    }

    .rightBox {
        width: 170px;
        float: right;
    }
    .tableItem {
        &__body {
            text-align: left;
            font-size: 18px;
            letter-spacing: 0.5px;
            line-height: 1.8;
            & .text-small {
                font-size: 14px;
            }
            & .text-large {
                font-size: 22px;
            }
            & .text-bold {
                font-weight: bold;
            }
            & .text-large-bold {
                font-size: 20px;
                font-weight: bold;
            }
            & .color-red {
                color: #d02b50;
            }
        }
    }
    ::v-deep .tableItem__body {
        overflow-x: scroll;
        table {
            border: 1px solid #ddd;
            width: 100%;
            border-collapse: collapse;
            white-space: nowrap;
            td, th {
                border:0;
                padding: 10px 10px 10px 30px;
                display: table-cell;
                vertical-align: middle;
                font-weight: normal;
            }
            tr {
                border-bottom: 1px solid #ddd;
            }
            tr:last-child {
                border-bottom: 0;
            }
        }
    }
</style>

