<template>
    <el-dialog
            title="APP献立ヒント - 追加"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>献立タイトル</dt>
                <dd>
                    <el-input placeholder="タイトル" v-model="title"></el-input>
                </dd>

                <dt>公開</dt>
                <dd>
                    <el-checkbox v-model="isPublished"></el-checkbox>
                </dd>

                <dt class="selectedRecipeList">選択済みレシピリスト</dt>
                <dd>
                    <el-button
                        @click="swapSelectedList"
                        :disabled="disabledSwap"
                    >
                        順番入れ替え
                    </el-button>
                </dd>
                <dd>
                    <el-table
                        :data="selectedLists"
                    >

                        <el-table-column label="ID" prop="id"></el-table-column>
                        <el-table-column label="タイトル" prop="title"></el-table-column>
                        <el-table-column label="バナー画像" width="350">
                            <template slot-scope="scope">
                                <div class="image">
                                    <label :for="'image_' + scope.row.id">
                                        <img v-if="scope.row.banner_url" :src="scope.row.banner_url" width="100" alt="サムネイル">
                                    </label>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="除去">
                            <template slot-scope="scope">
                                <el-button
                                    type="primary"
                                    @click="removeSelectedList(scope.row)"
                                >
                                    除去
                                </el-button>
                            </template>
                        </el-table-column>

                    </el-table>
                </dd>

            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="add(title, tabIsActive)">作成</el-button>
            <el-button @click="close">閉じる</el-button>
        </span>
        <div>
            <dl>
                <dt class="searchRecipeList">レシピリスト検索</dt>
                <dd></dd>
                <dd>
                    <el-form ref="form" :inline="true" :model="searchForm">
                        <el-form-item>
                            <el-input
                                v-model="searchForm.id"
                                placeholder="ID"
                                type="number"
                                min="1"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                v-model="searchForm.title"
                                placeholder="タイトル"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="searchLists">検索</el-button>
                        </el-form-item>
                    </el-form>
                </dd>

                <dd>
                    <el-table
                        :data="searchedLists"
                    >
                        <el-table-column label="ID" prop="id"></el-table-column>
                        <el-table-column label="タイトル" prop="title"></el-table-column>
                        <el-table-column label="バナー画像" width="350">
                            <template slot-scope="scope">
                                <div class="image">
                                    <label :for="'image_' + scope.row.id">
                                        <img v-if="scope.row.banner_url" :src="scope.row.banner_url" width="100" alt="サムネイル">
                                    </label>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="作成日時" prop="created_at"></el-table-column>
                        <el-table-column label="追加">
                            <template slot-scope="scope">
                                <el-button
                                    type="primary"
                                    @click="addAppRecipeMenuList(scope.row)"
                                    :disabled="disabledAdd"
                                >
                                    追加
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
                </dd>
            </dl>
        </div>
    </el-dialog>
</template>

<script>
import Pagination from '../../../../components/Pagination'
import * as types from '../../../../store/mutation-types'

export default {
    components : {
        Pagination,
    },
    props : {
        isActive: Boolean,
    },
    data() {
        return {
            searchForm : {
                appRecipeMenuListId  : '',
                title                : '',
            },
            searchedLists   : [],
            selectedLists   : [],
            selectedListIds : [],
            title           : '',
            isPublished     : false,
            tabIsActive     : false,
            pagination      : {},
        }
    },
    computed : {
        disabledAdd : function(){
            return this.selectedLists.length > 1
        },
        disabledSwap : function(){
            return this.selectedLists.length < 2
        }
    },
    methods : {
        // フォーム初期化
        formInit() {
            this.searchForm = {
                id           : '',
                title        : '',
            }
            this.title = ''
            this.isPublished = false
            this.selectedLists = []
            this.searchedLists = []
            this.selectedListIds = []
        },
        close() {
            this.formInit()
            this.$emit('close')
        },
        // 記事検索
        async searchLists(page = 1) {
            if (!this.isInit) {
                this.$store.commit(types.LOADING, true)
            }
            const params = {page}
            if (this.searchForm.id !== '') {
                params.id = this.searchForm.id
            }
            if (this.searchForm.title !== '') {
                params.title = this.searchForm.title
            }
            const {data, error} = await axios.get('/api/organization/app_recipe_menu/hints/fetch_app_recipe_lists', {params})
            if (!this.isInit) {
                this.$store.commit(types.LOADING, false)
            }
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '検索に失敗しました。')
                return
            }
            this.searchedLists = data.data
            this.pagination = Object.assign({}, this.pagination, data)
        },
        // 記事追加
        addAppRecipeMenuList(row) {
            this.selectedLists.push(row)
            this.selectedListIds.push(row.id)
            this.searchedLists = this.searchedLists.filter(list => row.id !== list.id )
        },
        // 選択済みリストを除去
        removeSelectedList(row) {
            this.searchedLists.push(row)
            this.selectedLists = this.selectedLists.filter(list => row.id !== list.id )
            this.selectedListIds = this.selectedListIds.filter(id => id !== row.id )
        },
        // 献立ヒント追加
        async add() {
            this.$store.commit(types.LOADING, true)
            const sendData = this.getSendData()
            const {data, error} = await axios.post('/api/organization/app_recipe_menu/hints', sendData)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの追加に成功しました。')
                this.close()
            }
            this.tabIsActive = false
            this.$store.commit(types.LOADING, false)
            this.$emit('reload')
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.searchLists()
        },
        swapSelectedList() {
            this.selectedLists = this.selectedLists.reverse()
            this.selectedListIds = this.selectedListIds.reverse()
        },
        getSendData() {
            const orderList = this.selectedListIds.map((listId, index) => {
                return {id: listId, order_num: index + 1}
            })
            return {
                title:                 this.title,
                is_published:          this.isPublished,
                app_recipe_menu_lists: orderList
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.content {
    dl {
        display: flex;
        flex-wrap: wrap;
    }

    dt {
        width: 15%;
        margin-bottom: 10px;
    }

    dd {
        width: 85%;
        margin-bottom: 10px;
    }

    .type-detail-select,
    .type-select {
        width: 400px;
    }
}
.searchRecipeList {
  padding-top: 50px;
}
.selectedRecipeList {
  padding-top: 20px;
}
</style>
