<template>
    <div class="macaroniVideo">
        <el-row>
            <el-row>
                <el-form class="itemForm" @submit.native.prevent="startSearchKeyword" >
                    <el-col :span="8">
                        <el-form-item style="width:275px;">
                            <el-input
                                type="text"
                                size="mini"
                                placeholder="タイトル、ID"
                                v-model="idTitle">
                                <el-button
                                    native-type="submit"
                                    slot="append"
                                    icon="el-icon-search"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item style="width:275px;">
                            <el-input
                                type="text"
                                size="mini"
                                placeholder="料理名"
                                v-model="cookName">
                                <el-button
                                    native-type="submit"
                                    slot="append"
                                    icon="el-icon-search"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item style="width:275px;">
                            <el-input
                                type="text"
                                size="mini"
                                placeholder="材料名（鶏肉 卵）"
                                v-model="materialName">
                                <el-button
                                    native-type="submit"
                                    slot="append"
                                    icon="el-icon-search"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <el-row>
                <el-table :data="list" >
                    <el-table-column label="ID（リンク）" width="110">
                        <template slot-scope="scope">
                            <a :href="scope.row.article_url" target="_blank">{{ scope.row.id }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="タイトル" />
                    <el-table-column label="画像" width="220">
                        <template slot-scope="scope">
                            <img :src="scope.row.thumbnail" alt="" class="thumbnail">
                        </template>
                    </el-table-column>
                    <el-table-column label="追加" width="110">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" :disabled="scope.row.added" @click="addItem(scope.row, scope.$index)">
                                <span v-if="scope.row.added">
                                    追加済み
                                </span>
                                    <span v-else>
                                    追加する
                                </span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row >
            <el-row v-if="total && list.length < total" type="flex" justify="center">
                <el-button style="width:80%;margin-top:10px;" @click="searchMore">次を読み込む</el-button>
            </el-row>
        </el-row>
        <div v-if="loading" v-loading="loading" class="loading"></div>
    </div>
</template>
<script>
import * as types from '../../../../../../store/mutation-types'

export default {
    name:'ItemImageMacaroniVideo',
    data () {
        return {
            list: [],
            page: 1,
            total: null,
            cookName:null,
            materialName:null,
            loading:false,
        }
    },
    props:{
        parentKeyword:null,
        imageType: String,
    },
    computed: {
        idTitle: {
            get() {
                return this.parentKeyword
            },
            set(keyword) {
                this.$emit('updateKeyword',keyword)
            }
        },
    },
    methods: {
        async searchKeyword() {
            this.loading = true
            const {data, error} = await axios.get('/api/macaroniVideo/search', {
                params: {
                    idTitle: this.idTitle,
                    cookName: this.cookName,
                    materialName: this.materialName,
                    page: this.page,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '検索に失敗しました')
                this.loading = false
                return
            }
            if (this.page === 1 && data.article.length === 0) {
                this.resetSearchResult()
            } else {
                this.list = Object.assign([], this.list.concat(data.article))
                this.page = data.current_page
                this.total = data.total
            }
            this.loading = false
        },
        searchMore() {
            this.page++
            this.searchKeyword()
        },
        startSearchKeyword() {
            this.resetSearchResult()
            this.searchKeyword()
        },
        resetSearchResult() {
            this.list = []
            this.page = 1
            this.total = null
        },
        async addItem(row, index) {
            row.added = true
            this.$set(this.list, index, Object.assign({}, row, {added: true}))
            if (this.imageType === 'item') {
                this.$emit('save', 'MacaroniVideo', {
                    image_src      : row.article_url,
                    quote_url      : row.article_url,
                    quote_text     : row.title,
                    quote_image_url: row.thumbnail,
                    photo_by_text  : 'macaroni'
                })
            } else if (this.imageType === 'icon') {
                this.$emit('saveIcon', {
                    thumbnail_quote_image_url: row.thumbnail,
                    quote_image_url          : row.article_url
                })
            }
        },
    }
}

</script>
<style>
.macaroniVideo .thumbnail {
    height: 100px;
}
</style>
