<template>
    <div>
        <el-dialog
            title="詳細"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
        >
            <div class="content">
                <dl>
                    <dt>編集者</dt>
                    <dd>{{ editorName }}</dd>
                    <dt>最終更新者</dt>
                    <dd>{{ lastUpdateAdminName }}</dd>
                    <dt>SEOキーワード</dt>
                    <dd>{{ keyWords }}</dd>
                </dl>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    props: {
        keyWords: Array,
        editorName: String,
        lastUpdateAdminName: String,
        isActive: Boolean
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss" scoped>
    dl {
        display: flex;
        flex-wrap: wrap;
    }
    dt {
        width: 15%;
        margin-top: 30px;
    }
    dd {
        width: 85%;
        margin-top: 30px;
    }
</style>
