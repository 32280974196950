<template>
    <el-dialog
            title="APP見つける特集 - 追加"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>タイトル</dt>
                <dd>
                    <el-input placeholder="タイトル" v-model="title"></el-input>
                </dd>
                <dt>表示</dt>
                <dd>
                    <el-checkbox v-model="tabIsActive"></el-checkbox>
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="add(title, tabIsActive)">追加</el-button>
            <el-button @click="close">閉じる</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../store/mutation-types'

export default {
    props  : {
        isActive: Boolean,
    },
    data() {
        return {
            title      : '',
            tabIsActive: false,
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        // 追加
        async add(title, tabIsActive) {
            if (!title.length) {
                return
            }
            this.$store.commit(types.LOADING, true)
            const params = {
                title    : title,
                is_active: tabIsActive
            }
            const {data, error} = await axios.post('/api/organization/app_topics', params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'データの追加に失敗しました。')
                return
            }
            this.title = ''
            this.tabIsActive = false
            this.$emit('close')
            this.$store.commit(types.LOADING, false)
            this.$emit('reload')
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの追加に成功しました。')
        },
    },
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            width: 15%;
            margin-bottom: 10px;
        }

        dd {
            width: 85%;
            margin-bottom: 10px;
        }

        .type-detail-select,
        .type-select {
            width: 400px;
        }
    }
</style>