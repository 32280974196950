<template>
    <div class="ad-article">
        <el-table :data="appAdArticles" style="font-size: 12px" max-height="800" :row-class-name="makeTableRowClass">
            <el-table-column :render-header="renderTableHeader(['記事ID/','URL（外部）'])" min-width="200">
                <template v-slot="scope">
                    <div class="text" v-if="scope.row.article_id !== null">
                        <el-input v-model="scope.row.article_id" placeholder="記事ID" size="mini"></el-input>
                    </div>
                    <div class="text" v-else>
                        <el-input v-model="scope.row.link_url" placeholder="URL（外部）" size="mini"></el-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :render-header="renderTableHeader(['クリエイティブ名','表示企業名（外部）'])" min-width="200">
                <template v-slot="scope">
                    <el-input v-model="scope.row.name" class="span-2column-first-input" placeholder="クリエイティブ名" size="mini"></el-input>
                    <br />
                    <el-input v-if="scope.row.article_id === null" v-model="scope.row.screen_name" placeholder="表示企業名（外部）" size="mini"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="サムネイル" width="120">
                <template v-slot="scope">
                    <label :for="'image_' + scope.row.id">
                        <img v-if="scope.row.image" :src="scope.row.image_cdn_url" width="100" alt="サムネイル">
                        <el-button v-else size="small" type="primary">画像選択</el-button>
                        <input type="file" accept="image/png,image/jpeg,image/gif" style="display: none;" :id="'image_' + scope.row.id" @change="selectedFile($event, scope.row)"/>
                    </label>
                </template>
            </el-table-column>
            <el-table-column :render-header="renderTableHeader(['タイトル', '配信期間', 'PRマーク非表示'])" width="330">
                <template v-slot="scope">
                    <el-input v-model="scope.row.title" class="span-2column-first-input" placeholder="タイトル" size="mini"></el-input>
                    <el-date-picker
                        :value="makeDateValue(scope.row)"
                        @input="handleDateChange($event, scope.$index)"
                        type="datetimerange"
                        range-separator="〜"
                        start-placeholder="開始日時"
                        end-placeholder="終了日時"
                        size="mini"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width: 320px;"
                    />
                    <el-checkbox
                        label="PRマーク非表示"
                        style="margin-top: 5px"
                        v-model="scope.row.hide_pr_mark_flag"
                        size="mini"
                    />
                </template>
            </el-table-column>

            <el-table-column :render-header="renderTableHeader(['配信比率', 'クリック'])" width="140">
                <template v-slot="scope">
                    <el-input-number
                        v-model="scope.row.distribute_ratio"
                        :min="0"
                        :max="100"
                        :disabled="isArchive"
                        size="mini"
                    />
                    <div>デイリー：{{ scope.row.daily_click_count }}</div>
                    <div>合計：{{ scope.row.click_count }}</div>
                </template>
            </el-table-column>

            <el-table-column width="150">
                <template v-slot="scope">
                    <el-button
                        type="warning"
                        class="update-button"
                        size="mini"
                        @click="updateAdArticle(scope.row)"
                    >更新</el-button>
                    <el-button
                        v-if="scope.row.is_archive"
                        type="info"
                        class="update-button"
                        size="mini"
                        @click="updateArchive(scope.row, false)"
                    >アーカイブを解除</el-button>
                    <el-button
                        v-else
                        type="info"
                        class="update-button"
                        size="mini"
                        @click="updateArchive(scope.row, true)"
                    >アーカイブ</el-button>
                    <el-button
                        type="danger"
                        class="update-button"
                        size="mini"
                        @click="openDeleteConfirmModal(scope.row.id)"
                    >削除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    props: {
        appAdArticles: {
            type: Array,
            required: true,
        },
        isArchive: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        selectedFile(e, appAdArticle) {
            this.$emit('selectedFile', e, appAdArticle, this.isArchive)
        },
        updateAdArticle(appAdArticle) {
            this.$emit('updateAdArticle', appAdArticle)
        },
        updateArchive(appAdArticle, isArchive) {
            this.$emit('updateArchive', appAdArticle, isArchive)
        },
        openDeleteConfirmModal(appAdArticleId) {
            this.$emit('openDeleteConfirmModal', appAdArticleId)
        },
        handleDateChange(value, idx) {
            this.$set(this.appAdArticles, idx, {
                ...this.appAdArticles[idx],
                distribute_start_at: value === null ? null : value[0],
                distribute_end_at: value === null ? null : value[1],
            })
        },
        makeDateValue(appAdArticle) {
            if (appAdArticle.distribute_start_at !== null && appAdArticle.distribute_end_at !== null) {
                return [appAdArticle.distribute_start_at, appAdArticle.distribute_end_at]
            }
            return null
        },
        makeTableRowClass({row, rowIndex}) {
            return !this.isArchive && !row.is_distribute ? 'notDistribute' : ''
        },
    },
}
</script>

<style scoped>
    .span-2column-first-input {
        margin-bottom: 10px;
    }
    .update-button {
        margin: 0 0 10px 0 !important;
    }
    ::v-deep .notDistribute {
        background-color: #e2e2e2;
    }
</style>
