<template>
    <div>
        <div style="margin-left: 160px">
            <el-button type="text" size="mini" @click="() => this.cooperateAffiDialog = true">探して追加する</el-button>
        </div>
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent label-width="155px" >
            <el-form-item label="商品名" >
                <el-input
                    type="text"
                    size="mini"
                    maxlength="120"
                    v-model="form.affi_name">
                </el-input>
            </el-form-item>
            <el-form-item label="商品説明" style="margin: 0;">
                <el-input
                    type="textarea"
                    size="mini"
                    :rows="3"
                    maxlength="120"
                    v-model="form.affi_description">
                </el-input>
                <text-length-count :targetText="form.affi_description" :maxTextLength="120"></text-length-count>
            </el-form-item>
            <el-form-item label="amazon価格" >
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.amazon_price">
                </el-input>
            </el-form-item>
            <el-form-item label="楽天価格" >
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.rakuten_price">
                </el-input>
            </el-form-item>
            <el-form-item label="yahoo価格">
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.yahoo_price">
                </el-input>
            </el-form-item>
            <el-form-item label="商品価格チェック日付" >
                <el-date-picker
                    v-model="form.price_checked_at"
                    type="date"
                    size="mini"
                    placeholder="日付を入力"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="AmazonURL" >
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.amazon_url">
                </el-input>
            </el-form-item>
            <el-form-item label="楽天URL" >
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.rakuten_url">
                </el-input>
            </el-form-item>
            <el-form-item label="YahooURL" >
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.yahoo_url">
                </el-input>
            </el-form-item>
            <el-form-item v-for="(sub, index) in subThumbnails" :key="index" :label=getLabel(index) >
                <div class="subBox">
                    <div class="inputSub">
                        <el-input
                            type="text"
                            size="mini"
                            v-model="subThumbnails[index]">
                        </el-input>
                    </div>
                    <div class="operation">
                        <el-button @click="subThumbnailMoveUp(sub, index)" icon="el-icon-arrow-up" size="mini" type="mini"></el-button>
                        <el-button @click="subThumbnailMoveDown(sub, index)" icon="el-icon-arrow-down" size="mini"></el-button>
                        <el-button @click="subThumbnailRemove(sub, index)" icon="el-icon-close" size="mini"></el-button>
                    </div>
                </div>
                <div class="subImage">
                    <img :src=subThumbnails[index] class="image">
                </div>
            </el-form-item>
            <div class="elementAddButton">
                <el-button size="mini" @click="addSub">追加する</el-button>
            </div>
        </el-form>
        <span slot="footer" class="itemFormFooter" style="margin-left: 155px;">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
        <el-dialog
            title="連携メディアから探す"
            @close="() => this.cooperateAffiDialog = false"
            :visible="cooperateAffiDialog"
            class="cooperateImageDialogWrapper"
            height="50%"
            width="1100px"
            append-to-body
        >
            <cooperate-affiliate
                @save="saveMediaItem"
                @close="() => this.cooperateAffiDialog = false"
                :imageType="'affiItem'" />
        </el-dialog>
    </div>
</template>

<script>

import TextLengthCount from './Components/TextLengthCount'
import CooperateAffiliate from './Components/CooperateAffiliate'

const defaultFormValues =  {
    affi_name:null,
    affi_description:null,
    affi_thumbnail_url:null,
    affi_price:null,
    amazon_price:null,
    rakuten_price:null,
    yahoo_price:null,
    price_checked_at:null,
    amazon_url:null,
    rakuten_url:null,
    yahoo_url:null,
    amazon_asin:null,
}
export default {
    components: {
        TextLengthCount,
        CooperateAffiliate
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
    },
    data () {
        return {
            form: {...defaultFormValues},
            pickerOptions: {
                shortcuts: [{
                    text: '今日',
                    onClick(picker) {
                        picker.$emit('pick', new Date())
                    }
                }]
            },
            subThumbnails:[],
            cooperateAffiDialog: false,
        }
    },
    computed: {
        resourceName() {
            return 'affiliates'
        }
    },
    mounted() {
        this.form.price_checked_at = new Date()
        this.subThumbnails.push(null)
    },
    methods: {
        submitForm () {
            this.form.thumbnails = this.subThumbnails

            this.save(this.resourceName, this.form, this.id)
        },
        openDialog(input) {
            this.form = Object.assign({}, defaultFormValues, _.pick(input, Object.keys(this.form)))
            this.form.price_checked_at = new Date()

            let subThumbnails = []
            if (this.form.affi_thumbnail_url.length > 0) {
                subThumbnails[0] = this.form.affi_thumbnail_url
            }

            if (input.sub_thumbnails.length > 0) {
                input.sub_thumbnails.forEach((value, index) => {
                    subThumbnails[subThumbnails.length] = value.sub_thumbnail_url
                })
            }
            this.subThumbnails = subThumbnails
        },
        addSub() {
            this.subThumbnails.push(null)
        },
        subThumbnailMoveDown(sub, index) {
            const movedIndex = index + 1
            if (this.subThumbnails[movedIndex]) {
                this.subThumbnails.splice(index, 2, this.subThumbnails[movedIndex], this.subThumbnails[index])
            }
        },
        subThumbnailMoveUp(sub, index) {
            if (index > 0) {
                const movedIndex = index - 1
                this.subThumbnails.splice(movedIndex, 2, this.subThumbnails[movedIndex+1], this.subThumbnails[movedIndex])
            }
        },
        subThumbnailRemove(sub, index) {
            this.subThumbnails.splice(index, 1)
        },
        getLabel(index) {
            if (index === 0) {
                return '商品画像'
            }
            return '商品サブ画像'
        },
        async saveMediaItem(media, form){
            let subFirstFlag = false
            if (this.subThumbnails.length  === 1 && this.subThumbnails[0] === null) {
                subFirstFlag = true
            }

            if (media === 'amazon') {
                this.form.affi_thumbnail_url = form.affi_thumbnail_url
                if (subFirstFlag) {
                    this.subThumbnails[0] = form.affi_thumbnail_url
                } else {
                    this.subThumbnails.push(form.affi_thumbnail_url)
                }
                if (form.amazon_price === '値段情報がありません') {
                    form.amazon_price = 0
                }
                if (form.sub_images) {
                    form.sub_images.map(sub_image => {
                        this.subThumbnails.push(sub_image)
                    })
                }
                this.form.amazon_price = form.amazon_price
                this.form.amazon_url = form.amazon_url
                this.form.amazon_asin = form.amazon_asin
            } else if (media === 'Rakuten') {
                this.form.rakuten_price = form.rakuten_price
                this.form.rakuten_url = form.rakuten_url
                this.form.affi_thumbnail_url = form.affi_thumbnail_url
                if (subFirstFlag) {
                    this.subThumbnails[0] = form.affi_thumbnail_url
                } else {
                    this.subThumbnails.push(form.affi_thumbnail_url)
                }
            } else if (media === 'Yahoo') {
                this.form.yahoo_price = form.yahoo_price
                this.form.yahoo_url = form.yahoo_url
                this.form.affi_thumbnail_url = form.affi_thumbnail_url
                if (subFirstFlag) {
                    this.subThumbnails[0] = form.affi_thumbnail_url
                } else {
                    this.subThumbnails.push(form.affi_thumbnail_url)
                }
            }
            this.cooperateAffiDialog = true
        },
    }
}
</script>
<style>
    .elementBox,.subBox {
        display: flex;
        justify-content: space-between;
    }
    .inputElement,.inputSub,.operation {
        flex-grow: 1;
        margin-left: 10px;
    }
    .elementAddButton {
        margin-left: 160px;
    }
    .subImage {
        margin-left: 10px;
    }
    .image {
        width: 150px;
    }
</style>
