<template>
    <el-dialog
            title="ドメイン追加"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>ドメイン</dt>
                <dd>
                    <el-input placeholder="例: macaro-ni.jp" v-model="domain"></el-input>
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="add(domain)">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../store/mutation-types'

export default {
    props  : {
        isActive: Boolean,
    },
    data() {
        return {
            domain: '',
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        async add(domain) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.post('/api/external_relation/ccd_exclude_domains', {
                domain
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.domain = ''
            this.$emit('close')
            this.$store.commit(types.LOADING, false)
            this.$emit('reload')
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの追加に成功しました。')
        }
    }
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            width: 15%;
            padding-top: 3%;
        }

        dd {
            margin-top:1.5%;
            width: 70%;
        }
    }
</style>
