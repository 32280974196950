<template>
    <div class="materialForm">
        <material-video
            v-if="articleFormat === 'video'"
            ref="childModule"
            :id="id"
            :save="save"
            :resource-name="resourceName"
            @close="close"
        ></material-video>
        <material-normal
            v-else-if="articleFormat === 'normal'"
            ref="childModule"
            :id="id"
            :save="save"
            :resource-name="resourceName"
            @close="close"
        ></material-normal>
    </div>
</template>

<script>

import MaterialVideo from './Material/MaterialVideo'
import MaterialNormal from './Material/MaterialNormal'

export default {
    components: {
        MaterialVideo,
        MaterialNormal,
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        articleFormat: {
            required: true,
            type: String,
        }
    },
    methods: {
        openDialog(input) {
            setTimeout(() => {
                this.$refs.childModule.openDialog(input)
            }, 1)
        },
        close() {
            this.$emit('close')
        }
    },
    computed: {
        resourceName() {
            return 'materials'
        }
    }
}
</script>

<style>
</style>
