<template>
    <div>
        <el-row type="flex" justify="end">
            <div class="search-form">
                <el-row type="flex" justify="end" style="margin-top:10px;font-size: 12px" >
                    <el-input class="search-form__input" v-model="searchForm.article_id" placeholder="記事ID" @keydown.enter="search"></el-input>
                    <el-input class="search-form__input" v-model="searchForm.id" placeholder="レビューID"></el-input>
                    <el-input class="search-form__input" v-model="searchForm.auth_user_id" placeholder="ユーザーID"></el-input>
                    <el-input class="search-form__input" v-model="searchForm.user_nickname" placeholder="ユーザー名"></el-input>
                    <el-select class="search-form__select" v-model="searchForm.status" placeholder="ステータス">
                        <el-option
                            v-for="articleReviewStatus in articleReviewStatuses"
                            :key="articleReviewStatus.value"
                            :label="articleReviewStatus.label"
                            :value="articleReviewStatus.value">
                        </el-option>
                    </el-select>
                    <el-input class="search-form__input" v-model="searchForm.admin_name" placeholder="管理者名"></el-input>
                    <el-input class="search-form__input" v-model="searchForm.review_comment" placeholder="レビュー内容"></el-input>
                </el-row>
                <el-row type="flex" justify="end" style="margin-top:10px;font-size: 12px" >
                    <el-date-picker
                        class="search-form__date"
                        v-model="searchForm.create_start_at"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="ユーザー作成開始日時"
                    ></el-date-picker>
                    <div class="search-form__range">〜</div>
                    <el-date-picker
                        class="search-form__date"
                        v-model="searchForm.create_end_at"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="ユーザー作成終了日時"
                    ></el-date-picker>
                    <el-button @click="search" icon="el-icon-search"></el-button>
              </el-row>
            </div>
        </el-row>
        <div class="articleReviews">
            <el-table
                :data="articleReviews"
                class="articleReviews__table"
                max-height="800"
            >
                <el-table-column width="90" :render-header="renderTableHeader(['記事ID','レビューID','記事ステータス'])" >
                    <template slot-scope="scope">
                        <a :href="'/articles/'+scope.row.article_id" target="_blank">{{ scope.row.article_id }}</a>
                        <br>
                        {{ scope.row.id }}
                        <br>
                        {{ scope.row.article.status }}
                    </template>
                </el-table-column>
                <el-table-column width="120" :render-header="renderTableHeader(['ユーザーID','ユーザー名'])" >
                    <template slot-scope="scope">
                        {{ scope.row.auth_user_id }}
                        <br>
                        {{ scope.row.nickname }}
                    </template>
                </el-table-column>
                <el-table-column :render-header="renderTableHeader(['レビュー点数','レビュー内容'])" >
                    <template slot-scope="scope">
                        {{ scope.row.review_score }}
                        <br>
                        <p class="articleReviews__comment" v-text="scope.row.review_comment"></p>
                    </template>
                </el-table-column>
                <el-table-column width="140" :render-header="renderTableHeader(['ユーザー作成日','ユーザー更新日','画像'])" >
                    <template slot-scope="scope">
                        {{ scope.row.created_at }}
                        <br>
                        {{ scope.row.updated_at }}
                        <br>
                        <el-popover v-if="scope.row.image_url" placement="left" trigger="hover">
                            <img alt="image" class="articleReviews__image" :src="scope.row.image_url" >
                            <div slot="reference">
                                <img alt="thumbnail" class="articleReviews__thumbnail" :src="scope.row.image_url" >
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column width="140" :render-header="renderTableHeader(['ステータス','最終管理者更新日','最終管理者名'])" >
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.status" :disabled="scope.row.status === 'deleted'" placeholder="カテゴリー">
                            <el-option
                                v-for="articleReviewStatus in articleReviewStatuses"
                                :key="articleReviewStatus.value"
                                :label="articleReviewStatus.label"
                                :value="articleReviewStatus.value"
                                :disabled="articleReviewStatus.value === 'deleted'"
                            >
                            </el-option>
                        </el-select>
                        <br>
                        {{ scope.row.article_review_history.created_at }}
                        <br>
                        {{ scope.row.article_review_history.admin_name }}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="80">
                    <template slot-scope="scope">
                        <div class="articleReviews__button">
                            <el-button
                                size="large"
                                type="warning"
                                @click="updateArticleReview(scope.row)"
                            >更新</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :handleCurrentChange="search" :pagination="pagination" />
        </div>
    </div>
</template>

<script>
import { PAGE_TITLE , LOADING } from '../../store/mutation-types'
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'

const searchFormDefault = {
    article_id: '',
    id: '',
    auth_user_id: '',
    user_nickname: '',
    status: '',
    admin_name: '',
    review_comment: '',
    create_start_at: '',
    create_end_at: '',
}

export default {
    components: {
        Pagination
    },
    data() {
        return {
            articleReviews: [],
            articleReviewStatuses: [],
            pagination: {},
            searchForm: searchFormDefault,
        }
    },
    created() {
        this.$store.commit(PAGE_TITLE, 'レビュー管理')
        this.init()
    },
    methods: {
        init() {
            // 初回検索
            this.search()
        },
        // 検索
        async search(page = 1) {
            this.$store.commit(LOADING, true)

            const { data, error } = await axios.get('/api/article_reviews', {
                params: {
                    page,
                    article_id: this.searchForm.article_id,
                    id: this.searchForm.id,
                    auth_user_id: this.searchForm.auth_user_id,
                    user_nickname: this.searchForm.user_nickname,
                    status: this.searchForm.status,
                    admin_name: this.searchForm.admin_name,
                    review_comment: this.searchForm.review_comment,
                    create_start_at: this.searchForm.create_start_at,
                    create_end_at: this.searchForm.create_end_at,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }
            this.articleReviews = data.article_reviews
            this.articleReviewStatuses = data.article_review_statuses
            this.articleReviewStatuses.unshift({label: null, value: null})
            this.pagination = Object.assign({}, data.pagination)
            this.$store.commit(LOADING, false)
        },
        async updateArticleReview(articleReview) {
            this.$store.commit(types.LOADING, true)
            const fd = this.getFormData(articleReview)
            // PUTだとformDataが空になるため、一旦postにし、optionでPUT指定します（他に解決方法ある？）
            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PUT'
                }
            }
            const { error } = await axios.post('/api/article_reviews/'+articleReview.id, fd, config)
            this.$store.commit(types.LOADING, false)

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, '更新しました。')

            await this.search()
        },
        getFormData(articleReview) {
            const fd = new FormData()
            fd.append('status', articleReview.status)

            return fd
        }
    }
}
</script>

<style lang="scss" scoped>
    .articleReviews {
        margin-top:20px;
        &__table {
            width: 100%;
            font-size: 12px;
        }
        &__button {
            line-height: 3.0em;
        }
        &__thumbnail {
            width: 120px;
            object-fit: cover;
        }
        &__image {
            max-width: 500px;
            object-fit: cover;
        }
        &__comment {
            white-space:pre-wrap;
        }
    }
    .search-form {
        &__input {
            width: 130px;
        }
        &__input:last-of-type {
            width: 250px;
        }
        &__input:not(:last-of-type) {
            margin-right: 20px;
        }
        &__select {
            margin-right: 20px;
            width: 130px;
        }
        &__date:last-of-type {
            margin-right: 20px;
        }
        &__range {
            line-height: 40px;
            margin: 0 10px;
        }
    }

</style>
