<template>
    <div class="adminMenu">
        <el-aside :width="isCollapse ? '100px' : '250px'">
            <el-menu
                :router="true"
                active-text-color="#E37E7B"
                :default-active="this.$route.path"
                :collapse="isCollapse"
                >
                <div class="adminMenu__collapse">
                    <div v-show="isCollapse" class="adminMenu__collapse--on" @click="changeCollapse">
                        <i class="fas fa-angle-right"></i>
                    </div>
                    <div v-show="!isCollapse" class="adminMenu__collapse--off" @click="changeCollapse">
                        <i class="fas fa-angle-left"></i>
                    </div>
                </div>
                <el-menu-item index="/">
                    <i class="fas fa-tachometer-alt"></i>
                    <span>ダッシュボード</span>
                </el-menu-item>
                <el-submenu index="article">
                    <template slot="title">
                        <i class="fas fa-newspaper"></i>
                        <span>記事管理</span>
                    </template>
                    <el-menu-item
                        v-for="item in subMenuList.article"
                        :key="item.path"
                        :index="item.path"
                    >{{ item.title }}</el-menu-item>
                </el-submenu>
                <el-submenu index="edit">
                    <template slot="title">
                        <i class="fas fa-edit"></i>
                        <span>編集</span>
                    </template>
                    <el-menu-item
                        v-for="item in subMenuList.edit"
                        :key="item.path"
                        :index="item.path"
                    >{{ item.title }}</el-menu-item>
                </el-submenu>
                <el-submenu index="rewriteEdit">
                    <template slot="title">
                        <i class="fas fa-sync-alt"></i>
                        <span>リライト</span>
                    </template>
                    <el-menu-item
                        v-for="item in subMenuList.rewriteEdit"
                        :key="item.path"
                        :index="item.path"
                    >{{ item.title }}</el-menu-item>
                </el-submenu>
                <el-submenu index="dataManagement">
                    <template slot="title">
                        <i class="fas fa-database"></i>
                        <span>記事作成データ管理</span>
                    </template>
                    <el-menu-item
                        v-for="item in subMenuList.dataManagement"
                        :key="item.path"
                        :index="item.path"
                    >{{ item.title }}</el-menu-item>
                </el-submenu>
                <el-submenu index="organization">
                    <template slot="title">
                        <i class="fas fa-sitemap"></i>
                        <span>編成</span>
                    </template>
                    <el-menu-item
                        v-for="item in subMenuList.organization"
                        :key="item.path"
                        :index="item.path"
                    >{{ item.title }}</el-menu-item>
                </el-submenu>
                <el-submenu index="apps">
                    <template slot="title">
                        <i class="fas fa-mobile"></i>
                        <span>アプリ</span>
                    </template>
                    <div v-for="item in subMenuList.apps">
                        <el-menu-item
                            v-if="item.path !== null"
                            :key="item.path"
                            :index="item.path"
                        >{{ item.title }}</el-menu-item>
                        <el-submenu
                            v-else
                            :index="item.index"
                        >
                            <template slot="title">
                                <span>{{ item.title }}</span>
                            </template>
                            <el-menu-item
                                v-for="child in item.children"
                                :key="child.path"
                                :index="child.path"
                            >{{ child.title }}</el-menu-item>
                        </el-submenu>
                    </div>
                </el-submenu>
                <el-menu-item index="/reprints">
                    <i class="fas fa-copy"></i>
                    <span>転載</span>
                </el-menu-item>
                <el-submenu index="ad">
                    <template slot="title">
                        <i class="fas fa-ad"></i>
                        <span>広告</span>
                    </template>
                    <el-menu-item
                        v-for="item in subMenuList.ad"
                        :key="item.path"
                        :index="item.path"
                    >{{ item.title }}</el-menu-item>
                </el-submenu>
                <el-submenu index="account">
                    <template slot="title">
                        <i class="fas fa-lock"></i>
                        <span>アカウント</span>
                    </template>
                    <el-menu-item
                        v-for="item in subMenuList.account"
                        :key="item.path"
                        :index="item.path"
                    >{{ item.title }}</el-menu-item>
                </el-submenu>
                <el-submenu index="externalRelation">
                    <template slot="title">
                        <i class="fas fa-share-alt"></i>
                        <span>外部連携管理</span>
                    </template>
                    <el-menu-item
                        v-for="item in subMenuList.externalRelation"
                        :key="item.path"
                        :index="item.path"
                    >{{ item.title }}</el-menu-item>
                </el-submenu>
                <el-submenu index="writerFee">
                    <template slot="title">
                        <i class="fas fa-yen-sign"></i>
                        <span>ライター報酬管理</span>
                    </template>
                    <el-menu-item
                        v-for="item in subMenuList.writerFee"
                        :key="item.path"
                        :index="item.path"
                    >{{ item.title }}</el-menu-item>
                </el-submenu>
                <el-menu-item index="/reviews">
                  <i class="fas fa-star-half-alt"></i>
                  <span>レビュー管理</span>
                </el-menu-item>
                <el-menu-item index="/forms">
                    <i class="fab fa-wpforms"></i>
                    <span>応募フォーム</span>
                </el-menu-item>
                <el-menu-item index="/questions">
                    <i class="fas fa-question"></i>
                    <span>コミュニティー</span>
                </el-menu-item>
                <el-menu-item index="999" @click="logout">
                    <i class="fas fa-door-open"></i>
                    <span>ログアウト</span>
                </el-menu-item>
            </el-menu>
        </el-aside>
        <el-dialog
            title="ログアウト"
            :visible.sync="confirmLogout"
            >
            <span>ログアウトしますか?</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmLogout = false">キャンセル</el-button>
                <el-button type="primary" @click="logout">ログアウト</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import * as types from '../store/mutation-types'
export default {
    name: 'TrAdminMenu',
    data() {
        return {
            confirmLogout: false,
            isCollapse: false,
            subMenuList: {
                // 記事管理
                article: [
                    {
                        path: '/articles',
                        title: '記事一覧',
                    },
                    {
                        path: '/articles/video',
                        title: '動画記事',
                    },
                    {
                        path: '/rss_edit',
                        title: 'RSS編集記事',
                    },
                    {
                        path: '/articles/down',
                        title: '非公開記事',
                    },
                ],
                // 編集管理
                edit: [
                    {
                        path: '/editings/plots',
                        title: 'プロット',
                    },
                    {
                        path: '/editings/directions',
                        title: 'ディレクション',
                    },
                    {
                        path: '/editings/checks',
                        title: '編集チェック',
                    },
                ],
                // リライト
                rewriteEdit: [
                    {
                        path: '/editings/rewrite_plots',
                        title: 'プロット',
                    },
                    {
                        path: '/editings/rewrite',
                        title: 'ディレクション',
                    },
                    {
                        path: '/editings/rewrite_checks',
                        title: '編集チェック',
                    },
                ],
                // 記事作成データ管理
                dataManagement: [
                    {
                        path: '/editings/ng',
                        title: 'NGワード',
                    },
                    {
                        path: '/managements/shutterstock',
                        title: 'ShutterStock',
                    },
                    {
                        path: '/managements/tags',
                        title: 'タグマスタ管理',
                    },
                    {
                        path: '/managements/mil_movies',
                        title: 'MIL動画',
                    },
                    {
                        path: '/managements/material',
                        title: '材料',
                    },
                    {
                        path: '/managements/material_unit',
                        title: '材料単位',
                    },
                    {
                        path: '/managements/affiliate_sale_info',
                        title: 'アフィセール設定',
                    },
                    {
                        path: '/managements/recipe_db',
                        title: 'レシピDBマスタ管理',
                    },
                ],
                // 編成
                organization: [
                    {
                        path: '/organizations/manage',
                        title: '編成管理',
                    },
                    {
                        path: '/organizations/topic',
                        title: '特集',
                    },
                    {
                        path: '/organizations/serialization',
                        title: '連載',
                    },
                    {
                        path: '/tag_operations',
                        title: 'タグページ運用',
                    },
                    {
                        path: '/categories',
                        title: 'カテゴリー',
                    },
                ],
                // アプリ
                apps: [
                    {
                        path: '/organizations/app_tab',
                        title: 'タイムラインタブ',
                    },
                    {
                        path: '/organizations/campaign',
                        title: 'キャンペーン枠',
                    },
                    {
                        path: '/organizations/app_topic',
                        title: '見つける特集',
                    },
                    {

                        path: '/organizations/app_search_word',
                        title: '見つける検索ワード',
                    },
                    {
                        path: '/organizations/smartphone/notifications',
                        title: 'PUSH',
                    },
                    {
                        path: '/organizations/app_notification',
                        title: 'お知らせ',
                    },
                    {
                        path: '/organizations/app_affinity_article',
                        title: '関連記事データ追加',
                    },
                    {
                        path: '/organizations/app_recipe_menu/hints',
                        title: '献立ヒント',
                    },
                    {
                        path: '/organizations/app_recipe_menu/lists',
                        title: 'レシピリスト',
                    },
                    {
                        path: null,
                        title: 'レシピタブ',
                        index: 'recipeTab',
                        children: [
                            {
                                path: '/organizations/app_recommend_material',
                                title: 'おすすめの食材'
                            },
                            {
                                path: '/organizations/app_basic_recipe',
                                title: '料理の基本'
                            },
                            {
                                path: '/organizations/app_search_genre',
                                title: 'ジャンルから見つける'
                            },
                            {
                                path: '/organizations/app_recipe_recommend_serialization',
                                title: 'おすすめ連載'
                            },
                            {
                                path: '/organizations/app_recipe_small_topic',
                                title: '小特集'
                            }
                        ]
                    },
                ],
                ad: [
                    {
                        path: '/articles/tieup',
                        title: '記事広告',
                    },
                    {
                        path: '/organizations/app_ad_articles',
                        title: 'ネイティブアド',
                    },
                    {
                        path: '/ad_tag',
                        title: '広告タグ設定',
                    },
                ],
                account: [
                    {
                        path: '/managements/user',
                        title: 'ユーザー管理',
                    },
                    {
                        path: '/managements/writer',
                        title: 'ライター管理',
                    },
                    {
                        path: '/managements/admin',
                        title: '管理者',
                    },
                ],
                externalRelation: [
                    {
                        path: '/managements/whitelist',
                        title: 'URLホワイトリスト',
                    },
                    {
                        path: '/managements/blacklist',
                        title: 'URLブラックリスト',
                    },
                    {
                        path: '/managements/restrict_sns',
                        title: 'SNSアカウント制限',
                    },
                    {
                        path: '/managements/export',
                        title: '引用済みURL一覧',
                    },
                    {
                        path: '/external_relations/ccd_exclude_domain',
                        title: 'CCD除外ドメイン',
                    },
                ],
                writerFee: [
                    {
                        path: '/writers/fees/monthly',
                        title: 'ライター報酬一覧',
                    },
                    {
                        path: '/incentives/article',
                        title: '記事別固定インセンティブ一覧',
                    },
                ]
            }
        }
    },
    methods: {
        changeCollapse () {
            this.isCollapse = this.isCollapse ? false : true
        },
        logout () {
            this.$store.commit(types.LOGOUT)
        }
    }
}
</script>

<style lang="scss" scoped>
    .adminMenu {
        &__collapse {
            margin-bottom: 10px;
        }
        &__collapse--on {
            margin-left: 20px;
        }
        &__collapse--off {
            margin-left: 200px;
        }
    }
    .is-active {
        background-color: rgb(252, 238, 238);
    }
</style>
