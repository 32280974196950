<template>
    <div>
        <el-row>
            <item-left-contents
                :check-state="checkState"
                :number="number"
                :page-number="item.page_number"
                @update:checkState="checkState = $event"
            />

            <el-col :span="21" class="contents">
                <link-type
                    v-if="isEditing"
                    ref="dialogModule"
                    :save="update"
                    :id="content.id"
                    :content="content"
                    :article-format="articleFormat"
                    @close="closeMenu"
                />
                <div v-else>
                    <el-row v-if="content.link_type === 'text'">
                        <p v-if="isInternalLink" class="textLink">
                            <i class="fas fa-link"></i><a :href="content.link_url" target="_blank">{{content.link_title}}</a>
                        </p>
                        <p v-else class="textLink">
                            <a :href="content.link_url" target="_blank">{{content.link_title}}</a><i class="fas fa-external-link-alt"></i>
                        </p>
                        <div class="comment">
                            {{content.link_comment}}
                        </div>
                    </el-row>
                    <el-row v-if="content.link_type === 'button'" type="flex" justify="center">
                        <a :href="content.link_url" class="buttonLink" target="_blank">
                            {{content.link_title}}<i v-if="isInternalLink" class="fas fa-link"></i><i v-else class="fas fa-external-link-alt"></i>
                        </a>
                    </el-row>
                    <el-row v-if="content.link_type === 'thumbnail'">
                        <a :href="content.link_url" class="thumnailLink" target="_blank">
                            <el-row class="thumnailLinkWrapper">
                                <el-col :span="5" style="width: 100px; margin-right: 15px;"><img :src="content.link_thumbnail_cdn_url" alt=""></el-col>
                                <el-col :span="19">
                                    <h4 class="title">{{content.link_title}}</h4>
                                    <div v-if="content.link_description" class="description">
                                        {{content.link_description}}
                                    </div>
                                </el-col>
                            </el-row>
                        </a>
                    </el-row>
                </div>
            </el-col>

            <el-col :span="2" class="rightBox" type="flex" justify="end" v-if="!updateOrdersMode">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', resourceName, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>
    </div>
</template>
<script>

import FeedbackForm from './Components/FeedbackForm'
import LinkType from '../Form/LinkType'
import ItemLeftContents from './Components/ItemLeftContents'

export default {
    data () {
        return {
            isEditing:false,
        }
    },
    computed: {
        content() {
            return this.item.content
        },
        isInternalLink() {
            return this.content.link_url.indexOf('macaro-ni.jp') != -1
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        resourceName() {
            return 'links'
        },
    },
    components: {
        ItemLeftContents,
        LinkType,
        FeedbackForm,
    },
    props: {
        articleFormat: {
            required: true,
            type: String,
        },
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check,item) {
            this.parentCheckItem(check,item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>
<style scoped>
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}
h5 {
    margin-bottom:10px;
}
.comment {
    color: gray;
    font-size: 50%;
}

.textLink a {
    color: #000 !important;
    font-size: 16px;
    line-height: 1.3em;
    text-decoration: underline;
}
.textLink .fa-link,
.textLink .fa-external-link-alt {
    color: #B2CFD2;
    font-size: 14px;
    display: inline-block;
}
.textLink .fa-link {
    padding-right: 5px;
}
.textLink .fa-external-link-alt {
    padding-left: 5px;
}

.buttonLink {
    width: 100%;
    padding: 16px 10px;
    text-align: center;
    letter-spacing: 0.07em;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border-radius: 3px;
    display: block;
    background-color: #444;
    border: 1px solid #444;
}
.buttonLink .fa-link,
.buttonLink .fa-external-link-alt {
    font-size: 10px;
    padding-left: 5px;
}

.thumnailLinkWrapper {
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
}
.thumnailLink img {
    width: 100px;
}
.thumnailLink .title {
    color: #4d4d4d;
    margin-top: 0;
}
.thumnailLink:hover .title {
    text-decoration: underline;
}
.thumnailLink .description {
    color: #7c7c7c;
    font-size: 14px;
}
</style>
