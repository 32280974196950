<template>
    <el-dialog
        title="ライター割当"
        height="50%"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
    >
        <h3>プロットには表示されなくなります</h3>
        <el-form @submit.native.prevent :model="form"  label-width="120px" >
            <el-form-item label="ライター名" >
                <el-col :span="16">
                    <el-autocomplete
                        placeholder="ライター名"
                        v-model="form.writer_name"
                        @select="handleSelectWriter"
                        :fetch-suggestions="querySearchWriter"
                        :trigger-on-focus="true"
                    >
                    </el-autocomplete>
                </el-col>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="submitForm">ライター割当</el-button>
        </span>
    </el-dialog>
</template>

<script>
const formDefaultData = {
    writer_name: null,
}
export default {
    props: {
        isActive: Boolean,
        input: Object,
    },
    data () {
        return {
            form: {...formDefaultData},
        }
    },
    watch: {
        isActive() {
            this.form = Object.assign({}, formDefaultData)
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        submitForm () {
            this.$emit('save', this.form, this.input.id)
        },
        handleSelectWriter (item) {
            this.form.writer_name = item.value
        },
        async querySearchWriter(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/writers/suggest', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'ライター検索に失敗しました')
                    return
                }
                cb(data.writers)
            }
        },
    }
}
</script>
<style scoped>
	.wordList {
		margin:5px 5px 0 0;
	}
	.inputNewTag {
		width: 140px;
		margin:5px 5px 0 0;
		vertical-align: bottom;
	}
</style>
