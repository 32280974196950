<template>
    <el-dialog
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
        width="500px"
    >
        <p>macaroniサービス全てが使用できなくなります。</p>
        <p>本当に退会させますか？</p>
        <el-form ref="form">
            <el-form-item>
                <el-button @click="close">キャンセル</el-button>
                <el-button type="danger" @click="withdraw">退会</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    props: {
        isActive: Boolean,
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        // 退会
        withdraw() {
            this.$emit('withdraw')
        },
    },
}
</script>
<style lang="scss" scoped>
    .el-form {
        text-align: center;
        margin-top: 25px;
    }
</style>