<!-- csvダウンロード用コンポネント
 csvダウンロードの実行先とinputタグのnameを渡してください。
 -->
<template>
    <el-button :type="elButtonType" :size="elButtonSize" @click="downloadCSV" icon="el-icon-download">
        {{ buttonName }}
    </el-button>
</template>

<script>
import * as types from '../store/mutation-types'
import FileDownload from 'js-file-download'
export default {
    name:'CsvDownload',
    props:{
        name: String,
        url: String,
        elButtonType: {
            type: String,
            default: 'warning',
        },
        elButtonSize: {
            type: String,
            default: 'medium',
        },
        buttonName: {
            type: String,
            default: 'CSVダウンロード',
        },
    },
    data() {
        return {
            header: {
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
        }
    },
    methods: {
        async downloadCSV() {
            this.$store.commit(types.PAGE_LOADING, true)
            const { data } = await axios.get(this.url)
            FileDownload(data, this.name)
            this.$store.commit(types.PAGE_LOADING, false)
        },
    }
}
</script>
<style scoped>
    .uploadButton{
        display:inline-block;
    }
</style>
