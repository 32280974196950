<template>
    <!--
    並び替え可能なURLフォームリストのコンポーネント
    -->
    <div class="urlList">
        <el-form-item v-for="(item, idx) in value" :key="idx">
            <div class="inlineItem">
                <el-input
                    :value="item.url"
                    class="urlInput"
                    type="text"
                    size="mini"
                    placeholder="追加するURLを入力してください"
                    @input="handleUrlInput($event, idx)"
                />
                <el-select
                    v-model="item.type"
                    class="siteSelect"
                    size="mini"
                    placeholder="区分"
                    @input="
                        newValue => $emit('input',
                            value.map((v, i) => i === idx ? {url: v.url, type: newValue } : v)
                        )
                    "
                >
                    <el-option
                        v-for="siteType in siteTypes"
                        :key="siteType.type"
                        :label="siteType.label"
                        :value="siteType.type"
                    />
                </el-select>
                <div class="moveButtons">
                    <!-- 上に移動 -->
                    <el-button
                        type="info"
                        icon="el-icon-caret-top"
                        size="mini"
                        :disabled="idx === 0"
                        @click="
                            $emit('input', value.map((v, i) => {
                                switch (i) {
                                    case idx - 1: return value[idx]
                                    case idx: return value[idx - 1]
                                    default: return v
                                }
                            }))
                        "
                    />
                    <!-- 下に移動 -->
                    <el-button
                        type="info"
                        icon="el-icon-caret-bottom"
                        size="mini"
                        :disabled="idx === value.length - 1"
                        @click="
                            $emit('input', value.map((v, i) => {
                                switch (i) {
                                    case idx: return value[idx + 1]
                                    case idx + 1: return value[idx]
                                    default: return v
                                }
                            }))
                        "
                    />
                    <!-- 削除 -->
                    <el-button
                        plain
                        type="warning"
                        icon="el-icon-delete"
                        size="mini"
                        @click="$emit('input', value.filter((_, i) => i !== idx))"
                    />
                </div>
            </div>
        </el-form-item>
        <el-form-item v-if="value.length < limitCount">
            <el-button
                type="info"
                size="mini"
                icon="el-icon-plus"
                :disabled="value.length >= limitCount"
                @click="$emit('input', value.concat(defaultValueItem))"
            />
        </el-form-item>
    </div>
</template>

<script>
const siteTypes = () => [
    {
        type: 'FACEBOOK',
        label: 'Facebook',
        hostnames: ['facebook.com'],
    },
    {
        type: 'INSTAGRAM',
        label: 'Instagram',
        hostnames: ['instagram.com'],
    },
    {
        type: 'X',
        label: 'X',
        hostnames: ['x.com', 'twitter.com'],
    },
    {
        type: 'YOUTUBE',
        label: 'YouTube',
        hostnames: ['youtube.com'],
    },
    {
        type: 'TIKTOK',
        label: 'TikTok',
        hostnames: ['tiktok.com'],
    },
    {
        type: 'HP',
        label: 'HP',
        hostnames: [],
    },
    {
        type: 'BLOG',
        label: 'Blog',
        hostnames: [],
    },
    {
        type: 'BOOK',
        label: '書籍・論文',
        hostnames: [],
    },
    {
        type: 'OTHER',
        label: 'その他',
        hostnames: [],
    },
]
const defaultValueItem = () => ({url: '', type: '' })

export default {
    props: {
        value: {
            type: Array,
            required: true,
            default: () => [defaultValueItem()],
        },
        onlySiteTypes: {
            type: Array,
            required: false,
            default: () => [], // ['FACEBOOK']
        },
        limitCount: {
            type: Number,
            required: false,
            default: 10,
        },
        isFromShopItem: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
        }
    },
    mounted() {
        // 旧店舗情報アイテムから移行のため開かれたフォームのとき、siteType判定更新のため各URLの入力ハンドラを発火させる
        // siteTypeはURLと異なっていても登録できる（あとから更新できる）仕様のため、デフォルトでこの挙動は行わない。あくまで旧店舗情報アイテム経由でオープンした場合のみ。
        if (this.isFromShopItem) {
            this.value.map((v, idx) => {
                if (v.url !== '') {
                    this.handleUrlInput(v.url, idx)
                }
            })
        }
    },
    computed: {
        siteTypes() {
            return this.onlySiteTypes.length === 0
                ? siteTypes()
                // propsで指定されたsiteTypeをその並びに抽出している
                : this.onlySiteTypes.map(type => siteTypes().find(v => v.type === type))
        },
        defaultValueItem() {
            return defaultValueItem()
        },
    },
    methods: {
        handleUrlInput(newUrl, idx) {
            // 入力URLのホスト名に一致するsiteTypeを検索
            const type = (() => {
                if (URL.canParse(newUrl)) {
                    const hostname = new URL(newUrl).hostname
                    return this.siteTypes
                        .find(siteType => siteType.hostnames.some(v => hostname === v || hostname.endsWith(`.${v}`)))?.type
                }
            })()

            this.$emit('input',
                this.value.map((v, i) => i === idx
                    ? {url: newUrl, type: type ?? v.type }
                    : v
                )
            )
        }
    }
}
</script>

<style scoped>
.inlineItem {
    display: flex;
    gap: 7px;
}
.moveButtons {
    display: flex;
    gap: 3px;
}

.urlInput {
    width: 332px;
}
.siteSelect {
    width: 110px;
}

/* ElementUIスタイルリセット */
.el-button+.el-button {
    margin-left: 0;
}
</style>
