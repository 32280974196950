<template>
    <div>
        <el-row type="flex" justify="end">
            <el-header>
               <el-col>
                   <csv-upload
                       name="upload_plot"
                       url="/api/plots/csv"
                       @afterCsvImportFetch="afterCsvImportFetch"
                   ></csv-upload>
                   <csv-download
                       name="plots.csv"
                       url="/api/plots/csv"
                   ></csv-download>
                </el-col>
            </el-header >
        </el-row>
        <el-row type="flex" justify="end" style="margin-top:10px;">
            <el-select placeholder="カテゴリでフィルタ" v-model="category" class="search">
                 <el-option
                     v-for="(item) in categoryFilterList"
                     :key="item.id"
                     :label="item.category_name"
                     :value="item.id">
                 </el-option>
            </el-select>
            <el-input
                placeholder="SEOキーワード"
                v-model="keyword"
                class="search keyword"
                @keyup.enter.native="search()"
            ></el-input>
            <el-button
                @click="search()"
                icon="el-icon-search"
                class="search-input"
                style="height: 40px; margin-top:10px;"
            ></el-button>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <el-row>
            <el-col >
                <plot-row
                    :tableData=tableData
                    :categoryList="categoryList"
                    :articleTypeList="articleTypeList"
                    :baseCharCountList="baseCharCountList"
                    :update="update"
                    :assignWriter="assignWriter"
                    :deleteRow="deleteRow"
                    :create="create"
                    :tagList="tagList"
                >
                </plot-row>
            </el-col>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />

        <assign-writer-modal
            :save="assignWriterSave"
            :dialog="assignWriterDialog"
            :closeDialog="closeDialog"
            :input="assignWriterForm"
            />

        <el-dialog
            title="プロット削除"
            :visible.sync="deleteConfirmDialog"
            width="30%"
            @close="closeDeleteDialog"
            >
            <span >プロットを削除してよろしいですか？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDeleteDialog">キャンセル</el-button>
                <el-button type="danger" @click="performDeleteDialog">削除する</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>

import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import SearchBox from '../../components/SearchBox'
import AssignWriterModal from './Plot/AssignWriterModal'
import PlotRow from '../../components/Editing/PlotRow'
import CsvUpload from '../../components/CsvUpload'
import CsvDownload from "../../components/CsvDownload.vue";

export default {
    components: {
        CsvDownload,
        Pagination,
        AssignWriterModal,
        SearchBox,
        PlotRow,
        CsvUpload,
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data() {
        return {
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            form: {},
            assignWriterForm: {},
            createDialog: false,
            dialog: false,
            assignWriterDialog:false,
            categories: {},
            articleTypes: {},
            pagination: {},
            baseCharCountList: [],
            tableData: [],
            deleteConfirmDialog: false,
            deleteConfirmId: null,
            confirmPlot: null,
            confirmPlotDialog: false,
            categoryList: [],
            articleTypeList: [],
            categoryFilterList: {},
            tagList: [],
            keyword: '',
            category: null,
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '編集　>　プロット')
        this.queryInit()
        this.fetch()
    },
    methods: {
        queryInit() {
            this.category = this.$route.query.category ? Number(this.$route.query.category) : null
            this.keyword = this.$route.query.keyword ? this.$route.query.keyword : ''
        },
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data } = await axios.get('/api/plots', {
                params: { ...this.$route.query }
            })

            axios.get('/api/plots/tags').then((response) => {
                this.tagList = response.data.tag_list
            })

            this.pagination = Object.assign({}, data.plots)

            /* フィルター用 */
            this.categoryFilterList = Object.assign([], data.categories)
            this.categoryFilterList.unshift({category_name: null, id: null})
            /* フィルター用 */

            this.categories = this.idKeyList(data.categories)
            this.categoryList = Object.values(this.categories).map((category) => {
                return {id: category.id, label: category.category_name}
            })
            this.categoryList.unshift({id: null, label: 'カテゴリ未設定'})

            this.articleTypes = this.idKeyList(data.article_types)
            this.articleTypeList = Object.values(this.articleTypes).map((articleType) => {
                return {id: articleType.id, label: articleType.type_name}
            })
            this.articleTypeList.unshift({id: null, label: '記事種別未設定'})

            const tmpBaseCharCountList = Object.assign({}, data.base_char_count_list)
            this.baseCharCountList = Object.values(tmpBaseCharCountList).map((charCount, index) => {
                return {id: index + 1, label: charCount.min + '〜' + charCount.max}
            })
            this.baseCharCountList.unshift({id: null, label: '文字数未設定' })

            // debug
            console.error({plots: data.plots})
            const datalist = Object.entries(data.plots.data).map(([key, data]) => {
                return {
                    admin_id: data.admin_id,
                    admin_name: data.admin.editor_name,
                    article_type_id: data.article_type_id,
                    base_char_count: data.base_char_count,
                    category_id: data.category_id,
                    id: data.id,
                    plot: data.plot,
                    keywords: Object.entries(data.keywords).map(([key, keyword]) => {
                        return keyword.keyword
                    }),
                    parent_tags: data.parent_tags,
                    child_tags: data.child_tags,
                    grandchild_tags: data.grandchild_tags,
                }
            })

            this.tableData = Object.assign([], datalist)
            if (this.pagination.current_page == 1) {
                this.tableData.unshift({
                    id: null,
                    keywords: [],
                    parent_tags: [],
                    child_tags: [],
                    grandchild_tags: [],
                    admin_id: null,
                    category_id: null,
                    article_type_id: null,
                    base_char_count: null,
                    plot: null,
                    admin_name: null,

                })
            }

            this.$store.commit(types.PAGE_LOADING, false)
        },
        search() {
            const query = Object.assign({}, this.$route.query, {
                category: this.category,
                keyword: this.keyword,
                page: 1
            })
            this.$router.push({ query })
        },
        idKeyList(list) {
            return _.keyBy(list, (data) => data.id)
        },
        createRow () {
            this.form = {}
            this.createDialog = true
        },
        assignWriter(form) {
            this.assignWriterForm = Object.assign({}, form)
            this.assignWriterDialog = true
        },
        deleteRow(data) {
            this.deleteConfirmId = data.id
            this.deleteConfirmDialog = true
        },
        closeDeleteDialog() {
            this.deleteConfirmId = null
            this.deleteConfirmDialog = false
        },
        async performDeleteDialog() {
            this.$store.commit(types.PAGE_LOADING, true)
            await axios.delete('/api/plots/' + this.deleteConfirmId )
            await this.fetch()
            this.$store.commit(types.PAGE_LOADING, false)
            this.deleteConfirmId = null
            this.deleteConfirmDialog = false
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'プロットを削除しました')
        },
        async update (param) {
            const form = Object.assign({}, param)

            this.$store.commit(types.LOADING, true)
            const {error} = await axios.patch('/api/plots/' + form.id, form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'プロットを変更しました')
            this.$store.commit(types.LOADING, false)
        },
        async create (form) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.post('/api/plots', form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'プロットを作成しました')
            this.$store.commit(types.LOADING, false)
        },
        async assignWriterSave (id, form) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.post('/api/plots/' + id + '/assign/writer', form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'ライターを割り当て、ディレクション作成 & プロットを削除しました')
            this.$store.commit(types.LOADING, false)

        },
        handleCurrentChange (page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
        closeDialog () {
            this.dialog = false
            this.createDialog = false
            this.assignWriterDialog = false
        },
        async afterCsvImportFetch() {
            await this.fetch()
        },
    }
}
</script>
<style scoped>
.uploadButton{
    display:inline-block;
}
.wordList {
    margin:0 5px;
}
.confirmPlot {
    white-space:pre-line;
    text-align:left;
    max-height:150px;
    margin-top:-10px;
}

.prePlot{
    white-space:pre-line;
}
.el-button {
    margin: 5px 0 5px 10px;
}

.unitBtn {
    width: 100%;
}

.list {
    vertical-align: top;
}
.el-tag + .el-tag {
    margin-left: 10px;
}

.el-select {
    margin: 5px 0 5px 10px;
}
.search{
    margin: 10px 5px 0;
}
.search.keyword {
    width: 400px;
}
</style>

