<template>
    <el-dialog
        title="インセンティブ詳細登録"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
    >
        <el-row type="flex" style="margin: 30px 0;">
            <el-input v-model="incentivePriceFormat.name" placeholder="インセンティブ詳細名" style="margin-right: 10px;"></el-input>
            <el-input v-model="incentivePriceFormat.price" placeholder="インセンティブ詳細金額" style="margin-right: 10px;"></el-input>
            <el-button type="primary" @click="addIncentivePrice">追加</el-button>
        </el-row>

        <el-table
            :data="editIncentivePrices"
            style="width: 100%"
        >
            <el-table-column label="インセンティブ詳細名">
                <template slot-scope="scope">
                    <el-input v-if="isEdit" placeholder="インセンティブ詳細名" v-model="scope.row.name"></el-input>
                    <div v-else>{{ scope.row.name }}</div>
                </template>
            </el-table-column>
            <el-table-column label="インセンティブ詳細金額">
                <template slot-scope="scope">
                    <el-input v-if="isEdit" placeholder="インセンティブ詳細金額" v-model="scope.row.price"></el-input>
                    <div v-else>{{ scope.row.price | number }}円</div>
                </template>
            </el-table-column>
            <el-table-column label="削除">
                <template slot-scope="scope">
                    <el-button type="danger" @click="deleteIncentivePrice(scope.row, scope.$index)">削除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <span slot="footer" class="dialog-footer">
            <el-button @click="close">閉じる</el-button>
        </span>
    </el-dialog>
</template>

<script>

import * as types from '../../../store/mutation-types'

export default {
    props: {
        isActive: Boolean,
        incentivePrices: Array,
        isEdit: Boolean,
    },
    data() {
        return {
            editIncentivePrices: [],
            incentivePriceFormat: {
                name: '',
                price: 0,
            },
            deleteIncentivePriceIds: [],
        }
    },
    methods: {
        close() {
            this.$emit('close', this.editIncentivePrices, this.deleteIncentivePriceIds)
        },
        addIncentivePrice() {
            this.editIncentivePrices.push(Object.assign({}, this.incentivePriceFormat))
            this.initIncentivePriceFormat()
        },
        deleteIncentivePrice(incentivePrice, idx) {
            if (this.editIncentivePrices.length === 1) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'インセンティブ詳細は最低1つ必要です。')
                return
            }
            if (incentivePrice.hasOwnProperty('id')) {
                if (! confirm('未公開の記事に紐づいている場合、金額が計上されません。\n本当に削除しますか？')) {
                    return
                }

                this.deleteIncentivePriceIds.push(incentivePrice.id)
            }
            this.editIncentivePrices.splice(idx, 1)
        },
        initIncentivePriceFormat() {
            this.incentivePriceFormat = {
                name: '',
                price: 0,
            }
        },
    },
    watch: {
        incentivePrices() {
            this.editIncentivePrices = this.incentivePrices
            this.initIncentivePriceFormat()
        }
    },
}
</script>
