import Vue from 'vue'
import Vuex from 'vuex'
import * as types from './mutation-types'
import { Message } from 'element-ui'
import createLogger from 'vuex/dist/logger'

const debug = process.env.NODE_ENV !== 'production'
const devenv = process.env.NODE_ENV === 'development'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loginUser: {},
        inRequest: false,
        error: {},
        infoMessage: '',
        errorMessage: '',
        route: {},
        requestConfig: {},
        requestResponse: {},
        isAppLoading: false,
        isPageLoading: false,
        isEditorLoading: false,
        isLogin: false,
        token: null,
        pageTitle: '',
        role:'tr',
        debug,
        devenv
    },
    mutations: {
        [types.SHOW_INFO_MESSAGE](state, message) {
            Message.closeAll()
            state.infoMessage = message
        },
        [types.EDITOR_LOADING](state, isLoading) {
            if (isLoading === true) {
                Message.closeAll()
            }
            state.isEditorLoading = isLoading
        },
        [types.SHOW_ERROR_MESSAGE](state, error) {
            Message.closeAll()
            if (typeof error === 'string') {
                state.errorMessage = error
            } else if(typeof error === 'object') {
                console.error(error.response)
                if( typeof error.response.data.errors  === 'object' ){
                    let errorsText = []
                    for (let [key, value] of Object.entries(error.response.data.errors)) {
                        errorsText.push(`${value}`)
                    }
                    state.errorMessage = errorsText.join('<br>')
                } else if(typeof error.response.data.message === 'string') {
                    state.errorMessage = error.response.data.message
                } else {
                    state.errorMessage = error.response.data
                }
            }
        },
        [types.API_REQUEST_START] (state, payload) {
            state.inRequest = true
            state.requestConfig = Object.assign({}, payload.config)
        },
        [types.API_REQUEST_END] (state, payload) {
            state.inRequest = false
            state.requestResponse = Object.assign({}, payload.response)
            state.error = {}
        },
        [types.API_REQUEST_FAILED] (state, payload) {
            state.inRequest = false
            state.error = payload.error
        },
        [types.LOADING](state, isLoading) {
            state.isAppLoading = isLoading
        },
        [types.PAGE_LOADING](state, isLoading) {
            state.isPageLoading = isLoading
        },
        [types.LOGIN](state, {token, info}) {
            state.token = token
            state.loginUser = info
            localStorage.setItem('Authorization.token', token)
            state.isLogin = true
        },
        [types.LOGOUT](state) {
            state.isLogin = false
        },
        [types.ALREADY_LOGIN](state) {
            state.isLogin = true
        },
        [types.CHANGE_ROUTE] (state, payload) {
            // Message.closeAll()
            state.route = Object.assign({}, payload)
        },

        [types.PAGE_TITLE](state, title) {
            state.pageTitle = title
        },
        [types.FETCH_LOGIN_USER](state, payload) {
            state.loginUser = payload
        },

    },
    actions: {
        async login({ commit }, { email, password} ) {
            const res = await axios.post('/api/login', { email, password })
            if (!res.error) {
                commit(types.LOGIN, res.data)
            }
            return res
        }
    },
    modules: {
    },
    strict: debug,
    plugins: devenv ? [createLogger()] : []
})
