<!-- コンテントエリアのプレイスアイテム -->
<template>
    <div class="placeItem">
        <el-row>
            <el-col class="leftBox" :span="1">
                <el-checkbox class="itemCheckBox" v-model="checkState" >{{number}}</el-checkbox>
            </el-col>

            <el-col class="contents" :span="21">
                <!-- 編集フォーム -->
                <place-type
                    v-if="isEditing"
                    :save="update"
                    :article-id="item.content.article_id"
                    :content="item.content"
                    @close="handleClose"
                />
                <!-- アイテム表示 -->
                <div v-else class="contentView">
                    <div class="section">
                        <div class="title">{{ item.content.place_name }}</div>
                    </div>
                    <div class="section">
                        <div class="address">
                            <img src="https://cdn.macaro-ni.jp/assets/img/place_pin.svg">
                            <div>
                                <div>〒{{ item.content.zip_code }}</div>
                                <div>{{ item.content.address }}</div>
                                <a
                                    v-if="item.content.google_map_url !== null"
                                    :href="item.content.google_map_url"
                                    target="_blank"
                                >Google Maps</a>
                            </div>
                        </div>
                    </div>
                    <div v-if="item.content.opening_hours.length" class="section">
                        <div class="openingHours">
                            <img src="https://cdn.macaro-ni.jp/assets/img/place_clock.svg">
                            <ul class="openingHoursList">
                                <li
                                    v-for="(label, dayOfWeek) in ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日']"
                                    :key="dayOfWeek"
                                    class="openingHoursItem"
                                >
                                    <div>{{ label }}</div>
                                    <div v-if="openingHoursKeyByDayOfWeek[dayOfWeek].is_open_twenty_four_hours">24時間営業</div>
                                    <div v-else-if="openingHoursKeyByDayOfWeek[dayOfWeek].opening_hours === null">定休日</div>
                                    <ul v-else>
                                        <li
                                            v-for="(hours, idx) in openingHoursKeyByDayOfWeek[dayOfWeek].opening_hours"
                                            :key="idx"
                                        >{{ `${hours['opening_hour']} 〜 ${hours['closing_hour']}` }}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="item.content.phone_number" class="section">
                        <div class="tel">
                            <img src="https://cdn.macaro-ni.jp/assets/img/place_phone.svg">
                            <div>{{ item.content.phone_number }}</div>
                        </div>
                    </div>
                    <div v-if="item.content.elements.length" class="section">
                        <ul class="elements">
                            <li v-for="(element, idx) in item.content.elements" :key="idx" class="elementsItem">
                                <div class="elementsItemLabel">{{ element.label }}</div>
                                <div>{{ element.value }}</div>
                            </li>
                        </ul>
                    </div>
                    <div v-if="hasFlags" class="section">
                        <ul class="flags">
                            <li v-if="item.content.is_no_smoking" class="flagsItem">
                                <img src="https://cdn.macaro-ni.jp/assets/img/place_not_smoking.svg" /><span class="flagsItemLabel">禁煙</span>
                            </li>
                            <li v-if="item.content.is_wifi" class="flagsItem">
                                <img src="https://cdn.macaro-ni.jp/assets/img/place_wifi.svg" /><span class="flagsItemLabel">Wi-Fi</span>
                            </li>
                            <li v-if="item.content.is_power_supply" class="flagsItem">
                                <img src="https://cdn.macaro-ni.jp/assets/img/place_power_supply.svg" /><span class="flagsItemLabel">電源</span>
                            </li>
                            <li v-if="item.content.is_serves_breakfast" class="flagsItem">
                                <img src="https://cdn.macaro-ni.jp/assets/img/place_serves_breakfast.svg" /><span class="flagsItemLabel">朝食</span>
                            </li>
                            <li v-if="item.content.is_serves_lunch" class="flagsItem">
                                <img src="https://cdn.macaro-ni.jp/assets/img/place_serves_lunch.svg" /><span class="flagsItemLabel">昼食</span>
                            </li>
                            <li v-if="item.content.is_serves_dinner" class="flagsItem">
                                <img src="https://cdn.macaro-ni.jp/assets/img/place_serves_dinner.svg" /><span class="flagsItemLabel">ディナー</span>
                            </li>
                        </ul>
                    </div>
                    <div v-if="item.content.place_url || item.content.urls.length" class="section">
                        <ul class="urls">
                            <li v-if="item.content.place_url" class="urlsItem">
                                <a :href="item.content.place_url" class="urlsItem_official" target="_blank">公式</a>
                            </li>
                            <li v-for="(url, idx) in item.content.urls" :key="idx" class="urlsItem">
                                <a :href="url.url" target="_blank"><img :src="url.icon_url" /></a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="hasAffiliate" class="section">
                        <div class="affiliate">
                            <a v-if="item.content.gnavi_affiliate_url" :href="item.content.gnavi_affiliate_url" class="affiliateItem" target="_blank">
                                <img src="https://cdn.macaro-ni.jp/assets/img/place_gnavi_icon.svg" alt="ぐるなび">
                            </a>
                            <a v-if="item.content.tabelog_affiliate_url" :href="item.content.tabelog_affiliate_url" class="affiliateItem" target="_blank">
                                <img src="https://cdn.macaro-ni.jp/assets/img/place_tabelog_icon.svg" alt="食べログ">
                            </a>
                            <a v-if="item.content.ikyu_affiliate_url" :href="item.content.ikyu_affiliate_url" class="affiliateItem" target="_blank">
                                <img src="https://cdn.macaro-ni.jp/assets/img/place_ikyu_icon.svg" alt="一休">
                            </a>
                        </div>
                    </div>
                </div>
            </el-col>

            <el-col class="rightBox" :span="2" justify="end" v-if="!updateOrdersMode">
                <el-tag v-if="item.content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', RESOURCE_NAME, id, form)" :input="item.content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="isEditing = true"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', RESOURCE_NAME, item.id)"
            >削除</el-button>
        </div>
    </div>
</template>

<script>
import FeedbackForm from './Components/FeedbackForm'
import PlaceType from '../Form/PlaceType'

const RESOURCE_NAME = 'places'

export default {
    components: {
        PlaceType,
        FeedbackForm,
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    data () {
        return {
            isEditing: false,
        }
    },
    computed: {
        RESOURCE_NAME() {
            return RESOURCE_NAME
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.parentCheckItem(check, this.item)
            }
        },
        hasFlags() {
            return this.item.content.is_no_smoking
                || this.item.content.is_wifi
                || this.item.content.is_power_supply
                || this.item.content.is_serves_breakfast
                || this.item.content.is_serves_lunch
                || this.item.content.is_serves_dinner
        },
        hasAffiliate() {
            return this.item.content.gnavi_affiliate_url
                || this.item.content.tabelog_affiliate_url
                || this.item.content.ikyu_affiliate_url
        },
        openingHoursKeyByDayOfWeek() {
            return this.item.content.opening_hours.reduce((carry, openingHour) => {
                carry[openingHour.day_of_week] = openingHour
                return carry
            }, {})
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    },
    methods: {
        async update(type, form, id) {
            const result = await this.parentUpdate(type, form, id)
            if (result) {
                this.isEditing = false
            }
        },
        handleClose() {
            this.isEditing = !window.confirm('キャンセルしますか？')
        },
    },
}
</script>

<style scoped>
/** アイテム共通スタイル */
.leftBox {
    width: 45px;
}
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}

/** PlaceItem用スタイル */
.contentView {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
}
.section {
    padding: 15px 0;
    border-bottom: solid 1px #ddd;
    &:has(.title) {
        border-bottom: solid 2px #444;
    }
}

.autoUpdate {
    display: flex;
    gap: 10px;
    margin: 0 0 0 20px;
}
.title {
    font-size: 18px;
    font-weight: 700;
}
.address {
    display: flex;
    gap: 30px;
    margin: 0 0 0 20px;
}
.openingHours {
    display: flex;
    gap: 30px;
    margin: 0 0 0 20px;
    .openingHoursList {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .openingHoursItem {
        display: flex;
        gap: 10px;
    }
}
.tel {
    display: flex;
    gap: 30px;
    margin: 0 0 0 20px;
    align-items: center;
}
.elements {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0 0 0 20px;
    .elementsItem {
        display: flex;
    }
    .elementsItemLabel {
        width: 120px;
    }
}
.flags {
    display: flex;
    margin: 0 0 0 10px;
    .flagsItem {
        width: 52px;
        display: flex;
        flex-direction: column;
        gap: 3px;
        align-items: center;
        justify-content: space-between;
    }
    .flagsItemLabel {
        font-size: 12px;
    }
}
.urls {
    display: flex;
    margin: 0 0 0 10px;
    .urlsItem {
        width: 52px;
        display: flex;
        justify-content: center;
    }
    .urlsItem_official {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        font-size: 12px;
        text-decoration: none;
        background-color: #444;
        color: #fff;
        border-radius: 5px;
    }
}
.affiliate {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 0 0 0 20px;
    .affiliateItem {
        width: 215px;
        display: flex;
        justify-content: center;
        padding: 8px 0;
        border: 1px solid #444;
        border-radius: 5px;
    }
}
</style>
