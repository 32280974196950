<template>
    <el-dialog title="ライター毎の費用に対する社内メモを編集" @close="closeDialog" :visible="dialog" height="50%">
        <el-form :model="form"  label-width="120px">
            <el-form-item label="社内メモ">
                <el-col>
                    <el-input type="textarea" v-model="form.fee_comment" placeholder="社内メモ"></el-input>
                </el-col>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">キャンセル</el-button>
            <el-button type="primary" @click="save">更新</el-button>
        </span>
    </el-dialog>
</template>
<script>

export default {
    props: {
        dialog: Boolean,
        writer: Object,
    },
    data () {
        return {
            form: {
                fee_comment: null
            }
        }
    },
    watch: {
        writer() {
            this.form = Object.assign({}, this.form, this.writer)
        },
    },
    methods: {
        save() {
            this.$emit('save', this.writer.id, this.form)
        },
        closeDialog() {
            this.$emit('closeDialog')
        },
    }
}
</script>
