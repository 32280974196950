<template>
    <div class="rootApp">
        <el-row type="flex" justify="end">
            <el-header >
               <el-col >
                   <el-button type="primary" @click="createRow">
                       新規作成
                   </el-button>
               </el-col>
            </el-header >
        </el-row>
        <el-row>
            <el-col >
                <el-table :data="tableData"  cell-class-name="tableCell" max-height="800">
                    <el-table-column label="タイトル">
                        <template slot-scope="scope">
                            {{scope.row.headings.en}}
                        </template>
                    </el-table-column>
                    <el-table-column label="メッセージ">
                        <template slot-scope="scope">
                            {{scope.row.contents.en}}
                        </template>
                    </el-table-column>
                    <el-table-column label="OS" width="60">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isAndroid">Android</span>
                            <span v-if="scope.row.isIos">iOS</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="セグメント">
                        <template slot-scope="scope">
                            {{scope.row.included_segments[0]}}
                        </template>
                    </el-table-column>
                    <el-table-column label="送信総数" width="80">
                        <template slot-scope="scope">
                            {{totalCount(scope.row) | number}}
                        </template>
                    </el-table-column>
                    <el-table-column label="成功数" width="80">
                        <template slot-scope="scope">
                            {{scope.row.successful | number}}
                        </template>
                    </el-table-column>
                    <el-table-column label="データ">
                        <template slot-scope="scope">
                            <span class="dataCell">
                            <el-button class="confirmData" type="text" @click="openConfirmData(scope.row)">
                                {{scope.row.data}}
                            </el-button>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="配信日時" width="110">
                        <template slot-scope="scope">
                            {{showDate(scope.row.send_after)}}
                            <br />
                            <span v-if="checkSendAfter(scope.row.send_after)" class="reserved">予約中</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="キャンセル">
                        <template slot-scope="scope">
                           <el-button v-if="checkSendAfter(scope.row.send_after)" type="danger" plain @click="cancelRow(scope.row)">
                               キャンセル
                           </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>

        <pagination :handleCurrentChange="fetch" :pagination="pagination" />

        <edit-modal
            :save="create"
            buttonLabel="作成"
            :dialog="createDialog"
            :closeDialog="closeDialog"
            :osList="osList"
            :input="form"
            :contentTypeList="contentTypeList"
        />
        <el-dialog
            title="データ確認"
            :visible.sync="confirmDataDialog"
            width="50%"
            >
            <pre class="preData">{{confirmData}}</pre>
        </el-dialog>
        <el-dialog
            title="通知キャンセル"
            :visible.sync="cancelConfirmDialog"
            width="30%"
            @close="closeCancelDialog"
            >
            <span >通知をキャンセルしてよろしいですか？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeCancelDialog">閉じる</el-button>
                <el-button type="danger" @click="performCancelDialog">キャンセル処理を行う</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import moment from 'moment'
import EditModal from './Notification/EditModal'
import Pagination from '../../components/Pagination'

export default {
    components: {
        EditModal,
        Pagination
    },
    data() {
        return {
            form: {},
            createDialog: false,
            dialog: false,
            osList: [],
            tableData: [],
            confirmData: null,
            confirmDataDialog: false,
            cancelConfirmDialog: false,
            cancelConfirmId: null,
            contentTypeList: [],
            pagination: {}
        }
    },
    created() {
        this.fetch()
        this.$store.commit(types.PAGE_TITLE, 'アプリ　>　PUSH')
    },
    methods: {
        async fetch(page = 1) {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data } = await axios.get('/api/smartphone/notifications', {
                params: { page }
            })
            this.tableData = Object.assign([], data.notifications).filter((item) => {
                return !item.canceled
            })
            this.osList = Object.assign([], Object.values(data.os_list))
            this.contentTypeList = Object.assign([], data.content_type_list)
            this.pagination = data.pagination
            this.$store.commit(types.PAGE_LOADING, false)
        },
        createRow () {
            this.form = {}
            this.createDialog = true
        },
        async create (form) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.post('/api/smartphone/notifications', form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                throw new Error(error)
            }

            this.closeDialog()
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'プッシュ通知を作成しました')
            this.$store.commit(types.LOADING, false)
        },
        closeDialog () {
            this.createDialog = false
        },
        totalCount(row) {
            return row.successful + row.failed + row.errored
        },
        showDate(date) {
            return moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
        },
        checkSendAfter(date) {
            return moment.unix(new Date().getTime() / 1000) - moment.unix(date) < 0
        },
        cancelRow(row) {
            this.cancelConfirmId = row.id
            this.cancelConfirmDialog = true
        },
        async performCancelDialog() {
            this.$store.commit(types.PAGE_LOADING, true)
            await axios.delete('/api/smartphone/notifications/' + this.cancelConfirmId)
            await this.fetch()
            this.$store.commit(types.PAGE_LOADING, false)

            this.cancelConfirmId = null
            this.cancelConfirmDialog = false
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'キャンセルしました')
        },
        closeCancelDialog() {
            this.cancelConfirmId = null
            this.cancelConfirmDialog = false
        },
        openConfirmData(row) {
            this.confirmDataDialog = true
            this.confirmData = row.data
        }

    }
}
</script>

<style scoped>
.preData{
    white-space:pre-line;
}
.confirmData {
    white-space:normal;
    text-align:left;
    max-height:200px;
    margin-top:-10px;
}

.reserved {
    color:RGBA(227, 126, 123);
}
</style>

