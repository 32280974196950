<template>
    <div class="materialForm">
        <div v-if="id === null" class="materialForm__caution">※同時に見出しアイテムも生成されます</div>
        <el-form class="materialForm__contents" @close="$emit('close')" @submit.native.prevent label-width="120px">
            <el-form-item v-if="!id" label="大見出し" required>
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.heading"
                />
                <text-length-count :targetText="form.heading" :maxTextLength="100" />
            </el-form-item>
            <el-form-item label="提供人数">
                <el-row>
                    <div v-if="!id" class="materialForm__caution">
                        <p>保存すると、「大見出し」に人数が反映されます</p>
                        <p>自由入力が優先されます</p>
                    </div>
                    <div v-else class="materialForm__caution">
                        <p>提供人数を更新した場合、見出しへの反映は自動でされないため、手動で更新してください</p>
                    </div>
                </el-row>
                <el-row class="materialForm__servingsWrapper">
                    <el-input-number
                        size="mini"
                        v-model="form.servings_min"
                        :min="0"
                    ></el-input-number> 人分〜
                    <el-input-number
                        size="mini"
                        v-model="form.servings_max"
                        :min="0"
                    ></el-input-number> 人分
                </el-row>
                <el-row>
                    <el-input
                        type="text"
                        size="mini"
                        v-model="form.servings_free_text"
                        placeholder="自由入力"
                    />
                    <text-length-count :targetText="form.servings_free_text" :maxTextLength="255" />
                </el-row>
            </el-form-item>
            <el-form-item label="材料" required>
                <el-form-item v-for="(element, idx) in elements" v-bind:key="idx">
                    <div class="materialList">
                        <div class="materialListMainItem">
                            <div>
                                <span class="required"></span>
                                <el-autocomplete
                                    v-model="element.material.material_name"
                                    class="materialListMainItem__formElement"
                                    type="text"
                                    size="mini"
                                    :fetch-suggestions="suggestMaterial"
                                    @focus="focusSuggestList(idx)"
                                    @select="showSuggestUnit"
                                    placeholder="材料・調味料"
                                    popper-class="materialListMainItem__formElementSuggestPopper"
                                ></el-autocomplete>
                            </div>
                            <el-input
                                v-model="element.quantity_text"
                                class="materialListMainItem__quantityElement"
                                type="text"
                                size="mini"
                                placeholder="分量"
                            >
                                <el-button slot="append" @click="elementMoveUp(element, idx)" icon="el-icon-arrow-up"></el-button>
                                <el-button slot="append" @click="elementMoveDown(element, idx)" icon="el-icon-arrow-down"></el-button>
                                <el-button slot="append" @click="elementRemove(element, idx)" icon="el-icon-close"></el-button>
                            </el-input>
                            <el-select
                                v-if="element.suggest_unit_list.length"
                                v-model="element.suggest_unit_name"
                                placeholder="単位"
                                size="mini"
                                class="materialListMainItem__unitElement"
                                @change="setUnitName(element)">
                                <el-option
                                    v-for="(value, index) in element.suggest_unit_list"
                                    :key="index"
                                    :label="value"
                                    :value="value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="materialListSubItem">
                            <el-input
                                v-model="element.material_group_name"
                                type="text"
                                size="mini"
                                placeholder="グループ名"
                                class="materialListSubItem__groupElement"
                            ></el-input>
                            <el-input
                                :value="element.material.material_category ? element.material.material_category.material_category_name : ''"
                                type="text"
                                size="mini"
                                disabled
                                placeholder="マスタなし"
                                class="materialListSubItem__categoryElement"
                            ></el-input>
                            <el-checkbox
                                v-model="element.is_main"
                                size="mini"
                                class="materialListSubItem__labelElement"
                            >メイン食材</el-checkbox>
                        </div>
                    </div>
                </el-form-item>
            </el-form-item>
            <el-form-item>
                <el-button size="small" @click="addElement()" type="primary">行を追加する</el-button>
            </el-form-item>
        </el-form>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
    </div>
</template>

<script>

import * as types from '../../../../../../store/mutation-types'
import TextLengthCount from '../Components/TextLengthCount'

const defaultFormValues = () => ({
    heading: '材料',
    servings_min: 0,
    servings_max: 0,
    servings_free_text: null,
    elements: []
})
const defaultElementFormValues = () => ({
    id: null,
    material_group_name: null,
    quantity_text: null,
    is_main: false,
    material: {
        material_name: null,
        material_category: {
            material_category_name: null,
        }
    },
    suggest_unit_list: [],
})
export default {
    components: {
        TextLengthCount
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        resourceName: {
            required: true,
            type: String,
        },
    },
    data () {
        return {
            form: defaultFormValues(),
            elements: [defaultElementFormValues()],
            focusedLineIndex: null,
        }
    },
    methods: {
        submitForm () {
            this.form.elements = this.elements.map((element) => ({
                id: element.id,
                material_name: element.material.material_name,
                material_group_name: element.material_group_name,
                quantity_text: element.quantity_text,
                is_main: element.is_main,
            }))
            const form = {
                ...this.form,
                servings_max: this.form.servings_max ?? null,
                servings_min: this.form.servings_min ?? null
            }
            this.save(this.resourceName, form, this.id)
        },
        openDialog(input) {
            this.form = {...defaultFormValues(), ..._.pick(input, Object.keys(this.form))}
            this.elements = input.elements.map((element) => ({...defaultElementFormValues(), ..._.pick(element, Object.keys(defaultElementFormValues()))}))
        },
        addElement() {
            this.elements.push(defaultElementFormValues())
        },
        elementMoveDown(element, index) {
            const movedIndex = index + 1
            if (this.elements[movedIndex]) {
                this.elements.splice(index, 2, this.elements[movedIndex], this.elements[index])
            }
        },
        elementMoveUp(element, index) {
            if (index > 0) {
                const movedIndex = index - 1
                this.elements.splice(movedIndex, 2, this.elements[movedIndex+1], this.elements[movedIndex])
            }
        },
        elementRemove(element, index) {
            if (this.elements.length <= 1) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '最低1つ必要です。')
                return
            }

            this.elements.splice(index, 1)
        },
        focusSuggestList(index) {
            this.elements[index].suggest_unit_list = []
            this.elements[index].suggest_unit_name = null
            this.focusedLineIndex = index
        },
        async suggestMaterial(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/articles/items/materials/suggest', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'URL検索に失敗しました')
                    return
                }
                cb(data.suggest_materials)
            }
        },
        showSuggestUnit(item) {
            this.$set(this.elements[this.focusedLineIndex], 'suggest_unit_list', item.unit_name)
            this.$set(this.elements[this.focusedLineIndex].material, 'material_name', this.elements[this.focusedLineIndex].material.material_name.replace('【商品名】', ''))
        },
        setUnitName(element) {
            element.quantity_text = element.suggest_unit_name
            element.suggest_unit_name = null
        },
    }
}
</script>

<style scoped lang="scss">
.materialForm {
    &__contents {
        margin-bottom: 15px;
    }
    &__caution {
        font-size: 12px;
        color: red;
    }
    &__servingsWrapper {
        margin-bottom: 10px;
    }
}

.materialList {
    display: flex;
    flex-direction: column;
}
.materialListMainItem {
    display: flex;
    &__formElement {
        width: 129px;
        margin: 0 5px 0 0;
    }
    &__quantityElement {
        width: 186px;
        margin: 0 5px 0 0;
    }
    &__unitElement {
        width: 106px;
        margin: 0 5px 0 0;
    }
}
.materialListSubItem {
    display: flex;
    margin: 5px 0 0 0;
    padding: 0 0 0 148.7px;
    &__groupElement {
        width: 186px;
        margin: 0 5px 0 0;
    }
    &__categoryElement {
        width: 106px;
        margin: 0 5px 0 0;
    }
    &__labelElement {
        width: 106px;
    }
}

.required::before {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
}
</style>
