<template>
    <div class="nutrientItem">
        <div v-if="id === null" class="nutrientItem__caution">※同時に見出しアイテムも生成されます</div>
        <div v-else class="nutrientItem__caution">※補足テキストを更新した場合、見出しへの反映は自動でされないため、手動で更新してください</div>
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent label-width="140px" >
            <el-form-item label="栄養情報">
                <el-row class="nutrientItem__innerFormContent">
                    <el-col :span="8">
                        <div class="nutrientItem__innerFormLabel">エネルギー</div>
                        <el-input-number
                            size="mini"
                            v-model="form.calorie"
                            :min="0"
                        ></el-input-number> Kcal
                    </el-col>
                    <el-col :span="8">
                        <div class="nutrientItem__innerFormLabel">タンパク質</div>
                        <el-input-number
                            size="mini"
                            v-model="form.protein"
                            :min="0"
                        ></el-input-number> g
                    </el-col>
                    <el-col :span="8">
                        <div class="nutrientItem__innerFormLabel">脂質</div>
                        <el-input-number
                            size="mini"
                            v-model="form.lipid"
                            :min="0"
                        ></el-input-number> g
                    </el-col>
                </el-row>
                <el-row class="nutrientItem__innerFormContent">
                    <el-col :span="8">
                        <div class="nutrientItem__innerFormLabel">炭水化物</div>
                        <el-input-number
                            size="mini"
                            v-model="form.carbohydrate"
                            :min="0"
                        ></el-input-number> g
                    </el-col>
                    <el-col :span="8">
                        <div class="nutrientItem__innerFormLabel">塩分量</div>
                        <el-input-number
                            size="mini"
                            v-model="form.salt"
                            :min="0"
                        ></el-input-number> g
                    </el-col>
                    <el-col :span="8">
                        <div class="nutrientItem__innerFormLabel">糖質</div>
                        <el-input-number
                            size="mini"
                            v-model="form.saccharinity"
                            :min="0"
                        ></el-input-number> g
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="補足テキスト" required>
                <el-input
                    type="text"
                    v-model="form.nutrient_comment"
                    size="mini"
                ></el-input>
                <text-length-count :targetText="form.nutrient_comment" :maxTextLength="255" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
    </div>
</template>

<script>
import TextLengthCount from './Components/TextLengthCount'

const defaultFormValues = () => ({
    id: null,
    calorie: null,
    protein: null,
    lipid: null,
    carbohydrate: null,
    salt: null,
    saccharinity: null,
    nutrient_comment: null,
})

export default {
    components: {
        TextLengthCount
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
    },
    data() {
        return {
            form: {},
        }
    },
    created() {
        this.form = defaultFormValues()
    },
    methods: {
        submitForm () {
            this.save(this.resourceName, this.form, this.id)
        },
        openDialog(input) {
            this.form = Object.assign({}, defaultFormValues(), _.pick(input, Object.keys(this.form)))
        },
    },
    computed: {
        resourceName() {
            return 'nutrients'
        },
    }
}

</script>

<style scoped lang="scss">
.nutrientItem {
    &__caution {
        color: red;
        font-size: 12px;
    }
    &__innerFormContent {
        &:first-child {
            margin-bottom: 10px;
        }
    }
}
</style>
