<template>
    <div>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <el-table
                :data="whitelists"
                style="width: 100%"
                max-height="800"
        >
            <el-table-column label="許可URL" prop="path"></el-table-column>
            <el-table-column label="引用タイプ" prop="quote_type"></el-table-column>
            <el-table-column label="作成日時" prop="created_at"></el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
    </div>
</template>

<script>
import * as types from '../../../store/mutation-types'
import Pagination from '../../../components/Pagination'

export default {
    components: {
        Pagination
    },
    data() {
        return {
            whitelists: [],
            pagination: {},
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '外部連携管理　>　URLホワイトリスト')
        this.init()
    },
    methods: {
        init() {
            this.search()
        },
        // 検索
        async search() {
            const page = this.$route.query.page
            this.$store.commit(types.LOADING, true)
            const { data, error } = await axios.get('/api/quote/url/whitelists', {
                params: { page }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.whitelists = data.data
            this.pagination = Object.assign({},this.pagination, data)
            this.$store.commit(types.LOADING, false)
            return Promise.resolve(1)
        },
        handleCurrentChange (page) {
            this.$router.push({query: {page}})
            this.search()
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
