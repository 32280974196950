import {Mark}       from 'tiptap'
import {toggleMark} from 'tiptap-commands'

export default class TextLarge extends Mark {
    get name() {
        return 'textLarge'
    }

    get defaultOptions() {
        return {
            levels: ['text-large']
        }
    }

    get schema() {
        return {
            attrs   : {
                level: {
                    default: ''
                }
            },
            parseDOM: [
                {
                    tag     : 'span.text-large',
                    getAttrs: (dom) => {
                        return {level: dom.classList[1]}
                    }
                },
            ],
            toDOM   : mark => {
                return ['span', {class: 'text-large'}, 0]
            }
        }
    }

    commands({type}) {
        return attrs => toggleMark(type, attrs)
    }
}
