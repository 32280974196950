<template>
    <div>
        <el-row type="flex" justify="end" style="margin-top:10px;">
            <el-select placeholder="ステータスでフィルタ" v-model="status" class="search">
                <el-option
                    v-for="(item) in statusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-autocomplete
                class="search"
                placeholder="担当者名"
                v-model="admin"
                :fetch-suggestions="querySearchAdmin"
                :trigger-on-focus="true"
            ></el-autocomplete>
            <el-input
                class="search"
                placeholder="元記事ID"
                v-model="articleId"
                @keyup.enter.native="search()"
                style="width: 300px;"
            ></el-input>
            <el-input
                class="search"
                placeholder="タイトル"
                v-model="articleTitle"
                @keyup.enter.native="search()"
                style="width: 500px;"
            ></el-input>
            <el-button
                @click="search()"
                icon="el-icon-search"
                class="search-input"
                style="height: 40px; margin-top: 10px;"
            ></el-button>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <el-table class="list" :data="tableData" style="font-size: 12px" max-height="800">
            <el-table-column :width="60" label="ID" >
                <template slot-scope="scope" >
                    {{ scope.row.id }}
                </template>
            </el-table-column>
            <el-table-column :width="60" label="元記事ID" >
                <template slot-scope="scope" >
                    <a :href="scope.row.article_url"
                       target="_blank">{{ scope.row.article_id }}</a>
                </template>
            </el-table-column>
            <el-table-column :width="60" label="編集記事ID" >
                <template slot-scope="scope" >
                    <a :href="scope.row.rss_edit_article_url"
                       target="_blank">{{ scope.row.rss_article_id }}</a>
                </template>
            </el-table-column>
            <el-table-column label="元記事サムネ" width="80">
                <template slot-scope="scope">
                    <div class="image">
                        <label :for="'image_' + scope.row.id">
                            <img v-if="scope.row.thumbnail_cdn_url" :src="scope.row.thumbnail_cdn_url" width="70" alt="サムネイル">
                        </label>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="サムネ" width="80">
                <template slot-scope="scope">
                    <div class="image">
                        <label :for="'image_' + scope.row.id">
                            <img v-if="scope.row.rss_edit_thumbnail_cdn_url" :src="scope.row.rss_edit_thumbnail_cdn_url"   width="70" alt="サムネイル">
                        </label>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :width="60" label="ステータス" >
                <template slot-scope="scope" >
                    {{ scope.row.status }}
                </template>
            </el-table-column>
            <el-table-column label="タイトル" >
                <template slot-scope="scope" >
                    {{ scope.row.title }}
                </template>
            </el-table-column>
            <el-table-column label="編集者" >
                <template slot-scope="scope" >
                    {{ scope.row.admin_name }}
                </template>
            </el-table-column>
            <el-table-column
                width="80">
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.status === '未配信'"
                        type="danger"
                        size="mini"
                        @click="deleteRow(scope.row.id)">
                        削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'

export default {
    components: {
        Pagination,
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data() {
        return {
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            pagination  : {},
            tableData   : [],
            statusList:[],
            adminList: [],

            status: null,
            admin: '',
            articleId: '',
            title: '',
            form: {}
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'RSS用編集記事一覧')
        this.queryInit()
        this.fetch()
    },
    methods: {
        queryInit() {
            this.status = this.$route.query.status ? Number(this.$route.query.status) : null
            this.admin = this.$route.query.admin ? this.$route.query.admin : ''
            this.articleId = this.$route.query.article_id ? this.$route.query.article_id : ''
            this.articleTitle = this.$route.query.article_title ? this.$route.query.article_title : ''
            this.page = this.$route.query.page ? this.$route.query.page : 1
        },
        search() {
            const query = Object.assign({}, this.$route.query,{
                status: this.status,
                admin: this.admin,
                article_id: this.articleId,
                article_title: this.articleTitle,
                page: 1
            })
            this.$router.push({ query })
        },
        init() {
            this.fetch()
        },
        // 検索
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data } = await axios.get('/api/rss_edit', {
                params: { ...this.$route.query }
            })

            this.pagination = Object.assign({}, data.rss_edits)

            const rssEdits = Object.assign([], data.rss_edits.data)
            
            this.statusList = Object.assign([], data.status_list)
            this.statusList.unshift({label: null, value: null})

            this.admin = this.$route.query.admin ? String(this.$route.query.admin) : null

            this.tableData = _.map(rssEdits, (rssEdit) => {
                const current_status = data.status_list.filter(s => s.value === rssEdit.status)
                return {
                    id: rssEdit.id,
                    article_id: rssEdit.origin_rss_article_id,
                    rss_article_id: rssEdit.rss_article_id,
                    article_url:'/articles/' + rssEdit.origin_rss_article_id,
                    thumbnail_cdn_url:rssEdit.origin_rss_article.thumbnail_cdn_url,
                    rss_edit_article_url:'/articles/' + rssEdit.rss_article_id,
                    rss_edit_thumbnail_cdn_url:rssEdit.rss_article.thumbnail_cdn_url,
                    admin_id: rssEdit.admin_id,
                    admin_name: ! rssEdit.rss_article.direction.editing_admin ? null:rssEdit.rss_article.direction.editing_admin.name,
                    status:current_status[0].label,
                    title: rssEdit.rss_article.title,
                }
            })

            this.$store.commit(types.PAGE_LOADING, false)
        },
        handleCurrentChange (page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
        handleSelectAdmin (item) {
            this.admin = item.value
        },
        async querySearchAdmin(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/admins/suggest/editor', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, '管理者検索に失敗しました')
                    return
                }
                cb(data.admins)
            }
        },
        async deleteRow(id) {
            if (! confirm('本当に削除しますか？')) {
                return
            }

            this.$store.commit(types.PAGE_LOADING, true)

            const { error } = await axios.delete('/api/rss_edit/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '削除しました')
            this.fetch()
        },
    }
}
</script>

<style scoped>
.search {
    margin: 10px 5px 0;
}
.btnEdit {
    padding: 9px 15px;

    border-radius: 3px;
    margin-right: 10px;
    margin-top: 10px;
}
.el-button {
    margin: 5px 0 5px 10px;
}
</style>
