<template>
    <div>
        <el-row align="middle" style="margin-top: 20px;">
            <el-radio-group v-model="form.link_type" style="margin: -3px 20px 0;" size="mini">
                <el-radio-button label="text">テキスト</el-radio-button>
                <el-radio-button label="button">ボタン</el-radio-button>
                <el-radio-button label="thumbnail">サムネイル</el-radio-button>
            </el-radio-group>
            <el-button type="text" :disabled="content !== null" size="mini" @click="() => this.internalLinkDialog = true">探して追加する</el-button>
        </el-row>
        <el-form class="itemForm" @submit.native.prevent label-width="200px" >
            <el-form-item label="URL入力" style="width:80%;">
                <el-input
                    :disabled="content !== null"
                    width="50%"
                    type="text"
                    size="small"
                    v-model="linkUrl">
                    <el-button @click="searchLinkFromUrl" :disabled="content !== null" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="リンクタイトル" >
                <el-input
                    type="text"
                    size="small"
                    maxlength="255"
                    v-model="form.link_title">
                </el-input>
            </el-form-item>
            <el-form-item label="リンクディスクリプション" v-if="form.link_type !== 'button'" style="margin-bottom: 6px;">
                <el-input
                    type="textarea"
                    size="small"
                    :rows="3"
                    v-model="form.link_description">
                </el-input>
            </el-form-item>
            <el-form-item label="コメント" v-if="form.link_type === 'text'">
                <el-input
                    type="textarea"
                    size="small"
                    maxlength="300"
                    :rows="3"
                    v-model="form.link_comment">
                </el-input>
            </el-form-item>
        </el-form>
        <div v-if="form.link_type === 'thumbnail'" >
            <h4 style="text-align:center;font-weight: normal;">サムネイル選択</h4>
            <el-row type="flex" justify="center"  style="margin: 0 20px 20px;">
                <el-col :span="10" v-if="fetchedImages.length > 0">
                     <el-carousel @change="setCrouselIndex" arrow="always" :autoplay="false" trigger="click"  height="200px" indicator-position="none">
                         <el-carousel-item v-for="(image,index) in fetchedImages" :key="index">
                             <img :src="image" alt="" >
                         </el-carousel-item>
                     </el-carousel>
                     <p style="margin-top: 5px;text-align: center;">
                         <el-radio v-model="useImage" label="site">サイト上の画像を選択</el-radio>
                     </p>
                </el-col>
                <el-col :span="2" style="text-align:center; margin-top:100px;" v-if="fetchedImages.length > 0">
                    OR
                </el-col>
                <el-col :span="10">
                     <el-upload
                        :headers="header"
                        class="avatar-uploader"
                        :auto-upload="false"
                        action=""
                        :on-change="handleImageAdd"
                        :show-file-list="false"
                        >
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <p style="margin-top: 5px;text-align: center;">
                        <el-radio v-model="useImage" label="upload">画像をアップロード</el-radio>
                    </p>
                </el-col>
            </el-row>
        </div>
        <div  class="itemFormFooter" style="margin-left: 200px;">
            <el-button type="primary" size="mini" :disabled="!enableSave" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </div>
        <el-dialog
            title="macaroniを探す"
            @close="() => this.internalLinkDialog = false"
            :visible="internalLinkDialog"
            class="internalLinkDialogWrapper"
            height="50%"
            width="75%"
            append-to-body
        >
            <internal-link :articleFormat="articleFormat" @save="submitFormInternalLink" @close="() => this.internalLinkDialog = false"/>
        </el-dialog>
    </div>
</template>

<script>

import * as types from '../../../../../store/mutation-types'
import InternalLink from './Components/InternalLink'

const defaultFormValues =  {
    link_url:null,
    link_title:null,
    link_type:'text',
    link_description:null,
    link_comment:null,
    link_thumbnail_url:null
}
export default {
    name:'ItemLinkType',
    components: {
        InternalLink,
    },
    props: {
        articleFormat: {
            required: true,
            type: String,
        },
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        content: {
            required: false,
            type: Object|null,
            default: null
        },
        saveAndContinue: {
            required: false,
            type: Function|null,
            default: null,
        },
    },
    data () {
        return {
            form: {...defaultFormValues},
            internalLinkDialog: false,
            useImage:'upload',
            imageUrl: null,
            imageRaw: null,
            fetchedLink: false,
            fetchedImages: [],
            linkUrl: null,
            linkKeyword: null,
            linkResult:null,
            hiddenImage:true,
            keywrodResults:[],
            crouselIndex:null,
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
        }
    },
    computed: {
        enableSave() {
            if (this.form.link_type === 'text' || this.form.link_type === 'button') {
                return Boolean(this.form.link_url) && this.fetchedLink
            } else if(this.useImage === 'upload'){
                return Boolean(this.form.link_url) && this.imageRaw && this.fetchedLink
            }  else {
                return Boolean(this.form.link_url) && this.fetchedLink
            }
        },
        resourceName() {
            return 'links'
        },
    },
    methods: {
        submitForm () {
            if (this.form.link_type === 'thumbnail') {
                if (this.useImage === 'site') {
                    this.form.link_thumbnail_url = this.fetchedImages[this.crouselIndex]
                } else {
                    this.form.image_file = this.imageRaw
                    const fd = new FormData()
                    _.forEach(this.form, (value,key) => {
                        if (value === null) {
                            this.form[key] = ''
                        }
                    })
                    this.form = Object.assign({}, defaultFormValues, this.form)
                    fd.append('image_file', this.form.image_file)
                    fd.append('link_url', this.form.link_url)
                    fd.append('link_title', this.form.link_title)
                    fd.append('link_description', this.form.link_description)
                    fd.append('link_comment', this.form.link_comment)
                    fd.append('link_type', this.form.link_type)
                    this.form = fd
                }
            }
            this.save(this.resourceName, this.form, this.id)
        },
        submitFormInternalLink (article, isArticle, isContinue = false, tagType = '') {
            const fd = new FormData()
            let submitTo = ''
            if (isArticle)
            {
                fd.append('article_id', article.id)
                submitTo = 'links/saveInternalLink'
            }
            else {
                if (tagType === 'grandchild') {
                    fd.append('grandchild_tag_id', article.id)
                } else if (tagType === 'child') {
                    fd.append('child_tag_id', article.id)
                } else if (tagType === 'parent') {
                    fd.append('parent_tag_id', article.id)
                }
                submitTo = 'links/saveTagLink'
            }
            this.form = fd

            if (isContinue) {
                this.saveAndContinue(submitTo, this.form, this.id)
            } else {
                this.save(submitTo, this.form, this.id)
            }
        },
        addLinkResult (link, index) {
            this.form = Object.assign({}, defaultFormValues, {is_show_image:!this.hiddenImageList[index]}, link)
            this.saveAndContinue('link', this.form, this.id)
        },
        async searchLinkFromUrl() {
            this.$store.commit(types.EDITOR_LOADING, true)
            const {data,error} = await axios.get('/api/site/scraping', {
                params: {
                    url:this.linkUrl
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.EDITOR_LOADING, false)
                return
            }
            this.linkResult = data
            this.fetchedLink = true
            this.form.link_title = this.linkResult.title
            this.form.link_description = this.linkResult.meta.description
            this.form.link_url = this.linkUrl
            this.fetchedImages = Object.assign([],this.linkResult.images)
            // リンク先でスクレイピングできなかった場合
            if (data.title === '' && data.meta === '' && data.images.length === 0) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'リンク先情報の取得に失敗、またはリンク切れを起こしています。<br>手動で情報の入力対応、リンク先の確認をお願いします。')
            }
            this.$store.commit(types.EDITOR_LOADING, false)
        },
        handleImageAdd(file) {
            this.imageRaw = file.raw
            this.imageUrl = URL.createObjectURL(file.raw)
            this.fetchedImage = true
        },

        async searchLinkFromKeywrod() {
            this.$store.commit(types.EDITOR_LOADING, true)
            const {data,error} = await axios.get('/api/link/search/links', {
                params: {
                    keyword:this.linkKeyword,
                    excludeRt:this.excludeRt,
                    onlyImgLink:this.onlyImgLink
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'キーワード検索に失敗しました')
                this.$store.commit(types.EDITOR_LOADING, false)
                return
            }
            this.keywrodResults = Object.assign([], data)
            this.$store.commit(types.EDITOR_LOADING, false)
        },
        setCrouselIndex(index) {
            this.crouselIndex = index
        },
        openDialog(input) {
            this.form = Object.assign({}, defaultFormValues, _.pick(input, Object.keys(this.form)))
            this.linkUrl = this.form.link_url
            this.fetchedLink = true
        },
    }
}
</script>
<style scoped>
.el-carousel__item {
  background-color: #99a9bf;
  text-align:center;
}

.el-carousel__item img {
    max-height:70%;
    padding-top:10px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader {
    text-align: center;
    height: 180px;
    background-color: #99a9bf;
    padding:10px;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 180px;
    height: 180px;
    line-height: 180px !important;
    text-align: center;
    background-color: rgba(255,255,255,0.9);
}
.avatar {
    width: 200px;
    display: block;
}

</style>
