<template>
    <div>
        <div :ref="'order'+number" class="sectionCard itemContents" :class="{ updateOrdersMode: updateOrdersMode }">
            <component
                :articleFormat="articleFormat"
                :is="item.item_type"
                @delete="confirmDelete"
                @updateFeedback="updateFeedback"
                :parentCreate="createItem"
                :parentUpdate="updateItem"
                :parentCheckItem="checkItem"
                :number="number"
                :updateOrdersMode="updateOrdersMode"
                :item="item"
                :response="response"
                :is-tieup="isTieup"
            />
        </div>

        <el-dialog
            title="記事アイテム削除"
            :visible.sync="confirmDeleteDialog"
            width="30%"
            @close="closeDeleteDialog"
            >
            <span >記事アイテム<span class="dialogItemNumber">{{ number }}</span>を削除してよろしいですか？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDeleteDialog">キャンセル</el-button>
                <el-button type="danger" @click="deleteItem">削除する</el-button>
            </span>
        </el-dialog>

        <menu-bar
            v-if="!updateOrdersMode"
            :fetch="fetch"
            :response="response"
            :order="order"
            :article-format="articleFormat"
            :is-tieup="isTieup"
        />
    </div>
</template>
<script>
import * as types from '../../../../store/mutation-types'
import HeadingType from './Items/HeadingType'
import TextType from './Items/TextType'
import ProductType from './Items/ProductType'
import QuoteType from './Items/QuoteType'
import AffiliateType from './Items/AffiliateType'
import TweetType from './Items/TweetType'
import PinterestType from './Items/PinterestType'
import ShopType from './Items/ShopType'
import PlaceType from './Items/PlaceType'
import ImageType from './Items/ImageType'
import LinkType from './Items/LinkType'
import VideoType from './Items/VideoType'
import MaterialType from './Items/MaterialType'
import RecipeType from './Items/RecipeType'
import EmbeddedType from './Items/EmbeddedType'
import EmphasisType from './Items/EmphasisType'
import InformationType from './Items/InformationType'
import ComparisonType from './Items/ComparisonType'
import RecipeLinkType from './Items/RecipeLinkType'
import TableType from './Items/TableType'
import CookingTimeType from './Items/CookingTimeType'
import NutrientType from './Items/NutrientType'
import MenuBar from './MenuBar'
import {PAGE_LOADING} from '../../../../store/mutation-types'

export default {
    data () {
        return {
            confirmDeleteDialog: null,
            deleteTargetId: null,
            deleteTargetType: null,
            deleteTargetOrderNum: null,
        }
    },
    components: {
        HeadingType,
        TextType,
        ProductType,
        QuoteType,
        AffiliateType,
        TweetType,
        PinterestType,
        ImageType,
        ShopType,
        PlaceType,
        LinkType,
        VideoType,
        MaterialType,
        RecipeType,
        EmbeddedType,
        EmphasisType,
        InformationType,
        MenuBar,
        ComparisonType,
        RecipeLinkType,
        TableType,
        CookingTimeType,
        NutrientType,
    },
    props: {
        order: Number,
        response: Object,
        item: Object,
        fetch: Function,
        checkItem: Function,
        updateOrdersMode: Boolean,
        number: Number,
        articleFormat: String,
        isTieup: Boolean,
    },
    mounted() {
        if (this.$route.hash) {
            const refName = this.$route.hash.replace('#', '')
            const ref = this.$refs[refName]
            if (ref !== undefined) {
                setTimeout(() => {
                    ref.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
                }, 1000)
            }
        }
    },
    methods: {
        // アイテム一覧から別のアイテムを生成するケース
        // 旧店舗情報アイテムの内容から新店舗情報アイテムを生成するケースで使用します
        async createItem(type, form) {
            this.$store.commit(types.PAGE_LOADING,true)

            const newItemOrderNum = this.item.order_num + 1
            if (form instanceof FormData) {
                form.append('order_num', newItemOrderNum)
            } else {
                form.order_num = newItemOrderNum
            }

            const { error } = await axios.post(`/api/articles/${this.response.article.id}/items/${type}`, form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING,false)
                return false
            }

            await this.fetch()
            this.$store.commit(types.PAGE_LOADING,false)
            return true
        },
        async updateItem(type, form, id) {
            this.$store.commit(types.PAGE_LOADING,true)

            let res = null
            if (form instanceof FormData) {
                const config = {
                    headers: {
                        'X-HTTP-Method-Override': 'PATCH'
                    }
                }
                res = await axios.post('/api/articles/'+this.response.article.id+'/items/' + type + '/' + id, form, config)
            } else {
                res = await axios.patch('/api/articles/'+this.response.article.id+'/items/' + type + '/' + id, form)
            }
            const error = res.error
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING,false)
                return false
            }
            await this.fetch()
            this.$store.commit(types.PAGE_LOADING,false)
            return true
        },
        async updateFeedback(type, id, form) {
            const {error} = await axios.patch('/api/articles/'+this.response.article.id+'/items/' + type + '/' + id + '/feedback',form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return false
            } else {
                await this.fetch()
            }
            return true
        },

        async deleteItem() {
            this.$store.commit(types.PAGE_LOADING, true)

            const {error} = await axios.delete('/api/articles/'+this.response.article.id+'/items/' + this.deleteTargetType + '/' + this.deleteTargetId)
            this.closeDeleteDialog()
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return false
            } else {
                await this.fetch()
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '記事アイテムを削除しました')
            this.$store.commit(types.PAGE_LOADING, false)
            return true
        },
        confirmDelete(type, id, {isSkipConfirm = false} = {}) {
            this.deleteTargetId = id
            this.deleteTargetType = type

            if (isSkipConfirm) {
                this.deleteItem()
            } else {
                this.confirmDeleteDialog = true
            }
        },
        closeDeleteDialog () {
            this.deleteTargetId = null
            this.deleteTargetType = null

            this.confirmDeleteDialog = false
        }
    }
}
</script>
<style>
.itemSection .sectionCard.itemContents {
    padding: 0px 15px;
}
.itemSection .sectionCard.itemContents:hover {
    background-color: #f2f2f2;
}
.leftBox {
    padding-top: 5px;
}
.sectionCard .contents {
    word-wrap: break-word;
    padding: 5px 38px 5px 0;
    border-right: 1px solid #ddd;
    min-height: 33px;
    color: #000000;
}
.rightBox {
    padding-top: 5px;
    text-align: right;
}
.itemCheckBox {
    float:left;
    margin-right:10px !important;
}
.updateOrdersMode {
    max-height:100px;
    overflow:hidden;
}
.el-badge__content.is-fixed.is-dot{
    right: 8px !important;
    top: 2px;
}
.itemFooter {
    position: absolute;
    padding: 0 20px;
    left: 955px;
    width: 67px;
    text-align: right;
    opacity: 0;
    z-index: 10;
}
.itemSection .sectionCard:hover .itemFooter,
.itemSection .itemFooter:hover {
    opacity: 1;
}
.itemFooter .btnEdit {
    font-size: 12px !important;
    padding: 5px 0 !important;
}
.itemFooter .btnEdit:hover {
    text-decoration: underline;
}
.el-input-group__append button {
    padding: 12px;
}
.dialogItemNumber {
    font-size:18px;
    font-weight:bold;
}
</style>
