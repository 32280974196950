<template>
    <div>
        <el-row>
            <el-col class="leftBox" :span="1">
                <el-checkbox class="itemCheckBox" v-model="checkState" >{{number}}</el-checkbox>
            </el-col>
            <el-col class="contents" :span="21">
                <recipe-type
                    v-if="isEditing"
                    ref="dialogModule"
                    :save="update"
                    :id="content.id"
                    :input="content"
                    :headingTypeList="response.headingTypeList"
                    :article-format="response.article.format"
                    :is-preparation="!!content.is_preparation"
                    @close="closeMenu"
                />
                <div v-else class="recipe">
                    <!-- 動画記事 -->
                    <div v-if="response.article.format === 'video'">
                        <el-row class="materialLabel" type="flex">
                            <div>{{ getCookName() }}の作り方</div>
                        </el-row>
                        <div v-for="(element, idx) in item.content.elements" v-bind:key="idx">
                            <p v-if="idx == 0">
                                <span class="make-index">下</span>
                                <span class="contentHtml" v-html="element.recipe_text"></span>
                            </p>
                            <p v-else>
                                <span class="make-index">{{idx}}</span>
                                <span class="contentHtml" v-html="element.recipe_text"></span>
                            </p>
                            <el-row type="flex">
                                <img v-if="element.recipe_image1_cdn_url" :src="element.recipe_image1_cdn_url" class="mainThumbnail recipeImage" />
                                <img v-if="element.recipe_image2_cdn_url" :src="element.recipe_image2_cdn_url" class="mainThumbnail recipeImage" />
                                <img v-if="element.recipe_image3_cdn_url" :src="element.recipe_image3_cdn_url" class="mainThumbnail recipeImage" />
                                <img v-if="element.recipe_image4_cdn_url" :src="element.recipe_image4_cdn_url" class="mainThumbnail recipeImage" />
                            </el-row>
                        </div>
                    </div>
                    <!-- 通常記事 -->
                    <div v-else-if="response.article.format === 'normal'">
                        <el-tag class="recipe__type" type="info">{{ content.is_preparation ? '下ごしらえ' : '作り方' }}</el-tag>
                        <div v-for="(element, idx) in content.elements" :key="idx" class="recipe__elementWrapper">
                            <div v-if="element.recipe_text" class="recipe__heading"><i class="far fa-circle"></i><span v-if="! content.is_preparation">{{ element.order_num }}.</span> {{ element.recipe_text }}</div>
                            <div v-for="(elementContent, elementContentIdx) in element.contents" :key="elementContentIdx" class="recipe__contentWrapper">
                                <img v-if="elementContent.image_cdn_url" class="recipe__img" :src="elementContent.image_cdn_url" />
                                <div v-if="elementContent.alt_text" class="recipe__imgAlt">alt属性：{{ elementContent.alt_text }}</div>
                                <div v-if="elementContent.photo_by" class="recipe__imgPhotoBy">photo by：{{ elementContent.photo_by }}</div>
                                <div class="recipe__text contentHtml" v-html="elementContent.text"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>

            <el-col class="rightBox" :span="2" v-if="!updateOrdersMode">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', resourceName, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>

    </div>
</template>
<script>

import FeedbackForm from './Components/FeedbackForm'
import RecipeType from '../Form/RecipeType'
import * as types from '../../../../../store/mutation-types'

export default {
    data () {
        return {
            isEditing: false,
        }
    },
    computed: {
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        content() {
            return this.item.content
        },
        resourceName() {
            return 'recipes'
        },
    },
    components: {
        RecipeType,
        FeedbackForm,
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        getCookName() {
            return (this.response.article) ? this.response.article.video_article.cook_name: ''
        },
        checkItem(check,item) {
            this.parentCheckItem(check,item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    },
}
</script>
<style scoped lang="scss">
.leftBox {
    width: 45px;
}
.contents {
    width: 732px;
    line-height: 1.7em;
    font-size: 14px;
}
.rightBox {
    width: 170px;
    float: right;
}
.recipeImage {
    margin-right: 20px;
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.materialLabel div {
    width: 100%;
    margin-right: 20px;
    color: #909399;
    border-bottom: 3px solid #E3D099;
    padding-bottom: 6px;
}
.make-index {
    margin: 0px 15px 0px 0;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: #E3D099;
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    box-sizing: content-box;
    padding: 5px;
    border-radius: 50%;
}
.recipe {
    position: relative;
    &__heading {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 15px
    }
    &__elementWrapper {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__contentWrapper {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__img {
        max-width: 600px;
    }
    &__text {
        line-height: 1.7em;
        font-size: 14px;
        white-space: pre-wrap;
        word-wrap: break-word;
        margin-top: 10px;
    }
    &__type {
        position: absolute;
        top: 0;
        right: 0;
    }
    &__imgAlt {
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 5px;
    }
    &__imgPhotoBy {
        font-size: 12px;
        color: #888;
    }
    .contentHtml ::v-deep {
        a:link {
            color: #444;
            text-decoration: underline;
        }
        a:visited {
            color: #444;
            text-decoration: underline;
        }
        a:hover {
            color: #888;
            text-decoration: underline;
        }
        a:active {
            color: #888;
            text-decoration: none;
        }
    }
}
.fa-circle {
    font-size: 20px;
    vertical-align: middle;
    padding: 0 5px 0 0;
    color: #999;
}
</style>
