<template>
    <div>
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent label-width="120px" >
            <el-form-item label="引用文" >
                <el-input
                    type="textarea"
                    size="small"
                    :rows="3"
                    v-model="form.quote_text">
                </el-input>
                <text-length-count :targetText="form.quote_text" :maxTextLength="100"></text-length-count>
            </el-form-item>
            <el-form-item label="引用URL" >
                <el-input
                    type="text"
                    size="small"
                    maxlength="255"
                    v-model="form.quote_url">
                </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
    </div>
</template>

<script>

import TextLengthCount from './Components/TextLengthCount'

const defaultFormValues =  {
    quote_text:null,
    quote_url:null
}
export default {
    components: {
        TextLengthCount,
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
    },
    data () {
        return {
            form: {...defaultFormValues},
        }
    },
    methods: {
        submitForm () {
            this.save(this.resourceName, this.form, this.id)
        },
        openDialog(input) {
            this.form = Object.assign({}, defaultFormValues, _.pick(input, Object.keys(this.form)))
        },
    },
    computed: {
        resourceName() {
            return 'quotes'
        },
    }
}
</script>
