<template>
    <el-table class="list" :data="tableData" style="font-size: 12px" max-height="800">
        <el-table-column v-if="isRewrite" width="94" :render-header="renderTableHeader(['ID','記事ID'])">
            <template slot-scope="scope">
                <p v-if="scope.row.id">
                    {{ scope.row.id }}<br>
                    {{ scope.row.article_id }}
                </p>
                <el-input
                    v-else
                    type="text"
                    v-model="scope.row.article_id"
                    size="mini"
                >
                </el-input>
            </template>
        </el-table-column>
        <el-table-column v-else width="60" label="ID">
            <template slot-scope="scope" v-if="scope.row.id">
                {{ scope.row.id }}
            </template>
        </el-table-column>
        <el-table-column :min-width="226" :render-header="renderTableHeader(['SEOキーワード','親タグ','子タグ','孫タグ'])">
            <template slot-scope="scope">
                <div class="keywordsWrap">
                    <keyword-select :keywords="scope.row.keywords"/>
                </div>
                <div class="tagsWrap">
                    <tag-select
                            ref="TagSelect"
                            v-if="parentTagList.length > 0"
                            :plot="scope.row"
                            :tags="scope.row.parent_tags"
                            :input="handleInputParentTag"
                            :close="handleCloseParentTag"
                            :suggestList="parentTagList(scope.row)"
                    />
                    <el-input v-else-if="scope.row.parent_tags.length > 0" value="読込中..." size="mini" :style="{width:'80px', display:'block'}" disabled />
                    <el-input v-if="scope.row.parent_tags.length === 0" value="親タグを設定してください" size="mini" :style="{width:'200px', display:'block', margin: '5px 0 5px 0'}" disabled />
                    <tag-select
                            ref="TagSelect"
                            v-else-if="childTagList.length > 0"
                            :plot="scope.row"
                            :tags="scope.row.child_tags"
                            :input="handleInputChildTag"
                            :close="handleCloseChildTag"
                            :suggestList="childTagList(scope.row)"
                    />
                    <el-input v-else-if="scope.row.child_tags.length > 0" value="読込中..." size="mini" :style="{width:'80px', display:'block'}" disabled />
                    <el-input v-if="scope.row.child_tags.length === 0" value="子タグを設定してください" size="mini" :style="{width:'200px', display:'block', margin: '5px 0 5px 0'}" disabled />
                    <tag-select
                            ref="TagSelect"
                            v-else-if="grandchildTagList.length > 0"
                            :plot="scope.row"
                            :tags="scope.row.grandchild_tags"
                            :input="handleInputGrandchildTag"
                            :close="handleCloseGrandchildTag"
                            :suggestList="grandchildTagList(scope.row)"
                    />
                    <el-input v-else-if="scope.row.grandchild_tags.length > 0" value="読込中..." size="mini" :style="{width:'80px', display:'block'}" disabled />
                </div>
            </template>
        </el-table-column>
        <el-table-column width="160" :render-header="renderTableHeader(['カテゴリ','記事種別'])" >
            <template slot-scope="scope" >
                <el-select v-model="scope.row.category_id"
                           size="mini"
                >
                    <el-option
                        v-for="item in categoryList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="scope.row.article_type_id"
                           size="mini"
                >
                    <el-option
                        v-for="item in articleTypeList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column v-if="baseCharCountList" width="160" :render-header="renderTableHeader(['担当者','目安文字量'])">
            <template slot-scope="scope">
                <el-autocomplete
                    v-model="scope.row.admin_name"
                    type="text"
                    size="mini"
                    :fetch-suggestions="querySearchAdmin"
                    @focus="focusSuggest(scope.$index)"
                    @select="setAdminId"
                    placeholder="担当者"
                    class="suggestAdmin"
                ></el-autocomplete>
                <el-select v-model="scope.row.base_char_count"
                   size="mini"
                >
                    <el-option
                        v-for="item in baseCharCountList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column v-else width="150" :render-header="renderTableHeader(['担当者', '単価種別', '金額'])">
            <template slot-scope="scope">
                <el-autocomplete
                    v-model="scope.row.admin_name"
                    type="text"
                    size="mini"
                    :fetch-suggestions="querySearchAdmin"
                    @focus="focusSuggest(scope.$index)"
                    @select="setAdminId"
                    placeholder="担当者"
                ></el-autocomplete>
                <el-select v-model="scope.row.payment_type" size="mini" style="width: 130px; margin: 10px 0" @change="changePaymentType($event, scope.row.id)">
                    <el-option
                        v-for="item in rewritePaymentTypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <el-input
                    v-model="scope.row.article_unit_price"
                    placeholder="金額"
                    type="number"
                    size="mini"
                    min="0"
                    :disabled="scope.row.payment_type !== 'ARTICLE'"
                ></el-input>
            </template>
        </el-table-column>
        <el-table-column width="300" label="プロット" >
            <template slot-scope="scope">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 1, maxRows: 10}"
                    :cols="40"
                    v-model="scope.row.plot">
                </el-input>
            </template>
        </el-table-column>
        <el-table-column :width="130" label="操作">
            <template slot-scope="scope" >
                <div v-if="scope.row.id">
                    <el-button
                        class="btnEdit unitBtn"
                        size="mini"
                        @click="doAssign(scope.row)"
                    >ライター割当</el-button>
                    <el-button
                        class="btnEdit unitBtn"
                        size="mini"
                        type="warning"
                        @click="doUpdate(scope.row)"
                    >更新</el-button>
                    <el-button
                        class="btnDelete unitBtn"
                        size="mini"
                        type="danger"
                        @click="doDelete(scope.row)"
                    >削除</el-button>
                </div>
                <div v-else>
                    <el-button
                        class="unitBtn"
                        size="mini"
                        type="primary"
                        @click="doCreate(scope.row)">
                        新規作成
                    </el-button>
                </div>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import TagSelect     from '../TagSelect'
import KeywordSelect from '../KeywordSelect'
import * as types    from '../../store/mutation-types'
export default {
    name: 'PlotRow',
    components: {
        TagSelect,
        KeywordSelect,
    },
    props: {
        categoryList: Array,
        articleTypeList: Array,
        baseCharCountList: {
            type: Array,
            required: false
        },
        tableData: Array,
        update: Function,
        assignWriter: Function,
        deleteRow: Function,
        create: Function,
        isRewrite: false,
        tagList: Array,
        rewritePaymentTypes: {
            type: Array,
            required: false,
        }
    },
    data () {
        return {
            focusedLineIndex: null
        }
    },
    computed:{
        parentTagList() {
            return (plot) => {
                return this.tagList.map(tag => ({id: tag.id, label: tag.tag_name}))
            }
        },
        childTagList() {
            return (plot) => {
                return this.tagList
                    .filter(parentTag => plot.parent_tags.some(selectParentTag => selectParentTag.id === parentTag.id))
                    .flatMap(parentTag => parentTag.children)
                    .map(childTag => {
                        const index = plot.parent_tags.findIndex(parentTag => parentTag.id === childTag.parent_tag_id)
                        return {id: childTag.id, label: childTag.tag_name, parent: plot.parent_tags[index].tag_name}
                    })
            }
        },
        grandchildTagList() {
            return (plot) => {
                return this.tagList
                    .filter(parentTag => plot.parent_tags.some(selectParentTag => selectParentTag.id === parentTag.id))
                    .flatMap(parentTag => parentTag.children)
                    .filter(childTag => plot.child_tags.some(selectChildTag => selectChildTag.id === childTag.id))
                    .flatMap(child => child.grandchildren)
                    .map(grandchildTag => {
                        const childTagIndex = plot.child_tags.findIndex(childTag => childTag.id === grandchildTag.child_tag_id)
                        const parentTagIndex = plot.parent_tags.findIndex(parentTag => {
                            const childTag = this.tagList.flatMap(parentTag => parentTag.children).filter(tag => tag.id === plot.child_tags[childTagIndex].id)[0]
                            return parentTag.id === childTag.parent_tag_id
                        })
                        return {id: grandchildTag.id, label: grandchildTag.tag_name, parent: plot.parent_tags[parentTagIndex].tag_name, child: plot.child_tags[childTagIndex].tag_name}
                    })
            }
        },
    },
    methods: {
        doUpdate (form) {
            this.update(form)
        },
        doAssign (form) {
            this.assignWriter(form)
        },
        doDelete (form) {
            this.deleteRow(form)
        },
        doCreate (form) {
            this.create(form)
        },
        async querySearchAdmin(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/admins/suggest/editor', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, '管理者検索に失敗しました')
                    return
                }
                cb(data.admins)
            }
        },
        focusSuggest(index) {
            this.focusedLineIndex = index
        },
        setAdminId(item) {
            this.tableData[this.focusedLineIndex]['admin_id'] = item.admin_id
        },
        handleInputParentTag(plot, value) {
            if (value && plot.parent_tags.findIndex(item => item.id === value) === -1) {
                const tag = this.tagList.find(item => item.id === value)
                plot.parent_tags.push({id: tag.id, tag_name: tag.tag_name})
            }
        },
        handleInputChildTag(plot, value) {
            if (value && plot.child_tags.findIndex(item => item.id === value) === -1) {
                const tag = this.tagList.flatMap(parentTag => parentTag.children).find(item => item.id === value)
                plot.child_tags.push({id: tag.id, tag_name: tag.tag_name})
            }
        },
        handleInputGrandchildTag(plot, value) {
            if (value && plot.grandchild_tags.findIndex(item => item.id === value) === -1) {
                const tag = this.tagList.flatMap(parentTag => parentTag.children)
                    .flatMap(child => child.grandchildren)
                    .find(item => item.id === value)
                plot.grandchild_tags.push({id: tag.id, tag_name: tag.tag_name})
            }
        },
        handleCloseParentTag(plot, index) {
            if (plot.parent_tags.length === 1) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '親タグは必須です。')
                return
            }
            this.tagList.filter(tag => {
                return (plot.parent_tags[index].id === tag.id)
            }).forEach(parentTag => {
                parentTag.children.forEach(childTag => {
                    const index = plot.child_tags.findIndex(tag => tag.id === childTag.id)
                    if (index !== -1) {
                        plot.child_tags.splice(index, 1)
                    }
                    childTag.grandchildren.forEach(grandchildTag => {
                        const index = plot.grandchild_tags.findIndex(tag => tag.id === grandchildTag.id)
                        if (index !== -1) {
                            plot.grandchild_tags.splice(index, 1)
                        }
                    })
                })
            })
            plot.parent_tags.splice(index, 1)
        },
        handleCloseChildTag(plot, index) {
            this.tagList.filter(parentTag => {
                const childTag = parentTag.children.filter(childTag => plot.child_tags[index].id === childTag.id)
                return (childTag.length !== 0)
            }).forEach(parentTag => {
                parentTag.children.forEach(childTag => {
                    childTag.grandchildren.forEach(grandchildTag => {
                        const index = plot.grandchild_tags.findIndex(tag => tag.id === grandchildTag.id)
                        if (index !== -1) {
                            plot.grandchild_tags.splice(index, 1)
                        }
                    })
                })
            })
            plot.child_tags.splice(index, 1)
        },
        handleCloseGrandchildTag(plot, index) {
            plot.grandchild_tags.splice(index, 1)
        },
        changePaymentType(paymentType, plotId) {
            if (paymentType === 'CHARACTER') {
                this.$emit('initArticleUnitPrice', plotId)
            }
        }
    },
}
</script>

<style>
    .el-table td, th{
        vertical-align: top !important;
    }
</style>


<style scoped>
    .el-button {
        margin: 5px 0 5px 10px;
    }

    .unitBtn {
        width: 100%;
    }

    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .el-select, .suggestAdmin {
        margin: 5px 0 5px 10px;
    }
    .keywordsWrap {
        border-bottom : 2px solid #EBEEF5;
    }
    .tagsWrap {
        padding-top: 5px;
    }

</style>
