<template>
    <div>
        <el-row type="flex" justify="end">
            <el-input class="fetch-input" placeholder="単位名" v-model="searchForm.name" @keyup.enter.native="search(1)"></el-input>
            <el-button @click="search(1)" icon="el-icon-search" class="search-input" style="height: 40px;"></el-button>
        </el-row>

        <pagination :handleCurrentChange="search" :pagination="pagination"/>

        <el-row type="flex" style="margin: 30px 0;">
            <el-input v-model="createForm.name" placeholder="単位名" class="create-form-input" style="width: 300px; margin-right: 10px;"></el-input>
            <el-button class="add-button create-form-input" type="primary" @click="create">新規作成</el-button>
        </el-row>

        <el-table
            :data="suggestUnits"
            style="width: 100%"
        >
            <el-table-column label="単位名" width="500">
                <template slot-scope="scope">
                    <el-input placeholder="単位名" v-model="scope.row.name"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="更新" width="100">
                <template slot-scope="scope">
                    <el-button
                        class="btn-warning"
                        type="warning"
                        @click="update(scope.row)"
                    >更新
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column label="削除" width="100">
                <template slot-scope="scope">
                    <el-button
                        class="btn-danger"
                        type="danger"
                        @click="deleteSuggestUnit(scope.row)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>

            <el-table-column></el-table-column>
        </el-table>

        <pagination :handleCurrentChange="search" :pagination="pagination"/>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'

export default {
    components: {
        Pagination,
    },
    data() {
        return {
            searchForm: {
                name: '',
            },
            createForm: {
                name: '',
            },
            pagination: {},
            suggestUnits: [],
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '記事作成データ管理　>　材料単位')
        this.searchForm.name = this.$route.query.name ? this.$route.query.name : ''
        this.fetch()
    },
    methods: {
        // 検索
        async fetch() {
            this.$store.commit(types.LOADING, true)

            const page = this.$route.query.page ? this.$route.query.page : 1
            const name = this.$route.query.name ? this.$route.query.name : ''

            const {data, error} = await axios.get('/api/suggests/units', {
                params: {
                    page: page,
                    name: name,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.pagination = Object.assign({}, data)
            this.suggestUnits = Object.assign([], data.data)

            this.$store.commit(types.LOADING, false)
        },
        search(page) {
            this.$router.push({
                query: {
                    page: page,
                    name: this.searchForm.name,
                }
            })
            this.fetch()
        },
        async update(suggestUnit) {
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.put('/api/suggests/units/'+suggestUnit.id, {
                id: suggestUnit.id,
                name: suggestUnit.name,
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.fetch()
        },
        async create() {
            this.$store.commit(types.LOADING, true)

            const { error } = await axios.post('/api/suggests/units', this.createForm)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.createForm = {
                name: '',
            }

            this.fetch()
        },
        async deleteSuggestUnit(suggestUnit) {
            if (! confirm('本当に削除しますか？')) {
                return
            }

            this.$store.commit(types.LOADING, true)

            const { error } = await axios.delete('/api/suggests/units/'+suggestUnit.id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.fetch()
        }
    }
}
</script>

<style lang="scss" scoped>
    .fetch-input {
        width: 300px;
        margin-right: 20px;
    }
</style>
