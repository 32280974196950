<template>
    <el-row class="headerTabWrapper">
        <div class="headerTabs">
            <div :class="tabClass('text')" @click="selectTab('text')"><i class="fas fa-pencil-alt fa-2x"></i><div class="itemSelectTab">テキスト</div></div>
            <div :class="tabClass('image')" @click="selectTab('image')"><i class="fas fa-camera-retro fa-2x"></i><div class="itemSelectTab">画像</div></div>
            <div :class="tabClass('link')" @click="selectTab('link')"><i class="fas fa-link fa-2x"></i><div class="itemSelectTab">リンク</div></div>
            <div :class="tabClass('video')" @click="selectTab('video')"><i class="fab fa-youtube fa-2x"></i><div class="itemSelectTab">動画</div></div>
            <div :class="tabClass('place')" @click="selectTab('place')"><i class="fas fa-store fa-2x"></i><div class="itemSelectTab">店舗情報</div></div>
        </div>
        <div class="headerForm" v-if="selectedTab !== null">
            <text-type
                v-if="selectedTab === 'text'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel" />
            <image-type
                v-if="selectedTab === 'image'"
                class="headerItemAddBox"
                :articleFormat="articleFormat"
                :save="create"
                :saveAndContinue="createAndContinue"
                :is-tieup="false"
                @close="closeMenuOfCancel" />
            <link-type
                v-if="selectedTab === 'link'"
                class="headerItemAddBox"
                :articleFormat="articleFormat"
                :saveAndContinue="createAndContinue"
                :save="create"
                @close="closeMenuOfCancel" />
            <video-type
                v-if="selectedTab === 'video'"
                class="headerItemAddBox"
                :save="create"
                @close="closeMenuOfCancel" />
            <place-type
                v-if="selectedTab === 'place'"
                class="headerItemAddBox"
                :save="create"
                :article-id="response.article.id"
                @close="closeMenuOfCancel"
            />
        </div>
    </el-row>
</template>

<script>
import TextType from '../Form/TextType'
import ImageType from '../Form/ImageType'
import LinkType from '../Form/LinkType'
import VideoType from '../Form/VideoType'
import PlaceType from '../Form/PlaceType'

export default {
    components: {
        TextType,
        ImageType,
        LinkType,
        VideoType,
        PlaceType,
    },
    props: {
        articleFormat: String,
        response: null,
        isTieup: Boolean,
    },
    data () {
        return {
            selectedTab: null,
        }
    },
    methods: {
        tabClass(active) {
            let className = 'headerTab'
            if (active === this.selectedTab) {
                className += ' active'
            }
            return className
        },
        selectTab(tab) {
            this.selectedTab = tab
        },
        closeMenu () {
            this.selectedTab = null
        },
        closeMenuOfCancel () {
            if (!confirm('キャンセルしますか？')) {
                return
            }
            this.selectedTab = null
        },
        async create (type, form) {
            this.$emit('create', type, form)
        },
        async createAndContinue (type, form) {
            this.$emit('createAndContinue', type, form)
        },
    },
}
</script>
