<template>
    <div>
        <el-row >
            <el-form class="itemForm" @submit.native.prevent="startSearchKeyword" >
                <el-col :span="5">
                    <el-form-item>
                        <el-select v-model="order" size="mini">
                            <el-option
                                v-for="item in orderType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="19">
                    <el-form-item style="width:412px;">
                        <el-input
                            width="50%"
                            type="text"
                            size="mini"
                            placeholder="楽天市場検索"
                            v-model="keyword">
                            <el-button
                                native-type="submit"
                                slot="append"
                                icon="el-icon-search"></el-button>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-row >
        <el-row v-for="(rowData, rowIndex) in arrengedList" :key="rowIndex" >
            <el-col :span="6" v-for="(data, index) in rowData" :key="index">
                <div class="thumbnails">
                    <img :src="data.image" >
                    <div class="thumbnailText">
                        ￥{{ data.price }}~<br>
                        {{ data.name }}
                    </div>
                    <div class="addButton">
                        <el-button type="primary" :disabled="data.added" size="mini" @click="addItem(data, index)">
                            <span v-if="data.added">
                                追加済み
                            </span>
                            <span v-else>
                                追加する
                            </span>
                        </el-button>
                    </div>
                </div>
            </el-col>
        </el-row >
        <el-row v-if="list.length > 0" type="flex" justify="center">
            <el-button style="width:80%;margin-top:10px;" @click="searchMore">次の画像を読み込む</el-button>
        </el-row >
        <div v-if="loading" v-loading="loading" class="loading"></div>
    </div>
</template>
<script>
import * as types from '../../../../../../../store/mutation-types'
export default {
    name:'ItemImageRakuten',
    data () {
        return {
            list: [],
            page: 1,
            orderType:[],
            order:null,
            loading:false,
        }
    },
    async created() {
        const {data,error} = await axios.get('/api/cooperation/rakuten/search/option')
        this.orderType = data.order_type
        this.order = this.orderType[0].value
    },
    props:{
        parentKeyword:null,
        arrengeList: Function,
        imageType: String,
    },
    computed: {
        keyword: {
            get() {
                return this.parentKeyword
            },
            set(keyword) {
                this.$emit('updateKeyword',keyword)
            }
        },
        arrengedList() {
            return this.arrengeList(this.list)
        },
    },
    methods: {
        async searchKeyword(){
            this.loading = true
            const {data,error} = await axios.get('/api/cooperation/rakuten/search', {
                params: {
                    keyword:this.keyword,
                    page:this.page,
                    order_type: this.order
                }
            })
            if (error) {
                this.$store.commit(
                    types.SHOW_ERROR_MESSAGE,
                    (error.response.status < 500 && error.response.data.message) || '検索に失敗しました'
                )
                this.loading = false
                return
            }
            this.list = Object.assign([], this.list.concat(data.images))
            this.loading = false
        },
        searchMore() {
            this.page++
            this.searchKeyword()
        },
        startSearchKeyword() {
            this.page = 1
            this.list = []
            this.searchKeyword()
        },
        addItem(data, index) {
            data.added = true
            this.$set(this.list, index, Object.assign({}, data, {added: true}))
            if (this.imageType === 'item') {
                this.$emit('save','Rakuten',{
                    quote_image_url: data.image,
                    quote_url: data.link,
                    image_title: data.name,
                })
            } else if (this.imageType === 'icon') {
                this.$emit('saveIcon', {
                    thumbnail_quote_image_url: data.image,
                    quote_image_url: data.link,
                })
            } else if (this.imageType ===  'affiItem') {
                this.$emit('save','Rakuten',{
                    affi_thumbnail_url: data.image,
                    rakuten_price: data.price,
                    rakuten_url: data.link,
                })
            }
        }
    }
}

</script>
