<template>
    <div>
        <el-row class="sumTable">
            <el-col >
                <el-table v-loading="sumLoading" :data="sumTableData" >
                    <el-table-column label="合計記事数">
                        <template slot-scope="scope">
                            <b>{{scope.row.articles_count | number}}</b>
                        </template>
                    </el-table-column>
                    <el-table-column label="合計リライト数">
                        <template slot-scope="scope">
                            <b>{{scope.row.rewrites_count | number}}</b>
                        </template>
                    </el-table-column>
                    <el-table-column label="合計金額">
                        <template slot-scope="scope">
                            <b>{{scope.row.result | number}}円</b>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row class="controllRow" type="flex" justify="end">
            <el-button type="warning" @click="downloadCSV" icon="el-icon-download">
                CSVダウンロード
            </el-button>
        </el-row>
        <el-row class="controllRow" type="flex" justify="end">
            <search-box :placeholder="'ライター名で検索'"  />
        </el-row>
        <el-row class="controllRow" type="flex" justify="end">
            <el-checkbox v-model="hideAllZeroWriter">記事数,リライト数,金額がいずれも0のライターを非表示にする</el-checkbox>
        </el-row>
        <el-row class="controllRow" type="flex" justify="end">
            {{tableData.length}}名のライターを表示中
        </el-row>
        <el-row>
            <el-col >
                <el-table :data="tableData" max-height="800">
                    <el-table-column prop="writer.writer_name" label="ライター名"/>
                    <el-table-column prop="grade.name" label="グレード"/>
                    <el-table-column prop="articles_count" label="先月の作成記事本数"/>
                    <el-table-column prop="rewrites_count" label="先月の作成リライト本数"/>
                    <el-table-column
                        label="金額入力"
                        width="180">
                        <template slot-scope="scope">
                            <el-button plain @click="editRow(scope.row)" >金額入力へ</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="金額">
                        <template slot-scope="scope">
                            <b>{{scope.row.result | number}}円</b>
                        </template>
                    </el-table-column>
                    <el-table-column label="社内メモ">
                        <template slot-scope="scope">
                            <div style="white-space: break-spaces;">{{ scope.row.writer.fee_comment }}</div>
                            <div v-if="scope.row.writer.fee_comment_updated_at !== null" style="font-size: 12px;">最終更新：{{ scope.row.writer.fee_comment_updated_at }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="編集">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="openCommentEditModal(scope.row.writer)">編集</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>

        <comment-edit-modal
            :dialog="isActiveCommentEditModal"
            :writer="editWriter"
            @save="updateFeeComment"
            @closeDialog="closeCommentEditModal"
        ></comment-edit-modal>
    </div>
</template>

<script>
import * as types from '../../../store/mutation-types'
import SearchBox from '../../../components/SearchBox'
import CommentEditModal from './Monthly/CommentEditModal'
import FileDownload from 'js-file-download'
import moment from 'moment'

export default {
    components: {
        SearchBox,
        CommentEditModal
    },
    data() {
        return {
            tableDataOrignal: [],
            sumTableData: [],
            sumLoading: true,
            hideAllZeroWriter: true,
            isActiveCommentEditModal: false,
            editWriter: {},
        }
    },
    created() {
        this.fetchSum()
        this.fetch()
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    computed: {
        tableData() {
            return this.tableDataOrignal.filter((data) => {
                if (this.hideAllZeroWriter === false) {
                    return data
                }
                return data.articles_count > 0 || data.rewrites_count > 0 || data.result > 0
            })
        }
    },
    methods: {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)
            const page = this.$route.query.page
            const keyword = this.$route.query.keyword
            const yearMonth = this.$route.query.year_month
            const { data, error } = await axios.get('/api/writers/fees/monthly', {
                params: { page, keyword, year_month: yearMonth}
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.$store.commit(types.PAGE_LOADING, false)
            this.tableDataOrignal = Object.assign([],data.fees)

            const titleLabel = yearMonth ?  moment(yearMonth).format('YYYY-MM') + 'の' : '先月発生した'
            this.$store.commit(types.PAGE_TITLE, titleLabel + 'ライター費用一覧')
        },
        async fetchSum() {
            const keyword = this.$route.query.keyword
            const yearMonth = this.$route.query.year_month
            const { data, error } = await axios.get('/api/writers/fees/monthly/sum', {
                params: { keyword, year_month: yearMonth}
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.sumLoading = false
                return
            }
            this.sumTableData.push({result: data.result, articles_count: data.articles_count, rewrites_count: data.rewrites_count})
            this.sumLoading = false
        },
        editRow (item) {
            this.$router.push('/writers/' + item.writer_id + '/fees')
        },
        handleCurrentChange (page) {
            this.$router.push({query: {page}})
        },
        async downloadCSV() {
            this.$store.commit(types.PAGE_LOADING, true)
            const { data, error } = await axios.get('/api/writers/fees/csv')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            const bom  = new Uint8Array([0xEF, 0xBB, 0xBF])
            FileDownload(data, 'writers_fees_monthly.csv', null, bom)
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async updateFeeComment(writerId, params) {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data, error } = await axios.put('/api/writers/fee_comment/'+writerId, params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            this.closeCommentEditModal()
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, '更新が完了しました。')

            this.$store.commit(types.PAGE_LOADING, false)
        },
        openCommentEditModal(writer) {
            this.isActiveCommentEditModal = true
            this.editWriter = Object.assign({}, writer)
        },
        closeCommentEditModal() {
            this.isActiveCommentEditModal = false
        },
    }
}
</script>
<style scoped>
    .sumTable {
        margin-bottom:40px;
    }
    .controllRow {
        margin-bottom:20px;
    }
</style>
