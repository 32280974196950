<template>
    <div>
        <el-table :data="categories">
            <el-table-column label="カテゴリ名" prop="category_name" width="200px" />
            <el-table-column label="ディスクリプション" prop="category_description" width="500px" />
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                        type="primary"
                        size="mini"
                        @click="$router.push(`/categories/${scope.row.id}`)"
                    >編集</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'

export default {
    data() {
        return {
            categories: [],
        }
    },
    async created() {
        this.$store.commit(types.PAGE_TITLE, '編成　>　カテゴリー')
        await this.fetch()
    },
    methods: {
        async fetch() {
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/categories')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.categories = data.categories.slice()
            this.$store.commit(types.LOADING, false)
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
