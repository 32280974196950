<template>
    <el-table class="list" :data="cloneTableData" style="font-size: 12px;vertical-align: top;" max-height="800">
        <el-table-column width="60" size="mini" label="記事ID" >
            <template slot-scope="scope">
                {{ scope.row.rewrite.origin_article_id }}
           </template>
        </el-table-column>
        <el-table-column size="small" label="リライトライター名" >
            <template slot-scope="scope">
                {{ scope.row.rewrite.writer ? scope.row.rewrite.writer.writer_name : 'ライター未設定' }}
            </template>
        </el-table-column>
        <el-table-column size="small" label="元記事公開ライター名" >
            <template slot-scope="scope">
                {{ scope.row.rewrite.origin_article.publish_writer.writer_name }}
            </template>
        </el-table-column>
        <el-table-column width="170" label="公開ライター名" >
            <template slot-scope="scope" v-if="canChange(scope.row)">
                {{ scope.row.rewrite.rewrite_article.publish_writer.writer_name }}
            </template>
        </el-table-column>

        <el-table-column size="small" label="記事タイトル" >
            <template slot-scope="scope">
                {{ scope.row.rewrite.rewrite_article ? scope.row.rewrite.rewrite_article.title : scope.row.rewrite.origin_article.title}}
            </template>
        </el-table-column>

        <el-table-column size="small" width="135" :render-header="renderTableHeader(['CCD最新日時','CCD送信日時','CCDステータス','CCD回数'])" >
            <template slot-scope="scope">
                {{ (scope.row.rewrite.rewrite_article && scope.row.rewrite.rewrite_article.ccd && scope.row.rewrite.rewrite_article.ccd.last_checked_at) ? scope.row.rewrite.rewrite_article.ccd.last_checked_at : '-' }}
                <br>
                {{ (scope.row.rewrite.rewrite_article && scope.row.rewrite.rewrite_article.ccd && scope.row.rewrite.rewrite_article.ccd.created_at) ? scope.row.rewrite.rewrite_article.ccd.created_at : '-' }}
                <br>
                <template v-if="scope.row.ccd_status === 100">
                    <el-select v-model="scope.row.rewrite.rewrite_article.ccd.status" size="mini">
                        <el-option
                            v-for="(item) in ccdEditableStatus"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </template>
                <template v-else>
                    {{ scope.row.rewrite.rewrite_article && scope.row.rewrite.rewrite_article.ccd && scope.row.rewrite.rewrite_article.ccd.status !== null ? ccdStatusLabelList[scope.row.rewrite.rewrite_article.ccd.status] : '-' }}
                    <br>
                </template>
                {{ (scope.row.rewrite.rewrite_article && scope.row.rewrite.rewrite_article.ccd && scope.row.rewrite.rewrite_article.ccd.check_count) ? scope.row.rewrite.rewrite_article.ccd.check_count : '-' }}
            </template>
        </el-table-column>

        <el-table-column size="small" width="120" :render-header="renderTableHeader(['単価種別', '金額'])" >
            <template slot-scope="scope">
                <el-select
                    v-model="scope.row.rewrite.payment_type"
                    size="mini"
                    style="width: 100px; margin: 5px 0 10px 0"
                    @change="changePaymentType($event, scope.row.id)"
                >
                    <el-option
                        v-for="item in rewritePaymentTypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <el-input
                    v-model="scope.row.rewrite.article_unit_price"
                    placeholder="金額"
                    type="number"
                    size="mini"
                    min="0"
                    :disabled="scope.row.rewrite.payment_type !== 'ARTICLE'"
                ></el-input>
            </template>
        </el-table-column>

        <el-table-column size="small" width="120" :render-header="renderTableHeader(['編集者','編集ステータス'])" >
            <template slot-scope="scope">
                <el-autocomplete
                    v-model="scope.row.editor_name"
                    type="text"
                    size="mini"
                    :fetch-suggestions="querySearchAdmin"
                    @focus="focusSuggest(scope.$index)"
                    @select="setAdminId"
                    placeholder="編集者"
                ></el-autocomplete>
                <br>
                <el-select v-model="scope.row.status" size="mini">
                    <el-option
                        v-for="(item) in statusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column :render-header="renderTableHeader(['リライト後文字数','after構造化個数'])">
            <template slot-scope="scope">
                <div>{{ scope.row.rewrite.article_char_count.payment_char_count }}</div>
                <div>{{ scope.row.rewrite.after_shop_item_count === null ? '未集計' : scope.row.rewrite.after_shop_item_count }}</div>
            </template>
        </el-table-column>
        <el-table-column width="135" size="medium" :render-header="renderTableHeader(['最終編集日時','支払いチェック'])"  >
            <template slot-scope="scope">
                {{ scope.row.updated_at }}
                <div v-if="scope.row.rewrite.rewrite_article">
                    <el-button
                        class="btnEdit checkBtn"
                        type="primary"
                        size="mini"
                        @click="doPaymentCheck(scope.row)"
                    >支払いチェック</el-button>
                </div>
                <div v-if="scope.row.payment_check">
                    {{scope.row.payment_check.created_at}}<br />
                    {{scope.row.payment_check.char_count}}文字
                </div>
            </template>
        </el-table-column>
        <el-table-column width="280" label="コメント" >
            <template slot-scope="scope">
                <el-input
                    type="textarea"
                    :rows="10"
                    :cols="40"
                    v-model="scope.row.comment">
                </el-input>
            </template>
        </el-table-column>
        <el-table-column size="small" label="操作" align="center">
            <template slot-scope="scope">
                <el-button
                    class="btnEdit unitBtn"
                    type="warning"
                    size="mini"
                    :disabled="isCompleted(scope.row)"
                    @click="doUpdate(scope.row)"
                >更新</el-button>
                <a :href="addArticleUrl(scope.row.rewrite.rewrite_article && !scope.row.rewrite.completed_at
                    ? scope.row.rewrite.rewrite_article.id : scope.row.rewrite.origin_article.id)" target="_blank" >記事作成</a>
                <br>
                <a v-if="scope.row.preview_url !== ''" :href="scope.row.preview_url" target="_blank">プレビュー</a>

            </template>
        </el-table-column>
    </el-table>
</template>

<style>
    .el-table td, th{
        vertical-align: top !important;
    }
</style>

<script>
export default {
    name: 'CheckRewriteRow',
    props: {
        tableData: Array,
        ccdStatusLabelList: Array,
        ccdEditableStatus: Array,
        statusList: Array,
        update: Function,
        paymentCheck: Function,
        rewritePaymentTypes: {
            type: Array,
            required: false,
        },
    },
    data () {
        return {
            focusedLineIndex: null,
            cloneTableData: [],
        }
    },
    watch: {
        tableData() {
            this.cloneTableData = structuredClone(this.tableData)
        }
    },
    methods: {
        isArticleAdd (status) {
            return (status === 100)
        },
        doUpdate (form) {
            this.update(form)
        },
        doPaymentCheck (form) {
            this.paymentCheck(form)
        },
        addArticleUrl (articleId) {
            return '/articles/' + articleId
        },
        async querySearchAdmin(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/admins/suggest/editor', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, '管理者検索に失敗しました')
                    return
                }
                cb(data.admins)
            } else if (typeof this.cloneTableData[this.focusedLineIndex]['rewrite']['admin_id'] === 'number') {
                this.cloneTableData[this.focusedLineIndex]['rewrite']['admin_id'] = null
            }
        },
        focusSuggest(index) {
            this.focusedLineIndex = index
        },
        setAdminId(item) {
            this.cloneTableData[this.focusedLineIndex]['rewrite']['admin_id'] = item.admin_id
        },
        isCompleted(item) {
            return (item.rewrite.completed_at !== null)
        },
        canChange(item) {
            return (item.rewrite.rewrite_article_id !== null)
        },
        changePaymentType(paymentType, rewriteCheckId) {
            if (paymentType === 'CHARACTER') {
                const findRowIdx = this.cloneTableData.findIndex(value => value.id === rewriteCheckId)
                this.$set(this.cloneTableData[findRowIdx].rewrite, 'article_unit_price', null)
            }
        },
    }
}
</script>

<style scoped>
    .el-button {
        margin: 5px 0 5px 10px;
    }

    .unitBtn {
        width: 100%;
    }
    .checkBtn {
        margin-top:20px;
        margin-left:0;
        width: 100%;
    }
    .list {
        vertical-align: top;
    }

    .el-select {
        margin: 5px 0 5px 10px;
    }
    .el-autocomplete {
        margin: 5px 0 5px 10px;
    }
</style>
