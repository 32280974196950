<template>
    <div>
        <!-- TODO spatieエラーログ確認用 -->
        <div>※システムデバッグ用です</div>
        <el-button @click="debugSpatie">spatieログデバッグ</el-button>
        <el-button @click="debugVendor">vendorログデバッグ</el-button>
    </div>
</template>

<script>
import { PAGE_TITLE } from '../store/mutation-types'
export default {
    created() {
        this.$store.commit(PAGE_TITLE, 'ダッシュボード')
    },
    methods: {
        async debugSpatie() {
            await axios.get('/api/confirm_spatie_log')
        },
        async debugVendor() {
            await axios.get('/api/confirm_vendor_map')
        },
    }
}
</script>
