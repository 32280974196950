<template>
    <div>
        <el-row type="flex" justify="end">
            <div class="search-form">
                <el-input v-model="searchForm.title" placeholder="連載のタイトル">
                    <el-button
                            @click="search"
                            slot="append"
                            icon="el-icon-search">
                    </el-button>
                </el-input>
            </div>
        </el-row>
        <div class="serialization">
            <el-table
                    :data="serializations"
                    style="width: 100%; font-size: 12px;"
                    max-height="800"
            >
                <el-table-column label="ID" width="50px">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.id"></div>
                        <router-link v-else :to="'/organizations/serialization/' + scope.row.id">{{ scope.row.id }}</router-link>
                    </template>
                </el-table-column>
                <el-table-column label="タイトル">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.title" size="mini"></el-input>
                     </template>
                </el-table-column>
                <el-table-column label="説明">
                    <template slot-scope="scope">
                        <el-input type="textarea" :rows="3" size="mini" v-model="scope.row.description"></el-input>
                        <p class="serialization__description-length">{{ getDescriptionLength(scope.row) }}</p>
                     </template>
                </el-table-column>
                <el-table-column label="バナー" width="130px">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.id">
                            <el-upload
                                action=""
                                accept="image/png,image/jpeg,image/gif"
                                list-type="picture"
                                class="topic-banner"
                                :show-file-list="false"
                                :auto-upload="false"
                                :on-change="handleBannerAdd"
                                :on-remove="handleBannerRemove"
                            >
                                <img v-if="scope.row.thumbnail" :src="scope.row.thumbnail_preview" class="thumbnail-img">
                                <el-button v-else size="small" type="primary">画像選択</el-button>
                            </el-upload>
                        </div>
                        <div v-else>
                            <label :for="'image_' + scope.row.id">
                                <img v-if="scope.row.thumbnail" :src="scope.row.thumbnail_preview" class="thumbnail-img">
                                <el-button v-else size="small" type="primary">画像選択</el-button>
                                <input type="file" accept="image/png,image/jpeg,image/gif" style="display: none;" :id="'image_' + scope.row.id" @change="selectedFile"/>
                            </label>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :render-header="renderTableHeader(['開始日時', '公開終了日時'])" width="230px">
                    <template slot-scope="scope">
                        <el-date-picker
                            v-model="scope.row.publish_start_at"
                            type="datetime"
                            size="mini"
                            value-format="yyyy-MM-dd HH:mm:ss"
                        ></el-date-picker>
                        <el-date-picker
                            v-model="scope.row.publish_end_at"
                            type="datetime"
                            size="mini"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            style="margin-top: 5px;"
                        ></el-date-picker>
                     </template>
                </el-table-column>
                <el-table-column label="レシピ" width="50px">
                    <template slot-scope="scope">
                        <el-checkbox
                            v-model="scope.row.is_video"
                        ></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column label="公開" width="50px">
                    <template slot-scope="scope">
                        <el-checkbox
                            v-model="scope.row.is_active"
                        ></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column label="作成日時" prop="created_at" width="135px"></el-table-column>
                <el-table-column align="right" width="80px">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.id" class="serialization__button">
                            <el-button
                                size="mini"
                                @click="createSerialization(scope.row)"
                            >登録</el-button>
                        </div>
                        <div v-else class="serialization__button">
                            <el-button
                                size="mini"
                                @click="updateSerialization(scope.row)"
                            >更新
                            </el-button>
                            <br>
                            <el-button
                                size="mini"
                                type="danger"
                                @click="deleteSerialization(scope.row.id)"
                            >削除
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :handleCurrentChange="search" :pagination="pagination" />
        </div>

    </div>
</template>

<script>
import { PAGE_TITLE , LOADING } from '../../store/mutation-types'
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'

export default {
    components: {
        Pagination
    },
    data() {
        return {
            serializations: [],
            pagination: {},
            searchForm: {
                title: '',
            },
            thumbnailPreview: '',
        }
    },
    created() {
        this.$store.commit(PAGE_TITLE, '編成　>　連載')
        this.init()
    },
    methods: {
        init() {
            // 初回検索
            this.search()
        },
        // 検索
        async search(page = 1) {
            this.$store.commit(LOADING, true)
            const { data, error } = await axios.get('/api/serializations', {
                params: {
                    page,
                    title: this.searchForm.title
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            this.pagination = Object.assign({},this.pagination, data)

            let serializationData = []
            // 1ページ目以外は新規作成行を表示しない
            if (this.pagination.current_page === 1) {
                serializationData.unshift({
                    title: '',
                    description: '',
                    thumbnail: '',
                    thumbnail_preview: '',
                    publish_start_at: '',
                    publish_end_at: '',
                    is_active: '',
                    is_video: '',
                })
            }
            let datalist = Object.entries(data.data).map(([key, data]) => {
                return {
                    id: data.id,
                    title: data.title,
                    description: data.description,
                    thumbnail: data.thumbnail_cdn_url,
                    thumbnail_preview: data.thumbnail_cdn_url,
                    publish_start_at: data.publish_start_at,
                    publish_end_at: data.publish_end_at,
                    is_active: data.is_active,
                    is_video: data.is_video,
                }
            })
            serializationData = serializationData.concat(datalist)
            this.serializations = serializationData
            this.$store.commit(LOADING, false)
        },
        // 登録
        async createSerialization(serialization) {
            this.$store.commit(types.LOADING, true)
            const fd = this.getFormData(serialization)
            const { error } = await axios.post('/api/serializations', fd )

            this.$store.commit(types.LOADING, false)
            if (error) {
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, '登録しました。')
            this.thumbnailPreview = ''

            this.search()

        },
        // 連載の新規作成/更新
        async updateSerialization(serialization) {
            this.$store.commit(types.LOADING, true)
            const fd = this.getFormData(serialization)
            // PUTだとformDataが空になるため、一旦postにし、optionでPUT指定します
            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PUT'
                }
            }
            const { error } = await axios.post('/api/serializations/'+serialization.id, fd, config)
            this.$store.commit(types.LOADING, false)

            if (error) {
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, '更新しました。')
            this.thumbnailPreview = ''
            this.search()
        },
        // 削除
        async deleteSerialization(id) {
            if (!confirm('本当に削除しますか？')) {
                return
            }
            this.$store.commit(LOADING, true)
            const { error } = await axios.delete('/api/serializations/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                return
            }
            this.$store.commit(LOADING, false)

            this.init()
        },
        // バナー画像選択時
        handleBannerAdd(file, fileList) {
            this.serializations[0].thumbnail = file.raw
            this.serializations[0].thumbnail_preview = file.url
        },
        // バナー画像削除時
        handleBannerRemove(file, fileList) {
            this.serializations[0].thumbnail = ''
            this.serializations[0].thumbnail_preview = ''
        },
        // フォームデータ作成
        getFormData(serialization) {
            // 画像UPで使用するため
            const fd = new FormData()
            if (serialization.id) {
                fd.append('id', serialization.id)
            }
            fd.append('title', serialization.title)
            fd.append('description', serialization.description)
            fd.append('thumbnail', serialization.thumbnail && typeof serialization.thumbnail !== 'string' ? serialization.thumbnail : '')
            fd.append('publish_start_at', serialization.publish_start_at ? serialization.publish_start_at : '')
            fd.append('publish_end_at', serialization.publish_end_at ? serialization.publish_end_at : '')
            fd.append('is_active', Number(serialization.is_active))
            fd.append('is_video', Number(serialization.is_video))

            return fd
        },
        getDescriptionLength: function (serialization) {
            return serialization.description ? serialization.description.length + '文字' : ''
        },
        selectedFile: function(e) {
            // 選択された File の情報を保存しておく
            e.preventDefault()
            let files = e.target.files
            let topicId = Number(e.target.id.replace('image_', ''))
            this.serializations = this.serializations.map(function(x) {
                if (x.id === topicId) {
                    x.thumbnail = files[0]
                    const reader = new FileReader()
                    reader.onload = e => {
                        x.thumbnail_preview = e.target.result
                    }
                    reader.readAsDataURL(files[0])
                }
                return x
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .create-button {
        margin-top: 10px;
        margin-right: 20px;
    }
    .search-form {
        margin-top: 10px;
        margin-right: 20px;
    }
    .thumbnail-img {
        width: 100px;
    }
    .el-icon-check{
        font-size:2.0em;
    }
    .serialization {
        margin-top: 20px;
        &__id {
            color: #428bca;
            cursor: pointer;
        }
        &__description-length {
            margin-top: 5px;
            color: #999;
            font-size: 12px;
            text-align: right;
        }
        &__date {
            line-height: 3.0em;
        }
        &__button {
            line-height: 3.0em;
        }
    }
</style>
