<template>
    <el-row type="flex">
        <el-header style="width: 100%; margin-bottom: 15px; height: 40px;">
            <el-col :span="7" style="height: 100%;">
                <el-row type="flex" align="middle" style="height: 100%;" v-if="rewriteData.length">
                    <span>リライト記事ID：{{rewriteData[0].rewrite_article_id}}<br>(元記事ID：{{rewriteData[0].origin_article_id}})</span>
                </el-row>
                <el-row type="flex" align="middle" style="height: 100%;" v-else>
                    記事ID：{{article.id}}
                </el-row>
            </el-col>
            <el-col :span="17" style="height: 100%;">
                <el-row type="flex" align="middle" justify="end" style="height: 100%;">
                    <div v-if="articleFormat === 'normal'" class="csvActions_group">
                        <csv-upload name="csv" :url="`/api/articles/${article.id}/items/csv`" button-text="アイテムCSVアップロード" elButtonSize="mini" @afterCsvImportFetch="fetch" />
                        <csv-download name="article_items_format.csv" :url="`/api/articles/${article.id}/items/csv`" elButtonSize="mini" button-name="フォーマットDL" />
                    </div>
                    <!-- 編集者以上が日付更新可 -->
                    <el-checkbox v-if="status === '公開中' && this.$store.state.role === 'tr' && this.$store.state.loginUser.authority >= 100" v-model="isUpdateReleasedAt">
                        日付更新
                    </el-checkbox>
                    <el-button v-if="this.$store.state.role === 'tr' && !rssEditData" size="mini" @click="openCopyDialog">
                        別のライターへ記事コピー
                    </el-button>
                    <el-button v-if="this.$store.state.role === 'tr' && status === 'レビュー' && !rssEditData" size="mini" @click="copyRssArticle">
                        RSS用へ記事コピー
                    </el-button>
                    <el-button type="danger" size="mini" v-if="status === '公開中' && this.$store.state.role === 'tr'" @click="openPrivateConfirmDialog()">
                        非公開記事にする
                    </el-button>
                    <el-button type="warning" size="mini" v-if="status === '非公開' && this.$store.state.role === 'tr'" @click="openRepublishConfirmDialog()">
                        非公開から公開に戻す
                    </el-button>
                    <el-button type="primary" size="mini" v-if="(status === '未対応' || (status === '執筆中' && article.published_at === null)) && !rssEditData" @click="sendEditCheck">
                        送信する
                    </el-button>
                    <el-button type="primary" size="mini" v-if="status === '執筆中' && article.published_at !== null" @click="sendEditReCheck">
                        送信する
                    </el-button>
                    <el-button type="primary" size="mini" v-if="status === 'レビュー'" @click="sendSaveButton">
                        保存する
                    </el-button>
                    <el-button type="primary" size="mini" v-if="rssEditData && rssEditData.status === 100" @click="makeRssArticlePublish">
                        RSS編集完了にする
                    </el-button>
                    <el-button type="primary" size="mini" v-if="status === '公開中' && this.$store.state.role === 'tr'" @click="publishItem">
                        更新する
                    </el-button>
                </el-row>
             </el-col>
            <el-col :span="24" style="height: 100%;">
                <el-row type="flex" align="middle" justify="end" style="height: 100%;font-size: 15px;">
                    <span v-if="rewriteData.length && !rewriteData[0].writer_id">執筆ライター：割り当てなし</span>
                    <span v-else>執筆ライター：<img :src="article.writer.profile_image_cdn_url" class="writerIcon">{{article.writer.screen_name}}({{article.writer.writer_name}})</span>
                </el-row>
            </el-col>

        </el-header >
        <copy-article-modal
            @save="copyArticle"
            :dialog="copyDialog"
            @close="closeDialog"
            :writers="writerList"
            :input="form"
            >
        </copy-article-modal>
        <check-ng-words
                ref="checkNgWords"
        >
        </check-ng-words>
        <el-dialog
            title="確認"
            :visible.sync="confirmPrivateDialog"
            width="30%"
        >
            <span>非公開にしてもよろしいでしょうか？</span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="confirmPrivateDialog = false">キャンセル</el-button>
                <el-button type="primary" size="mini" @click="makePrivateArticle()">非公開にする</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="確認"
            :visible.sync="confirmRepublishDialog"
            width="30%"
        >
            <span>公開状態に戻してもよろしいでしょうか？</span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="confirmRepublishDialog = false">キャンセル</el-button>
                <el-button type="primary" size="mini" @click="makeRepublishArticle()">公開する</el-button>
            </span>
        </el-dialog>
    </el-row>
</template>

<script>
import moment from 'moment'
import CopyArticleModal from './HeaderButtons/CopyArticleModal'
import CheckNgWords from './ArticleMain/Components/CheckNgWords'
import CsvDownload from '../../../components/CsvDownload'
import CsvUpload from '../../../components/CsvUpload'
import * as types from '../../../store/mutation-types'

export default {
    components: {
        CopyArticleModal,
        CheckNgWords,
        CsvDownload,
        CsvUpload
    },
    props: {
        article: Object,
        writerList: Array,
        statusList: Array,
        ngWords: Array,
        rewriteData: Array,
        rssEditData: Object,
        fetch: Function,
        isWr:Boolean,
        articleFormat: String,
    },
    computed: {
        status() {
            return this.statusList.filter((status) => {
                return status.value === this.article.status
            })[0].label
        },
        checkRewriteData() {
            if (this.rewriteData.length === 0) {
                return false
            }
            return true
        },
        rewriteSendData() {
            if (this.rewriteData.length === 0) {
                return false
            }
            if (!this.rewriteData[0].rewrite_plot_id) {
                return false
            }
            if (this.rewriteData[0].status !== 3) {
                return false
            }

            return this.rewriteData
        }
    },
    data() {
        return {
            copyDialog: false,
            form: null,
            isUpdateReleasedAt: false,
            confirmPrivateDialog: false,
            confirmRepublishDialog: false,
        }
    },
    methods: {
        closeDialog () {

            this.copyDialog = false
        },
        openCopyDialog (form) {
            this.copyDialog = true
            this.form = Object.assign({},this.article)
        },
        openPrivateConfirmDialog () {
            this.form = Object.assign({},this.article)
            this.confirmPrivateDialog = true
        },
        openRepublishConfirmDialog () {
            this.form = structuredClone(this.article)
            this.confirmRepublishDialog = true
        },
        async sendEditCheck() {
            const ngWordMessage = this.$refs.checkNgWords.perform(this.ngWords, this.article)
            if (ngWordMessage !== '' && !confirm('NGワードが含まれていますが、実行してもよろしいですか？\n' + ngWordMessage)) {
                return
            }
            this.$store.commit(types.LOADING, true)
            if (this.rewriteData.length !== 0 && this.rewriteData[0].id) {
                const form = {'rewriteInternalFlag': false, 'rewrite_id' : this.rewriteData[0].id}
                if (this.isWr) {
                    var { error } = await axios.post('/api/writers/rewrite/send', form)
                } else {
                    var { error } = await axios.post('/api/editing/rewrite_checks', form)
                }
            } else {
                var { error } = await axios.post('/api/editing/checks', {
                    article_id: this.article.id
                })
            }

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                this.$store.commit(types.SHOW_INFO_MESSAGE, '編集部に送信しました。')
            }
            this.$store.commit(types.LOADING, false)
        },
        async sendEditReCheck() {
            const ngWordMessage = this.$refs.checkNgWords.perform(this.ngWords, this.article)
            if (ngWordMessage !== '' && !confirm('NGワードが含まれていますが、実行してもよろしいですか？\n' + ngWordMessage)) {
                return
            }
            this.$store.commit(types.LOADING, true)

            let { error } = await axios.post('/api/editing/checks/recheck', {
                article_id: this.article.id
            })

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                this.$store.commit(types.SHOW_INFO_MESSAGE, '編集部に送信しました。')
            }
            this.$store.commit(types.LOADING, false)
        },
        sendSaveButton() {
            // 既存の動作に合わせてメッセージ出力のみとする。
            this.$store.commit(types.LOADING, true)
            this.$store.commit(types.SHOW_INFO_MESSAGE, '保存しました。')
            this.$store.commit(types.LOADING, false)
        },
        async copyArticle(id,form) {
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.patch('/api/articles/' + id + '/copy', form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                this.$store.commit(types.SHOW_INFO_MESSAGE, '記事データをコピーしました, 新しい記事ページへ遷移します')
                setTimeout(() => {
                    this.$router.push('/articles/' + data.article.id)
                },3000)
            }
            this.$store.commit(types.LOADING, false)
        },
        async copyRssArticle() {
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.patch('/api/articles/' + this.article.id + '/rss_copy')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                this.$store.commit(types.SHOW_INFO_MESSAGE, '記事データをコピーしました, 新しい記事ページへ遷移します')
                setTimeout(() => {
                    this.$router.push('/articles/' + data.copy_article_id)
                },3000)
            }
            this.$store.commit(types.LOADING, false)
        },
        async makePrivateArticle() {
            this.$store.commit(types.LOADING, true)

            // ステータス411発生の可能性がある為、ヘッダーにContent-Length設定し空欄のパラメータ設置
            const config = {
                headers: {
                    'Content-Length': Buffer.byteLength('')
                }
            }
            const { error } = await axios.patch('/api/articles/' + this.form.id + '/private','' ,config)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '記事を非公開にしました。')
            this.confirmPrivateDialog = false
            this.$store.commit(types.LOADING, false)
        },
        async makeRepublishArticle() {
            this.$store.commit(types.LOADING, true)

            const { error } = await axios.patch('/api/articles/' + this.form.id + '/republish')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '記事を公開しました。')
            this.confirmRepublishDialog = false
            this.$store.commit(types.LOADING, false)
        },
        async makeRssArticlePublish() {
            this.$store.commit(types.LOADING, true)
            const form = { 'rss_edit_id' : this.rssEditData.id}
            const {error} = await axios.patch('/api/articles/'+ this.rssEditData.rss_article_id + '/rss_edit_publish', form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'RSS配信準備が完了しました。')
            }
            this.$store.commit(types.LOADING, false)
        },
        async rewriteReflect(rewriteData) {
            this.$store.commit(types.LOADING, true)
            const form = {'rewriteInternalFlag': true, 'rewrite_id' : rewriteData.id}
            const {error} = await axios.patch('/api/rewrite/' + rewriteData.rewrite_article_id + '/' + rewriteData.writer_id  , form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'リライト反映が完了しました。')
            }
            this.$store.commit(types.LOADING, false)
        },
        async rewriteSend(rewriteData) {
            this.$store.commit(types.LOADING, true)
            const form = {'rewriteInternalFlag': false, 'rewrite_id' : rewriteData[0].id}
            const {error} = await axios.post('/api/writers/rewrite/send', form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'リライト送信が完了しました。')
            }
            this.$store.commit(types.LOADING, false)
            setTimeout(() => {
                this.$router.push('/articles/' + rewriteData.rewrite_article_is)
            },3000)
        },
        async publishItem() {
            const ngWordMessage = this.$refs.checkNgWords.perform(this.ngWords, this.article)
            if (ngWordMessage !== '' && !confirm('NGワードが含まれていますが、実行してもよろしいですか？\n' + ngWordMessage)) {
                return
            }
            this.$store.commit(types.LOADING, true)

            let form = {}
            if (this.isUpdateReleasedAt) {
                form = { 'released_at' : moment().format('YYYY-MM-DD HH:mm:ss') }
            }
            const { error } = await axios.post('/api/articles/' + this.article.id + '/items/draft', form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                this.$store.commit(types.SHOW_INFO_MESSAGE, '記事の更新が完了しました。')
                await this.fetch()
            }

            this.$store.commit(types.LOADING, false)
        }
    }
}
</script>
<style scoped>
    .csvActions {
        display: flex;
        justify-content: flex-end;
        gap: 30px;
    }
    .csvActions_group {
        display: flex;
        gap: 5px;
        margin-right: 5px;
    }
    .writerIcon {
        width: 35px;
        height: 35px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 5px;
    }
</style>
