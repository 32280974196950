<template>
    <div class="contents">
        <el-container  class="loginWrapper">
            <el-main>
                <el-row >
                    <el-col
                        :span="10"
                        :offset="7">
                        <el-form :model="form" >
                            <el-form-item >
                               <el-input name="email" placeholder="メールアドレス" v-model="form.email" auto-complete="on">
                                   <template slot="prepend"><i class="el-icon-message"></i></template>
                               </el-input>
                            </el-form-item>
                            <el-form-item >
                                <el-input name="password" placeholder="パスワード" type="password" v-model="form.password" >
                                   <template slot="prepend"><i class="fas fa-key"></i></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item class="buttons">
                                <el-button type="primary" @click="login(form)">ログイン</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                </el-row>
                <el-row class="linkWriterPage">
                    <a :href="wrLoginPage" target="_blank">
                        <p>ライター用ログイン画面へ</p>
                    </a>
                </el-row>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import * as types from '../store/mutation-types'

export default {
    name: 'TrLogin',
    computed: {
        ...mapState(['isLogin'])
    },
    data() {
        return {
            wrLoginPage: '',
            form: {
                email: '',
                password: '',
            },
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '')
        this.$store.commit(types.LOADING, false)
        this.$store.commit(types.PAGE_LOADING, false)
        // ログイン状態の判定を行い、ログイン時はダッシュボードへ戻す
        if (this.isLogin) {
            this.$router.push('/')
            return
        }
        this.fetch()
    },
    methods: {
        // ライター用ログインページURL取得
        async fetch() {
            this.$store.commit(types.LOADING, true)

            const { data, error } = await axios.get('/api/login')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'データの取得に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }

            this.wrLoginPage = data.wrLogin
            this.$store.commit(types.LOADING, false)
        },
        async login(form) {
            this.$store.commit(types.LOADING, true)

            const { error } = await this.$store.dispatch('login', form)

            this.$store.commit(types.LOADING, false)
            if (error) {
                if (error.response.status === 422) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'アカウント情報に誤りがあります')
                } else {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'システムエラーが発生しました<br>システム担当者にお知らせください')
                }
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, 'ログインしました')
            this.$router.push('/')
        }
    }
}
</script>
<style scoped>
.loginWrapper {
    margin-top:200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buttons {
    text-align: center;
}
.linkWriterPage {
    text-align: center;
}

</style>
