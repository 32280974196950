<template>
    <div>
        <el-row type="flex" justify="end">
            <el-header>
                <el-col>
                    <el-button class="add-button" type="primary" @click="openRegistrationModal()">
                        記事追加
                    </el-button>
                </el-col>
            </el-header >
        </el-row>
        <el-row type="flex" justify="end" align="middle">
            <el-header>
            <el-col>
                合計：{{ totalRatio }}%&nbsp;&nbsp;
                <el-button type="warning" @click="updateDistributeRatio()">配信比率更新</el-button>
            </el-col>
            </el-header>
        </el-row>

        <el-tabs v-model="activeTagName">
            <el-tab-pane label="配信中" name="unarchived">
                <app-ad-article-list
                    :app-ad-articles="unarchivedAppAdArticles"
                    :is-archive="false"
                    @selectedFile="selectedFile"
                    @updateAdArticle="updateAdArticle"
                    @updateArchive="updateArchive"
                    @openDeleteConfirmModal="openDeleteConfirmModal"
                />
            </el-tab-pane>
            <el-tab-pane label="アーカイブ" name="archived">
                <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
                <app-ad-article-list
                    :app-ad-articles="archivedAppAdArticles"
                    :is-archive="true"
                    @selectedFile="selectedFile"
                    @updateAdArticle="updateAdArticle"
                    @updateArchive="updateArchive"
                    @openDeleteConfirmModal="openDeleteConfirmModal"
                />
                <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
            </el-tab-pane>
        </el-tabs>

        <registration-modal
            :is-active="registration.isActive"
            :frequency-interval-types="frequencyIntervalTypes"
            @save="insertAdArticle"
            @close="closeRegistrationModal"
        ></registration-modal>
        <delete-confirm-modal
            :is-active="deleteConfirm.isActive"
            :delete-ad-article-id="deleteAdArticleId"
            @delete="deleteAdArticle"
            @close="closeDeleteConfirmModal"
        ></delete-confirm-modal>
    </div>

</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import RegistrationModal from './AppAdArticle/RegistrationModal'
import DeleteConfirmModal from './AppAdArticle/DeleteConfirmModal'
import AppAdArticleList from './AppAdArticle/AppAdArticleList'

export default {
    components: {
        Pagination,
        RegistrationModal,
        DeleteConfirmModal,
        AppAdArticleList,
    },
    data() {
        return {
            deleteAdArticleId: 0,
            registration: {
                isActive: false,
            },
            deleteConfirm: {
                isActive: false,
            },
            frequencyIntervalTypes : {},
            unarchivedAppAdArticles: [],
            archivedAppAdArticles: [],
            activeTagName: 'unarchived',
            pagination  : {},
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '広告　>　ネイティブアド')
        this.init()
    },
    methods   : {
        init() {
            this.fetch()
        },
        openRegistrationModal() {
            this.registration.isActive = true
        },
        openDeleteConfirmModal(id) {
            this.deleteConfirm.isActive = true
            this.deleteAdArticleId = id
        },
        closeRegistrationModal() {
            this.registration.isActive = false
        },
        closeDeleteConfirmModal() {
            this.deleteConfirm.isActive = false
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },

        async fetch() {
            const page = this.$route.query.page
            const params = {page}
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/organization/adarticles', {params})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.unarchivedAppAdArticles = structuredClone(data.unarchived_app_ad_articles)
            this.archivedAppAdArticles = structuredClone(data.archived_app_ad_articles.data)
            this.pagination = structuredClone(data.archived_app_ad_articles)
            this.frequencyIntervalTypes = structuredClone(data.frequency_interval_type)
            this.$store.commit(types.LOADING, false)
        },
        async insertAdArticle(fd) {
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.post('/api/organization/adarticles', fd)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, '登録しました。')
            this.closeRegistrationModal()
            this.fetch()
        },
        async updateAdArticle(adArticle) {
            const fd = this.getFormData(adArticle)
            this.$store.commit(types.LOADING, true)
            // formがpatchでrequest投げると空になるので、headerを書き換える
            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PATCH'
                }
            }
            const { error } = await axios.post('/api/organization/adarticles/'+adArticle.id, fd, config)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '更新しました。')
            this.fetch()
        },
        async updateArchive(adArticle, isArchive) {
            if (! confirm(isArchive ? '本当にアーカイブしますか？' : '本当にアーカイブを解除しますか？')) {
                return
            }

            this.$store.commit(types.LOADING, true)
            const { error } = await axios.patch('/api/organization/adarticles/'+adArticle.id+'/archive', {is_archive: isArchive})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, isArchive ? 'アーカイブしました' : 'アーカイブを解除しました')
            this.fetch()
        },
        // フォームデータ作成
        getFormData(adArticle) {
            const fd = new FormData()
            if (adArticle.hide_pr_mark_flag) {
                fd.append('hide_pr_mark_flag', '1')
            } else {
                fd.append('hide_pr_mark_flag', '0')
            }
            if (adArticle.article_id) {
                fd.append('article_id', adArticle.article_id)
            } else {
                fd.append('link_url', adArticle.link_url)
                fd.append('screen_name', adArticle.screen_name)
            }
            fd.append('name', adArticle.name)
            fd.append('title', adArticle.title)
            fd.append('image', adArticle.image && typeof adArticle.image !== 'string' ? adArticle.image : '')
            if (adArticle.distribute_start_at !== null && adArticle.distribute_end_at !== null) {
                fd.append('distribute_start_at', adArticle.distribute_start_at)
                fd.append('distribute_end_at', adArticle.distribute_end_at)
            }

            return fd
        },
        async deleteAdArticle() {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.delete('/api/organization/adarticles/' + this.deleteAdArticleId)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'データの削除に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            this.closeDeleteConfirmModal()
            this.fetch()
        },
        selectedFile(e, appAdArticle, isArchive) {
            // 選択された File の情報を保存しておく
            e.preventDefault()
            const files = e.target.files
            const image = files[0]
            const reader = new FileReader()
            if (isArchive) {
                const findIdx = this.archivedAppAdArticles.findIndex((archivedAppAdArticle) => archivedAppAdArticle.id === appAdArticle.id)
                reader.onload = e => {
                    this.$set(this.archivedAppAdArticles, findIdx, {...appAdArticle, image, image_cdn_url: e.target.result})
                }
                reader.readAsDataURL(files[0])
            } else {
                const findIdx = this.unarchivedAppAdArticles.findIndex((unarchivedAppAdArticle) => unarchivedAppAdArticle.id === appAdArticle.id)
                reader.onload = e => {
                    this.$set(this.unarchivedAppAdArticles, findIdx, {...appAdArticle, image, image_cdn_url: e.target.result})
                }
                reader.readAsDataURL(files[0])
            }
        },
        async updateDistributeRatio() {
            const params = this.unarchivedAppAdArticles.map((appAdArticle) => ({
                id: appAdArticle.id,
                distribute_ratio: appAdArticle.distribute_ratio
            }))
            const { error } = await axios.patch('/api/organization/adarticles/distribute_ratio', {ratio_list: params})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, '配信比率を更新しました。')
            this.fetch()
        }
    },
    computed: {
        totalRatio() {
            return this.unarchivedAppAdArticles.reduce((acc, appAdArticle) => acc + appAdArticle.distribute_ratio, 0)
        },
    }
}
</script>
