<template>
    <div class="shopForm">
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent  label-width="100px" >
            <el-form-item label="店舗名" >
                <el-input
                    type="text"
                    size="mini"
                    placeholder="トラストリッジ 代官山店"
                    v-model="form.shop_name">
                </el-input>
            </el-form-item>
            <el-form-item label="公式HP" >
                <el-input
                    type="text"
                    size="mini"
                    placeholder="https://macaro-ni.jp"
                    v-model="form.shop_url">
                </el-input>
            </el-form-item>
            <el-form-item v-for="(element, index) in elements" :key="index" :label="element.name">
                <el-input
                    type="textarea"
                    size="mini"
                    maxlength="200"
                    :placeholder="fetchPlaceholder(element.type)"
                    v-model="element.value">
                </el-input>
                <div style="float: right;">
                    <el-button @click="elementMoveUp(element, index)" icon="el-icon-arrow-up" size="mini" type="mini" class="shopFormButton"></el-button>
                    <el-button @click="elementMoveDown(element, index)" icon="el-icon-arrow-down" size="mini" class="shopFormButton"></el-button>
                    <el-button @click="elementRemove(element, index)" icon="el-icon-close" size="mini" class="shopFormButton"></el-button>
                </div>
                <div v-if="element.type === 'postal_code'">
                    <el-button @click="addressSearch(element.value, index)" type="primary" size="mini">住所検索</el-button>
                </div>
            </el-form-item>

            <el-form-item label="追加情報" >
                 <el-select v-model="element" size="mini">
                     <el-option
                         v-for="item in shopTypeStatus"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                 </el-select>
                 <el-input
                     type="text"
                     size="mini"
                     placeholder="その他の項目を入力"
                     maxlength="60"
                     class="otherLabel"
                     v-if="element === 'other'"
                     v-model="otherLabel">
                 </el-input>
                 <el-button @click="addElement" :disabled="!enableAddElement" size="mini">追加</el-button>
            </el-form-item>

            <el-row type="flex">
                <el-col :span="7">
                    <el-form-item label="Wi-Fi" >
                        <el-select v-model="form.wifi_type" size="mini">
                            <el-option
                                v-for="item in wifiTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="7">
                    <el-form-item label="電源" >
                        <el-select v-model="form.power_supply_type" size="mini">
                            <el-option
                                v-for="item in powerSupplyTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="7">
                    <el-form-item label="喫煙" >
                        <el-select v-model="form.smoking_type" size="mini">
                            <el-option
                                v-for="item in smokingTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="margin-left: 100px;">
                <el-button
                    type="text"
                    size="mini"
                    @click="() => this.mapDialog = true">位置情報を追加</el-button>
            </el-row>
            <el-row>
                <p v-if="showAddressLabel" class="addressLabel"><a style="color: #409eff;" :href="addressLinkUrl" target="_blank"><i class="el-icon-location"></i>{{form.geo_location}}</a></p>
            </el-row>
        </el-form>
        <span slot="footer" class="itemFormFooter" style="margin-left: 100px;">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
        <el-dialog
            title="位置情報入力"
            @close="() => this.mapDialog = false"
            :visible="mapDialog"
            height="50%"
            append-to-body
            >
            <map-form
                @save="(mapInfo) => {
                    this.saveAddress(mapInfo)
                    this.mapDialog = false
                }"
            />
        </el-dialog>
    </div>
</template>

<script>
import MapForm from './Components/Map'
import * as types from '../../../../../store/mutation-types'

const defaultFormValues =  {
    shop_name:'',
    shop_url:'',
    geo_location:null,
    geo_lat:null,
    geo_lng:null,
    wifi_type:null,
    power_supply_type:null,
    smoking_type:null,
}
const defaultElementFormValues =  {
    id:null,
    name:null,
    value:null,
    type:null,
}

export default {
    name:'ItemFormShopType',
    components: {
        MapForm
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        shopTypeList: {
            required: true,
            type: Array,
        },
        wifiTypeList: {
            required: true,
            type: Array,
        },
        smokingTypeList: {
            required: true,
            type: Array,
        },
        powerSupplyTypeList: {
            required: true,
            type: Array,
        },
        save: {
            required: true,
            type: Function,
        },
        isEdit: {
            required: true,
            type: Boolean,
        },
    },
    computed: {
        showAddressLabel() {
            return Boolean(this.form.geo_location)
        },
        shopTypeStatus() {
            const shopTypeStatus = Object.assign([], this.shopTypeList)
            shopTypeStatus.unshift({label: null, value: null})
            return shopTypeStatus
        },
        enableAddElement() {
            if (this.element === 'other') {
                return this.otherLabel
            } else {
                return this.element
            }
        },
        addressLinkUrl(){
            return 'https://maps.google.co.jp/maps?center=' + this.form.geo_lat + ',' + this.form.geo_lng + '&q=' + this.form.geo_location + '&iwloc=A'
        },
        resourceName() {
            return 'shops'
        },
    },
    data () {
        return {
            mapDialog:false,
            position:null,
            element:null,
            otherLabel:null,
            form: {...defaultFormValues},
            elements:[],
        }
    },
    mounted() {
        this.form = {...defaultFormValues}
        if (!this.isEdit) {
            // これ多分使ってないっぽい、下の処理(openDialog)で上書きしている
            // デフォルトの為必要とかかな？
            this.elements = [
                {'name': '参考URL', 'type': 'reference_url', 'id': null, 'value': null},
                {'name': '電話番号', 'type': 'telephone', 'id': null, 'value': null},
                {'name': '最寄駅', 'type': 'other', 'id': null, 'value': null},
                {'name': '郵便番号', 'type': 'postal_code', 'id': null, 'value': null},
                {'name': '都道府県', 'type': 'region', 'id': null, 'value': null},
                {'name': '市区町村', 'type': 'locality', 'id': null, 'value': null},
                {'name': '町域', 'type': 'address', 'id': null, 'value': null},
                {'name': '営業時間', 'type': 'other', 'id': null, 'value': null},
                {'name': '定休日', 'type': 'other', 'id': null, 'value': null},
            ]
        }
        this.form.wifi_type = this.wifiTypeList[0].value
        this.form.smoking_type = this.smokingTypeList[0].value
        this.form.power_supply_type = this.powerSupplyTypeList[0].value
    },
    methods: {
        saveAddress({formattedAddress, lat, lng}) {
            this.form.geo_location = formattedAddress
            this.form.geo_lat = lat
            this.form.geo_lng = lng
        },
        submitForm () {
            this.form.elements = Object.assign([],this.elements)
            this.save(this.resourceName, this.form, this.id)
        },
        openDialog(input) {
            this.form = Object.assign({}, defaultFormValues, _.pick(input, Object.keys(this.form)))
            this.elements = input.elements.map((element) =>
                // vuexのwarning対策
                Object.assign({}, defaultElementFormValues,  _.pick(element, Object.keys(defaultElementFormValues)))
            )
        },
        addElement() {
            const typeLabel = this.shopTypeList.filter((type) => type.value === this.element)[0].label
            let element = Object.assign({}, defaultElementFormValues, {
                name: typeLabel,
                type: this.element,
            })
            if (this.otherLabel && this.element === 'other') {
                element.name = this.otherLabel
            }
            this.element = null
            this.otherLabel = null
            this.elements.push(element)
        },
        elementMoveDown(element, index) {
            const movedIndex = index + 1
            if (this.elements[movedIndex]) {
                this.elements.splice(index, 2, this.elements[movedIndex], this.elements[index])
            }
        },
        elementMoveUp(element, index) {
            if (index > 0) {
                const movedIndex = index - 1
                this.elements.splice(movedIndex, 2, this.elements[movedIndex+1], this.elements[movedIndex])
            }
        },
        elementRemove(element, index) {
            this.elements.splice(index, 1)
        },
        fetchPlaceholder(type) {
            let placeholder = ''
            switch (type) {
            case 'shop_name':
                placeholder = 'トラストリッジ 代官山店'
                break
            case 'reference_url':
                placeholder = 'https://macaro-ni.jp'
                break
            case 'telephone':
                placeholder = '03-6412-8794'
                break
            case 'postal_code':
                placeholder = '153-0061'
                break
            case 'region':
                placeholder = '東京都'
                break
            case 'locality':
                placeholder = '目黒区'
                break
            case 'address':
                placeholder = '中目黒1丁目1-71 KN代官山2F'
                break
            }
            return placeholder
        },
        async addressSearch(zip) {
            if (zip === null || zip === '') {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '郵便番号を入力してください。')
                return
            }
            this.$store.commit(types.EDITOR_LOADING, true)

            const {data, error} = await axios.get('/api/addresses/zip_search/'+zip)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.EDITOR_LOADING, false)
                return
            }

            const vue = this
            this.elements.map(function(element, idx) {
                if (element.type === 'region') {
                    vue.elements[idx].value = data.pref
                } else if (element.type === 'locality') {
                    vue.elements[idx].value = data.city
                } else if (element.type === 'address') {
                    vue.elements[idx].value = data.town
                }
            })

            this.$store.commit(types.EDITOR_LOADING, false)
        }
    },
}
</script>

<style>
.el-form-item__content li {
    display: list-item !important;
    list-style: disc !important;
    margin-left:20px;
}
.gmap {
    height:500px;
    width:500px;
}
.otherLabel >.el-input__inner{
    width:300px;
}
.contents .el-table td,
.contents .el-table th {
    padding: 5px 0;
    font-size: 12px;
}
</style>
<style scoped>
.addressLabel {
    margin-left:100px;
    font-size: 12px;
}
.otherLabel {
    width:300px;
    display:inline;
}
.shopForm .el-textarea {
    width: 490px;
}
.shopForm .el-button+.el-button {
    margin: 0;
}
.shopFormButton {
    padding: 8px;
}
</style>
