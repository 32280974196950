<template>
    <div>
        <el-row type="flex" justify="end">
            <div class="create-button">
                <el-button class="add-button" type="primary" @click="openRegistrationModal()">新規作成</el-button>
            </div>
        </el-row>
        <el-row type="flex" justify="end">
            <div class="search-form">
                <el-input class="fetch-input" placeholder="アカウント名" v-model="accountName" @keyup.enter.native="fetch(accountName)">
                    <el-button
                            @click="fetch(accountName)"
                            slot="append"
                            icon="el-icon-search">
                    </el-button>
                </el-input>
            </div>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <el-table
            :data="blacklists"
            style="width: 100%"
            max-height="800"
        >
            <el-table-column label="SNS" prop="service"></el-table-column>
            <el-table-column label="アカウント名" prop="account_name"></el-table-column>
            <el-table-column label="作成日時" prop="created_at"></el-table-column>
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                        type="danger"
                        size="mini"
                        @click="destroyBlacklist(scope.row.id)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <registration-modal
            :is-active="registration.isActive"
            :services="registration.services"
            @close="registration.isActive = false"
            @reload="fetch"
        ></registration-modal>
    </div>
</template>

<script>
import * as types from '../../../../store/mutation-types'
import Pagination from '../../../../components/Pagination'
import RegistrationModal from './Blacklist/RegistrationModal'

export default {
    components: {
        Pagination,
        RegistrationModal,
    },
    data() {
        return {
            blacklists: [],
            pagination: {},
            accountName: '',
            registration: {
                isActive: false,
                services: {},
            }
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '外部連携管理 > SNSアカウント制限')
        this.init()
    },
    methods: {
        init() {
            this.fetch().then(this.fetchService())
        },
        // 検索
        async fetch(accountName = '') {
            const page = this.$route.query.page
            const params = accountName === '' ? {page} : {page, account_name: accountName}
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/quote/snsaccount/blacklists', {
                params: params
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.blacklists = data.data
            this.pagination = Object.assign({}, this.pagination, data)
            this.$store.commit(types.LOADING, false)
            return Promise.resolve(1)
        },
        // serviceの一覧を取得
        async fetchService() {
            const {data, error} = await axios.get('/api/quote/snsaccount/blacklists/service')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'データの取得に失敗しました。')
                return
            }
            this.registration.services = data
            return Promise.resolve(1)
        },
        // SNSアカウントブラックリストを削除
        async destroyBlacklist(blacklistId) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.delete('/api/quote/snsaccount/blacklists/' + blacklistId)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.LOADING, false)
        },
        openRegistrationModal() {
            this.registration.isActive = true
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch(this.accountName)
        },
    }
}
</script>

<style lang="scss" scoped>
    .create-button {
        margin-top: 10px;
        margin-right: 20px;
    }
    .search-form {
        margin-top: 10px;
        margin-right: 20px;
    }
</style>