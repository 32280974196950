<template>
    <div>
        <h4 v-if="baseCharCountList && plot.base_char_count">目安文字量</h4>
        <p v-if="baseCharCountList && plot.base_char_count">
            {{baseCharCountList[plot.base_char_count].min}} 〜 {{baseCharCountList[plot.base_char_count].max}}
        </p>
        <h4 v-if="plot.keywords">SEOキーワード</h4>
        <p v-if="plot.keywords">
           <el-tag size="mini" type="info" class="wordList" v-for='keyword in plot.keywords' :key='keyword.id'>
               {{keyword.keyword}}
           </el-tag>
        </p>
        <h4>プロット</h4>
        <p class="prePlot">
            {{plot.plot}}
        </p>
    </div>
</template>
<script>
export default {
    name: 'ConfirmPlotText',
    props: {
        plot: Object,
        baseCharCountList: Object
    }
}
</script>
<style scoped>
.wordList {
    margin:0 5px 5px 0;
}
.prePlot{
    white-space:pre-line;
}
</style>
