<template>
    <div>
        <el-row>
            <item-left-contents
                :check-state="checkState"
                :number="number"
                :page-number="item.page_number"
                @update:checkState="checkState = $event"
            />

            <el-col class="contents" :span="21">
                <comparison-type
                    v-if="isEditing"
                    ref="dialogModule"
                    :save="update"
                    :id="content.id"
                    :input="content"
                    :article-id="response.article.id"
                    :items="response.article.items"
                    :heading-different-information-items="response.headingDifferentInformationItems"
                    @close="closeMenu"
                ></comparison-type>
                <div v-else class="comparison">
                    <table class="comparison__table" border="1">
                        <thead>
                            <tr>
                                <th>商品</th>
                                <th v-if="content.is_show_affiliate">最安値</th>
                                <th v-if="content.is_show_other_link">その他リンク</th>
                                <th v-for="(heading, idx) in content.headings" :key="idx">{{ heading.heading }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, idx) in content.rows" :key="idx">
                                <td>
                                    <div class="comparison__imageWrapper">
                                        <img :src="row.image_src" class="comparison__image" />
                                        <span v-if="content.is_ranking_mode" class="comparison__ranking">{{ idx + 1 }}</span>
                                    </div>
                                    <div class="comparison__itemDetail">
                                        <div class="comparison__brand">{{ row.brand }}</div>
                                        <div>{{ row.item_name }}</div>
                                    </div>
                                </td>
                                <td v-if="content.is_show_affiliate">
                                    <a v-if="row.affiliate_url" :href="row.affiliate_url" target="_blank" class="comparison__link--affiliate">
                                        <div class="comparison__linkText--small">{{ row.affiliate_label }}</div>
                                        <div class="comparison__linkText">{{ row.affiliate_price }}円</div>
                                        <div class="comparison__linkText--small">(税込み)</div>
                                    </a>
                                </td>
                                <td v-if="content.is_show_other_link">
                                    <a v-if="row.other_url" :href="row.other_url" target="_blank" class="comparison__link--other">
                                        {{ row.other_label_text }}
                                    </a>
                                </td>
                                <td v-for="(heading, headingIdx) in content.headings" :key="headingIdx">
                                    {{ row.cells.find(cell => cell.heading.heading === heading.heading).body }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </el-col>
            <el-col class="rightBox" :span="2" justify="end" v-if="!updateOrdersMode">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList">下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', resourceName, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>

    </div>
</template>
<script>

import FeedbackForm from './Components/FeedbackForm'
import ComparisonType from '../Form/ComparisonType'
import ItemLeftContents from './Components/ItemLeftContents'

export default {
    components: {
        ComparisonType,
        FeedbackForm,
        ItemLeftContents,
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    data() {
        return {
            isEditing: false,
        }
    },
    computed: {
        content() {
            return this.item.content
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        resourceName() {
            return 'comparisons'
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check, item) {
            this.parentCheckItem(check, item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>
<style scoped lang="scss">
.comparison {
    &__table {
        display: block;
        width: 100%;
        overflow-x: scroll;
        border: none;
        margin: 15px 0 20px;
        white-space: nowrap;
        border-collapse: collapse;
        & tbody,
        & thead {
            border-color: #cccccc;
        }
        & th {
            padding: 3px 10px;
        }
        & td {
            padding: 10px;
        }
    }
    &__imageWrapper {
        position: relative;
        display: inline-block;
    }
    &__image {
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
    &__ranking {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #696868;
        position: absolute;
        left: -5px;
        top: -5px;
        color: white;
        text-align: center;
    }
    &__itemDetail {
        display: inline-block;
    }
    &__brand {
        color: #999999;
    }
    &__linkText {
        &--small {
            font-size: 10px;
        }
    }
    %__link {
        display: block;
        border-radius: 10px;
        padding: 5px;
        text-decoration: none;
        color: white;
        text-align: center;
    }
    &__link {
        &--affiliate {
            @extend %__link;
            background: #FF7B7B;
        }
        &--other {
            @extend %__link;
            background: #FFAB7B;
        }
    }
}
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}

</style>
