<template>
    <div>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <el-row v-for='(question, index) in tableData' :key="index" style="margin-bottom:30px;">
            <el-col >
                <el-card class="box-card" >
                    <el-table border :data="[question]" :default-expand-all="true">
                        <el-table-column fixed type="expand">
                            <template slot-scope="props">
                                <el-table border :data="props.row.threads" :default-expand-all="true" header-row-class-name="tabTableHeader">
                                    <el-table-column label="ユーザー名" width="200">
                                        <template slot-scope="props">
                                            <p>ipアドレス: {{ props.row.session.ip_address }}</p>
                                            <p>os: {{ props.row.session.os }}</p>
                                            <p>ブラウザ: {{ props.row.session.browser }}</p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="100" prop="user_name" label="ユーザー名" />
                                    <el-table-column width="100" prop="favorite_count" label="お気に入り数" />
                                    <el-table-column prop="thread_text" label="返信内容" />
                                    <el-table-column width="100">
                                        <template slot-scope="props">
                                            <div v-if="props.row.deleted_at">
                                                <el-tag type="danger">削除済み</el-tag>
                                            </div>
                                            <div v-else>
                                                <el-button
                                                    class="btn-edit"
                                                    size="mini"
                                                    @click="editRow(props.row)"
                                                >編集</el-button>
                                            </div>
                                            <br>
                                            <div v-if="props.row.deleted_at">
                                            </div>
                                            <div v-else>
                                                <div v-if="props.row.question_id || props.row.parent_thread_id" >
                                                    <el-button
                                                        type="danger"
                                                        size="mini"
                                                        @click="destroyThread(props.row.id)"
                                                    >
                                                        削除
                                                    </el-button>
                                                </div>
                                                <div v-else >
                                                    <el-button
                                                        type="danger"
                                                        size="mini"
                                                        @click="destroyQuestion(props.row.id)"
                                                    >
                                                        削除
                                                    </el-button>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed type="expand">
                                        <template slot-scope="props">
                                            <el-table border :data="props.row.child_threads" :default-expand-all="true" header-row-class-name="tabTableHeader">
                                                <el-table-column label="ユーザー名" width="200">
                                                    <template slot-scope="scope">
                                                        <p>ipアドレス: {{ props.row.session.ip_address }}</p>
                                                        <p>os: {{ props.row.session.os }}</p>
                                                        <p>ブラウザ: {{ props.row.session.browser }}</p>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100" prop="user_name" label="ユーザー名" />
                                                <el-table-column width="500" prop="thread_text" label="返信内容" />
                                                <el-table-column width="100">
                                                    <template slot-scope="props">
                                                        <div v-if="props.row.deleted_at">
                                                            <el-tag type="danger">削除済み</el-tag>
                                                        </div>
                                                        <div v-else>
                                                            <el-button
                                                                class="btn-edit"
                                                                size="mini"
                                                                @click="editRow(props.row)"
                                                            >編集</el-button>
                                                        </div>
                                                        <br>
                                                        <div v-if="props.row.deleted_at">
                                                        </div>
                                                        <div v-else>
                                                            <div v-if="props.row.question_id || props.row.parent_thread_id" >
                                                                <el-button
                                                                    type="danger"
                                                                    size="mini"
                                                                    @click="destroyThread(props.row.id)"
                                                                >
                                                                    削除
                                                                </el-button>
                                                            </div>
                                                            <div v-else >
                                                                <el-button
                                                                    type="danger"
                                                                    size="mini"
                                                                    @click="destroyQuestion(props.row.id)"
                                                                >
                                                                    削除
                                                                </el-button>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column label="ユーザー名" width="200">
                            <template slot-scope="props">
                                <p>ipアドレス: {{ props.row.ip_address }}</p>
                                <p>os: {{ props.row.os }}</p>
                                <p>ブラウザ: {{ props.row.browser }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column width="100" prop="user_name" label="ユーザー名" />
                        <el-table-column width="100" prop="favorite_count" label="お気に入り数" />
                        <el-table-column prop="question_text" label="質問内容" />
                        <el-table-column width="100">
                            <template slot-scope="props">
                                <div v-if="props.row.deleted_at">
                                    <el-tag type="danger">削除済み</el-tag>
                                </div>
                                <div v-else>
                                    <el-button
                                        class="btn-edit"
                                        size="mini"
                                        @click="editRow(props.row)"
                                    >編集</el-button>
                                </div>
                                <br>
                                <div v-if="props.row.deleted_at">
                                </div>
                                <div v-else>
                                    <div v-if="props.row.question_id || props.row.parent_thread_id" >
                                        <el-button
                                            type="danger"
                                            size="mini"
                                            @click="destroyThread(props.row.id)"
                                        >
                                            削除
                                        </el-button>
                                    </div>
                                    <div v-else >
                                        <el-button
                                            type="danger"
                                            size="mini"
                                            @click="destroyQuestion(props.row.id)"
                                        >
                                            削除
                                        </el-button>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />

        <edit-modal @save="update" :is-active="isActive" @close="closeDialog" :form="form"
        />

    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import EditModal from './Question/EditModal'

export default {
    name: 'Question',
    components: {
        EditModal,
        Pagination,
    },
    data() {
        return {
            tableData: [],
            pagination: {},
            isActive:false,
            form: {}
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'コミュニティー機能')
        this.init()
    },
    methods   : {
        init() {
            this.fetch()
        },
        editRow (form) {
            this.form = Object.assign({}, form)
            this.isActive = true
        },
        closeDialog() {
            this.isActive = false
        },
        handleCurrentChange (page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
        async fetch()
        {
            const page = this.$route.query.page
            const params = {page}
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/questions', {params})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.pagination = Object.assign({},this.pagination, data.questions)

            this.tableData = Object.entries(data.questions.data).map(([key, data]) => {
                return {
                    id: data.id,
                    ip_address:data.session.ip_address,
                    os:data.session.os,
                    browser:data.session.browser,
                    question_text:data.question_text,
                    user_name:data.user_name,
                    favorite_count:data.favorite_count,
                    deleted_at:data.deleted_at,
                    threads:data.threads,
                }
            })
            this.$store.commit(types.LOADING, false)
        },
        async updateQuestion (form) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.patch('/api/questions/' + form.id, form)

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.fetch()
            this.$store.commit(types.LOADING, false)
        },
        async update (params) {
            this.isActive = false

            let id = params.id
            id = Number(id)
            this.$store.commit(types.LOADING, true)
            if (params.is_question) {
                var {error} = await axios.patch('/api/questions/' + id, params)
            } else {
                var {error} = await axios.patch('/api/question_thread/' + id, params)
            }

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.fetch()
            this.$store.commit(types.LOADING, false)
        },
        async destroyThread(id) {
            if (!confirm('本当に削除しますか？')) {
                return
            }
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.delete('/api/question_thread/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            this.$store.commit(types.LOADING, true)

            this.fetch()
        },
        async destroyQuestion(id) {
            if (!confirm('本当に削除しますか？')) {
                return
            }
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.delete('/api/questions/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            this.$store.commit(types.LOADING, true)

            this.fetch()
        },
    }
}
</script>

<style scoped>
    .update-button {
        margin-bottom: 10px;
    }
    td {
        text-align: center;
    }
</style>
