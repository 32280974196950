<template>
    <div>
        <div class="button-list">
            <el-button type="success" @click="() => this.$router.push('/organizations/app_recipe_menu/lists/' + this.$route.params.appRecipeMenuListId + '/articles/order')">並び替え</el-button>
            <el-button @click="openRegistrationModal()">新規追加</el-button>
        </div>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <el-table
                ref="appRecipeMenuListArticleTable"
                :data="appRecipeMenuListArticle"
                style="width: 100%"
                max-height="800"
        >
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="タイトル" prop="title"></el-table-column>
            <el-table-column label="サムネイル画像" width="350">
                <template slot-scope="scope">
                    <div class="image">
                        <label :for="'image_' + scope.row.id">
                            <img v-if="scope.row.thumbnail_cdn_url" :src="scope.row.thumbnail_cdn_url" width="100" alt="サムネイル">
                        </label>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="並び順" prop="pivot.order_num"></el-table-column>
            <el-table-column label="公開日時" prop="published_at"></el-table-column>
            <el-table-column label="noindex" prop="is_noindex">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_noindex" disabled></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column label="作成日時" prop="created_at"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="danger"
                            @click="destroyAppRecipeMenuListArticle(scope.row.id)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <registration-modal
                :is-active="registration.isActive"
                :app-recipe-menu-list-title="registration.appRecipeMenuListTitle"
                @close="registration.isActive = false"
                @reload="fetch()"
        ></registration-modal>
    </div>
</template>

<script>
import * as types        from '../../../store/mutation-types'
import Pagination        from '../../../components/Pagination'
import RegistrationModal from './Article/RegistrationModal'

export default {
    components: {
        RegistrationModal,
        Pagination,
    },
    data() {
        return {
            pagination     : {},
            appRecipeMenuListTitle  :'',
            appRecipeMenuListArticle: [],
            registeredIds  : [],
            registration   : {
                isActive: false,
                appRecipeMenuList: {},
            },
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '記事一覧')
        this.init()
    },
    methods   : {
        init() {
            this.fetch()
        },
        // 検索
        async fetch() {
            this.$store.commit(types.LOADING, true)
            const page = this.$route.query.page
            const params = {page}
            const {data, error} = await axios.get('/api/organization/app_recipe_menu/lists/' + this.$route.params.appRecipeMenuListId + '/articles', {params})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'データの取得に失敗しました。')
                return
            }
            if (data.data.length !== 0) {
                this.appRecipeMenuListTitle = data.data[0].title
                this.$store.commit(types.PAGE_TITLE, data.data[0].title + ' - 記事一覧')
                this.appRecipeMenuListArticle = data.data[0].articles
            }
            this.pagination = Object.assign({}, this.pagination, data)
            this.$store.commit(types.LOADING, false)
        },
        // APPレシピリスト記事を削除
        async destroyAppRecipeMenuListArticle(articleId) {
            if (!confirm('本当に削除しますか？')) {
                return
            }
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.delete('/api/organization/app_recipe_menu/lists/' + this.$route.params.appRecipeMenuListId + '/articles/' + articleId)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの削除に成功しました。')
        },
        openRegistrationModal() {
            this.registration.isActive = true
            this.registration.appRecipeMenuListTitle = this.appRecipeMenuListTitle
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
