<template>
    <el-dialog
            :title="appRecipeMenuListTitle"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
    >
        <el-form ref="form" :inline="true" :model="searchForm">
            <el-form-item>
                <el-input
                        v-model="searchForm.articleId"
                        placeholder="ID"
                        type="number"
                        min="1"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-input
                        v-model="searchForm.title"
                        placeholder="タイトル"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-input
                        v-model="searchForm.description"
                        placeholder="ディスクリプション"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="searchArticle">検索</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="searchArticles"
                style="width: 100%"
        >
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="タイトル" prop="title"></el-table-column>
            <el-table-column label="サムネイル画像" width="350">
                <template slot-scope="scope">
                    <div class="image">
                        <label :for="'image_' + scope.row.id">
                            <img v-if="scope.row.thumbnail_cdn_url" :src="scope.row.thumbnail_cdn_url" width="100" alt="サムネイル">
                        </label>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="並び順" prop="pivot.order_num"></el-table-column>
            <el-table-column label="公開日時" prop="published_at"></el-table-column>
            <el-table-column label="noindex" prop="is_noindex">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_noindex" disabled></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column label="作成日時" prop="created_at"></el-table-column>
            <el-table-column label="追加">
                <template slot-scope="scope">
                    <el-button
                            type="primary"
                            @click="addAppRecipeMenuListArticle(scope.row.id)"
                    >追加
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="searchArticle" :pagination="pagination"/>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">閉じる</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../../store/mutation-types'
import Pagination from '../../../../components/Pagination'

export default {
    components: {Pagination},
    props     : {
        appRecipeMenuListTitle: String,
        isActive     : Boolean,
    },
    data() {
        return {
            searchForm    : {
                articleId  : '',
                title      : '',
                description: '',
            },
            searchArticles: [],
            pagination    : {},
            isInit        : false,
        }
    },
    methods: {
        // モーダルデータ初期化
        async init() {
            this.isInit = true
            await this.searchArticle()
            await this.$emit('reload')
            this.isInit = false
        },
        // フォーム初期化
        formInit() {
            this.searchForm = {
                article_id : '',
                title      : '',
                description: '',
            }
        },
        // モーダル閉じ
        close() {
            this.formInit()
            this.$emit('close')
        },
        // 記事検索
        async searchArticle(page = 1) {
            if (!this.isInit) {
                this.$store.commit(types.LOADING, true)
            }
            const params = {page}
            if (this.searchForm.articleId !== '') {
                params.article_id = this.searchForm.articleId
            }
            if (this.searchForm.title !== '') {
                params.title = this.searchForm.title
            }
            if (this.searchForm.description !== '') {
                params.description = this.searchForm.description
            }
            const {data, error} = await axios.get('/api/organization/app_recipe_menu/lists/' + this.$route.params.appRecipeMenuListId + '/articles/unregister', {params})
            if (!this.isInit) {
                this.$store.commit(types.LOADING, false)
            }
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '検索に失敗しました。')
                return
            }
            this.searchArticles = data.data
            this.pagination = Object.assign({}, this.pagination, data)
        },
        // 記事追加
        async addAppRecipeMenuListArticle(articleId) {
            this.$store.commit(types.LOADING, true)
            const params = {
                article_id: articleId,
            }
            const {error} = await axios.post('/api/organization/app_recipe_menu/lists/' + this.$route.params.appRecipeMenuListId + '/articles', params)
            if (error) {
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '記事の追加に失敗しました。')
                return
            }
            this.init()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの追加に成功しました。')
        },
    },
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }

        dt {
            width: 15%;
        }

        dd {
            width: 85%;
        }

        .type-detail-select,
        .type-select {
            width: 400px;
        }
    }
</style>
