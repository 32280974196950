<template>
    <div>
        <el-dialog
            :title="'タイアップ記事'+title"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
        >
            <el-form ref="form" :model="editForm" label-width="180px">
                <el-form-item v-if="isCreate" label="記事ID">
                    <el-input-number
                        v-model="editForm.article_id"
                        :min="0"
                        style="width: 300px;"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="タイアップ記事サムネイル">
                    <el-upload
                        action=""
                        accept="image/png,image/jpeg"
                        list-type="picture"
                        :show-file-list="false"
                        :auto-upload="false"
                        :on-change="handleThumbnailAdd"
                        :on-remove="handleThumbnailRemove"
                    >
                        <img v-if="previewThumbnail" :src="previewThumbnail" class="tieup-thumbnail">
                        <el-button v-else size="small" type="primary">画像選択</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="タイアップ記事タイトル">
                    <el-input v-model="editForm.tieup_title" placeholder="タイアップ記事タイトル"></el-input>
                </el-form-item>
                <el-form-item label="提供">
                    <el-input v-model="editForm.company_name" placeholder="提供"></el-input>
                </el-form-item>
                <el-form-item label="提供を非表示にする">
                    <el-checkbox v-model="editForm.is_hide_company_name"></el-checkbox>
                </el-form-item>
                <el-form-item label="おすすめ表示期間">
                    <el-date-picker
                        v-model="recommendAtList"
                        type="datetimerange"
                        start-placeholder="おすすめ開始日時"
                        end-placeholder="おすすめ終了日時"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="配信チャネル">
                    <el-checkbox v-model="editForm.is_webpc">WebPC</el-checkbox>
                    <el-checkbox v-model="editForm.is_websp">WebSP</el-checkbox>
                    <el-checkbox v-model="editForm.is_ios">iOS</el-checkbox>
                    <el-checkbox v-model="editForm.is_android">Android</el-checkbox>
                </el-form-item>
                <el-form-item label="トラッキングタグ">
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="トラッキングタグ"
                        v-model="editForm.tracking_tag">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">キャンセル</el-button>
                <el-button type="primary" @click="save">保存</el-button>
            </span>

        </el-dialog>
    </div>
</template>

<script>

import moment from 'moment'

export default {
    props: {
        isActive: Boolean,
        tieupArticle: Object,
        title: String,
    },
    data() {
        return {
            editForm: {
                article_id: '',
                tieup_title: '',
                tieup_thumbnail: '',
                company_name: '',
                is_hide_company_name: false,
                tracking_tag: '',
                is_webpc: false,
                is_websp: false,
                is_ios: false,
                is_android: false,
                recommend_start_at: '',
                recommend_end_at: '',
            },
            previewThumbnail: '',
            recommendAtList: [],
            isCreate: false,
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        // 編集
        async save() {
            if (this.editForm.tieup_thumbnail === '') {
                delete this.editForm.tieup_thumbnail
            }

            // 画像UPするためFromData使用
            const fd = new FormData()
            // 新規作成時
            if (this.isCreate) {
                fd.append('article_id', this.editForm.article_id)
            }
            // サムネイル存在時
            if (typeof this.editForm.tieup_thumbnail !== 'undefined') {
                fd.append('tieup_thumbnail', this.editForm.tieup_thumbnail)
            }
            // おすすめ日時存在時
            if (this.recommendAtList !== null) {
                fd.append('recommend_start_at', this.recommendAtList[0] ? moment((new Date(this.recommendAtList[0])).getTime()).format('YYYY-MM-DD HH:mm:ss') : '')
                fd.append('recommend_end_at', this.recommendAtList[1] ? moment((new Date(this.recommendAtList[1])).getTime()).format('YYYY-MM-DD HH:mm:ss') : '')
            } else {
                fd.append('recommend_start_at', '')
                fd.append('recommend_end_at', '')
            }
            fd.append('tieup_title', this.editForm.tieup_title ? this.editForm.tieup_title : '')
            fd.append('company_name', this.editForm.company_name ? this.editForm.company_name : '')
            fd.append('is_hide_company_name', this.editForm.is_hide_company_name ? 1 : 0)
            fd.append('is_webpc', this.editForm.is_webpc ? 1 : 0)
            fd.append('is_websp', this.editForm.is_websp ? 1 : 0)
            fd.append('is_ios', this.editForm.is_ios ? 1 : 0)
            fd.append('is_android', this.editForm.is_android ? 1 : 0)
            fd.append('tracking_tag', this.editForm.tracking_tag ? this.editForm.tracking_tag : '')

            this.$emit('save', fd, this.tieupArticle.id)
        },
        // サムネイル選択時
        handleThumbnailAdd(file, fileList) {
            this.editForm.tieup_thumbnail = file.raw
            this.previewThumbnail = file.url
        },
        // サムネイル画像削除時
        handleThumbnailRemove(file, fileList) {
            this.editForm.tieup_thumbnail = ''
            this.previewThumbnail = ''
        },
    },
    watch: {
        tieupArticle() {
            if (Object.keys(this.tieupArticle).length) {
                // 編集
                this.previewThumbnail = this.tieupArticle.tieup_thumbnail ? this.tieupArticle.tieup_thumbnail_cdn_url : null
                this.editForm = Object.assign({}, this.tieupArticle, { tieup_thumbnail: '' })
                this.isCreate = false
            } else {
                // 新規作成
                this.editForm = {}
                this.isCreate = true
            }
            this.recommendAtList = [
                this.tieupArticle.recommend_start_at ? this.tieupArticle.recommend_start_at : '',
                this.tieupArticle.recommend_end_at ? this.tieupArticle.recommend_end_at : ''
            ]
        }
    },
}
</script>


<style lang="scss" scoped>
    .tieup-thumbnail{
        width: 250px;
    }
</style>