<template>
    <el-dialog
        title="ライター情報更新"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
    >
        <div class="content">
            <dl>
                <dt>資格</dt>
                <dd>
                    <div v-for="(writerLicense, idx) in writerLicenses" :key="idx">
                        <el-row class="writerLicenseList">
                            <el-col :span="21">
                                <el-autocomplete
                                    v-model="writerLicense.license_name"
                                    type="text"
                                    size="mini"
                                    :fetch-suggestions="handleSuggestLicense"
                                    @select="handleSelectSuggestLicense($event, idx)"
                                    placeholder="資格名"
                                    class="writerLicenseNameInput"
                                ></el-autocomplete>
                            </el-col>
                            <el-col :span="2" :offset="1">
                                <i class="el-icon-remove-outline licenseDeleteIcon" @click="handleDeleteWriterLicense(idx)"></i>
                            </el-col>
                        </el-row>
                    </div>
                    <el-button @click="handleAddWriterLicense">行を追加</el-button>
                </dd>
                <dt>在住地</dt>
                <dd>
                    <el-select placeholder="在住地" v-model="prefecture_id" class="search">
                        <el-option
                            v-for="(item) in prefecture"
                            :key="item.id"
                            :label="item.prefecture_name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </dd>
                <dt>得意なジャンル</dt>
                <dd>
                    <el-input type="textarea" class="title" :autosize="{ minRows: 4}" v-model="strong_field"></el-input>
                </dd>
                <dt>過去の実績</dt>
                <dd>
                    <el-input type="textarea" class="title" :autosize="{ minRows: 4}" v-model="history"></el-input>
                </dd>
                <dt>メモ</dt>
                <dd>
                    <el-input type="textarea" class="title" :autosize="{ minRows: 4}" v-model="memo"></el-input>
                </dd>
                <dt>ライター窓口</dt>
                <dd>
                    <el-autocomplete
                        v-model="editor_admin_editor_name"
                        type="text"
                        size="mini"
                        :fetch-suggestions="querySearchAdmin"
                        @select="setEditorAdminId"
                        placeholder="ライター窓口"
                    ></el-autocomplete>
                </dd>
                <dt>フラグ</dt>
                <dd style="display: inline-block">
                    <el-checkbox v-model="can_rewrite">リライト可</el-checkbox>
                    <el-checkbox v-model="can_macaronimate">メイト</el-checkbox>
                    <el-checkbox v-model="can_supervisor">監修者</el-checkbox>
                    <el-checkbox v-model="can_cook_specialist">料理家</el-checkbox>
                    <el-checkbox v-model="can_payee">支払い対象者</el-checkbox>
                </dd>
                <div style="width: 30%">執筆カテゴリ</div>
                <div style="width: 70%">
                    <el-checkbox-group v-model="checkedCategories">
                        <el-checkbox v-for="category in categories" :label="category.id" :key="category.id">{{category.category_name}}</el-checkbox>
                    </el-checkbox-group>
                </div>
                <dt>所属チーム</dt>
                <dd>
                <el-select v-model="writer_team_id" placeholder="所属チーム" size="mini">
                    <el-option
                        v-for="writerTeam in writerTeams"
                        :key="writerTeam.id"
                        :label="writerTeam.name"
                        :value="writerTeam.id">
                    </el-option>
                </el-select>
                </dd>
            </dl>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="add()">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>

const writerLicenseDefaultValue = {
    id: null,
    license_name: null,
}

export default {
    props: {
        isActive: Boolean,
        row:Object,
        prefecture:Array,
        categories: Array,
        writerTeams: Array,
        adminSuggestList: Array,
        licenses: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            id : '',
            strong_field : '',
            history: '',
            memo: '',
            prefecture_id:'',
            writer_team_id:'',
            checkedCategories: [],
            editor_admin_name: '',
            editor_admin_editor_name: '',
            can_rewrite: false,
            can_macaronimate: false,
            can_supervisor: false,
            can_cook_specialist: false,
            can_payee: false,
            writerLicenses: [],
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        async add() {
            const params = {
                id: this.id,
                prefecture_id: this.prefecture_id,
                strong_field: this.strong_field,
                history: this.history,
                memo: this.memo,
                editor_admin_name: this.editor_admin_editor_name,
                can_rewrite: this.can_rewrite,
                can_macaronimate: this.can_macaronimate,
                can_supervisor: this.can_supervisor,
                can_cook_specialist: this.can_cook_specialist,
                can_payee: this.can_payee,
                category_ids: this.checkedCategories,
                writer_team_id: this.writer_team_id,
                writer_licenses: structuredClone(this.writerLicenses)
            }
            this.$emit('update', params)
        },
        querySearchAdmin(queryString, cb) {
            const values = this.adminSuggestList
            const results = queryString ? values.filter(this.createFilter(queryString)) : values
            cb(results)
        },
        createFilter(queryString) {
            return (item) => {
                return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
            }
        },
        setEditorAdminId(item) {
            this.editor_admin_name = item.value
        },
        handleAddWriterLicense() {
            this.writerLicenses.push(structuredClone(writerLicenseDefaultValue))
        },
        handleDeleteWriterLicense(idx) {
            this.writerLicenses.splice(idx, 1)
        },
        handleSuggestLicense(queryString, cb) {
            const suggestList = queryString
                ? this.licenses.filter((license) => license.license_name.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
                : structuredClone(this.licenses)
            cb(suggestList.map((license) => ({ value: license.license_name })))
        },
        handleSelectSuggestLicense(license, idx) {
            this.writerLicenses[idx].license_name = license.value
        },
    },
    watch: {
        row() {
            this.id = this.row.id
            this.prefecture_id = this.row.prefecture_id
            this.writer_team_id = this.row.writer_team_id
            this.history = this.row.history
            this.strong_field = this.row.strong_field
            this.memo = this.row.memo
            this.can_rewrite = this.row.can_rewrite
            this.can_macaronimate = this.row.can_macaronimate
            this.can_supervisor = this.row.can_supervisor
            this.can_cook_specialist = this.row.can_cook_specialist
            this.can_payee = this.row.can_payee
            this.editor_admin_name = this.row.editor_admin_name
            this.editor_admin_editor_name = this.row.editor_admin_editor_name
            if (this.row.category.length > 0) {
                for (let i = 0; i < this.row.category.length; i++) {
                    this.checkedCategories[i] = this.row.category[i].id
                }
            } else {
                this.checkedCategories = []
            }
            this.writerLicenses = structuredClone(this.row.writer_licenses)
        },
    }
}
</script>

<style lang="scss" scoped>
    .content {
        dl {
            display: flex;
            flex-wrap: wrap;
        }
        dt {
            margin-top: 2%;
            width: 30%;
        }

        dd {
            margin-top: 3%;
            margin-bottom:3%;
            width: 70%;
        }
    }
    .preview-img {
        width: 250px;
    }
    .licenseDeleteIcon {
        cursor: pointer;
        font-size: 20px;
    }
    .writerLicenseList {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    .writerLicenseNameInput {
        display: block;
    }
</style>
