<template>
    <el-dialog title="記事毎の固定単価を入力" @close="closeDialog" :visible="dialog" height="50%">
        <el-form :model="form"  label-width="120px">
            <el-form-item label="記事単価" >
                <el-col :span="14">
                    <el-tooltip class="item" effect="dark" content="この欄が0でない場合、この記事の単価が個別に計算されます" placement="bottom-start">
                        <el-input v-model="form.fee.manual_unit_price" placeholder="0">
                            <template slot="append">円</template>
                        </el-input>
                    </el-tooltip>
                </el-col >
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">キャンセル</el-button>
            <el-button type="primary" @click="submitForm">変更する</el-button>
        </span>
    </el-dialog>
</template>
<script>

export default {
    name: 'TrWriterFeeArticleEditor',
    props: {
        dialog: Boolean,
        input: Object,
        save: Function,
        closeDialog: Function
    },
    data () {
        return {
            gradeList: [],
            form: {
                fee: {
                    manual_unit_price: 0,
                }
            }
        }
    },
    watch: {
        input() {
            this.form.fee.manual_unit_price = 0
            //formで定義されているプロパティのみ親コンポーネントから受け取り
            this.form.fee = Object.assign({}, this.form.fee, _.pick(this.input.fee, Object.keys(this.form.fee)))
        },
    },
    methods: {
        submitForm () {
            this.save(this.input.id, this.form)
            this.closeDialog()
        }
    }
}
</script>
