<template>
    <el-table class="list" :data="tableData" style="font-size: 12px" max-height="800">
        <el-table-column width="60" label="ID">
            <template slot-scope="scope" v-if="scope.row.article.id">
                <a :href="scope.row.article.id" target="_blank">{{ scope.row.article.id }}</a>
            </template>
        </el-table-column>
        <el-table-column label="タイトル" >
            <template slot-scope="scope" v-if="scope.row.article.title">
                {{ scope.row.article.title }}
            </template>
        </el-table-column>
        <el-table-column width="160" label="ライター" >
            <template slot-scope="scope">
                <el-select v-model="scope.row.article.writer_id"
                           size="mini"
                >
                    <el-option
                        v-for="item in writerList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column width="160" label="動画ID" >
            <template slot-scope="scope">
                <el-input
                    type="text"
                    v-model="scope.row.video.video_code">
                </el-input>
            </template>
        </el-table-column>
        <el-table-column width="90" label="記事ステータス" >
            <template slot-scope="scope" v-if="articleStatusList[scope.row.article.status]">
                {{ articleStatusList[scope.row.article.status] }}
            </template>
        </el-table-column>
        <el-table-column width="140" label="公開日時" >
            <template slot-scope="scope">
                <div v-if="scope.row.article.published_at">
                    {{ scope.row.article.published_at }}
                </div>
                <div v-else>
                    ---
                </div>
            </template>
        </el-table-column>
        <el-table-column width="140" label="公開予約日時" >
            <template slot-scope="scope">
                <div v-if="scope.row.article.reserved_at">
                    {{ scope.row.article.reserved_at }}
                </div>
                <div v-else>
                    ---
                </div>
            </template>
        </el-table-column>
        <el-table-column width="60" label="操作">
            <template slot-scope="scope" >
                <div v-if="scope.row.video.id">
                    <el-button
                        class="btnEdit unitBtn"
                        size="mini"
                        type="warning"
                        @click="doUpdate(scope.row)"
                    >更新</el-button>
                </div>
            </template>
        </el-table-column>
        <el-table-column width="140" label="公開日設定">
            <template slot-scope="scope" >
                <div v-if="scope.row.article.released_at && scope.row.article.released_at !== downDate">
                    <el-button
                        class="btnEdit unitBtn"
                        size="mini"
                        type="danger"
                        @click="doDownPublishedAt(scope.row)"
                    >公開日を下げる</el-button>
                </div>
                <div v-else-if="scope.row.article.released_at && scope.row.article.released_at === downDate">
                    <el-button
                    class="btnEdit unitBtn"
                    size="mini"
                    type="primary"
                    @click="doUpPublishedAt(scope.row)"
                    >公開日を戻す</el-button>
                </div>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    name: 'VideoRow',
    props: {
        tableData: Array,
        writerList: Array,
        articleStatusList: Array,
        downDate: String,
        update: Function,
        downPublishedAt: Function,
        upPublishedAt: Function
    },
    methods: {
        doUpdate (form) {
            this.update(form)
        },
        doDownPublishedAt (form) {
            this.downPublishedAt(form)
        },
        doUpPublishedAt (form) {
            this.upPublishedAt(form)
        },
    }
}
</script>

<style scoped>

</style>
