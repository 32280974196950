<template>
    <div>
        <section class="content">
            <el-table :data="tableData">
                <el-table-column prop="masterName" label="マスタ名" width="150px"></el-table-column>
                <el-table-column prop="masterUrls" label="マスタデータ" width="200px">
                    <template v-if="scope.row.masterCsv" slot-scope="scope">
                        <div class="buttonList">
                            <csv-upload
                                :name="scope.row.masterCsv.upload.name"
                                :url="scope.row.masterCsv.upload.url"
                            ></csv-upload>
                            <csv-download
                                el-button-type="danger"
                                :name="scope.row.masterCsv.download.name"
                                :url="scope.row.masterCsv.download.url"
                            ></csv-download>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="記事との紐付け" width="200px">
                    <template v-if="scope.row.articleRelationCsv" slot-scope="scope">
                        <div class="buttonList">
                            <csv-upload
                                :name="scope.row.articleRelationCsv.upload.name"
                                :url="scope.row.articleRelationCsv.upload.url"
                            ></csv-upload>
                            <csv-download
                                el-button-type="danger"
                                :name="scope.row.articleRelationCsv.download.name"
                                :url="scope.row.articleRelationCsv.download.url"
                            ></csv-download>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="食材名との紐付け" width="200px">
                    <template v-if="scope.row.materialRelationCsv" slot-scope="scope">
                        <div class="buttonList">
                            <csv-upload
                                :name="scope.row.materialRelationCsv.upload.name"
                                :url="scope.row.materialRelationCsv.upload.url"
                            ></csv-upload>
                            <csv-download
                                el-button-type="danger"
                                :name="scope.row.materialRelationCsv.download.name"
                                :url="scope.row.materialRelationCsv.download.url"
                            ></csv-download>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="メイン食材" width="200px">
                    <template v-if="scope.row.mainMaterialCsv" slot-scope="scope">
                        <div class="buttonList">
                            <csv-upload
                                :name="scope.row.mainMaterialCsv.upload.name"
                                :url="scope.row.mainMaterialCsv.upload.url"
                            ></csv-upload>
                            <csv-download
                                el-button-type="danger"
                                :name="scope.row.mainMaterialCsv.download.name"
                                :url="scope.row.mainMaterialCsv.download.url"
                            ></csv-download>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </section>

        <section class="content">
            <h2>SVGアイコンBase64変換ツール</h2>
            <p>SVGをテキストに変換します。アイコンをCSVに含める場合に使用してください。</p>
            <el-upload
                :show-file-list="false"
                :auto-upload="false"
                :on-change="svgToBase64"
                accept="image/svg+xml"
                action=""
                drag
                multiple
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">D&Dまたはクリックして画像を選択してください。<br>複数枚を一度に処理可能です。</div>
                <div class="el-upload__tip" slot="tip">5KBまでのsvgに対応しています。</div>
            </el-upload>
            <ul v-if="images.length" class="previewList">
                <li v-for="image in images" :key="image.uid" class="previewItem">
                    <img :src="`data:image/svg+xml;base64,${image.base64}`" alt="image.name">
                    <div>{{ image.name }}</div>
                    <el-button size="mini" icon="el-icon-copy-document" @click="copyToClipBoard(image.base64)">Base64コピー</el-button>
                </li>
                <li class="previewItem">
                    <el-button type="info" size="mini" icon="el-icon-circle-close" @click="images = []">リセット</el-button>
                </li>
            </ul>
        </section>
    </div>
</template>
<script>
import * as types from '../../store/mutation-types'
import CsvUpload from '../../components/CsvUpload'
import CsvDownload from '../../components/CsvDownload'
export default {
    components: {
        CsvUpload,
        CsvDownload
    },
    data() {
        return {
            tableData: [
                {
                    masterName: '食材名',
                    masterCsv: {
                        upload: {
                            name: 'csv',
                            url: '/api/recipe/csv/material_categories',
                        },
                        download: {
                            name: 'material_categories.csv',
                            url: '/api/recipe/csv/material_categories',
                        },
                    },
                    articleRelationCsv: null,
                    materialRelationCsv: {
                        upload: {
                            name: 'csv',
                            url: '/api/recipe/csv/materials',
                        },
                        download: {
                            name: 'materials.csv',
                            url: '/api/recipe/csv/materials',
                        },
                    },
                    mainMaterialCsv: {
                        upload: {
                            name: 'csv',
                            url: '/api/recipe/csv/main_materials',
                        },
                        download: {
                            name: 'main_materials.csv',
                            url: '/api/recipe/csv/main_materials',
                        },
                    },
                },
                {
                    masterName: '料理区分',
                    masterCsv: {
                        upload: {
                            name: 'csv',
                            url: '/api/recipe/csv/menu_class_categories',
                        },
                        download: {
                            name: 'menu_class_categories.csv',
                            url: '/api/recipe/csv/menu_class_categories',
                        },
                    },
                    articleRelationCsv: {
                        upload: {
                            name: 'csv',
                            url: '/api/recipe/csv/menu_class_categories/relationships',
                        },
                        download: {
                            name: 'menu_class_category_relationships.csv',
                            url: '/api/recipe/csv/menu_class_categories/relationships',
                        },
                    },
                    materialRelationCsv: null,
                    mainMaterialCsv: null,
                },
                {
                    masterName: '調理法',
                    masterCsv: {
                        upload: {
                            name: 'csv',
                            url: '/api/recipe/csv/cooking_method_categories',
                        },
                        download: {
                            name: 'cooking_method_categories.csv',
                            url: '/api/recipe/csv/cooking_method_categories',
                        },
                    },
                    articleRelationCsv: {
                        upload: {
                            name: 'csv',
                            url: '/api/recipe/csv/cooking_method_categories/relationships',
                        },
                        download: {
                            name: 'cooking_method_category_relationships.csv',
                            url: '/api/recipe/csv/cooking_method_categories/relationships',
                        },
                    },
                    materialRelationCsv: null,
                    mainMaterialCsv: null,
                }
            ],
            header: {
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            images: [],
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'レシピDBマスタ管理')
    },
    methods: {
        svgToBase64(file) {
            if (file.size > 5000) {
                this.$message.error('5KBまでのsvgに対応しています。')
                return
            }

            const reader = new FileReader()
            reader.onload = (event) => {
                this.images.push({
                    uid: file.uid,
                    name: file.name,
                    base64: event.currentTarget.result.replace(/^data:image\/svg\+xml;base64,/, '')
                })
            }
            reader.readAsDataURL(file.raw)
        },
        copyToClipBoard(text) {
            navigator.clipboard.writeText(text).then(() => {
                this.$message.success('クリップボードにコピーしました。')
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    margin: 0 0 50px 0;
}

.buttonList {
    height: 95px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.previewList {
    gap: 15px 0;
    display: flex;
    flex-direction: column;
    margin: 20px 0 0 0;
}
.previewItem {
    display: flex;
    gap: 0 10px;
    align-items: center;
}
</style>
