<template>
    <div>
        <el-row type="flex" justify="end">
            <div class="create-button">
                <el-button type="primary" @click="openCreateModal">新規作成</el-button>
            </div>
        </el-row>
        <el-row type="flex" justify="end" style="margin-top: 10px;">
            <el-form :inline="true" :model="searchForm">
                <el-form-item>
                    <el-checkbox v-model="searchForm.is_published">公開中のみ表示</el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="searchForm.channel" placeholder="配信OS" multiple>
                        <el-option
                            v-for="channel in channels"
                            :key="channel.value"
                            :label="channel.label"
                            :value="channel.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="search-action">
                    <div class="search-action-wrapper"  @click="search()">
                        <i class="el-icon-search"></i>
                    </div>
                </div>
            </el-form>
        </el-row>
        <el-table
                :data="notifications"
                style="width: 100%"
                max-height="800"
        >
            <el-table-column label="お知らせID" prop="id"></el-table-column>
            <el-table-column label="タイトル" prop="title"></el-table-column>
            <el-table-column label="サブタイトル" prop="sub_title"></el-table-column>
            <el-table-column label="公開日時" prop="publish_start_at"></el-table-column>
            <el-table-column label="公開終了日時" prop="publish_end_at"></el-table-column>
            <el-table-column label="URL" prop="url"></el-table-column>
            <el-table-column label="配信OS">
                <template slot-scope="scope">
                    <div>IOS :  <i v-if="scope.row.is_ios" class="el-icon-check"></i></div>
                    <div>Android : <i v-if="scope.row.is_android" class="el-icon-check"></i></div>
                </template>
            </el-table-column>
            <el-table-column label="画像">
                <template slot-scope="scope">
                    <img :src="scope.row.image" width="100px">
                </template>
            </el-table-column>
            <el-table-column label="画像サイズ">
                <template slot-scope="scope">
                    {{ imageSizeLabels[scope.row.image_size] }}
                </template>
            </el-table-column>
            <el-table-column align="right" width="150px">
                <template slot-scope="scope">
                    <el-button
                        @click="openEditModal(scope.row)"
                        size="mini"
                    >編集</el-button>
                    <el-button
                            type="danger"
                            size="mini"
                            @click="destroy(scope.row.id)"
                    >削除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="search" :pagination="pagination" />

        <!-- 新規作成 -->
        <edit-modal
            :notification="editNotification"
            :transition-types="transitionTypes"
            :image-sizes="imageSizes"
            :is-active="isModalActive.create"
            title="お知らせ新規作成"
            :app-tabs="appTabs"
            :app-tab-id-type-list="appTabIdTypeList"
            @close="closeCreateModal"
            @save="create"
        ></edit-modal>

        <!-- 編集 -->
        <edit-modal
            :notification="editNotification"
            :transition-types="transitionTypes"
            :image-sizes="imageSizes"
            :is-active="isModalActive.edit"
            title="お知らせ更新"
            :app-tabs="appTabs"
            :app-tab-id-type-list="appTabIdTypeList"
            @close="closeEditModal"
            @save="update"
        ></edit-modal>
    </div>
</template>

<script>
import { PAGE_TITLE , LOADING } from '../../../store/mutation-types'
import * as types from '../../../store/mutation-types'
import Pagination from '../../../components/Pagination'
import EditModal from './Notification/EditModal'

export default {
    components: { Pagination, EditModal },
    data() {
        return {
            notifications: [],
            channels: [],
            imageSizes: [],
            imageSizeLabels: {},
            transitionTypes: [],
            transitionTypeValues: {},
            pagination: {},
            isModalActive: {
                create: false,
                edit: false,
            },
            searchForm: {
                is_published: false,
                channel: '',
                page: 1,
            },
            editNotification: {},
            appTabs: [],
            appTabIdTypeList: {},
        }
    },
    beforeRouteUpdate(to, from, next) {
        next()
        this.fetch()
    },
    created() {
        this.$store.commit(PAGE_TITLE, 'アプリ　>　お知らせ')
        this.fetch()
    },
    methods: {
        // 一覧取得
        async fetch() {
            this.$store.commit(LOADING, true)

            const { data, error } = await axios.get('/api/app/notifications', {
                params: { ...this.$route.query }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            this.notifications = Object.assign([], data.notifications.data)
            this.pagination = Object.assign({}, data.notifications)
            this.channels = Object.assign([], data.channels)
            this.imageSizes = Object.assign([], data.image_sizes)
            Object.keys(this.imageSizes).forEach((key) => {
                this.imageSizeLabels[this.imageSizes[key].value] = this.imageSizes[key].label
            })
            this.transitionTypes = Object.assign([], data.transition_types)
            this.appTabs = Object.assign([], data.app_tabs)
            Object.keys(this.appTabs).forEach((key) => {
                this.$set(this.appTabIdTypeList, this.appTabs[key].id, this.appTabs[key].type)

            })
            this.$store.commit(LOADING, false)
        },
        // 検索
        search (page = 1) {
            let params = Object.assign({}, this.searchForm)
            params.page = page
            params.is_published = params.is_published ? 1 : 0
            const query = Object.assign({}, this.$route.query, params)
            this.$router.push({ query })
        },
        // 削除
        async destroy(id) {
            if (!confirm('本当に削除しますか？')) {
                return
            }
            this.$store.commit(LOADING, true)
            const { error } = await axios.delete('/api/app/notifications/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(LOADING, false)
                return
            }
            this.$store.commit(LOADING, false)

            this.fetch()
        },
        // 新規作成
        async create(params) {
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.post('/api/app/notifications', params)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'お知らせを作成しました。')
            this.isModalActive.create = false
            this.fetch()
        },
        // 編集
        async update(params) {
            this.$store.commit(types.LOADING, true)

            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PUT'
                }
            }
            const { error } = await axios.post('/api/app/notifications/'+this.editNotification.id, params, config)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.isModalActive.edit = false
            this.fetch()
        },
        // 新規追加モーダルオープン
        openCreateModal() {
            this.editNotification = {}
            this.isModalActive.create = true
        },
        // 新規追加モーダルクローズ
        closeCreateModal() {
            this.isModalActive.create = false
        },
        // 編集追加モーダルオープン
        openEditModal(notification) {
            this.editNotification = Object.assign({}, notification)
            this.isModalActive.edit = true
        },
        // 編集追加モーダルクローズ
        closeEditModal() {
            this.isModalActive.edit = false
        },
    }
}
</script>
<style scoped>
    .search-action {
        display: inline-block;
        width: 57px;
        height: 38px;
        border-radius: 4px;
        background-color: #f5f7fa;
        color: #909399;
        border: 1px solid #DCDFE6;
    }
    .search-action-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
</style>
