<template>
    <el-dialog
            title="MIL動画新規登録"
            :visible="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
    >
        <el-form @submit.native.prevent label-width="140px" >
            <el-form-item label="タイトル" >
                <el-col :span="20">
                    <el-input v-model="title" placeholder="タイトル" />
                </el-col>
            </el-form-item>
            <el-form-item style="margin-bottom:10px;" class="uploadVideo" label="動画">
                <el-upload
                    class="avatar-uploader"
                    :auto-upload="false"
                    action=""
                    :on-change="handleVideoAdd"
                    :show-file-list="false"
                    accept="video/*"
                >
                    <p v-if="videoName" class="selectedVideo">{{videoName}}</p>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="submitForm">アップロード</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as types from '../../../store/mutation-types'

export default {
    props  : {
        isActive: Boolean,
    },
    data() {
        return {
            title: '',
            video_file: '',
            videoName: null,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        async submitForm() {
            const fd = new FormData()
            fd.append('title', this.title)
            fd.append('video_file', this.video_file)
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.post('/api/mil_movies', fd)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.title = ''
            this.video_file = ''
            this.videoName = null
            this.$emit('close')
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'MIL動画を作成しました。')
            this.$emit('reload')
        },
        handleVideoAdd(file) {
            this.video_file = file.raw
            this.videoName = file.name
        },
    },
}
</script>

<style scoped>
    .selectedVideo {
        width: 178px;
        height: 178px;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: break-all;
    }
</style>
