<template>
    <div>
        <el-row type="flex" justify="end">
            <div class="search-form">
                <el-input v-model="searchForm.tag_name" @keyup.enter.native="search" placeholder="タグ名">
                    <el-button
                        @click="search"
                        slot="append"
                        icon="el-icon-search"
                    />
                </el-input>
            </div>
        </el-row>
        <div class="tagOperation">
            <pagination :handleCurrentChange="search" :pagination="pagination" />
            <el-table
                :data="tagPaths"
                style="width: 100%; font-size: 12px;"
                max-height="800"
            >
                <el-table-column label="ID" width="70">
                    <template slot-scope="scope">
                        <a :href="makeServiceUrl(`/tags/${scope.row.id}`)" target="_blank">{{ scope.row.id }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="タグ名" width="150">
                    <template slot-scope="scope">{{ scope.row.tag.tag_name }}</template>
                </el-table-column>
                <el-table-column label="タグ種別" width="70">
                    <template slot-scope="scope">
                        <span v-if="scope.row.parent_tag_id">親タグ</span>
                        <span v-else-if="scope.row.child_tag_id">子タグ</span>
                        <span v-else-if="scope.row.grandchild_tag_id">孫タグ</span>
                    </template>
                </el-table-column>
                <el-table-column label="カテゴリー" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.category">{{ scope.row.category.category_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="ディスクリプション" width="500">
                    <template slot-scope="scope">{{ scope.row.tag.tag_description }}</template>
                </el-table-column>
                <el-table-column label="最終更新日">
                    <template slot-scope="scope">{{ scope.row.tag.updated_at }}</template>
                </el-table-column>
                <el-table-column align="right">
                    <template slot-scope="scope">
                        <el-tooltip content="食材タグは編集できません" placement="left" :disabled="scope.row.tag_class !== 'MATERIAL'">
                            <el-button
                                size="mini"
                                type="primary"
                                :disabled="scope.row.tag_class === 'MATERIAL'"
                                @click="$router.push(`/tag_operations/${scope.row.id}`)"
                            >編集</el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :handleCurrentChange="search" :pagination="pagination" />
        </div>
    </div>
</template>

<script>
import { PAGE_TITLE , LOADING } from '../../store/mutation-types'
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'

export default {
    components: {
        Pagination
    },
    data() {
        return {
            tagPaths: [],
            pagination: {},
            searchForm: {
                tag_name: '',
            },
        }
    },
    async created() {
        this.$store.commit(PAGE_TITLE, '編成　>　タグページ運用')
        await this.fetch()
    },
    methods: {
        // 検索
        async search(page) {
            const query = Number.isInteger(page)
                ? { page, ...this.searchForm }
                : { ...this.searchForm}
            this.$router.push({ query })
            await this.fetch()
        },
        async fetch() {
            this.$store.commit(LOADING, true)

            const { data, error } = await axios.get('/api/tag_operations', { params: {...this.$route.query} })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            this.pagination = {...data.tag_paths}
            this.tagPaths = data.tag_paths.data.slice()
            this.$store.commit(LOADING, false)
        }
    }
}
</script>

<style lang="scss" scoped>
    .search-form {
        margin-top: 10px;
        margin-right: 20px;
    }
    .tagOperation {
        margin-top:20px;
    }
</style>
