import { render, staticRenderFns } from "./CookingTimeType.vue?vue&type=template&id=3bcc169c&scoped=true"
import script from "./CookingTimeType.vue?vue&type=script&lang=js"
export * from "./CookingTimeType.vue?vue&type=script&lang=js"
import style0 from "./CookingTimeType.vue?vue&type=style&index=0&id=3bcc169c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bcc169c",
  null
  
)

export default component.exports