<template>
    <div class="validWriters">
        <el-table
                :data="propWriters"
                style="font-size:12px"
                max-height="800"
        >
            <el-table-column align="center" :render-header="renderTableHeader(['アカウント名','ライター名'])" width="100px">
                <template slot-scope="scope">
                    <a :href="'/writers/'+scope.row.id + '/fees'" target="_blank">{{ scope.row.writer_name }}</a>
                    <br>
                    <a :href="'/managements/writer/'+scope.row.id" target="_blank">{{ scope.row.screen_name }}</a>
                </template>
            </el-table-column>
            <el-table-column label="ライター窓口" width="100px">
                <template slot-scope="scope">
                    <div v-if="scope.row.editor_admin_editor_name">
                        {{ scope.row.editor_admin_editor_name }}
                    </div>
                    <div v-else>
                        {{ scope.row.editor_admin_name }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" :render-header="renderTableHeader(['リライト','可'])" width="70px">
                <template slot-scope="scope">
                    <el-checkbox
                            v-model="scope.row.can_rewrite"
                            disabled
                    ></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column align="center" label="メイト" width="70px">
                <template slot-scope="scope">
                    <el-checkbox
                            v-model="scope.row.can_macaronimate"
                            disabled
                    ></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column align="center" label="監修者" width="70px">
                <template slot-scope="scope">
                    <el-checkbox
                            v-model="scope.row.can_supervisor"
                            disabled
                    ></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column align="center" label="料理家" width="70px">
                <template slot-scope="scope">
                    <el-checkbox
                            v-model="scope.row.can_cook_specialist"
                            disabled
                    ></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column align="center" :render-header="renderTableHeader(['支払い','対象者'])" width="70px">
                <template slot-scope="scope">
                    <el-checkbox
                            v-model="scope.row.can_payee"
                            disabled
                    ></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column align="center" :render-header="renderTableHeader(['文字単価','記事単価', '撮影ランク'])">
                <template slot-scope="scope">
                    {{ scope.row.fee?.grade.weight ?? '-' }}
                    <br>
                    {{ scope.row.fee?.unit_price ?? '-' }}
                    <br>
                    {{ scope.row.photo_rank ?? '-' }}
                </template>
            </el-table-column>
            <el-table-column label="資格" width="300px">
                <template v-slot="scope">
                    <div v-for="(writerLicense, idx) in scope.row.writer_licenses" :key="idx">
                        {{ writerLicense.license_name }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="執筆カテゴリ">
                <template slot-scope="scope">
                    <div v-for="(val, index) in scope.row.category" :key="index" class="articleRow">
                        {{val.category_name}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="所属チーム">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.writer_team_id" placeholder="所属チーム" size="mini" disabled>
                        <el-option
                            v-for="writerTeam in writerTeams"
                            :key="writerTeam.id"
                            :label="writerTeam.name"
                            :value="writerTeam.id">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column align="right" width="80px">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        @click="openEditModal(scope.row)"
                    >編集
                    </el-button>
                    <el-button
                        size="mini"
                        type="danger"
                        @click="destroy(scope.row.id)"
                    >無効
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <update-modal
            ref="edit"
            :is-active="edit.isActive"
            :row="edit.row"
            :prefecture="prefecture"
            :categories="categories"
            :writerTeams="writerTeams"
            :adminSuggestList="adminSuggestList"
            :licenses="licenses"
            @update="update"
            @close="close"
        ></update-modal>
    </div>
</template>

<script>
import UpdateModal from './UpdateModal'

export default {
    components: {UpdateModal},
    props: {
        propWriters: Array,
        writerTeams: Array,
        prefecture: Array,
        categories: Array,
        adminSuggestList: Array,
        photoRanks: Array,
        licenses: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            rowWriters: [],
            focusedLineIndex: null,
            edit         : {
                isActive: false,
                row     : {},
            },
        }
    },
    watch: {
        propWriters: {
            handler() {
                this.rowWriters = this.propWriters
            }
        }
    },
    methods: {
        update(params) {
            this.$emit('updateWriter', params)
        },
        close() {
            this.$emit('close')
            this.edit.isActive = false
        },
        destroy(id) {
            this.$emit('destroy', id)
        },
        async querySearchAdmin(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/admins/suggest', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, '管理者検索に失敗しました')
                    return
                }
                cb(data.admins)
            }
        },
        focusSuggest(index) {
            this.focusedLineIndex = index
        },
        setEditorAdminId(item) {
            this.propWriters[this.focusedLineIndex]['editor_admin_id'] = item.admin_id
        },
        openEditModal(row) {
            this.edit.isActive = true
            this.edit.row = row
        },
    }
}
</script>
<style scoped>
    .el-select {
        margin: 5px 0 5px 10px;
    }
    .el-button {
        margin: 5px 0 5px 10px;
    }
    .el-autocomplete {
        margin: 5px 0 5px 10px;
    }
</style>

