<template>
    <div class="gurunavi">
        <el-row :class="shopListClass">
            <el-row>
                <el-form class="itemForm" @submit.native.prevent="startSearchKeyword" >
                    <el-col :span="12">
                        <el-form-item style="width:412px;">
                            <el-input
                                width="50%"
                                type="text"
                                size="mini"
                                placeholder="ジャンル、業態、店名"
                                v-model="keyword1">
                                <el-button
                                    native-type="submit"
                                    slot="append"
                                    icon="el-icon-search"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item style="width:412px;">
                            <el-input
                                width="50%"
                                type="text"
                                size="mini"
                                placeholder="エリア、駅名"
                                v-model="keyword2">
                                <el-button
                                    native-type="submit"
                                    slot="append"
                                    icon="el-icon-search"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <el-row>
                <el-table :data="list" >
                    <el-table-column prop="shopName" label="店舗名" />
                    <el-table-column prop="shopCategory" label="業態" />
                    <el-table-column prop="shopArea" label="エリア" />
                    <el-table-column label="リンク">
                        <template slot-scope="scope">
                            <a :href="scope.row.shopUrl" target="_blank">HPへ</a>
                        </template>
                    </el-table-column>
                    <el-table-column >
                        <template slot-scope="scope">
                            <el-button size="mini" @click="showImages(scope.row)">画像を見る</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row >
            <el-row v-if="list.length > 0" type="flex" justify="center">
                <el-button style="width:80%;margin-top:10px;" @click="searchMore">次を読み込む</el-button>
            </el-row>
        </el-row>
        <el-row :class="imageListClass">
            <el-row style="margin-bottom: 15px;">
                <el-row>
                    <el-button size="mini" @click="closeSelectImages">前に戻る</el-button>
                    <span v-if="shop">{{shop.shopName}}</span>
                </el-row>
                <el-row>
                    <span>※ ぐるなび側の仕様により、ぐるなび上に掲載されていても一部画像は以下に表示されない場合があります。</span>
                </el-row>
            </el-row>
            <el-row>
                <el-row v-for="(rowData,rowIndex) in arrengedList" :key="rowIndex" >
                    <el-col :span="6" v-for="(data,index) in rowData" :key="index">
                        <div class="thumbnails">
                            <img :src="data.img_url" >
                            <div class="addButton">
                                <el-button type="primary" :disabled="data.added" size="mini" @click="addItem(data, index)">
                                    <span v-if="data.added">
                                        追加済み
                                    </span>
                                    <span v-else>
                                        追加する
                                    </span>
                                </el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row >
            </el-row>
        </el-row>
        <div v-if="loading" v-loading="loading" class="loading"></div>
    </div>
</template>
<script>
export default {
    name:'ItemImageGurunavi',
    data () {
        return {
            list: [],
            page: 1,
            keyword2:null,
            shop:null,
            loading:false,
            imageList:[],
            shopListClass: '',
            imageListClass: 'hide',
            shopQuoteUrl: null,
        }
    },
    props:{
        parentKeyword:null,
        arrengeList: Function,
        imageType: String,
    },
    computed: {
        keyword1: {
            get() {
                return this.parentKeyword
            },
            set(keyword) {
                this.$emit('updateKeyword',keyword)
            }
        },
        arrengedList() {
            return this.arrengeList(this.imageList)
        },
    },
    methods: {
        async searchKeyword(){
            this.loading = true
            const {data,error} = await axios.get('/api/cooperation/gurunavi/places/search', {
                params: {
                    keyword1:this.keyword1,
                    keyword2:this.keyword2,
                    page:this.page,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, `サーバーエラーが発生しました。${error.response.data.message ?? ''}`)
                this.loading = false
                return
            }
            if (data.shops.shops.length === 0) {
                this.$message.info('条件に該当する店舗が見つかりませんでした。')
            }

            this.list = Object.assign([],this.list.concat(data.shops.shops))
            this.loading = false
        },
        searchMore() {
            this.page++
            this.searchKeyword()
        },
        startSearchKeyword() {
            this.page = 1
            this.list = []
            this.shop = null
            this.imageList = []
            this.searchKeyword()
        },
        async searchShopImage() {
            this.loading = true
            const {data,error} = await axios.get('/api/cooperation/gurunavi/images/search', {
                params: {
                    shop_id:this.shop.shopId
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, `サーバーエラーが発生しました。${error.response.data.message ?? ''}`)
                this.loading = false
                return
            }
            if (data.shop.length === 0) {
                this.$message.info('画像が見つかりませんでした。')
                this.loading = false
                return
            }

            this.imageList = data.shop.images
            this.shopQuoteUrl = data.shop.quote_url
            this.loading = false
        },
        addItem(data, index) {
            data.added = true
            this.imageList[index].added = true
            if (this.imageType === 'item') {
                this.$emit('save','Gurunavi',{
                    quote_image_url: data.img_url,
                    quote_url: this.shopQuoteUrl
                })
            } else if (this.imageType === 'icon') {
                this.$emit('saveIcon', {
                    'thumbnail_quote_image_url': data.img_url,
                })
            }
        },
        closeSelectImages() {
            this.imageListClass = 'hide'
            this.shopListClass = ''
        },
        showImages(shop) {
            if (this.shop === null || this.shop.shopId !== shop.shopId)
            {
                this.imageList = []
                this.shop = shop
                this.searchShopImage()
            }
            this.shopListClass = 'hide'
            this.imageListClass = ''
        }
    }
}

</script>
<style>
.gurunavi .hide {
    display: none;
}
</style>
