<template>
    <div>
        <el-row type="flex" justify="end" style="margin-top:10px;">
                <div id="search" class="form_line target_date">
                    <el-date-picker
                        type="date"
                        style="width: 150px; margin-top: 10px;margin-right: 5px;"
                        placeholder="対象日"
                        v-model="add_data.date"
                        @change="changeDate"
                        >
                    </el-date-picker>
                </div>
                <div id="add_data">
                    <el-time-picker
                            type="time"
                            style="width: 130px;"
                            placeholder="対象時間"
                            v-model="add_data.time">
                    </el-time-picker>
                    <el-select
                            v-model="add_data.type"
                            placeholder="掲載枠">
                        <el-option
                                v-for="( value, key ) in organization_type"
                                :key="key"
                                :label="value"
                                @change="findSuggestData"
                                :value="key"></el-option>
                    </el-select>
                    <el-input
                            v-model="add_data.id"
                            class="no_number_spinner"
                            type="number"
                            @blur="findSuggestData"
                            placeholder="記事・特集ID"
                            style="width: 130px;">
                    </el-input>
                    <el-button
                            @click="storeOrganization"
                            type="primary">
                        新規作成
                    </el-button>
                </div>
                <div id="suggest_wrapper" v-show="suggest_data.is_show">
                    <div id="suggest_data" v-if="suggest_data.title">
                        <img :src="suggest_data.thumbnail_cdn_url">
                        <div class="suggest_text">
                            <div class="suggest_title">{{ suggest_data.title }}</div>
                            <div v-show="suggest_data.status_text">（{{ suggest_data.status_text }}）</div>
                        </div>
                    </div>
                    <div id="not_found_suggest" v-else>
                        追加できる記事・特集がありません。
                    </div>
            </div>
        </el-row>
        <el-row type="flex" justify="end">
            <el-header>
                <el-col>
                    <el-upload
                        class="uploadButton"
                        accept=".csv"
                        :before-upload="importCsvStart"
                        :on-error="importCsvError"
                        :on-success="importCsvSuccess"
                        :show-file-list="false"
                        :headers="header"
                        action="/api/organizations/articles/csv/import"
                    >
                        <el-button type="warning" icon="el-icon-upload2">
                            CSVアップロード
                        </el-button>
                    </el-upload>
                    <el-button type="warning" @click="downloadCSV" icon="el-icon-download">
                        CSVテンプレートダウンロード
                    </el-button>
                </el-col>
            </el-header >
        </el-row>
        <el-row type="flex" justify="end" style="margin-top:10px;">
        <div id="new_user_time"
             v-show="is_new_user === 1">
            <span class="form_label">新規ユーザー時間</span>
            <el-select v-model="appNewUserLimitHour" class="new_user_time_select">
                <el-option
                        v-for="n in 6"
                        :key="n" :value="n">
                </el-option>
            </el-select>
            時間
            <el-button
                    @click="updateNewUserLimitHour">
                更新
            </el-button>
        </div>
        </el-row>
            <el-tabs
                    @tab-click="tabClick"
                    v-model="tabActiveName"
            >
                <el-tab-pane label="通常編成" name="default"></el-tab-pane>
                <el-tab-pane label="初回編成" name="first"></el-tab-pane>
            </el-tabs>
        <div id="organization">
            <h3 class="type_heading">特集枠</h3>
            <el-table
                    :data="organizations.topics"
                    >
                <el-table-column
                        type="index"
                        class-name="index_font"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="processed_reserve_at"
                        label="編成時間"
                        width="100">
                </el-table-column>
                <el-table-column
                        label="特集ID"
                        width="80">
                    <template slot-scope="props">
                        <a
                                v-if="props.row.topic.topic_url"
                                :href="props.row.topic.topic_url"
                                target="_blank">{{ props.row.topic.id }}</a>
                        <span
                                v-else
                        >{{ props.row.topic.id }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="タイトル">
                    <template slot-scope="props">
                        <div class="omission_text">{{ props.row.topic.title }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="バナー" width="120">
                    <template slot-scope="props">
                        <img :src="props.row.topic.thumbnail_cdn_url" class="thumbnail-img">
                    </template>
                </el-table-column>
                <el-table-column
                        width="80">
                    <template slot-scope="props">
                        <el-button
                                type="danger"
                                size="mini"
                                @click="deleteOrganization(props.row.id, 'topic')">
                            削除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <h3 class="type_heading">リッチコンテンツ枠</h3>
            <el-table
                    :data="organizations.rich"
                    >
                <el-table-column
                        type="index"
                        class-name="index_font"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="processed_reserve_at"
                        label="編成時間"
                        width="100">
                </el-table-column>
                <el-table-column
                        label="記事ID"
                        width="80">
                    <template slot-scope="props">
                        <a
                                v-if="props.row.article.article_url"
                                :href="props.row.article.article_url"
                                target="_blank">{{ props.row.article.id }}</a>
                        <span
                                v-else
                        >{{ props.row.article.id }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="タイトル">
                    <template slot-scope="props">
                        <div class="omission_text">{{ props.row.article.title }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="サムネイル" width="120">
                    <template slot-scope="props">
                        <img :src="props.row.article.thumbnail_cdn_url" class="thumbnail-img">
                    </template>
                </el-table-column>
                <el-table-column
                        width="80">
                    <template slot-scope="props">
                        <el-button
                                type="danger"
                                size="mini"
                                @click="deleteOrganization(props.row.id, 'RICH')">
                            削除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <h3 class="type_heading">動画枠</h3>
            <el-table
                    :data="organizations.movie"
                    :row-class-name="tableRowClassName"
                    >
                <el-table-column
                        type="index"
                        class-name="index_font"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="processed_reserve_at"
                        label="編成時間"
                        width="100">
                </el-table-column>
                <el-table-column
                        label="記事ID"
                        width="80">
                    <template slot-scope="props">
                        <a
                                v-if="props.row.article.article_url"
                                :href="props.row.article.article_url"
                                target="_blank">{{ props.row.article.id }}</a>
                        <span
                                v-else
                        >{{ props.row.article.id }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="タイトル・動画用料理名">
                    <template slot-scope="props">
                        <div class="omission_text">{{ props.row.article.title }}</div>
                        <div class="omission_text">
                            <el-input placeholder="動画用料理名" v-model="props.row.alt_title"></el-input>
                        </div>
                            <div class="alt_title_length">{{ getAltTitleLength(props.row.alt_title) }}文字</div>
                    </template>
                </el-table-column>
                <el-table-column
                        label="ステータス"
                        width="120">
                    <template slot-scope="props">
                        {{ getStatusValue(props.row.article.status) }}
                    </template>
                </el-table-column>
                <el-table-column label="サムネイル" width="120">
                    <template slot-scope="props">
                        <img :src="props.row.article.thumbnail_cdn_url" class="thumbnail-img">
                    </template>
                </el-table-column>
                <el-table-column
                        width="80">
                    <template slot-scope="props">
                        <el-button
                                type="warning"
                                size="mini"
                                class="update-button"
                                @click="updateAltTitle(props.row.alt_title, props.row.id)">
                            更新
                        </el-button><br>
                        <el-button
                                type="danger"
                                size="mini"
                                @click="deleteOrganization(props.row.id, 'MOVIE')">
                            削除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <h3 class="type_heading">トップピックアップ</h3>
            <el-table
                    :data="organizations.top_pickup"
                    :row-class-name="tableRowClassName"
                    >
                <el-table-column
                        type="index"
                        class-name="index_font"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="processed_reserve_at"
                        label="編成時間"
                        width="100">
                </el-table-column>
                <el-table-column
                        label="記事ID"
                        width="80">
                    <template slot-scope="props">
                        <a
                                v-if="props.row.article.article_url"
                                :href="props.row.article.article_url"
                                target="_blank">{{ props.row.article.id }}</a>
                        <span
                                v-else
                        >{{ props.row.article.id }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="タイトル・アプリ用タイトル">
                    <template slot-scope="props">
                        <div class="omission_text">{{ props.row.article.title }}</div>
                        <div class="omission_text">
                            <el-input placeholder="アプリ用タイトル" v-model="props.row.alt_title"></el-input>
                        </div>
                        <div class="alt_title_length">{{ getAltTitleLength(props.row.alt_title) }}文字</div>
                    </template>
                </el-table-column>
                <el-table-column
                        width="150"
                        label="ライター">
                    <template slot-scope="props">
                        <div class="omission_text">{{ props.row.article.writer.screen_name }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    :render-header="renderTableHeader(['ステータス','種別','カテゴリ'])"
                    width="120">
                    <template slot-scope="props">
                        <p>{{ getStatusValue(props.row.article.status) }}</p>
                        <p>{{ props.row.article.video_flag }}</p>
                        <p>{{ props.row.article.category === null ? '-' : props.row.article.category.category_name }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="サムネイル" width="120">
                    <template slot-scope="props">
                        <img :src="props.row.article.thumbnail_cdn_url" class="thumbnail-img">
                    </template>
                </el-table-column>
                <el-table-column
                        width="80">
                    <template slot-scope="props">
                        <el-button
                                type="warning"
                                class="update-button"
                                size="mini"
                                @click="updateAltTitle(props.row.alt_title, props.row.id)">
                            更新
                        </el-button><br>
                        <el-button
                                type="danger"
                                size="mini"
                                @click="deleteOrganization(props.row.id, 'TOP_PICKUP')">
                            削除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <h3 class="type_heading">ピックアップ</h3>
            <el-table
                    :data="organizations.pickup"
                    :row-class-name="tableRowClassName"
                    >
                <el-table-column
                        type="index"
                        class-name="index_font"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="processed_reserve_at"
                        label="編成時間"
                        width="100">
                </el-table-column>
                <el-table-column
                        label="記事ID"
                        width="80">
                    <template slot-scope="props">
                        <a
                                v-if="props.row.article_id"
                                :href="props.row.article.article_url"
                                target="_blank">{{ props.row.article_id }}</a>
                        <span
                                v-else
                        >{{ props.row.article_id }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="タイトル・アプリ用タイトル">
                    <template slot-scope="props">
                        <div class="omission_text">{{ props.row.article.title }}</div>
                        <div class="omission_text">
                            <el-input placeholder="アプリ用タイトル" v-model="props.row.alt_title"></el-input>
                        </div>
                        <div class="alt_title_length">{{ getAltTitleLength(props.row.alt_title) }}文字</div>
                    </template>
                </el-table-column>
                <el-table-column
                        label="ライター"
                        width="150">
                    <template slot-scope="props">
                        <div class="omission_text">{{ props.row.article.writer.screen_name }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    :render-header="renderTableHeader(['ステータス','種別','カテゴリ'])"
                    width="120">
                    <template slot-scope="props">
                        <div>
                            <p>{{ getStatusValue(props.row.article.status) }}</p>
                            <p>{{ props.row.article.video_flag }}</p>
                            <p>{{ props.row.article.category === null ? '-' : props.row.article.category.category_name }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="サムネイル" width="120">
                    <template slot-scope="props">
                        <img :src="props.row.article.thumbnail_cdn_url" class="thumbnail-img">
                    </template>
                </el-table-column>
                <el-table-column
                        width="80">
                    <template slot-scope="props">
                        <el-button
                                type="warning"
                                size="mini"
                                class="update-button"
                                @click="updateAltTitle(props.row.alt_title, props.row.id)">
                            更新
                        </el-button><br>
                        <el-button
                                type="danger"
                                size="mini"
                                @click="deleteOrganization(props.row.id, 'PICKUP')">
                            削除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <edit-modal
                    :is-active="edit.isActive"
                    :row="edit.row"
                    @save="updateAltTitle"
                    @close="edit.isActive = false"
            ></edit-modal>
        </div>
    </div>
</template>
<script>
import * as types from '../../store/mutation-types'
import EditModal from './Manage/EditModal'
import FileDownload from 'js-file-download'

export default {
    components: {
        EditModal,
    },
    data() {
        return {
            appNewUserLimitHour: 0,
            appNewUserLimitId: null,
            url_path : null,
            target_date: null,
            is_new_user: 0,
            add_data: {
                'date': null,
                'time': null,
                'type': null,
                'id': null,
            },
            suggest_data: {
                'title': null,
                'thumbnail': null,
                'status_text': null,
                'is_show': false,
            },
            organizations: {
                'topics': [],
                'rich': [],
                'movie': [],
                'top_pickup': [],
                'pickup': [],
                'article_status': [],
            },
            thumbnail: {
                'url': null,
                'class_name': null,
                'top': null,
                'left': null,
            },
            articleStatusList: {},
            articleStatusLabelList: [],
            alt_title_max_length: 191,
            organization_type: [],
            tabActiveName: 'default',
            edit        : {
                isActive: false,
                row     : {},
            },
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '編成　>　編成管理')
        this.init()
    },
    computed: {
        getStatusValue: function () {
            self = this
            return function (status) {
                for(let key of Object.keys(this.articleStatusLabelList)) {
                    if (Number(key) === Number(status)) {
                        return this.articleStatusLabelList[key]
                    }
                }
            }
        },
        getAltTitleLength: function () {
            self = this
            return function (param) {
                let altTitle = param
                if (!altTitle) {
                    return '0 / ' + this.alt_title_max_length
                } else {
                    return altTitle.length + ' / ' + this.alt_title_max_length
                }
            }
        }
    },
    methods: {
        init(reInit = false) {
            this.fetchOrganizationType()
            this.fetchOrganizationData()
            this.resetAddAndSuggestData(reInit)
        },
        openEditModal(row) {
            this.edit.isActive = true
            this.edit.row = row
        },
        async fetchOrganizationType() {
            this.$store.commit(types.PAGE_LOADING, true)
            const {data, error} = await axios.get('/api/organizations/manage/types')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            this.organization_type = data
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async fetchOrganizationData() {
            this.$store.commit(types.PAGE_LOADING, true)

            const params = {
                'is_new_user': Number(this.is_new_user),
                'reserve_at': this.getFormattedDate(this.add_data.date)
            }
            const {data, error} = await axios.get('/api/organizations/manage', {params})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            this.appNewUserLimitHour = data.app_new_user_limit_hour.limit_hour
            this.appNewUserLimitId   = data.app_new_user_limit_hour.id
            this.organizations = data
            this.articleStatusList = Object.assign([], data.article_status)
            this.articleStatusList.forEach((format) => {
                this.articleStatusLabelList[format.value] = format.label
            })
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async findSuggestData() {
            const type = this.add_data.type
            const id = Number(this.add_data.id)
            if (!type)
            {
                if (!Number(id))
                {
                    this.add_data.id = ''
                }
                return
            }

            this.$store.commit(types.PAGE_LOADING, true)

            const params = {
                'type' : type,
                'id' : id,
            }
            const { data, error } = await axios.get('/api/organizations/suggest', { params })
            if (error)
            {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'データの取得に失敗しました。')
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            this.suggest_data = data
            this.$store.commit(types.PAGE_LOADING, false)
        },
        async storeOrganization() {
            if( !confirm('編成を追加してよろしいですか？'))
            {
                return
            }
            this.$store.commit(types.PAGE_LOADING, true)

            if (this.add_data.type === 'topic')
            {
                const params = {
                    'topic_id' : Number(this.add_data.id),
                    'reserve_at' : this.getFormattedDate(this.add_data.date) + ' ' + this.getFormattedTime(this.add_data.time),
                    'is_new_user' : this.is_new_user,
                }
                var { error } = await axios.post('/api/organizations/topics', params)
            }
            else
            {
                const params = {
                    'article_id' : Number(this.add_data.id),
                    'reserve_at' : this.getFormattedDate(this.add_data.date) + ' ' + this.getFormattedTime(this.add_data.time),
                    'type' : this.add_data.type,
                    'is_new_user' :this.is_new_user,
                }
                var { error } = await axios.post('/api/organizations/articles', params)
            }

            if (error)
            {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            this.init(true)
            this.$store.commit(types.PAGE_LOADING, false)
        },
        // 編集
        async updateAltTitle(altTitle, id) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.patch('/api/organizations/articles/' + id, {
                alt_title: altTitle
            })
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            this.init(true)
        },
        // 編成データ削除
        async deleteOrganization(id, type) {
            if( ! confirm('削除してよろしいですか？'))
            {
                return
            }
            this.$store.commit(types.PAGE_LOADING, true)
            if (type === 'topic')
            {
                var { error } = await axios.delete('/api/organizations/topics/' + id)
            }
            else
            {
                var { error } = await axios.delete('/api/organizations/articles/' + id)
            }
            if (error)
            {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            this.init(true)
            this.$store.commit(types.LOADING, false)
        },
        async updateNewUserLimitHour() {
            const params = {
                'limit_hour': Number(this.appNewUserLimitHour),
            }
            const {error} = await axios.patch('/api/organization/app_new_user_limit_hour/' + Number(this.appNewUserLimitId), params)
            if (error)
            {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            this.init()
            this.$store.commit(types.LOADING, false)
        },
        changeDate () {
            this.fetchOrganizationData()
        },
        resetAddAndSuggestData (reInit = false) {
            if (!reInit) {
                const now = new Date()
                this.add_data.date = now
                this.add_data.time = now.getDate() + '00:00:00'
            }
            this.add_data.type = null
            this.add_data.id = null
            this.suggest_data.title = null
            this.suggest_data.thumbnail_cdn_url = null
            this.suggest_data.status_text = null
            this.suggest_data.is_show = false
        },
        tableRowClassName({row, rowIndex}) {
            return row.row_class_name
        },
        getFormattedDate (date) {
            let target_date = new Date()
            if (date) {
                target_date = new Date(date)
            }
            return target_date.getFullYear() + '-' + ('0'+(target_date.getMonth() + 1)).slice(-2) + '-' + ('0'+(target_date.getDate())).slice(-2)
        },
        // 時間フォーマッター
        getFormattedTime (time) {
            if (!time) {
                return
            }
            let target_time = new Date(time)
            return ('0'+(target_time.getHours())).slice(-2) + ':' + ('0'+(target_time.getMinutes())).slice(-2) + ':' + ('0'+(target_time.getSeconds())).slice(-2)
        },
        tabClick(tab) {
            if (tab.name === 'first') {
                this.is_new_user = 1
                this.init()
            } else
            {
                this.is_new_user = 0
                this.init()
            }
        },
        importCsvStart() {
            this.$store.commit(types.LOADING, true)
        },
        importCsvError(e) {
            let errorMsg = 'CSVアップロードに失敗しました'
            if (e.status === 400){
                errorMsg = JSON.parse(e.message)
            } else if (e.status === 403) {
                errorMsg = 'この操作は許可されていません。'
            }
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_ERROR_MESSAGE, errorMsg)
        },
        async importCsvSuccess(event) {
            if (event.status === 400) {
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_ERROR_MESSAGE, event.error)
            } else {
                await this.init()
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'CSVデータをアップロードしました')
            }
        },
        async downloadCSV() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data, error } = await axios.get('/api/organizations/articles/csv/export')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }

            const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
            FileDownload(data, 'organization_articles.csv', null, bom)

            this.$store.commit(types.PAGE_LOADING, false)
        },
    }
}
</script>
<style lang="scss" scoped>
    // input type=numberのスピンボタンを非表示
    .no_number_spinner ::v-deep {
        input::-webkit-inner-spin-button,
        input::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
            -moz-appearance:textfield;
        }
    }

    div.el-table ::v-deep tr.manage-show-line {
        background-color: #e3f6ce;
    }
    div.el-table ::v-deep tr.manage-disabled-line {
        background-color: #dddddd;
    }
    #organization {
        padding-bottom: 50px;
    }
    #organization_header {
        padding: 10px 0;
    }
    #add_data {
        padding: 10px 0 22px;
        display: inline-block;
        vertical-align: top;
    }
    .form_label {
        font-weight: bold;
    }
    #thumbnail.topic, #thumbnail img.topic {
        height: 61px;
        display: block;
    }
    #suggest_wrapper {
        display: inline-block;
    }
    #suggest_data,
    #not_found_suggest {
        padding: 5px 10px;
        margin: 10px 0 0 50px;
    }
    #suggest_data {
        background-color: #eeeeee;
    }
    #suggest_data img {
        width: 100px;
        margin-right: 10px;
        object-fit: cover;
    }
    #suggest_data .summary {
        height: 54px;
    }
    #suggest_data .topic {
        height: 32px;
    }
    #suggest_data .suggest_text {
        display: inline-block;
        vertical-align: top;
        font-size: 13px;
    }
    #suggest_data .suggest_title {
        max-width: 480px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
    .new_user_time_select {
        width: 64px;
    }
    .new_user_time_select_submit {
        margin: 0 20px;
    }
    .alt_title_length {
        font-size: 10px;
        text-align: right;
    }
    .update-button {
        margin-bottom: 5px;
    }
    .thumbnail-img {
        width: 120px;
    }
    .type_heading {
        margin-top: 30px;
    }
</style>
