<template>
    <div>
        <div class="header-wrapper">
            <el-row type="flex" justify="end">
                <div class="create-button">
                    <el-button type="primary" @click="openCreateModal">新規作成</el-button>
                </div>
            </el-row>
            <el-row type="flex" justify="end" align="middle">
                <el-checkbox v-model="searchForm.is_active">公開中のみ表示する</el-checkbox>
                <el-input v-model="searchForm.title" class="input-title" placeholder="タイトル">
                    <el-button
                        @click="search()"
                        slot="append"
                        icon="el-icon-search"
                    ></el-button>
                </el-input>
            </el-row>
        </div>
        <el-table
                :data="forms"
                style="width: 100%"
                max-height="800"
        >
            <el-table-column label="応募フォームID" prop="id"></el-table-column>
            <el-table-column label="タイトル">
                <template slot-scope="scope">
                    <a v-if="scope.row.is_active" :href="'https://'+macaroniDomain+'/postform/'+scope.row.id" target="_blank">{{ scope.row.title }}</a>
                    <p v-else>{{ scope.row.title }}</p>
                </template>
            </el-table-column>
            <el-table-column label="公開中">
                <template slot-scope="scope">
                    <i :class=" scope.row.is_active ? 'el-icon-check' : '' "></i>
                </template>
            </el-table-column>
            <el-table-column label="応募数">
                <template slot-scope="scope">{{ scope.row.entries.length }}</template>
            </el-table-column>
            <el-table-column label="作成日時" prop="created_at" width="160"></el-table-column>
            <el-table-column label="終了日時" prop="end_at" width="160"></el-table-column>
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                        @click="openEditModal(scope.row)"
                        size="mini"
                    >編集</el-button>
                </template>
            </el-table-column>
            <el-table-column label="回答">
                <template slot-scope="scope">
                    <el-button
                            type="warning"
                            @click="csvExport(scope.row)"
                    ><i class="el-icon-download"></i>CSVダウンロード</el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="search" :pagination="pagination" />

        <edit-modal
            title="新規作成"
            :is-active="isModalActive.create"
            :form="editForm"
            :input-types="inputTypes"
            :form-html-list="formHtmlList"
            :html-classes="htmlClasses"
            @save="createForm"
            @close="closeCreateModal"
            @addFormHtml="addFormHtml"
            @deleteFormHtml="deleteFormHtml"
        ></edit-modal>

        <edit-modal
            title="編集"
            :is-active="isModalActive.edit"
            :form="editForm"
            :input-types="inputTypes"
            :form-html-list="formHtmlList"
            :html-classes="htmlClasses"
            @save="updateForm"
            @close="closeEditModal"
            @addFormHtml="addFormHtml"
            @deleteFormHtml="deleteFormHtml"
        ></edit-modal>
    </div>
</template>

<script>
import { PAGE_TITLE , LOADING } from '../../store/mutation-types'
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import EditModal from './Form/EditModal'
import FileDownload from 'js-file-download'


export default {
    components: { Pagination, EditModal },
    data() {
        return {
            forms: [],
            formHtmls: [],
            htmlClasses: {},
            inputTypes: [],
            pagination: {},
            searchForm: {
                title: '',
                is_active: false,
                page: 1,
            },
            isModalActive: {
                create: false,
                edit: false,
                entry: false,
            },
            editForm: {},
            formHtmlList: [],
            editorOptions: {
                toolbar: {
                    buttons: ['bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'quote'],
                },
            },
            macaroniDomain: '',
        }
    },
    beforeRouteUpdate(to, from, next) {
        next()
        this.fetch()
    },
    created() {
        this.$store.commit(PAGE_TITLE, '応募フォーム')
        this.fetch()
    },
    methods: {
        // 一覧取得
        async fetch() {
            this.$store.commit(LOADING, true)

            const { data, error } = await axios.get('/api/entry/forms', {
                params: { ...this.$route.query }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            this.forms = Object.assign([], data.forms.data)
            this.pagination = Object.assign({}, data.forms)
            this.inputTypes = Object.assign([], data.input_types)
            this.formHtmls = Object.assign([], data.form_html_list)
            this.htmlClasses = Object.assign({}, data.html_classes)
            this.macaroniDomain = data.macaroni_domain

            this.$store.commit(LOADING, false)
        },
        // 検索
        search (page = 1) {
            this.searchForm.page = page
            let query = Object.assign({}, this.$route.query, this.searchForm)
            query.is_active = Number(query.is_active)
            this.$router.push({ query })
        },
        // 新規作成
        async createForm(params, html) {
            this.$store.commit(types.LOADING, true)
            let formData = this.setFormData(params, html)
            if (!formData) {
                this.$store.commit(types.LOADING, false)
                return
            }
            const { error } = await axios.post('/api/entry/forms', formData)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, '作成が完了しました。')

            this.isModalActive.create = false
            this.fetch()
        },
        // 編集
        async updateForm(params, html) {
            this.$store.commit(types.LOADING, true)
            let formData = this.setFormData(params, html)
            if (!formData) {
                this.$store.commit(types.LOADING, false)
                return
            }
            // PUTだとformDataが空になるため、一旦postにし、optionでPUT指定します
            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PUT'
                }
            }
            const { error } = await axios.post('/api/entry/forms/'+this.editForm.id, formData, config)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, '更新が完了しました。')

            this.isModalActive.edit = false
            this.fetch()
        },
        // 新規登録/編集時のフォームデータ生成
        setFormData(params, html) {
            const fd = new FormData()
            if (params.id) {
                fd.append('id', params.id)
            }
            fd.append('title', params.title)
            fd.append('icon', params.icon)
            fd.append('background_image', params.background_image)
            fd.append('top_text', params.top_text)
            fd.append('bottom_text', params.bottom_text)
            fd.append('is_active', Number(params.is_active))
            fd.append('end_at', params.end_at || '')
            fd.append('form_html', html)
            return fd
        },
        // 新規追加モーダルオープン
        openCreateModal() {
            this.editForm = {
                title: '',
                icon: '',
                background_image: '',
                top_text: '',
                bottom_text: '',
                is_active: false,
                end_at: '',
                form_html: '',
            }
            this.formHtmlList = []
            this.isModalActive.create = true
        },
        // 新規追加モーダルクローズ
        closeCreateModal() {
            this.isModalActive.create = false
        },
        // 編集追加モーダルオープン
        openEditModal(form) {
            this.editForm = Object.assign({}, form)
            this.formHtmlList = Object.assign([], this.formHtmls[form.id])
            this.isModalActive.edit = true
        },
        // 編集追加モーダルクローズ
        closeEditModal() {
            this.isModalActive.edit = false
        },
        // フォームHTML追加
        addFormHtml(formHtmlList, html) {
            this.formHtmlList = Object.assign([], formHtmlList)
            this.$set(this.formHtmlList, this.formHtmlList.length, html)
        },
        // フォームHTML削除
        deleteFormHtml(formHtmlList, key) {
            this.formHtmlList = Object.assign([], formHtmlList)
            this.formHtmlList.splice(key, 1)
        },
        // 回答一覧CSVダウンロード
        async csvExport(entryForm) {
            this.$store.commit(types.PAGE_LOADING, true)
            const { data, error } = await axios.get('/api/entries/csv/export/'+entryForm.id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            const bom  = new Uint8Array([0xEF, 0xBB, 0xBF])
            FileDownload(data, entryForm.title+'.csv', null, bom)
            this.$store.commit(types.PAGE_LOADING, false)
        },
    }
}
</script>
<style scoped>
    .header-wrapper {
        margin: 20px;
    }
    .input-title {
        width: 500px;
    }
    .create-button button {
        margin-bottom: 20px;
    }
    .el-icon-check {
        font-size: 2.0em;
    }
</style>
