<template>
    <div>
        <el-dialog
            :title="title"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
        >
            <el-form ref="form" :model="editForm" label-width="140px">
                <el-form-item label="タイトル">
                    <el-input v-model="editForm.title" placeholder="タイトル" maxlength="60"></el-input>
                </el-form-item>
                <el-form-item label="サブタイトル">
                    <el-input v-model="editForm.sub_title" placeholder="サブタイトル" maxlength="15"></el-input>
                </el-form-item>
                <el-form-item label="公開開始日時">
                    <el-date-picker
                        v-model="editForm.publish_start_at"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="公開終了日時">
                    <el-date-picker
                        v-model="editForm.publish_end_at"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="配信OS" v-if="!isEdit">
                    <el-checkbox
                        v-model="editForm.is_ios"
                        label="IOS"
                    ></el-checkbox>
                    <el-checkbox
                        v-model="editForm.is_android"
                        label="Android"
                    ></el-checkbox>
                </el-form-item>
                <el-form-item label="画像" v-if="!isEdit">
                    <el-upload
                        action=""
                        accept="image/png,image/jpeg"
                        list-type="picture"
                        :show-file-list="false"
                        :auto-upload="false"
                        :on-change="handleBannerAdd"
                        :on-remove="handleBannerRemove"
                    >
                        <img v-if="previewImage" :src="previewImage" class="preview-img">
                        <el-button v-else size="small" type="primary">画像選択</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="画像サイズ" v-if="editForm.image">
                    <el-select v-model="editForm.image_size" placeholder="画像サイズ">
                        <el-option
                            v-for="size in imageSizes"
                            :key="size.value"
                            :label="size.label"
                            :value="size.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="お知らせタイプ" v-if="!isEdit">
                    <el-select v-model="editForm.transition_type" placeholder="お知らせタイプ">
                        <el-option
                            v-for="type in transitionTypes"
                            :key="type.value"
                            :label="type.label"
                            :value="type.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="記事ID" v-if="!isEdit && editForm.transition_type === 'article'">
                    <el-input v-model="editForm.article_id" placeholder="記事ID" type="number"></el-input>
                </el-form-item>
                <el-form-item label="特集ID" v-if="!isEdit && editForm.transition_type === 'topic'">
                    <el-input v-model="editForm.topic_id" placeholder="特集ID" type="number"></el-input>
                </el-form-item>
                <el-form-item label="遷移先タイムライン" v-if="!isEdit && editForm.transition_type === 'timeline'">
                    <el-select v-model="editForm.timeline_id" placeholder="遷移先タイムライン">
                        <el-option
                            v-for="appTab in appTabs"
                            :key="appTab.id"
                            :label="appTab.tab_name"
                            :value="appTab.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="close">キャンセル</el-button>
                    <el-button type="primary" @click="save">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
const editFormDefault = {
    title: '',
    sub_title:'',
    publish_start_at: '',
    publish_end_at: '',
    is_ios: true,
    is_android: true,
    image: '',
    image_size: '',
    transition_type: '',
    article_id: '',
    topic_id: '',
    timeline_id: '',
    timeline_type: '',
}

export default {
    props: {
        notification: Object,
        imageSizes: Array,
        transitionTypes: Array,
        isActive: Boolean,
        sub_title: String,
        title: String,
        appTabs: Array,
        appTabIdTypeList: Object,
    },
    data() {
        return {
            editForm: editFormDefault,
            previewImage: '',
            isEdit: false,
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.previewImage = ''
            this.$emit('close')
        },
        // 編集
        async save() {
            // タイムラインタイプのアサイン
            if (this.editForm.timeline_id) {
                this.editForm.timeline_type = this.appTabIdTypeList[this.editForm.timeline_id]
            }

            // 画像UPで使用するため
            const fd = new FormData()
            fd.append('title', this.editForm.title)
            fd.append('sub_title', this.editForm.sub_title)
            fd.append('publish_start_at', this.editForm.publish_start_at ? this.editForm.publish_start_at : '')
            fd.append('publish_end_at', this.editForm.publish_end_at ? this.editForm.publish_end_at : '')
            fd.append('is_ios', this.editForm.is_ios ? 1 : 0)
            fd.append('is_android', this.editForm.is_android ? 1 : 0)
            fd.append('image', this.editForm.image ? this.editForm.image : '')
            fd.append('image_size', this.editForm.image_size ? this.editForm.image_size : '')
            fd.append('transition_type', this.editForm.transition_type)
            fd.append('article_id', this.editForm.article_id ? this.editForm.article_id : '')
            fd.append('topic_id', this.editForm.topic_id ? this.editForm.topic_id : '')
            fd.append('timeline_id', this.editForm.timeline_id ? this.editForm.timeline_id : '')
            fd.append('timeline_type', this.editForm.timeline_type? this.editForm.timeline_type : '')

            this.$emit('save', fd)
        },
        // 画像選択時
        handleBannerAdd(file, fileList) {
            this.editForm.image = file.raw
            this.previewImage = file.url
            this.editForm.image_size = ''
        },
        // 画像削除時
        handleBannerRemove(file, fileList) {
            this.editForm.image = ''
            this.previewImage = ''
            this.editForm.image_size = ''
        },
    },
    watch: {
        notification() {
            this.isEdit = Object.keys(this.notification).length > 0
            this.editForm = Object.assign({}, editFormDefault, this.notification)
        },
    },
}
</script>

<style lang="scss" scoped>
    .preview-img {
        width: 250px;
    }
    .display-none {
        display: none;
    }
</style>
