<template>
    <div>
        <el-row type="flex" justify="end">
            <el-header >
                <el-col >
                    <el-upload class="uploadButton" accept=".csv" :headers="header" :before-upload="importCsvStart" :on-error="importCsvError" :on-success="importCsvSuccess" :show-file-list="false" action="/api/articles/csv/import" name="csv">
                        <el-button type="warning" icon="el-icon-upload2">
                            CSVアップロード
                        </el-button>
                    </el-upload>
                    <el-button type="warning" @click="downloadCSV" icon="el-icon-download">
                        CSVダウンロード
                    </el-button>
                </el-col>
            </el-header >
        </el-row>
        <el-row type="flex" justify="end" style="margin-top:10px;">
            <el-select placeholder="ステータスでフィルタ" v-model="query.articleStatus" class="search">
                 <el-option
                     v-for="(item) in articleStatusFilterList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                 </el-option>
            </el-select>
            <el-select placeholder="カテゴリでフィルタ" v-model="query.category" class="search">
                 <el-option
                     v-for="(item) in categoryFilterList"
                     :key="item.id"
                     :label="item.category_name"
                     :value="item.id">
                 </el-option>
            </el-select>
            <el-select placeholder="記事種別でフィルタ" v-model="query.articleType" class="search">
                <el-option
                    v-for="(item) in articleTypeFilterList"
                    :key="item.id"
                    :label="item.type_name"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select placeholder="フォーマットでフィルタ" v-model="query.format" class="search">
                <el-option
                    v-for="item in formatFilterList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>

        </el-row>
        <el-row type="flex" justify="end" style="margin-top:10px;">
            <el-autocomplete
                class="search"
                placeholder="執筆ライター名"
                v-model="query.searchWriter"
                @select="handleSelectWriter"
                :fetch-suggestions="querySearchWriter"
                :trigger-on-focus="true"
                @keyup.enter.native="search()"
            />
            <el-autocomplete
                class="search"
                placeholder="公開ライター名"
                v-model="query.publishWriter"
                @select="handleSelectWriter"
                :fetch-suggestions="querySearchWriter"
                :trigger-on-focus="true"
                @keyup.enter.native="search()"
            >
            </el-autocomplete>
            <el-input
                class="search"
                placeholder="ID タイトル"
                v-model="query.keyword"
                @keyup.enter.native="search()"
                style="width: 500px;"
            ></el-input>
        </el-row>
        <el-row type="flex" justify="end" style="margin: 15px 5px 0 0; align-items: center;">
            <el-checkbox true-label="1" false-label="0" v-model="query.isNoindex">no-index</el-checkbox>
            <el-checkbox true-label="1" false-label="0" v-model="query.isStealth">ステルス</el-checkbox>
            <el-checkbox true-label="1" false-label="0" v-model="query.isTieup">記事広告</el-checkbox>
            <el-checkbox true-label="1" false-label="0" v-model="query.isNoPager">No Pager</el-checkbox>
            <el-checkbox true-label="1" false-label="0" v-model="query.isHideAd">広告非表示</el-checkbox>
            <el-checkbox true-label="1" false-label="0" v-model="query.isDisplayMateForm">mateフォーム</el-checkbox>
            <el-checkbox true-label="1" false-label="0" v-model="query.isTrend">トレンド記事</el-checkbox>
            <el-checkbox true-label="1" false-label="0" v-model="query.isMate">Mate記事</el-checkbox>
            <el-checkbox true-label="1" false-label="0" v-model="query.isOriginal">オリジナル記事</el-checkbox>
            <el-button @click="search()" icon="el-icon-search" class="search-input"></el-button>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <el-row>
            <el-col>
                <edit-article-row
                    :tableData=tableData
                    :update="update"
                    :formatLabelList="formatLabelList"
                    :articleTypes="articleTypes"
                >
                </edit-article-row>
            </el-col>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
    </div>
</template>

<script>
import Pagination from '../components/Pagination'
import * as types from '../store/mutation-types'
import EditArticleRow from '../components/Article/EditArticleRow'
import moment from 'moment'
import FileDownload from "js-file-download";

const formDefaultData = {
    articleStatus: 500,
    category: null,
    articleType: null,
    format: null,
    publishWriter: null,
    searchWriter: null,
    keyword: null,
    isNoindex: 0,
    isTieup: 0,
    isStealth: 0,
    isNoPager: 0,
    isHideAd: 0,
    isDisplayMateForm: 0,
}

export default {
    components: {
        EditArticleRow,
        Pagination,
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data() {
        return {
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            articles: [],
            form: {},
            dialog: false,
            pagination: {},
            tableData: [],
            formatList: [],
            formatLabelList: {},
            formatFilterList: [],
            articleStatusList: [],
            articleStatusFilterList: [],
            articleStatusLabelList: [],
            categories: {},
            categoryFilterList: [],
            articleTypes: {},
            articleTypeFilterList: {},
            writers: {},
            writerSuggestList:[],
            query: {
                articleStatus: 500,
                category: null,
                articleType: null,
                format: null,
                publishWriter: null,
                searchWriter:null,
                keyword: null,
                isNoindex: 0,
                isTieup: 0,
                isStealth: 0,
                isNoPager: 0,
                isHideAd: 0,
                isDisplayMateForm: 0,
                isTrend: 0,
                isMate: 0,
                isOriginal: 0,
            },
            page: 1
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '記事管理　>　記事一覧')
        this.queryInit()
        this.fetch()
    },
    methods: {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)
            const page = this.$route.query.page
            this.$route.query.articleStatus = this.$route.query.articleStatus ? Number(this.$route.query.articleStatus) : 500
            const { data } = await axios.get('/api/articles', {
                params: { ...this.$route.query }
            })

            this.pagination = Object.assign({}, data.articles)

            const articles = Object.assign([], data.articles.data)
            this.tableData = _.map(articles, (article) => {
                return article
            })

            this.$store.commit(types.PAGE_LOADING, false)

            this.formatList = Object.assign([], data.format_list)
            this.formatList.forEach((format) => {
                this.formatLabelList[format.value] = format.label
            })

            this.formatFilterList = Object.assign([], data.format_list)
            this.formatFilterList.unshift({label: null, value: null})

            this.articleStatusList = Object.assign([], data.status_list)
            this.articleStatusList.forEach((format) => {
                this.articleStatusLabelList[format.value] = format.label
            })

            this.articleStatusFilterList = Object.assign([], data.status_list)

            this.writers = this.idKeyList(data.writers)
            this.writerSuggestList = Object.assign([],data.writers).map((writer) => {return {value:writer.screen_name}}).filter(writer => writer)

            this.articleTypes = this.idKeyList(data.article_types)
            this.articleTypeFilterList = Object.assign([], data.article_types)
            this.articleTypeFilterList.unshift({type_name: null, id: null})

            this.categories = this.idKeyList(data.categories)
            this.categoryFilterList = Object.assign([], data.categories)
            this.categoryFilterList.unshift({category_name: null, id: null})

        },
        async update (id, form) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.patch('/api/articles/' + id, form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                await this.fetch()
                this.$store.commit(types.SHOW_INFO_MESSAGE, '更新しました')
            }
            this.$store.commit(types.LOADING, false)
        },
        handleCurrentChange (page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
        handleSelectWriter (item) {
            this.writer = item.value
        },
        queryInit() {
            this.query = Object.assign({}, this.$route.query, this.formDefaultData, {
                articleStatus: this.$route.query.articleStatus ? Number(this.$route.query.articleStatus) : 500,
                category: this.$route.query.category ? Number(this.$route.query.category) : null,
                articleType: this.$route.query.articleType ? Number(this.$route.query.articleType) : null,
                format: this.$route.query.format ? this.$route.query.format : null,
                publishWriter: this.$route.query.publishWriter ? this.$route.query.publishWriter : null,
                searchWriter: this.$route.query.searchWriter ? this.$route.query.searchWriter : null,
                keyword: this.$route.query.keyword ? this.$route.query.keyword : '',
                isNoindex: this.$route.query.isNoindex ? this.$route.query.isNoindex : 0,
                isTieup: this.$route.query.isTieup ? this.$route.query.isTieup : 0,
                isStealth: this.$route.query.isStealth ? this.$route.query.isStealth : 0,
                isNoPager: this.$route.query.isNoPager ? this.$route.query.isNoPager : 0,
                isHideAd: this.$route.query.isHideAd ? this.$route.query.isHideAd : 0,
                isDisplayMateForm: this.$route.query.isDisplayMateForm ? this.$route.query.isDisplayMateForm : 0,
                page: this.$route.query.page ? this.$route.query.page : 1,
            })
        },
        search () {
            const query = Object.assign({}, this.$route.query, formDefaultData, {
                articleStatus: this.query.articleStatus,
                category: this.query.category,
                articleType: this.query.articleType,
                format: this.query.format,
                publishWriter: this.query.publishWriter,
                searchWriter: this.query.searchWriter,
                keyword: this.query.keyword,
                isNoindex: this.query.isNoindex,
                isTieup: this.query.isTieup,
                isStealth: this.query.isStealth,
                isNoPager: this.query.isNoPager,
                isHideAd: this.query.isHideAd,
                isDisplayMateForm: this.query.isDisplayMateForm,
                isTrend: this.query.isTrend,
                isMate: this.query.isMate,
                isOriginal: this.query.isOriginal,
                page: 1,
            })
            this.$router.push({ query })
        },
        querySearchWriter(queryString, cb) {
            const values = this.writerSuggestList
            const results = queryString ? values.filter(this.createFilter(queryString)) : values
            cb(results)
        },
        createFilter(queryString) {
            return (item) => {
                return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
            }
        },
        idKeyList(list) {
            return _.keyBy(list, (data) => data.id)
        },
        showDate(date) {
            return date ? moment(date).format('YYYY-MM-DD') : ''
        },
        showTime(date) {
            return date ? moment(date).format('HH:mm:ss') : ''
        },
        closeDialog () {
            this.dialog = false
            this.deployDialog = false
        },
        editRow (form) {
            this.form = Object.assign({}, form)
            this.dialog = true
        },
        previewRow (form) {
            alert('TODO')
        },
        editArticleRow (form) {
            alert('TODO')
        },
        async importCsvSuccess(event) {
            if (event.status === 400) {
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_ERROR_MESSAGE, event.error)
            } else {
                await this.fetch()
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'CSVデータをアップロードしました')
            }
        },
        async downloadCSV() {
            this.$store.commit(types.PAGE_LOADING, true)
            const { data, error } = await axios.get('/api/articles/csv/export')
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            const bom  = new Uint8Array([0xEF, 0xBB, 0xBF])
            FileDownload(data, 'article_update_template.csv', null, bom)
            this.$store.commit(types.PAGE_LOADING, false)
        },
        importCsvStart() {
            this.$store.commit(types.LOADING, true)
        },
        importCsvError(e) {
            let errorMsg = 'CSVアップロードに失敗しました'
            if (e.status === 422){
                let errorJson = JSON.parse(e.message)
                errorMsg = typeof errorJson.error !== 'undefined' ? errorJson.error : errorMsg
            } else if (e.status === 403) {
                errorMsg = 'この操作は許可されていません。'
            }
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_ERROR_MESSAGE, errorMsg)
        },
    }
}
</script>
<style scoped>
.articleTitle{
    float:left;
    margin-top:15px;
    margin-left:7px;
    width:650px;
}
.el-button {
    margin: 5px 0 5px 10px;
}
.bottomTable {
    margin-top:5px;
}
.box-article{
    padding: 5px 20px 0 20px;
}
.el-icon-check{
    font-size:2.0em;
}
.search{
    margin: 10px 5px 0;
}
</style>
