<template>
    <div class="categoryEdit">
        <el-form label-width="150px" class="categoryEdit__form">
            <el-form-item label="タグ名">{{ category.category_name }}</el-form-item>
            <el-form-item label="ディスクリプション">{{ category.category_description }}</el-form-item>
            <el-form-item label="気になるタグ">
                <tag-suggest v-model="categoryInterestTagPaths" />
            </el-form-item>
            <el-form-item class="categoryEdit__buttonWrapper">
                <el-button type="default" @click="$router.back()" class="categoryEdit__button">キャンセル</el-button>
                <el-button type="primary" @click="update" class="categoryEdit__button">更新</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import TagSuggest from '../../components/TagSuggest'

export default {
    components: {
        TagSuggest,
    },
    data() {
        return {
            category: {},
            categoryInterestTagPaths: [],
        }
    },
    computed: {
        categoryId() {
            return this.$route.params.id
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '編成　>　カテゴリー　>　カテゴリー編集')
        this.fetch()
    },
    methods: {
        async fetch() {
            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get(`/api/categories/${this.categoryId}`)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.category = {...data.category}
            this.categoryInterestTagPaths = data.category_interest_tag_paths.slice()
            this.$store.commit(types.LOADING, false)
        },
        async update() {
            this.$store.commit(types.LOADING, true)

            const {error} = await axios.put(`/api/categories/${this.categoryId}`, {
                tag_path_ids: this.categoryInterestTagPaths.map(tagPath => tagPath.id)
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.$store.commit(types.SHOW_INFO_MESSAGE, '更新しました')
            this.fetch()
        },
    },
}
</script>

<style lang="scss" scoped>
.categoryEdit {
    &__form {
        max-width: 1200px;
    }
    &__button {
        width: 150px;
    }
    &__buttonWrapper {
        text-align: right;
    }
}
</style>
