<template>
    <div>
        <el-row>
            <item-left-contents
                :check-state="checkState"
                :number="number"
                :page-number="item.page_number"
                @update:checkState="checkState = $event"
            />

            <el-col class="twitterItem contents" :span="21">
                <twitter-contents :twitter="content" :showImage="content.is_show_image"/>
            </el-col>

            <el-col class="rightBox" :span="2">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>
    </div>
</template>
<script>

import TwitterContents from '../Form/Components/Twitter'
import ItemLeftContents from './Components/ItemLeftContents'

export default {
    components:{
        ItemLeftContents,
        TwitterContents
    },
    computed: {
        content() {
            return this.item.content
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        resourceName() {
            return 'tweet'
        },
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    methods: {
        checkItem(check,item) {
            this.parentCheckItem(check,item)
        },
        clickTargetBlank (link) {
            window.open(link, '_blank', '')
        }
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>
<style scoped>
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}
</style>
