<template>
    <div>
        <div class="inlineFormArea">
            <div class="inlineItem">
                <el-button type="primary" @click="openCreateModal">新規作成</el-button>
            </div>
            <div class="inlineItem">
                <el-checkbox v-model="searchForm.withInvalid">削除済みも含める</el-checkbox>
                <el-input
                    v-model="searchForm.name"
                    class="searchForm"
                    placeholder="アカウント名で検索"
                    @keyup.enter.native="search()"
                />
                <el-button icon="el-icon-search" @click="search()" />
            </div>
        </div>

        <div class="admin">
            <el-table
                    :data="admins"
                    style="width: 100%"
                    max-height="800"
                    :row-style="({row}) => isInvalid(row.status) ? {'background-color': '#ccc'} : {}"
            >
                <el-table-column label="管理者ID" prop="id" width="90px"></el-table-column>
                <el-table-column label="アカウント名" prop="name"></el-table-column>
                <el-table-column label="編集者名">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.editor_name" :disabled="isInvalid(scope.row.status)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="権限" width="170px">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.authority" :disabled="isInvalid(scope.row.status)">
                            <el-option
                                v-for="authority in selectAuthorities"
                                :key="authority.value"
                                :label="authority.label"
                                :value="authority.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="メールアドレス" prop="email"></el-table-column>
                <el-table-column label="参加日時" prop="created_at" width="170px"></el-table-column>
                <el-table-column align="right" width="150px">
                    <template slot-scope="scope">
                        <span v-if="isInvalid(scope.row.status)">
                            <el-button
                                size="mini"
                                @click="reEnableAdmin(scope.row.id)"
                            >再有効化</el-button>
                        </span>
                        <span v-else>
                            <el-button
                                size="mini"
                                @click="updateAdmin(scope.row)"
                            >更新</el-button>
                            <el-button
                                type="danger"
                                size="mini"
                                @click="destroy(scope.row.id)"
                            >削除</el-button>
                        </span>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :handleCurrentChange="search" :pagination="pagination" />
        </div>

        <!-- 新規登録モーダル -->
        <edit-modal
            :authorities="selectAuthorities"
            :is-active="isModalActive.create"
            :admin="editAdmin"
            title="管理ユーザー追加"
            @close="closeCreateModal"
            @save="createAdmin"
        ></edit-modal>
    </div>
</template>

<script>
import { PAGE_TITLE , LOADING } from '../../store/mutation-types'
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import EditModal from './Admin/EditModal'

const adminStatus = {
    INVALID: 0,
    VALID: 200,
}

export default {
    components: { Pagination, EditModal },
    data() {
        return {
            admins: [],
            authorities: {},
            pagination: {},
            searchForm: {
                name: '',
                withInvalid: false,
            },
            isModalActive: {
                create: false,
            },
            editAdmin: {
                'email': '',
                'name': '',
                'authority': '',
            },
            selectAuthorities: {},
        }
    },
    beforeRouteUpdate(to, from, next) {
        next()
        this.fetch()
    },
    created() {
        this.$store.commit(PAGE_TITLE, 'アカウント　>　管理者')

        this.searchForm.name = this.$route.query.name || ''
        this.searchForm.withInvalid = this.$route.query.withInvalid === '1'

        this.fetch()
    },
    methods: {
        // 一覧取得
        async fetch() {
            this.$store.commit(LOADING, true)

            const { data, error } = await axios.get('/api/admins', {
                params: { ...this.$route.query }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(LOADING, false)
                return
            }

            this.admins = Object.assign([], data.admins.data)
            this.pagination = Object.assign({}, data.admins)
            this.selectAuthorities = Object.assign({}, data.authorities)
            Object.keys(data.authorities).forEach(key => {
                this.$set(this.authorities, data.authorities[key].value, data.authorities[key].label)
            })
            this.$store.commit(LOADING, false)
        },
        // 検索
        search (page = 1) {
            const query = Object.assign({}, this.$route.query,{
                name: this.searchForm.name,
                withInvalid: this.searchForm.withInvalid ? 1 : 0,
                page,
            })
            this.$router.push({ query })
        },
        isInvalid(status) {
            return status === adminStatus.INVALID
        },
        // 削除
        async destroy(id) {
            if (!confirm('本当に削除しますか？')) {
                return
            }
            this.$store.commit(LOADING, true)
            const { error } = await axios.delete('/api/admins/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(LOADING, false)
                return
            }
            this.$store.commit(LOADING, false)

            this.fetch()
        },
        // 新規作成
        async createAdmin(params) {
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.post('/api/admins', params)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, '管理者の登録を行い、登録完了メールを送信しました。')
            this.isModalActive.create = false
            this.fetch()
        },
        // 更新
        async updateAdmin(params) {
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.put('/api/admins/'+params.id, params)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.fetch()
        },
        async reEnableAdmin(adminId) {
            if (! confirm(`管理者ID:${adminId} の管理者権限を復元しますか？`)) {
                return
            }

            this.$store.commit(types.LOADING, true)
            const { error } = await axios.post(`/api/admins/${adminId}/enable`)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.$store.commit(types.SHOW_INFO_MESSAGE, '管理者権限を復元しました。')
            this.fetch()
        },
        // 新規追加モーダルオープン
        openCreateModal() {
            this.editAdmin = {
                'email': '',
                'name': '',
                'authority': '',
            }
            this.isModalActive.create = true
        },
        // 新規追加モーダルクローズ
        closeCreateModal() {
            this.isModalActive.create = false
        },
    }
}
</script>

<style lang="scss" scoped>
    .inlineFormArea {
        display: flex;
        flex-direction: column;
        gap: 10px 0;
    }
    .inlineItem {
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 0 8px;
    }
    .searchForm {
        width: 200px;
    }
</style>
