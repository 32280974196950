<template>
    <el-row>
        <div
            :class="itemButtonClass"
            @click="showItemMenu">
            <div class="itemButtonInner">
                <div class="addButton">追加＋</div>
                <hr class="border">
            </div>
        </div>

        <div v-if="isShowMenu" :class="menuBarClass">
            <normal-menu-bar
                v-if="articleFormat === 'normal'"
                :articleFormat="articleFormat"
                :is-tieup="isTieup"
                :response="response"
                @show="showItemMenu"
                @close="closeMenu"
                @create="create"
                @createAndContinue="createAndContinue"
            ></normal-menu-bar>

            <rich-menu-bar
                v-if="articleFormat === 'rich'"
                :articleFormat="articleFormat"
                :is-tieup="isTieup"
                :response="response"
                @show="showItemMenu"
                @close="closeMenu"
                @create="create"
                @createAndContinue="createAndContinue"
            ></rich-menu-bar>

            <video-menu-bar
                v-if="articleFormat === 'video'"
                :articleFormat="articleFormat"
                :is-tieup="isTieup"
                :response="response"
                @show="showItemMenu"
                @close="closeMenu"
                @create="create"
                @createAndContinue="createAndContinue"
            ></video-menu-bar>
        </div>
    </el-row>
</template>

<script>

import * as types from '../../../../store/mutation-types'
import NormalMenuBar from './MenuBar/Normal'
import RichMenuBar from './MenuBar/Rich'
import VideoMenuBar from './MenuBar/Video'

export default {
    components: {
        NormalMenuBar,
        RichMenuBar,
        VideoMenuBar,
    },
    props: {
        response: null,
        fetch: Function,
        order: Number,
        articleFormat: String,
        isTieup: Boolean,
    },
    data () {
        return {
            isShowMenu: false,
            itemButtonClass: 'itemButton',
            menuBarClass: 'hide',
        }
    },
    methods: {
        showItemMenu() {
            this.isShowMenu = true
            this.itemButtonClass = 'itemButton hide'
            this.menuBarClass = ''
        },
        closeMenu () {
            this.isShowMenu = false
            this.itemButtonClass = 'itemButton'
            this.menuBarClass = 'hide'
        },
        async create (type, form) {
            const ret = await this.performeCreate(type, form)
            if (ret === true) {
                this.closeMenu()
            }
        },
        async performeCreate(type, form)  {
            this.$store.commit(types.PAGE_LOADING,true)
            if (form instanceof FormData) {
                form.append('order_num', this.order)
            } else {
                form.order_num = this.order
            }
            const {error} = await axios.post('/api/articles/'+this.response.article.id+'/items/' + type, form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.PAGE_LOADING,false)
                return false
            }

            await this.fetch()
            this.$store.commit(types.PAGE_LOADING,false)
            return true
        },
        async createAndContinue (type, form) {
            await this.performeCreate(type, form)
        },
    },
}
</script>
<style scoped>
.itemButton {
    width: 791px;
    padding: 0;
    border-right: 1px solid #ddd;
    position: relative;
    cursor: pointer;
}
.hide {
    display: none;
}
.addButton {
    position: absolute;
    top: 0px;
    font-size: 10px;
    border: 1px solid #ccc;
    border-radius: 13px;
    padding: 6px 10px;
    background-color: #fff;
}
.itemButtonInner {
    height: 10px;
    padding: 4px 0 12px;
    opacity: 0;
}
.itemButtonInner:hover {
    opacity: 1;
}
.itemButton .border {
    border: 0;
    border-top: dotted 2px #bfbfbf;
}
</style>

<style>
    /* メニューバーのレイアウト修正 */
    .el-tabs__header {
        margin: 0 !important;
    }
    .el-tabs__content {
        padding-top: 15px;
    }
</style>
