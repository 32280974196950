<template>
    <div class="recipeSummaryItem">
        <el-tabs type="card">
            <el-tab-pane label="単一レシピ">
                <recipe-summary
                    :id="id"
                    :save="save"
                    :emphasis-item-color-list="emphasisItemColorList"
                    :emphasis-item-heading-tag-type-list="emphasisItemHeadingTagTypeList"
                    :emphasis-item-type-list="emphasisItemTypeList"
                    @close="close"
                />
            </el-tab-pane>
            <el-tab-pane label="調理時間">
                <cooking-time-type
                  :id="id"
                  :save="save"
                  @close="close"
                />
            </el-tab-pane>
            <el-tab-pane label="栄養情報">
                <nutrient-type
                    :id="id"
                    :save="save"
                    @close="close"
                />
            </el-tab-pane>
            <el-tab-pane label="材料">
                <material-type
                    :id="id"
                    :save="save"
                    :is-video="false"
                    :article-format="articleFormat"
                    @close="close"
                />
            </el-tab-pane>
            <el-tab-pane label="下ごしらえ">
                <recipe-type
                    :id="id"
                    :save="save"
                    :article-format="articleFormat"
                    :is-preparation="true"
                    @close="close"
                />
            </el-tab-pane>
            <el-tab-pane label="作り方">
                <recipe-type
                    :id="id"
                    :save="save"
                    :article-format="articleFormat"
                    @close="close"
                />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import RecipeSummary from './RecipeSummary/RecipeSummary'
import CookingTimeType from './CookingTimeType'
import NutrientType from './NutrientType'
import MaterialType from './MaterialType'
import RecipeType from './RecipeType'

export default {
    components: {
        RecipeSummary,
        CookingTimeType,
        NutrientType,
        MaterialType,
        RecipeType,
    },
    props: {
        id: {
            required: false,
            type: Number,
            default: null,
        },
        save: {
            required: true,
            type: Function,
        },
        emphasisItemHeadingTagTypeList: {
            required: true,
            type: Array,
        },
        emphasisItemColorList: {
            required: true,
            type: Array,
        },
        emphasisItemTypeList: {
            required: true,
            type: Array,
        },
        articleFormat: {
            required: true,
            type: String,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
    }
}
</script>
<style>
/* TODO 子コンポーネントでv-deepを使用して定義してもスタイルが効かないため、グローバルで記述する（材料アイテムのサジェストウィンドウ） */
.materialListMainItem__formElementSuggestPopper {
    width: 300px !important;
}
</style>
