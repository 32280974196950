<template>
    <div>
        <el-card class="recipeCategory">
            <el-form @submit.native.prevent label-width="85px" >
                <el-row class="recipeCategory__row">
                    <el-col :span="2" class="recipeCategory__label">料理区分</el-col>
                    <el-col :span="4">
                        <el-autocomplete
                            v-model="selectMenuClassCategory"
                            :fetch-suggestions="searchMenuClassCategories"
                            @select="addMenuClassCategory"
                            size="mini"
                            placeholder="選択してください"
                        ></el-autocomplete>
                    </el-col>
                    <el-col :span="18">
                        <el-tag
                            v-for="(menuClassCategory, idx) in selectMenuClassCategoryList"
                            :key="idx"
                            closable
                            type="info"
                            size="small"
                            @close="deleteMenuClassCategory(idx)"
                            class="recipeCategory__selectCategory"
                        >
                            {{ menuClassCategory.value }}
                        </el-tag>
                    </el-col>
                </el-row>
                <el-row class="recipeCategory__row">
                    <el-col :span="2" class="recipeCategory__label">調理法</el-col>
                    <el-col :span="4">
                        <el-autocomplete
                            v-model="selectCookingMethodCategory"
                            :fetch-suggestions="searchCookingMethodCategories"
                            @select="addCookingMethodCategory"
                            size="mini"
                            placeholder="選択してください"
                        ></el-autocomplete>
                    </el-col>
                    <el-col :span="18">
                        <el-tag
                            v-for="(cookingMethodCategory, idx) in selectCookingMethodCategoryList"
                            :key="idx"
                            closable
                            type="info"
                            size="small"
                            @close="deleteCookingMethodCategory(idx)"
                            class="recipeCategory__selectCategory"
                        >
                            {{ cookingMethodCategory.value }}
                        </el-tag>
                    </el-col>

                </el-row>
            </el-form>
        </el-card>
        <div class="articleMain">
            <el-form @submit.native.prevent :model="form">
                <el-row type="flex" style="margin-bottom: 30px;">
                    <el-col :span="12">
                        <el-form-item>
                            サムネイル
                            <el-upload
                                :headers="header"
                                class="avatar-uploader"
                                :auto-upload="false"
                                action=""
                                :on-change="handleThumbnailImageAdd"
                                :show-file-list="false"
                                accept="image/png,image/jpeg,image/gif">
                                <img v-if="thumbnailImageUrl" :src="thumbnailImageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                <icon-aspect-check :imageUrl="thumbnailImageUrl"></icon-aspect-check>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item>
                            動画
                            <el-form-item style="margin-bottom:10px;" class="uploadVideo">
                                <el-upload
                                    :headers="header"
                                    class="avatar-uploader"
                                    :auto-upload="false"
                                    action=""
                                    :on-change="handleVideoAdd"
                                    :show-file-list="false"
                                    accept="video/*">
                                    <video  v-if="videoUrl" :src="videoUrl" class="avatar"></video>
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </el-form-item>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-form @submit.native.prevent :model="form"  label-width="180px" >
                <el-form-item label="タイトル" style="margin: 0;">
                    <el-input
                        type="text"
                        style="font-size:25px;"
                        @change="update"
                        maxlength="60"
                        v-model="form.article.title">
                    </el-input>
                    <text-length-count :targetText="form.article.title" :maxTextLength="60" ></text-length-count>
                </el-form-item>
                <el-form-item label="ディスクリプション" style="margin: 0;">
                    <el-input
                        type="textarea"
                        size="medium"
                        placeholder="100〜140文字、キーワードが記事に設定されている場合は初めの50字以内に含むようにしてください。"
                        @change="update"
                        maxlength="140"
                        :autosize="{ minRows: 3, maxRows: 3}"
                        v-model="form.article.description">
                    </el-input>
                    <text-length-count :targetText="form.article.description" :maxTextLength="140"></text-length-count>
                </el-form-item>
                <el-form-item label="メモ" style="margin: 0;">
                    <el-input
                        type="textarea"
                        size="mini"
                        @change="update"
                        maxlength="2000"
                        :autosize="{ minRows: 3, maxRows: 3}"
                        v-model="form.article.memo">
                    </el-input>
                    <text-length-count :targetText="form.article.memo" :maxTextLength="2000"></text-length-count>
                </el-form-item>
                <el-form-item label="料理名">
                    <el-input
                        type="text"
                        size="mini"
                        @change="update"
                        maxlength="255"
                        v-model="form.video_article.cook_name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="提供人数">
                    <el-input-number
                        size="mini"
                        @change="update"
                        v-model="form.video_article.servings_min"
                    ></el-input-number> 人分〜
                    <el-input-number
                        size="mini"
                        @change="update"
                        v-model="form.video_article.servings_max"
                    ></el-input-number> 人分
                </el-form-item>
                <el-form-item label="">
                    <el-input
                        type="text"
                        size="mini"
                        @change="update"
                        maxlength="255"
                        v-model="form.video_article.servings_free_text"
                        placeholder="人数自由入力"
                    ></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="調理時間">
                            <el-input-number
                                size="mini"
                                @change="update"
                                v-model="form.video_article.cooking_time"
                            ></el-input-number> 分
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="調理時間に関するコメント">
                    <el-autocomplete
                        type="text"
                        size="mini"
                        class="cookTimeInput"
                        @change="update"
                        :maxlength="255"
                        :fetch-suggestions="cookTimeCommentListSearch"
                        v-model="form.video_article.cooking_time_comment"
                    ></el-autocomplete>
                </el-form-item>
                <el-form-item label="栄養情報">
                    <el-row class="nutrientRow">
                        <el-col :span="4">
                            <div class="nutrientRowLabel">エネルギー</div>
                            <el-input-number
                                size="mini"
                                @change="update"
                                v-model="form.video_article.calorie"
                            ></el-input-number> Kcal
                        </el-col>
                        <el-col :span="4">
                            <div class="nutrientRowLabel">タンパク質</div>
                            <el-input-number
                                size="mini"
                                @change="update"
                                v-model="form.video_article.protein"
                            ></el-input-number> g
                        </el-col>
                        <el-col :span="4">
                            <div class="nutrientRowLabel">脂質</div>
                            <el-input-number
                                size="mini"
                                @change="update"
                                v-model="form.video_article.lipid"
                            ></el-input-number> g
                        </el-col>
                    </el-row>
                    <el-row class="nutrientRow">
                        <el-col :span="4">
                            <div class="nutrientRowLabel">炭水化物</div>
                            <el-input-number
                                size="mini"
                                @change="update"
                                v-model="form.video_article.carbohydrate"
                            ></el-input-number> g
                        </el-col>
                        <el-col :span="4">
                            <div class="nutrientRowLabel">塩分量</div>
                            <el-input-number
                                size="mini"
                                @change="update"
                                v-model="form.video_article.salt"
                            ></el-input-number> g
                        </el-col>
                        <el-col :span="4">
                            <div class="nutrientRowLabel">糖質</div>
                            <el-input-number
                                size="mini"
                                @change="update"
                                v-model="form.video_article.saccharinity"
                            ></el-input-number> g
                        </el-col>
                    </el-row>
                    <el-row class="nutrientRow">
                        <el-col :span="8">
                            <div class="nutrientRowLabel">補足テキスト</div>
                            <el-input
                                type="text"
                                v-model="form.video_article.nutrient_comment"
                                @change="update"
                                size="mini"
                            ></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item>
                    <el-button
                        v-if="this.$store.state.role === 'tr'"
                        size="small"
                        @click="doCopy('YoutubeSummary')"
                    >Youtubeまとめ用コピー</el-button>
                    <el-button
                        v-if="this.$store.state.role === 'tr'"
                        size="small"
                        @click="doCopy('YoutubeSingle')"
                    >Youtube単一用コピー</el-button>
                    <el-button
                        v-if="this.$store.state.role === 'tr'"
                        size="small"
                        @click="doCopy('Instagram')"
                    >Instagram用コピー</el-button>
                    <el-button
                        v-if="this.$store.state.role === 'tr'"
                        size="small"
                        @click="doCopy('other')"
                    >他SNS用コピー</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import * as types from '../../../../store/mutation-types'
import TextLengthCount from '../ItemSection/Form/Components/TextLengthCount'
import IconAspectCheck from './Components/IconAspectCheck'
import sanitizeHTML from 'sanitize-html'
import Video from '../../Video'

const formDefaultData = {
    article: {
        thumbnail_cdn_url: null,
        title: null,
        description: null,
        memo: null,
        items: {},
    },
    video_article: {
        video: null,
        cook_name: null,
        servings_min: null,
        servings_max: null,
        servings_free_text: null,
        cooking_time: null,
        total_cooking_time: 0,
        cooking_time_comment: null,
        calorie: null,
        protein: 0,
        lipid: 0,
        carbohydrate: 0,
        salt: 0,
        saccharinity: 0,
        nutrient_comment: null,
        thumbnail: null,
        origin_video: null
    },
}
export default {
    name:'ArticleMainVideo',
    components: {
        Video,
        TextLengthCount,
        IconAspectCheck,
    },
    props: {
        response: Object,
        fetch: Function
    },
    data () {
        return {
            baseCharCountList: [],
            form: {...formDefaultData},
            fetchedImage: false,
            thumbnailImageRaw: null,
            thumbnailImageUrl: null,
            videoUrl: null,
            videoRaw: null,
            header:{
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },

            selectMenuClassCategory: null,
            selectMenuClassCategoryList: [],
            selectCookingMethodCategory: null,
            selectCookingMethodCategoryList: [],
        }
    },
    mounted() {
        this.form.article = Object.assign({}, _.pick(this.article, Object.keys(this.form.article)))
        this.form.video_article = Object.assign({}, _.pick(this.article.video_article, Object.keys(this.form.video_article)))

        if (this.form.article.thumbnail_cdn_url) {
            this.thumbnailImageUrl = this.form.article.thumbnail_cdn_url
        }
        if (this.form.video_article.origin_video) {
            this.videoUrl = this.form.video_article.origin_video
        }

        this.selectMenuClassCategoryList = this.response.selectMenuClassCategories.map(menuClassCategory => ({ id: menuClassCategory.id, value: menuClassCategory.menu_class_category_name }))
        this.selectCookingMethodCategoryList = this.response.selectCookingMethodCategories.map(cookingMethodCategory => ({ id: cookingMethodCategory.id, value: cookingMethodCategory.cooking_method_category_name }))
    },
    computed: {
        article() {
            if (!this.response) {
                return {}
            }
            return this.response.article
        },
        cookTimeCommentList() {
            if (!this.response) {
                return {}
            }
            return this.response.cookTimeCommentList
        },
        menuClassCategories() {
            const selectIds = this.selectMenuClassCategoryList.map(selectMenuClassCategory => selectMenuClassCategory.id)
            return this.response
                ? this.response.menuClassCategories.filter(menuClassCategory => !selectIds.includes(menuClassCategory.id))
                : []
        },
        cookingMethodCategories() {
            const selectIds = this.selectCookingMethodCategoryList.map(selectCookingMethodCategory => selectCookingMethodCategory.id)
            return this.response
                ? this.response.cookingMethodCategories.filter(cookingMethodCategory => !selectIds.includes(cookingMethodCategory.id))
                : []
        },
    },
    methods: {
        edit () {
            this.form = Object.assign({}, this.article)

        },
        async update () {
            this.$store.commit(types.EDITOR_LOADING,true)
            this.form.article.thumbnail = this.thumbnailImageRaw
            this.form.video_article.video = this.videoRaw
            const fd = new FormData()
            _.forEach(this.form.article, (value, key) => {
                if (value === null) {
                    this.form.article[key] = ''
                }
            })
            _.forEach(this.form.video_article, (value, key) => {
                if (value === null) {
                    this.form.video_article[key] = ''
                }
            })

            fd.append('article[thumbnail]', this.form.article.thumbnail)
            fd.append('article[title]', this.form.article.title)
            fd.append('article[description]', this.form.article.description)
            fd.append('article[memo]', this.form.article.memo)
            fd.append('video_article[video]', this.form.video_article.video)
            fd.append('video_article[cook_name]', this.form.video_article.cook_name)
            fd.append('video_article[servings_min]', this.form.video_article.servings_min)
            fd.append('video_article[servings_max]', this.form.video_article.servings_max)
            fd.append('video_article[servings_free_text]', this.form.video_article.servings_free_text)
            fd.append('video_article[cooking_time]', this.form.video_article.cooking_time)
            fd.append('video_article[total_cooking_time]', this.form.video_article.total_cooking_time)
            fd.append('video_article[cooking_time_comment]', this.form.video_article.cooking_time_comment)
            fd.append('video_article[calorie]', this.form.video_article.calorie)
            fd.append('video_article[protein]', this.form.video_article.protein ? this.form.video_article.protein : 0)
            fd.append('video_article[lipid]', this.form.video_article.lipid ? this.form.video_article.lipid : 0)
            fd.append('video_article[carbohydrate]', this.form.video_article.carbohydrate ? this.form.video_article.carbohydrate : 0)
            fd.append('video_article[salt]', this.form.video_article.salt ? this.form.video_article.salt : 0)
            fd.append('video_article[saccharinity]', this.form.video_article.saccharinity ? this.form.video_article.saccharinity : 0)
            fd.append('video_article[nutrient_comment]', this.form.video_article.nutrient_comment)

            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PATCH'
                }
            }
            const {error} = await axios.post('/api/articles/' + this.article.id + '/video', fd, config)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.EDITOR_LOADING,false)
                return
            }
            await this.fetch()
            this.$store.commit(types.EDITOR_LOADING,false)
        },
        handleThumbnailImageAdd(file) {
            this.thumbnailImageRaw = file.raw
            this.thumbnailImageUrl = URL.createObjectURL(file.raw)
            this.fetchedImage = true
            this.update()
        },
        handleVideoAdd(file) {
            this.videoRaw = file.raw
            this.videoUrl = URL.createObjectURL(file.raw)
            this.fetchedVideo = true
            this.update()
        },
        cookTimeCommentListSearch(queryString, cb) {
            let cookTimeComment = this.cookTimeCommentList
            let filteredComment = queryString ? cookTimeComment.filter(this.createFilter(queryString)) : cookTimeComment

            let result = []
            filteredComment.forEach(function(comment){
                let rowData = {}
                rowData.value = comment
                result.push(rowData)
            })
            cb(result)
        },
        createFilter(queryString) {
            return (link) => {
                return (link.indexOf(queryString) === 0)
            }
        },
        doCopy(buttontype) {
            const article = this.form.article
            article.video_article = this.form.video_article
            this.$copyText(this.fetchCopyText(article, buttontype)).then(() => {
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'クリップボードにコピーしました。')
            }, () => {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'コピーに失敗しました。')
            })
        },
        fetchCopyText(article, buttontype) {
            const vm = this
            const copyText = []

            // 記事タイトル
            if (article.title !== null) {
                copyText.push(article.title)
            }

            copyText.push('')

            // 材料
            const servingsMin = (article.video_article.servings_min !== null) ? parseInt(article.video_article.servings_min) : null
            const servingsMax = (article.video_article.servings_max !== null) ? parseInt(article.video_article.servings_max) : null
            const servingsFreeText = (article.video_article.servings_free_text !== null) ? article.video_article.servings_free_text : ''
            const cookingTime = (article.video_article.cooking_time !== null) ? article.video_article.cooking_time : ''

            if (buttontype === 'YoutubeSummary' || buttontype === 'YoutubeSingle') {
                if(Number.isInteger(cookingTime)) {
                    copyText.push('■調理時間：' + cookingTime + '分')
                    if (article.video_article.cooking_time_comment !== null) {
                        copyText.push('※' + article.video_article.cooking_time_comment)
                    }
                    copyText.push('')
                }
                if (Number.isInteger(servingsMin) && Number.isInteger(servingsMax)) {
                    copyText.push('■材料（' + servingsMin + '〜' + servingsMax+ '人分)')
                } else if (Number.isInteger(servingsMin)) {
                    copyText.push('■材料（' + servingsMin + '人分)')
                } else if (servingsFreeText.length > 0) {
                    copyText.push('■材料（' + servingsFreeText + ')\n')
                }
            }
            else {
                if (Number.isInteger(servingsMin) && Number.isInteger(servingsMax)) {
                    copyText.push('■材料（' + servingsMin + '〜' + servingsMax+ '人分/' + cookingTime + '分)')
                } else if (Number.isInteger(servingsMin)) {
                    copyText.push('■材料（' + servingsMin + '人分/' + cookingTime + '分)')
                } else if (servingsFreeText.length > 0) {
                    copyText.push('■材料（' + servingsFreeText + '/' + cookingTime + '分)\n')
                }
                if (article.video_article.cooking_time_comment !== null && buttontype !== 'YoutubeSummary' ) {
                    copyText.push('※' + article.video_article.cooking_time_comment)
                }
            }

            article.items.forEach((item, key1) => {
                switch (item.item_type) {
                case 'MaterialType':
                    // eslint-disable-next-line no-case-declarations
                    let materialGroupName = null
                    item.content.elements.forEach(ele => {
                        const materialName = ele.material_name
                        const quantityText = ele.quantity_text

                        if (materialGroupName !== ele.material_group_name) {
                            materialGroupName = ele.material_group_name
                            if (materialGroupName !== null) {
                                copyText.push('')
                                copyText.push('＜' + materialGroupName + '＞')
                            } else {
                                copyText.push('')
                            }
                        }

                        if (-1 !== materialName.indexOf('☆') || -1 !== materialName.indexOf('★')) {
                            copyText.push(materialName + '：' + quantityText)
                        } else if (materialName.length > 0 && quantityText.length > 0) {
                            copyText.push('・' + materialName + '：' + quantityText)
                        }
                    })
                    break
                }
            })

            copyText.push('')

            if (buttontype === 'YoutubeSummary') {
                return copyText.reduce((previousValue, currentValue) => previousValue + '\n' + currentValue)
            }

            // 作り方
            article.items.forEach((item, key1) => {
                switch (item.item_type) {
                case 'RecipeType':
                    item.content.elements.forEach((ele, key2) => {
                        if (ele.recipe_text !== null) {
                            if (key2 === 0) {
                                copyText.push('■下ごしらえ')
                                copyText.push(vm.convertHtmlToText(ele.recipe_text))
                                copyText.push('')
                            } else if (key2 > 0) {
                                if (key2 === 1) {
                                    copyText.push('■作り方')
                                }
                                if(buttontype !== 'YoutubeSingle') {
                                    copyText.push(vm.numberWrapCircle(key2) +  vm.convertHtmlToText(ele.recipe_text) + ( buttontype === 'Instagram' ? '' : '\n'))
                                } else {
                                    copyText.push(key2 +  '. ' + vm.convertHtmlToText(ele.recipe_text) + (''))
                                }

                            }
                        }
                    })
                    break
                }
            })

            copyText.push('')

            // コツ・ポイント
            article.items.forEach((item, key1) => {
                switch (item.item_type) {
                case 'HeadingType':
                    if (item.content.heading_text === 'コツ・ポイント') {
                        if (article.items[key1 + 1].content.text_body) {
                            if (buttontype === 'Instagram') {
                                copyText.push('★MEMO★' + vm.convertHtmlToText(article.items[key1 + 1].content.text_body))
                            } else {
                                copyText.push('■コツ・ポイント')
                                copyText.push(vm.convertHtmlToText(article.items[key1 + 1].content.text_body))
                            }
                        }
                    }
                    break
                }
            })

            copyText.push('')

            return copyText.reduce((previousValue, currentValue) => previousValue + '\n' + currentValue)
        },
        convertHtmlToText(str) {
            str = str.replace(/<p>(.*?)<\/p>/g, '$1\n').trim()
            const div = document.createElement('div')
            div.innerHTML = sanitizeHTML(str)
            return div.innerText
        },
        numberWrapCircle(num){
            var result = ''
            switch (parseInt(num))
            {
            case 1:
                result = '①'
                break
            case 2:
                result = '②'
                break
            case 3:
                result = '③'
                break
            case 4:
                result = '④'
                break
            case 5:
                result = '⑤'
                break
            case 6:
                result = '⑥'
                break
            case 7:
                result = '⑦'
                break
            case 8:
                result = '⑧'
                break
            case 9:
                result = '⑨'
                break
            case 10:
                result = '⑩'
                break
            case 11:
                result = '⑪'
                break
            case 12:
                result = '⑫'
                break
            case 13:
                result = '⑬'
                break
            case 14:
                result = '⑭'
                break
            case 15:
                result = '⑮'
                break
            case 16:
                result = '⑯'
                break
            case 17:
                result = '⑰'
                break
            case 18:
                result = '⑱'
                break
            case 19:
                result = '⑲'
                break
            case 20:
                result = '⑳'
                break
            }

            return result
        },
        searchMenuClassCategories(queryString, cb) {
            const searchCategories = queryString
                ? this.menuClassCategories.filter(category => category.menu_class_category_name.indexOf(queryString) === 0)
                : this.menuClassCategories
            const result = searchCategories.map(category => ({
                id: category.id,
                value: category.menu_class_category_name,
            }))
            cb(result)
        },
        searchCookingMethodCategories(queryString, cb) {
            const searchCategories = queryString
                ? this.cookingMethodCategories.filter(category => category.cooking_method_category_name.indexOf(queryString) === 0)
                : this.cookingMethodCategories
            const result = searchCategories.map(category => ({
                id: category.id,
                value: category.cooking_method_category_name,
            }))
            cb(result)
        },
        async updateMenuClassCategory() {
            this.$store.commit(types.EDITOR_LOADING, true)

            const params = { ids: this.selectMenuClassCategoryList.map(value => value.id) }
            const { error } = await axios.post(`/api/articles/${this.article.id}/menu_class_categories`, params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            }

            this.$store.commit(types.EDITOR_LOADING, false)
        },
        async updateCookingMethodCategory() {
            this.$store.commit(types.EDITOR_LOADING, true)

            const params = { ids: this.selectCookingMethodCategoryList.map(value => value.id) }
            const { error } = await axios.post(`/api/articles/${this.article.id}/cooking_method_categories`, params)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            }

            this.$store.commit(types.EDITOR_LOADING, false)
        },
        async addMenuClassCategory(e) {
            this.selectMenuClassCategoryList.push({...e})
            this.selectMenuClassCategory = null
            await this.updateMenuClassCategory()
        },
        async addCookingMethodCategory(e) {
            this.selectCookingMethodCategoryList.push({...e})
            this.selectCookingMethodCategory = null
            await this.updateCookingMethodCategory()
        },
        async deleteMenuClassCategory(idx) {
            this.selectMenuClassCategoryList.splice(idx, 1)
            await this.updateMenuClassCategory()
        },
        async deleteCookingMethodCategory(idx) {
            this.selectCookingMethodCategoryList.splice(idx, 1)
            await this.updateCookingMethodCategory()
        },
    },

}
</script>

<style scoped lang="scss">
.articleMain {
    padding: 15px;
}
.articleMain .avatar-uploader:hover .imgAspectRatioPopup {
    display: block;
}
.articleMain .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: visible;
}
.avatar-uploader {
    width: 178px;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px !important;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}
.cookTimeInput {
    width: 100%;
}
.nutrientRow {
    margin-bottom: 15px;
}
.nutrientRow:first-child {
    margin-top: 5px;
}
.nutrientRowLabel {
    margin-bottom: 5px;
    color: #5E5E5E;
}

.el-card.is-always-shadow.recipeCategory {
    box-shadow: none;
    margin-top: 30px;
    margin-bottom: 15px;
}
.recipeCategory {
    &__row {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__selectCategory {
        margin: 0 5px 5px;
    }
    &__label {
        vertical-align: middle;
        font-size: 14px;
        color: #606266;
        line-height: 28px;
        box-sizing: border-box;
    }
}
</style>
