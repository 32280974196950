<template>
<div>
    <el-row type="flex" justify="end" style="margin-top:10px;font-size: 12px" >
        <el-select placeholder="ステータスでフィルタ" v-model="status" class="search">
            <el-option
                v-for="(item) in statusFilterList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
    </el-row>
    <el-row type="flex" justify="end" style="margin-top:10px;font-size: 12px" >
        <el-input
            class="search"
            placeholder="記事ID"
            v-model="articleId"
            @keyup.enter.native="search()"
        ></el-input>
        <el-input
            class="search"
            placeholder="タイトル"
            v-model="title"
            @keyup.enter.native="search()"
        ></el-input>
        <el-input
            class="search"
            placeholder="料理名"
            v-model="cookName"
            @keyup.enter.native="search()"
        ></el-input>
        <el-input
            class="search"
            placeholder="材料名"
            v-model="materialName"
            @keyup.enter.native="search()"
        ></el-input>
        <el-button
            @click="search()"
            icon="el-icon-search"
            class="search-input"
            style="height: 40px; margin-top: 10px;"
        ></el-button>
    </el-row>
    <el-row>
        <el-col >
            <video-row
                :tableData=tableData
                :writerList="writerList"
                :articleStatusList="articleStatusList"
                :downDate="downDate"
                :update="update"
                :downPublishedAt="downPublishedAt"
                :upPublishedAt="upPublishedAt"
            >
            </video-row>
        </el-col>
    </el-row>
    <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
</div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import VideoRow from '../../components/Article/VideoRow'
import SearchBox from '../../components/SearchBox'

export default {
    name: 'Video',
    components: {
        Pagination,
        VideoRow,
        SearchBox
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data(){
        return {
            articleId: null,
            title: null,
            cookName: null,
            materialName: null,
            downDate: '',
            writerSuggestList: [],
            pagination: {},
            tableData: [],
            writers: [],
            writerList: [],
            statusFilterList: [],
            articleStatusList: [],
            status: null,
            page: 1,
        }
    },
    created() {
        this.queryInit()
        this.fetch()
        this.$store.commit(types.PAGE_TITLE, '記事管理　>　動画記事')
    },
    methods: {
        queryInit() {
            this.status = this.$route.query.status ? Number(this.$route.query.status) : null
            this.articleId = this.$route.query.id ? this.$route.query.id : null
            this.title = this.$route.query.title ? this.$route.query.title : null
            this.cookName = this.$route.query.cook_name ? this.$route.query.cook_name : null
            this.materialName = this.$route.query.material_name ? this.$route.query.material_name : null
            this.page = this.$route.query.page ? this.$route.query.page : 1
        },
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data, error } = await axios.get('/api/video/articles', {
                params: { ...this.$route.query }
            })
            this.$store.commit(types.PAGE_LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }

            this.writers = this.idKeyList(data.writers)
            this.writerList = Object.values(this.writers).map((writer) => {
                return {id: writer.id, label: writer.screen_name}
            })
            this.writerList.unshift({id: null, label: 'ライター未設定'})

            data.article_status.forEach((status) => {
                this.articleStatusList[status.value] = status.label
            })

            this.statusFilterList = Object.assign([], data.article_status)
            this.statusFilterList.unshift({label: null, value: null})

            this.downDate = data.downDate

            this.tableData = Object.values(data.articles.data).map((article) => {
                return {
                    article: {
                        id: article.id,
                        writer_id: article.writer_id,
                        title: article.title,
                        status:article.status,
                        reserved_at:article.reserved_at,
                        released_at:article.released_at,
                        published_at : article.published_at
                    },
                    video: {
                        id: article.video_article.id,
                        video_code: article.video_article.video_code,
                    }
                }
            })
            this.pagination = Object.assign({}, data.articles)
            this.$store.commit(types.PAGE_LOADING, false)
        },
        handleCurrentChange (page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
        },
        search() {
            const query = Object.assign({}, this.$route.query,{
                status: this.status,
                id: this.articleId,
                title: this.title,
                cook_name: this.cookName,
                material_name: this.materialName,
                page: 1
            })
            this.$router.push({ query })
        },
        idKeyList(list) {
            return _.keyBy(list, (data) => data.id)
        },
        async update(form) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.put('/api/video/articles/' + form.article.id, form)
            if (error) {
                return
            }
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'レシピ動画記事を変更しました')
            this.$store.commit(types.LOADING, false)

        },
        async downPublishedAt(form) {
            this.$store.commit(types.LOADING, true)
            // ステータス411発生の可能性がある為、ヘッダーにContent-Length設定し空欄のパラメータ設置
            const config = {
                headers: {
                    'Content-Length': Buffer.byteLength('')
                }
            }
            const {error} = await axios.patch('/api/video/articles/' + form.article.id + '/down','' ,config)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, '公開日を下げました')
            this.$store.commit(types.LOADING, false)
        },
        async upPublishedAt(form) {
            this.$store.commit(types.LOADING, true)
            // ステータス411発生の可能性がある為、ヘッダーにContent-Length設定し空欄のパラメータ設置
            const config = {
                headers: {
                    'Content-Length': Buffer.byteLength('')
                }
            }
            const { error } = await axios.patch('/api/video/articles/' + form.article.id + '/up','' ,config)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.SHOW_INFO_MESSAGE, '公開日を元に戻しました')
            this.$store.commit(types.LOADING, false)
        }
    }
}


</script>

<style scoped>
    .search{
        margin: 10px 5px 0;
    }
</style>
