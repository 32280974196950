import { Table } from 'tiptap-extensions'
import { setCellAttr } from 'prosemirror-tables'
import CustomTableNodes from './CustomTableNodes'

export default class CustomTable extends Table {
    get schema () {
        return CustomTableNodes.table
    }
    commands ({ schema }) {
        return {
            ...super.commands({ schema }),
            setCellAttr: ({ name, value }) => setCellAttr(name, value)
        }
    }
}
