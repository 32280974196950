<template>
    <el-dialog
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
        width="500px"
    >
        <p>本当に削除しますか？</p>
        <el-form ref="form">
            <el-form-item>
                <el-button @click="close">キャンセル</el-button>
                <el-button type="danger" @click="deleteAdArticle">削除</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    props: {
        isActive: Boolean,
        deleteAdArticleId: Number,
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        // 削除
        deleteAdArticle() {
            this.$emit('delete')
        },
    },
}
</script>
<style lang="scss" scoped>
    .el-form {
        text-align: center;
        margin-top: 25px;
    }
</style>