<template>
    <div>
        <el-dialog
            :title="title"
            :visible.sync="isActive"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
        >
            <div v-if="isQuestion">
                <el-input class="ng-word-input" placeholder="NGワード" v-model="question_text"></el-input>
            </div>
            <div v-else>
                <el-input class="ng-word-input" placeholder="NGワード2" v-model="thread_text"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="close">キャンセル</el-button>
                    <el-button type="primary" @click="save">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        form: Object,
        isActive: Boolean,
        title: String,
    },
    data() {
        return {
            id: '',
            isQuestion: false,
            thread_text: '',
            question_text: '',
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.$emit('close')
        },
        // 編集
        async save() {
            const params = {
                id: this.id,
                thread_text  : this.thread_text,
                question_text: this.question_text,
                is_question : this.isQuestion,
            }
            this.$emit('save', params)
        },
    },
    watch: {
        form() {
            this.id = this.form.id
            if (this.form.threads === undefined) {
                this.thread_text = this.form.thread_text
            } else {
                this.isQuestion = true
                this.question_text = this.form.question_text
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    .preview-img {
        width: 250px;
    }
    .display-none {
        display: none;
    }
</style>
