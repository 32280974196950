<template>
    <el-dialog title="インセンティブ編集" @close="close" :visible="dialog" height="70%">
        <el-form label-width="150px">
            <el-form-item label="インセンティブ追加">
                <el-col :span="18" style="display: flex; align-items: center;">
                    <el-input v-model="incentiveFormat.description" placeholder="説明" style="width: 50%; margin-right: 10px;"></el-input>
                    <el-input v-model="incentiveFormat.price" placeholder="0" style="width: 30%; margin-right: 10px;">
                        <template slot="append">円</template>
                    </el-input>
                    <el-button @click="addIncentive">追加</el-button>
                </el-col>
                <el-col :span="14" style="margin-top: 10px;">
                    <span>現在：{{ fee.incentive | number }}円</span>
                </el-col>
                <el-col :span="14">
                    <el-table v-if="form.incentives.length !== 0" :data="form.incentives">
                        <el-table-column label="説明" prop="description"></el-table-column>
                        <el-table-column label="金額">
                            <template slot-scope="scope">{{ scope.row.price | number }}円</template>
                        </el-table-column>
                        <el-table-column label="削除">
                            <template slot-scope="scope">
                                <el-button @click="deleteIncentive(scope.$index)">削除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-form-item>
            <el-form-item label="オリジナル撮影料">
                <el-checkbox v-model="form.is_photo_incentive_target"></el-checkbox>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="submitForm">更新</el-button>
        </span>
    </el-dialog>
</template>
<script>

import * as types from '../../../../store/mutation-types'

export default {
    props: {
        dialog: Boolean,
        fee: Object,
        isPhotoIncentiveTarget: Boolean,
    },
    data () {
        return {
            incentiveFormat: {
                description: '',
                price: 0,
            },
            form: {
                incentives: [],
                is_photo_incentive_target: false,
            }
        }
    },
    watch: {
        fee() {
            this.form.incentives = []
            this.form.is_photo_incentive_target = this.isPhotoIncentiveTarget
        },
    },
    methods: {
        submitForm () {
            this.$emit('save', this.fee.id, this.form)
        },
        close () {
            this.$emit('close')
        },
        addIncentive() {
            if (this.incentiveFormat.description === '') {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '説明は必須です。')
                return
            }
            this.form.incentives.push(this.incentiveFormat)
            this.initIncentiveFormat()
        },
        deleteIncentive(idx) {
            this.form.incentives.splice(idx, 1)
        },
        initIncentiveFormat() {
            this.incentiveFormat = {
                description: '',
                price: 0,
            }
        },
    }
}
</script>
