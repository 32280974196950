<!-- csvアップロード用コンポネント
 csvアップロードを実行先とinputタグのnameを渡してください。
 また、アップロード後のfetch実行のため、emitでafterCsvImportFetchを実行するようにしているので、呼び出し元で
 @afterCsvImportFetchをつけるようにして下さい。（使用しない用のフラグか何か必要？）
 -->

<template>
	<el-upload
        class="uploadButton"
        accept=".csv"
        :headers="header"
        :before-upload="importCsvStart"
        :on-error="importCsvError"
        :on-success="importCsvSuccess"
        :show-file-list="false"
        :action="url"
        :name="name"
    >
		<el-button :type="elButtonType" icon="el-icon-upload2">
			{{ buttonText }}
		</el-button>
	</el-upload>
</template>

<script>
import * as types from '../store/mutation-types'

export default {
    name:'CsvUpload',
    props:{
        name: String,
        url: String,
        buttonText: {
            type: String,
            default: 'CSVアップロード',
        },
        elButtonType: {
            type: String,
            default: 'warning',
        },
    },
    data() {
        return {
            header: {
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
        }
    },
    methods: {
        importCsvStart() {
            this.$store.commit(types.LOADING, true)
        },
        importCsvError(e) {
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_ERROR_MESSAGE, JSON.parse(e.message).message ?? 'CSVアップロードに失敗しました')
        },
        async importCsvSuccess(event) {
            if (event.status === 400) {
                this.$store.commit(types.LOADING, false)
                this.$store.commit(types.SHOW_ERROR_MESSAGE, event.error)
            } else {
                this.$store.commit(types.LOADING, false)
                this.$emit('afterCsvImportFetch')
            }
        },
    },
}
</script>
<style scoped>
.el-button {
    width: 100%;
}
.uploadButton >>> .el-upload {
    width: 100%;
}
</style>
