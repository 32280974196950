<template>
    <el-dialog
        title="記事公開"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="closeModal"
    >
        <div style="margin-bottom: 20px">「公開予約日時」に日時を入力すると、公開予約として保存されます。</div>
        <el-form ref="form" label-width="120px">
            <el-form-item label="公開予約日時">
                <el-date-picker
                    v-model="reservedAt"
                    type="datetime"
                    size="mini"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button @click="closeModal">閉じる</el-button>
                <el-button type="primary" @click="$emit('publishArticle', form, reservedAt)">公開</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>

export default {
    props: {
        isActive: {
            type: Boolean,
            required: true,
        },
        form: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            reservedAt: null,
        }
    },
    watch: {
        form() {
            this.reservedAt = this.form.article.reserved_at
        },
    },
    methods: {
        closeModal() {
            this.$emit('closeModal')
        },
    },
}
</script>
