<template>
    <div>
        <div class="header-wrapper">
            <el-row type="flex" justify="end" class="create-button-wrapper">
                <el-button type="primary" @click="openCreateModal()">新規作成</el-button>
            </el-row>
            <div class="search-form">
                <el-row type="flex" justify="end" class="search-input-wrapper">
                    <el-input v-model="searchForm.id" class="search-input" placeholder="記事ID" style="width: 200px;"></el-input>
                    <el-input v-model="searchForm.title" class="search-input" placeholder="記事タイトル" style="width: 300px;"></el-input>
                    <el-input v-model="searchForm.description" class="search-input" placeholder="記事ディスクリプション" style="width: 300px;"></el-input>
                </el-row>
                <el-row type="flex" justify="end" class="search-input-wrapper">
                    <el-select v-model="searchForm.status" class="search-input" placeholder="記事ステータス">
                        <el-option label="" value=""></el-option>
                        <el-option
                            v-for="status in articleStatus"
                            :key="status.value"
                            :label="status.label"
                            :value="status.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="searchForm.channel" class="search-input" placeholder="配信チャネル" multiple>
                        <el-option
                            v-for="channel in channels"
                            :key="channel.value"
                            :label="channel.label"
                            :value="channel.value">
                        </el-option>
                    </el-select>
                    <el-checkbox v-model="searchForm.is_recommend" class="search-input">おすすめ表示期間中のみ表示</el-checkbox>
                    <el-button
                        @click="search()"
                        icon="el-icon-search"
                        class="search-input"
                    ></el-button>
                </el-row>
            </div>
        </div>
        <el-table
                :data="tieupArticles"
                style="width:100%; font-size:12px;"
                max-height="800"
        >
            <el-table-column label="記事ID" width="70">
                <template slot-scope="scope">
                    <a :href="scope.row.article.article_url" v-if="isArticlePublish(scope.row.article.status)" target="_blank">{{ scope.row.article_id }}</a>
                    <span v-else>{{ scope.row.article_id }}</span>
                    <br/>
                    <a class="el-icon-edit" :href="addArticleUrl(scope.row.article_id)" target="_blank" >編集</a>
                </template>
            </el-table-column>
            <el-table-column label="記事サムネイル">
                <template slot-scope="scope">
                    <img :src="scope.row.tieup_thumbnail_cdn_url" style="width:100px;">
                </template>
            </el-table-column>
            <el-table-column :render-header="renderTableHeader(['提供', '記事タイトル', 'タイアップ記事タイトル'])">
                <template slot-scope="scope">
                    <div class="article-title">{{scope.row.company_name}}</div>
                    <div class="article-title">{{scope.row.article.title}}</div>
                    <div class="article-title">{{scope.row.tieup_title}}</div>
                </template>
            </el-table-column>
            <el-table-column label="提供を非表示に" width="120">
                <template slot-scope="scope">
                    <i class="el-icon-check" v-if="scope.row.is_hide_company_name"></i>
                </template>
            </el-table-column>
            <el-table-column label="作成日時" prop="created_at" width="160"></el-table-column>
            <el-table-column label="おすすめ表示期間" width="160">
                <template slot-scope="scope">
                    <div>{{ scope.row.recommend_start_at }}</div>
                    <div style="padding-left:50px">~</div>
                    <div>{{ scope.row.recommend_end_at }}</div>
                </template>
            </el-table-column>
            <el-table-column label="配信チャネル" width="100">
                <template slot-scope="scope">
                    <div>WebPC : <i class="el-icon-check" v-if="scope.row.is_webpc"></i></div>
                    <div>WebSP : <i class="el-icon-check" v-if="scope.row.is_websp"></i></div>
                    <div>IOS : <i class="el-icon-check" v-if="scope.row.is_ios"></i></div>
                    <div>Android : <i class="el-icon-check" v-if="scope.row.is_android"></i></div>
                </template>
            </el-table-column>
            <el-table-column label="トラッキングタグ">
                <template slot-scope="scope">
                    <el-button class="confirmTag" type="text" @click="openConfirmTag(scope.row.tracking_tag)">{{scope.row.tracking_tag}}</el-button>
                </template>
            </el-table-column>
            <el-table-column align="right">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        @click="openEditModal(scope.row)"
                    >編集</el-button>
                    <el-button
                        class="btn-delete"
                        size="mini"
                        type="danger"
                        @click="releaseRow(scope.row.id)"
                    >解除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="search" :pagination="pagination" />

        <!-- 新規作成モーダル -->
        <tieup-edit-modal
            :is-active="isModalActive.create"
            :tieup-article="{}"
            title="新規作成"
            @close="closeCreateModal"
            @save="create"
        ></tieup-edit-modal>

        <!-- 編集モーダル -->
        <tieup-edit-modal
            :is-active="isModalActive.edit"
            :tieup-article="editTieupArticle"
            title="編集"
            @close="closeEditModal"
            @save="update"
        ></tieup-edit-modal>
        <el-dialog
            title="トラッキングタグ確認"
            :visible.sync="isModalActive.confirmTagDialog"
            width="50%"
        >
            <pre class="preTag">{{confirmTag}}</pre>
        </el-dialog>
        <el-dialog
            title="広告解除"
            :visible.sync="deleteConfirmDialog"
            width="30%"
            @close="closeDeleteDialog"
        >
            <span >記事広告を解除してよろしいでしょうか？(記事広告にする前の状態に戻ります。)</span>
            <span slot="footer" class="dialog-footer">
            <el-button @click="closeDeleteDialog">キャンセル</el-button>
            <el-button type="danger" @click="release()">解除する</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import * as types from '../../../store/mutation-types'
import Pagination from '../../../components/Pagination'
import TieupEditModal from './Tieup/EditModal'

export default {
    components: { Pagination, TieupEditModal },
    data() {
        return {
            tieupArticles: [],
            pagination: {},
            searchForm: {
                'id': '',
                'title': '',
                'description': '',
                'status': '',
                'channel': '',
                'is_recommend': false,
                'page': 1,
            },
            isModalActive: {
                create: false,
                edit: false,
                confirmTagDialog: false,
            },
            editTieupArticle: {},
            articleStatus: [],
            channels: [],
            confirmTag:[],
            deleteConfirmId: null,
            deleteConfirmDialog: false,
        }
    },
    beforeRouteUpdate(to, from, next) {
        next()
        this.fetch()
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '広告　>　記事広告')
        this.fetch()
    },
    methods: {
        // 一覧取得
        async fetch() {
            this.$store.commit(types.LOADING, true)

            const { data, error } = await axios.get('/api/tieup/articles', {
                params: { ...this.$route.query }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.tieupArticles = data.tieup_articles.data
            this.pagination = Object.assign({}, data.tieup_articles)
            this.articleStatus = Object.assign({}, data.article_status)
            this.channels = data.channels
            this.$store.commit(types.LOADING, false)
        },
        // 検索
        search (page = 1) {
            let searchForm = Object.assign({}, this.searchForm)
            searchForm.page = page
            searchForm.is_recommend = searchForm.is_recommend ? 1 : 0
            const query = Object.assign({}, this.$route.query, searchForm)
            this.$router.push({ query })
        },
        // 新規作成
        async create(params) {
            this.$store.commit(types.LOADING, true)
            const { error } = await axios.post('/api/tieup/articles', params)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.isModalActive.create = false
            this.fetch()
        },
        // 編集
        async update(params, id) {
            this.$store.commit(types.LOADING, true)
            const config = {
                headers: {
                    'X-HTTP-Method-Override': 'PUT'
                }
            }
            const { error } = await axios.post('/api/tieup/articles/'+id, params, config)
            this.$store.commit(types.LOADING, false)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.isModalActive.edit = false
            this.fetch()
        },
        // 新規作成モーダルopen
        openCreateModal() {
            this.isModalActive.create = true
        },
        // 新規作成モーダルclose
        closeCreateModal() {
            this.isModalActive.create = false
        },
        // 編集モーダルopen
        openEditModal(tieupArticle) {
            this.editTieupArticle = tieupArticle
            this.isModalActive.edit = true
        },
        // 編集モーダルclose
        closeEditModal() {
            this.isModalActive.edit = false
        },
        openConfirmTag(data) {
            this.isModalActive.confirmTagDialog = true
            this.confirmTag = data
        },
        isArticlePublish(status) {
            return status === 500
        },
        addArticleUrl(articleId) {
            return '/articles/' + articleId
        },
        releaseRow(id) {
            this.deleteConfirmId = id
            this.deleteConfirmDialog = true
        },
        closeDeleteDialog() {
            this.deleteConfirmId = null
            this.deleteConfirmDialog = false
        },
        async release() {
            this.$store.commit(types.PAGE_LOADING, true)
            const { error } = await axios.delete('/api/tieup/articles/' + this.deleteConfirmId)
            if (error) {
                this.$store.commit(types.PAGE_LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.PAGE_LOADING, false)
            this.deleteConfirmId = null
            this.deleteConfirmDialog = false
            this.$store.commit(types.SHOW_INFO_MESSAGE, '記事広告を解除しました')
        },
    }
}
</script>
<style scoped>
    .create-button-wrapper {
        margin: 0 10px 30px 0;
    }
    .search-form {
        margin-bottom: 30px;
    }
    .search-input {
        margin: 10px 5px 0;
    }
    .search-input-wrapper {
        align-items: center;
        margin-right: 5px;
    }
    .article-title {
        padding-left: 10px;
        border-bottom: 1px solid #EBEEF5;
    }
    .article-title:last-child {
        border-bottom: 0;
    }
    .preTag{
        white-space:pre-line;
    }
    .confirmTag {
        white-space:pre-line;
        text-align:left;
        max-height:150px;
        margin-top:-10px;
    }
    .btn-delete {
        margin-top: 15px;
    }
</style>
