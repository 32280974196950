<template>
    <div>
        <el-row>
            <item-left-contents
                :check-state="checkState"
                :number="number"
                :page-number="item.page_number"
                @update:checkState="checkState = $event"
            />
            <el-col class="affiItem contents" :span="21">
                <affiliate-type v-if="isEditing" ref="dialogModule" :save="update" @close="closeMenu" :id="content.id" :input="content" />
                <div class="affiWrap" v-else>
                    <div class="left">
                        <img :src="content.affi_thumbnail_url" :alt="content.affi_name" width="250px" >
                    </div>
                    <div class="affiText">
                        <h4 class="affiName">
                            {{content.affi_name}}
                        </h4>
                        <div class="affiPrice">
                                ￥{{checkPrice(content) | number}}〜
                        </div>
                        <div class="affiDescription" style="white-space: pre-line;">
                            {{content.affi_description}}
                        </div>
                        <div class="subWrap">
                            <div v-for="(sub_thumbnail, index) in content.sub_thumbnails" :key="index">
                                <img :src="sub_thumbnail.sub_thumbnail_url" class="subImage">
                            </div>
                        </div>
                        <div class="affiBtnsWrap">
                            <el-button
                                v-if="content.amazon_url"
                                size="medium"
                                class="affiBtns"
                                @click="clickTargetBlank(content.amazon_url)"
                            >Amazonで見る<span v-if="content.amazon_price">　￥{{content.amazon_price | number}}(税込)</span></el-button>
                            <el-button
                                v-if="content.rakuten_url"
                                size="medium"
                                class="affiBtns"
                                @click="clickTargetBlank(content.rakuten_url)"
                            >楽天で見る<span v-if="content.rakuten_price">　￥{{content.rakuten_price | number}}(税込)</span></el-button>
                            <el-button
                                v-if="content.yahoo_url"
                                size="medium"
                                class="affiBtns"
                                @click="clickTargetBlank(content.yahoo_url)"
                            >Yahooで見る<span v-if="content.yahoo_price">　￥{{content.yahoo_price | number}}(税込)</span></el-button>
                        </div>
                        <div class="priceCheckedAt" v-if="content.price_checked_at">
                            ＊商品価格チェック日付<span>{{content.price_checked_at | showDateFormat}}</span>
                        </div>
                    </div>
                </div>
            </el-col>

            <el-col class="rightBox" :span="2">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', resourceName, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>


        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>

    </div>
</template>
<script>

import FeedbackForm from './Components/FeedbackForm'
import AffiliateType from '../Form/AffiliateType'
import ItemLeftContents from './Components/ItemLeftContents'

export default {
    data () {
        return {
            isEditing: false,
        }
    },
    computed: {
        content() {
            return this.item.content
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        resourceName() {
            return 'affiliates'
        },
    },
    components: {
        AffiliateType,
        FeedbackForm,
        ItemLeftContents,
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check,item) {
            this.parentCheckItem(check,item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        clickTargetBlank (link) {
            window.open(link, '_blank', '')
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
        checkPrice(content) {
            if (!content.amazon_price && !content.rakuten_price && !content.yahoo_price) {
                return content.affi_price
            }
            if(content.amazon_price && !content.rakuten_price && !content.yahoo_price) {
                return content.amazon_price
            }
            if(content.rakuten_price && !content.amazon_price && !content.yahoo_price) {
                return content.rakuten_price
            }
            if(content.yahoo_price && !content.amazon_price && !content.rakuten_price) {
                return content.yahoo_price
            }

            if (content.amazon_price && content.rakuten_price && content.yahoo_price) {
                return Math.min(content.amazon_price, content.rakuten_price, content.yahoo_price)
            }
            if (content.amazon_price && content.yahoo_price) {
                return Math.min(content.amazon_price, content.yahoo_price)
            }
            if (content.amazon_price && content.rakuten_price) {
                return Math.min(content.amazon_price, content.rakuten_price)
            }
            if (content.rakuten_price && content.yahoo_price) {
                return Math.min(content.rakuten_price, content.yahoo_price)
            }

            return 0
        }
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>
<style scoped>
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}
.affiWrap {
    display: flex;
    justify-content: left;
}

.affiText {
    margin-left:20px;
}
.affiName {
    margin: 0;
    font-size: 20px;
}

.affiPrice {
    color:#666;
    margin-top:10px;
}
.affiPrice span {
    padding-left:10px;
    font-size:14px;
}
.affiDescription {
    color:#999;
    margin-top:10px;
    font-size:14px;
}
.affiBtnsWrap {
    margin-top:25px;
    margin-right:10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.priceCheckedAt {
    margin-top:25px;
    margin-right:10px;
    color:#666;
    font-size: 14px;
}
.affiBtns {
    color: #FFF !important;
    font-size: 13px;
    font-weight: 200;
    text-align: center;
    padding: 15px 20px;
    border-radius: 3px;
    display: inline-block;
    background-color: #e37e7b;
    width: 220px;
}

.affiBtns:nth-child(3) {
    margin: 10px 0 0 0;
}

.subWrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    width: 460px;
}
.subImage {
    margin-left: 20px;
    width: 50px;
}

</style>
