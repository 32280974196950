<template>
    <div>
        <el-row type="flex" style="margin-bottom: 10px">
            <el-autocomplete
                v-model="keyword"
                class="inputrecipeCategoryTag"
                @select="handleSelect"
                :fetch-suggestions="querySearch"
                :trigger-on-focus="true"
                placeholder="タグ名"
            ></el-autocomplete>
        </el-row>
        <div class="caution">※D&Dでソートできます</div>
        <draggable v-model="thisValues" :options="draggableOptions">
            <el-tag
                v-for='(tagPath, index) in thisValues'
                :key='index'
                :disable-transitions="true"
                type="info"
                size="Default"
                closable
                class="recipeCategoryList"
                @close="handleClose(index)"
            >
                {{ tagPath.tag.tag_name }}
            </el-tag>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import * as types from '../store/mutation-types'
export default {
    components: {
        draggable
    },
    props: {
        value: {
            type: Array,
            required: false,
            default: () => [],
        },
        tagPathId: {
            type: Number,
            required: false,
            default: null,
        }
    },
    data() {
        return {
            keyword: '',
            beforeKeyword: '',
            tagPaths: [],
            suggestParams: [],
            isInitSearch: false,
            values: [],
        }
    },
    watch: {
        value() {
            this.values = this.value.map(val => ({
                ...val,
                parent_tag: val.parent_tag ? {...val.parent_tag} : null,
                child_tag: val.child_tag ? {...val.child_tag} : null,
                grandchild_tag: val.grandchild_tag ? {...val.grandchild_tag} : null,
                tag: {...val.tag},
            }))
        }
    },
    methods: {
        async querySearch(queryString, cb) {
            // タグパスとキーワードが空の場合api実行しない/キーワードが前回検索時と同様の場合api実行しない/初回フォーカス時に検索実行させたいためフラグで初回を判定
            if ((this.tagPathId === null && queryString === '') || (this.isInitSearch && this.keyword === this.beforeKeyword)) {
                cb(this.suggestParams)
                return
            }
            const {data, error} = await axios.get('/api/tags/suggest', {
                params: {
                    keyword: queryString,
                    tag_path_id: this.tagPathId,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                return
            }
            this.tagPaths = data.tag_paths.slice()
            this.beforeKeyword = this.keyword
            this.suggestParams = data.tag_paths.map(tagPath => ({id: tagPath.id, value: tagPath.tag.tag_name}))
            this.isInitSearch = true
            cb(this.suggestParams)
        },
        handleSelect(item) {
            if (this.value.find(tagPath => tagPath.id === item.id)) {
                this.keyword = this.beforeKeyword
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '追加済みです')
                return
            }
            this.values.push(this.tagPaths.find(tagPath => tagPath.id === item.id))
            this.$emit('input', this.values)
            this.keyword = this.beforeKeyword
        },
        handleClose(index) {
            this.values.splice(index, 1)
            this.$emit('input', this.values)
        },
    },
    computed: {
        thisValues: {
            get() {
                return this.value
            },
            set(values) {
                this.$emit('input', values)
            }
        },
        draggableOptions() {
            return {
                handle: '.recipeCategoryList'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.recipeCategoryList {
    margin: 5px 5px 5px 0;
    cursor: grab;
}
.inputrecipeCategoryTag {
    width: 400px;
    vertical-align: bottom;
}
.caution {
    margin: 5px 0;
    color: red;
    font-size: 14px;
}
</style>
