<template>
    <div>
        <el-row type="flex" justify="end">
            <el-button class="add-button" type="primary" @click="openRegistrationModal()">追加</el-button>
        </el-row>
        <el-row type="flex" justify="end">
            <div class="search-check">
                <el-checkbox v-model="isNormal">通常記事</el-checkbox>
                <el-checkbox v-model="isVideo">動画記事</el-checkbox>
            </div>
            <el-input class="fetch-input" placeholder="NGワード" v-model="ngWord" @keyup.enter.native="fetch(ngWord)">
                <el-button
                    @click="fetch(ngWord)"
                    slot="append"
                    icon="el-icon-search">
                </el-button>
            </el-input>
        </el-row>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <el-table
            :data="ngWords"
            style="width: 100%"
            max-height="800"
        >
            <el-table-column label="NGワード">
                <template slot-scope="scope">
                    <div>{{ replaceSpecialText(scope.row.ng_word) }}</div>
                </template>

            </el-table-column>
            <el-table-column label="通常記事">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_normal" disabled></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column label="動画記事">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_video" disabled></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column label="編集">
                <template slot-scope="scope">
                    <el-button
                        class="btn-warning"
                        @click="openEditModal(scope.row)"
                    >編集
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column label="削除">
                <template slot-scope="scope">
                    <el-button
                        class="btn-danger"
                        type="danger"
                        @click="destroyNgWord(scope.row.id)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <registration-modal
            :is-active="registration.isActive"
            @close="registration.isActive = false"
            @reload="fetch(ngWord)"
        ></registration-modal>
        <edit-modal
            ref="edit"
            :is-active="edit.isActive"
            :row="edit.row"
            @close="edit.isActive = false"
            @reload="fetch(ngWord)"
        ></edit-modal>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import RegistrationModal from './Ng/RegistrationModal'
import EditModal from './Ng/EditModal'

export default {
    components: {
        Pagination,
        RegistrationModal,
        EditModal,
    },
    data() {
        return {
            isNormal    : false,
            isVideo     : false,
            ngWords     : [],
            pagination  : {},
            ngWord      : '',
            registration: {
                isActive: false,
            },
            edit        : {
                isActive: false,
                row     : {},
            }
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '記事作成データ管理　>　NGワード')
        this.init()

    },
    methods: {
        init() {
            this.fetch()
        },

        // 検索
        async fetch(ngWord = '') {
            const page = this.$route.query.page
            let params = {page}
            if (ngWord !== '') {
                params.ng_word = ngWord
            }
            params.is_normal = Number(this.isNormal)
            params.is_video = Number(this.isVideo)

            this.$store.commit(types.LOADING, true)
            const {data, error} = await axios.get('/api/editing/ngwords', {params})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.ngWords = data.data
            this.pagination = Object.assign({}, this.pagination, data)
            this.$store.commit(types.LOADING, false)
        },
        // NGワードを削除
        async destroyNgWord(ngWordId) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.delete('/api/editing/ngwords/' + ngWordId)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.LOADING, false)
        },
        openRegistrationModal() {
            this.registration.isActive = true
        },
        openEditModal(row) {
            this.edit.isActive = true
            this.edit.row = row
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch(this.ngWord, this.isNormal, this.video)
        },
        // 特定の文字だけ表示を変更
        replaceSpecialText: function(ngWord){
            let returnWord = ngWord
            if (ngWord == '　') {
                returnWord = '[全角スペース]'
            }
            else if (ngWord.match(/\n\n\n/g)) {
                returnWord = '[改行3連続]'
            }
            return returnWord
        },
    }
}
</script>

<style lang="scss" scoped>
	.search-check {
        margin: 10px 20px;
    }
    .add-button {
        margin: 10px 20px 20px;
    }

    .fetch-input {
        width: 500px;
        margin-right: 20px;
    }
</style>
