<template>
    <div>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />
        <el-row>
            <el-col >
                <el-table :data="tableData" max-height="800">
                    <el-table-column label="年月">
                        <template slot-scope="scope">
                             <el-button @click="showRow(scope.row.year_month)" type="text">
                                 {{showDate(scope.row.year_month)}}
                             </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="grade.name" label="グレード"/>
                    <el-table-column label="撮影ランク">
                        <template slot-scope="scope">
                            {{ scope.row.writer_photo_rank === null ? '未設定' : scope.row.writer_photo_rank.name + ' (' + scope.row.writer_photo_rank.low_price + '円/' + scope.row.writer_photo_rank.high_price + '円)' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="articles_count" label="記事本数"/>
                    <el-table-column prop="rewrites_count" label="リライト本数"/>
                    <el-table-column label="記事単価">
                        <template slot-scope="scope">
                            {{scope.row.unit_price | number}}円
                        </template>
                    </el-table-column>
                    <el-table-column label="インセンティブ">
                        <template slot-scope="scope">
                            {{scope.row.result.incentive | number}}円
                            <div v-if="scope.row.custom_incentive_histories.length !== 0">
                                <el-button type="primary" size="small" style="padding: 5px 10px;" @click="openIncentiveModal(scope.row.custom_incentive_histories)">詳細</el-button>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :render-header="renderTableHeader(['記事金額','(税込)'])">
                        <template slot-scope="scope">
                            {{scope.row.result.article | number}}円
                        </template>
                    </el-table-column>
                    <el-table-column :render-header="renderTableHeader(['リライト金額','(税込)'])">
                        <template slot-scope="scope">
                            {{scope.row.result.rewrite | number}}円
                        </template>
                    </el-table-column>
                    <el-table-column label="店舗/商品/アイテム情報/比較表入力(税込)">
                        <template slot-scope="scope">
                            {{scope.row.result.other | number}}円
                        </template>
                    </el-table-column>
                    <el-table-column label="固定費">
                        <template slot-scope="scope">
                            {{scope.row.fixed_cost | number}}円
                        </template>
                    </el-table-column>
                    <el-table-column :render-header="renderTableHeader(['合計金額','(税込)'])">
                        <template slot-scope="scope">
                            {{scope.row.result.total | number}}円
                        </template>
                    </el-table-column>
                    <el-table-column prop="comment" label="ライター向けコメント" />
                    <el-table-column label="編集">
                        <template slot-scope="scope">
                            <el-button
                                class="btn-edit"
                                @click="editRow(scope.row)"
                            >編集</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <pagination
            :handleCurrentChange="handleCurrentChange"
            :pagination="pagination"
        />
        <edit-modal
            :save="save"
            :dialog="dialog"
            :closeDialog="closeDialog"
            :input="form"
            :grades="grades"
            :writerPhotoRanks="writerPhotoRanks"
        />
        <incentive-modal
            :incentive-histories="incentiveHistories"
            :dialog="isIncentiveModal"
            @close="closeIncentiveModal"
            @deleteIncentiveHistory="deleteIncentiveHistory"
        ></incentive-modal>
    </div>
</template>

<script>

import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import EditModal from './Fee/EditModal.vue'
import IncentiveModal from './Fee/IncentiveModal.vue'
import moment from 'moment'

export default {
    components: {
        IncentiveModal,
        Pagination,
        EditModal
    },
    data() {
        return {
            form: {},
            targetId: null,
            newWriterGradeBeginDay: null,
            dialog: false,
            isIncentiveModal: false,
            pagination: {},
            newGrades: [],
            oldGrades: [],
            grades: [],
            tableData: [],
            incentiveHistories: [],
            writerPhotoRanks: [],
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'ライター報酬管理')
        this.fetch()
    },
    methods: {
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const page = this.$route.query.page
            const { data } = await axios.get('/api/writers/' + this.$route.params.writerId + '/fees', {
                params: { page }
            })

            this.$store.commit(types.PAGE_TITLE, data.writer.writer_name + ' の月毎の金額入力画面')
            this.pagination = Object.assign({},this.pagination, data.fees)
            this.tableData = Object.assign([], data.fees.data)
            this.newGrades = data.new_grades
            this.oldGrades = data.old_grades
            this.newWriterGradeBeginDay = data.newWriterGradeBeginDay
            this.writerPhotoRanks = data.writer_photo_ranks

            this.$store.commit(types.PAGE_LOADING, false)
        },
        editRow (form) {
            // 新grade始まった日付を元に分岐
            if (moment(form.year_month) >= moment('2019-08-01 00:00:00')) {
                this.grades = this.newGrades
            } else {
                this.grades = this.oldGrades
            }

            this.form = Object.assign({}, form)
            this.dialog = true
        },
        showDate (item) {
            return moment(item).format('YYYY-MM')
        },
        showRow (date) {
            return this.$router.push('/writers/' + this.$route.params.writerId + '/fees/articles/' + this.showDate(date))
        },
        async save (id, form) {
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.patch('/api/writers/' + this.$route.params.writerId + '/fees/' + id, form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                await this.fetch()
                this.$store.commit(types.SHOW_INFO_MESSAGE, 'データを変更しました')
            }
            this.$store.commit(types.LOADING, false)
        },
        handleCurrentChange (page) {
            this.$router.push({query: {page}})
            this.fetch()
        },
        closeDialog () {
            this.dialog = false
        },
        openIncentiveModal(incentiveHistories) {
            this.incentiveHistories = incentiveHistories
            this.isIncentiveModal = true
        },
        closeIncentiveModal() {
            this.isIncentiveModal = false
        },
        async deleteIncentiveHistory(id) {
            this.$store.commit(types.LOADING, true)

            const { data, error } = await axios.delete('/api/incentives/histories/'+id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました')
            } else {
                this.closeIncentiveModal()
                await this.fetch()
                this.$store.commit(types.SHOW_INFO_MESSAGE, '削除しました')
            }

            this.$store.commit(types.LOADING, false)
        }
    }
}
</script>

