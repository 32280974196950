<template>
    <div>
        <div class="button-list">
            <el-button type="success" @click="() => this.$router.push('/organizations/app_topic/order')">並び替え</el-button>
            <el-button @click="openRegistrationModal()">新規追加</el-button>
        </div>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <el-table
                ref="appTopicTable"
                :data="appTopic"
                style="width: 100%"
                max-height="800"
        >
            <el-table-column label="app特集ID" prop="id">
                <template slot-scope="scope">
                    <a :href="'/organizations/app_topic/' + scope.row.id + '/articles'">{{ scope.row.id }}</a>
                </template>
            </el-table-column>
            <el-table-column label="タイトル" prop="title"></el-table-column>
            <el-table-column label="並び順" prop="order_num"></el-table-column>
            <el-table-column label="表示" prop="is_active">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.is_active" disabled></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column label="作成日時" prop="created_at"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            @click="openEditModal(scope.row)"
                    >編集
                    </el-button>
                    <el-button
                            size="mini"
                            type="danger"
                            @click="destroyAppTopic(scope.row.id)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination"/>
        <registration-modal
                :is-active="registration.isActive"
                @close="registration.isActive = false"
                @reload="fetch()"
        ></registration-modal>
        <edit-modal
                ref="edit"
                :is-active="edit.isActive"
                :row="edit.row"
                @close="edit.isActive = false"
                @reload="fetch()"
        ></edit-modal>
    </div>
</template>

<script>
import * as types        from '../../store/mutation-types'
import Pagination        from '../../components/Pagination'
import RegistrationModal from './AppTopic/RegistrationModal'
import EditModal         from './AppTopic/EditModal'

export default {
    components: {
        RegistrationModal,
        EditModal,
        Pagination,
    },
    data() {
        return {
            pagination   : {},
            appTopic     : [],
            registeredIds: [],
            registration : {
                isActive: false,
            },
            edit         : {
                isActive: false,
                row     : {},
            },
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, 'アプリ　>　見つける特集')
        this.init()
    },
    methods   : {
        init() {
            this.fetch()
        },
        // 検索
        async fetch() {
            this.$store.commit(types.LOADING, true)
            const page = this.$route.query.page
            const params = {page}
            const {data, error} = await axios.get('/api/organization/app_topics', {params})
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }
            this.appTopic = data.data
            this.pagination = Object.assign({}, this.pagination, data)
            this.$store.commit(types.LOADING, false)
        },
        // APP見つける特集を削除
        async destroyAppTopic(id) {
            if (!confirm('本当に削除しますか？')) {
                return
            }
            this.$store.commit(types.LOADING, true)
            const {error} = await axios.delete('/api/organization/app_topics/' + id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '削除に失敗しました。')
                this.$store.commit(types.LOADING, false)
                return
            }
            await this.fetch()
            this.$store.commit(types.LOADING, false)
            this.$store.commit(types.SHOW_INFO_MESSAGE, 'データの削除に成功しました。')
        },
        openRegistrationModal() {
            this.registration.isActive = true
        },
        openEditModal(row) {
            this.edit.isActive = true
            this.edit.row = row
        },
        handleCurrentChange(page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
            this.fetch()
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
